import {Box, Stack, Tab, Tabs, Typography} from "@mui/material";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import React, {ReactNode, SyntheticEvent, useEffect, useMemo, useRef, useState,} from "react";
import {MynkPageContext, MynkPageContextValue} from "./context";
import {MynkTabInfo} from "./MynkTab";
import {ChevronLeft} from "@mui/icons-material";

export interface MynkPageProps {
  title: string;
  basePath: string;
  tabs: MynkTabInfo[];
  action?: ReactNode;
  showTabs?: boolean;
  backLink?: -1 | string;
}


interface ActionContainerProps {
  setActionContainerEl: (el: HTMLDivElement | null) => void;
}

function ActionContainer(props: ActionContainerProps) {
  const actionContainerEl = useRef<null | HTMLDivElement>();

  useEffect(() => {
    if (actionContainerEl.current)
      props.setActionContainerEl(actionContainerEl.current!);
  });

  return (
    <Box ref={actionContainerEl} />
  )
}

export function MynkPage(props: MynkPageProps) {
  const location = useLocation();
  const currentTab = useMemo(() => {
    return props.tabs.findIndex(
      (t) => props.basePath + t.path === location.pathname
    );
  }, [location.pathname]);

  const navigate = useNavigate();
  const handleTabChange = (_event: SyntheticEvent, value: number) => {
    navigate(props.basePath + props.tabs[value].path, {replace: true});
  };

  // hack to make refs appear in the useMemo below
  const [ready, setReady] = useState(false);

  const [actionContainerEl, setActionContainerEl] = useState<null | HTMLDivElement>(null);
  const contextValue = useMemo<MynkPageContextValue>(
    () => {
      return {
        actionContainer: actionContainerEl,
      };
    },
    [ready, actionContainerEl]
  );

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <Box sx={{p: 4}}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography fontFamily="Helvetica Neue" variant="h1">
            {props.title}
          </Typography>

          {props.backLink && (
            <Typography
              sx={{
                textDecoration: "underline",
                color: "#75b3ff",
                cursor: "pointer",
              }}
              // onClick={handleBack}
              component={Link}
              to={props.backLink as string}
              fontSize="1.44em"
            >
              <ChevronLeft sx={{width: "1.1rem", height: "1.1rem", ml: -1, mt: 2.2}}/>
              Back
            </Typography>
          )}
        </Box>

        <ActionContainer setActionContainerEl={setActionContainerEl} />
      </Stack>

      {currentTab >= 0 && props.showTabs !== false && (
        <Tabs
          value={currentTab}
          sx={{mb: 3.4, mt: props.backLink ? 1 : 1, width: "50%"}}
          onChange={handleTabChange}
        >
          {props.tabs.map((tab) => (
            <Tab
              key={tab.title}
              label={tab.title}
              sx={{
                position: 'relative',
                fontFamily: "Arial",
                fontSize: "1.2rem",

                minWidth: '0px',
                marginRight: '2rem',
                overflow: 'visible',
                px: '0.5rem',

                borderBottom: 'solid 0.1429rem #cde6ff',

                '&:not(:last-child)::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -2,
                  left: 0,
                  height: '2px',
                  background: '#cde6ff',
                  width: 'calc(100% + 2rem)',
                }
              }}
            />
          ))}
        </Tabs>
      )}

      <MynkPageContext.Provider value={contextValue}>
        <Outlet/>
      </MynkPageContext.Provider>
    </Box>
  );
}
