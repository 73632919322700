import {Box, Button, Card, CircularProgress, Stack, Typography} from "@mui/material";
import {useCheckoutSubscriptionMutation, useWhoamiQuery} from "../../api/auth";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import DialogActionButton from "../../components/DialogActionButton";
import {SubscriptionPlan} from "../../api/types";


interface PlanBoxProps {
  id: SubscriptionPlan;
  title: string;
  description: string;
  price: number;
  points: string[];
  onSelect?: (id: SubscriptionPlan) => void;
  loading?: boolean;
}

function PlanBox(props: PlanBoxProps) {
  return (
    <Card sx={{ maxWidth: '18rem', p: 3 }}>
      <Typography variant="h5">
        {props.title}
      </Typography>

      <Typography sx={{ mt: 1, minHeight: '6.5rem' }}>
        {props.description}
      </Typography>

      <Typography sx={{ my: 1 }}>
        <b style={{fontSize: '1.25rem'}}>${props.price}</b> / Month
      </Typography>

      <Box sx={{ minHeight: '7rem'}}>
        {props.points.map((point, index) => (
          <Typography key={index} variant="body2" sx={{ ml: 1, fontSize: '0.9rem' }}>
            ✓ {point}
          </Typography>
        ))}
      </Box>

      {props.onSelect && (
        <Stack alignItems="center">
          <DialogActionButton
            onClick={() => props.onSelect!(props.id)}
            loading={props.loading}
          >
            Select
          </DialogActionButton>
        </Stack>
      )}
    </Card>
  )
}


interface PlanSelectionProps {
  onSelect: (id: SubscriptionPlan) => void;
  loading?: boolean;
}

function PlanSelection(props: PlanSelectionProps) {
  return (
    <Stack direction="row" spacing={5}>
      <PlanBox
        id="essentials"
        title="Essentials"
        description="All the business essentials needed to run your business smoothly."
        price={15}
        points={[
          "Projects & Clients",
          "Invoices & Contracts",
          "Calendar",
          "Portfolio",
        ]}
        onSelect={props.onSelect}
        loading={props.loading}
      />

      <PlanBox
        id="pro"
        title="Pro"
        description="Take your business to the next level with incredible client experience."
        price={30}
        points={[
          "Everything in Essentials",
          "AI Assistant",
        ]}
        onSelect={props.onSelect}
        loading={props.loading}
      />
    </Stack>
  );
}


export interface CheckoutPageProps {
  isInDialog?: boolean;
  onReady?: () => void;
}


export default function CheckoutPage(props: CheckoutPageProps) {
  const checkoutSubscription = useCheckoutSubscriptionMutation({});

  const tryCheckout = async (plan: null | SubscriptionPlan = null) => {
    try {
      const result = await checkoutSubscription.mutateAsync({ plan: plan });
      window.location.href = result.url;
    } catch (e) {
      // failed to start checkout process
      const error = (e as any).response?.data?.detail?.error;
      if (error === 'select_plan') {
        setSelectPlans(true);
      } else {
        // failed because of some other reason, go back to login
        navigate('/login');
      }
    }
  };

  const [selectPlans, setSelectPlans] = useState(false);
  const handleSelectPlan = async (id: SubscriptionPlan) => {
    await tryCheckout(id);
  };

  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(async () => {
      await tryCheckout();
    }, 1000);
  }, []);


  const {data: whoamiData, isSuccess: whoamiSuccess} = useWhoamiQuery();
  const planStatus = whoamiData?.plan?.status;

  useEffect(() => {
    if (!props.isInDialog && whoamiSuccess && planStatus === 'active') {
      navigate('/photography/dashboard/home');
    }
  }, [whoamiSuccess, planStatus]);

  useEffect(() => {
    if (selectPlans)
      props.onReady?.();
  }, [selectPlans]);

  return (
    <Box>
      {!selectPlans && (
        <Typography>
          Please wait... &nbsp; <CircularProgress size="1rem" />
        </Typography>
      )}
      {selectPlans && (
        <>
          {planStatus === 'trial_expired' && (
            <Typography sx={{mb: 3}}>
              Your trial has come to an end.
            </Typography>
          )}

          {planStatus === 'expired' && (
            <Typography sx={{mb: 3}}>
              Your subscription is no longer active.
            </Typography>
          )}

          <PlanSelection onSelect={handleSelectPlan} loading={checkoutSubscription.isPending} />
        </>
      )}
    </Box>
  )
}