import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FormikHelpers, useFormik } from "formik";
import { useAskForgotPasswordMutation, useLoginMutation, useResendResetPasswordEmailMutation } from "../../api/auth";
import axios, { AxiosError } from "axios";
import { useAtom, useSetAtom } from "jotai";
import { axiosAuthenticatedAtom, loginStateAtom } from "../../state/auth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import successGif from "../../assets/images/success-animation.gif";
import { currentWorkflowAtom } from "../../components/MainAppBar/UserControlPopup";
import { useListWorkflowsQuery } from "../../api/workflows";
import { useQueryClient } from "@tanstack/react-query";
import { AskForgotPasswordParams, LoginParams } from "../../api/types";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { closeSnackbar, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import emailSent from "../../assets/icons/email-sent-icon.svg";
import { checkIsMobile } from "../../util/mobile";

interface LoginValues {
  email: string;
  password: string;
  remember_me: boolean;
}

const INITIAL_VALUES: LoginValues= {
  email: "",
  password: "",
  remember_me: false,
};

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function SignInPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const updateLoginState = useSetAtom(loginStateAtom);
  const updateAxiosAuthenticated = useSetAtom(axiosAuthenticatedAtom);
  const navigate = useNavigate();
  const [currentWorkflow, setCurrentWorkflow] = useAtom(currentWorkflowAtom);
  const { data: listData, isPending } = useListWorkflowsQuery({});
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [navigateBackToLogin, setNavigateBackToLogin] = useState(true);

  const queryClient = useQueryClient();
  const [goHome, setGoHome] = useState(false);
  const [goHome2, setGoHome2] = useState(false);

  const login = useLoginMutation({
    async onSuccess(data) {
      console.log("login success", data);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + data.access_token;
      updateAxiosAuthenticated(true);

      let expiresIn = new Date();
      if (!expiresIn) {
        expiresIn = new Date();
      }
      expiresIn.setSeconds(expiresIn.getSeconds() + data.expires_in);
      console.log("access token expires in", expiresIn);

      updateLoginState({
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        expiresIn,
      });
      // Manually trigger the refetch of the workflows data
      await queryClient.refetchQueries({queryKey: ["workflows-list"]});
      setGoHome2(true);
    },
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, LoginParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        setErrorMsg(detail ?? "");
      }
    },
  });

  useEffect(() => {
    if (listData?.workflows?.[0] && !isPending) {
      setCurrentWorkflow(listData?.workflows[0]);
      setGoHome(true);
    }
  }, [listData, isPending]);

  useEffect(() => {
    if (goHome && goHome2) {
      setGoHome(false);
      setGoHome2(false);
      navigate("/photography/dashboard/home");
    }
  }, [goHome, goHome2]);
  const formik = useFormik<LoginValues>({
    initialValues: INITIAL_VALUES,

    async onSubmit(values, { setSubmitting }) {
      await login.mutateAsync(values);
      setSubmitting(false);
    },
  });

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      formik.submitForm().then();
    }
  };

  const askForgotPassword = useAskForgotPasswordMutation({
    onSuccess(data) {
      console.log("ask forgot password success", data);
      setNavigateBackToLogin(false);
      startCountdown();
    },
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, AskForgotPasswordParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
          setErrorMsg(detail ?? "");
      }
    },
  });

  const { enqueueSnackbar } = useSnackbar();
  const resendResetPasswordEmail = useResendResetPasswordEmailMutation({
    onSuccess(data) {
      enqueueSnackbar("Email sent successfully", {
        variant: "success",
        action: (key) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
        style: {
          borderRadius: "0.7rem",
        },
      });
    },
    onError: (error: unknown) => {
      enqueueSnackbar("Failed sending email", {
        variant: "error",
        action: (key) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
        style: {
          borderRadius: "0.7rem",
        },
      });
    },
  });
  
  const [isResendClicked, setIsResendClicked] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);

  const countdownIntervalRef = useRef(null as any);
  const startCountdown = async () => {
    setIsResendClicked(true);
    let timer = timeLeft;
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }    countdownIntervalRef.current = setInterval(() => {
      if (timer <= 1) {
        clearInterval(countdownIntervalRef.current);
        setIsResendClicked(false);
        setTimeLeft(60);
      } else {
        timer--;
        setTimeLeft(timer);
      }
    }, 1000);
    
  }
  const resendEmail = async () => {
    await startCountdown();
    await resendResetPasswordEmail.mutateAsync({ email: forgotPasswordEmail });
  };

  const mobile = useMemo(() => checkIsMobile(), []);

  return login.isSuccess  && !navigateBackToLogin ? (
    <Box
      sx={{
        width: mobile ? "25rem" : "37rem",
        height: mobile ? "45rem" : "41rem",
        pt: 3,
        pb: 1,
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box component="img" src={successGif} width="25rem" />
    </Box>
  ) : (
    <Stack
      sx={{
        width: mobile ? "25rem" : "37rem",
        height: mobile ? "45rem" : "41rem",
        pt: 3,
        pb: 1,
        justifyContent: "center",
      }}
    >
      {openForgotPassword ? (
        askForgotPassword.isSuccess && !navigateBackToLogin ? (
          <>
           {/* <Stack direction="row" spacing={1}>
            <MarkEmailReadIcon />
            <Typography fontFamily="Helvetica Neue" variant="h3">
              Reset your password
            </Typography>
          </Stack>
          <Stack
            sx={{
              alignContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <br />
            <Typography>You're almost there! We sent an email to:</Typography>
            <Typography fontSize={"1.4rem"} fontWeight="bold">
              {forgotPasswordEmail}
            </Typography>
            <Typography>
              Please check your inbox and click on the link to change your password.
            </Typography>
          </Stack> */}
          {/* <Stack alignItems="center" sx={{ mt: "1rem" }}>
            <Button
              sx={{
                border: "0.1rem solid black",
                borderRadius: "2rem",
                px: "1rem",
                textTransform: "none",
                color: "black",
                bgcolor: "white",
                py: "0.3rem",
                fontWeight: "bold",
              }}
              onClick={resendEmail}
              disabled={isResendClicked}
            >
              Resend email {isResendClicked && `(${timeLeft})`}
            </Button>
          </Stack> */}
          <Stack
            sx={{
              alignContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Box
              component="img"
              src={emailSent}
              sx={{ mb: "-2rem", height: "15rem" }}
            />
            <Typography
              fontFamily="Helvetica Neue"
              variant="h3"
              fontSize="1.77rem"
              color="#69B3FF"
            >
              Reset your password
            </Typography>
            <br />
            <Typography>You're almost done! We sent an email to:</Typography>
            <Typography
              fontSize={"1.4rem"}
              fontWeight="bold"
              sx={{ mt: "0.4rem" }}
            >
              {forgotPasswordEmail}
            </Typography>
          </Stack>
          <Box sx={{ height: "5rem" }} />
          <Stack alignItems="center" sx={{ mt: "1rem" }}>
            <Button
              sx={{
                borderRadius: "2rem",
                px: "1rem",
                textTransform: "none",
                color: "#69B3FF",
                bgcolor: "transprent",
                py: "0.3rem",
                textDecoration: "underline",
                fontSize: "1.2rem",
              }}
              onClick={resendEmail}
              disabled={isResendClicked}
            >
              Resend email {isResendClicked && `(${timeLeft})`}
            </Button>
            <Typography
              onClick={() => setNavigateBackToLogin(true)}
              sx={{ color: "#8C5AFF", cursor: "pointer" }}
            >
              Back to login
            </Typography>
          </Stack>
          </>
          ) : (
        <>
          <Typography fontFamily="Helvetica Neue" variant="h2">
            Forgot your password?
          </Typography>
          {askForgotPassword.isError && (
            <Typography sx={{ color: "red" }}>{errorMsg}</Typography>
          )}
          <Box sx={{ height: "5rem "}} />
          <Typography sx={{ mt: 5, mb: 2 }}>Email</Typography>
          <TextField
            fullWidth
            disabled={login.isPending}
            sx={{
              "& *": {
                borderColor: "#c7a5ff",
                borderRadius: "1.7143rem",
              },
            }}
            onChange={(event) => {
              setForgotPasswordEmail(event.target.value);
            }}
          />
          <Typography
            textAlign="center"
            fontFamily="Helvetica Neue"
            sx={{ mt: 2, color: "#8852ff", cursor: "pointer" }}
            onClick={() => setOpenForgotPassword(false)}
          >
            Back to login
          </Typography>
          <Box sx={{ height: "6.6rem "}} />

          <Button
            fullWidth
            variant="contained"
            sx={{
              my: 2,
              textTransform: "capitalize",
              background: "linear-gradient(90deg, #6ab4ff 0%, #c2a6ff 100%)",
              fontFamily: "Helvetica Neue",
              fontSize: "1.5rem",
              borderRadius: "1.7143rem",
              py: 1.5,
              boxShadow: "0px 7px 8px #999",
            }}
            disabled={askForgotPassword.isPending }
            onClick={async () => await askForgotPassword.mutateAsync({email: forgotPasswordEmail})}
          >
            {/*Continue*/}
            {askForgotPassword.isPending ? (
              <CircularProgress />
            ) : (
              "Continue"
            )}
          </Button>
          <Box sx={{ height: "5rem "}} />

        </>
      )
      ) : (
        <Stack width={mobile ? "30rem" : "37rem"} ml={mobile ? -3 : 0}>
          <Typography fontFamily="Helvetica Neue" variant="h2" fontSize={mobile ? "24px" : undefined} mt={mobile ? -3 : undefined}>
            Sign in
          </Typography>

          {login.isError && (
            <Typography sx={{ color: "red" }}>{errorMsg}</Typography>
          )}

          <Typography
            sx={{ mt: 3, mb: 1 }}
            fontSize={mobile ? "14px" : undefined} 
            fontFamily={mobile ? '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif' : undefined}
            fontWeight={mobile ? "500" : undefined}
          >Email</Typography>
          <TextField
            fullWidth
            {...formik.getFieldProps("email")}
            disabled={login.isPending}
            InputProps={{
              sx: {
                height: "4rem",
              },
            }}
            sx={{
              "& *": {
                borderColor: "#c7a5ff",
                borderRadius: "1.7143rem",
              },
            }}
          />

          <Typography
            sx={{ mt: mobile ? 1.5 : 3, mb: 1 }}
            fontSize={mobile ? "14px" : undefined} 
            fontFamily={mobile ? '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif' : undefined}
            fontWeight={mobile ? "500" : undefined}
          >Password</Typography>
          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            {...formik.getFieldProps("password")}
            disabled={login.isPending}
            onKeyUp={handleKeyUp}
            sx={{
              "& *": {
                borderColor: "#c7a5ff",
                borderRadius: "1.7143rem",
              },
            }}
            InputProps={{
              sx: {
                height: "4rem",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    <Box
                      width={"1.4286rem"}
                      height={"1.4286rem"}
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon sx={{ color: "#00000055" }} />
                      ) : (
                        <VisibilityIcon sx={{ color: "#00000055" }} />
                      )}
                    </Box>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Typography
            textAlign="center"
            fontFamily="Helvetica Neue"
            sx={{ mt: 2, color: "#8852ff", cursor: "pointer" }}
            fontSize={mobile ? "14px" : undefined} 
            fontWeight={mobile ? "500" : undefined}
            onClick={() => setOpenForgotPassword(true)}
          >
            Forgot your password?
          </Typography>

          <FormControlLabel
            control={<Checkbox />}
            label="Remember me"
            sx={{ mt: 1 }}
            disabled={login.isPending}
            onChange={(e) => {
              formik.setFieldValue("remember_me", (e.target as HTMLInputElement).checked);
            }}
            value={formik.values.remember_me}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{
              my: 2,
              textTransform: "capitalize",
              background: "linear-gradient(90deg, #6ab4ff 0%, #c2a6ff 100%)",
              fontFamily: "Helvetica Neue",
              fontSize: "1.5rem",
              borderRadius: "1.7143rem",
              py: mobile ? 1 : 1.5,
              boxShadow: "0px 7px 8px #999",
            }}
            disabled={login.isPending || login.isSuccess}
            onClick={() => formik.submitForm()}
          >
            {/*Continue*/}
            {login.isPending || login.isSuccess ? (
              <CircularProgress />
            ) : (
              "Continue"
            )}
          </Button>
          
          {/* <Button
            variant="contained"
            sx={{
              mt: 1,
              textTransform: "capitalize",
              background: "linear-gradient(90deg, #6ab4ff 0%, #c2a6ff 100%)",
              fontFamily: "Helvetica Neue",
              fontSize: "1.5rem",
              borderRadius: "1.7143rem",
              py: 1.5,
              boxShadow: "0px 7px 8px #999",
            }}
            disabled={loginWithGoogle.isPending || loginWithGoogle.isSuccess}
            onClick={() => loginWithGoogle.mutateAsync({})}
          >
            {loginWithGoogle.isPending || loginWithGoogle.isSuccess ? (
              <CircularProgress />
            ) : (
              "Sign in with Google"
            )}
          </Button> */}

          <Box textAlign="center" sx={{ mt: mobile ? 3 : 5 }}>
            <Typography component="span" fontFamily="Helvetica Neue">
              Don't have an account?
            </Typography>
            <Typography
              onClick={() => navigate("/signup")}
              component="span"
              fontFamily="Helvetica Neue"
              sx={{ ml: 1, color: "#8852ff", cursor: "pointer" }}
            >
              Sign up
            </Typography>
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
