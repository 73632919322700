import { Link, Popover, Stack, Typography } from "@mui/material";
import { useEffect, useState, useMemo } from 'react';
import { useListExpensesQuery, useDeleteExpenseMutation } from '../../api/expenses';
import { ListExpensesResponse, Expense, SearchOption, SortOption, FilterOption, PaymentMethod, ExpensesCategories } from '../../api/types';
import ViewExpenseDialog from '../../dialogs/ViewExpenseDialog';
import { EditExpenseDialog } from "../../dialogs/EditExpense";
import {
  SimpleTableCard,
  TableType,
  SimpleTableColumn,
  SimpleTableColumnType
} from "../SimpleTable";
import ThreeDotsButton from "../ThreeDotsButton";
import ConfirmActionDialog from "../ConfirmActionDialog";
import { PaymentMethodLabel } from "../PaymentMethodLabel";
import { SORT_EXPENSES_OPTIONS } from "../ChipSelect/commonOptions";
import { useAtomValue } from "jotai";
import { currentWorkflowAtom } from "../MainAppBar/UserControlPopup";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { makePath, WorkflowPath, PATHS } from "../../paths";
import { useGetClientMutation } from "../../api/clients";

const PAGE_SIZE = 10;

const EXPENSES_BASE_COLUMNS: SimpleTableColumn[] = [
  {
    key: "description",
    label: "Description",
    type: SimpleTableColumnType.NAME
  },
  {
    key: "amount",
    label: "Amount",
    type: SimpleTableColumnType.USD,
  },
  {
    key: "date",
    label: "Date",
    type: SimpleTableColumnType.DATE,
  },
];

enum threeDotsOptions {
  EDIT = "edit-client",
  DELETE = "delete-client",
}

const DOTS_MENU = [
  {
    id: threeDotsOptions.EDIT,
    label: "Edit",
  },
  {
    id: threeDotsOptions.DELETE,
    label: "Delete",
  },
];

export default function ExpensesInfoTable () {
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState<SearchOption>({ query: "" });
    const [filter, setFilter] = useState<FilterOption>({ by: "", query: "" });
    const [sort, setSort] = useState<SortOption>({ by: "", descending: false });
    const [openedDelete, setOpenedDelete] = useState<boolean>(false);
    const [openedEdit, setOpenedEdit] = useState<boolean>(false);
    const [openedView, setOpenedView] = useState<boolean>(false);
    const [selectedExpense, setSelectedExpense] = useState<Expense | undefined>();
    const [clientDataMap, setClientDataMap] = useState<Record<string, string>>({});

    const deleteExpenseMutation = useDeleteExpenseMutation();
    const getClientMutation = useGetClientMutation({});

    const { data, isPending } = useListExpensesQuery({
      retrieve_options: {
        search: search,
        filter: filter,
        sort: sort
      },
      page_size: PAGE_SIZE,
      page_index: page,
    });
    
    const workflowType = useAtomValue(currentWorkflowAtom)?.type;

    const defaultColumns = useMemo(() => {
      return [...EXPENSES_BASE_COLUMNS].map((column) => column.key);
    }, [workflowType]);

    const [selectedColumns, setSelectedColumns] = useState<string[]>(defaultColumns);
    const [current, setCurrent] = useState<null | ListExpensesResponse>(null);

    const navigate = useNavigate();

    useEffect(() => {
      if (!isPending && data) {
        setCurrent(data);
      }
    }, [data, isPending]);

    useEffect(() => {
      if (current?.expenses) {
        const clientUuids = current.expenses.map((expense) => expense.client).filter(Boolean);
  
        const fetchClients = async () => {
          const newClientDataMap: Record<string, string> = {};
  
          for (const uuid of clientUuids) {
            if (!uuid) continue;

            try {
              const response = await getClientMutation.mutateAsync({ uuid });
              if (response.client) {
                newClientDataMap[uuid] = response.client.full_name;
              }
            } catch (error) {
              console.error(`Failed to fetch client data for uuid: ${uuid}`, error);
            }
          }
          
          setClientDataMap(newClientDataMap);
        };
  
        fetchClients();
      }
    }, [current]);
  
    const handleViewExpense = (expense: Expense) => {
      setSelectedExpense(expense);
      setOpenedView(true);
    };

    const handleDeleteExpense = () => {
      if (!selectedExpense || !selectedExpense.uuid) return;
    
      deleteExpenseMutation.mutate({uuid: selectedExpense.uuid}, {
        onSuccess: () => {
          setTimeout(() => {
            setOpenedDelete(false);
          }, 70);
        }
      });
    };

    const additionalColumnsFromData = useMemo(() => {
      if (!data || !data.expenses || data.expenses.length === 0) return [];
      const dynamicKeys = new Set<string>();
    
      data.expenses.forEach((expense: any) => {
        Object.keys(expense).forEach((key) => {
          const value = expense[key];
          if (!key.includes("uuid")) {
            dynamicKeys.add(key);
          }
        });
      });
    
      // Remove keys that are already in baseColumns to avoid duplicates
      return [...dynamicKeys].filter((key) => !defaultColumns.includes(key));
    }, [data, defaultColumns]);

    const allColumns = useMemo(() => {
      const result = [...EXPENSES_BASE_COLUMNS];

      const extraColumns = [
        {
          key: "info",
          label: "",
          render(_: unknown, row: any) {
            return (
              <Stack direction="row" alignItems="center" spacing={3}>
                <ThreeDotsButton
                  menu={DOTS_MENU}
                  onClickItem={(option) => {
                    setSelectedExpense(row);
                    switch (option) {
                      case threeDotsOptions.EDIT:
                        setOpenedEdit(true);
                        break;
                      case threeDotsOptions.DELETE:
                        setOpenedDelete(true);
                        break;
                      default:
                        break;
                    }
                  }}
                  children={undefined}
                  sx={{mt: "0.5rem"}}
                />
              </Stack>
            );
          },
        },
      ];

      const dynamicColumns = additionalColumnsFromData.map((key) => ({
        key,
        label: (key.charAt(0).toUpperCase() + key.slice(1)).replace("_", " "), // Format label
        render: (value: any) => {
          // Check if the value is a date in ISO format
          const isDate = typeof value === "string" && !isNaN(Date.parse(value));
          
          // If it's a date, format it
          if (isDate) {
            const date = DateTime.fromISO(value); // Use Luxon to parse the ISO string
            return (
              <Stack direction="row">
                <Typography fontSize={"1.15rem"} color="#404040" fontWeight="bold">
                  {date.toFormat("EEEE")}, {/* e.g. Wednesday */}
                </Typography>
                <Typography fontSize={"1.15rem"} color="#404040B2">
                  &nbsp;{date.toFormat("LLL dd, yyyy")} {/* e.g. Oct 16, 2024 */}
                </Typography>
              </Stack>
            );
          }

          if (key === "client") {
            console.log(clientDataMap)
            const handleViewContact = (uuid: string) => {
              navigate(
                makePath(
                  `/${workflowType}` as WorkflowPath,
                  PATHS.viewContact(uuid).general
                )
              );
            };

            return (
              <Typography
                variant="body2"
                sx={{ color: clientDataMap[value] ? "#75B3FF" : "", cursor: clientDataMap[value] ? "pointer" : "" }}
                onClick={() => handleViewContact(value)}
              >
                {clientDataMap[value] ?? "N/A"}
              </Typography>
            );
          }
      
          // Render the value or "N/A" if it's not a date or is empty
          return <Typography>{value === 0 ? 0 : value || "N/A"}</Typography>;
        },
      }));

      return [...result, ...dynamicColumns, ...extraColumns];
    }, [workflowType, additionalColumnsFromData, navigate, clientDataMap]);

    const columns = useMemo(() => {
      const essentialColumns = ["info"];
      const visibleColumns = selectedColumns.length === 0 ? allColumns : allColumns.filter((col) => selectedColumns.includes(col.key));
      return [...new Set([...visibleColumns, ...allColumns.filter(col => essentialColumns.includes(col.key))])];
    }, [selectedColumns, allColumns]);
  
    const mainColumns = useMemo(() => {
      return columns.filter(col => !["info"].includes(col.key));
    }, [columns]);
  
    const columnWidth = useMemo(() => {
      const totalMainColumns = mainColumns.length;
      return `${100 / totalMainColumns}%`;
    }, [mainColumns]);

    const allColumnsWithoutInfo = allColumns.filter((col) => col.key !== "info");

    return (
      <>
        <ViewExpenseDialog
          expense={selectedExpense}
          open={openedView}
          onClose={() => {
            setOpenedView(false)
            setSelectedExpense(undefined)
          }}
        />

        <EditExpenseDialog
          open={openedEdit}
          onClose={() => {
            setOpenedEdit(false)
            setSelectedExpense(undefined)
          }}
          expense={selectedExpense}
        />

        <ConfirmActionDialog
          open={openedDelete}
          what="expense"
          whatAction="delete"
          onClose={() => {
            setOpenedDelete(false)
            setSelectedExpense(undefined)
          }}
          onConfirm={() =>
            selectedExpense
              ? handleDeleteExpense()
              : undefined
          }
          loading={deleteExpenseMutation.isPending}
          prefix="an"
        >
          Delete
        </ConfirmActionDialog>

        <SimpleTableCard
          title={"Expenses info"}
          data={current?.expenses}
          columns={columns}
          tableType={TableType.EXPENSES}
          selectedPage={page}
          setSelectedPage={setPage}
          pageSize={PAGE_SIZE}
          numOfPages={current?.num_of_pages ?? 1}
          total={current?.total ?? 0}
          isPending={isPending}
          onClickLink={(row) => handleViewExpense(row)}
          searchState={[search, setSearch]}
          filterState={[filter, setFilter]}
          sortState={[sort, setSort]}
          sortOptions={SORT_EXPENSES_OPTIONS}
          columnDivision={Array(columns.length).fill(columnWidth)}
          allColumnsWithoutInfo={allColumnsWithoutInfo}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          downloadToCsv={true}
        />
    </>
  );
}