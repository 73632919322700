import { MynkFormControlCommonProps } from './types'
import { Box, Typography } from '@mui/material'
import { BoxWithGradientFrame } from '../../GradientFrame'
import MuiPhoneNumber from 'mui-phone-number'
import { useFormikContext } from 'formik'
import { useIsInEditable } from '../context'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          maxHeight: '14.2857rem', // Limit dropdown height
        },
      },
    },
  },
});

export interface MynkFormPhoneNumberProps extends MynkFormControlCommonProps<string> {}

export function MynkFormPhoneNumber(props: MynkFormPhoneNumberProps) {
  const formik = useFormikContext();
  const isInEditable = useIsInEditable();

  return (
    <Box>
      {!isInEditable && (
        <Typography variant="subtitle1">
          {props.label}
        </Typography>
      )}

      <BoxWithGradientFrame>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '4.5rem',
            pl: 1.5,

            "& .MuiPaper-root": {
              width: '20rem !important',
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <MuiPhoneNumber
              label=""
              data-cy="user-phone"
              defaultCountry="au"
              sx={{
                flexGrow: 1,  // Make the input field take available space
                "& .MuiInputBase-root": {
                  height: "100%",
                },
                "& .MuiList-root": {
                  maxHeight: "14.2857rem",
                },
              }}
              InputProps={{
                disableUnderline: true,
                sx: {
                  fontFamily: "Helvetica Neue",
                },
              }}
              value={formik.values[props.name]}
              onChange={(value) => {
                formik.setFieldValue(props.name, value)
              }}
              onBlur={() => formik.handleBlur(props.name)}
            />
          </ThemeProvider>
        </Box>
      </BoxWithGradientFrame>
    </Box>
  );
}
