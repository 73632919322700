import {Button, CircularProgress, SxProps} from "@mui/material";
import {ArrowForward} from "@mui/icons-material";
import React, {ReactNode} from "react";


export interface DialogActionButtonProps {
  type?: 'button' | 'submit' | undefined;
  children: ReactNode;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  sx?: SxProps;
}

export default function DialogActionButton(props: DialogActionButtonProps) {
  return (
    <Button
      type={props.type}
      variant="contained"
      endIcon={!props.loading && <ArrowForward />}
      sx={{
        minWidth: "8rem",
        minHeight: "3.3rem",
        borderRadius: '5rem',
        background: 'linear-gradient(90deg, #59b5d9, #6a91f2)',
        textTransform: "none",
        fontFamily: 'Helvetica Neue',
        fontSize: "1.25rem",
        mb: 2,
        ...props.sx,
      }}
      onClick={() => props.onClick?.()}
      disabled={props.loading || props.disabled}
    >
      {props.loading ? <CircularProgress size={20} sx={{ my: 1 }}/> : props.children}
    </Button>
  )
}