import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  BATHROOM_OPTIONS,
  BEDROOM_OPTIONS,
  ENTRANCE_DATE_OPTIONS,
  LISTING_TYPE_OPTIONS,
  SIZE_UNITS_OPTIONS,
} from "../components/ChipSelect/commonOptions";
import { useNavigate } from "react-router-dom";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import { closeSnackbar, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

import propertyAreaIcon from "../assets/icons/property-area-icon.svg";
import bedroomIcon from "../assets/icons/bedroom-icon.svg";
import bathroomIcon from "../assets/icons/bathroom-icon.svg";
import uploadFilesIcon from "../assets/icons/upload-files-icon.svg";
import { PATHS, WorkflowPath, makePath } from "../paths";
import { useAddRealEstateListingMutation } from "../api/projects";
import { EntranceDateTypeOptions, FileObj, WorkflowType } from "../api/types";
import MynkFormConditional from "../components/MynkForm/MynkFormConditional";
import { DateTime } from "luxon";
import MynkFormEditable from "../components/MynkForm/MynkFormEditable";

export interface AddListingDialog {
  open: boolean;
  onClose: () => void;
  contactId?: string;
}

export function AddListingDialog(props: AddListingDialog) {
  const addListing = useAddRealEstateListingMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    try {
      console.log(values);
      const result = await addListing.mutateAsync({
        type: values["type"],
        location: values["location"],
        price: Number(values["price"]),
        size: Number(values["size"]),
        size_units: values["sizeUnits"],
        bedrooms: Number(values["bedrooms"]),
        bathrooms: Number(values["bathrooms"]),
        description: values["description"] ?? "",
        entrance_date:
          values["entranceDate"] == EntranceDateTypeOptions.READY_TO_MOVE
            ? EntranceDateTypeOptions.READY_TO_MOVE
            : values["entranceSpecificDate"],
        owner_uuid: values["ownerId"],
        name:
          values["listingName"] == ""
            ? values["location"]
            : values["listingName"],
        photos: values["photos"] == "" ? [] : values["photos"],
      });

      console.log(
        "----------------------------------------------------------------"
      );
      console.log("result", result);
      if (result?.uuid != "") {
        navigate(
          makePath(
            WorkflowPath.REAL_ESTATE,
            PATHS.viewListing(result.uuid).general
          )
        );
      } else {
        enqueueSnackbar("Error adding listing, did you fill all fields?", {
          variant: "error",
          action: (key) => (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
          style: {
            borderRadius: "0.7rem",
          },
        });
      }
    } catch {}
    props.onClose();
  };

  const handleFilesChange = (files: FileObj[] | null) => {
    // Handle the selected files (e.g., upload to server)
    if (files) {
      for (let i = 0; i < files.length; i++) {
        console.log(`Selected File ${i + 1}:`, files[i]);
      }
    }
  };

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      title="New listing"
      action="Add listing"
      onSubmit={handleSubmit}
      loading={addListing.isPending}
    >
      <Stack direction="row" sx={{ width: "71.4286rem", maxHeight: "33rem" }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.CHIP_SELECT}
              name="type"
              label="Type"
              default="for_rent"
              options={LISTING_TYPE_OPTIONS}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="location"
              label="Property's location"
              placeholder="Enter property's location"
              autoComplete={false}
              default=""
            />
          </Grid>

          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="price"
              label="Price "
              placeholder="Enter price"
              autoComplete={false}
              isNum={true}
              default={0}
            />
          </Grid>

          <Grid item xs={3}>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="size"
              label="Size"
              placeholder="Enter size"
              autoComplete={false}
              isNum={true}
              default={0}
            />
          </Grid>

          <Grid item xs={6} sx={{ mt: 5, ml: 1}}>
            <Stack direction="row" spacing={1}>
              <MynkFormControl
                kind={MynkFormControlKind.ICONED_CHIP_SELECT}
                name="sizeUnits"
                label=""
                default="sqft"
                options={SIZE_UNITS_OPTIONS}
                icon={propertyAreaIcon}
              />
              <MynkFormControl
                kind={MynkFormControlKind.ICONED_CHIP_SELECT}
                name="bedrooms"
                label="Beds"
                default="1"
                options={BEDROOM_OPTIONS}
                icon={bedroomIcon}
              />
              <MynkFormControl
                kind={MynkFormControlKind.ICONED_CHIP_SELECT}
                name="bathrooms"
                label="Baths"
                default="1"
                options={BATHROOM_OPTIONS}
                icon={bathroomIcon}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="description"
              label="Property's description"
              placeholder="Enter property's description"
              autoComplete={false}
              multiLine={true}
              height={150}
              default=""
            />
          </Grid>
        </Grid>

        <Divider orientation="vertical" sx={{ ml: 5, mr: 5 }} flexItem />

        <Grid container spacing={2} alignItems="center" alignContent="start">
          <Grid item xs={12}>
            <Stack spacing={1}>
              <MynkFormControl
                kind={MynkFormControlKind.CHIP_SELECT}
                name="entranceDate"
                label="Entrance date"
                default="ready_to_move"
                options={ENTRANCE_DATE_OPTIONS}
              />
              <MynkFormConditional
                ifWhat={"entranceDate"}
                equalTo={EntranceDateTypeOptions.SPECIFIC_DATE}
              >
                <MynkFormEditable>
                  <MynkFormControl
                    kind={MynkFormControlKind.DATE_SELECT}
                    name="entranceSpecificDate"
                    label=""
                    default={DateTime.now()}
                  />
                </MynkFormEditable>
              </MynkFormConditional>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.CONTACT_SEARCH}
              name="ownerId"
              label="Owner (Only visible to you)"
              placeholder="Enter owners's name"
              default={props.contactId ?? ""}
              workflowType={WorkflowType.REAL_ESTATE}
            />
          </Grid>

          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="listingName"
              label="Listing's name (Optional)"
              placeholder="Enter property's name"
              autoComplete={false}
              default={""}
            />
          </Grid>

          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.FILE_UPLOAD}
              name="photos"
              label="Add photos"
              default={[]}
              onFilesChange={handleFilesChange}
            />
          </Grid>
        </Grid>
      </Stack>
    </MynkFormDialog>
  );
}
