import { Box, Menu, MenuItem, SxProps, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import editIcon from "../../assets/icons/edit-icon.svg";
import deleteIcon from "../../assets/icons/delete-icon.svg";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import threeDotsIcon from "../../assets/icons/three-dots-icon-no-margin.svg";

enum threeDotsOptions {
  EDIT = "edit",
  DELETE = "delete",
  DOWNLOAD = "download"
}

// A single entry of a 3-dots option - label is the text will be presented in the UI:
export interface ThreeDotsEntry {
  id: string;
  label: string;
}

// Properties for the ThreeDotsButton component:
export interface ThreeDotsButtonProps {
  children: ReactNode;
  menu?: ThreeDotsEntry[];
  onClickItem?: (id: string) => void;
  sx?: SxProps;
}

/**
 * Renders a button with three dots that opens a dropdown menu with options.
 * When an option is clicked, the onClickItem callback is called with the selected option's ID.
 *
 * @param {ThreeDotsButtonProps} props - The props object containing the following properties:
 *   - children (ReactNode): The content to be rendered inside the button.
 *   - menu (array): An array of objects representing the options in the dropdown menu. Each object should have an id (string) and a label (string).
 *   - onClickItem (function): An optional callback function that is called when an option is clicked. It receives the selected option's ID as a parameter.
 *   - sx (object): An optional object containing style props for customizing the button's appearance.
 * @returns {ReactNode} The rendered React component.
 */
export default function ThreeDotsButton(props: ThreeDotsButtonProps) {
  // 'anchorEl' purpose is to mention the position where the menu will be open after 3-dots clicked:
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      {/* If menu property provided - render a menu: */}
      {props.menu && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          sx={{
            ml: -9,
            "& ul > li": {
              paddingRight: 5,
            },
          }}
        >
          {/* For each option render a '<MenuItem/>' with a unique key (id) */}
          {props.menu.map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => {
                setAnchorEl(null);
                props.onClickItem?.(item.id);
              }}
            >
              {/* Display icons based on the id - pencil if edit, trash-bin if delete */}
              {(() => {
                if (item.id.startsWith(threeDotsOptions.EDIT)) {
                  return <Box component="img" src={editIcon} width="0.9rem" height="0.95rem" />;
                } else if (item.id.startsWith(threeDotsOptions.DELETE)) {
                  return <Box component="img" src={deleteIcon} width="0.9rem" height="0.95rem"/>;
                } else if (item.id.startsWith(threeDotsOptions.DOWNLOAD)) {
                  return <FileDownloadIcon sx={{ color: "#7c7c7c", ml: "-0.2rem", mr: "-0.4rem" }} fontSize="small" />
                }
              })()}
              <Typography sx={{ ml: 1.3, fontWeight: "regular", color: "#848484" }}>{item.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
      )}

      {/* The 3-dots icon */}

      <Box component="img" src={threeDotsIcon}
        sx={{
          width: "1.3rem",
          height: "1.3rem",
          alignSelf: "center",
          cursor: "pointer",
          ...props.sx
        }}
        // Open the dropdown menu (if there are no items - refer the click on empty string)
        onClick={(e) =>
          props.menu
            ? setAnchorEl(e.currentTarget as unknown as HTMLElement)
            : props.onClickItem?.("")
        }
      />
    </>
  );
}
