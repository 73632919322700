import {useState, useEffect} from 'react'
import { Stack, Divider, Box, TextField, IconButton, Typography } from '@mui/material'
import { MynkFormDialog } from '../components/MynkForm'
import { BoxWithGradientFrame } from '../components/GradientFrame'
import { ChipSelect } from '../components/ChipSelect'
import { DURATION_OPTIONS, LOCATIONS_OPTIONS, BLOCK_OFF_TIME_OPTIONS, MINIMUM_NOTICE_TIME } from '../components/ChipSelect/commonOptions'
import CheckRadioButton from '../assets/icons/check-radio-button.svg'
import UncheckRadioButton from '../assets/icons/uncheck-radio-button.svg'
import InformationIcon from '../assets/icons/information-icon.svg'
import RightArrow from '../assets/icons/arrow-right-show-more.svg'
import DownArrow from '../assets/icons/arrow-down-show-more-opened.svg'
import { useAddMeetingTypeMutation } from '../api/scheduler'
import { AddEventParams, AddMeetingTypeParams, MeetingType } from '../api/types'
import {AxiosError} from "axios";
import { MynkFormControl } from '../components/MynkForm'
import { MynkFormControlKind } from '../components/MynkForm/controlTypes'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

type AddMeetingTypeProps = {
    meetingTypes: Array<MeetingType>
    setMeetingTypes: React.Dispatch<React.SetStateAction<Array<MeetingType>>>
    open: boolean
    onClose: () => void
}

type NewMeetingTypeProps = {
    newMeetingType: MeetingType
    setNewMeetingType: React.Dispatch<React.SetStateAction<MeetingType>>
}

type AdditionalInputProps = {
    placeholder: string
    inputName: 'name' | 'price' | 'description'
}

type InputProps = NewMeetingTypeProps & AdditionalInputProps

function Input({newMeetingType, setNewMeetingType, placeholder, inputName}: InputProps) {
    return (
        <BoxWithGradientFrame>
            <Box sx={{
                    width: '10rem',
                    height: inputName == 'description' ? '7rem' : '3.5rem',
                    marginRight: '0.5rem',
                    marginTop: '0.2rem',
                    marginLeft: '1rem'
                }}
            >
                <TextField
                    variant="standard"
                    placeholder={placeholder}
                    fullWidth
                    InputProps={{
                        disableUnderline: true,
                        sx: {
                            height: '3.5rem',
                            width: '22rem',
                        },
                    }}
                    onChange={(e) => {
                        if (!newMeetingType || !setNewMeetingType) {
                            return
                        }

                        newMeetingType[inputName] = e.target.value;
                        setNewMeetingType(newMeetingType)
                    }}
                />
            </Box>
        </BoxWithGradientFrame>
    )
}

export default function AddMeetingType({ meetingTypes, setMeetingTypes, open, onClose }: AddMeetingTypeProps) {
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
    const [isPublic, setIsPublic] = useState<boolean>(true)
    const [blockOffTimeBefore, setBlockOffTimeBefore] = useState<string>('0 min')
    const [blockOffTimeAfter, setBlockOffTimeAfter] = useState<string>('0 min')
    const [minimumNoticeTime, setMinimumNoticeTime] = useState<string>('0 min')

    const handleRadioButtonClick = () => {
        setIsPublic(!isPublic)
    }

    const addMeetingType = useAddMeetingTypeMutation({
        onError: (error: unknown) => {
            if (error instanceof Error && "response" in error) {
                const axiosError = error as AxiosError<unknown, AddMeetingTypeParams>;
                const detail = (axiosError.response?.data as { detail?: string })
                ?.detail;
                setErrorMsg(detail ?? "");
            }
        }
    })

    const handleSubmit = async (values: any) => {
        if (!values['name'] || !values['duration'] || !values['location']) {
            return
        }

        const newMeetingType: MeetingType = {
            name: values['name'],
            duration: values['duration'],
            location: values['location'],
            isPublic: isPublic,
            price: values['price'],
            description: values['description'],
            blockOffTimeBefore: blockOffTimeBefore,
            blockOffTimeAfter: blockOffTimeAfter,
            minimumNoticeTime: minimumNoticeTime,
        }

        const result = await addMeetingType.mutateAsync(newMeetingType);

        newMeetingType.uuid = result.uuid;
        setMeetingTypes([...meetingTypes, newMeetingType])
        onClose();
    };
    
    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: '1rem',
          borderRadius: '10px',
          fontStyle: 'normal',
          fontFamily: 'Helvetica Neue',
          fontWeight: 600,
          padding: '0.5rem',
        },
    }));

    return (
        <MynkFormDialog
            open={open}
            onClose={onClose}
            actionDisabled={false}
            onSubmit={handleSubmit}
            loading={addMeetingType.isPending}
            action='Set meeting type'
        >
            <Stack flexDirection={'row'}>
                <Stack sx={{width: '28rem'}}>
                    <h1 style={{fontSize: '2rem'}}>New meeting type</h1>
                    <MynkFormControl
                        kind={MynkFormControlKind.TEXT}
                        name="name"
                        label="Name"
                        placeholder="Enter meeting type's name"
                        autoComplete={false}
                        default=""
                    />
                    <Box sx={{marginTop: '1.5rem'}}>
                        <MynkFormControl
                            kind={MynkFormControlKind.CHIP_SELECT}
                            name="duration"
                            label="Duration"
                            options={DURATION_OPTIONS}
                            default="30 min"
                        />
                    </Box>
                    <Box sx={{marginTop: '0.5rem'}}>
                        <MynkFormControl
                            kind={MynkFormControlKind.CHIP_SELECT}
                            name="location"
                            label="Location"
                            options={LOCATIONS_OPTIONS}
                            default="None"
                        />
                    </Box>
                    <Stack flexDirection={'row'} sx={{marginTop: '1rem'}}>
                        <IconButton
                            sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
                            onClick={() => isPublic && handleRadioButtonClick()}
                        >
                            <Box
                                src={isPublic ? UncheckRadioButton : CheckRadioButton}
                                component='img'
                                sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
                            />
                        </IconButton>
                        <span style={{fontSize: '1.2rem', marginLeft: '0.6rem', marginRight: '1rem'}}>Private</span>
                        <LightTooltip
                            title={"No one can see or book this meeting."}
                            placement='top'
                        >
                            <Box
                                src={InformationIcon}
                                component='img'
                                sx={{width: '1.2rem', height: '1.2rem', marginTop: '0.1rem', cursor: 'pointer'}}
                            />
                        </LightTooltip>
                    </Stack>
                    <Stack flexDirection={'row'} sx={{marginTop: '1rem'}}>
                        <IconButton
                            sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
                            onClick={() => isPublic && handleRadioButtonClick()}
                        >
                            <Box
                                src={isPublic ? CheckRadioButton : UncheckRadioButton}
                                component='img'
                                sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
                            />
                        </IconButton>
                        <span style={{fontSize: '1.2rem', marginLeft: '0.6rem',  marginRight: '1.55rem'}}>Public</span>
                        <LightTooltip
                            title={"Everyone with the link can see and book this meeting."}
                            placement='top'
                        >
                            <Box
                                src={InformationIcon}
                                component='img'
                                sx={{width: '1.2rem', height: '1.2rem', marginTop: '0.1rem', cursor: 'pointer'}}
                            />
                        </LightTooltip>
                    </Stack>
                </Stack>

                <Divider orientation='vertical' flexItem sx={{marginX: '3rem', marginTop: '3rem', height: '25rem'}} />

                <Stack sx={{width: '28rem', marginTop: '5rem'}}>
                    <MynkFormControl
                        kind={MynkFormControlKind.TEXT}
                        name="price"
                        label="Price"
                        placeholder="Enter meeting type price"
                        autoComplete={false}
                        default=""
                    />
        
                    <MynkFormControl
                        kind={MynkFormControlKind.TEXT}
                        name="description"
                        label="Description"
                        placeholder="Enter Meeting Type's description"
                        autoComplete={false}
                        multiLine={true}
                        default=""
                        height={100}
                    />
        
                    <Typography style={{fontSize: '1.2rem', marginTop: '1.5rem', marginBottom: '0.5rem', marginRight: '1rem'}}>Block off time</Typography>
                    <Stack flexDirection={'row'} justifyContent={'space-between'}>
                        <Typography>Before: </Typography>
                        <ChipSelect options={BLOCK_OFF_TIME_OPTIONS} value={blockOffTimeBefore} ignoreCentering={true}
                            sx={{
                                borderRadius: '20px',
                                backgroundColor: '#FFF',
                                boxShadow: '0px 0px 3px 0px rgba(117, 179, 255, 0.50)',
                                width: '7rem',
                                marginBottom: '0.5rem',
                                marginLeft: '2rem',
                            }}
                            onChange={(e) => {
                                setBlockOffTimeBefore(e);
                            }}
                        ></ChipSelect>
                        
                        <Typography>After: </Typography>
                        <ChipSelect options={BLOCK_OFF_TIME_OPTIONS} value={blockOffTimeAfter} ignoreCentering={true}
                            sx={{
                                borderRadius: '20px',
                                backgroundColor: '#FFF',
                                boxShadow: '0px 0px 3px 0px rgba(117, 179, 255, 0.50)',
                                width: '7rem',
                                marginBottom: '0.5rem',
                                marginLeft: '2rem',
                            }}
                            onChange={(e) => {
                                setBlockOffTimeAfter(e);
                            }}
                        ></ChipSelect>
                    </Stack>
                    <Stack flexDirection={'row'} sx={{marginTop: '2rem'}}>
                        <span style={{fontSize: '1.2rem', marginRight: '0.7rem'}}>Minimum notice time</span>
                        <LightTooltip
                            title={"Clients will be able to book with you no later than this time prior the meeting"}
                            placement='top'
                        >
                            <Box
                                src={InformationIcon}
                                component='img'
                                sx={{width: '1.2rem', height: '1.2rem', marginTop: '0.1rem', cursor: 'pointer'}}
                            />
                        </LightTooltip>
                        <ChipSelect options={MINIMUM_NOTICE_TIME} value={minimumNoticeTime} ignoreCentering={true}
                            sx={{
                                borderRadius: '20px',
                                backgroundColor: '#FFF',
                                boxShadow: '0px 0px 3px 0px rgba(117, 179, 255, 0.50)',
                                width: '7rem',
                                marginBottom: '0.5rem',
                                marginLeft: '2rem',
                            }}
                            onChange={(e) => {
                                setMinimumNoticeTime(e);
                            }}
                        ></ChipSelect>
                    </Stack>
                </Stack>
            </Stack>
        </MynkFormDialog>
  )
}