import { Button, SxProps } from "@mui/material";
import { ReactNode } from "react";

export interface SimpleButtonProps {
  onClick?: () => void;
  backgroundColor: string;
  borderColor: string;
  fontColor: string;
  sx?: SxProps;
  children?: ReactNode;
}

/**
 * Renders a button with customizable colors (background, border and inner text).
 *
 * @param {Object} props - The props of the component.
 *   - onClick (Function) - A function to be called when the button is clicked.
 *   - backgroundColor (string) - The background color of the button.
 *   - borderColor (string) - The border color of the button.
 *   - fontColor (string) - The font color of the button.
 *   - sx (Object) - Additional styles for the button using the `SxProps` type from the Material-UI library.
 *   - children (ReactNode) - The content to be displayed inside the button.
 * @returns {JSX.Element} The rendered button component.
 */
export default function SimpleButton(props: SimpleButtonProps) {
  return (
    <Button
      variant="contained"
      sx={{
        zIndex: 100,
        background: props.backgroundColor,
        color: props.fontColor,
        borderRadius: "0.2rem",
        border: 1,
        borderColor: props.borderColor,
        textTransform: "capitalize",
        fontSize: "1.1429rem",
        boxShadow: "0",
        height: "1.9286rem",
        "&:hover": {
          background: props.backgroundColor,
          color: props.fontColor,
          opacity: 0.7,
        },
      }}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
}
