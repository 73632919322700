import { MynkTab } from "../../../../components/MynkPage";
import { Box, Button, Grid, IconButton, Stack } from "@mui/material";
import React, { useState } from "react";
import ActionMenuButton from "../../../../components/ActionMenuButton";
import { Add } from "@mui/icons-material";
import TaskView, {
  TaskViewProps,
  TaskViewType,
  openedDeleteAtom,
  openedEditAtom,
  selectedTaskAtom,
} from "../../../../components/TaskView";
import AddTaskDialog from "../../../../dialogs/AddTask";
import AddReminderDialog from "../../../../dialogs/AddReminder";
import listViewSwitchOn from "../../../../assets/icons/list-view-switch-on.svg";
import listViewSwitchOff from "../../../../assets/icons/list-view-switch-off.svg";
import cardViewSwitchOn from "../../../../assets/icons/card-view-switch-on.svg";
import cardViewSwitchOff from "../../../../assets/icons/card-view-switch-off.svg";
import verticalLineSeperatesIcon from "../../../../assets/icons/vertical-line-seperates-icon.svg";
import { useAtom } from "jotai";
import ConfirmActionDialog from "../../../../components/ConfirmActionDialog";
import { useDeleteTaskMutation } from "../../../../api/tasks";
import { DeleteTaskParams, SearchOption } from "../../../../api/types";
import { closeSnackbar, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { EditTaskDialog } from "../../../../dialogs/EditTask";
import SearchBox from "../../../../components/SearchFilterSort/Search";

enum NewMenuIds {
  NEW_TASK = "new-task",
  NEW_REMINDER = "new-reminder",
}

const NEW_MENU = [
  {
    id: NewMenuIds.NEW_TASK,
    label: "New task",
  },
  {
    id: NewMenuIds.NEW_REMINDER,
    label: "New reminder",
  }
];

export function NewTaskButton() {
  const [addTaskOpen, setAddTaskOpen] = useState(false);
  const [addReminder, setAddReminder] = useState(false);

  const handleClickItem = (id: string) => {
    switch (id) {
      case NewMenuIds.NEW_TASK:
        setAddTaskOpen(true);
        break;
      case NewMenuIds.NEW_REMINDER:
        setAddReminder(true);
        break;
    }
  };

  return (
    <>
      <AddTaskDialog open={addTaskOpen} onClose={() => setAddTaskOpen(false)} />
      <AddReminderDialog open={addReminder} onClose={() => setAddReminder(false)} />

      <ActionMenuButton
        startIcon={<Add sx={{ width: "1.1rem" }} />}
        menu={NEW_MENU}
        onClickItem={handleClickItem}
      >
        New
      </ActionMenuButton>
    </>
  );
}

const MAIN_TASK_GROUPS: TaskViewProps[] = [
  { completed: false, dueDaysMin: 0, dueDaysMax: 1, title: "Today" },
  { completed: false, dueDaysMin: 1, dueDaysMax: 2, title: "Tomorrow" },
  { completed: false, dueDaysMin: 2, title: "Upcoming", allowAdd: false },
];

const SIDE_TASK_GROUPS: TaskViewProps[] = [
  { completed: false, dueDaysMax: 0, title: "Overdue", allowAdd: false },
];

interface TaskViewGroupsProps {
  groups: TaskViewProps[];
  viewType: TaskViewType;
  title_query: string;
}

export function TaskViewGroups(props: TaskViewGroupsProps) {
  const [addTaskOpen, setAddTaskOpen] = useState(false);
  const [addTaskDelta, setAddTaskDelta] = useState(0);

  return (
    <>
      <AddTaskDialog
        open={addTaskOpen}
        onClose={() => setAddTaskOpen(false)}
        defaultDelta={addTaskDelta}
      />

      {/* Task list - extract each task from the tasks' map */}
      {props.groups.map((group) => (
        <Box key={group.title} sx={{ pb: 3.8 }}>
          <TaskView
            {...group}
            viewType={props.viewType}
            allowAdd={group.allowAdd ?? true}
            onAdd={() => {
              setAddTaskDelta(group.dueDaysMin!);
              setAddTaskOpen(true);
            }}
            query={props.title_query}
          />
        </Box>
      ))}
    </>
  );
}

interface SearchAndViewTypeSwitchProps {
  searchState: [SearchOption, React.Dispatch<React.SetStateAction<SearchOption>>];
  selectedView: TaskViewType;
  setSelectedView: (newSelectedView: TaskViewType) => void;
}

export function SearchAndViewTypeSwitch(props: SearchAndViewTypeSwitchProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{mb: 1.2}}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 1.3, mr: 1.5 }}
      >
        <Box
          component="img"
          src={
            props.selectedView == TaskViewType.LIST
              ? listViewSwitchOn
              : listViewSwitchOff
          }
          sx={{ cursor: "pointer", height: "2.5rem" }}
          onClick={() => props.setSelectedView(TaskViewType.LIST)}
        />
        <Box
          component="img"
          src={verticalLineSeperatesIcon}
          sx={{ height: "1.7857rem" }}
        />
        <Box
          component="img"
          src={
            props.selectedView == TaskViewType.CARD
              ? cardViewSwitchOn
              : cardViewSwitchOff
          }
          sx={{ cursor: "pointer", height: "2.5rem" }}
          onClick={() => props.setSelectedView(TaskViewType.CARD)}
        />
      </Box>
      {/* <SearchBox searchState={props.searchState} 
      height={39}
        sx={{ mt: 1.3, mr: 1.5, display: "flex",
        alignItems: "center"}}/> */}
    </Box>
  );
}

export default function TasksAllSubpage() {
  const [search, setSearch] = useState<SearchOption>({ query: "" });
  const [selectedView, setSelectedView] = useState(TaskViewType.LIST);
  const [selectedTask, setSelectedTask] = useAtom(selectedTaskAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);
  const { enqueueSnackbar } = useSnackbar();

  // Fetch delete task:
  const deleteTask = useDeleteTaskMutation();
  const handleDeleteTask = async (uuid: string) => {
    const params = {
      uuid: uuid,
    } as DeleteTaskParams;

    await deleteTask.mutateAsync(params);
    enqueueSnackbar("Task deleted successfully", {
      variant: "success",
      action: (key) => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
      style: {
        borderRadius: "0.7rem",
      },
    });

    setOpenedDelete(false);
  };
  
  return (
    <MynkTab
      title="All"
      action={<NewTaskButton />}
      nextToAction={
        <SearchAndViewTypeSwitch
          searchState={[search, setSearch]}
        selectedView={selectedView}
          setSelectedView={setSelectedView}
        />
      }
    >
      {/* Confirm delete task pop-up window */}
      <ConfirmActionDialog
        open={openedDelete}
        what="task"
        whatAction="delete"
        onClose={() => setOpenedDelete(false)}
        onConfirm={() =>
          selectedTask ? handleDeleteTask(selectedTask.uuid) : undefined
        }
        loading={deleteTask.isPending}
      >
        Delete
      </ConfirmActionDialog>

      {/* Confirm edit task pop-up window */}
      <EditTaskDialog
        open={openedEdit}
        onClose={() => setOpenedEdit(false)}
        oldTask={selectedTask}
      />

      {selectedView == TaskViewType.LIST ? (
        <Grid container>
          {/* Tasks horizontal view (left side - Today/Tomorrow/Upcoming) */}
          <Grid item xs={9}>
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderRight: "solid 1px #75b3ffb2",
                  mx: 3,
                  pointerEvents: "none",
                }}
              />

              <Stack spacing={3} sx={{ mt: 2 }}>
                <TaskViewGroups
                  groups={MAIN_TASK_GROUPS}
                  viewType={selectedView}
                  title_query={search?.query ?? ""}
                />
              </Stack>
            </Box>
          </Grid>
          {/* Task table view (right side - Overdue) */}
          <Grid item xs={3}>
            <Box sx={{ ml: 3, mr: -1.7, pl: 3, position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  width: 1,
                  height: "100%",
                  borderLeft: "solid 1px #75b3ffb2",
                  mx: -3,
                  pointerEvents: "none",
                }}
              />

              <Box sx={{ minHeight: "75vh" }}>
                <TaskViewGroups
                  groups={SIDE_TASK_GROUPS}
                  viewType={TaskViewType.CARD}
                  title_query={search?.query ?? ""}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : selectedView == TaskViewType.CARD ? (
        <Grid container spacing={6}>
          {[...MAIN_TASK_GROUPS, ...SIDE_TASK_GROUPS].map((group, i) => (
            <Grid item xs={3} key={group.title}>
              {i > 0 && (
                <Box
                  sx={{
                    position: "fixed",
                    width: 1,
                    height: "calc(100% - 146px)",
                    borderLeft: "solid 1px #75b3ffb2",
                    mx: -3,
                    pointerEvents: "none",
                  }}
                />
              )}

              <TaskView allowAdd {...group} viewType={TaskViewType.CARD} query={search?.query ?? ""}/>
            </Grid>
          ))}
        </Grid>
      ) : (
        <></>
      )}
    </MynkTab>
  );
}
