import {
  AddLeadParams,
  AddLeadResponse,
  AddListingParams,
  AddListingResponse,
  AddProjectFileParams,
  AddProjectFileResponse,
  AddProjectNoteParams,
  AddProjectNoteResponse,
  AddProjectParams,
  AddProjectResponse,
  ChangeListingViewerParams,
  ChangeListingViewerResponse,
  DeleteProjectFileParams,
  DeleteProjectFileResponse,
  DeleteProjectNoteParams,
  DeleteProjectNoteResponse,
  DeleteProjectParams,
  DeleteProjectResponse,
  EditProjectParams,
  EditProjectResponse,
  GetProjectParams,
  GetProjectResponse,
  IsProjectDateAvailableParams,
  IsProjectDateAvailableResponse,
  ListListingViewersParams,
  ListListingViewersResponse,
  ListProjectFilesParams,
  ListProjectFilesResponse,
  ListProjectsParams,
  ListProjectsResponse,
  SearchProjectsParams,
  SearchProjectsResponse,
  SetListingAvailabilityParams,
  SetListingAvailabilityResponse,
  SetProjectPriorityParams,
  SetProjectPriorityResponse,
  SetProjectStageParams,
  SetProjectStageResponse,
} from "./types";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseMutationOptions,
} from "@tanstack/react-query";
import axios from "axios";

export function useAddPhotographyProjectMutation(
  options: UseMutationOptions<AddProjectResponse, unknown, AddProjectParams>
) {
  const queryClient = useQueryClient();
  return useMutation<AddProjectResponse, unknown, AddProjectParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/projects/add_photography", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["project-list"] });
      await queryClient.invalidateQueries({ queryKey: ["stage-stats"] });
    },
    ...options,
  });
}

export function useAddRealEstateListingMutation() {
  const queryClient = useQueryClient();
  return useMutation<AddListingResponse, unknown, AddListingParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/projects/add_real_estate", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["project-list"] });
      // FIXME: await queryClient.invalidateQueries("stage-stats");
    },
  });
}


export function useIsProjectDateAvailable(
  params: IsProjectDateAvailableParams,
  enabled?: boolean
) {
  return useQuery<IsProjectDateAvailableResponse>({
    queryKey: ["is-project-date-available", params],
    queryFn: async () => (await axios.post("/api/projects/is-project-date-available", params)).data,
    enabled: enabled ?? true,
  });
}

export function useSetListingAvailabilityMutation() {
  const queryClient = useQueryClient();
  return useMutation<
    SetListingAvailabilityResponse,
    unknown,
    SetListingAvailabilityParams
  >({
    mutationFn: async (params) =>
      (await axios.post("/api/projects/set-availability", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({ queryKey: ["project-list"] });
      // FIXME: await queryClient.invalidateQueries("stage-stats");
      await queryClient.invalidateQueries({
        queryKey: ["project", params.uuid],
      });
    },
  });
}

export function useEditProjectMutation(
  options: UseMutationOptions<EditProjectResponse, unknown, EditProjectParams>
) {
  const queryClient = useQueryClient();
  return useMutation<EditProjectResponse, unknown, EditProjectParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/projects/edit", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({
        queryKey: ["project", params.uuid],
      });
      await queryClient.invalidateQueries({ queryKey: ["project-list"] });
      await queryClient.invalidateQueries({ queryKey: ["stage-stats"] });
    },
    ...options,
  });
}

export function useDeleteProjectMutation() {
  const queryClient = useQueryClient();
  return useMutation<DeleteProjectResponse, unknown, DeleteProjectParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/projects/delete", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["project-list"] });
      await queryClient.invalidateQueries({ queryKey: ["stage-stats"] });
    },
  });
}
export function useGetProjectQuery(
  params: GetProjectParams,
  enabled?: boolean
) {
  return useQuery<GetProjectResponse>({
    queryKey: ["project", params.project_uuid],
    queryFn: async () =>
      (await axios.get("/api/projects/get", { params })).data,

    enabled: enabled ?? true,
  });
}

export function useGetProjectMutation(
  options: UseMutationOptions<GetProjectResponse,unknown,GetProjectParams>
) {
  const queryClient = useQueryClient();
  return useMutation<GetProjectResponse, unknown, GetProjectParams>({
    mutationFn: async (params) =>
      (await axios.get("/api/projects/get", { params })).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["project-list"] });
      await queryClient.invalidateQueries({ queryKey: ["project-stats"] });
    },
  });
}

export function useListProjectsQuery(
  params: ListProjectsParams,
  enabled?: boolean
) {
  return useQuery<ListProjectsResponse>({
    queryKey: ["project-list", params],
    queryFn: async () => (await axios.post("/api/projects/list", params)).data,

    enabled: enabled ?? true,
  });
}

export function useSearchProjectsQuery(params: SearchProjectsParams) {
  return useQuery<SearchProjectsResponse>({
    queryKey: ["search-projects", params],
    queryFn: async () =>
      (await axios.get("/api/projects/search", { params })).data,
  });
}

export function useAddProjectFileMutation(
  options: UseMutationOptions<
    AddProjectFileResponse,
    unknown,
    AddProjectFileParams
  >
) {
  const queryClient = useQueryClient();
  return useMutation<AddProjectFileResponse, unknown, AddProjectFileParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/projects/add-file", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({ queryKey: ["project-files-list"] });
    },
    ...options,
  });
}

export function useDeleteProjectFileMutation() {
  const queryClient = useQueryClient();
  return useMutation<
    DeleteProjectFileResponse,
    unknown,
    DeleteProjectFileParams
  >({
    mutationFn: async (params) =>
      (await axios.post("/api/projects/delete-file", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({ queryKey: ["project-files-list"] });
      await queryClient.invalidateQueries({ queryKey: ["client-files-list"] });
    },
  });
}

export function useListProjectFilesQuery(
  params: ListProjectFilesParams,
  enabled?: boolean
) {
  return useQuery<ListProjectFilesResponse>({
    queryKey: ["project-files-list", params],
    queryFn: async () =>
      (await axios.get("/api/projects/list-files", { params })).data,

    enabled: enabled ?? true,
  });
}

export function useSetProjectStageMutation() {
  const queryClient = useQueryClient();
  return useMutation<SetProjectStageResponse, unknown, SetProjectStageParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/projects/set-stage", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({ queryKey: ["project-list"] });
      await queryClient.invalidateQueries({ queryKey: ["stage-stats"] });
      await queryClient.invalidateQueries({
        queryKey: ["project", params.uuid],
      });
    },
  });
}

export function useSetProjectPriorityMutation() {
  const queryClient = useQueryClient();
  return useMutation<
    SetProjectPriorityResponse,
    unknown,
    SetProjectPriorityParams
  >({
    mutationFn: async (params) =>
      (await axios.post("/api/projects/set-priority", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({ queryKey: ["project-list"] });
      await queryClient.invalidateQueries({ queryKey: ["stage-stats"] });
      await queryClient.invalidateQueries({
        queryKey: ["project", params.uuid],
      });
    },
  });
}
export function useAddProjectNoteMutation(
  options: UseMutationOptions<
    AddProjectNoteResponse,
    unknown,
    AddProjectNoteParams
  >
) {
  const queryClient = useQueryClient();
  return useMutation<AddProjectNoteResponse, unknown, AddProjectNoteParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/projects/add-note", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({
        queryKey: ["project", params.project_uuid],
      });
    },
    ...options,
  });
}

export function useDeleteProjectNoteMutation() {
  const queryClient = useQueryClient();
  return useMutation<
    DeleteProjectNoteResponse,
    unknown,
    DeleteProjectNoteParams
  >({
    mutationFn: async (params) =>
      (await axios.post("/api/projects/delete-note", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({
        queryKey: ["project", params.project_uuid],
      });
    },
  });
}

export function useListListingViewersQuery(
  params: ListListingViewersParams,
  enabled?: boolean
) {
  return useQuery<ListListingViewersResponse>({
    queryKey: ["listing-viewers-list", params],
    queryFn: async () =>
      (await axios.post("/api/projects/list-viewers", params)).data,

    enabled: enabled ?? true,
  });
}

export function useChangeListingViewer() {
  const queryClient = useQueryClient();

  return useMutation<
    ChangeListingViewerResponse,
    unknown,
    ChangeListingViewerParams
  >({
    mutationFn: async (params) =>
      (await axios.post("/api/projects/change-listing-viewer", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({
        queryKey: ["listing-viewers-list"],
      });
      await queryClient.invalidateQueries({ queryKey: ["project-list"] });
    },
  });
}
