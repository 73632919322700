import React, { useEffect, useRef, useState } from 'react';
import { Stack, Typography, Divider, Collapse, Box, CircularProgress } from '@mui/material';
import { useListFilesQuery } from '../../../api/files';
import { LibraryCategory } from '../../../api/types';
import JSZip from 'jszip';
import mammoth from 'mammoth';
import { useParams } from 'react-router-dom';
import MynkPageHeader from '../../../components/MynkPage/MynkPageHeader';
import { PATHS, WorkflowPath, makePath } from "../../../paths";
import { useListFavoritesQuery, useAddFavoriteMutation, useDeleteFavoriteMutation } from '../../../api/library';
import { AddLibraryFavoriteParams, DeleteLibraryFavoriteParams } from '../../../api/types';
import { AxiosError } from 'axios';


interface GuidesSideBarProps {
    currentTitle: string;
    currentSubtitle: string;
    titlesAndSubtitles: Record<string, string[]> | null;
    titleRefs: any;
    subtitleRefs: any;
    scrollableContainerRef: any;
}

function GuideSideBar(props: GuidesSideBarProps) {
    const removeEmojis = (text: string) => {
        return text.replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u0023-\u0039]\uFE0F?\u20E3|[\u3297\u3299\u303D\u3030\u2B55\u2B1B\u2B1C\u2B50\u2B06\u2B07\u2194-\u2199\u21AA\u21A9\u2934\u2935\u25B6\u25C0\u25FB\u25FC\u25AB\u25AA\u2B1A]|[\uD83C\uDC04]|[\uD83C\uDD70-\uDD71]|[\uD83C\uDD7E-\uDD7F]|[\uD83C\uDD8E]|[\uD83C\uDD91-\uDD9A]|[\uD83C\uDE01-\uDE02]|[\uD83C\uDE1A-\uDE1B]|[\uD83C\uDE2F]|[\uD83C\uDE32-\uDE3A]|[\uD83C\uDE50-\uDE51]|[\uD83D\uDC00-\uDFFF])/g,
            ''
        );
    };

    const handleTitleClick = (title: string) => {
        const titleElement = props.titleRefs.current[title];

        if (titleElement) {
            const container = props.scrollableContainerRef.current;
            const rect = titleElement.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();
            
            // Calculate the center position for scrolling
            const scrollPosition = rect.top - containerRect.top + container.scrollTop - (containerRect.height / 2) + (rect.height / 2);
    
            container.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        }
    };

    const handleSubtitleClick = (subtitle: string) => {
        const subtitleElement = props.subtitleRefs.current[subtitle];

        if (subtitleElement) {
            const container = props.scrollableContainerRef.current;
            const rect = subtitleElement.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();
            
            // Calculate the center position for scrolling
            const scrollPosition = rect.top - containerRect.top + container.scrollTop - (containerRect.height / 2) + (rect.height / 2);
    
            container.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        }
    };

    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    return (
        <Stack mr={10} sx={{
            position: "sticky",
            alignSelf: "flex-start",
            flexBasis: "30rem",
            top: 0,
        }}>
            <Typography fontSize={"clamp(16px, 1.6rem, 1.6rem)"} fontWeight={600}>
                What's in the Guide
            </Typography>

            <Divider sx={{ mt: 2, maxWidth: "21.5rem" }} />

            {props.titlesAndSubtitles && Object.keys(props.titlesAndSubtitles).map((title: string) => (
                <Stack key={title} maxWidth={"20rem"}>
                    <Typography
                        onClick={() => handleTitleClick(title)}
                        sx={{
                            mt: 2,
                            opacity: props.currentTitle === title ? 1 : 0.7,
                            fontSize: "clamp(14px, 1.2rem, 1.2rem)",
                            fontFamily: 'InterVariable,-apple-system,Helvetica,Arial,BlinkMacSystemFont,Segoe UI,Roboto,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
                            fontWeight: 600,
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: 1,
                            },
                        }}
                    >
                        {capitalizeWords(removeEmojis(title.toLowerCase()))}
                    </Typography>
                    <Collapse in={props.currentTitle === title} timeout="auto" unmountOnExit>
                        <Stack ml={3}>
                            {props.titlesAndSubtitles && props.titlesAndSubtitles[title].map((subtitle, index) => (
                                <Typography
                                    key={subtitle}
                                    onClick={() => handleSubtitleClick(subtitle)}
                                    sx={{
                                        mt: index === 0 ? 2 : 1,
                                        opacity: props.currentSubtitle === subtitle ? 1 : 0.7,
                                        fontSize: "clamp(11px, 1rem, 1rem)",
                                        fontFamily: 'InterVariable,-apple-system,Helvetica,Arial,BlinkMacSystemFont,Segoe UI,Roboto,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            opacity: 1,
                                        },
                                    }}
                                >
                                    {removeEmojis(subtitle)}
                                </Typography>
                            ))}
                        </Stack>
                    </Collapse>
                </Stack>
            ))}
        </Stack>
    );
}


interface GuideContentProps {
    titlesSubtitles: Record<string, string[]>;
    titlesContents: Record<string, HTMLElement[]>;
    subtitleContents: Record<string, HTMLElement[]>;
    titleRefs: any;
    subtitleRefs: any;
    scrollableContainerRef: any;
    mainTitle: string;
    mainTitleContent: HTMLElement[];
}

function GuideContent(props: GuideContentProps) {
    return (
        <Stack
            width="calc(100% - 30rem)"
            sx={{
                overflowY: 'scroll',
                scrollbarWidth: 'none',
            }}
            ref={props.scrollableContainerRef}
        >
            <Typography fontWeight={600} fontSize={"clamp(24px, 2.5rem, 2.5rem)"} mt={5}>
                {props.mainTitle}
            </Typography>

            {props.mainTitleContent && props.mainTitleContent.map((paragraph, index) => (
                <Typography
                    key={index}
                    mt={2.5}
                    fontSize={"clamp(16px, 1.5rem, 1.5rem)"}
                    lineHeight={"2rem"}
                    fontFamily={'"InterVariable",sans-serif'}
                    component="div"
                    textAlign={"justify"}
                    dangerouslySetInnerHTML={{ __html: paragraph.innerHTML }}
                />
            ))}

            <Divider sx={{ my: 6 }} />

            {Object.keys(props.titlesSubtitles).map((title: string, index) => (
                <Stack id={`title-${title}`} key={index} ref={(el) => (props.titleRefs.current[title] = el)}>
                    {index !== 0 && <Divider sx={{ my: 6 }} />}

                    <Typography
                        fontSize={"clamp(20px, 2.1rem, 2.1rem)"}
                        fontFamily={"Helvetica"}
                        lineHeight={"35px"}
                        fontWeight={600}
                        textAlign={'justify'}
                    >
                        {title}
                    </Typography>
                    
                    {props.titlesContents[title].length > 0 && (
                        props.titlesContents[title].map((paragraph, index) => (
                            <Typography
                                key={index}
                                mt={2.5}
                                fontSize={"clamp(16px, 1.5rem, 1.5rem)"}
                                fontFamily={'"InterVariable",sans-serif'}
                                component="div"
                                textAlign={"justify"}
                                dangerouslySetInnerHTML={{ 
                                    __html: paragraph.innerHTML
                                }}
                            />
                            ))
                    )}

                    {props.titlesSubtitles[title].length > 0 && <Divider sx={{ my: 6 }} />}

                    {props.titlesSubtitles[title].map((subtitle, subIndex) => (
                        <Stack key={subIndex}>
                            {subIndex !== 0 && <Divider sx={{ my: 6 }} />}

                            <Stack direction="row" alignItems="flex-start" id={`subtitle-${subtitle}`} ref={(el) => (props.subtitleRefs.current[subtitle] = el)}>
                                <Typography
                                    sx={{
                                        fontSize: "clamp(16px, 1.5rem, 1.5rem)",
                                        fontFamily: '"InterVariable",sans-serif',
                                        fontWeight: 600,
                                        position: "sticky",
                                        top: "10rem",
                                        flexBasis: "15rem",
                                        mr: 2,
                                    }}
                                >
                                    {subtitle}
                                </Typography>
                                
                                {props.subtitleContents[subtitle].length > 0 && (
                                    <Stack>
                                        {props.subtitleContents[subtitle].map((paragraph, index) => (
                                            <Typography
                                                key={index}
                                                fontSize={"clamp(16px, 1.5rem, 1.5rem)"}
                                                fontFamily={'"InterVariable",sans-serif'}
                                                component="div"
                                                dangerouslySetInnerHTML={{ __html: paragraph.innerHTML }}
                                                maxWidth={'55rem'}
                                                textAlign={"justify"}
                                            />
                                        ))}
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            ))}
        </Stack>
    );
}

interface GuideHeaderProps {
    fileName: string;
}

function ContractHeader(props: GuideHeaderProps) {
    const [isFavorite, setIsFavorite] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");

    const { data: favoritesData, isLoading: favoritesLoading } = useListFavoritesQuery({});

    const { mutate: addFavorite, isPending } = useAddFavoriteMutation({
        onError: (error: unknown) => {
            if (error instanceof Error && "response" in error) {
                const axiosError = error as AxiosError<unknown, AddLibraryFavoriteParams>;
                const detail = (axiosError.response?.data as { detail?: string })?.detail;
                setErrorMsg(detail ?? "Something went wrong. Please try again.");
            }
        },
        onSuccess: () => {
            setIsFavorite(true);
            setErrorMsg("");
        },
    });

    const { mutate: deleteFavorite, isPending: deletePending } = useDeleteFavoriteMutation({
        onError: (error: unknown) => {
            if (error instanceof Error && "response" in error) {
                const axiosError = error as AxiosError<unknown, DeleteLibraryFavoriteParams>;
                const detail = (axiosError.response?.data as { detail?: string })?.detail;
                setErrorMsg(detail ?? "Something went wrong. Please try again.");
            }
        },
        onSuccess: () => {
            setIsFavorite(false);
            setErrorMsg("");
        },
    });

    const handleFavoriteClick = () => {
        if (favoritesLoading || isPending || deletePending) return;

        if (!isFavorite)
            addFavorite({ file_name: props.fileName });
        else
            deleteFavorite({ file_name: props.fileName });
    };

    useEffect(() => {
        if (favoritesData) {
            setIsFavorite(favoritesData.favorites.includes(props.fileName));
        }
    }, [favoritesData, props.fileName]);

    return (
        <Stack
            direction={"row"}
            alignContent={"justify-between"}
            sx={{
                position: 'sticky',
                height: "6.5rem",
                maxHeight: "100px",
                zIndex: 1000,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 32,
                    left: 32,
                }}
            >
                <MynkPageHeader
                    title="Guide"
                    backLink={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.library.all)}
                    selectedTabIndex={0}
                    tabs={[]}
                />
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    top: 32,
                    right: "5rem",
                    padding: "1rem",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    cursor: 'pointer',
                }}
                onClick={handleFavoriteClick}
            >
                <Box
                    component="svg"
                    viewBox="0 0 24 24"
                    width={"1.7rem"}
                    height={"1.7rem"}
                    sx={{
                        transition: "fill 0.3s ease, stroke 0.3s ease",
                        fill: isFavorite ? "#1976d2" : "#FFFFFF",
                        stroke: isFavorite ? "#1976d2" : "#808080",
                        strokeWidth: 2,
                    }}
                >
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                </Box>
            </Box>
        </Stack>
    );
}

export default function GuidePage() {
    const { imageName } = useParams<{ imageName?: string }>();
    const { data, isLoading } = useListFilesQuery({ folder: LibraryCategory.GUIDES });

    const [htmlContent, setHtmlContent] = useState<string>('');

    const [mainTitle, setMainTitle] = useState<string>('');
    const [mainTitleContent, setMainTitleContent] = useState<HTMLElement[]>([]);
    
    const [currentTitle, setCurrentTitle] = useState<string>('');
    const [currentSubtitle, setCurrentSubtitle] = useState<string>('');

    const [titlesSubtitles, setTitlesSubtitles] = useState<Record<string, string[]> | null>(null);
    const [titlesContents, setTitlesContents] = useState<Record<string, HTMLElement[]> | null>(null);
    const [subtitleContents, setSubtitleContents] = useState<Record<string, HTMLElement[]> | null>(null);

    const titleRefs = useRef<{ [key: string]: HTMLElement | null }>({});
    const subtitleRefs = useRef<{ [key: string]: HTMLElement | null }>({});
    const scrollableContainerRef = useRef<HTMLDivElement>(null);

    const processZip = async (zipBlob: Blob) => {
        try {
            const arrayBuffer = await zipBlob.arrayBuffer();
            const zip = await JSZip.loadAsync(arrayBuffer);
            const items = [];

            for (const fileName of Object.keys(zip.files)) {
                const file = zip.file(fileName);
                
                if (fileName && file) {
                    const content = await file.async('arraybuffer');

                    const { value: html } = await mammoth.convertToHtml({ arrayBuffer: content });
                    items.push({
                        'fileName': fileName.substring(0, fileName.lastIndexOf('.')),
                        'html': html,
                    });
                }
            }

            return items;
        } catch (error) {
            console.error('Error processing ZIP file:', error);
            return [];
        }
    };

    useEffect(() => {
        if (data && !isLoading && imageName) {
            const processGuides = async () => {
                const htmls = await processZip(data);
                
                for (const html of htmls) {
                    if (html.fileName.replaceAll(" ", "").toLowerCase() === imageName.replaceAll(" ", "").toLowerCase()) {
                        setHtmlContent(html.html);
                        break;
                    }
                }
            };

            processGuides();
        }
    }, [data, isLoading]);

    useEffect(() => {
        if (!htmlContent) return;

        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        
        // Extract titles and subtitles
        const titleElements = doc.querySelectorAll('s');
        const subtitleElements = doc.querySelectorAll('em');
        const tempMainTitle = Array.from(subtitleElements).shift();

        const tempParagraphsWithoutTags: Node[] = [];
        const allParagraphs = doc.querySelectorAll('p, ul');
        allParagraphs.forEach(paragraph => {
            const containsS = paragraph.querySelector('s');
            const containsEm = paragraph.querySelector('em');
            
            if (!containsS && !containsEm) {
                tempParagraphsWithoutTags.push(paragraph);
            }
        });

        const tempTitlesSubtitles: Record<string, string[]> = {};
        const tempTitlesContents: Record<string, HTMLElement[]> = {};
        const tempSubtitleContents: Record<string, HTMLElement[]> = {};
        const tempMainTitleContent: HTMLElement[] = []; // For content between main title and first content title

        titleElements.forEach((title, index) => {
            tempTitlesSubtitles[title.textContent || ''] = [];

            // Get the current title's position
            const currentTitlePosition = title.compareDocumentPosition.bind(title);
        
            // Get the next title's position if it exists
            const nextTitle = titleElements[index + 1];
            const nextTitlePosition = nextTitle ? nextTitle.compareDocumentPosition.bind(nextTitle) : null;
        
            // Filter subtitles that are between the current title and the next title
            const subtitlesBetween = Array.from(subtitleElements).filter(subtitle => {
                const isAfterCurrentTitle = currentTitlePosition(subtitle) & Node.DOCUMENT_POSITION_FOLLOWING;
                const isBeforeNextTitle = nextTitlePosition ? nextTitlePosition(subtitle) & Node.DOCUMENT_POSITION_PRECEDING : true;
                return isAfterCurrentTitle && isBeforeNextTitle;
            });
            
            if (subtitlesBetween.length === 0) {
                const titlesContentsBetween = tempParagraphsWithoutTags.filter(paragraph => {
                    const isAfterCurrentTitle = currentTitlePosition(paragraph) & Node.DOCUMENT_POSITION_FOLLOWING;
                    const isBeforeNextTitle = nextTitlePosition ? nextTitlePosition(paragraph) & Node.DOCUMENT_POSITION_PRECEDING : true;
                    return isAfterCurrentTitle && isBeforeNextTitle;
                });

                tempTitlesContents[title.textContent || ''] = titlesContentsBetween.map(paragraph => paragraph as HTMLElement);
            }
            
            if (index === 0) {
                const mainTitleContentBetween = tempParagraphsWithoutTags.filter(paragraph => {
                    const isBeforeFirstTitle = currentTitlePosition(paragraph) & Node.DOCUMENT_POSITION_PRECEDING;
                    return isBeforeFirstTitle;
                });
                tempMainTitleContent.push(...mainTitleContentBetween.map(paragraph => paragraph as HTMLElement));
            }

            // Log the subtitles between the current and next title
            subtitlesBetween.forEach((subtitle, index) => {
                tempTitlesSubtitles[title.textContent || ''].push(subtitle.textContent || '');
                const currentSubtitlePosition = subtitle.compareDocumentPosition.bind(subtitle);

                if (index === 0) {
                    const titlesContentsBetween = tempParagraphsWithoutTags.filter(paragraph => {
                        const isAfterCurrentTitle = currentTitlePosition(paragraph) & Node.DOCUMENT_POSITION_FOLLOWING;
                        const isBeforeCurrentSubtitle = currentSubtitlePosition(paragraph) & Node.DOCUMENT_POSITION_PRECEDING;
                        return isAfterCurrentTitle && isBeforeCurrentSubtitle;
                    });

                    tempTitlesContents[title.textContent || ''] = titlesContentsBetween.map(paragraph => paragraph as HTMLElement);
                }

                const nextSubtitle = subtitlesBetween[index + 1];
                const nextSubtitlePosition = nextSubtitle ? nextSubtitle.compareDocumentPosition.bind(nextSubtitle) : null;

                const subtitleContentsBetween = tempParagraphsWithoutTags.filter(paragraph => {
                    const isAfterCurrentSubtitle = currentSubtitlePosition(paragraph) & Node.DOCUMENT_POSITION_FOLLOWING;
                    const isBeforeNextSubtitle = nextSubtitlePosition ? nextSubtitlePosition(paragraph) & Node.DOCUMENT_POSITION_PRECEDING : true;
                    const isBeforeNextTitle = nextTitlePosition ? nextTitlePosition(paragraph) & Node.DOCUMENT_POSITION_PRECEDING : true;

                    if (!nextSubtitle) {
                        return isAfterCurrentSubtitle && isBeforeNextTitle;
                    }
                    return isAfterCurrentSubtitle && isBeforeNextSubtitle;
                });

                tempSubtitleContents[subtitle.textContent || ''] = subtitleContentsBetween.map(paragraph => paragraph as HTMLElement);
            });
        });

        setTitlesSubtitles(tempTitlesSubtitles);
        setTitlesContents(tempTitlesContents);
        setSubtitleContents(tempSubtitleContents);
        setCurrentTitle(Object.keys(tempTitlesSubtitles || {})[0]);
        setCurrentSubtitle(Object.values(tempTitlesSubtitles || {})[0][0]);
        setMainTitle(tempMainTitle?.textContent || '');
        setMainTitleContent(tempMainTitleContent);
    }, [htmlContent]);

    useEffect(() => {
        if (!scrollableContainerRef.current) return;

        const handleIntersect = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const elementId = entry.target.id;

                    // Detect current title based on the element at the center of the viewport
                    if (elementId.startsWith("title-")) {
                        const title = elementId.replace("title-", "");
                        setCurrentTitle(title);
                    }

                    // Detect current subtitle based on the element at the center of the viewport
                    if (elementId.startsWith("subtitle-")) {
                        const subtitle = elementId.replace("subtitle-", "");
                        setCurrentSubtitle(subtitle);
                    }
                }
            });
        };

        const observerOptions = {
            root: scrollableContainerRef.current, // Set the root to the scrollable container
            rootMargin: "-50% 0px -50% 0px", // Adjust this margin as needed
            threshold: 0, // Adjust the threshold if necessary
        };

        const observer = new IntersectionObserver(handleIntersect, observerOptions);

        // Observe title elements
        Object.keys(titleRefs.current).forEach((key) => {
            const el = titleRefs.current[key];
            if (el) observer.observe(el);
        });

        // Observe subtitle elements
        Object.keys(subtitleRefs.current).forEach((key) => {
            const el = subtitleRefs.current[key];
            if (el) observer.observe(el);
        });

        return () => {
            observer.disconnect(); // Clean up observers
        };
    }, [titleRefs, subtitleRefs, titlesSubtitles, scrollableContainerRef]);

    return (
        <Stack>
            <ContractHeader fileName={imageName ?? '...'}/>

            <Stack
                direction="row"
                spacing={0}
                maxWidth={'1800px'}
                mx={"auto"}
                pl={10}
                pr={20}
                mt={7}
            >
                {htmlContent && (
                    <Stack
                        direction="row"
                        sx={{
                            flexGrow: 1,
                            height: 'calc(100vh - 20rem)',
                        }}
                    >
                        <GuideSideBar
                            currentTitle={currentTitle}
                            currentSubtitle={currentSubtitle}
                            titlesAndSubtitles={titlesSubtitles}
                            titleRefs={titleRefs}
                            subtitleRefs={subtitleRefs}
                            scrollableContainerRef={scrollableContainerRef}
                        />
                        <GuideContent
                            titlesSubtitles={titlesSubtitles || {}}
                            titlesContents={titlesContents || {}}
                            subtitleContents={subtitleContents || {}}
                            titleRefs={titleRefs}
                            subtitleRefs={subtitleRefs}
                            scrollableContainerRef={scrollableContainerRef}
                            mainTitle={mainTitle}
                            mainTitleContent={mainTitleContent}
                        />
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
}