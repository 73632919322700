import { ChipSelectOption } from "./index";
import {
  Availability,
  ClientSource,
  EntranceDateTypeOptions,
  ListingStage,
  ListingType,
  Priority,
  ProjectStage,
  RealEstateClientLookingTo,
  RealEstateClientType,
  TimePeriod,
  ExpenseCategory
} from "../../api/types";
import descendingIcon from "../../assets/icons/sort-icon.svg";
import {
  EVENT_TAGS,
  EventTagName,
} from "../../pages/CommonComponents/Scheduler/common/CalendarCard";
import { tableHeadClasses } from "@mui/material";

export const LEAD_STAGE_OPTIONS: ChipSelectOption[] = [
  { value: ProjectStage.LEAD, label: "Lead" },
  { value: ProjectStage.PROPOSAL, label: "Proposal" },
  {
    value: ProjectStage.UNDER_CONTRACT,
    label: "Under contract",
    shortenLabel: "Contract",
  },
];

export const ALL_PROJECT_STAGE_OPTIONS: ChipSelectOption[] = [
  ...LEAD_STAGE_OPTIONS,
  {
    value: ProjectStage.WAITING_FOR_EVENT,
    label: "Waiting for event",
    shortenLabel: "Event",
  },
  { value: ProjectStage.EDITING, label: "Editing" },
  { value: ProjectStage.FINAL_APPROVAL, label: "Final approval" },
  { value: ProjectStage.COMPLETED, label: "Completed" },
];

export const REAL_ESTATE_LISTING_STAGE_OPTIONS: ChipSelectOption[] = [
  { value: ListingStage.INTEREST, label: "Interest" },
  { value: ListingStage.PROPERTY_VIEWING, label: "Viewing" },
  { value: ListingStage.SERIOUS_CONSIDERATION, label: "Consideration" },
  { value: ListingStage.NEGOTIATION, label: "Neogtiation" },
  { value: ListingStage.UNDER_CONTRACT, label: "Under contract" },
  { value: ListingStage.SOLD, label: "Closed/sold" },
];

export const PRIORITY_OPTIONS: ChipSelectOption[] = [
  { value: Priority.LOW, label: "Low" },
  { value: Priority.MEDIUM, label: "Medium" },
  { value: Priority.HIGH, label: "High" },
];

export const AVAILABILITY_OPTIONS: ChipSelectOption[] = [
  { value: Availability.AVAILABLE, label: "Available" },
  { value: Availability.ON_HOLD, label: "On hold" },
  { value: Availability.NOT_AVAILABLE, label: "Not available" },
];

export const LISTING_TYPE_OPTIONS: ChipSelectOption[] = [
  { value: ListingType.FOR_RENT, label: "For rent" },
  { value: ListingType.FOR_SALE, label: "For sale" },
];

export const REAL_ESTATE_CONTACT_TYPE_OPTIONS: ChipSelectOption[] = [
  { value: RealEstateClientType.OWNER, label: "Owner" },
  { value: RealEstateClientType.PROSPECT, label: "Prospect" },
];

export const REAL_ESTATE_LOOKING_TO_OPTIONS: ChipSelectOption[] = [
  { value: RealEstateClientLookingTo.BUY, label: "Buy" },
  { value: RealEstateClientLookingTo.RENT, label: "Rent" },
];

export const ENTRANCE_DATE_OPTIONS: ChipSelectOption[] = [
  { value: EntranceDateTypeOptions.READY_TO_MOVE, label: "Ready to move" },
  { value: EntranceDateTypeOptions.SPECIFIC_DATE, label: "Select date" },
];

export const SIZE_UNITS_OPTIONS: ChipSelectOption[] = [
  { value: "sqm", label: "sqm" },
  { value: "sqft", label: "sqft" },
];

export const BEDROOM_OPTIONS: ChipSelectOption[] = [
  { value: "1", label: "1 Beds" },
  { value: "2", label: "2 Beds" },
  { value: "3", label: "3 Beds" },
  { value: "4", label: "4 Beds" },
  { value: "5", label: "5 Beds" },
  { value: "6", label: "6 Beds" },
  { value: "7", label: "7 Beds" },
  { value: "8", label: "8 Beds" },
  { value: "9", label: "9 Beds" },
  { value: "10", label: "10 Beds" },
  { value: "11", label: "11 Beds" },
  { value: "12", label: "12 Beds" },
  { value: "13", label: "13 Beds" },
  { value: "14", label: "14 Beds" },
];

export const MONTHS_OPTIONS: ChipSelectOption[] = [
  { value: "1", label: "1 Month" },
  { value: "2", label: "2 Months" },
  { value: "3", label: "3 Months" }
];

export const TAXES_OPTIONS: ChipSelectOption[] = [
  { value: "0", label: "None" },
  { value: "5", label: "5%" },
  { value: "7.5", label: "7.5%" },
  { value: "10", label: "10%" },
  { value: "12.5", label: "12.5%" },
  { value: "15", label: "15%" },
];

export const DISCOUNT_OPTIONS: ChipSelectOption[] = [
  { value: "0", label: "None" },
  { value: "5", label: "5%" },
  { value: "10", label: "10%" },
  { value: "15", label: "15%" },
  { value: "20", label: "20%" },
];

export const BATHROOM_OPTIONS: ChipSelectOption[] = [
  { value: "1", label: "1 Baths" },
  { value: "2", label: "2 Baths" },
  { value: "3", label: "3 Baths" },
  { value: "4", label: "4 Baths" },
  { value: "5", label: "5 Baths" },
  { value: "6", label: "6 Baths" },
  { value: "7", label: "7 Baths" },
  { value: "8", label: "8 Baths" },
  { value: "9", label: "9 Baths" },
  { value: "10", label: "10 Baths" },
  { value: "11", label: "11 Baths" },
  { value: "12", label: "12 Baths" },
  { value: "13", label: "13 Baths" },
  { value: "14", label: "14 Baths" },
];

export const REPEAT_INTERVAL_OPTIONS: ChipSelectOption[] = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
];

export const REPEAT_EVERY_WHAT_PERIOD_OPTIONS: ChipSelectOption[] = [
  { value: TimePeriod.DAYS, label: "Days" },
  { value: TimePeriod.WEEKS, label: "Weeks" },
  { value: TimePeriod.MONTHS, label: "Months" },
  { value: TimePeriod.YEARS, label: "Years" },
];

export const REPEAT_UNTIL_OPTIONS: ChipSelectOption[] = [
  { value: "custom_date", label: "Custom date" },
  { value: "1", label: "1 occurrences" },
  { value: "2", label: "2 occurrences" },
  { value: "3", label: "3 occurrences" },
  { value: "4", label: "4 occurrences" },
  { value: "5", label: "5 occurrences" },
  { value: "6", label: "6 occurrences" },
  { value: "7", label: "7 occurrences" },
  { value: "8", label: "8 occurrences" },
  { value: "9", label: "9 occurrences" },
  { value: "10", label: "10 occurrences" },
  { value: "11", label: "11 occurrences" },
  { value: "12", label: "12 occurrences" },
  { value: "13", label: "13 occurrences" },
  { value: "14", label: "14 occurrences" },
  { value: "15", label: "15 occurrences" },
];

export const CONTACT_SOURCE_OPTIONS: ChipSelectOption[] = [
  { value: ClientSource.FACEBOOK, label: "Facebook" },
  { value: ClientSource.INSTAGRAM, label: "Instagram" },
  { value: ClientSource.TIKTOK, label: "Tiktok" },
  { value: ClientSource.LINKEDIN, label: "LinkedIn" },
  { value: ClientSource.WEBSITE, label: "Website" },
  { value: ClientSource.GOOGLE_SEARCH, label: "Google search" },
  { value: ClientSource.REFERRAL, label: "Referral" },
  { value: ClientSource.PINTEREST, label: "Pinterest" },
  { value: ClientSource.PAID_ADS, label: "Paid ads" },
  { value: ClientSource.BEHANCE, label: "Behance" },
  { value: ClientSource.DRIBBLE, label: "Dribbble" },
  { value: ClientSource.OTHER, label: "Other" },
];

export const FILTER_PROJECTS_OPTIONS: ChipSelectOption[] = [
  { value: "date", label: "Date" },
  { value: "priority", label: "Priority" },
];

export const FILTER_REAL_ESTATE_LISTINGS_OPTIONS: ChipSelectOption[] = [
  { value: "type", label: "Type" },
  { value: "availability", label: "Status" },
];

export const SORT_PROJECTS_OPTIONS: ChipSelectOption[] = [
  { value: "name:false", label: "Ascending", icon: descendingIcon },
  { value: "name:true", label: "Descending", icon: descendingIcon },
  { value: "date:true", label: "Date: new first", icon: descendingIcon },
  { value: "date:false", label: "Date: old first", icon: descendingIcon },
];

export const SORT_SENT_TEMPLATES_OPTIONS: ChipSelectOption[] = [
  { value: "client_name:false", label: "Client: ascending", icon: descendingIcon },
  { value: "client_name:true", label: "Client: descending", icon: descendingIcon },
  { value: "issuing_date:true", label: "Date: new first", icon: descendingIcon },
  { value: "issuing_date:false", label: "Date: old first", icon: descendingIcon },
];

export const SORT_REAL_ESTATE_LISTINGS_OPTIONS: ChipSelectOption[] = [
  { value: "price:true", label: "Price: high to low", icon: descendingIcon },
  { value: "price:false", label: "Price: low to high", icon: descendingIcon },
  { value: "size:true", label: "Size: largest first", icon: descendingIcon },
  { value: "size:false", label: "Size: smallest first", icon: descendingIcon },
];

export const PROJECTS_TABLE_CHOOSE_PROJECTS_CATEGORY_OPTIONS: ChipSelectOption[] =
  [
    { value: "all_projects", label: "All projects" },
    { value: "leads_to_under_contract", label: "Leads to under contract" },
    { value: "active_projects", label: "Active projects" },
    { value: "completed_projects", label: "Completed projects" },
  ];

export const FILTER_CLIENTS_OPTIONS: ChipSelectOption[] = [
  { value: "priority", label: "Priority" },
];

export const SORT_CLIENTS_OPTIONS: ChipSelectOption[] = [
  { value: "full_name:false", label: "Ascending", icon: descendingIcon },
  { value: "full_name:true", label: "Descending", icon: descendingIcon },
];

export const SORT_EXPENSES_OPTIONS: ChipSelectOption[] = [
  { value: "amount:false", label: "Amount Ascending", icon: descendingIcon },
  { value: "amount:true", label: "Amount Descending", icon: descendingIcon },
  { value: "date:false", label: "Date Ascending", icon: descendingIcon },
  { value: "date:true", label: "Date Descending", icon: descendingIcon },
];

export const CALENDAR_VIEW_TYPE_OPTIONS: ChipSelectOption[] = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
];

export const CALENDAR_TAG_OPTIONS: ChipSelectOption[] = EVENT_TAGS.filter(
  (tag) => tag.tag !== EventTagName.HOLIDAY && tag.tag !== EventTagName.TASK && tag.tag !== EventTagName.PROJECT
).map((tag) => ({
  value: tag.tag,
  label: tag.tag.charAt(0).toUpperCase() + tag.tag.slice(1),
  color: tag.color,
}));

export const DURATION_OPTIONS: ChipSelectOption[] = [
  { value: "30 min", label: "30 min" },
  { value: "45 min", label: "45 min" },
  { value: "60 min", label: "1 Hour" },
];

export const LOCATIONS_OPTIONS: ChipSelectOption[] = [
  { value: "None", label: "None" },
  { value: "Adress", label: "Adress" },
  { value: "Video call", label: "Video call" },
  { value: "Phone call", label: "Phone call" },
];

export const BLOCK_OFF_TIME_OPTIONS: ChipSelectOption[] = [
  { value: "0 min", label: "None" },
  { value: "10 min", label: "10 min" },
  { value: "20 min", label: "20 min" },
  { value: "30 min", label: "30 min" },
  { value: "45 min", label: "45 min" },
  { value: "60 min", label: "1 Hour" },
];

export const MINIMUM_NOTICE_TIME: ChipSelectOption[] = [
  { value: "0 min", label: "None" },
  { value: "30 min", label: "30 min" },
  { value: "60 min", label: "1 Hour" },
  { value: "240 min", label: "4 Hour" },
  { value: "1440 min", label: "1 Day" },
  { value: "2880 min", label: "2 Days" },
  { value: "10080 min", label: "1 Week" },
];

export const EXPENSES_CATEGORIES: ChipSelectOption[] = [
  { value: ExpenseCategory.None, label: "None" },
  { value: ExpenseCategory.PhotographyEquipment, label: "Photography Equipment" },
  { value: ExpenseCategory.AdministrativeExpenses, label: "Administrative Expenses" },
  { value: ExpenseCategory.MarketingAndAdvertising, label: "Marketing & Advertising" },
  { value: ExpenseCategory.PersonnelAndStaffing, label: "Personnel & Staffing" },
  { value: ExpenseCategory.OfficeExpenses, label: "Office Expenses" },
  { value: ExpenseCategory.TravelAndEntertainment, label: "Travel & Entertainment" },
  { value: ExpenseCategory.OperationalAndMaintenance, label: "Operational & Maintenance" },
];

export const PAYMENT_METHODS: ChipSelectOption[] = [
  { value: "none", label: "None" },
  { value: "card", label: "Card" },
  { value: "cash", label: "Cash" },
  { value: "bank", label: "Bank transfer" },
];

export const PROFESSIONS: ChipSelectOption[] = [
  { value: "photography", label: "Photographer" },
  { value: "graphic_design", label: "Graphic Designer" },
  { value: "web_development", label: "Web Developer" },
  { value: "coach", label: "Coach" },
  { value: "copywriter", label: "Copywriter" },
  { value: "event_planner", label: "Event Planner" },
  { value: "marketer", label: "Marketer" },
  { value: "hair_makeup_artist", label: "Hair & Make up artist" },
  { value: "real_estate", label: "Real Estate Agent" },
  { value: "other", label: "Other" },
];

export const MEETING_STATUS_OPTIONS: ChipSelectOption[] = [
  { value: "needsAction", label: "Maybe" },
  { value: "accepted", label: "Accepted" },
  { value: "declined", label: "Declined" },
];

export const REMIND_ME_OPTIONS: ChipSelectOption[] = [
  { value: "none", label: "None" },
  { value: "10 min", label: "10 min" },
  { value: "30 min", label: "30 min" },
  { value: "60 min", label: "1 Hour" },
  { value: "120 min", label: "2 Hours" },
  { value: "1440 min", label: "1 Day" },
];