import { PaymentMethod } from '../api/types';
import { Stack, Typography } from '@mui/material';
import creditCardIcon from "../assets/icons/credit-card.svg";
import cashIcon from "../assets/icons/cash.svg";
import bankIcon from "../assets/icons/bank-transfer.svg";
import React from 'react';


export interface PaymentMethodLabelProps {
  method: PaymentMethod;
  hint?: string;
}

export function PaymentMethodLabel(props: PaymentMethodLabelProps) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="body2">
        {props.method === 'cash' && 'Cash'}
        {props.method === 'card' && 'Card'}
        {props.method === 'bank' && 'Bank transfer'}
        {props.method === 'none' && 'N/A'}
      </Typography>

      {props.method === 'card' && ( <img src={creditCardIcon} style={{width: 24, height: 24,}}/> )}
      {props.method === 'cash' && ( <img src={cashIcon} style={{width: 24, height: 24,}}/> )}
      {props.method === 'bank' && ( <img src={bankIcon} style={{width: 24, height: 24,}}/> )}
    </Stack>
  )
}
