import React, { useEffect, useState } from 'react'
import { MynkFormDialog } from '../components/MynkForm';
import { Stack, Box, Typography, TextField, IconButton, InputAdornment } from '@mui/material';
import { useResetPasswordConnectedMutation } from '../api/auth';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AxiosError } from 'axios';
import { ResetPasswordConnectedParams } from '../api/types';

interface ChangePasswordDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function ChangePasswordDialog(props: ChangePasswordDialogProps) {
    const [oldPassword, setOldPassword] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [isErrorFrontend, setIsErrorFrontend] = useState<boolean>(false);

    const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const resetPassword = useResetPasswordConnectedMutation({
        onError: (error: unknown) => {
            if (error instanceof Error && "response" in error) {
              const axiosError = error as AxiosError<unknown, ResetPasswordConnectedParams>;
              const detail = (axiosError.response?.data as { detail?: string })
                ?.detail;
              setErrorMsg(detail ?? "");
              setIsErrorFrontend(true);
            }
        },
        onSuccess: () => {
            props.onClose();
        }
    });

    const handleSubmit = async (values: any) => {
        if (!password || !confirmPassword) return;
        if (password !== confirmPassword) {
            setIsErrorFrontend(true);
            setErrorMsg("Passwords doesn't match!");
            return;
        }

        await resetPassword.mutateAsync({
            old_password: oldPassword,
            password: password,
        });
    };

    useEffect(() => {
        if (!props.open) {
            setPassword("");
            setConfirmPassword("");
            setErrorMsg("");
            setIsErrorFrontend(false);
        }
    }, [props.open]);

    return (
        <MynkFormDialog
            open={props.open}
            onClose={props.onClose}
            title="Change password"
            action="Change password"
            onSubmit={handleSubmit}
            loading={resetPassword.isPending}
        >
            <Stack sx={{
                width: "25rem",
            }}>
                {isErrorFrontend && (
                    <Typography sx={{ color: "red" }}>{errorMsg}</Typography>
                )}
                <Typography sx={{ mt: 1, mb: 1.5 }}>Old password</Typography>
                <TextField
                    fullWidth
                    type={showOldPassword ? "text" : "password"}
                    disabled={resetPassword.isPending}
                    onChange={(event) => {
                        setOldPassword(event.target.value);
                    }}
                    sx={{
                        "& *": {
                            borderColor: "#c7a5ff",
                            borderRadius: "1.7143rem",
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowOldPassword(!showOldPassword)}>
                                    <Box
                                        width={"1.4286rem"}
                                        height={"1.4286rem"}
                                        sx={{
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        >
                                        {!showOldPassword ? (
                                            <VisibilityOffIcon sx={{ color: "#00000055" }} />
                                        ) : (
                                            <VisibilityIcon sx={{ color: "#00000055" }} />
                                        )}
                                    </Box>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Typography sx={{ mt: 2.5, mb: 1.5 }}>New password</Typography>
                <TextField
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    disabled={resetPassword.isPending}
                    onChange={(event) => {
                        setPassword(event.target.value);
                    }}
                    sx={{
                        "& *": {
                            borderColor: "#c7a5ff",
                            borderRadius: "1.7143rem",
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    <Box
                                        width={"1.4286rem"}
                                        height={"1.4286rem"}
                                        sx={{
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        >
                                        {!showPassword ? (
                                            <VisibilityOffIcon sx={{ color: "#00000055" }} />
                                        ) : (
                                            <VisibilityIcon sx={{ color: "#00000055" }} />
                                        )}
                                    </Box>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Typography
                    color="#40404080"
                    fontSize={"1.1rem"}
                    sx={{ ml: 1, mt: 0.7 }}
                >
                        Must be at least 8 characters and contain both letters and numbers.
                </Typography>
                <Typography sx={{ mt: 2.5, mb: 1.5 }}>Confirm password</Typography>
                <TextField
                    fullWidth
                    type={showConfirmPassword ? "text" : "password"}
                    disabled={resetPassword.isPending}
                    onChange={(event) => {
                        setConfirmPassword(event.target.value);
                    }}
                    sx={{
                        "& *": {
                            borderColor: "#c7a5ff",
                            borderRadius: "1.7143rem",
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                    <Box
                                    width={"1.4286rem"}
                                    height={"1.4286rem"}
                                    sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                    >
                                    {!showConfirmPassword ? (
                                        <VisibilityOffIcon sx={{ color: "#00000055" }} />
                                    ) : (
                                        <VisibilityIcon sx={{ color: "#00000055" }} />
                                    )}
                                    </Box>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
        </MynkFormDialog>
    );
}
