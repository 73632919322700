import {
  Box,
  Table,
  TableBody,
} from "@mui/material";
import { SimpleTableColumn, SimpleTableRow } from "../SimpleTable";

export interface StackTableProps {
  columns: SimpleTableColumn[];
  data?: any[];
  isPending?: boolean;
  tableHeight?: number;
  columnDivision?: string[];
}

export function StackTable(props: StackTableProps) {
  return (
    <Box  sx={{ height: `${props.tableHeight! / 14}rem`, overflowY: "auto" }}>
      <Table>
      {props.columnDivision && 
        <colgroup>
          {props.columnDivision.map((currentWidth, index) => {
            return <col key={index} span={1} style={{ width: currentWidth }} />
          })}
        </colgroup>
        }

      {props.data && !props.isPending && (
        <TableBody>
          {props.data.map((row, i) => (
            <SimpleTableRow
              key={row?.id ?? i}
              row={row}
              columns={props.columns}
            />
          ))}
        </TableBody>
      )}
    </Table>
    </Box>
    
  );
}
