import {Box} from "@mui/material";


export interface BoxWithGradientFrameProps {
  children?: React.ReactNode;
}

export function BoxWithGradientFrame(props: BoxWithGradientFrameProps) {
  return (
    <Box sx={{position: 'relative'}}>
      <svg
        style={{
          position: 'absolute',
          inset: 0,
        }}
        width="100%"
        height="100%"
      >
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stop-color="#C7A5FF" stop-opacity="1"/>
            <stop offset="100%" stop-color="#68B3FF" stop-opacity="1"/>
          </linearGradient>
        </defs>

        <rect
          x="1" y="8" width="calc(100% - 2px)" height="calc(100% - 12px)" rx="5" ry="5" fill="transparent"
          stroke="url(#gradient1)" stroke-width="1"
        />
      </svg>

      {props.children}
    </Box>
  );
}
