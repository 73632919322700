import { MynkTab } from "../../../../components/MynkPage";
import ViewGeneralLayout, {
  TimelineColumn,
  TimelineItem,
} from "../../../../components/ViewProjectOrContact/ViewGeneralLayout";
import React, { useMemo, useState } from "react";
import {
  Expense
} from "../../../../api/types";
import { zonedDate } from "../../../../util/common";
import { useCurrentContact } from "../common";
import { useAtom, useAtomValue } from "jotai";
import { currentWorkflowAtom } from "../../../../components/MainAppBar/UserControlPopup";
import { UpperDetailsCard, LowerDetailsCard } from "./General";
import { useListExpensesQuery } from "../../../../api/expenses";
import { AddExpenseDialog } from "../../../../dialogs/AddExpense";

const TIMELINE_COLUMNS = [
  TimelineColumn.DESCRIPTION,
  TimelineColumn.DATE,
  TimelineColumn.PRICE,
];

export default function ViewPhotographyContactExpensesSubpage() {
  const [newExpenseOpen, setNewExpenseOpen] = useState(false);
  const onTimelineAction = () => {
    setNewExpenseOpen(true);
  };

  const currentWorkflow = useAtomValue(currentWorkflowAtom);
  const contact = useCurrentContact();

  const { data, isPending } = useListExpensesQuery(
    {
      client_uuid: contact?.uuid ?? "",
      page_size: 10,
    },
    Boolean(contact)
  );
  
  const timelineItems: undefined | TimelineItem[] = useMemo(() => {
    if (isPending || !data || !currentWorkflow) return undefined;
    const expenses = data.expenses as Expense[];

    return expenses.map((expense) => {
      const date = zonedDate(expense.date);
        
      return {
        uuid: expense.uuid,
        title: expense.description,
        date,
        price: expense.amount,
      };
    });
  }, [data, isPending, currentWorkflow]);


  return (
    <MynkTab
      title="General"
    >
      <AddExpenseDialog
        open={newExpenseOpen}
        onClose={() => setNewExpenseOpen(false)}
        contactId={contact?.uuid}
      />

      <ViewGeneralLayout
        timeline={{
          timelineTitle: "Expenses",
          timelineItems,
          timelineColumns: TIMELINE_COLUMNS,
          timelineAction: "+ New expense",
          onTimelineActionClick: onTimelineAction,
          columnDivision: ["35%", "24%"]
        }}
        upperCard={<UpperDetailsCard />}
        lowerCard={<LowerDetailsCard />}
      />
    </MynkTab>
  );
}
