import { MynkPage } from "../../../components/MynkPage";
import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetProjectQuery } from "../../../api/projects";
import { PATHS, WorkflowPath, makePath } from "../../../paths";

const TABS = [
  {
    title: "General",
    path: "/general",
  },
  {
    title: "Files",
    path: "/files",
  },
  {
    title: "Tasks",
    path: "/tasks",
  },
  {
    title: "Notes",
    path: "/notes",
  },
  {
    title: "Expenses",
    path: "/expenses",
  }
];

export default function ViewPhotographyProjectPage() {
  const { projectId } = useParams();
  const basePath = useMemo(() => {
    return makePath(
      WorkflowPath.PHOTOGRAPHY,
      PATHS.viewProject(projectId ?? "").index
    );
  }, [projectId]);

  const { data } = useGetProjectQuery({ project_uuid: projectId ?? "" });

  return (
    <MynkPage
      title={!data?.project ? "..." : data.project.name}
      basePath={basePath}
      tabs={TABS}
      backLink={-1}
    />
  );
}
