import {
  AddClientFileParams,
  AddClientFileResponse,
  AddClientNoteParams,
  AddClientNoteResponse,
  AddClientParams,
  AddClientResponse,
  AddLeadParams,
  AddLeadResponse,
  AddPhotographyClientIn,
  AddRealEstateClientIn,
  DeleteClientNoteParams,
  DeleteClientNoteResponse,
  DeleteClientParams,
  DeleteClientResponse,
  EditClientParams,
  EditClientResponse,
  GetClientParams,
  GetClientResponse,
  GetClientStatsResponse,
  GetLeadParams,
  GetLeadResponse,
  GetProjectParams,
  GetProjectResponse,
  GetStageStatsResponse,
  ListClientFilesParams,
  ListClientFilesResponse,
  ListClientsParams,
  ListClientsResponse,
  ListLeadsParams,
  ListLeadsResponse,
  SearchContactsParams,
  SearchContactsResponse,
  SetClientPriorityParams,
  SetClientPriorityResponse,
  SetClientSourceParams,
  SetClientSourceResponse,
  AddPhotographyClientPortalIn
} from "./types";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseMutationOptions,
} from "@tanstack/react-query";
import axios from "axios";

export function useAddPhotographyClientMutation(
  options: UseMutationOptions<
    AddClientResponse,
    unknown,
    AddPhotographyClientIn
  >
) {
  const queryClient = useQueryClient();
  return useMutation<AddClientResponse, unknown, AddPhotographyClientIn>({
    mutationFn: async (params) =>
      (await axios.post("/api/clients/add_photography", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["client-list"] });
      await queryClient.invalidateQueries({ queryKey: ["client-stats"] });
      await queryClient.invalidateQueries({ queryKey: ["search-contacts"] });
    },
    ...options,
  });
}

export function useAddPhotographyClientPortalMutation(
  options: UseMutationOptions<
    AddClientResponse,
    unknown,
    AddPhotographyClientPortalIn
  >
) {
  const queryClient = useQueryClient();
  return useMutation<AddClientResponse, unknown, AddPhotographyClientPortalIn>({
    mutationFn: async (params) =>
      (await axios.post("/api/clients/add-photography-client-portal", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["client-list"] });
      await queryClient.invalidateQueries({ queryKey: ["client-stats"] });
      await queryClient.invalidateQueries({ queryKey: ["search-contacts"] });
    },
    ...options,
  });
}

export function useAddRealEstateClientMutation() {
  const queryClient = useQueryClient();
  return useMutation<AddClientResponse, unknown, AddRealEstateClientIn>({
    mutationFn: async (params) =>
      (await axios.post("/api/clients/add_real_estate", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["client-list"] });
      await queryClient.invalidateQueries({ queryKey: ["client-stats"] });
    },
  });
}

export function useEditClientMutation(  options: UseMutationOptions<
  EditClientResponse,
  unknown,
  EditClientParams
>) {
  const queryClient = useQueryClient();
  return useMutation<EditClientResponse, unknown, EditClientParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/clients/edit", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({
        queryKey: ["client", params.uuid],
      });
      await queryClient.invalidateQueries({ queryKey: ["client-list"] });
      await queryClient.invalidateQueries({ queryKey: ["client-stats"] });
    },
    ...options
  });
}

export function useDeleteClientMutation() {
  const queryClient = useQueryClient();
  return useMutation<DeleteClientResponse, unknown, DeleteClientParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/clients/delete", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["client-list"] });
      await queryClient.invalidateQueries({ queryKey: ["client-stats"] });
    },
  });
}

export function useGetClientStatsQuery() {
  return useQuery<GetClientStatsResponse>({
    queryKey: ["client-stats"],
    queryFn: async () => (await axios.get("/api/clients/stats")).data,
  });
}

export function useGetClientQuery(params: GetClientParams, enabled?: boolean) {
  return useQuery<GetClientResponse>({
    queryKey: ["client", params.uuid],
    queryFn: async () => (await axios.get("/api/clients/get", { params })).data,
    enabled: enabled ?? true,
  });
}

export function useGetClientMutation(options: UseMutationOptions<GetClientResponse,unknown,GetClientParams>) {
  const queryClient = useQueryClient();
  return useMutation<GetClientResponse, unknown, GetClientParams>({
    mutationFn: async (params) =>
      (await axios.get("/api/clients/get", { params })).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["client-list"] });
      await queryClient.invalidateQueries({ queryKey: ["client-stats"] });
    },
  });
}

export function useListClientsQuery(params: ListClientsParams) {
  return useQuery<ListClientsResponse>({
    queryKey: ["client-list", params],
    queryFn: async () => (await axios.post("/api/clients/list", params)).data,
  });
}

export function useSearchContactsQuery(params: SearchContactsParams) {
  return useQuery<SearchContactsResponse>({
    queryKey: ["search-contacts", params],
    queryFn: async () =>
      (await axios.get("/api/clients/search", { params })).data,
  });
}

export function useAddClientFileMutation(  options: UseMutationOptions<
  AddClientFileResponse,
  unknown,
  AddClientFileParams
>) {
  const queryClient = useQueryClient();
  return useMutation<AddClientFileResponse, unknown, AddClientFileParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/clients/add-file", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({ queryKey: ["client-files-list"] });
    },
  });
}

export function useListClientFilesQuery(
  params: ListClientFilesParams,
  enabled?: boolean
) {
  return useQuery<ListClientFilesResponse>({
    queryKey: ["client-files-list", params],
    queryFn: async () =>
      (await axios.get("/api/clients/list-files", { params })).data,
    enabled: enabled ?? true,
  });
}

export function useSetClientPriority() {
  const queryClient = useQueryClient();
  return useMutation<
    SetClientPriorityResponse,
    unknown,
    SetClientPriorityParams
  >({
    mutationFn: async (params) =>
      (await axios.post("/api/clients/set-priority", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({
        queryKey: ["client", params.uuid],
      });
      await queryClient.invalidateQueries({ queryKey: ["client-list"] });
      await queryClient.invalidateQueries({ queryKey: ["client-stats"] });
    },
  });
}

export function useSetClientSource() {
  const queryClient = useQueryClient();
  return useMutation<SetClientSourceResponse, unknown, SetClientSourceParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/clients/set-source", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({
        queryKey: ["client", params.uuid],
      });
      await queryClient.invalidateQueries({ queryKey: ["client-list"] });
      await queryClient.invalidateQueries({ queryKey: ["client-stats"] });
    },
  });
}

export function useAddLeadMutation() {
  const queryClient = useQueryClient();
  return useMutation<AddLeadResponse, unknown, AddLeadParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/clients/add-lead", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["project-list"] });
      await queryClient.invalidateQueries({ queryKey: ["stage-stats"] });
    },
  });
}

export function useGetLeadQuery(params: GetLeadParams) {
  return useQuery<GetLeadResponse>({
    queryKey: ["lead", params],
    queryFn: async () =>
      (await axios.get("/api/clients/get-lead", { params })).data,
  });
}

export function useListLeadsQuery(params: ListLeadsParams) {
  return useQuery<ListLeadsResponse>({
    queryKey: ["lead-list", params],
    queryFn: async () =>
      (await axios.get("/api/clients/list-leads", { params })).data,
  });
}

export function useGetStageStatsQuery() {
  return useQuery<GetStageStatsResponse>({
    queryKey: ["stage-stats"],
    queryFn: async () => (await axios.get("/api/clients/stage-stats")).data,
  });
}

export function useAddClientNoteMutation(
  options: UseMutationOptions<
    AddClientNoteResponse,
    unknown,
    AddClientNoteParams
  >
) {
  const queryClient = useQueryClient();
  return useMutation<AddClientNoteResponse, unknown, AddClientNoteParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/clients/add-note", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({
        queryKey: ["client", params.client_uuid],
      });
    },
    ...options,
  });
}

export function useDeleteClientNoteMutation() {
  const queryClient = useQueryClient();
  return useMutation<DeleteClientNoteResponse, unknown, DeleteClientNoteParams>(
    {
      mutationFn: async (params) =>
        (await axios.post("/api/clients/delete-note", params)).data,
      async onSuccess(_, params) {
        await queryClient.invalidateQueries({
          queryKey: ["client", params.client_uuid],
        });
      },
    }
  );
}
