import { Divider, Grid, IconButton } from "@mui/material";
import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import { useMemo } from "react";
import MynkFormEditable from "../components/MynkForm/MynkFormEditable";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import {
  Client,
  ClientSource,
  EditClientParams,
  Priority,
  RealEstateClientInfo,
  RealEstateClientLookingTo,
  RealEstateClientType,
} from "../api/types";
import {
  CONTACT_SOURCE_OPTIONS,
  PRIORITY_OPTIONS,
  REAL_ESTATE_LOOKING_TO_OPTIONS,
} from "../components/ChipSelect/commonOptions";
import { useEditClientMutation } from "../api/clients";
import { closeSnackbar, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import MynkFormConditional from "../components/MynkForm/MynkFormConditional";

export interface EditRealEstateContactDialogProps {
  open: boolean;
  onClose: () => void;
  contact?: RealEstateClientInfo | null;
}

export default function EditRealEstateContactDialog(
  props: EditRealEstateContactDialogProps
) {
  const { enqueueSnackbar } = useSnackbar();

  // Fetch edit contact to the server:
  const editContact = useEditClientMutation();
  const handleSubmit = async (values: any) => {
    if (!props.contact?.uuid) return;

    const params: EditClientParams = {
      uuid: props.contact.uuid,
      fields: {
        full_name: values["contactName"].trim() ?? "",
        email: values["contactEmail"].trim() ?? "",
        phone: values["contactPhone"].trim() ?? "",
        source: values["contactSource"] ?? ClientSource.OTHER,
        priority: values["contactPriority"] ?? Priority.LOW,
        looking_to: values["lookingTo"] ?? "",
        budget: values["budget"] ?? 0,
        preferences: values["preferences"] ?? "",
      },
    };

    await editContact.mutateAsync(params);
    enqueueSnackbar("Contact edited successfully", {
      variant: "success",
      action: (key) => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
      style: {
        borderRadius: "0.7rem",
      },
    });
    props.onClose();
  };

  const initialValues = useMemo(() => {
    if (!props.contact) return {};

    return {
      contactName: props.contact.full_name,
      contactEmail: props.contact.email,
      contactPhone: props.contact.phone,
      contactSource: props.contact.source,
      contactPriority: props.contact.priority,
      lookingTo: props.contact.looking_to,
      budget: props.contact.budget,
      preferences: props.contact.preferences,
    };
  }, [props.contact]);

  if (!props.contact) {
    // In case no contact was supplied - don't open the dialog.
    return <></>;
  }

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      title="Edit client"
      action="Apply changes"
      onSubmit={handleSubmit}
      initialValues={initialValues}
      loading={editContact.isPending}
    >
      <Grid container spacing={1} sx={{ width: "34.5714rem" }}>
        <Grid item xs={12}>
          <Divider sx={{ mb: 2 }} />
        </Grid>

        {/* Place for fill the client's name */}
        <Grid item xs={12}>
          <MynkFormEditable>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="contactName"
              label="Client's name"
              placeholder="Enter name"
              autoComplete={false}
              default=""
            />
          </MynkFormEditable>
        </Grid>

        {/* Place to fill the client's mail */}
        <Grid item xs={12}>
          <MynkFormEditable>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="contactEmail"
              label="Email"
              placeholder="Enter email address"
              autoComplete={false}
              default=""
            />
          </MynkFormEditable>
        </Grid>

        {/* Place to fill the client's phone number */}
        <Grid item xs={12}>
          <MynkFormEditable>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="contactPhone"
              label="Phone"
              placeholder="Enter phone number"
              autoComplete={false}
              default=""
            />
          </MynkFormEditable>
        </Grid>

        {props.contact.type == RealEstateClientType.PROSPECT && (
          <>
            <Grid item xs={12}>
              <Divider sx={{ mb: 1, mt: 1 }} />
            </Grid>

            {/* Place to change the client's looking to */}
            <Grid item xs={12}>
              <MynkFormControl
                kind={MynkFormControlKind.CHIP_SELECT}
                name="lookingTo"
                label="Looking to"
                default={""}
                options={REAL_ESTATE_LOOKING_TO_OPTIONS}
              />
            </Grid>

            {/* Place to change the prospect's budget */}
            <MynkFormConditional
              ifWhat={"lookingTo"}
              equalTo={RealEstateClientLookingTo.RENT}
            >
              <Grid item xs={12}>
                <MynkFormEditable>
                  <MynkFormControl
                    kind={MynkFormControlKind.TEXT}
                    name="budget"
                    label="Budget "
                    placeholder="0.00 / Month"
                    autoComplete={false}
                    isNum={true}
                    default={0}
                  />
                </MynkFormEditable>
              </Grid>
            </MynkFormConditional>

            <MynkFormConditional
              ifWhat={"lookingTo"}
              equalTo={RealEstateClientLookingTo.BUY}
            >
              <Grid item xs={12}>
                <MynkFormEditable>
                  <MynkFormControl
                    kind={MynkFormControlKind.TEXT}
                    name="budget"
                    label="Budget "
                    placeholder="0.00"
                    autoComplete={false}
                    isNum={true}
                    default={0}
                  />
                </MynkFormEditable>
              </Grid>
            </MynkFormConditional>

            <Grid item xs={12}>
              <Divider sx={{ mb: 1, mt: 1 }} />
            </Grid>
          </>
        )}

        {/* Place to change the prospect's preferences */}
        <Grid item xs={12}>
          <MynkFormEditable>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="preferences"
              label="Preferences"
              placeholder="Enter preferences"
              autoComplete={false}
              multiLine={true}
              default=""
              height={100}
            />
          </MynkFormEditable>
        </Grid>

        {/* Place to change the client's priority */}
        <Grid item xs={12}>
          <MynkFormControl
            kind={MynkFormControlKind.CHIP_SELECT}
            name="contactPriority"
            label="Priority"
            default={Priority.LOW}
            options={PRIORITY_OPTIONS}
          />
        </Grid>

        {/* Place to change the client's source */}
        <Grid item xs={12}>
          <MynkFormControl
            kind={MynkFormControlKind.CHIP_SELECT}
            name="contactSource"
            label="Lead source"
            default={ClientSource.OTHER}
            options={CONTACT_SOURCE_OPTIONS}
          />
        </Grid>
      </Grid>
    </MynkFormDialog>
  );
}
