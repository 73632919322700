import { useMutation, useQueryClient, useQuery, UseMutationOptions } from "@tanstack/react-query";
import axios from "axios";
import {
  ListExpensesResponse,
  AddExpenseParams,
  AddExpenseResponse,
  ListExpensesParams,
  DeleteExpenseResponse,
  DeleteExpenseParams,
  EditExpenseParams,
  EditExpenseResponse,
  ListAllExpensesResponse
} from "./types";

export function useListExpensesQuery(
  params: ListExpensesParams,
  enabled?: boolean
) {
  return useQuery<ListExpensesResponse>({
    queryKey: ["expenses-list", params],
    queryFn: async () => (await axios.post("/api/expenses/list-expenses", params)).data,

    enabled: enabled ?? true,
  });
}

export function useAddExpenseMutation(
  options: UseMutationOptions<AddExpenseResponse, unknown, AddExpenseParams>
) {
  const queryClient = useQueryClient();
  return useMutation<AddExpenseResponse, unknown, AddExpenseParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/expenses/add-expense", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["expenses-list"]});
    },
    ...options
  });
}

export function useDeleteExpenseMutation() {
  const queryClient = useQueryClient();
  return useMutation<DeleteExpenseResponse, unknown, DeleteExpenseParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/expenses/delete-expense", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["expenses-list"]});
    },
  });
}

export function useEditExpenseMutation() {
  const queryClient = useQueryClient();
  return useMutation<EditExpenseResponse, unknown, EditExpenseParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/expenses/edit-expense", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["expenses-list"]});
    },
  });
}

export function useListAllExpensesQuery() {
  return useQuery<ListAllExpensesResponse>({
    queryKey: ["expenses-list"],
    queryFn: async () => (await axios.post("/api/expenses/list-all-expenses")).data,
  });
}