import { MynkTab } from "../../../../components/MynkPage";
import ActionMenuButton from "../../../../components/ActionMenuButton";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import { Add, Email, Phone } from "@mui/icons-material";
import {
  SimpleTableCard,
  SimpleTableColumn,
  SimpleTableColumnType,
  TableType,
} from "../../../../components/SimpleTable";
import {
  useDeleteClientMutation,
  useListClientsQuery,
  useSetClientPriority,
} from "../../../../api/clients";
import {
  Box,
  Link,
  Popover,
  Stack,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup
} from "@mui/material";
import {
  Client,
  ClientSource,
  DeleteClientParams,
  FilterOption,
  LastEvaluatedKey,
  ListClientsResponse,
  PhotographyClientInfo,
  Priority,
  RealEstateClientInfo,
  SearchOption,
  SortOption,
  WorkflowType,
} from "../../../../api/types";
import { useNavigate } from "react-router-dom";
import { AddPhotographyContactDialog } from "../../../../dialogs/AddPhotographyContact";
import ConfirmActionDialog from "../../../../components/ConfirmActionDialog";
import ThreeDotsButton from "../../../../components/ThreeDotsButton";
import EditContactDialog from "../../../../dialogs/EditContact";
import { atom, useAtom, useAtomValue } from "jotai";
import PriorityChip from "../../../../components/PriorityChip";
import {
  FILTER_CLIENTS_OPTIONS,
  SORT_CLIENTS_OPTIONS,
} from "../../../../components/ChipSelect/commonOptions";
import { PATHS, WorkflowPath, makePath } from "../../../../paths";
import { currentWorkflowAtom } from "../../../../components/MainAppBar/UserControlPopup";
import { ClientsAllSubpageNewButton } from "../tabs/All";
import EditRealEstateContactDialog from "../../../../dialogs/EditRealEstateContact";
import { DateTime } from "luxon";

export const selectedClientAtom = atom<
  Client | PhotographyClientInfo | RealEstateClientInfo | null
>(null);
export const openedEditAtom = atom(false);
export const openedDeleteAtom = atom(false);

const PHOTOGAPHY_BASE_COLUMNS: SimpleTableColumn[] = [
  {
    key: "full_name",
    label: "Client's name",
    type: SimpleTableColumnType.NAME
  },
  {
    key: "created_at",
    label: "Creation date",
    type: SimpleTableColumnType.DATE,
  },
  {
    key: "source",
    label: "Source",
    type: SimpleTableColumnType.NAME,
  },
  {
    key: "total_spent",
    label: "Total spent",
    type: SimpleTableColumnType.USD,
  },
  {
    key: "priority",
    label: "Priority",
    type: SimpleTableColumnType.PRIORITY,
  },
];

const REAL_ESTATE_BASE_COLUMNS: SimpleTableColumn[] = [
  {
    key: "full_name",
    label: "Client's name",
  },
  {
    key: "type",
    label: "Type",
    type: SimpleTableColumnType.REAL_ESTATE_CLIENT_TYPE,
  },
  {
    key: "priority",
    label: "Priority",
    type: SimpleTableColumnType.PRIORITY,
  },
];

const PAGE_SIZE = 10;

enum threeDotsOptions {
  EDIT = "edit-client",
  DELETE = "delete-client",
}

const DOTS_MENU = [
  {
    id: threeDotsOptions.EDIT,
    label: "Edit",
  },
  {
    id: threeDotsOptions.DELETE,
    label: "Delete",
  },
];

interface ThreeDotsClientProps {
  client: Client;
}

/**
 * Renders a button with three dots. When the button is clicked, it opens a menu with options to edit or delete a task.
 * Also renders two dialog components, `ConfirmDeleteDialog` and `AddPhotographyContactDialog`, which are opened when the corresponding menu options are selected.
 *
 * @returns The rendered `ThreeDotsTask` component with a button and two dialog components.
 */
function ThreeDotsClient(props: ThreeDotsClientProps) {
  const [selectedClient, setSelectedClient] = useAtom(selectedClientAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);

  /**
   * Handles the click event on the menu options.
   * Sets the corresponding state variable to `true` to open the corresponding dialog component.
   *
   * @param id - The ID of the clicked menu option.
   */
  const handleClickItem = (id: string) => {
    setSelectedClient(props.client);

    switch (id) {
      case threeDotsOptions.EDIT:
        setOpenedEdit(true);
        break;
      case threeDotsOptions.DELETE:
        setOpenedDelete(true);
        break;
    }
  };

  return (
    <ThreeDotsButton
      menu={DOTS_MENU}
      onClickItem={handleClickItem}
      children={undefined}
    ></ThreeDotsButton>
  );
}

export function ContactsTableCard() {
  const [search, setSearch] = useState<SearchOption>({ query: "" });
  const [filter, setFilter] = useState<FilterOption>({ by: "", query: "" });
  const [sort, setSort] = useState<SortOption>({ by: "", descending: false });

  const workflowType = useAtomValue(currentWorkflowAtom)?.type;

  // Initialize selectedColumns with the default visible columns
  const defaultColumns = useMemo(() => {
    return workflowType === WorkflowType.PHOTOGRAPHY
      ? PHOTOGAPHY_BASE_COLUMNS.map((col) => col.key)
      : workflowType === WorkflowType.REAL_ESTATE
      ? REAL_ESTATE_BASE_COLUMNS.map((col) => col.key)
      : [];
  }, [workflowType]);

  const [selectedColumns, setSelectedColumns] = useState<string[]>(defaultColumns);
  const [selectedPage, setSelectedPage] = useState(0);
  const [current, setCurrent] = useState<null | ListClientsResponse>(null);

  const { data, isPending } = useListClientsQuery({
    page_size: PAGE_SIZE,
    page_index: selectedPage,
    retrieve_options: {
      search: search,
      filter: filter,
      sort: sort,
    },
  });

  useEffect(() => {
    setSelectedPage(0);
  }, [search, filter, sort]);

  useEffect(() => {
    if (!isPending && data) {
      setCurrent(data);
    }
  }, [data, isPending]);

  const navigate = useNavigate();
  const handleViewContact = (row: { uuid: string }) => {
    navigate(
      makePath(
        `/${workflowType}` as WorkflowPath,
        PATHS.viewContact(row.uuid).general
      )
    );
  };

  const additionalColumnsFromData = useMemo(() => {
    if (!data || !data.clients || data.clients.length === 0) return [];
    const dynamicKeys = new Set<string>();
  
    // Iterate over the clients data and extract all unique keys
    data.clients.forEach((client: any) => {
      Object.keys(client).forEach((key) => {
        // Check if the value is a string and not email or phone
        const value = client[key];
        if (
          (typeof value === "string" || typeof value === "number") &&
          key !== "email" &&
          key !== "phone" &&
          !key.includes("uuid")
        ) {
          dynamicKeys.add(key);
        }
      });
    });
  
    // Remove keys that are already in baseColumns to avoid duplicates
    return [...dynamicKeys].filter((key) => !defaultColumns.includes(key));
  }, [data, defaultColumns]);

  const setClientPriority = useSetClientPriority();
  
  const allColumns = useMemo(() => {
    const baseColumns =
      workflowType === WorkflowType.PHOTOGRAPHY
        ? PHOTOGAPHY_BASE_COLUMNS
        : workflowType === WorkflowType.REAL_ESTATE
        ? REAL_ESTATE_BASE_COLUMNS
        : [];

    const extraColumns = [
      {
        key: "info",
        label: "",
        render(_: unknown, row: any) {
          const [anchorEl, setAnchorEl] = useState<Element | null>(null);
          const [info, setInfo] = useState("");

          const handleClick = (
            event: React.MouseEvent<SVGSVGElement, MouseEvent>,
            value: string
          ) => {
            setAnchorEl(event.currentTarget);
            setInfo(value);
          };

          const handleClose = () => {
            setAnchorEl(null);
          };

          const open = Boolean(anchorEl);
          const id = open ? "simple-popover" : undefined;

          return (
            <Stack direction="row" alignItems="center" spacing={3} onClick={(e) => {e.stopPropagation();}}>
              <Stack direction={'row'} spacing={1.5}>
                {row.email ? (
                  <Email
                    sx={{
                      color: "#75b3ff",
                      width: "1.6rem",
                      height: "1.6rem",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleClick(e, row.email)}
                  />
                ) : (
                  <Email
                    sx={{
                      color: "#A9A9A9",
                      width: "1.6rem",
                      height: "1.6rem",
                    }}
                  />
                )}

                {row.phone ? (
                  <Phone
                    sx={{
                      color: "#75b3ff",
                      width: "1.6rem",
                      height: "1.6rem",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleClick(e, row.phone)}
                  />
                ) : (
                  <Phone
                    sx={{
                      color: "#A9A9A9",
                      width: "1.6rem",
                      height: "1.6rem",
                    }}
                  />
                )}
              </Stack>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Typography sx={{ p: 2 }}>{info}</Typography>
              </Popover>
              <ThreeDotsClient client={row} />
            </Stack>
          );
        },
      },
    ];

    // Check if 'priority' column already exists in baseColumns before adding it
    if (baseColumns.some((col) => col.key === "priority")) {
      baseColumns.forEach((col) => {
        if (col.key === "priority") {
          col.render = (value: any, row: { uuid: string; priority: Priority }) => {
            const handleChangePriority = (value: string) => {
              setClientPriority.mutate({
                uuid: row.uuid!,
                priority: value as Priority,
              });
            };

            return (
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <PriorityChip
                  value={row.priority}
                  onChange={handleChangePriority}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "7.7rem",
                    height: "2.5rem",
                    textAlign: "center",
                  }}
                />
              </Box>
            );
          };
        }
      });
    }

    if (baseColumns.some((col) => col.key === "source")) {
      baseColumns.forEach((col) => {
        if (col.key === "source") {
          col.render = (value: any) => (
            <Typography textTransform="capitalize">
              {value.replace("_", " ")}
            </Typography>
          );
        }
      });
    }

    const dynamicColumns = additionalColumnsFromData.map((key) => ({
      key,
      label: (key.charAt(0).toUpperCase() + key.slice(1)).replace("_", " "), // Format label
      render: (value: any) => {
        // Check if the value is a date in ISO format
        const isDate = typeof value === "string" && !isNaN(Date.parse(value));
        
        // If it's a date, format it
        if (isDate) {
          const date = DateTime.fromISO(value); // Use Luxon to parse the ISO string
          return (
            <Stack direction="row">
              <Typography fontSize={"1.15rem"} color="#404040" fontWeight="bold">
                {date.toFormat("EEEE")}, {/* e.g. Wednesday */}
              </Typography>
              <Typography fontSize={"1.15rem"} color="#404040B2">
                &nbsp;{date.toFormat("LLL dd, yyyy")} {/* e.g. Oct 16, 2024 */}
              </Typography>
            </Stack>
          );
        }
    
        // Render the value or "N/A" if it's not a date or is empty
        return <Typography>{value === 0 ? 0 : value || "N/A"}</Typography>;
      },
    }));
    
    return [...baseColumns, ...dynamicColumns, ...extraColumns];
  }, [workflowType]);

  const columns = useMemo(() => {
    const essentialColumns = ["info"];
    const visibleColumns = selectedColumns.length === 0 ? allColumns : allColumns.filter((col) => selectedColumns.includes(col.key));
    return [...new Set([...visibleColumns, ...allColumns.filter(col => essentialColumns.includes(col.key))])];
  }, [selectedColumns, allColumns]);

  const mainColumns = useMemo(() => {
    return columns.filter(col => !["info"].includes(col.key));
  }, [columns]);

  const columnWidth = useMemo(() => {
    const totalMainColumns = mainColumns.length;
    return `${100 / totalMainColumns}%`;
  }, [mainColumns]);

  const allColumnsWithoutInfo = allColumns.filter((col) => col.key !== "info");

  return (
    <>
      <SimpleTableCard
        title="Contacts"
        data={current?.clients}
        columns={columns}
        tableType={TableType.CLIENTS}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        pageSize={PAGE_SIZE}
        numOfPages={current?.num_of_pages ?? 1}
        total={current?.total ?? 0}
        isPending={isPending}
        searchState={[search, setSearch]}
        filterState={[filter, setFilter]}
        sortState={[sort, setSort]}
        filterOptions={FILTER_CLIENTS_OPTIONS}
        sortOptions={SORT_CLIENTS_OPTIONS}
        onClickLink={(row) => handleViewContact(row)}
        columnDivision={Array(columns.length).fill(columnWidth)}
        allColumnsWithoutInfo={allColumnsWithoutInfo}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
      />
    </>
  );
}

export enum EditContactType {
  REGULAR = "regular",
  REAL_ESTATE = "real_estate",
}
