import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import {
  Availability,
  EntranceDateTypeOptions,
  FilterOption,
  ListingStage,
  ListingType,
  RealEstateListing,
  SearchOption,
  SortOption,
  SquareSizeUnits,
  WorkflowType,
} from "../api/types";
import { fetchAllImagesByUuids, formatMoney, zonedDate } from "../util/common";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { format } from "date-fns";

import bedroomIcon from "../assets/icons/bedroom-icon.svg";
import bathroomIcon from "../assets/icons/bathroom-icon.svg";
import propertyAreaIcon from "../assets/icons/property-area-icon.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import ListingTypeReadOnlyChip from "./ListingTypeReadOnlyChip";
import {
  useListProjectsQuery,
  useSetListingAvailabilityMutation,
} from "../api/projects";
import { PATHS, WorkflowPath, makePath } from "../paths";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AvailabilityReadOnlyChip from "./AvailabilityReadOnlyChip";
import { useAtomValue } from "jotai";
import { userCurrencyAtom } from "../layouts/Main";

export enum ListingViewType {
  LONG_CARD = "long_card",
  BOX = "box",
}

interface ListingCardProps {
  listing: RealEstateListing;
  viewType?: ListingViewType;
  handleChecked?: (uuid: string, add: boolean) => void;
  isChecked?: boolean;
  sx?: SxProps;
}

export function ListingCard(props: ListingCardProps) {

  const entrance_date = useMemo(() => {
    if (props.listing.entrance_date === EntranceDateTypeOptions.READY_TO_MOVE) {
      return "Ready to move";
    } else {
      const date = zonedDate(props.listing.entrance_date)
      if (!date) {
        return "Invalid date";
      }
      return date.toFormat("LLL dd, yyyy");
    }
  }, [props.listing.entrance_date]);

  const [isChecked, setIsChecked] = useState(props.isChecked ?? false);

  const handleCheckboxClicked = () => {
    if (props.handleChecked) {
      props.handleChecked(props.listing.uuid, !isChecked);
    }
    setIsChecked(!isChecked);
  };

  const setListingAvailability = useSetListingAvailabilityMutation();
  const handleChangeAvailability = (value: string) => {
    setListingAvailability.mutate({
      uuid: props.listing.uuid,
      availability: value as Availability,
    });
  };

  // const setListingType = useSetListingTypeMutation();
  // const handleChangeType = (value: string) => {
  //   setListingType.mutate({
  //     uuid: props.listing.uuid,
  //     type: value as ListingType,
  //   });
  // };

  const currencyCode = useAtomValue(userCurrencyAtom);

  const navigate = useNavigate();
  const handleClickShowMore = useCallback(() => {
    navigate(
      makePath(
        WorkflowPath.REAL_ESTATE,
        PATHS.viewProject(props.listing.uuid).general
      )
    );
  }, []);

  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (props.listing.files_uuids) {
          const imgSrcs = await fetchAllImagesByUuids([
            props.listing.files_uuids[0],
          ]);
          setImageSrc(imgSrcs[0]);
      }
    };

    fetchData();
  }, [props.listing.files_uuids]);

  if (props.viewType == ListingViewType.LONG_CARD) {
    return (
      <Box
        sx={{
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%)",
          borderRadius: "0.5714rem",
          boxShadow: "0px 0.3571rem 1.4286rem rgba(117, 179, 255, 0.35)",
          maxHeight: "17.1429rem",
          p: 1.7,
          ...props.sx,
        }}
      >
        <Stack direction="row" maxHeight={"17.1429rem"}>
          {imageSrc === null ? (
            <Box
              sx={{
                width: "17.1429rem",
                height: "17.1429rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={"3.5714rem"} />
            </Box>
          ) : (
            <Box
              component="img"
              src={imageSrc ?? ""}
              sx={{
                width: "16.4286rem",
                height: "16.4286rem",
                minWidth: "16.4286rem",
                minHeight: "16.4286rem",
                objectFit: "cover",
                borderRadius: 2,
                cursor: "pointer",
              }}
              onClick={handleClickShowMore}
            />
          )}
          <Stack sx={{ width: "100%" }}>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
                mt: 0.7,
                mr: 1.5,
              }}
            >
              <ListingTypeReadOnlyChip value={props.listing.type} width={100} />
              <AvailabilityReadOnlyChip
                value={props.listing.availability}
                width={115}
              />
            </Stack>
            <Stack sx={{ ml: 4 }} spacing={3}>
              <Box>
                {/* Price area (add "/ Month" if the property is for rent): */}
                <Typography
                  fontFamily="Helvetica Neue Bold"
                  fontSize={"1.7143rem"}
                  fontWeight="bold"
                  sx={{ display: "inline-block", mt: 0.7, color: "black" }}
                >
                  {formatMoney(props.listing.price, currencyCode)}
                  {props.listing.type == ListingType.FOR_RENT ? " / " : ""}
                  <Typography
                    fontFamily="Helvetica Neue"
                    fontSize={"1.2857rem"}
                    fontWeight="bold"
                    sx={{ display: "inline-block", color: "black"  }}
                  >
                    {props.listing.type == ListingType.FOR_RENT ? "Month" : ""}
                  </Typography>
                </Typography>

                {/* Name (or Location) area */}
                <Typography
                  fontFamily="Helvetica Neue"
                  fontSize={"1.2143rem"}
                  sx={{ color: "#9B9B9B" }}
                >
                  {props.listing.name}
                </Typography>
              </Box>
              {/* Property's info area */}
              <Stack direction="row" spacing={5}>
                {/* Bedrooms info */}
                <Stack direction="row" alignItems="center">
                  <Box
                    component="img"
                    src={bedroomIcon}
                    sx={{ maxHeight: "0.9286rem", mr: 1 }}
                  />
                  <Typography fontFamily="Helvetica Neue" fontSize={"1.0714rem"}>
                    {props.listing.bedrooms} Beds
                  </Typography>
                </Stack>
                {/* Bathrooms info */}
                <Stack direction="row" alignItems="center">
                  <Box
                    component="img"
                    src={bathroomIcon}
                    sx={{ maxHeight: "1rem", mr: 1 }}
                  />
                  <Typography fontFamily="Helvetica Neue" fontSize={"1.0714rem"}>
                    {props.listing.bathrooms} Baths
                  </Typography>
                </Stack>
                {/* property's area info */}
                <Stack direction="row" alignItems="center">
                  <Box
                    component="img"
                    src={propertyAreaIcon}
                    sx={{ maxHeight: "1.2857rem", mr: 1 }}
                  />
                  <Typography fontFamily="Helvetica Neue" fontSize={"1.0714rem"}>
                    {props.listing.size} {props.listing.size_units}
                  </Typography>
                </Stack>
              </Stack>

              {/* Entrance date and listing's name */}
              <Typography fontFamily="Helvetica Neue" fontSize={"1rem"} marginBottom={2}>
                Entrance date: {entrance_date}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ justifyContent: "flex-end" }}>
              <Typography
                sx={{ color: "#75B3FF", cursor: "pointer", mr: 2.2 }}
                onClick={handleClickShowMore}
              >
                Show more
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    );
  } else if (props.viewType == ListingViewType.BOX) {
    return (
      <Stack
        sx={{
          background: "white",
          borderRadius: "8px",
          boxShadow: "0px 5px 20px rgba(117, 179, 255, 0.25)",
          p: 1.2,
          cursor: "pointer",
          ...props.sx,
        }}
        onClick={handleCheckboxClicked}
      >
        <Box>
          <Checkbox checked={isChecked} onClick={handleCheckboxClicked} />
        </Box>
        {imageSrc === null ? (
          <Box
            sx={{
              width: "17.8571rem",
              height: "17.8571rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={"3.5714rem"} />
          </Box>
        ) : (
          <Box
            component="img"
            src={imageSrc ?? ""}
            sx={{
              width: "17.8571rem",
              height: "17.8571rem",
              objectFit: "cover",
              borderRadius: 2,
            }}
          />
        )}

        <Stack sx={{ mt: 1 }}>
          <Stack direction="row">
            {/* Price area (add "/ Month" if the property is for rent): */}
            <Typography
              fontFamily="Helvetica Neue Bold"
              fontSize={"1.4286rem"}
              fontWeight="bold"
              sx={{ display: "inline-block" }}
            >
              {formatMoney(props.listing.price, currencyCode)}
              {props.listing.type == ListingType.FOR_RENT ? " / " : ""}
              <Typography
                fontFamily="Helvetica Neue"
                fontSize={"1.0714rem"}
                fontWeight="bold"
                sx={{ display: "inline-block" }}
              >
                {props.listing.type == ListingType.FOR_RENT ? "Month" : ""}
              </Typography>
            </Typography>
            <Box sx={{ flex: 1 }} />
            <ListingTypeReadOnlyChip
              value={props.listing.type}
              width={70}
              height={23}
            />
          </Stack>
          {/* Name (or Location) area */}
          <Typography
            fontFamily="Arial"
            fontSize={"0.9286rem"}
            sx={{ color: "#9B9B9B" }}
          >
            {props.listing.name}
          </Typography>

          {/* Property's info area */}
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
            {/* Bedrooms info */}
            <Stack direction="row" alignItems="center">
              <Box
                component="img"
                src={bedroomIcon}
                sx={{ maxHeight: "0.7857rem", mr: 1 }}
              />
              <Typography fontFamily="Helvetica Neue" fontSize={"0.8571rem"}>
                {props.listing.bedrooms} Beds
              </Typography>
            </Stack>
            {/* Bathrooms info */}
            <Stack direction="row" alignItems="center">
              <Box
                component="img"
                src={bathroomIcon}
                sx={{ maxHeight: "0.8571rem", mr: 1 }}
              />
              <Typography fontFamily="Helvetica Neue" fontSize={"0.8571rem"}>
                {props.listing.bathrooms} Baths
              </Typography>
            </Stack>
            {/* property's area info */}
            <Stack direction="row" alignItems="center">
              <Box
                component="img"
                src={propertyAreaIcon}
                sx={{ maxHeight: "1rem", mr: 1 }}
              />
              <Typography fontFamily="Helvetica Neue" fontSize={"0.8571rem"}>
                {props.listing.size} {props.listing.size_units}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  } else {
    return <></>;
  }
}

const PAGE_SIZE = 10;

interface ListingsViewProps {
  availability?: Availability;
  search?: SearchOption;
  filter?: FilterOption;
  sort?: SortOption;
}

export default function ListingsView(props: ListingsViewProps) {
  const [current, setCurrent] = useState<RealEstateListing[]>([]);
  const [pageIndex, setPageIndex] = useState(0);

  const { data, isPending } = useListProjectsQuery({
    page_size: PAGE_SIZE,
    page_index: pageIndex,
    retrieve_options: {
      search: props.search,
      filter: props.filter,
      sort: props.sort,
    },
    availability: props.availability,
  });

  const loadMore = () => {
    if (current && pageIndex < (data?.num_of_pages ?? 0) - 1) {
      setPageIndex(pageIndex + 1);
    }
  };

  useEffect(() => {
    setCurrent([]);
  }, [props.search, props.filter, props.sort]);

  // *Append* more listings:
  useEffect(() => {
    if (data) {
      setCurrent((prevState) => {
        const newData = (data?.projects as RealEstateListing[]) || [];
        return [...(prevState ?? []), ...newData];
      });
    }
  }, [data, isPending]);

  return (
    <Stack alignItems="center">
      {isPending && (
        <CircularProgress sx={{ display: "table", mx: "auto", my: 3 }} />
      )}
      <Grid container spacing={2.5} sx={{ p: 2, width: "100%" }}>
        {current?.map((item, i) => (
          <Grid
            item
            sx={{
              "@media (max-width: 70rem)": { width: "100%" },
              "@media (min-width: 70rem)": { width: "50%" },
            }}
          >
            <ListingCard
              viewType={ListingViewType.LONG_CARD}
              listing={item as RealEstateListing}
            />
          </Grid>
        ))}
      </Grid>
      {current && pageIndex < (data?.num_of_pages ?? 0) - 1 ? (
        <IconButton
          sx={{
            color: "#f5fbfe",
            background: "linear-gradient(180deg, #D288F2 0%, #6DBFF2 100%)",
            width: 40,
            height: 40,
            mt: 2,
            "&:hover": {
              background:
                "linear-gradient(180deg, #D288F2A6 0%, #6DBFF2A6 100%)",
            },
          }}
          onClick={loadMore}
        >
          <ExpandCircleDownIcon sx={{ width: 40, height: 40 }} />
        </IconButton>
      ) : (
        <></>
      )}
    </Stack>
  );
}
