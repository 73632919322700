import { MynkFormControlCommonProps } from "./controls/types";
import {
  MynkFormTextField,
  MynkFormTextFieldProps,
} from "./controls/TextField";
import { Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { MynkFormContext, MynkFormEditableContext } from "./context";
import {
  MynkFormChipSelect,
  MynkFormChipSelectProps,
} from "./controls/ChipSelect";
import {
  MynkFormContactSearch,
  MynkFormContactSearchProps,
} from "./controls/ContactSearch";
import {
  MynkFormDateSelect,
  MynkFormDateSelectProps,
} from "./controls/DateSelect";
import { MynkFormControlKind, MynkFormControlProps } from "./controlTypes";
import { MynkFormIconedChipSelect } from "./controls/IconedChipSelect";
import MynkFormFileUpload from "./controls/FileUpload";
import { MynkFormColorPicker } from "./controls/ColorPicker";
import { MynkFormColoredChipSelect } from "./controls/ColoredChipSelect";
import { MynkFormProjectSearch } from "./controls/ProjectsSearch";
import { MynkFormPhoneNumber } from "./controls/PhoneNumber";
import { MynkFormInstagramSearch } from "./controls/InstagramSearch";

export function MynkFormControl<T>(props: MynkFormControlProps) {
  const context = useContext(MynkFormContext);
  useEffect(() => {
    if (context) {
      context.trackControl({
        name: props.name,
        default: props.default,
      });
    }
  }, []);

  const editableContext = useContext(MynkFormEditableContext);
  useEffect(() => {
    if (editableContext && editableContext.control === null) {
      editableContext.setControl(props);
    }
  }, [editableContext]);

  switch (props.kind) {
    case MynkFormControlKind.TEXT:
      return <MynkFormTextField {...(props as any)} />;

    case MynkFormControlKind.CHIP_SELECT:
      return <MynkFormChipSelect {...(props as any)} />;

    case MynkFormControlKind.ICONED_CHIP_SELECT:
      return <MynkFormIconedChipSelect {...(props as any)} />;

    case MynkFormControlKind.CONTACT_SEARCH:
      return <MynkFormContactSearch {...(props as any)} />;
    
    case MynkFormControlKind.PROJECT_SEARCH:
      return <MynkFormProjectSearch {...(props as any)} />;
      
    case MynkFormControlKind.DATE_SELECT:
      return <MynkFormDateSelect {...(props as any)} />;

    case MynkFormControlKind.FILE_UPLOAD:
      return <MynkFormFileUpload {...(props as any)} />;

    case MynkFormControlKind.COLOR_PICKER:
      return <MynkFormColorPicker {...(props as any)} />;
    
    case MynkFormControlKind.COLORED_CHIP_SELECT:
      return <MynkFormColoredChipSelect {...(props as any)} />;
    
    case MynkFormControlKind.PHONE_NUMBER:
      return <MynkFormPhoneNumber {...(props as any)} />;

    case MynkFormControlKind.INSTAGRAM_HANDLE:
      return <MynkFormInstagramSearch {...(props as any)} />;

    default:
      return <Typography>Invalid mynk form control kind</Typography>;
  }
}
