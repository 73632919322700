import { atom, useAtom } from 'jotai';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';


export enum LanguageCode {
    English = 'en',
    Hebrew = 'he',
}
export const languageAtom = atom(LanguageCode.English);


const languages = [
  { code: LanguageCode.English, name: 'English' },
  { code: LanguageCode.Hebrew, name: 'עברית' },
];

export function LanguagePicker() {
  // Use Jotai's atom for language state management
  const [selectedLanguage, setSelectedLanguage] = useAtom(languageAtom);

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    console.log(`Language changed to: ${newLanguage}`);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="language-select-label">Language</InputLabel>
        <Select
          labelId="language-select-label"
          id="language-select"
          value={selectedLanguage}
          label="Language"
          onChange={handleLanguageChange}
          sx={{
            paddingRight: 1,
          }}
        >
          {languages.map((lang) => (
            <MenuItem key={lang.code} value={lang.code}>
              {lang.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
