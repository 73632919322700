import React, {ReactNode, useEffect, useState} from 'react';
import { useListTasksQuery } from '../api/tasks';
import RemindersDialog from '../dialogs/Reminders';
import { Box, CircularProgress, Stack } from '@mui/material';
import mynkTextLogo from '../assets/images/mynk-textlogo.svg';
import { Task } from '../api/types';

export interface RemindersGuardProps {
    children: ReactNode;
}

export default function RemindersGuard(props: RemindersGuardProps) {
    const { data, isPending } = useListTasksQuery({
        due_days_max: 0,
        completed: false,
    });
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [reminderTasks, setReminderTasks] = useState<Array<Task>>([]);

    useEffect(() => {
        if (data && data.tasks) {
            const tasksWithReminder = data.tasks.filter(task => task.is_reminder);
            setReminderTasks(tasksWithReminder);
            if (tasksWithReminder.length > 0) {
                setIsDialogOpen(true);
            }
        }
    }, [data]);

    const onClose = () => {
        setIsDialogOpen(false);
    }

    return (
        <>
            <RemindersDialog
                open={isDialogOpen}
                onClose={onClose}
                tasks={reminderTasks}
            />
            {isPending && (
                <Stack justifyContent="center" alignItems="center" sx={{height: '100vh'}}>
                    <CircularProgress size={"4.2857rem"} thickness={32} sx={{ color: "#c05ec6" }}/>
                    <Box height={"1.4286rem"} />
                    <Box component="img" src={mynkTextLogo} sx={{ width: "8.5714rem" }}/>
                </Stack>
            )}
            {isPending ? null : props.children}
        </>
    );
}