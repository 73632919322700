import { AccountCircle, Message } from "@mui/icons-material";
import {Box, LinearProgress, Stack, Typography} from "@mui/material";
import mynkLogo from "../../../assets/images/mynk-textlogo.svg";
import ProfilePicture from "../../ProfilePicture";
import './styling.css';
import { DateTime } from "luxon";
import ReactMarkdown from 'react-markdown';
import { PhotoType } from "../../../pages/CommonComponents/Settings/common/UploadOnePhoto";
import {InvoiceDetails} from "../../../api/types";
import {TemplatePreviewImage} from "../../../pages/MynkPhotography/InstantiateTemplate/TemplatePreviewImage";

export enum MessageFrom {
  ME = "me",
  YOU = "you",
}

export type ChatBubble = {
  from: MessageFrom;
  time?: DateTime;
  content?: null | string;
  invoice?: InvoiceDetails;
};


interface ChatBubbleProps {
  bubble: ChatBubble;
}
export function ChatBubbleBox(props: ChatBubbleProps) {
  const isMe = props.bubble.from === MessageFrom.ME;

  const bubbleStyle = {
    bgcolor: isMe ? "#75B3FF" : "white",
    color: isMe ? "#ffffff" : "#000000",
    borderRadius: isMe ? "0.8571rem 0.8571rem 0 0.8571rem" : "0.8571rem 0.8571rem 0.8571rem 0",
    //      alignSelf: isMe ? "flex-end" : "flex-start",
  };

  return (
    <Stack direction="row" justifyContent={isMe ? "flex-end" : "flex-start"} sx={{ alignItems: "end"}}>
      { isMe ? (<></>) : <Box component="img" src={mynkLogo} sx={{ width: "3rem", pr: 1.5 }} />}
      <Box
        sx={{
          maxWidth: "57%",
          mt: 1,
          ...bubbleStyle,
        }}
      >
        <Stack marginRight={0.5} sx={{ pt: "0.7rem", pb: 1.4, pl: 2, pr: 2}}>
          {props.bubble.invoice && (
            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontSize: "1.3rem", fontFamily: "Arial", ...bubbleStyle, }}>
                <TemplatePreviewImage
                  template_uuid={props.bubble.invoice.template_uuid}
                  client_uuid={props.bubble.invoice.client_uuid}
                  item_name={props.bubble.invoice.item_name}
                  item_price={props.bubble.invoice.item_price}
                  item_quantity={props.bubble.invoice.item_quantity}
                />
              </Typography>
            </Stack>
          )}

          {props.bubble.content !== undefined ? (
            <Typography sx={{ fontSize: "1.3rem", fontFamily: "Arial", ...bubbleStyle, }}><ReactMarkdown>{props.bubble.content}</ReactMarkdown></Typography>
          ) : (
            <Stack sx={{ width: '5rem' }} alignItems="center">
              <div className="dot-flashing" />
            </Stack>
          )}

          {props.bubble.time && (
            <Typography
              sx={{
                fontFamily: "Helvetica Neue",
                fontSize: "0.9rem",
                color: bubbleStyle.color + "AA",
                ...(isMe ? { alignSelf: "flex-end" } : { alignSelf: "flex-start" })
              }}
            >
              {props.bubble.time.toFormat("HH:mm")}
            </Typography>
          )}
        </Stack>
      </Box>
      { isMe ? <ProfilePicture type={PhotoType.PROFILE} size="3rem" sx={{ ml: 1, mb: -0.5 }} /> : (<></>)}

    </Stack>
  );
}
