import { Priority } from "../api/types";
import { Chip, SxProps } from "@mui/material";
import React from "react";
import { ChipSelect } from "./ChipSelect";
import { PRIORITY_OPTIONS } from "./ChipSelect/commonOptions";

import priorityChipArrowGreen from "../assets/icons/prioritychip-arrow-icon-green.svg";
import priorityChipArrowBlue from "../assets/icons/prioritychip-arrow-icon-blue.svg";
import priorityChipArrowOrange from "../assets/icons/prioritychip-arrow-icon-orange.svg";

export interface PriorityChipProps {
  value: Priority;
  sx?: SxProps;
  onChange?: (value: string) => void | null;
}

export default function PriorityChip(props: PriorityChipProps) {
  
  switch (props.value) {
    case Priority.LOW:
      return (
        <ChipSelect
          options={PRIORITY_OPTIONS}
          value="low"
          sx={{
            color: "#37CE4A",
            bgcolor: "#37CE4A33",
            ...props.sx,
          }}
          onChange={props.onChange}
          arrowIcon={priorityChipArrowGreen}
        />
      );

    case Priority.MEDIUM:
      return (
        <ChipSelect
          options={PRIORITY_OPTIONS}
          value="medium"
          sx={{
            color: "#75B3FF",
            bgcolor: "#75B3FF33",
            ...props.sx,
          }}
          onChange={props.onChange}
          arrowIcon={priorityChipArrowBlue}
        />
      );

    case Priority.HIGH:
      return (
        <ChipSelect
          options={PRIORITY_OPTIONS}
          value="high"
          sx={{
            color: "#FF7A00",
            bgcolor: "#FF7A0033",
            ...props.sx,
          }}
          onChange={props.onChange}
          arrowIcon={priorityChipArrowOrange}
        />
      );
    
    default:
      return (
        <ChipSelect
          options={PRIORITY_OPTIONS}
          value=""
          sx={{
            color: "#5d6063",
            bgcolor: "#ecf0f5",
            ...props.sx
          }}
          onChange={props.onChange}
          />
      )
  }
}
