import { Stack, Box, TextField, Typography } from '@mui/material'
import { MynkFormDialog } from '../components/MynkForm'
import { BoxWithGradientFrame } from '../components/GradientFrame'

type MessageMeDialogProps = {
  open: boolean;
  onClose: () => void;
};

type InputProps = {
  placeholder: string;
  inputName: string;
};

function Input({placeholder, inputName}: InputProps) {
  return (
      <BoxWithGradientFrame>
          <Box sx={{
                  width: '10rem',
                  height: inputName === 'message' ? '7rem' : '3.5rem',
                  marginRight: '0.5rem',
                  marginTop: '0.2rem',
                  marginLeft: '1rem',
              }}
          >
              <TextField
                  variant="standard"
                  placeholder={placeholder}
                  fullWidth
                  InputProps={{
                      disableUnderline: true,
                      sx: {
                          height: '3.5rem',
                          width: '33rem',
                      },
                  }}
              />
          </Box>
      </BoxWithGradientFrame>
  )
}

export default function MessageMeDialog({ open, onClose }: MessageMeDialogProps) {
  return (
    <MynkFormDialog
      open={open}
      onClose={onClose}
      action='send'
      onSubmit={() => {}}
      loading={false}
    >
      <Stack sx={{width: '35rem', height: '35rem', marginX: '1.5rem'}}>
        <h1>New message</h1>
        <Typography mb={0.4} fontSize={'1.1rem'}>Name</Typography>
        <Input placeholder='Enter name' inputName='name' />
        <Typography mb={0.4} fontSize={'1.1rem'} mt={1}>Email</Typography>
        <Input placeholder='Enter email' inputName='email' />
        <Typography mb={0.4} fontSize={'1.1rem'} mt={1}>Phone<span style={{opacity: '0.4', marginLeft: '0.2rem', fontWeight: 600}}>(Optional)</span></Typography>
        <Input placeholder='Enter phone number' inputName='phone' />
        <Typography mb={0.4} fontSize={'1.1rem'} mt={1}>Message</Typography>
        <Input placeholder='Type your message' inputName='message' />
      </Stack>
    </MynkFormDialog>
  )
}
