import {
  AddLeadParams,
  AddLeadResponse,
  AddTaskParams,
  AddTaskResponse,
  DeleteTaskParams,
  DeleteTaskResponse,
  EditTaskParams,
  EditTaskResponse,
  ListTasksParams,
  ListTasksResponse,
  SetTaskPriorityParams,
  SetTaskPriorityResponse,
  SetTaskStatusParams,
  SetTaskStatusResponse,
} from "./types";
import { useMutation, useQuery, useQueryClient, UseMutationOptions } from "@tanstack/react-query";
import axios from "axios";

export function useAddTaskMutation(
  options: UseMutationOptions<AddTaskResponse, unknown, AddTaskParams>
) {
  const queryClient = useQueryClient();
  return useMutation<AddTaskResponse, unknown, AddTaskParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/tasks/add", params)).data,
    async onSuccess() {
      // TODO: invalidate only relevant task lists
      await queryClient.invalidateQueries({ queryKey: ["task-list"] });
    },
    ...options
  });
}

export function useEditTaskMutation(
  options: UseMutationOptions<AddTaskResponse, unknown, EditTaskParams>
) {
  const queryClient = useQueryClient();
  return useMutation<EditTaskResponse, unknown, EditTaskParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/tasks/edit", params)).data,
    async onSuccess() {
      // TODO: invalidate only relevant task lists
      await queryClient.invalidateQueries({ queryKey: ["task-list"] });
    },
    ...options
  });
}

export function useDeleteTaskMutation() {
  const queryClient = useQueryClient();
  return useMutation<DeleteTaskResponse, unknown, DeleteTaskParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/tasks/delete", params)).data,
    async onSuccess() {
      // TODO: invalidate only relevant task lists
      await queryClient.invalidateQueries({ queryKey: ["task-list"] });
    },
  });
}

export function useListTasksQuery(params: ListTasksParams) {
  return useQuery<ListTasksResponse>({
    queryKey: ["task-list", params],
    queryFn: async () => (await axios.get("/api/tasks/list", { params })).data,
  });
}

export function useSetTaskStatusMutation() {
  const queryClient = useQueryClient();

  return useMutation<SetTaskStatusResponse, unknown, SetTaskStatusParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/tasks/set-status", params)).data,
    async onSuccess() {
      // TODO: invalidate only relevant task lists
      await queryClient.invalidateQueries({ queryKey: ["task-list"] });
    },
  });
}

export function useSetTaskPriorityMutation() {
  const queryClient = useQueryClient();

  return useMutation<SetTaskPriorityResponse, unknown, SetTaskPriorityParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/tasks/set-priority", params)).data,
    async onSuccess() {
      // TODO: invalidate only relevant task lists
      await queryClient.invalidateQueries({ queryKey: ["task-list"] });
    },
  });
}
