import React from 'react'
import { useEffect, useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { useListAvailableMeetingTimesPortalQuery, useListEventsQuery } from '../../api/scheduler';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import FormButton from './FormButton';
import { MeetingType } from '../../api/types';
import { MynkSelectBox } from '../MynkSelectBox';
import timezones from "timezones-list";


type DateAndTimePageProps = {
    setCurrentPage: React.Dispatch<React.SetStateAction<1 | 2 | 3 | 4>>
    meetingType?: MeetingType
    selectedDate: string
    setSelectedDate: React.Dispatch<React.SetStateAction<string>>
    selectedTime: string
    setSelectedTime: React.Dispatch<React.SetStateAction<string>>
    companyName?: string
    isMobile?: boolean
}

function DateAndTime(props: DateAndTimePageProps) {
    const [possibleMeetingTimes, setPossibleMeetingTimes] = useState<Array<string>>([]);
    const { data, isPending } = useListAvailableMeetingTimesPortalQuery({ date: props.selectedDate, company_name: props.companyName ?? '', meeting_type_uuid: props.meetingType?.uuid ?? '' });
    
    useEffect(() => {
        if (data && !isPending) {
            setPossibleMeetingTimes(data.available_meeting_times);
        }
    }, [data, isPending]);

    return (
        <Stack
            sx={{
                borderRadius: '16px',
                background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%)',
                boxShadow: '0px 5px 15px 0px rgba(117, 179, 255, 0.20)',
                paddingLeft: '2rem',
                paddingY: '2rem',
                height: 'fit-content',
                alignSelf: 'center',
                paddingBottom: '2rem',
                justifyContent: 'center'
            }}
            flexGrow={1}
        >
            <Typography sx={{fontWeight: 600, fontSize: '1.6rem', marginLeft: '1.2rem', marginBottom: '0.5rem'}}>Date & Time</Typography>
            <Stack flexDirection={props.isMobile ? 'column' : 'row'}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DayCalendarSkeleton']}>
                        <DateCalendar
                            referenceDate={dayjs()}
                            views={['year', 'month', 'day']}
                            onChange={(date) => {
                                if (date) {
                                    const selectedDate = new Date(date.format('YYYY-MM-DD'));
                                    const today = new Date();
                                    today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for accurate comparison
                                
                                    if (selectedDate > today || selectedDate.getTime() === today.getTime()) {
                                        props.setSelectedDate(date.format('YYYY-MM-DD'));
                                    } else {
                                        alert('Please select a future date');
                                    }
                                }
                            }}
                        />
                    </DemoContainer>
                </LocalizationProvider>
                {props.selectedDate && possibleMeetingTimes.length > 0 && (
                    <Stack sx={{marginLeft: '1rem', marginTop: '1.5rem', width: '16rem', marginRight: '1rem'}} flexGrow={1}>
                        <Typography sx={{marginBottom: '1rem'}}>Choose a slot</Typography>
                        <Stack flexWrap={'wrap'} flexDirection={'row'} justifyContent={'flex-start'} gap={'1rem'}>
                            {possibleMeetingTimes.map((time, index) => {
                                return (
                                    <Box
                                        key={index}
                                        onClick={() => props.setSelectedTime(time)}
                                        sx={{
                                            borderRadius: '30px',
                                            background: props.selectedTime === time ? 'rgb(117, 179, 255, 0.90)' : 'rgba(117, 179, 255, 0.10)',
                                            color: props.selectedTime === time ? '#F0FFFF' : '#75B3FF',
                                            fontSize: '1.1rem',
                                            paddingX: '1rem',
                                            paddingY: '0.5rem',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {time}
                                    </Box>
                                );
                            })}
                        </Stack>
                    </Stack>
                )}
            </Stack>
            {props.selectedTime && (
                <Box sx={{marginRight: '2rem', alignSelf: 'center'}}>
                    <FormButton text='Continue' nextPage={3} setCurrentPage={props.setCurrentPage} />
                </Box>
                )
            }
        </Stack>
    );
}

export default DateAndTime
