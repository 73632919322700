import { useEffect, useMemo, useState } from "react";
import {
  ChangeListingViewerAction,
  FilterOption,
  ListClientsResponse,
  ListListingViewersResponse,
  ListingStage,
  ListingViewer,
  RealEstateListing,
  SearchOption,
  SortOption,
  WorkflowType,
} from "../../../../api/types";
import {
  useChangeListingViewer,
  useListListingViewersQuery,
} from "../../../../api/projects";
import { useAtomValue } from "jotai";
import { currentWorkflowAtom } from "../../../../components/MainAppBar/UserControlPopup";
import { Link, useNavigate } from "react-router-dom";
import { PATHS, WorkflowPath, makePath } from "../../../../paths";
import {
  SimpleTableCard,
  SimpleTableColumn,
  SimpleTableColumnType,
  TableType,
} from "../../../../components/SimpleTable";
import { REAL_ESTATE_LISTING_STAGE_OPTIONS } from "../../../../components/ChipSelect/commonOptions";
import { ChipSelect } from "../../../../components/ChipSelect";
import { Box, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";

const LISTING_VIEWERS_BASE_COLUMNS: SimpleTableColumn[] = [
  {
    key: "full_name",
    label: "Client",
  },
  {
    key: "type",
    label: "Type",
    type: SimpleTableColumnType.REAL_ESTATE_CLIENT_TYPE,
    hideHeader: true
  },
  {
    key: "stage",
    label: "Stage",
  },
  {
    key: "view_contact",
    label: "View",
    type: SimpleTableColumnType.LINK,
    hideHeader: true,
  },
  // {
  //   key: "",
  //   label: "",
  //   render(_: unknown, row: any) {
  //     return <ThreeDotsProject project={row} />;
  //   },
  // },
];

interface ListingViewersTableCardProps {
  listing: RealEstateListing;
}

const PAGE_SIZE = 10;

export default function ListingViewersTableCard(
  props: ListingViewersTableCardProps
) {
  const [selectedPage, setSelectedPage] = useState(0);
  const [current, setCurrent] = useState<null | ListListingViewersResponse>(
    null
  );

  const { data, isPending } = useListListingViewersQuery({
    listing_uuid: props.listing.uuid,
    page_size: PAGE_SIZE,
    page_index: selectedPage,
  });

  useEffect(() => {
    if (!isPending && data) {
      setCurrent(data);
    }
  }, [data, isPending]);

  const navigate = useNavigate();
  const handleViewContact = (row: { uuid: string }) => {
    navigate(
      makePath(
        `/${WorkflowType.REAL_ESTATE}` as WorkflowPath,
        PATHS.viewContact(row.uuid).general
      )
    );
  };

  const columns = useMemo(() => {
    const result = [...LISTING_VIEWERS_BASE_COLUMNS];

    const stageIndex = result.findIndex((c) => c.key === "stage");
    result[stageIndex] = {
      key: "stage",
      label: "Stage",
      render(value, row) {
        const currentStage = props.listing.viewers.find(
          (viewer: ListingViewer) => viewer.client_uuid === row.uuid
        )?.stage;

        const changeViewer = useChangeListingViewer();
        const handleChangeStage = (value: string) => {
          changeViewer.mutate({
            prospect_uuid: row.uuid,
            listing_uuid: props.listing.uuid,
            action: ChangeListingViewerAction.SET_STAGE,
            new_stage: value as ListingStage,
          });
        };

        return (
          <>
            <ChipSelect
              options={REAL_ESTATE_LISTING_STAGE_OPTIONS}
              value={currentStage ?? ""}
              onChange={handleChangeStage}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 1px 5px rgba(117, 179, 255, 0.35)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 130,
                textAlign: "center",
              }}
            />
            <></>
          </>
        );
      },
    };

    const viewContactIndex = result.findIndex((c) => c.key === "view_contact");
    result[viewContactIndex] = {
      key: "view_contact",
      label: "View",
      hideHeader: true,
      render(_: unknown, row: any) {
        return (
          <Typography
            onClick={() => handleViewContact(row)}
            variant="body2"
            sx={{
              color: "#75b3ff",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            View
          </Typography>
        );
      },
    };

    return result;
  }, []);

  return (
    <SimpleTableCard
      title="Prospects"
      data={current?.viewers}
      columns={columns}
      tableType={TableType.CLIENTS}
      selectedPage={selectedPage}
      setSelectedPage={setSelectedPage}
      pageSize={PAGE_SIZE}
      total={current?.viewers.length ?? 0}
      numOfPages={current?.num_of_pages ?? 1}
      isPending={isPending}
    />
  );
}
