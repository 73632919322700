import { MynkTab } from "../../../../components/MynkPage";
import ViewGeneralLayout, {
  TimelineColumn,
  TimelineItem,
} from "../../../../components/ViewProjectOrContact/ViewGeneralLayout";
import React, { useMemo, useState } from "react";
import {
  Expense,
  PhotographyProject
} from "../../../../api/types";
import { zonedDate } from "../../../../util/common";
import { useAtom, useAtomValue } from "jotai";
import { currentWorkflowAtom } from "../../../../components/MainAppBar/UserControlPopup";
import { UpperCard, LowerCard } from "../common/cards";
import { useListExpensesQuery } from "../../../../api/expenses";
import { AddExpenseDialog } from "../../../../dialogs/AddExpense";
import { useParams } from "react-router-dom";
import { useGetProjectQuery } from "../../../../api/projects";
import { useGetClientQuery } from "../../../../api/clients";

const TIMELINE_COLUMNS = [
  TimelineColumn.DESCRIPTION,
  TimelineColumn.DATE,
  TimelineColumn.PRICE,
];

export default function ViewPhotographyProjectExpensesSubpage() {
  const [newExpenseOpen, setNewExpenseOpen] = useState(false);
  const onTimelineAction = () => {
    setNewExpenseOpen(true);
  };

  const { projectId } = useParams();
  const { data: projectData, isPending: projectPending } = useGetProjectQuery(
    {project_uuid: projectId ?? ""},
    Boolean(projectId)
  );
  const project = projectData?.project as PhotographyProject;

  const { data: clientData } = useGetClientQuery(
    {uuid: project?.client_uuid ?? ""},
    Boolean(project)
  );
  const contact = clientData?.client;

  const currentWorkflow = useAtomValue(currentWorkflowAtom);

  const { data, isPending } = useListExpensesQuery(
    {
      project_uuid: project?.uuid ?? "",
      page_size: 10,
    },
    Boolean(project)
  );
  
  const timelineItems: undefined | TimelineItem[] = useMemo(() => {
    if (isPending || !data || !currentWorkflow) return undefined;
    const expenses = data.expenses as Expense[];

    return expenses.map((expense) => {
      const date = zonedDate(expense.date);
        
      return {
        uuid: expense.uuid,
        title: expense.description,
        date,
        price: expense.amount,
      };
    });
  }, [data, isPending, currentWorkflow]);


  return (
    <MynkTab
      title="General"
    >
      <AddExpenseDialog
        open={newExpenseOpen}
        onClose={() => setNewExpenseOpen(false)}
        contactId={contact?.uuid}
        projectId={project?.uuid}
      />

      <ViewGeneralLayout
        timeline={{
          timelineTitle: "Expenses",
          timelineItems,
          timelineColumns: TIMELINE_COLUMNS,
          timelineAction: "+ New expense",
          onTimelineActionClick: onTimelineAction,
          columnDivision: ["35%", "24%"]
        }}
        upperCard={<UpperCard project={project} />}
        lowerCard={<LowerCard project={project} />}
        loading={!projectData?.project || projectPending}
      />
    </MynkTab>
  );
}
