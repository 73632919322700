import {MynkTab} from "../../../../components/MynkPage";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ViewGeneralLayout, {TimelineColumn,} from "../../../../components/ViewProjectOrContact/ViewGeneralLayout";
import {PhotographyProject, ProjectStage, TemplateKind} from "../../../../api/types";
import {Stack} from "@mui/material";
import {getProjectStageSingular, zonedDate} from "../../../../util/common";
import {useGetProjectQuery,} from "../../../../api/projects";
import ActionMenuButton, {ActionMenuButtonVariant,} from "../../../../components/ActionMenuButton";
import {Add, Tune} from "@mui/icons-material";
import {useGetDefaultTemplateQuery, useListTemplatesQuery,} from "../../../../api/templates";
import {useAtom, useSetAtom} from "jotai";
import {templateStateAtom} from "../../../../state/projects";
import EditProjectDialog from "../../../../dialogs/EditProject";
import {LowerCard, UpperCard} from "../common/cards";
import {makePath, PATHS, WorkflowPath} from "../../../../paths";
import AddProjectNoteDialog from "../../../../dialogs/AddProjectNote";
import AddTaskDialog from "../../../../dialogs/AddTask";
import {useTemplateAtoms} from "../../InstantiateTemplate/TemplateContext";

enum NewMenuIds {
  NEW_MESSAGE = "new_message",
  NEW_TASK = "new_task",
  NEW_NOTE = "new_note",
  NEW_INVOICE = "new_invoice",
}

const NEW_MENU = [
  {
    id: NewMenuIds.NEW_TASK,
    label: "New task",
  },
  {
    id: NewMenuIds.NEW_NOTE,
    label: "New note",
  },
  {
    id: NewMenuIds.NEW_INVOICE,
    label: "New invoice"
  }
];

const TIMELINE_COLUMNS = [TimelineColumn.STAGE, TimelineColumn.DATE];

export function useNewProposal(project: null | PhotographyProject) {
  const templateAtoms = useTemplateAtoms();
  const [want, setWant] = useState(false);
  const {data, isPending} = useListTemplatesQuery({}, want);

  const {data: defaultTemplate} = useGetDefaultTemplateQuery({
    template_kind: "proposal",
  });

  const updateProposalState = useSetAtom(templateStateAtom);
  const updateInitialTemplateDetails = useSetAtom(templateAtoms.initialDetailsAtom);
  const navigate = useNavigate();
  useEffect(() => {
    if (!want || !data || isPending || !defaultTemplate?.uuid) return;

    updateProposalState({
      project: project!,
    });

    updateInitialTemplateDetails({
      items: [{item: project?.name, quantity: 1.0, price: 100.0}],
      client: {
        uuid: project?.client_uuid,
        name: null,
      },
    });

    setWant(false);
    navigate(
      makePath(
        WorkflowPath.PHOTOGRAPHY,
        PATHS.instantiateTemplate(defaultTemplate.uuid).index
      )
    );
  }, [want, data, isPending]);

  return useCallback(() => {
    if (!project) return;

    setWant(true);
  }, [project]);
}

interface TwoActionButtonsViewProjectProps {
  project: PhotographyProject;
}

export function TwoActionButtonsViewProject(
  props: TwoActionButtonsViewProjectProps
) {
  //const newProposal = useNewProposal(project ?? null);
  const [newNoteOpen, setNewNoteOpen] = useState(false);
  const [newTaskOpen, setNewTaskOpen] = useState(false);
  const [editProjectOpen, setEditProjectOpen] = useState(false);

  const [templateState, setTemplateState] = useAtom(templateStateAtom);
  const navigate = useNavigate();

  const {data: defaultTemplate, isPending} = useGetDefaultTemplateQuery({
    template_kind: TemplateKind.INVOICE
  })
  const handleClickItem = (id: string) => {
    switch (id) {
      case NewMenuIds.NEW_MESSAGE:
        //newProposal();
        break;
      case NewMenuIds.NEW_NOTE:
        setNewNoteOpen(true);
        break;
      case NewMenuIds.NEW_TASK:
        setNewTaskOpen(true);
        break;
      case NewMenuIds.NEW_INVOICE:
        setTemplateState({project: props.project});
        navigate(makePath(WorkflowPath.PHOTOGRAPHY, PATHS.instantiateTemplate(defaultTemplate?.uuid ?? "").index));
        break;
    }
  };
  return (
    <Stack direction="row" alignItems="center">
      <AddProjectNoteDialog
        open={newNoteOpen}
        onClose={() => setNewNoteOpen(false)}
        projectId={props.project?.uuid}
      />
      <AddTaskDialog
        open={newTaskOpen}
        onClose={() => setNewTaskOpen(false)}
        project_uuid={props.project?.uuid}
      />
      <EditProjectDialog
        open={editProjectOpen}
        onClose={() => setEditProjectOpen(false)}
        project={props.project}
      />
      <ActionMenuButton
        startIcon={<Tune sx={{mr: 1}}/>}
        variant={ActionMenuButtonVariant.SECONDARY}
        sx={{
          mb: -9,
          mr: 2,
        }}
        onClickItem={() => setEditProjectOpen(true)}
      >
        Edit
      </ActionMenuButton>

      <ActionMenuButton
        startIcon={<Add sx={{width: "1.1rem"}}/>}
        menu={NEW_MENU}
        onClickItem={handleClickItem}
        sx={{
          mb: -9,
        }}
      >
        New
      </ActionMenuButton>
    </Stack>
  );
}

export default function ViewPhotographyProjectGeneralSubpage() {
  const {projectId} = useParams();
  const {data, isPending} = useGetProjectQuery(
    {project_uuid: projectId ?? ""},
    Boolean(projectId)
  );
  const project = data?.project as PhotographyProject;

  const timelineItems = useMemo(() => {
    const changes = project?.stage_changes;
    if (!changes) return [];

    const initialCreationEntry = {
      title: "Project created",
      date: zonedDate(changes[0].change_time), // Use the creation date of the first occurrence
      stage: "Project",
    };

    // Map the rest of the changes
    const mappedChanges = changes.map((change) => {
      let title = getProjectStageSingular(change.new_stage);
      title = title[0].toUpperCase() + title.slice(1);

      if (change.old_stage === null) {
        title += " created";
      }

      if (change.new_stage === ProjectStage.PROPOSAL) {
        title = "Proposal sent";
      }

      return {
        title,
        date: zonedDate(change.change_time),
        stage: change.new_stage,
      };
    });

    // Combine the initial creation entry with the mapped changes
    const combinedChanges = [initialCreationEntry, ...mappedChanges];

    return combinedChanges.reverse();
  }, [project?.stage_changes]);

  return (
    <MynkTab
      title="General"
      action={<TwoActionButtonsViewProject project={project}/>}
    >
      <ViewGeneralLayout
        timeline={{
          timelineItems,
          timelineColumns: TIMELINE_COLUMNS,
          timelineAction: "",
          isProjectTimeline: true,
        }}
        upperCard={data?.project && <UpperCard project={project}/>}
        lowerCard={data?.project && <LowerCard project={project}/>}
        loading={!data?.project || isPending}
      />
    </MynkTab>
  );
}
