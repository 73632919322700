import { GetFileType, ProjectStage } from "../api/types";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { Atom } from "jotai";
import axios from "axios";
import noImagePlaceholder from "../assets/images/no-image-placeholder.png";
import {DateTime, Settings} from "luxon";
import { Coordinates } from "react-advanced-cropper";

export function zonedDate(datestring: string) { 
  return DateTime.fromISO(datestring, { zone: "utc" }).setZone(Settings.defaultZone);
}
export function formatMoney(value?: number, currency_code?: string): string {
  if (value === undefined && !currency_code) return "";
  if (value === undefined && currency_code) {
    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency_code,
    }).format(0);

    // Extract the currency symbol
    const currencySymbol = formattedValue.replace(/[0-9,.]/g, '').trim();
    return currencySymbol;
    }

  if (!currency_code) {
    return value?.toString() ?? "";
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency_code,
  }).format(value ?? 0);
}

export async function getCroppedImageDataURL(dataURL: string, coords: Coordinates): Promise<string> {
  return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = coords.width;
          canvas.height = coords.height;
          const ctx = canvas.getContext('2d');
          if (!ctx) {
              reject('Unable to get 2D context');
              return;
          }
          ctx.drawImage(img, coords.left, coords.top, coords.width, coords.height, 0, 0, coords.width, coords.height);
          resolve(canvas.toDataURL());
      };
      img.onerror = () => {
          reject('Failed to load image');
      };
      img.src = dataURL;
  });
}

export function downloadFile(fileName: string, dataUrl: string) {
  // Create a link element
  const link = document.createElement("a");

  // Set the link's href to the data URL passed to the function
  link.href = dataUrl;

  // Set the download attribute to the desired file name
  link.download = fileName;

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the body
  document.body.removeChild(link);
}

export function getMimeType(filename: string): string | null {
  const extension = filename.split(".").pop()?.toLowerCase();
  const mimeMap: Record<string, string> = {
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    bmp: "image/bmp",
    webp: "image/webp",
    mp4: "video/mp4",
    mpeg: "video/mpeg",
    mov: "video/quicktime",
    mp3: "audio/mp3",
    wav: "audio/wav",
    ogg: "audio/ogg",
    pdf: "application/pdf",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    json: "application/json",
    xml: "application/xml",
    txt: "text/plain",
    html: "text/html",
    zip: "application/zip",
    gz: "application/x-gzip",
    tar: "application/x-tar",
    py: "application/x-python-code",
    cpp: "text/x-c++src",
    php: "application/x-httpd-php",
    js: "application/javascript",
    rb: "application/x-ruby",
    java: "text/x-java-source",
    pl: "application/x-perl",
    css: "text/css",
    sh: "application/x-shellscript",
    sql: "application/x-sql",
    tex: "application/x-tex",
    lisp: "application/x-lisp",
    lua: "application/x-lua",
    swift: "application/x-swift",
    dart: "application/x-dart",
    go: "application/x-go",
    rs: "application/x-rust",
    yaml: "application/x-yaml",
    md: "application/x-markdown",
    erb: "application/x-httpd-eruby",
    csh: "application/x-csh",
    ksh: "application/x-ksh",
    tcl: "application/x-tcl",
    texi: "application/x-texinfo",
    el: "application/x-emacs-lisp",
    clj: "application/x-clojure",
    coffee: "application/x-coffeescript",
    erl: "application/x-erlang",
    fs: "application/x-fsharp",
    groovy: "application/x-groovy",
    hs: "application/x-haskell",
    kt: "application/x-kotlin",
    m: "application/x-matlab",
    objc: "application/x-objective-c",
    pas: "application/x-pascal",
    r: "application/x-r",
    scala: "application/x-scala",
    ts: "application/x-typescript",
    tsx: "application/javascript",
    vb: "application/x-vb",
    vbs: "application/x-vbscript",
    wl: "application/x-wolfram",
    zsh: "application/x-zsh",
  };

  // Check if the extension exists in the map, otherwise return a default value
  return extension ? mimeMap[extension] : null;
}

export function simplifyMimeType(mimeType: string): string {
  const mimeMap: Record<string, string> = {
    "image/jpeg": "Image",
    "image/jpg": "Image",
    "image/png": "Image",
    "image/gif": "Image",
    "image/bmp": "Image",
    "image/webp": "Image",
    "image/svg+xml": "Icon",
    "video/mp4": "Video",
    "video/mpeg": "Video",
    "video/quicktime": "Video",
    "audio/mp3": "Audio",
    "audio/mpeg": "Audio",
    "audio/wav": "Audio",
    "audio/ogg": "Audio",
    "application/pdf": "PDF",
    "application/msword": "Document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "Document",
    "application/vnd.ms-excel": "Spreadsheet",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      "Spreadsheet",
    "application/vnd.ms-powerpoint": "Presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      "Presentation",
    "application/json": "JSON",
    "application/xml": "XML",
    "text/plain": "Text",
    "text/html": "HTML",
    "application/zip": "ZIP Archive",
    "application/x-gzip": "GZIP Archive",
    "application/x-tar": "Tar Archive",
    "application/vnd.ms-powerpoint.presentation.macroenabled.12":
      "Macro-Enabled Presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
      "Slideshow",
    "application/vnd.ms-powerpoint.slideshow.macroenabled.12":
      "Macro-Enabled Slideshow",
    "application/vnd.ms-excel.sheet.macroenabled.12":
      "Macro-Enabled Spreadsheet",
    "application/vnd.ms-word.document.macroenabled.12":
      "Macro-Enabled Document",
    "application/vnd.oasis.opendocument.text": "OpenDocument Text",
    "application/vnd.oasis.opendocument.spreadsheet":
      "OpenDocument Spreadsheet",
    "application/vnd.oasis.opendocument.presentation":
      "OpenDocument Presentation",
    "application/x-python-code": "Python",
    "text/x-python": "Python",
    "text/x-c++src": "C++",
    "application/x-cplusplus": "C++",
    "application/x-httpd-php": "PHP",
    "application/javascript": "JavaScript",
    "application/x-ruby": "Ruby",
    "text/x-java-source": "Java",
    "application/x-perl": "Perl",
    "text/css": "CSS",
    "application/x-shellscript": "Shell Script",
    "application/x-sql": "SQL",
    "application/x-latex": "LaTeX",
    "application/x-tex": "TeX",
    "application/x-lisp": "Lisp",
    "application/x-lua": "Lua",
    "application/x-swift": "Swift",
    "application/x-dart": "Dart",
    "application/x-go": "Go",
    "application/x-rust": "Rust",
    "application/x-yaml": "YAML",
    "application/x-markdown": "Markdown",
    "application/x-httpd-eruby": "ERB",
    "application/x-sh": "Bash",
    "application/x-csh": "C Shell",
    "application/x-ksh": "Korn Shell",
    "application/x-tcl": "TCL",
    "application/x-python": "Python",
    "application/x-texinfo": "Texinfo",
    "application/x-emacs-lisp": "Emacs Lisp",
    "application/x-clojure": "Clojure",
    "application/x-coffeescript": "CoffeeScript",
    "application/x-erlang": "Erlang",
    "application/x-fsharp": "F#",
    "application/x-groovy": "Groovy",
    "application/x-haskell": "Haskell",
    "application/x-java": "Java",
    "application/x-kotlin": "Kotlin",
    "application/x-matlab": "MATLAB",
    "application/x-objective-c": "Objective-C",
    "application/x-pascal": "Pascal",
    "application/x-r": "R",
    "application/x-scala": "Scala",
    "application/x-typescript": "TypeScript",
    "application/x-vb": "Visual Basic",
    "application/x-vbscript": "VBScript",
    "application/x-wolfram": "Wolfram",
    "application/x-xml": "XML",
    "application/x-zsh": "Zsh",
  };

  // Check if the mimeType exists in the map, otherwise return a default value
  return mimeMap[mimeType] || "Unknown";
}

export async function fetchFileSrc(imgUuid: string, isGeneral: boolean) {
  let imageUrl = "";

  if (isGeneral) {
    imageUrl = `/api/files/get/${GetFileType.GENERAL}/${imgUuid}`;
  } else {
    imageUrl = `/api/files/get/${GetFileType.SPECIFIC_WORKFLOW}/${imgUuid}`;
  }

  try {
    // Fetch with the authorization token (with 'axios'):
    const response = await axios.get(imageUrl, {
      responseType: "arraybuffer",
    });

    // If succeed parse the image:
    if (response.status === 200) {
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const objectUrl = URL.createObjectURL(blob);
      return objectUrl;
    } else {
      console.error(`Failed to fetch image. Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching image:", error);
  }
}

export async function fetchAllImagesByUuids(
  uuidsList?: string[],
  isGeneral?: boolean
) {
  let urlDataList = <Array<string>>[];

  if (uuidsList) {
    await Promise.all(
      uuidsList.map(async (value) => {
        if (value) {
          const imgSrc = await fetchFileSrc(value, isGeneral ?? false);
          if (imgSrc) {
            urlDataList.push(imgSrc);
          }
        } else {
          urlDataList.push(noImagePlaceholder);
        }
      })
    );
  }

  return urlDataList;
}

export function convertBlobToBase64(blobUrl: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result as string); // Cast the result to a string
      };
      reader.onerror = reject;
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", blobUrl);
    xhr.responseType = "blob";
    xhr.send();
  });
}

export function getProjectStagePlural(stage?: ProjectStage) {
  switch (stage) {
    case ProjectStage.LEAD:
      return "leads";
    case ProjectStage.PROPOSAL:
      return "proposals";
    case ProjectStage.UNDER_CONTRACT:
      return "under contract";
    case ProjectStage._CLOSED_WON:
      return "closed/won";
    default:
      return "projects";
  }
}

export function getProjectStageSingular(stage?: ProjectStage) {
  switch (stage) {
    case ProjectStage.LEAD:
      return "lead";
    case ProjectStage.PROPOSAL:
      return "proposals";
    case ProjectStage.UNDER_CONTRACT:
      return "under contract";
    case ProjectStage._CLOSED_WON:
      return "closed/won";
    case ProjectStage.WAITING_FOR_EVENT:
      return "waiting for event";
    case ProjectStage.EDITING:
      return "editing";
    case ProjectStage.FINAL_APPROVAL:
      return "final approval";
    case ProjectStage.COMPLETED:
      return "completed";
    default:
      return "project";
  }
}

export function useReadAtom<T>(atom: Atom<T>) {
  return useAtomCallback(useCallback((get) => get(atom), [atom]));
}

function dec2hex(dec: number) {
  return dec.toString(16).padStart(2, "0")
}

export function generateId(len: number) {
  const arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}


export function articleFromNoun(noun: string) {
  switch (noun[0]) {
    case "a":
    case "e":
    case "i":
    case "o":
    case "u":
      return "an";

    default:
      return "a";
  }
}
