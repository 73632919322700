import { useState, useEffect, useRef } from 'react'
import { Stack, Box } from '@mui/material';
import personImage from '../../../../assets/images/images.jpg';
import messageMeIcon from '../../../../assets/icons/message-me-icon.svg';
import { TextStyle } from './common';
import MessageMeDialog from '../../../../dialogs/MessageMeDialog';

type PresentationProps = {
    isEditing: boolean;
    textStyle?: TextStyle;
    setTextStyle?: React.Dispatch<React.SetStateAction<TextStyle>>;
    lastChanged?: Partial<TextStyle>;
};

const NONE_FOCUSED = 0
const TITLE_FOCUSED = 1
const DESCRIPTION_FOCUSED = 2
type FocusState = 0 | 1 | 2;

function MessageMe() {
    const [messageMeOpen, setMessageMeOpen] = useState<boolean>(false);

    return (
        <>
            <MessageMeDialog open={messageMeOpen} onClose={() => setMessageMeOpen(prevMessageMeOpen => !prevMessageMeOpen)} />
            <Box
                component="img"
                src={messageMeIcon}
                sx={{ width: "7rem", height: "7rem", marginLeft: "6.7rem", cursor: 'pointer'}}
                onClick={() => setMessageMeOpen(prevMessageMeOpen => !prevMessageMeOpen)}
            />
        </>
    );
}

export default function Presentation({ isEditing, textStyle, setTextStyle, lastChanged }: PresentationProps) {
    const [focused, setFocused] = useState<FocusState>(NONE_FOCUSED)
    const titleRef = useRef<HTMLTextAreaElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (lastChanged === undefined) return;

        const key = Object.keys(lastChanged)[0] as keyof TextStyle;
        if (focused === TITLE_FOCUSED) {
            titleRef.current?.style.setProperty(key, lastChanged[key]?.toString() || '');
        } else if (focused === DESCRIPTION_FOCUSED) {
            descriptionRef.current?.style.setProperty(key, lastChanged[key]?.toString() || '');
        }
    }, [lastChanged])

    const handleTitleClick = () => {
        const titleElement = titleRef.current;
        console.log(titleElement?.style.color)

        if (titleElement && setTextStyle){
            setTextStyle({
                'font-family': titleElement?.style.fontFamily,
                'font-weight': titleElement?.style.fontWeight,
                'font-size': titleElement?.style.fontSize,
                'font-style': titleElement?.style.fontStyle,
                'text-decoration': titleElement?.style.textDecoration,
                'text-align': titleElement?.style.textAlign,
                'line-height': titleElement?.style.lineHeight,
                'color': titleElement?.style.color
            });
        }
        setFocused(TITLE_FOCUSED);
    }
    
    const handleDescriptionClick = () => {
        const descriptionElement = descriptionRef.current;

        if (descriptionElement && setTextStyle) {
            setTextStyle({
                'font-family': descriptionElement?.style.fontFamily,
                'font-weight': descriptionElement?.style.fontWeight,
                'font-size': descriptionElement?.style.fontSize,
                'font-style': descriptionElement?.style.fontStyle,
                'text-decoration': descriptionElement?.style.textDecoration,
                'text-align': descriptionElement?.style.textAlign,
                'line-height': descriptionElement?.style.lineHeight,
                'color': descriptionElement?.style.color
            });
        }
        setFocused(DESCRIPTION_FOCUSED);
    }

    return (
        isEditing ? (
            <Stack sx={{borderRadius: "16px 355px 355px 16px", background: "#FFF", boxShadow: "0px 5px 15px 0px rgba(117, 179, 255, 0.20)", width: "95rem", height: "30rem", marginTop: '-3rem'}} flexDirection={"row"}>
                <Stack marginTop={5}>
                    <textarea
                        className='title-textarea'
                        ref={titleRef}
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: '5rem',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: "100%",
                            textDecoration: 'none',
                            marginLeft: "6.5rem",
                            marginBottom: '1rem',
                            paddingBottom: '1rem',
                            borderRadius: '16px',
                            border: focused === TITLE_FOCUSED ? '2px solid black' : 'none',
                            width: '40rem',
                            overflow: 'hidden',
                            resize: 'none'
                        }}
                        onClick={handleTitleClick}
                        rows={2}
                    >
                        Matt Diamond Photography
                    </textarea>
                    <textarea
                        className='description-textarea'
                        ref={descriptionRef}
                        style={{
                            fontFamily: "Helvetica",
                            fontSize: '1.25rem',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '125%',
                            textDecoration: 'none',
                            marginLeft: '6.5rem',
                            marginRight: '7rem',
                            marginBottom: '-0.8rem',
                            paddingTop: '0.5rem',
                            paddingLeft: '0.5rem',
                            width: '51rem',
                            overflow: 'hidden',
                            resize: 'none',
                            borderRadius: '16px',
                            border: focused === DESCRIPTION_FOCUSED ? '1.5px solid black' : 'none',
                            marginTop: '1rem'
                        }}
                        onClick={handleDescriptionClick}
                        rows={5}
                    >
                        Hello! I'm Matt Diamond, a passionate wedding photographer dedicated to capturing the magic of your special day. With a keen eye for detail and a knack for immortalizing fleeting moments, my goal is to tell your unique love story through timeless images. Let's create beautiful memories together that you'll cherish forever.
                    </textarea>
                    <MessageMe />
                </Stack>
                <img src={personImage} style={{borderRadius: "50%", width: "30rem", height: "30rem"}}></img>
            </Stack>
        ) :
        (
            <Stack sx={{borderRadius: "16px 355px 355px 16px", background: "#FFF", boxShadow: "0px 5px 15px 0px rgba(117, 179, 255, 0.20)", width: "95rem", height: "30rem", marginTop: '-3rem'}} flexDirection={"row"}>
                <Stack>
                    <h1 style={{fontFamily: 'Helvetica', fontSize: '5rem', fontStyle: 'normal', fontWeight: 700, lineHeight: "100%", marginLeft: "7rem", marginBottom: '1rem'}}>Matt Diamond Photography</h1>
                    <p style={{fontFamily: "Inter", fontSize: '1.25rem', fontStyle: 'normal', fontWeight: 400, lineHeight: '125%', marginLeft: '7rem', marginRight: '7rem', marginBottom: '-0.8rem'}}>Hello! I'm Matt Diamond, a passionate wedding photographer dedicated to capturing the magic of your special day. With a keen eye for detail and a knack for immortalizing fleeting moments, my goal is to tell your unique love story through timeless images. Let's create beautiful memories together that you'll cherish forever.</p>
                    <MessageMe />
                </Stack>
                <img src={personImage} style={{borderRadius: "50%", width: "30rem", height: "30rem"}}></img>
            </Stack>
        )
    );
}