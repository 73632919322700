import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import {
  useResetPasswordMutation,
  useVerifyEmailMutation,
} from "../../api/auth";
import { useLocation, useNavigate } from "react-router-dom";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import LoginIcon from "@mui/icons-material/Login";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AxiosError } from "axios";
import { ResetPasswordParams } from "../../api/types";

export default function ResetPasswordPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const code = queryParams.get("code");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isErrorFrontend, setIsErrorFrontend] = useState(false);

  const resetPassword = useResetPasswordMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, ResetPasswordParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        setErrorMsg(detail ?? "");
        setIsErrorFrontend(true);
      }
    },
    onSuccess: () => {
      navigate("/login");
      setIsErrorFrontend(false);
    }
  });
  const navigate = useNavigate();

  const resetPasswordAction = async () => {
    if (password != password2) {
      setIsErrorFrontend(true);
      setErrorMsg("Passwords doesn't match!");
    } else {
      await resetPassword.mutateAsync({
        email: email ?? "",
        code: code ?? "",
        password: password,
      });
    }
  };

  return (
    <Stack
      sx={{
        width: "37rem",
        height: "41rem",
        pt: 3,
        pb: 1,
        justifyContent: "center",
      }}
    >
      {" "}
      <Typography fontFamily="Helvetica Neue" variant="h2">
        Reset password
      </Typography>
      {isErrorFrontend && (
        <Typography sx={{ color: "red" }}>{errorMsg}</Typography>
      )}
      <Typography sx={{ mt: 5, mb: 2 }}>New password</Typography>
      <TextField
        fullWidth
        type={showPassword ? "text" : "password"}
        disabled={resetPassword.isPending}
        onChange={(event) => {
          setPassword(event.target.value);
        }}
        //onKeyUp={handleKeyUp}
        sx={{
          "& *": {
            borderColor: "#c7a5ff",
            borderRadius: "1.7143rem",
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                <Box
                  width={"1.4286rem"}
                  height={"1.4286rem"}
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {showPassword ? (
                    <VisibilityOffIcon sx={{ color: "#00000055" }} />
                  ) : (
                    <VisibilityIcon sx={{ color: "#00000055" }} />
                  )}
                </Box>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
       <Typography
            color="#40404080"
            fontSize={"1.1rem"}
            sx={{ ml: 2, mt: 0.7 }}
          >
            Must be at least 8 characters and contain both letters and numbers.
          </Typography>
      <Typography sx={{ mt: 3, mb: 2 }}>Confirm password</Typography>
      <TextField
        fullWidth
        type={showPassword ? "text" : "password"}
        disabled={resetPassword.isPending}
        onChange={(event) => {
          setPassword2(event.target.value);
        }}
        //onKeyUp={handleKeyUp}
        sx={{
          "& *": {
            borderColor: "#c7a5ff",
            borderRadius: "1.7143rem",
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                <Box
                  width={"1.4286rem"}
                  height={"1.4286rem"}
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {showPassword ? (
                    <VisibilityOffIcon sx={{ color: "#00000055" }} />
                  ) : (
                    <VisibilityIcon sx={{ color: "#00000055" }} />
                  )}
                </Box>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
     
      <br />
      <Button
        fullWidth
        variant="contained"
        sx={{
          my: 2,
          textTransform: "capitalize",
          background: "linear-gradient(90deg, #6ab4ff 0%, #c2a6ff 100%)",
          fontFamily: "Helvetica Neue",
          fontSize: "1.5rem",
          borderRadius: "1.7143rem",
          py: 1.5,
          boxShadow: "0px 7px 8px #999",
        }}
        disabled={resetPassword.isPending}
        onClick={resetPasswordAction}
      >
        {resetPassword.isPending ? <CircularProgress /> : "Change password"}
      </Button>
    </Stack>
  );
}
