import { MynkPage, MynkTabInfo } from "../../../components/MynkPage";
import { PATHS, WorkflowPath, makePath } from "../../../paths";

const TABS: MynkTabInfo[] = [
  {
    title: "General",
    path: "/general",
  },
  {
    title: "Integrations",
    path: "/integrations",
  }
];

export default function PhotographySettingsPage() {
  return (
    <MynkPage
      title="Settings"
      basePath={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.settings.index)}
      tabs={TABS}
    />
  );
}
