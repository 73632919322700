import {MynkPage} from "../../../components/MynkPage";
import React from "react";
import { PATHS, WorkflowPath, makePath } from "../../../paths";

const TABS = [
  {
    title: 'All',
    path: '/all',
  },
  {
    title: 'Sent',
    path: '/sent',
  },
]

export default function TemplatesPage() {
  return (
    <MynkPage
      title="Templates"
      basePath={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.templates.index)}
      tabs={TABS}
    />
  );
}
