import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Grid, IconButton, Input, Stack, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import uploadFilesIcon from "../../../assets/icons/upload-files-icon.svg";
import { MynkFormControlCommonProps } from "./types";
import { useFormikContext } from "formik";
import { FileObj } from "../../../api/types";

export const readAsDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => -reject(error);

    reader.readAsDataURL(file);
  });
};

export interface MynkFormFileUploadProps
  extends MynkFormControlCommonProps<FileObj[]> {
  onFilesChange: (files: FileObj[] | null) => void;
  noBorder?: boolean;
  noTitle?: boolean;
}

export default function MynkFormFileUpload(props: MynkFormFileUploadProps) {
  const formik = useFormikContext();

  const [selectedFiles, setSelectedFiles] = useState<FileObj[] | null>(null);
  const [filePreviews, setFilePreviews] = useState<string[]>([]);

  const [ready, setReady] = useState(false);

  // 'useEffect' to init selectedFiels and initial file previewes with the formik's initial values.
  useEffect(() => {
    if (!ready && formik) {
      const initVals = formik.initialValues as Record<string, any>;
      const photos = initVals["photos"] as FileObj[];
      console.log(photos);
      let initialFilePreviews: string[] = [];
      photos.map((value, index) => {
        initialFilePreviews.push(value.data);
      });
      setSelectedFiles(photos);
      setFilePreviews(initialFilePreviews);
      setReady(true);
    }
  }, []);

  useEffect(() => {
    if (selectedFiles) {
      // Set field value using Formik context:
      formik.setFieldValue(props.name, selectedFiles);
      // Call the outer property function after selectedFiles has been updated:
      props.onFilesChange(selectedFiles);
    }
  }, [selectedFiles]);

  const handleFilesChange = async (files: FileList) => {
    // Filter only images:
    const allowedFiles = Array.from(files).filter(
      (file) => file.type === "image/jpeg" || file.type === "image/png"
    );

    try {
      const fileObjects = await Promise.all(
        allowedFiles.map(async (file) => {
          try {
            const dataUrl = await readAsDataURL(file);
            return {
              name: file.name,
              data: dataUrl,
            };
          } catch (error) {
            console.error(error);
            throw error;
          }
        })
      );

      setSelectedFiles((prevFiles) => {
        if (!prevFiles) {
          return fileObjects;
        }
        return [...prevFiles, ...fileObjects];
      });

      // Generate previews for the photos:
      const previews = allowedFiles.map((file) => URL.createObjectURL(file));
      setFilePreviews((prevPreviews) => [...prevPreviews, ...previews]);
    } catch (error) {
      console.error("Error processing files:", error);
    }
  };

  const handleRemoveFile = (
    index: number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation(); // Prevent file dialog from opening again.

    if (selectedFiles) {
      // Remove the file from local object:
      const newFiles = Array.from(selectedFiles);
      newFiles.splice(index, 1);

      // Override state and call the outer property function:
      setSelectedFiles(newFiles);

      // Update files' previews:
      const newPreviews = [...filePreviews];
      newPreviews.splice(index, 1);
      setFilePreviews(newPreviews);
    }
  };

  const openFileDialog = () => {
    // Find and open add files' dialog:
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.value = "";
    fileInput.click();
  };

  // Allow drag files into the box (DRAG and drop):
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  // Allow release mouse when drag files into the box (drag and DROP):
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const droppedFiles = (event.dataTransfer.files as FileList) || null;
    if (droppedFiles) {
      handleFilesChange(droppedFiles);
    }
  };

  return (
    <div>
      {props.noTitle ? (
        <></>
      ) : (
        <Typography variant="subtitle1">{props.label}</Typography>
      )}
      <Box
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={openFileDialog}
        display="flex"
        flexWrap="wrap"
        mt={2}
        sx={{
          minHeight: "15.2143rem",
          border: props.noBorder ? "none" : "0.1429rem solid #DBE2E6",
          borderRadius: 2,
          maxHeight: "14.2857rem",
          overflowY: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        {/* Responsible to open the add files' dialog: */}
        <Input
          id="fileInput"
          type="file"
          inputProps={{ accept: "image/png, image/jpeg", multiple: true }}
          onChange={(e) =>
            handleFilesChange((e.target as HTMLInputElement).files as FileList)
          }
          style={{ display: "none" }}
        />

        {/* If there are files - show them as card view */}
        {filePreviews.length > 0 ? (
          <Grid container>
            {filePreviews.map((preview, index) => (
              <Grid item xs={4} key={index}>
                <Box
                  m={1}
                  position="relative"
                  sx={{
                    boxShadow: "0px 0.0714rem 0.3571rem rgba(117, 179, 255, 0.35)",
                    border: "0.0714rem solid rgba(117, 179, 255, 0.35)",
                    borderRadius: 2,
                    height: "90%",
                  }}
                >
                  <img
                    src={preview}
                    alt={`Preview ${index}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: 2,
                    }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      alignContent: "center",
                      width: "1.2857rem",
                      height: "1.2857rem",
                      top: 5,
                      right: 5,
                      background:
                        "linear-gradient(180deg, #D288F2 0%, #6DBFF2 100%)",
                    }}
                    onClick={(event) => handleRemoveFile(index, event)}
                  >
                    <DeleteForeverIcon
                      sx={{ width: "1.2143rem", height: "1.2143rem", color: "white" }}
                    />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          // If no files - show the "Choose a file or drag it here." message
          <Stack alignItems="center">
            <Box
              component="img"
              src={uploadFilesIcon}
              width={"4.2857rem"}
              sx={{ mb: 2 }}
            />
            <Typography fontFamily="Helvetica Neue">
              <b>Choose a file</b> or drag it here.
            </Typography>
          </Stack>
        )}
      </Box>
    </div>
  );
}
