import React from 'react';
import { Grid } from '@mui/material';
import { MynkFormControl, MynkFormDialog } from '../components/MynkForm';
import {ListingStage, Priority, ProjectStage, WorkflowType} from '../api/types';
import { LEAD_STAGE_OPTIONS, PRIORITY_OPTIONS } from '../components/ChipSelect/commonOptions';
import { useAddLeadMutation } from '../api/clients';
import {getProjectStageSingular} from "../util/common";
import { MynkFormControlKind } from '../components/MynkForm/controlTypes';

export interface AddLeadDialogProps {
  open: boolean;
  onClose: () => void;
  status?: ProjectStage;
}

export function AddLeadDialog (props: AddLeadDialogProps) {
  const addLead = useAddLeadMutation();

  const handleSubmit = async (values: any) => {
    try {
      await addLead.mutateAsync({
        name: values['leadName'],
        contact_id: values['contactId'],
        priority: values['leadPriority'],
        status: props.status ?? values['leadStatus'],
      });

      props.onClose();
    } catch {

    }
  };

  const singular = props.status === undefined ? 'lead' : getProjectStageSingular(props.status);

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      title={`New ${singular}`}
      action={`Create ${singular}`}
      onSubmit={handleSubmit}
      loading={addLead.isPending}
    >
      <Grid container spacing={2} sx={{ width: "28.5714rem" }}>
        <Grid item xs={12}>
          <MynkFormControl
            kind={MynkFormControlKind.TEXT}
            name="leadName"
            label="Lead's name"
            placeholder="Enter lead's name"
            autoComplete={false}
            default=""
          />
        </Grid>

        <Grid item xs={12}>
          <MynkFormControl
            kind={MynkFormControlKind.CONTACT_SEARCH}
            name="contactId"
            label="Contact"
            placeholder="Enter contact's name"
            default=""
            workflowType={WorkflowType.PHOTOGRAPHY}
          />
        </Grid>

        <Grid item xs={6}>
          <MynkFormControl
            kind={MynkFormControlKind.CHIP_SELECT}
            name="leadPriority"
            label="Priority"
            default={Priority.LOW}
            options={PRIORITY_OPTIONS}
          />
        </Grid>

        {props.status === undefined && (
          <Grid item xs={6}>
            <MynkFormControl
              kind={MynkFormControlKind.CHIP_SELECT}
              name="leadStatus"
              label="Status"
              default="lead"
              options={LEAD_STAGE_OPTIONS}
            />
          </Grid>
        )}
      </Grid>
    </MynkFormDialog>
  );
}
