import {MynkPage, MynkTabInfo} from "../../../components/MynkPage";
import { PATHS, WorkflowPath, makePath } from "../../../paths";
import { useAtomValue } from "jotai";
import { currentWorkflowAtom } from "../../../components/MainAppBar/UserControlPopup";


const TABS: MynkTabInfo[] = [
  {
    title: 'All',
    path: '/all',
  },
  {
    title: 'Favorites',
    path: '/favorites',
  }
];

export default function LibraryPage() {
  return (
    <MynkPage
      title="Library"
      basePath={makePath(`/${useAtomValue(currentWorkflowAtom)?.type}` as WorkflowPath, PATHS.library.index)}
      tabs={TABS}
    />
  );
}
