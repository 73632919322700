import { MynkFormControlCommonProps } from "./types";
import { Stack, Grid, Box, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useIsInEditable } from "../context";
import { useState, useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";

export interface MynkFormColorPickerProps
  extends MynkFormControlCommonProps<string> {
  options: string[];
}

export function MynkFormColorPicker(props: MynkFormColorPickerProps) {
  const formik = useFormikContext();

  const isInEditable = useIsInEditable();
  const [selectedColor, setSelectedColor] = useState<string | undefined>(
    props.default
  );

  useEffect(() => {
    formik?.setFieldValue(props.name, selectedColor);
  }, [selectedColor]);
  
  return (
    <Stack>
      {!isInEditable && (
        <Typography variant="subtitle1">{props.label}</Typography>
      )}

      <Stack justifyContent="center" alignItems="center">
        <Grid container sx={{ width: "50%" }} spacing="0.7rem">
          {props.options.map((color, index) => {
            return (
              <Grid item xs={2}>
                <Box
                  sx={{
                    width: "1.7rem",
                    height: "1.7rem",
                    borderRadius: "50%",
                    bgcolor: color,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => setSelectedColor(color)}
                >
                  {selectedColor === color && (
                    <DoneIcon sx={{ width: "1.4rem", color: "black" }} />
                  )}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Stack>
  );
}
