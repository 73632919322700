import { useAtomValue } from "jotai";
import { domStateAtom, useAddUpdateRecord } from "../../InstantiateTemplate/common";
import { useMemo, useState } from "react";
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Stack,
  Box,
  Divider
} from "@mui/material";
import {
  FormatItalic,
  FormatUnderlined,
  StrikethroughS,
} from "@mui/icons-material";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import Images from '../common/Images'
import Presentation from '../common/Presentation'
import PreviewButtons from '../common/PreviewButtons'
import { TextStyle } from '../common/common';
import { TwitterPicker, ColorResult } from 'react-color';

const DEFAULT_FONTS = [
  "Inter",
  "Helvetica"
];

const FONT_SIZES = [
  12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60
].map((x) => ({
  value: `${Math.round((x / 12) * 4) / 4}rem`,
  label: `${x} pt`,
}));

const LINES_SPACING = [
  '1.0', '1.25', '1.5', '2.0', '2.5', '3.0'
].map((x) => ({
  value: `${Number(x) * 100}%`,
  label: `${x}`,
}));

const DEFAULT_FONT_WEIGHTS = [
  { label: "Normal", value: "400" },
  { label: "Bold", value: "700" },
  { label: "Extra Bold", value: "800" },
];

const FONT_STYLING_OPTIONS = [
  {
    value: "italic",
    icon: <FormatItalic />,
  },
  {
    value: "underline",
    icon: <FormatUnderlined />,
  },
  {
    value: "line-through",
    icon: <StrikethroughS />,
  },
];

const TEXT_SIDE_OPTIONS = [
  {
    value: "left",
    icon: <FormatAlignLeftIcon />,
    jsKey: "textAlign",
    cssKey: "text-align",
    cssValue: "left",
  },
  {
    value: "right",
    icon: <FormatAlignRightIcon />,
    jsKey: "textAlign",
    cssKey: "text-align",
    cssValue: "right",
  },
  {
    value: "center",
    icon: <FormatAlignCenterIcon />,
    jsKey: "textAlign",
    cssKey: "text-align",
    cssValue: "center",
  },
  {
    value: "justify",
    icon: <FormatAlignJustifyIcon />,
    jsKey: "textAlign",
    cssKey: "text-align",
    cssValue: "justify",
  },
];

type FontProps = {
  textStyle:TextStyle;
  setTextStyle: React.Dispatch<React.SetStateAction<TextStyle>>;
  setLastChanged: React.Dispatch<React.SetStateAction<Partial<TextStyle> | undefined>>;
};

function FontFamilySelect({ textStyle, setTextStyle, setLastChanged }: FontProps) {
  const handleChange = (e: SelectChangeEvent<string>) => {
    setLastChanged({ 'font-family': e.target.value });
    setTextStyle(prevStyle => ({...prevStyle, 'font-family': e.target.value}));
  };

  return (
    <Select
      fullWidth
      sx={{
        height: "3.2rem",
        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        borderRadius: '10px',
        bgcolor: "#e7f4ff",
      }}
      size="small"
      value={textStyle['font-family']}
      onChange={handleChange}
      style={{paddingLeft: 0, paddingRight: '2rem'}}
    >
      {DEFAULT_FONTS.map((font) => (
        <MenuItem key={font} value={font}>
          {font}
        </MenuItem>
      ))}
    </Select>
  );
}

function FontWeightSelect({ textStyle, setTextStyle, setLastChanged }: FontProps) {
  const handleChange = (e: SelectChangeEvent<string>) => {
    setLastChanged({ 'font-weight': e.target.value });
    setTextStyle(prevStyle => ({...prevStyle, 'font-weight': e.target.value}));
  };

  return (
    <Select
      fullWidth
      sx={{
        height: "3.2rem",
        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          // bgcolor: '#e7f4ff',
        },
        borderRadius: '10px',
        bgcolor: "#e7f4ff",
      }}
      size="small"
      value={textStyle['font-weight']?.toString()}
      onChange={handleChange}
      style={{paddingLeft: 0, paddingRight: '3.5rem'}}
    >
      {DEFAULT_FONT_WEIGHTS.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

function FontSizing({ textStyle, setTextStyle, setLastChanged }: FontProps) {
  const handleChange = (e: SelectChangeEvent<string>) => {
    setLastChanged({ 'font-size': e.target.value });
    setTextStyle(prevStyle => ({...prevStyle, 'font-size': e.target.value}));
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ height: "100%" }}
    >
      <Typography variant="body1" marginRight={'1rem'}>Font size</Typography>

      <Select
        sx={{
          height: "3.2rem",
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
            // backgroundColor: '#e7f4ff',
          },
          borderRadius: '10px',
          bgcolor: "#e7f4ff",
        }}
        size="small"
        value={textStyle['font-size']?.toString()}
        onChange={handleChange}
        style={{paddingLeft: 0, paddingRight: '1.5rem'}}
      >
        {FONT_SIZES.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}

function FontStyling({ textStyle, setTextStyle, setLastChanged }: FontProps) {
  const handleClick = async (option: (typeof FONT_STYLING_OPTIONS)[0]) => {
    switch (option.value) {
      case "italic":
        setLastChanged({ 'font-style': textStyle['font-style'] === 'italic' ? 'normal' : 'italic' });
        setTextStyle(prevStyle => ({...prevStyle, 'font-style': textStyle['font-style'] === 'italic' ? 'normal' : 'italic'}))
        break;
      case "underline":
        setLastChanged({ 'text-decoration': textStyle['text-decoration'] === 'underline' ? 'none' : 'underline' });
        setTextStyle(prevStyle => ({...prevStyle, 'text-decoration': textStyle['text-decoration'] === 'underline' ? 'none' : 'underline'}))
        break;
      case "line-through":
        setLastChanged({ 'text-decoration': textStyle['text-decoration'] === 'line-through' ? 'none' : 'line-through' });
        setTextStyle(prevStyle => ({...prevStyle, 'text-decoration': textStyle['text-decoration'] === 'line-through' ? 'none' : 'line-through'}))
        break;
    }
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={textStyle['font-style']}
      sx={{ height: '100%', display: "flex", flexDirection: "row", overflow: "hidden", borderRadius: '10px', borderColor: 'divider' }}
    >
      <Stack direction="row" alignItems="center">
        {FONT_STYLING_OPTIONS.map((option, index) => (
          <>
            <ToggleButton
              key={option.value}
              value={option.value}
              onClick={() => handleClick(option)}
              sx={{
                height: "3.2rem",
                width: "4rem",
                border: 0,
                bgcolor: "#e7f4ff",
                color: "black",
                "&.MuiToggleButton-root.Mui-selected": {
                  color: "#75B3FF",
                },
                borderRadius: index === 0 ? '10px 0 0 10px' : index === FONT_STYLING_OPTIONS.length - 1 ? '0 10px 10px 0' : 0
              }}
            >
              {option.icon}
            </ToggleButton>
            {index < FONT_STYLING_OPTIONS.length - 1 && (
              <Divider orientation="vertical" variant="middle" flexItem />
            )}
          </>
        ))}
      </Stack>
    </ToggleButtonGroup>
  );
}

function TextSide({ textStyle, setTextStyle, setLastChanged }: FontProps) {
  const handleClick = async (option: (typeof TEXT_SIDE_OPTIONS)[0]) => {
    switch (option.value) {
      case "left":
        setLastChanged({ 'text-align': 'left' });
        setTextStyle(prevStyle => ({...prevStyle, 'text-align': 'left'}));
        break;
      case "right":
        setLastChanged({ 'text-align': textStyle['text-align'] === 'right' ? 'left' : 'right' });
        setTextStyle(prevStyle => ({...prevStyle, 'text-align': textStyle['text-align'] === 'right' ? 'left' : 'right'}))
        break;
      case "center":
        setLastChanged({ 'text-align': textStyle['text-align'] === 'center' ? 'left' : 'center' });
        setTextStyle(prevStyle => ({...prevStyle, 'text-align': textStyle['text-align'] === 'center' ? 'left' : 'center'}))
        break;
      case "justify":
        setLastChanged({ 'text-align': textStyle['text-align'] === 'justify' ? 'left' : 'justify' });
        setTextStyle(prevStyle => ({...prevStyle, 'text-align': textStyle['text-align'] === 'justify' ? 'left' : 'justify'}))
        break;
    }
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={textStyle['text-align']}
      sx={{ height: '100%', display: "flex", flexDirection: "row", overflow: "hidden", borderRadius: '10px', borderColor: 'divider' }}
    >
      <Stack flexDirection="row">
        {TEXT_SIDE_OPTIONS.map((option, index) => (
          <>
            <ToggleButton
              key={option.value}
              value={option.value}
              onClick={() => handleClick(option)}
              sx={{
                height: "3.2rem",
                width: "4rem",
                border: 0,
                bgcolor: "#e7f4ff",
                color: "black",
                "&.MuiToggleButton-root.Mui-selected": {
                  color: "#75B3FF",
                },
                borderRadius: index === 0 ? '10px 0 0 10px' : index === FONT_STYLING_OPTIONS.length - 1 ? '0 10px 10px 0' : 0
              }}
            >
              {option.icon}
            </ToggleButton>
            {index < FONT_STYLING_OPTIONS.length - 1 && (
              <Divider orientation="vertical" variant="middle" flexItem />
            )}
          </>
        ))}
      </Stack>
    </ToggleButtonGroup>
  );
}

function LinesSpacing({ textStyle, setTextStyle, setLastChanged }: FontProps) {
  const handleChange = (e: SelectChangeEvent<string>) => {
    setLastChanged({ 'line-height': e.target.value });
    setTextStyle(prevStyle => ({...prevStyle, 'line-height': e.target.value}));
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ height: "100%" }}
    >
      <Typography variant="body1" marginRight={'1rem'}>Spacing</Typography>

      <Select
        sx={{
          height: "3.2rem",
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
            // backgroundColor: '#e7f4ff',
          },
          borderRadius: '10px',
          bgcolor: "#e7f4ff",
        }}
        size="small"
        value={textStyle['line-height']}
        onChange={handleChange}
        style={{paddingLeft: 0, paddingRight: '1.5rem'}}
      >
        {LINES_SPACING.map((option) => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}

function TextColor({ textStyle, setTextStyle, setLastChanged }: FontProps) {
  const [changeColorOpened, setChangeColorOpened] = useState<boolean>(false);
  console.log(textStyle['color'])

  const handleColorChange = (colorResult: ColorResult) => {
    const color = colorResult.hex;
    setLastChanged({ 'color': color });
    setTextStyle(prevStyle => ({...prevStyle, 'color': color}));
  };

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: "100%", backgroundColor: '#e7f4ff', borderRadius: '10px', padding: '0.7rem', cursor: 'pointer', maxWidth: '5.3rem'}}
        onClick={() => setChangeColorOpened((prevChangeColorOpened => !prevChangeColorOpened))}
      >
        <Typography variant="body1" marginRight={'0.8rem'}>Color</Typography>
        <div 
            style={
              {
                width: '1.8rem',
                height: '1.8rem',
                borderRadius: '4px',
                backgroundColor: textStyle['color'] || '#000000',
              }
          } 
        />
      </Stack>
      <Box>
        {changeColorOpened && <TwitterPicker color={textStyle['color'] || '#000000'} onChange={handleColorChange} colors={['#000000', '#0000FF', '#FF0000', '#00FF00', '#FFFF00']}/>}
      </Box>
    </Stack>
  );
}

function InvoiceStyleFonts({ textStyle, setTextStyle, setLastChanged }: FontProps) {
  return (
    <Stack gap={2} flexDirection={'row'} marginBottom={'5rem'}>
      <Box>
        <FontFamilySelect textStyle={textStyle} setTextStyle={setTextStyle} setLastChanged={setLastChanged}/>
      </Box>

      <Box>
        <FontWeightSelect textStyle={textStyle} setTextStyle={setTextStyle} setLastChanged={setLastChanged}/>
      </Box>

      <FontSizing textStyle={textStyle} setTextStyle={setTextStyle} setLastChanged={setLastChanged}/>

      <FontStyling textStyle={textStyle} setTextStyle={setTextStyle} setLastChanged={setLastChanged}/>

      <TextSide textStyle={textStyle} setTextStyle={setTextStyle} setLastChanged={setLastChanged}/>

      <LinesSpacing textStyle={textStyle} setTextStyle={setTextStyle} setLastChanged={setLastChanged}/>

      <TextColor textStyle={textStyle} setTextStyle={setTextStyle} setLastChanged={setLastChanged} />
    </Stack>
  );
}

export default function EditPage() {
  const [phoneSelected, setPhoneSelected] = useState<boolean>(false)
  const [desktopSelected, setDesktopSelected] = useState<boolean>(false)
  const [lastChanged, setLastChanged] = useState<Partial<TextStyle> | undefined>();
  const [textStyle, setTextStyle] = useState<TextStyle>({
    'font-family': 'Helvetica',
    'font-weight': '400',
    'font-size': '1rem',
    'font-style': 'normal',
    'text-decoration': 'none',
    'text-align': 'left',
    'line-height': '100%',
    'color': '#000000',
  });

  return (
    <Stack>
      <PreviewButtons phoneSelected={phoneSelected} desktopSelected={desktopSelected} setPhoneSelected={setPhoneSelected} setDesktopSelected={setDesktopSelected}/>
      <InvoiceStyleFonts textStyle={textStyle} setTextStyle={setTextStyle} setLastChanged={setLastChanged} />
      <Presentation isEditing={true} textStyle={textStyle} setTextStyle={setTextStyle} lastChanged={lastChanged}/>
      <Typography fontFamily="Helvetica Medium" variant="h1" fontWeight='500' sx={{marginTop: '4rem', marginLeft: '2.5rem', marginBottom: '2rem'}}>
        My Portfolio
      </Typography>
      <Images />
    </Stack>
  )
}
