import {useState, useEffect} from 'react'
import { Stack, Divider, Box } from '@mui/material'
import { MynkFormDialog } from '../components/MynkForm'
import { MeetingType } from '../api/types'
import Description from '../components/SchedulerLink/Description'
import ChooseMeeting from '../components/SchedulerLink/ChooseMeeting'
import DateAndTime from '../components/SchedulerLink/DateAndTime'
import AddDetails from '../components/SchedulerLink/AddDetails';
import Confirm from '../components/SchedulerLink/Confirm'
import dayjs from 'dayjs'

type SchedulerPreviewProps = {
    meetingTypes: Array<MeetingType>
    name: string
    description: string
    open: boolean
    onClose: () => void
    companyName: string
}

export default function SchedulerPreview({ meetingTypes, name, description, open, onClose, companyName }: SchedulerPreviewProps) {
    const [currentPage, setCurrentPage] = useState<1 | 2 | 3 | 4>(1)

    const [selectedMeetingType, setSelectedMeetingType] = useState<string>('');
    const [selectedDate, setSelectedDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
    const [selectedTime, setSelectedTime] = useState<string>('');
    const [selectedName, setSelectedName] = useState<string>('');
    const [selectedEmail, setSelectedEmail] = useState<string>('');
    const [selectedPhone, setSelectedPhone] = useState<string>('');

    useEffect(() => {
        if (open) {
            setCurrentPage(1);
        }
    }, [open]);

    const renderPage = () => {
        switch(currentPage) {
            case 1:
                return <ChooseMeeting
                    meetingTypes={meetingTypes}
                    setCurrentPage={setCurrentPage}
                    selectedMeetingType={selectedMeetingType}
                    setSelectedMeetingType={setSelectedMeetingType}
                />;
            case 2:
                return <DateAndTime
                    setCurrentPage={setCurrentPage}
                    meetingType={meetingTypes.find(meetingType => meetingType.uuid === selectedMeetingType)}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    companyName={companyName}
                />;
            case 3:
                return <AddDetails
                    setCurrentPage={setCurrentPage}
                    name={selectedName}
                    setName={setSelectedName}
                    email={selectedEmail}
                    setEmail={setSelectedEmail}
                    phone={selectedPhone}
                    setPhone={setSelectedPhone}
                 />;
            case 4:
                return <Confirm email={selectedEmail}/>;
            default:
                return null;
        }
    }

    return (
        <MynkFormDialog
            open={open}
            onClose={onClose}
            actionDisabled={false}
            onSubmit={() => {}}
            loading={false}
            noMargin
        >
            <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'} sx={{minHeight: '55rem', width: '110rem'}}>
                <Box width={'50%'} marginLeft={'7rem'}>
                    <Description name={name} description={description} />
                </Box>
                <Divider orientation={'vertical'} flexItem sx={{marginY: '5rem', marginX: '5rem'}} />
                <Box width={'50%'} marginRight={'7rem'}>
                {renderPage()}
            </Box>
            </Stack>
        </MynkFormDialog>
    )
}