import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import {
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PRIORITY_OPTIONS } from "../components/ChipSelect/commonOptions";
import React, { useEffect, useMemo, useState } from "react";
import { useAddTaskMutation } from "../api/tasks";
import { AddTaskParams, WorkflowType } from "../api/types";
import { DateSelectQuickOption } from "../components/MynkForm/controls/DateSelect";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import {AxiosError} from "axios";
import { minScale } from "react-advanced-cropper";

export const PAST_QUICK_OPTIONS = [
  { label: "Today", deltaMin: 0, deltaMax: 1 },
  { label: "Yesterday", deltaMin: -1, deltaMax: 0 },
];

export const FUTURE_QUICK_OPTIONS = [
  { label: "Today", deltaMin: 0, deltaMax: 1 },
  { label: "Tomorrow", deltaMin: 1, deltaMax: 2 },
];

export interface AddTaskDialogProps {
  open: boolean;
  onClose: () => void;
  defaultDelta?: number;
  quickOptions?: DateSelectQuickOption[];
  completed?: boolean;
  project_uuid?: string | null;
  client_uuid?: string | null;
}

export default function AddTaskDialog(props: AddTaskDialogProps) {
  const [title, setTitle] = useState(""); 
  const [linkOption, setLinkOption] = useState(props.client_uuid ? "client" : props.project_uuid ? "project" : "");
  const [additionalDetailsOpen, setAdditionalDetailsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  console.log(props)
  useEffect(() => {
    if (props.open) {
      setAdditionalDetailsOpen(false);
    }
  }, [props.open]);


  const addTask = useAddTaskMutation({
      onError: (error: unknown) => {
        if (error instanceof Error && "response" in error) {
          const axiosError = error as AxiosError<unknown, AddTaskParams>;
          const detail = (axiosError.response?.data as { detail?: string })
            ?.detail;
          setErrorMsg(detail ?? "");
        }
      }
  });
  
  const handleSubmit = async (values: any) => {
    const dueDate = new Date(values.due_date.valueOf());
  
    // Set to the last moment of the day in local time
    dueDate.setHours(23, 59, 59);
  
    // Adjust for UTC+3
    const offsetInMilliseconds = 3 * 60 * 60 * 1000; // 3 hours in milliseconds
    const adjustedDueDate = new Date(dueDate.getTime() + offsetInMilliseconds);
  
    const params = {
      title: title.trim(),
      priority: values.priority,
      due_date: adjustedDueDate.getTime(), // Use the adjusted due_date
      completed: props.completed ?? false,
      project_uuid: props.project_uuid ? props.project_uuid : (linkOption == "project" && values.projectUuid) ? values.projectUuid : undefined,
      client_uuid: props.client_uuid ? props.client_uuid : (linkOption == "client" && values.clientUuid) ? values.clientUuid : undefined,
    } as AddTaskParams;
  
    await addTask.mutateAsync(params);
    props.onClose();
  };

  const dueDateData = useMemo(() => {
    const quickOptions = props.quickOptions ?? FUTURE_QUICK_OPTIONS;

    let defaultIndex = null;
    if (props.defaultDelta !== undefined) {
      quickOptions.forEach((option, i) => {
        if (
          props.defaultDelta! >= option.deltaMin &&
          props.defaultDelta! < option.deltaMax
        ) {
          defaultIndex = i;
        }
      });
    }

    // Calculate the date 2 days ahead
    const twoDaysAhead = new Date();
    twoDaysAhead.setDate(twoDaysAhead.getDate() + 2);

    return {
      default: twoDaysAhead,
      defaultIndex,
      quickOptions,
    };
  }, [props.defaultDelta]);

  useEffect(() => {
    setTitle("");
  }, [props.open]);

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      action="Add Task"
      actionDisabled={title.length === 0}
      onSubmit={handleSubmit}
      loading={addTask.isPending}
      elevatorSx={{
        px: "3rem",
        py: "2rem",
      }}
      errorMsg={errorMsg}
    >
      <Stack sx={{ width: "28rem" }}>
        <TextField
          fullWidth
          multiline
          variant="standard"
          placeholder="Type task here.."
          InputProps={{
            disableUnderline: true,
            style: { fontSize: "1.8rem", fontFamily: "Helvetica Neue Bold" },
          }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <Grid container spacing="1.4rem" sx={{ mt: "0.4rem" }}>
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.DATE_SELECT}
              name="due_date"
              label="Due:"
              default={dueDateData.default}
              quickOptions={dueDateData.quickOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.CHIP_SELECT}
              name="priority"
              label="Priority:"
              default="medium"
              options={PRIORITY_OPTIONS}
              width="8rem"
            />
          </Grid>

{!props.client_uuid &&  !props.project_uuid && (
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="#69B3FF"
              sx={{ cursor: "pointer" }}
              onClick={() => setAdditionalDetailsOpen(!additionalDetailsOpen)}
            >
              {"Additional details >"}
            </Typography>
          </Grid>
)
          
}
          {additionalDetailsOpen && (
            <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
               <Grid item xs={12}>
               <RadioGroup value={linkOption} onChange={(event) => setLinkOption(event.target.value)}>
                  <Stack flexDirection={'row'}>
                    <FormControlLabel
                      value="client"
                      control={<Radio />}
                      label="Link client"
                    />
                    <span style={{marginTop: '0.7rem', marginLeft: '-0.5rem', opacity: 0.7}}>(optional)</span>
                  </Stack>
                  <Stack flexDirection={'row'}>
                    <FormControlLabel
                      value="project"
                      control={<Radio />}
                      label="Link project"
                    />
                    <span style={{marginTop: '0.7rem', marginLeft: '-0.5rem', opacity: 0.7}}>(optional)</span>
                  </Stack>
               </RadioGroup>
             </Grid>
             {linkOption == "client" ? (
             <Grid item xs={12}>
               <MynkFormControl
                 kind={MynkFormControlKind.CONTACT_SEARCH}
                 name="clientUuid"
                 default={props.project_uuid ?? null}
                 label="Client:"
               />
             </Grid>
             ) : linkOption == "project" ? (
             <Grid item xs={12}>
               <MynkFormControl
                 kind={MynkFormControlKind.PROJECT_SEARCH}
                 name="projectUuid"
                 default={props.client_uuid ?? null}
                 label="Project:"
                 workflowType={WorkflowType.PHOTOGRAPHY}
               />
             </Grid>
             ) : (
               <></>
             )}
             </>
          )}
       
        </Grid>
      </Stack>
    </MynkFormDialog>
  );
}
