import { MynkPage, MynkTabInfo } from "../../../components/MynkPage";
import { PATHS, WorkflowPath, makePath } from "../../../paths";

const TABS: MynkTabInfo[] = [
  {
    title: "View",
    path: "/view",
  },
  {
    title: "Edit",
    path: "/edit",
  },
  {
    title: "Templates",
    path: "/templates",
  },
];

export default function RealEstatePortalPage() {
  return (
    <MynkPage
      title="Portal"
      basePath={makePath(WorkflowPath.REAL_ESTATE, PATHS.portal.index)}
      tabs={TABS}
    />
  );
}
