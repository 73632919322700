import {atom} from 'jotai';
import {DomChangeRecord, Template} from '../../../api/types';
import {useParams} from 'react-router-dom';
import {useGetTemplateQuery} from '../../../api/templates';
import {TemplateRenderResult} from './Template';

// export interface InvoiceItem {
//   item: string;
//   quantity: string;
//   price: string;
// }
//
// export interface InvoiceTemplateData {
//   kind: 'invoice';
//   items: InvoiceItem[];
//   updates: number;
// }
//
// export type TemplateData = InvoiceTemplateData;

export interface TemplateData {
  settings: any;
  data: any;
  updates: number;
}

export const templateDataAtom = atom<TemplateData>({
  data: {},
  updates: 0,
});

export const templateInitialDetailsAtom = atom<null | any>(null);

export interface DomState {
  elements: Map<string, HTMLElement>;
  selectedElement: null | HTMLElement;
  counter: number;
  canDrag: boolean;
}

export const _domStateAtom = atom<null | DomState>(null);

export const recordsAtom = atom<DomChangeRecord[]>([]);

export interface TemplateChangesCheckpoint {
  // recordCount: number;
  lastUpdate: number;
}

export const templateChangesCheckpointAtom = atom<TemplateChangesCheckpoint>({
  // recordCount: 0,
  lastUpdate: 0,
});

export const templateDataChangedAtom = atom(
  (get) => {
    const checkpoint = get(templateChangesCheckpointAtom);
    return get(templateDataAtom).updates > checkpoint.lastUpdate;
  },
);

export const templateSentAtom = atom<boolean>(false);

export interface TemplateRef {
  renderTemplate: () => Promise<TemplateRenderResult | null>;
  rerender: () => void;
}

export const templateRefAtom = atom<null | TemplateRef>(null);

export function useCurrentTemplate (): undefined | null | Template {
  const { templateId } = useParams();
  if (!templateId)
    return null;

  const { data, isPending } = useGetTemplateQuery({ uuid: templateId });
  if (isPending)
    return undefined;

  return data?.template ?? null;
}

export function makeEmptyDomState (): DomState {
  return {
    elements: new Map(),
    selectedElement: null,
    counter: 0,
    canDrag: false,
  };
}

interface TemplateHooks {
  deselectAll: () => void;
}
