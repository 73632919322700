import {MynkPage, MynkTabInfo} from "../../../components/MynkPage";
import { PATHS, WorkflowPath, makePath } from "../../../paths";
import { useAtomValue } from "jotai";
import { currentWorkflowAtom } from "../../../components/MainAppBar/UserControlPopup";


const TABS: MynkTabInfo[] = [
  {
    title: 'All events',
    path: '/calendar',
  },
  {
    title: 'Scheduler',
    path: '/scheduler',
  },
];

export default function SchedulerPage() {
  return (
    <MynkPage
      title="Calendar"
      basePath={makePath(`/${useAtomValue(currentWorkflowAtom)?.type}` as WorkflowPath, PATHS.scheduler.index)}
      tabs={TABS}
    />
  );
}
