import { useState } from "react";
import { MynkTab } from "../../../../components/MynkPage";
import {
  Box,
  Card,
  Stack,
} from "@mui/material";
import ListingsView from "../../../../components/ListingsView";
import {
  Availability,
  FilterOption,
  SearchOption,
  SortOption,
} from "../../../../api/types";
import {
  FILTER_REAL_ESTATE_LISTINGS_OPTIONS,
  SORT_PROJECTS_OPTIONS,
  SORT_REAL_ESTATE_LISTINGS_OPTIONS,
} from "../../../../components/ChipSelect/commonOptions";
import SearchFilterSort from "../../../../components/SearchFilterSort";
import { ListingsActionNewButton } from "./All";
import { TableType } from "../../../../components/SimpleTable";
import { ListingsTableCard } from "../common/ListingsTableCard";

export default function ListingsArchivedSubpage() {
  return (
    <MynkTab title="Archived" action={<ListingsActionNewButton />}>
      <ListingsTableCard tableType={TableType.COMPLETED_PROJECTS} availability={Availability.NOT_AVAILABLE}/>
    </MynkTab>
  );
}
