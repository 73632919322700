import {atom} from "jotai";
import {atomWithStorage} from "jotai/utils";


export interface LoginState {
  accessToken: string;
  refreshToken: string;
  expiresIn: Date;
}

export const loginStateAtom = atomWithStorage<null | LoginState>('login-state', null);
export const axiosAuthenticatedAtom = atom(false);
