import { useMutation, useQueryClient, useQuery, UseMutationOptions } from "@tanstack/react-query";
import axios from "axios";
import {
  AddLibraryFavoriteParams,
  AddLibraryFavoriteResponse,
  ListLibraryFavoritesParams,
  ListLibraryFavoritesResponse,
  DeleteLibraryFavoriteParams,
  DeleteLibraryFavoriteResponse
} from "./types";

export function useAddFavoriteMutation(
  options: UseMutationOptions<AddLibraryFavoriteResponse, unknown, AddLibraryFavoriteParams>
) {
  const queryClient = useQueryClient();
  return useMutation<AddLibraryFavoriteResponse, unknown, AddLibraryFavoriteParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/library/add-favorite", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["favorites-list"]});
    },
    ...options
  });
}

export function useListFavoritesQuery(params: ListLibraryFavoritesParams) {
  return useQuery<ListLibraryFavoritesResponse>({
    queryKey: ["favorites-list", params],
    queryFn: async () => (await axios.post("/api/library/list-favorites", params)).data,
  });
}

export function useDeleteFavoriteMutation(
  options: UseMutationOptions<DeleteLibraryFavoriteResponse, unknown, DeleteLibraryFavoriteParams>
) {
  const queryClient = useQueryClient();
  return useMutation<DeleteLibraryFavoriteResponse, unknown, DeleteLibraryFavoriteParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/library/delete-favorite", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["favorites-list"]});
    },
    ...options
  });
}