import {MynkPage} from "../../../components/MynkPage";

const TABS = [
  {
    title: 'All',
    path: '',
  },
]

export default function ExpensesPage() {
  return (
    <MynkPage
      title="Expenses"
      basePath="/dashboard/expenses"
      tabs={TABS}
    />
  );
}
