import {
    SimpleTableCard,
    SimpleTableColumn,
    SimpleTableColumnType,
    TableType,
  } from "../../../../components/SimpleTable";
  import { useCallback, useEffect, useMemo, useState } from "react";
  import {
    DeleteProjectParams,
    FilterOption,
    ListProjectsResponse,
    SearchOption,
    SortOption,
    Availability,
    RealEstateListing,
    ListingType,
    WorkflowType,
  } from "../../../../api/types";
  import {
    useDeleteProjectMutation,
    useListProjectsQuery,
  } from "../../../../api/projects";
  import {
    FILTER_REAL_ESTATE_LISTINGS_OPTIONS,
    SORT_REAL_ESTATE_LISTINGS_OPTIONS,
  } from "../../../../components/ChipSelect/commonOptions";
  import { useNavigate } from "react-router-dom";
  import ConfirmActionDialog from "../../../../components/ConfirmActionDialog";
  import ThreeDotsButton from "../../../../components/ThreeDotsButton";
  import { atom, useAtom } from "jotai";
  import { PATHS, WorkflowPath, makePath } from "../../../../paths";
import { EditListingDialog } from "../../../../dialogs/EditRealEstateListing";
import { Box, Typography } from "@mui/material";
import { ChipSelect } from "../../../../components/ChipSelect";
  
  const selectedListingAtom = atom<RealEstateListing | null>(null);
  const openedEditAtom = atom(false);
  const openedDeleteAtom = atom(false);
  
  const BASE_COLUMNS: SimpleTableColumn[] = [
    {
      key: "name",
      label: "Listing",
      type: SimpleTableColumnType.NAME,
    },
    {
      key: "client_name",
      label: "Owner",
      type: SimpleTableColumnType.LINK,
    },
    {
      key: "price",
      label: "Price",
      type: SimpleTableColumnType.USD,
    },
    {
      key: "stage",
      label: "Stage",
      /* Replaced later */
    },
    {
      key: "type",
      label: "Type",
      type: SimpleTableColumnType.REAL_ESTATE_LISTING_TYPE
    },
    {
      key: "view_listing",
      label: "View",
      hideHeader: true,
    },
    {
      key: "",
      label: "",
      render(_: unknown, row: any) {
        return <ThreeDotsListing listing={row} />;
      },
    },
  ];
  
  enum threeDotsOptions {
    EDIT = "edit-project",
    DELETE = "delete-project",
  }
  
  const DOTS_MENU = [
    {
      id: threeDotsOptions.EDIT,
      label: "Edit",
    },
    {
      id: threeDotsOptions.DELETE,
      label: "Delete",
    },
  ];
  
  interface ThreeDotsListingProps {
    listing: RealEstateListing;
  }
  
  function ThreeDotsListing(props: ThreeDotsListingProps) {
    const [selectedListing, setSelectedListing] = useAtom(selectedListingAtom);
    const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
    const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);
  
    /**
     * Handles the click event on the menu options.
     * Sets the corresponding state variable to `true` to open the corresponding dialog component.
     *
     * @param id - The ID of the clicked menu option.
     */
    const handleClickItem = (id: string) => {
        setSelectedListing(props.listing);
  
      switch (id) {
        case threeDotsOptions.EDIT:
          setOpenedEdit(true);
          break;
        case threeDotsOptions.DELETE:
          setOpenedDelete(true);
          break;
      }
    };
  
    return (
      <ThreeDotsButton
        menu={DOTS_MENU}
        onClickItem={handleClickItem}
        children={undefined}
      ></ThreeDotsButton>
    );
  }
  
  export interface ListingsTableCardProps {
    tableType: TableType;
    availability?: Availability;
  }
  
  const PAGE_SIZE = 10;
  
  export function ListingsTableCard(props: ListingsTableCardProps) {
    const [selectedListing, setSelectedListing] = useAtom(selectedListingAtom);
    const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
    const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);
  
    const [search, setSearch] = useState<SearchOption | undefined>(undefined);
    const [filter, setFilter] = useState<FilterOption | undefined>(undefined);
    const [sort, setSort] = useState<SortOption | undefined>(undefined);
  
    const [selectedPage, setSelectedPage] = useState(0);
    const [current, setCurrent] = useState<null | ListProjectsResponse>(null);
  
    const { data, isPending } = useListProjectsQuery({
      page_size: PAGE_SIZE,
      page_index: selectedPage,
      retrieve_options: {
        search: search,
        filter: filter,
        sort: sort,
      },
      availability: props.availability ?? undefined,
    });
  
    // If something changes - re-paginate everything:
    useEffect(() => {
      setSelectedPage(0);
    }, [ search, filter, sort]);
  
    useEffect(() => {
      if (!isPending && data) {
        setCurrent(data);
      }
    }, [data, isPending]);
  
  
    const navigate = useNavigate();
    const handleViewListing = useCallback((row: RealEstateListing) => {
      navigate(makePath(WorkflowPath.REAL_ESTATE, PATHS.viewProject(row.uuid).general));
    }, []);

    const handleViewOwner = useCallback((row: RealEstateListing) => {
      navigate(makePath(WorkflowPath.REAL_ESTATE, PATHS.viewContact(row.client_uuid).general));
    }, []);

    const columns = useMemo(() => {
      const result = [...BASE_COLUMNS];

      const stageIndex = result.findIndex((c) => c.key === "stage");
      result[stageIndex] = {
        key: "stage",
        label: "Stage",
        render(_: unknown, row: any) {
          return (
            <Box sx={{
              borderRadius: "3.5714rem",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              height: "2.1429rem",
              width: "5.7143rem",
              bgcolor: "white",
              boxShadow: "0px 1px 5px rgba(117, 179, 255, 0.35)"
            }}> 
              <Typography fontSize={"1rem"} fontFamily="Helvetica Neue">
                {row.type == ListingType.FOR_RENT ? "Leased" : "Sold"}
              </Typography>
            </Box>
          );
        }
      }
      const viewListingIndex = result.findIndex((c) => c.key === "view_listing");
      result[viewListingIndex] = {
        key: "view_listing",
        label: "View",
        hideHeader: true,
        render(_: unknown, row: any) {
          return (
            <Typography
              onClick={() => handleViewListing(row)}
              variant="body2"
              sx={{
                color: "#75b3ff",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              View
            </Typography>
          );
        },
      };

      return result;
    }, []);
  
    const deleteProject = useDeleteProjectMutation();
  
    const handleDeleteProject = async (uuid: string) => {
      const params = {
        uuid: uuid,
      } as DeleteProjectParams;
  
      await deleteProject.mutateAsync(params);
      setOpenedDelete(false);
    };
  
    return (
      <>
        <ConfirmActionDialog
          open={openedDelete}
          what="listing"
          whatAction="delete"
          onClose={() => setOpenedDelete(false)}
          onConfirm={() =>
            selectedListing
              ? handleDeleteProject(selectedListing.uuid)
              : undefined
          }
          loading={deleteProject.isPending}
        >
          Delete
        </ConfirmActionDialog>
  
        <EditListingDialog
          open={openedEdit}
          onClose={() => setOpenedEdit(false)}
          listing={selectedListing ?? undefined}
        />
  
        <SimpleTableCard
          title="Archived listings"
          data={current?.projects}
          columns={columns}
          tableType={props.tableType}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          pageSize={PAGE_SIZE}
          numOfPages={current?.num_of_pages ?? 1}
          total={current?.total ?? 0}
          isPending={isPending}
          onClickLink={handleViewOwner}
          searchState={[search, setSearch]}
          filterState={[filter, setFilter]}
          sortState={[sort, setSort]}
          filterOptions={FILTER_REAL_ESTATE_LISTINGS_OPTIONS}
          sortOptions={SORT_REAL_ESTATE_LISTINGS_OPTIONS}
        />
      </>
    );
  }
  