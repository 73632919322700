import {useState, useRef, useEffect} from 'react'
import { format, addDays } from 'date-fns';
import { Stack, Divider, Box, TextField, Autocomplete, Popper, InputAdornment, Typography } from '@mui/material'
import { Add } from "@mui/icons-material";
import CheckBoxUnchecked from '../../../../assets/icons/simple-checkbox-unchecked.svg'
import CheckBoxChecked from '../../../../assets/icons/simple-checkbox-checked.svg'
import chipSelectArrowIcon from "../../../../assets/icons/chipselect-arrow-icon.svg";
import CopyIcon from '../../../../assets/icons/copy-icon.svg'
import Edit from '../../../../assets/icons/edit.svg'
import ClockIcon from '../../../../assets/icons/small-clock-icon.svg'
import EyeIcon from '../../../../assets/icons/small-eye-icon.svg'
import LocationIcon from '../../../../assets/icons/small-location-icon.svg'
import { BoxWithGradientFrame } from '../../../../components/GradientFrame'
import ActionMenuButton from "../../../../components/ActionMenuButton";
import MeetingTypeDialog from '../../../../dialogs/MeetingType';
import EditMeetingTypeDialog from '../../../../dialogs/EditMeetingType';
import AddMeetingType from '../../../../dialogs/AddMeetingType';
import SchedulerPreview from '../../../../dialogs/SchedulerPreview';
import ThreeDotsButton from '../../../../components/ThreeDotsButton';
import SmallPlusIcon from '../../../../assets/icons/small-plus-icon.svg'
import SmallXIcon from '../../../../assets/icons/small-X-icon.svg'
import {
    useListMeetingTypesQuery,
    useListAvailableMeetingTimesQuery,
    useEditAvailableMeetingTimesMutation,
    useAddDescriptionMutation,
    useDeleteMeetingTypeMutation,
    useGetPortalQuery,
} from '../../../../api/scheduler';
import {
    EditAvailableMeetingTimesParams,
    MeetingType,
    AvailableMeetingTime,
    AddDescriptionParams
} from '../../../../api/types'
import {AxiosError} from "axios";
import dayjs from 'dayjs'
import { profilePhotoAtom } from '../../../../components/ProfilePicture';
import { useAtomValue } from 'jotai';
import ConfirmActionDialog from '../../../../components/ConfirmActionDialog';
import { MynkTab } from '../../../../components/MynkPage';

type SchedulerAvailabilityProps = {
    availabilities: Array<AvailableMeetingTime>;
    setAvailabilities: React.Dispatch<React.SetStateAction<Array<AvailableMeetingTime>>>;
}

type HourInputProps = SchedulerAvailabilityProps & {
    placeholder: 'start_time' | 'end_time';
    index: number;
    timeIndex: number;
}

type GeneralSchedulerComponentProps = {
    availabilities: Array<AvailableMeetingTime>;
    meetingTypes: Array<MeetingType>;
    setMeetingTypes: React.Dispatch<React.SetStateAction<Array<MeetingType>>>;
}

type MeetingTypeComponentProps = GeneralSchedulerComponentProps & {
    meetingType: MeetingType;
    index: number;
    companyName: string;
}

enum NewMenuIds {
    NEW_MEETING_TYPE = "new-meeting-type",
}

enum threeDotsOptions {
    EDIT = "edit-lead",
    DELETE = "delete-lead",
}

const DOTS_MENU = [
    {
      id: threeDotsOptions.EDIT,
      label: "Edit",
    },
    {
      id: threeDotsOptions.DELETE,
      label: "Delete",
    },
];
  

const NEW_MENU = [
    {
        id: NewMenuIds.NEW_MEETING_TYPE,
        label: "New meeting type",
    },
];

function HourInput(props: HourInputProps) {
    const listboxRef = useRef<HTMLUListElement | null>(null);
    const [inputValue, setInputValue] = useState('');
  
    const options = Array.from({ length: 24 * 4 }, (_, i) => {
      const hour = Math.floor(i / 4) % 12 === 0 ? 12 : Math.floor(i / 4) % 12;
      const minutes = (i % 4) * 15;
      const amPm = Math.floor(i / 4) < 12 ? 'am' : 'pm';
      return `${hour}:${minutes.toString().padStart(2, '0')} ${amPm}`;
    });
  
    useEffect(() => {
      if (listboxRef.current && inputValue) {
        const nearestHourIndex = options.findIndex(option => option.startsWith(inputValue));
        if (nearestHourIndex >= 0) {
            const listItem = listboxRef.current.querySelector(`li:nth-child(${nearestHourIndex + 1})`);
            if (listItem && listItem instanceof HTMLElement) {
                listboxRef.current.scrollTop = listItem.offsetTop;
            }
        }
      }
    }, [inputValue, options]);

    return (
        <BoxWithGradientFrame>
            <Autocomplete
                disablePortal
                id="hour-autocomplete"
                filterOptions={(x) => x}
                PopperComponent={(props) => (
                    <Popper {...props} style={{ backgroundColor: "white", borderRadius: "1rem", width: "100%" }}/>
                )}
                value={props.availabilities[props.index][props.placeholder][props.timeIndex]}
                onChange={(event, newValue) => {
                    props.availabilities[props.index][props.placeholder][props.timeIndex] = newValue ?? props.availabilities[props.index][props.placeholder][props.timeIndex];
                    props.setAvailabilities([...props.availabilities]);
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        key={props.index + props.placeholder}
                        variant="standard"
                        fullWidth
                        sx={{
                            width: '6.8rem',
                            marginLeft: '1.2rem',
                            paddingRight: '0.5rem',
                            marginRight: props.placeholder === 'end_time' ? '0.5rem' : '0rem',
                            marginTop: '0.3rem'
                        }}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Box component="img" src={chipSelectArrowIcon} sx={{ width: "0.9rem", height: "0.9rem", mr: "-2rem" }} />
                                </InputAdornment>
                            ),
                            sx: {
                                height: '3.5rem',
                                pr: "1.2rem !important",
                            },
                        }}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} key={option}>
                        {option}
                    </li>
                )}
                options={options}
                ListboxProps={{ style: { maxHeight: '11rem', overflow: 'auto' } }}
            />
        </BoxWithGradientFrame>
    )
}

function SchedulerAvailability(props: SchedulerAvailabilityProps) {
    const handleCheckedClick = (index: number) => {
        if (props.availabilities[index].start_time.length > 0 && props.availabilities[index].end_time.length > 0) {
            props.availabilities[index].start_time = [];
            props.availabilities[index].end_time = [];
        } else {
            props.availabilities[index].start_time = ['9:00 am'];
            props.availabilities[index].end_time = ['5:00 pm'];
        }

        props.setAvailabilities([...props.availabilities]);
    }

    const handlePlusClick = (index: number) => {
        props.availabilities[index].start_time.push('9:00 am');
        props.availabilities[index].end_time.push('5:00 pm');
        props.setAvailabilities([...props.availabilities]);
    }

    const handleDeleteHour = (index: number, timeIndex: number) => {
        props.availabilities[index].start_time.splice(timeIndex, 1);
        props.availabilities[index].end_time.splice(timeIndex, 1);
        props.setAvailabilities([...props.availabilities]);
    }
    
    return (
        <Stack flexDirection={'column'} sx={{marginLeft: '3.3rem', marginRight: '3rem', marginBottom: '2rem'}}>
            {props.availabilities.map((availability: AvailableMeetingTime, index) => (
                <Stack key={index} flexDirection={'row'} minHeight={'3.8rem'} my={0.5}>
                    <Box
                        component="img"
                        src={availability.start_time.length > 0 && availability.end_time.length > 0 ? CheckBoxChecked : CheckBoxUnchecked}
                        onClick={() => handleCheckedClick(index)}
                        sx={{cursor: 'pointer', marginRight: '1rem', width: '1.5rem', height: '1.5rem', marginTop: '1.15rem'}}
                    />
                    
                    <Typography style={{
                        width: '6.5rem',
                        marginRight: '3.4rem',
                        marginTop: '1.3rem',
                        lineHeight: 'normal'
                    }}>{availability.day}</Typography>
                    
                    {availability.start_time && availability.end_time && (
                        <Stack>
                            {availability.start_time.map((time, timeIndex) => {
                                return (
                                    <>
                                        <Stack flexDirection={'row'}>
                                            <HourInput placeholder={'start_time'} index={index} timeIndex={timeIndex} {...props}/>

                                            <Typography sx={{
                                                color: 'rgba(0, 0, 0, 0.30)',
                                                fontFeatureSettings: "'clig' off, 'liga' off",
                                                fontSize: '2.2rem',
                                                fontWeight: 400,
                                                lineHeight: '2.6rem',
                                                marginRight: '0.5rem',
                                                marginLeft: '0.5rem',
                                                marginTop: '0.5rem'
                                            }}>-</Typography>
            
                                            <HourInput placeholder={'end_time'} index={index} timeIndex={timeIndex} {...props}/>

                                            <Stack flexDirection={'row'} ml={1.5}>
                                                <Box
                                                    component="img"
                                                    src={SmallXIcon}
                                                    sx={{cursor: 'pointer', width: '0.8rem', height: '0.8rem', marginTop: '1.5rem'}}
                                                    onClick={() => handleDeleteHour(index, timeIndex) }
                                                />
                                                {timeIndex === 0 && (
                                                    <Box
                                                        component="img"
                                                        src={SmallPlusIcon}
                                                        sx={{cursor: 'pointer', marginLeft: '0.7rem', borderRadius: '100%', border: '1px dashed #AFB1B3', width: '0.8rem', height: '0.8rem', padding: '0.15rem', marginTop: '1.35rem'}}
                                                        onClick={() => handlePlusClick(index)}
                                                    />
                                                )}
                                            </Stack>
                                        </Stack>
                                    </>
                                )
                            })}
                        </Stack> 
                    )}
                </Stack>
            ))}
        </Stack>
    );
};

function AvailabilityComponent(props: SchedulerAvailabilityProps) {
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

    const editAvailableMeetingTime = useEditAvailableMeetingTimesMutation({
        onError: (error: unknown) => {
            if (error instanceof Error && "response" in error) {
                const axiosError = error as AxiosError<unknown, EditAvailableMeetingTimesParams>;
                const detail = (axiosError.response?.data as { detail?: string })
                ?.detail;
                setErrorMsg(detail ?? "");
            }
        }
    })

    useEffect(() => {
        const handleSave = () => {
            editAvailableMeetingTime.mutate({ available_meeting_times: props.availabilities });
        }

        if (props.availabilities.length > 0)
            handleSave();
    }, [props.availabilities]);

    return (
        <Stack sx={{borderRadius: '20px', background: 'background: var(--Window-c, linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.30) 100%))', boxShadow: '0px 0px 20px 0px rgba(117, 179, 255, 0.20)', width: 'fit-content', height: 'fit-content', marginLeft: '5rem', marginTop: '3rem', marginRight: '5rem'}}>     
            <h1 style={{
                color: '#404040',
                fontSize: '2.6rem',
                fontWeight: '700',
                lineHeight: '3.1rem',
                marginLeft: '4rem',
                marginTop: '3rem'
            }}>Availability</h1>

            <Divider sx={{width: '35rem', marginLeft: '3rem', marginTop: '1rem', marginBottom: '1rem', marginRight: '3rem'}}/>

            <SchedulerAvailability {...props}/>
        </Stack>
    )
}

function MeetingTypeComponent(props: MeetingTypeComponentProps) {
    const [meetingTypeOpen, setMeetingTypeOpen] = useState<boolean>(false);
    const [editMeetingTypeOpen, setEditMeetingTypeOpen] = useState<boolean>(false);
    const [openDeleteMeetingType, setOpenDeleteMeetingType] = useState<boolean>(false);
    const deleteMeetingType = useDeleteMeetingTypeMutation();
    const link = `app.mynk.ai/portal/${props.companyName}/${props.meetingType.uuid}`;
    const [linkCopied, setLinkCopied] = useState(false);

    const handleThreeDotsClick = (id: string) => {
        if (!props.meetingType.uuid) return;

        switch (id) {
            case threeDotsOptions.EDIT:
                setEditMeetingTypeOpen(true);
                break;
            case threeDotsOptions.DELETE:
                setOpenDeleteMeetingType(true)
                break;
        }
    }

    const handleDelete = () => {
        if (!props.meetingType.uuid) return;

        deleteMeetingType.mutateAsync({ uuid: props.meetingType.uuid });
        const updatedMeetingTypes = props.meetingTypes.filter(item => item?.uuid !== props.meetingType.uuid);
        props.setMeetingTypes(updatedMeetingTypes);
    }

    return (
        <>
            <EditMeetingTypeDialog
                open={editMeetingTypeOpen}
                onClose={() => setEditMeetingTypeOpen(false)}
                {...props}
            />

            <MeetingTypeDialog
                open={meetingTypeOpen}
                onClose={() => setMeetingTypeOpen(false)}
                portalLink={link}
                {...props}
            />
            
            <ConfirmActionDialog
                open={openDeleteMeetingType}
                what="meeting type"
                whatAction="delete"
                onClose={() => setOpenDeleteMeetingType(false)}
                onConfirm={handleDelete}
                loading={deleteMeetingType.isPending}
            >
                Delete
            </ConfirmActionDialog>

            <Stack
                flexDirection={'column'}
                justifyContent={'space-between'}
                onClick={() => setMeetingTypeOpen(true)}
                sx={{
                    flex: '0 0 calc(50% - 2.7rem)',
                    height: '9.5rem',
                    borderRadius: '20px',
                    background: 'var(--D, linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%))',
                    boxShadow: '0px 5px 25px 0px rgba(117, 179, 255, 0.20)',
                    marginLeft: props.index % 2 === 0 ? '1.5rem' : '0rem',
                    marginRight: props.index % 2 === 0 ? '0rem' : '1.5rem',
                    marginTop: props.index < 2 ? '1.5rem' : '0rem',
                    cursor: 'pointer',
                    paddingX: '0.2rem',
                }}
            >
                <Stack justifyContent={'space-between'} flexDirection={'row'}>
                    <Typography sx={{
                        fontSize: '1.3rem',
                        fontWeight: 700,
                        marginTop: '1.4rem',
                        marginLeft: '1rem',
                    }}>
                        {props.meetingType.name}
                    </Typography>
                    <Box
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    >
                        <ThreeDotsButton
                            menu={DOTS_MENU}
                            onClickItem={handleThreeDotsClick}
                            children={undefined}
                            sx={{cursor: 'pointer', marginLeft: '0.5rem', marginTop: '1.3rem', height: '1.5rem', marginRight: '1rem', marginBottom: '1.3rem', px: '0.5rem'}}
                        ></ThreeDotsButton>
                    </Box>
                </Stack>

                <Stack flexDirection={'row'} justifyContent={'flex-start'} sx={{height: '1.5rem', marginLeft: '1rem', marginBottom: '0.5rem'}}>
                    <Stack flexDirection={'row'} gap={'1rem'} sx={{marginRight: '1.3rem'}}>
                        <Box
                            component="img"
                            src={ClockIcon}
                            sx={{width: '1rem', height: '1rem', marginTop: '0.2rem'}}
                        />
                        <Typography sx={{
                            fontSize: '1.3rem',
                            marginLeft: '-0.5rem',
                        }}>
                            {props.meetingType.duration}
                        </Typography>
                    </Stack>
                    <Stack flexDirection={'row'} gap={'1rem'}>
                        <Box
                            component="img"
                            src={LocationIcon}
                            sx={{width: '1rem', height: '1rem', marginTop: '0.2rem'}}
                        />
                        <Typography sx={{
                            fontSize: '1.3rem',
                            marginLeft: '-0.5rem',
                        }}>
                            {props.meetingType.location}
                        </Typography>
                    </Stack>
                </Stack>

                <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{height: '1.5rem', marginLeft: '1rem', marginBottom: '1rem'}}>
                    <Stack flexDirection={'row'} gap={'1rem'} sx={{marginRight: '1.3rem'}}>
                        <Box
                            component="img"
                            src={EyeIcon}
                            sx={{width: '1rem', height: '1rem', marginTop: '0.2rem'}}
                        />
                        <Typography sx={{
                            fontSize: '1.3rem',
                            marginLeft: '-0.5rem',
                        }}>
                            {props.meetingType.isPublic ? 'Public' : 'Private'}
                        </Typography>
                    </Stack>
                    <Stack
                        flexDirection={'row'}
                        gap={'1rem'}
                        sx={{marginRight: '1rem', cursor: 'pointer'}}
                        onClick={(event) => {
                            event.stopPropagation();
                            navigator.clipboard.writeText(link);
                            setLinkCopied(true);
                            setTimeout(() => setLinkCopied(false), 1000);
                        }}
                    >
                        <Box
                            component="img"
                            src={CopyIcon}
                            sx={{width: '1rem', height: '1rem', marginTop: '0.3rem'}}
                        />
                        <Typography sx={{
                            fontSize: '1.3rem',
                            marginLeft: '-0.5rem',
                            color: 'rgba(64, 64, 64, 0.50)',
                            textDecoration: 'underline',
                        }}>
                            {linkCopied ? 'Copied!' : 'Link'}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}

function GeneralSchedulerComponent(props: GeneralSchedulerComponentProps) {
    const [addMeetingTypeOpen, setAddMeetingTypeOpen] = useState<boolean>(false);
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [linkCopied, setLinkCopied] = useState(false);
    const [description, setDescription] = useState<string>('');

    const {data, isPending} = useGetPortalQuery({});

    useEffect(() => {
        if (data && !isPending) {
            setDescription(data.description);
        }
    }, [data]);

    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

    const addDescription = useAddDescriptionMutation({
        onError: (error: unknown) => {
            if (error instanceof Error && "response" in error) {
                const axiosError = error as AxiosError<unknown, AddDescriptionParams>;
                const detail = (axiosError.response?.data as { detail?: string })
                ?.detail;
                setErrorMsg(detail ?? "");
            }
        }
    })

    const handleDescritptionEdit = (editDescription: string) => {
        addDescription.mutate({ description: editDescription });
    }

    return (
        <Stack>
            <AddMeetingType meetingTypes={props.meetingTypes} open={addMeetingTypeOpen} onClose={() => setAddMeetingTypeOpen(false)} setMeetingTypes={props.setMeetingTypes}/>

            <Stack sx={{borderRadius: '20px', background: 'background: var(--Window-c, linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.30) 100%))', boxShadow: '0px 0px 20px 0px rgba(117, 179, 255, 0.20)', minHeight: '50rem', height: 'fit-content'}}>
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography
                        sx={{
                            fontSize: '1.8rem',
                            fontWeight: 500,
                            lineHeight: '2.4rem',
                            marginTop: '2rem',
                            marginLeft: '2rem'
                        }}
                    >
                        General scheduler
                    </Typography>

                    {data && (
                        <SchedulerPreview meetingTypes={props.meetingTypes} name={data.company_name} description={description} open={previewOpen} onClose={() => setPreviewOpen(false)} companyName={data.company_name} />
                    )}

                    <Typography
                        sx={{
                            color: '#75B3FF',
                            fontSize: '1.2rem',
                            marginTop: '2.3rem',
                            marginRight: '2.3rem',
                            cursor: 'pointer',
                        }}
                        onClick={() => setPreviewOpen(true)}
                    >
                        Preview
                    </Typography>
                </Stack>
                {data && (
                    <Stack
                        flexDirection={'row'}
                        onClick={(event) => {
                            navigator.clipboard.writeText(`app.mynk.ai/portal/${data.company_name}`);
                            setLinkCopied(true); // Set linkCopied to true when the link is copied
                            setTimeout(() => setLinkCopied(false), 1000); // Set linkCopied back to false after 1 second
                        }}
                        sx={{cursor: 'pointer'}}
                    >
                        <Box
                            component="img"
                            src={CopyIcon}
                            sx={{cursor: 'pointer', marginLeft: '2rem', marginTop: '2.4rem', width: "1.5rem", height: "1.5rem"}}
                        />

                        <Typography style={{
                            color: '#404040',
                            fontSize: '1.4rem',
                            marginLeft: '0.7rem',
                            marginTop: '2rem',
                            opacity: '0.7',
                        }}>
                            {linkCopied ? 'Copied: ' : 'Link:'}
                        </Typography>

                        <Typography style={{
                            color: '#404040',
                            fontSize: '1.4rem',
                            marginTop: '2rem',
                            marginLeft: '0.5rem',
                        }}>
                            app.mynk.ai/portal/{data.company_name ?? ""}
                        </Typography>
                    </Stack>
                )}

                <Divider sx={{width: '100%', marginTop: '1.5rem'}}/>

                <Stack>
                    <Typography style={{
                        fontSize: '1.8rem',
                        fontWeight: 500,
                        marginTop: '1.5rem',
                        marginLeft: '2rem'
                    }}>
                        Opening message
                    </Typography>

                    <Box
                        sx={{
                            height: '10rem',
                            mx: '2rem',
                            marginTop: '1rem',
                            borderRadius: '20px',
                            background: 'var(--D, linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%))',
                            boxShadow: '0px 5px 25px 0px rgba(117, 179, 255, 0.20)',
                            overflow: 'hidden',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <TextField
                            variant="standard"
                            value={description}
                            onChange={(event) => handleDescritptionEdit(event.target.value)}
                            multiline
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    fontSize: '1.1rem',
                                    lineHeight: 'normal',
                                    marginRight: '2rem',
                                    marginLeft: '2rem',
                                    overflow: 'hidden',
                                    whiteSpace: 'pre-wrap', // Preserve line breaks
                                    wordWrap: 'break-word', // Break long words
                                    textOverflow: 'ellipsis', // Add ellipsis for long text
                                    display: 'block', // Ensure block rendering
                                    maxHeight: '8rem', // Set max height
                                },
                            }}
                            sx={{
                                width: '46rem',
                                height: '8rem', // Restrict overall height
                                resize: 'none', // Prevent resizing
                                overflow: 'hidden',
                                wordWrap: 'break-word',
                            }}
                        />
                    </Box>
                </Stack>

                <Stack>
                    <Typography style={{
                        fontSize: '1.8rem',
                        fontWeight: 500,
                        marginTop: '1.5rem',
                        marginLeft: '2rem'
                    }}>
                        Meeting types
                    </Typography>
                    <Stack flexWrap={'wrap'} flexDirection={'row'} justifyContent={'flex-start'} gap={'1.5rem'}>
                        {props.meetingTypes.map((meetingType, index) => (
                            <MeetingTypeComponent key={index} meetingType={meetingType} index={index} companyName={data.company_name ?? ""} {...props} />
                        ))}
                    </Stack>
                    <Typography
                        onClick={() => setAddMeetingTypeOpen(true)}
                        sx={{
                            color: '#75B3FF',
                            textAlign: 'center',
                            fontSize: '1.4rem',
                            marginTop: '1.5rem',
                            marginBottom: '1.5rem',
                            cursor: 'pointer',
                        }}
                    >
                        + New meeting type
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}

function SchedulerActionMenuButton(props: GeneralSchedulerComponentProps) {
    const [addMeetingTypeOpen, setAddMeetingTypeOpen] = useState<boolean>(false);
  
    const handleClickItem = (id: string) => {
      switch (id) {
        case NewMenuIds.NEW_MEETING_TYPE:
            setAddMeetingTypeOpen(true);
            break;
      }
    };
  
    return (
      <>
        <AddMeetingType open={addMeetingTypeOpen} onClose={() => setAddMeetingTypeOpen(false)} {...props}/>
        <ActionMenuButton
            startIcon={<Add sx={{ width: "1.1rem" }} />}
            menu={NEW_MENU}
            onClickItem={handleClickItem}
        >
            New
        </ActionMenuButton>
      </>
    );
}

export default function SchedulerSchedulerSubpage() {
    const [availabilities, setAvailabilities] = useState<Array<AvailableMeetingTime>>([]);
    const [meetingTypes, setMeetingTypes] = useState<Array<MeetingType>>([]);

    const { data: meetingTypesData, isPending: meetingTypesIsPending } = useListMeetingTypesQuery();
    const { data: availabilityData, isPending: availabilityIsPending } = useListAvailableMeetingTimesQuery();

    useEffect(() => {
        if (meetingTypesIsPending || availabilityIsPending) return;

        if (meetingTypesData) {
            setMeetingTypes(meetingTypesData.meeting_types);
        }
        if (availabilityData) {
            setAvailabilities(availabilityData.available_meeting_times);
        }
    }, [meetingTypesData, availabilityData, meetingTypesIsPending, availabilityIsPending]);

    return (
        <MynkTab
            title="Scheduler"
            action={
            <Stack flexDirection={'row'}>
                <SchedulerActionMenuButton meetingTypes={meetingTypes} setMeetingTypes={setMeetingTypes} availabilities={availabilities} />
            </Stack>
        }>
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                {availabilities && (
                    <AvailabilityComponent availabilities={availabilities} setAvailabilities={setAvailabilities} />
                )}

                <Divider orientation='vertical' variant='middle' flexItem sx={{margin: '3rem', height: '47rem'}} />
                
                <GeneralSchedulerComponent meetingTypes={meetingTypes} setMeetingTypes={setMeetingTypes} availabilities={availabilities} />
            </Stack>
        </MynkTab>
    )
}