import React, {useState} from "react";
import {AddSaleDialog} from "../../../../dialogs/AddSale";
import {Button, Menu, MenuItem} from "@mui/material";
import {Add, ExpandMore} from "@mui/icons-material";

export function SalesNewButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [addSaleOpen, setAddSaleOpen] = useState(false);

  const handleAddSale = () => {
    setAddSaleOpen(true) ;
    setAnchorEl(null);
  };

  return (
    <>
      <AddSaleDialog open={addSaleOpen} onClose={() => setAddSaleOpen(false)} />

      <Menu
        id="sales-new-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={{
          ml: -4,
          '& ul > li': {
            paddingRight: 6,
          },
        }}
      >
        <MenuItem>Checkout</MenuItem>
        <MenuItem>New invoice</MenuItem>
        <MenuItem onClick={handleAddSale} disabled>Add a sale</MenuItem>
      </Menu>

      <Button
        variant="contained"
        startIcon={<Add />}
        endIcon={<ExpandMore />}
        sx={{
          background: 'linear-gradient(#fbadfd 0%, #ad4bfb 100%)',
          borderRadius: '1.7143rem',
          textTransform: 'capitalize',
          fontSize: "1.1429rem",
          boxShadow: '0',
          '& span': {
            margin: 0,
          }
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        New
      </Button>
    </>
  )
}



