import {MynkFormControlCommonProps} from "./types";
import React from "react";
import {Box, InputAdornment, MenuItem, Select, Stack, Typography} from "@mui/material";
import {SalesWindowSize} from "../../../api/types";
import {useFormikContext} from "formik";
import { ChipSelectOption } from "../../ChipSelect";
import chipSelectArrowIcon from "../../../assets/icons/chipselect-arrow-icon.svg";



export interface MynkFormChipSelectProps extends MynkFormControlCommonProps<string> {
  options: ChipSelectOption[];
  width?: string;
}

export function MynkFormChipSelect(props: MynkFormChipSelectProps) {
  const formik = useFormikContext();

  return (
    <Stack direction="row" alignItems="baseline" justifyContent="space-between" spacing={2}>
      <Typography variant="subtitle1">{props.label}</Typography>

      <Select
        variant="standard"
        {...(formik?.getFieldProps?.(props.name) ?? {name: props.name, value: props.default})}
        IconComponent={() => <Box component="img" src={chipSelectArrowIcon} sx={{ width: "0.7rem", height: "0.7rem", pr: "0.6rem" }} />}
        sx={{
          mb: -1,
          bgcolor: "white",
          height: "2.2rem",
          minWidth: props.width ? props.width : "7rem",
          boxShadow: "0px 0px 0.2143rem rgba(117, 179, 255, 0.50)",
          "& .MuiSelect-select": {
            backgroundColor: "transparent !important", // Prevent grey background
          },
          "&:focus": {
            backgroundColor: "transparent", // Clear background on focus
          },
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "14.2857rem",
              minWidth: "9rem",
            },
          },
        }}
      >
        {props.options.map(option => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </Stack>
  )
}