import React from 'react'
import { Stack, Typography } from '@mui/material'
import personImage from '../../../../assets/images/images.jpg'
import './Images.css'

export default function Images() {
  return (
    <>
        <Stack flexWrap={'wrap'} flexDirection={'row'} justifyContent={'space-around'} alignItems={'center'} gap={'1.5rem'}>
            <div className='flex-item'>
                <img src={personImage}></img>
            </div>
            <Stack className='flex-item' sx={{gap: '1.5rem'}}>
                <img src={personImage} style={{height: '16.75rem'}}></img>
                <img src={personImage} style={{height: '16.75rem'}}></img>
            </Stack>
            <div className='flex-item'>
                <img src={personImage}></img>
            </div>
            <Stack className='flex-item' sx={{gap: '1.5rem', width: '16.75rem', flex: 'none'}}>
                <img src={personImage} style={{width: '16.75rem', height: '16.75rem'}}></img>
                <img src={personImage} style={{width: '16.75rem', height: '16.75rem'}}></img>
            </Stack>
            <div className='flex-item' style={{flexGrow: 1}}>
                <img src={personImage}></img>
            </div>
            <div className='flex-item' style={{flexGrow: 1}}>
                <img src={personImage}></img>
            </div>
            <Stack className='flex-item' sx={{gap: '1.5rem'}}>
                <img src={personImage} style={{height: '16.75rem'}}></img>
                <img src={personImage} style={{height: '16.75rem'}}></img>
            </Stack>
            <div className='flex-item'>
                <img src={personImage}></img>
            </div>
            <div className='flex-item'>
                <img src={personImage}></img>
            </div>
        </Stack>
    </>
  )
}
