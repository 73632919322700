import axios from "axios";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  LoginWithGoogleParams,
  LoginWithGoogleResponse,
  RefreshGoogleTokenParams,
  RefreshGoogleTokenResponse,
} from "./types";


export function useLoginWithGoogleMutation(
  options: UseMutationOptions<LoginWithGoogleResponse, unknown, LoginWithGoogleParams>
) {
  return useMutation<LoginWithGoogleResponse, unknown, LoginWithGoogleParams>({
    mutationFn: async (params) => {
      return (await axios.post("/api/integrations/login-with-google", params)).data;
    },
    ...options,
  });
}

export function useRefreshGoogleTokenMutation(
  options: UseMutationOptions<RefreshGoogleTokenResponse, unknown, RefreshGoogleTokenParams>
) {
  return useMutation<RefreshGoogleTokenResponse, unknown, RefreshGoogleTokenParams>({
    mutationFn: async (params) => {
      return (await axios.post("/api/integrations/refresh-google-token", params)).data;
    },
    ...options,
  });
}