import { useParams } from "react-router-dom";
import { useState } from "react";
import { MynkTab } from "../../../../components/MynkPage";
import ViewGeneralLayout from "../../../../components/ViewProjectOrContact/ViewGeneralLayout";
import { LowerCard, UpperCard } from "../common/cards";
import { useGetProjectQuery } from "../../../../api/projects";
import { Card, Typography, IconButton, TableHead, TableRow, TableCell, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  DeleteTaskParams,
  PhotographyProject,
  Task,
} from "../../../../api/types";
import { atom } from "jotai";
import AddTaskDialog from "../../../../dialogs/AddTask";
import TaskView, {
  openedDeleteAtom,
  openedEditAtom,
  selectedTaskAtom,
} from "../../../../components/TaskView";
import ConfirmActionDialog from "../../../../components/ConfirmActionDialog";
import { useAtom } from "jotai";
import { useDeleteTaskMutation } from "../../../../api/tasks";
import { closeSnackbar, useSnackbar } from "notistack";
import { EditTaskDialog } from "../../../../dialogs/EditTask";
import { TwoActionButtonsViewProject } from "./General";

interface MainContentCardProps {
  project?: PhotographyProject;
}

function MainContentCard(props: MainContentCardProps) {
  const [newTaskOpen, setNewTaskOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useAtom(selectedTaskAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);
  const { enqueueSnackbar } = useSnackbar();

  const handleNewTask = () => {
    //setEditTask(null);
    setNewTaskOpen(true);
  };

  // Fetch delete task:
  const deleteTask = useDeleteTaskMutation();
  const handleDeleteTask = async (uuid: string) => {
    const params = {
      uuid: uuid,
    } as DeleteTaskParams;

    await deleteTask.mutateAsync(params);
    enqueueSnackbar("Task deleted successfully", {
      variant: "success",
      action: (key) => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
      style: {
        borderRadius: "0.7rem",
      },
    });

    setOpenedDelete(false);
  };

  return (
    <>
      <AddTaskDialog
        open={newTaskOpen}
        onClose={() => setNewTaskOpen(false)}
        project_uuid={props.project?.uuid ?? null}
      />
      {/* Confirm delete task pop-up window */}
      <ConfirmActionDialog
        open={openedDelete}
        what="task"
        whatAction="delete"
        onClose={() => setOpenedDelete(false)}
        onConfirm={() =>
          selectedTask ? handleDeleteTask(selectedTask.uuid) : undefined
        }
        loading={deleteTask.isPending}
      >
        Delete
      </ConfirmActionDialog>

      {/* Confirm edit task pop-up window */}
      <EditTaskDialog
        open={openedEdit}
        onClose={() => setOpenedEdit(false)}
        oldTask={selectedTask}
      />

      <Card sx={{ py: 2, height: "51.5rem", overflowY: "auto" }}>
        <Typography variant="h4" fontFamily="Helvetica Neue" sx={{ px: 3 }}>
          Tasks
        </Typography>

        <TaskView
          sx={{ mt: 0, pt: 0.4, m: 1 }}
          title=""
          project_uuid={props.project?.uuid}
          completed={false}
          onAdd={handleNewTask}
          showAll
          noHeader
        />

        <Typography
          variant="body2"
          sx={{ color: "#75b3ff", mt: 2, cursor: "pointer" }}
          align="center"
          onClick={handleNewTask}
        >
          + New task
        </Typography>
      </Card>
    </>
  );
}

export default function ViewPhotographyProjectTasksSubpage() {
  const { projectId } = useParams();
  const { data, isPending } = useGetProjectQuery(
    { project_uuid: projectId ?? "" },
    Boolean(projectId)
  );
  const project = data?.project as PhotographyProject;

  return (
    <MynkTab title="Tasks" action={<TwoActionButtonsViewProject project={project} />}>
      <ViewGeneralLayout
        mainContent={
          <MainContentCard project={data?.project as PhotographyProject} />
        }
        upperCard={
          data?.project && (
            <UpperCard project={data.project as PhotographyProject} />
          )
        }
        lowerCard={
          data?.project && (
            <LowerCard project={data.project as PhotographyProject} />
          )
        }
      />{" "}
    </MynkTab>
  );
}
