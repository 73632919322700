import React, { ReactNode, useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  MynkFormEditableContext,
  MynkFormEditableContextValue,
} from "./context";
import { MynkFormControlKind, MynkFormControlProps } from "./controlTypes";

import editIcon from "../../assets/icons/edit.svg";
import { useFormikContext } from "formik";
import { useGetClientQuery } from "../../api/clients";
import { useAtomValue } from "jotai";
import { userCurrencyAtom } from "../../layouts/Main";
import { formatMoney, zonedDate } from "../../util/common";

export interface MynkFormEditableProps {
  children?: ReactNode;
  formatMoney?: boolean;
  longText?: boolean;
  datePicker?: boolean;
  editing?: boolean;
  setEditing?: (editing: boolean) => void;
}

interface ControlValuePreviewProps {
  control: MynkFormControlProps;
  isOnEdit?: boolean;
  formatMoney?: boolean;
  longText?: boolean;
}

function ControlValuePreview(props: ControlValuePreviewProps) {
  const formik = useFormikContext();
  const control = props.control;

  const currencyCode = useAtomValue(userCurrencyAtom);
  const value = (formik?.values as any)?.[control.name] ?? "";

  const { data: clientData, isPending: clientLoading } = useGetClientQuery(
    { uuid: value },
    Boolean(value) && control.kind === MynkFormControlKind.CONTACT_SEARCH
  );

  const isPending = clientLoading;

  switch (control.kind) {
    case MynkFormControlKind.CONTACT_SEARCH:
      return isPending ? (
        <CircularProgress size={"1.1429rem"} />
      ) : (
        <Typography color="#75b3ff">
          {clientData?.client?.full_name ?? ""}
        </Typography>
      );
    case MynkFormControlKind.TEXT:
      if (props.longText) {
        return props.isOnEdit ? (
          <></>
        ) : (
          <Typography fontSize="1.05rem" color="#404040b2" sx={{ mt: "0.5rem" }}>{value.length < 155 ? value.toString() : `${value.toString().slice(0, 155)}...`}</Typography>
        );
      }
      return props.formatMoney
        ? formatMoney(value, currencyCode)
        : value.toString().length >= 110
        ? `${value.toString().slice(0, 110)}...`
        : value;
    case MynkFormControlKind.DATE_SELECT:
      return (
        value && (
          <Stack direction="row">
            {!props.control.onlyTime && !props.control.onlyDate &&
              <Typography fontSize={"1.03rem"} color="#404040" fontWeight="bold">
                {zonedDate(value).toFormat("EEEE")},
              </Typography>
            }
            <Typography
              fontSize={"1.03rem"}
              fontWeight="bold"
              color="#404040B2"
            >
              &nbsp;{props.control.withTime ? zonedDate(value).toFormat("LLL dd, yyyy HH:mm") : props.control.onlyTime ? zonedDate(value).toFormat("HH:mm") : zonedDate(value).toFormat("LLL dd, yyyy")}
            </Typography>
          </Stack>
        )
      );
    default:
      return <Typography>{value}</Typography>;
  }
}

export default function MynkFormEditable(props: MynkFormEditableProps) {
  const formik = useFormikContext();

  const [control, setControl] = useState<null | MynkFormControlProps>(null);

  const [internalEditing, setInternalEditing] = useState(false);

  const editing = props.editing ?? internalEditing;
  const setEditing = props.setEditing ?? setInternalEditing;

  const value = (formik?.values as any)?.[control?.name ?? ""];
  const hasValue = Boolean(value) || control?.kind !== MynkFormControlKind.DATE_SELECT;

  const popped = !(editing && hasValue);

  const contextValue = useMemo<MynkFormEditableContextValue>(
    () => ({
      control,
      setControl,
      popped,
    }),
    [setControl, popped]
  );

  return (
    <Box sx={{ position: "relative" }}>
      {control && (
        <Stack
          direction={props.longText ? "column" : "row"}
          alignItems={!props.longText ? "center" : undefined}
        >
          {props.longText ? (
            <Stack direction="row">
              <Typography variant="subtitle1" sx={{ mr: 2 }}>
                {control.label}
              </Typography>

              <Box sx={{ flex: 1 }} />

              <IconButton onClick={() => setEditing(!editing)}>
                <img
                  src={editIcon}
                  alt="Edit"
                  style={{
                    cursor: "pointer",
                    width: "1.1429rem",
                  }}
                />
              </IconButton>
            </Stack>
          ) : (
            <Typography variant="subtitle1" sx={{ mr: 2 }}>
              {control.label}
            </Typography>
          )}

          <div style={{ flex: 1 }} />

          {hasValue && (
            <ControlValuePreview
              control={control}
              isOnEdit={editing}
              formatMoney={props.formatMoney}
              longText={props.longText}
            />
          )}

          {/*<Typography>{popped ? 'Popped' : 'Un-popped'}</Typography>*/}

          {!props.longText && hasValue && (
            <IconButton onClick={() => setEditing(!editing)}>
              <img
                src={editIcon}
                alt="Edit"
                style={{
                  cursor: "pointer",
                  width: "1.1429rem",
                }}
              />
            </IconButton>
          )}
        </Stack>
      )}

      <div
        style={{
          visibility: editing || !hasValue ? "visible" : "hidden",
          // mt: editing || !hasValue ? "0.5rem" : "0",
          height: editing || !hasValue ? "auto" : "0",
          ...(control?.kind === MynkFormControlKind.DATE_SELECT && !hasValue
            ? { position: "absolute", top: 0, right: 0 }
            : {}),
        }}
      >
        <MynkFormEditableContext.Provider value={contextValue}>
          {props.children}
        </MynkFormEditableContext.Provider>
      </div>
    </Box>
  );
}
