import { Box, Grid, Stack, Typography } from "@mui/material";
import { ReactNode, useMemo, useState } from "react";

import wavingHand from "../../../assets/icons/waving-hand-sign.svg";
import "./style.css";

import smallLeftArrowGray from "../../../assets/icons/small-left-arrow-gray.svg";
import fourSquares from "../../../assets/icons/four-squares.svg";
import chatBubbleIcon from "../../../assets/icons/chat-bubble-icon.svg";
import smallLeftArrowBlack from "../../../assets/icons/small-left-arrow-black.svg";
import { Link } from "react-router-dom";
import { useListGeneralSettingsQuery } from "../../../api/settings";
import ChatbotDialog from "../../../components/ChatbotDialog";
import {DateTime} from "luxon";
import ImageCarousel from "../../../components/ImageCarousel";
import { languageAtom, LanguageCode } from "../../../components/LanguagePicker";
import { useAtomValue } from "jotai";

import img1 from "../../../../public/welcomepanel_imgs/1.png";
import img2 from "../../../../public/welcomepanel_imgs/2.png";
import img3 from "../../../../public/welcomepanel_imgs/3.jpg";
import img4 from "../../../../public/welcomepanel_imgs/4.jpg";
import img5 from "../../../../public/welcomepanel_imgs/5.jpg";
import img6 from "../../../../public/welcomepanel_imgs/6.jpg";
import img7 from "../../../../public/welcomepanel_imgs/7.jpg";
import img8 from "../../../../public/welcomepanel_imgs/8.jpg";
import img9 from "../../../../public/welcomepanel_imgs/9.jpg";
import img10 from "../../../../public/welcomepanel_imgs/10.jpg";
import img11 from "../../../../public/welcomepanel_imgs/11.jpg";
import img12 from "../../../../public/welcomepanel_imgs/12.jpg";
import img13 from "../../../../public/welcomepanel_imgs/13.jpg";
import img14 from "../../../../public/welcomepanel_imgs/14.jpg";
import img15 from "../../../../public/welcomepanel_imgs/15.jpg";
import img16 from "../../../../public/welcomepanel_imgs/16.jpg";
import img17 from "../../../../public/welcomepanel_imgs/17.jpg";
import img18 from "../../../../public/welcomepanel_imgs/18.jpg";
import img19 from "../../../../public/welcomepanel_imgs/19.jpg";
import img20 from "../../../../public/welcomepanel_imgs/20.jpg";
import img21 from "../../../../public/welcomepanel_imgs/21.jpg";
import img22 from "../../../../public/welcomepanel_imgs/22.jpg";


interface WelcomePanelProps {
  scrollingImg: string;
}

export function WelcomePanel(props: WelcomePanelProps) {
  const today = useMemo(() => DateTime.now(), []);
  const fullName = useListGeneralSettingsQuery({}).data?.user_name;
  const language = useAtomValue(languageAtom); // Get the current language

  const photoItems = [
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22,
  ];

  // Localized strings
  const localizedStrings = {
    [LanguageCode.English]: {
      dayFormat: "EEEE",
      dateFormat: "LLL dd, yyyy",
      welcomeBack: "Welcome back",
      letsGetStarted: "Let's get started",
    },
    [LanguageCode.Hebrew]: {
      dayFormat: "EEEE",
      dateFormat: "dd LLL, yyyy", // Adjusted date format for Hebrew
      welcomeBack: "ברוכים השבים",
      letsGetStarted: "בוא נתחיל",
    },
  };

  const strings = localizedStrings[language] || localizedStrings[LanguageCode.English];
  const isHebrew = language === LanguageCode.Hebrew;

  // Format the date for Hebrew or English
  const formattedDay = isHebrew
    ? today.setLocale("he").toFormat(strings.dayFormat)
    : today.toFormat(strings.dayFormat);

  const formattedDate = isHebrew
    ? today.setLocale("he").toFormat(strings.dateFormat)
    : today.toFormat(strings.dateFormat);

  return (
    <Box
      sx={{
        height: "45.2rem",
        bgcolor: "black",
        position: "relative",
        boxShadow: "0 20px 55px #75b3ff33",
        overflow: "hidden",
      }}
    >
      <ImageCarousel items={photoItems} height={45.17} secondsPerImg={12} />
      {/* Gradient white welcome box on top of the image */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          width: "71.4286rem",
          [isHebrew ? "right" : "left"]: 0, // Adjust position based on language
          background:
            "linear-gradient(to right, #ffffff 0%, #ffffff 60%, #ffffff00 100%)",
          transform: isHebrew ? "scaleX(-1)" : "none", // Flip gradient for Hebrew
        }}
      />

      {/* Content on white box - Welcome, Date, etc */}
      <Box
        sx={{
          position: "relative",
          padding: 18,
          [isHebrew ? "pr" : "pl"]: 11, // Adjust padding for text box
          boxSizing: "border-box",
          direction: isHebrew ? "rtl" : "ltr", // Set text direction
        }}
      >
        {/* Date */}
        <Stack direction="row" justifyContent="flex-start">
          <Typography
            fontFamily="Helvetica Neue"
            fontSize="1.9rem"
            fontWeight="bold"
            color="#404040"
          >
            {formattedDay},
          </Typography>
          <Typography
            fontFamily="Helvetica Neue"
            fontSize="1.9rem"
            color="#828282"
          >
            &nbsp;{formattedDate}
          </Typography>
        </Stack>

        {/* Welcome back + User message */}
        <Typography
          fontSize="5.4rem"
          fontFamily="Helvetica Neue"
          fontWeight="bold"
          sx={{ mt: 6.2, lineHeight: 1.1 }}
        >
          {strings.welcomeBack}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Typography
            fontSize="3.8rem"
            fontWeight="bold"
            fontFamily="Helvetica Neue"
          >
            {fullName?.split(" ")[0]}
          </Typography>
          <img
            src={wavingHand}
            style={{
              width: "4rem",
            }}
          />
        </Stack>

        <Typography fontSize="2rem" sx={{ mt: 3.8 }}>
          {strings.letsGetStarted}
        </Typography>
      </Box>
    </Box>
  );
}

export function NeedHelp() {
  const [openChat, setOpenChat] = useState(false);
  const language = useAtomValue(languageAtom); // Get the current language

  // Localized strings
  const localizedStrings = {
    en: {
      needHelp: "Need help?",
      talkTo: "Talk to one of mynk members",
    },
    he: {
      needHelp: "צריך עזרה?",
      talkTo: "דבר עם איש צוות",
    },
  };

  const strings = localizedStrings[language] || localizedStrings.en;
  const isHebrew = language === LanguageCode.Hebrew;

  return (
    <>
      <ChatbotDialog open={openChat} onClose={() => setOpenChat(false)} />

      <Stack
        sx={{
          mt: 1,
          ml: isHebrew ? 0 : -2,
          mr: isHebrew ? -2 : 0,
          width: "95%",
          height: "6rem",
          bgcolor: "white",
          borderRadius: 2,
          boxShadow: "0 0.2143rem 0.5rem #ceddf0",
          alignItems: "center",
          cursor: "pointer"
        }}
        direction={'row'}
        justifyContent={'space-between'}
        onClick={() => setOpenChat(true)}
      >
        <Stack ml={isHebrew ? 0 : 2} mr={isHebrew ? 2 : 0}>
          <Stack direction={'row'}>
            <Box component="img" src={chatBubbleIcon} width={"1.2rem"} ml={isHebrew ? 2 : 0} mr={isHebrew ? 0 : 2} />
            <Typography
              fontSize="1.35rem"
            >
              <b>{strings.needHelp}</b> <br />
            </Typography>
          </Stack>
          <Typography sx={{ fontSize: "1.2rem" }} mt={1} ml={isHebrew ? 0 : 4} mr={isHebrew ? 4 : 0}>
            {strings.talkTo}&#160;
          </Typography>
        </Stack>
        <Box
          component="img"
          src={smallLeftArrowGray}
          width={"0.77rem"}
          sx={{
            transform: isHebrew ? "rotate(180deg)" : "none", // Flip arrow for Hebrew
            mr: isHebrew ? 0 : 2,
            ml: isHebrew ? 2 : 0,
          }}
        />
      </Stack>
    </>
  );
}

export function ExploreButton(props: { text: string; link: string }) {
  const language = useAtomValue(languageAtom); // Get the current language
  const isHebrew = language === LanguageCode.Hebrew;

  return (
    <a href={props.link} className="explore-button">
      <Box>
        <Stack direction="row">
          <Box component="img" src={fourSquares} width={"1.25rem"} />
          <Typography
            variant="h6"
            sx={{ ml: isHebrew ? 0 : 1.2, mr: isHebrew ? 1.2 : 0, color: "black", fontSize: "1.27rem" }}
          >
            {props.text}
          </Typography>
          <Box sx={{ flex: 1 }} />
          <Box
            component="img"
            src={smallLeftArrowBlack}
            width={"0.75rem"}
            sx={{
              transform: isHebrew ? "rotate(180deg)" : "none",
            }} />
        </Stack>
      </Box>
    </a>
  );
}

interface MoreToExploreProps {
  children?: ReactNode;
  link?: string;
}
export function MoreToExploreButton(props: MoreToExploreProps) {
  const language = useAtomValue(languageAtom); // Get the current language
  const isHebrew = language === LanguageCode.Hebrew;

  return (
    <Link
      to={props.link || ""}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <Box
        sx={{
          borderRadius: "1.3rem",
          border: "solid 1px white",
          cursor: "pointer",
          background: "linear-gradient(#FFFFFF, #FFFFFF25)",
          "&:hover": {
            border: "solid 1px #bfddff",
          },
        }}
      >
        <Stack
          direction="row"
          padding={2.7}
          sx={{
            position: "relative",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"1.45rem"} sx={{ ml: 0.5 }}>
            {props?.children}
          </Typography>
          <Box sx={{ flex: 1 }} />
          <Box
            component="img"
            src={smallLeftArrowBlack}
            width={"0.8rem"}
            sx={{
              transform: isHebrew ? "rotate(180deg)" : "none", // Rotate arrow for Hebrew
            }}
          />
        </Stack>
      </Box>
    </Link>
  );
}
