import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  BATHROOM_OPTIONS,
  BEDROOM_OPTIONS,
  ENTRANCE_DATE_OPTIONS,
  LISTING_TYPE_OPTIONS,
  SIZE_UNITS_OPTIONS,
} from "../components/ChipSelect/commonOptions";
import { useNavigate } from "react-router-dom";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import { closeSnackbar, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

import propertyAreaIcon from "../assets/icons/property-area-icon.svg";
import bedroomIcon from "../assets/icons/bedroom-icon.svg";
import bathroomIcon from "../assets/icons/bathroom-icon.svg";
import {
  useEditProjectMutation,
} from "../api/projects";
import {
  EditProjectParams,
  EntranceDateTypeOptions,
  FileObj,
  RealEstateListing,
  WorkflowType,
} from "../api/types";
import MynkFormEditable from "../components/MynkForm/MynkFormEditable";
import { useEffect, useMemo, useState } from "react";
import { convertBlobToBase64, fetchAllImagesByUuids, zonedDate } from "../util/common";
import MynkFormConditional from "../components/MynkForm/MynkFormConditional";
import { DateTime } from "luxon";

export interface EditListingDialogProps {
  open: boolean;
  onClose: () => void;
  listing?: RealEstateListing;
}

export function EditListingDialog(props: EditListingDialogProps) {
  const editListing = useEditProjectMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    if (!props.listing?.uuid) return;

    const params: EditProjectParams = {
      uuid: props.listing.uuid,
      fields: {
        type: values["type"],
        location: values["location"],
        price: Number(values["price"]),
        size: Number(values["size"]),
        size_units: values["sizeUnits"],
        bedrooms: Number(values["bedrooms"]),
        bathrooms: Number(values["bathrooms"]),
        description: values["description"],
        entrance_date:
          values["entranceDate"] == EntranceDateTypeOptions.READY_TO_MOVE
            ? EntranceDateTypeOptions.READY_TO_MOVE
            : values["entranceSpecificDate"],
        owner_uuid: values["ownerId"],
        name:
          values["listingName"] == ""
            ? values["location"]
            : values["listingName"],
        photos: values["photos"] == "" ? [] : values["photos"],
      },
    };

    await editListing.mutateAsync(params);
    enqueueSnackbar("Listing edited successfully", {
      variant: "success",
      action: (key) => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
      style: {
        borderRadius: "0.7rem",
      },
    });
    props.onClose();
  };

  const handleFilesChange = (files: FileObj[] | null) => {
    // Handle the selected files (e.g., upload to server)
    if (files) {
      for (let i = 0; i < files.length; i++) {
        console.log(`Selected File ${i + 1}:`, files[i]);
      }
    }
  };

  const [initialFiles, setInitialFiles] = useState<FileObj[]>([]);
  const [fileFetched, setFileFetched] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!props.listing) return;

      const filesData = await fetchAllImagesByUuids(
        props.listing?.files_uuids ?? []
      );
      const fileObjListPromises = filesData.map(async (file) => {
        const base64Data = await convertBlobToBase64(file);
        return {
          name: "nothing",
          data: base64Data,
        };
      });

      const fileObjList = await Promise.all(fileObjListPromises);
      setInitialFiles(fileObjList);
      setFileFetched(true);
    };

    fetchData();
  }, [props.listing]);

  console.log(initialFiles);
  const initialValues = useMemo(() => {
    if (!props.listing) return {};
    console.log("edit")
    console.log(new Date(props.listing.entrance_date));
    
    return {
      type: props.listing.type,
      location: props.listing.location,
      price: props.listing.price,
      size: props.listing.size,
      sizeUnits: props.listing.size_units,
      bedrooms: props.listing.bedrooms,
      bathrooms: props.listing.bathrooms,
      description: props.listing.description,
      entranceDate:
        props.listing.entrance_date == EntranceDateTypeOptions.READY_TO_MOVE
          ? EntranceDateTypeOptions.READY_TO_MOVE
          : EntranceDateTypeOptions.SPECIFIC_DATE,
      entranceSpecificDate:
        props.listing.entrance_date == EntranceDateTypeOptions.READY_TO_MOVE
          ? new Date()
          : new Date(props.listing.entrance_date),
      ownerId: props.listing.client_uuid,
      listingName: props.listing.name,
      photos: initialFiles,
    };
  }, [props.listing, initialFiles]);

  if (!props.listing) {
    return <></>;
  }

  if (!fileFetched) {
    return <></>;
  }

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      title="Edit listing"
      action="Apply changes"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      loading={editListing.isPending}
    >
      <Stack direction="row">
        <Grid container spacing={1} sx={{ width: "30.4286rem" }}>
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.CHIP_SELECT}
              name="type"
              label="Type"
              default="for_rent"
              options={LISTING_TYPE_OPTIONS}
            />
          </Grid>

          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name="location"
                label="Property's location"
                placeholder="Enter property's location"
                autoComplete={false}
                default=""
              />
            </MynkFormEditable>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name="price"
                label="Price "
                placeholder="Enter price"
                autoComplete={false}
                isNum={true}
                default={0}
              />
            </MynkFormEditable>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name="size"
                label="Size"
                placeholder="Enter size"
                autoComplete={false}
                isNum={true}
                default={0}
              />
            </MynkFormEditable>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <MynkFormControl
                kind={MynkFormControlKind.ICONED_CHIP_SELECT}
                name="sizeUnits"
                label=""
                default="sqft"
                options={SIZE_UNITS_OPTIONS}
                icon={propertyAreaIcon}
              />
              <MynkFormControl
                kind={MynkFormControlKind.ICONED_CHIP_SELECT}
                name="bedrooms"
                label="Beds"
                default="1"
                options={BEDROOM_OPTIONS}
                icon={bedroomIcon}
              />
              <MynkFormControl
                kind={MynkFormControlKind.ICONED_CHIP_SELECT}
                name="bathrooms"
                label="Baths"
                default="1"
                options={BATHROOM_OPTIONS}
                icon={bathroomIcon}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name="description"
                label="Property's description"
                placeholder="Enter property's description"
                autoComplete={false}
                multiLine={true}
                height={150}
                default=""
              />
            </MynkFormEditable>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name="listingName"
                label="Listing's name (Optional)"
                placeholder="Enter property's name"
                autoComplete={false}
                default={""}
              />
            </MynkFormEditable>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Divider orientation="vertical" sx={{ ml: 3, mr: 3 }} flexItem />

        <Grid container spacing={1} sx={{ width: "26.4286rem" }}>
          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.CONTACT_SEARCH}
                name="ownerId"
                label="Owner (Only visible to you)"
                placeholder="Enter owners's name"
                default={props.listing?.client_uuid ?? ""}
                workflowType={WorkflowType.REAL_ESTATE}
              />
            </MynkFormEditable>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1}>
              <MynkFormControl
                kind={MynkFormControlKind.CHIP_SELECT}
                name="entranceDate"
                label="Entrance date"
                default={EntranceDateTypeOptions.READY_TO_MOVE}
                options={ENTRANCE_DATE_OPTIONS}
              />
              <MynkFormConditional
                ifWhat={"entranceDate"}
                equalTo={EntranceDateTypeOptions.SPECIFIC_DATE}
              >
                <MynkFormEditable>
                  <MynkFormControl
                    kind={MynkFormControlKind.DATE_SELECT}
                    name="entranceSpecificDate"
                    label=""
                    default={zonedDate(props.listing.entrance_date) ?? DateTime.now()}
                  />
                </MynkFormEditable>
              </MynkFormConditional>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.FILE_UPLOAD}
              name="photos"
              label="Change photos"
              default={[]}
              onFilesChange={handleFilesChange}
            />
          </Grid>
        </Grid>
      </Stack>
    </MynkFormDialog>
  );
}
