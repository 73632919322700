import {Box, IconButton, Stack, TextField, Typography, InputAdornment} from "@mui/material";
import {useState} from "react";
import { Expense } from "../api/types";
import { EXPENSES_CATEGORIES, PAYMENT_METHODS } from "../components/ChipSelect/commonOptions";
import CheckRadioButton from '../assets/icons/check-radio-button.svg'
import UncheckRadioButton from '../assets/icons/uncheck-radio-button.svg'
import RightArrow from '../assets/icons/arrow-right-show-more.svg'
import DownArrow from '../assets/icons/arrow-down-show-more-opened.svg'
import { useAddExpenseMutation } from "../api/expenses";
import { AddMeetingTypeParams } from "../api/types";
import { AxiosError } from "axios";
import { format } from 'date-fns';
import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import {
  WorkflowType,
} from "../api/types";
import { formatMoney } from "../util/common";
import { useAtomValue } from "jotai";
import { userCurrencyAtom } from "../layouts/Main";


export interface AddExpenseDialogProps {
  open: boolean;
  onClose: () => void;
  contactId?: string;
  projectId?: string;
}

export function AddExpenseDialog(props: AddExpenseDialogProps) {
  const [linkClient, setLinkClient] = useState(false)
  const [linkProject, setLinkProject] = useState(false)
  const [description, setDescription] = useState<string>('')
  const [amount, setAmount] = useState<string>('')
  const userCurrency = useAtomValue(userCurrencyAtom);

  const [errorMsg, setErrorMsg] = useState<string>("");

  const addExpense = useAddExpenseMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, AddMeetingTypeParams>;
        const detail = (axiosError.response?.data as { detail?: string })
        ?.detail;
        setErrorMsg(detail ?? "");
      }
    }
  })

  const handleSubmit = async (values: any) => {
    if (!amount || !description) {
      alert("Please fill in all the fields");
      return;
    }

    let formattedDate;
    if (values.date instanceof Date) {
      const date = new Date(values.date.ts);
      formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    } else {
      const date = new Date(values.date);
      formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    }

    const newExpense: Expense = {
      amount: parseFloat(amount),
      description: description,
      date: formattedDate,
      category: values.category ?? 'none',
      payment_method: values.payment_method ?? 'none',
      client: props.contactId ? props.contactId : linkClient ? values.clientId : undefined,
      project: props.projectId ? props.projectId : linkProject ? values.projectId : undefined,
    }

    addExpense.mutate(newExpense);
    props.onClose();
  };

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      actionDisabled={false}
      onSubmit={handleSubmit}
      loading={addExpense.isPending}
      action='Set expense'
      elevatorSx={{
        px: "3rem",
        py: "2rem",
      }}
    >
      <Stack flexDirection={'row'} height={'fit-content'}>
        <Stack sx={{width: '30rem'}}>
          <h1 style={{fontSize: '2rem', marginBottom: '2.5rem'}}>New Expense</h1>

          <Stack>
            <Stack direction='row' justifyContent={'space-between'} mb={2}>
              <Typography>Description</Typography>
              <Typography>Amount</Typography>
            </Stack>
            <Stack direction='row'>
              <TextField
                fullWidth
                variant="standard"
                placeholder='Type description here..'
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: '3.6rem',
                    mt: '-1rem',
                  },
                }}
                onChange={(e) => {
                  setDescription(e.target.value)
                }}
              />

              <TextField
                fullWidth
                variant="standard"
                placeholder='0.00'
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: '3.6rem',
                    mt: '-1rem',
                    mr: '-3rem',
                    width: '7rem',
                    alignSelf: 'flex-end',
                    justifySelf: 'flex-end',
                  },
                  startAdornment:
                    <InputAdornment position="start">
                      {formatMoney(undefined, userCurrency)}
                    </InputAdornment>
                }}
                onChange={(e) => {
                  setAmount(e.target.value)
                }}
              />
            </Stack>
          </Stack>
          

          <Box mt={2.5}>
            <MynkFormControl
              kind={MynkFormControlKind.DATE_SELECT}
              name="date"
              label="Date"
              default={format(new Date(), 'MM-dd-yyyy')}
            />
          </Box>

          <Stack>
            <Box mt={1}>
              <MynkFormControl
                kind={MynkFormControlKind.CHIP_SELECT}
                name="category"
                label="Category"
                default='none'
                width="12rem"
                options={EXPENSES_CATEGORIES}
              />
            </Box>

            <Box mt={1}>
              <MynkFormControl
                kind={MynkFormControlKind.CHIP_SELECT}
                name="payment_method"
                label="Payment method"
                default='none'
                options={PAYMENT_METHODS}
              />
            </Box>
            
            <Stack flexDirection={'row'} sx={{marginTop: '1rem'}}>
              <IconButton
                sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
                onClick={() => {
                  setLinkClient(prevState => !prevState)
                }}
              >
                <Box
                  src={linkClient ? CheckRadioButton : UncheckRadioButton}
                  component='img'
                  sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
                />
              </IconButton>
              <Typography style={{fontSize: '1.2rem', marginLeft: '0.5rem'}}>Link client (Optional)</Typography>
            </Stack>
            
            {linkClient && (
                <Box sx={{marginTop: '1rem', marginLeft: '1rem'}}>
                  <MynkFormControl
                    kind={MynkFormControlKind.CONTACT_SEARCH}
                    name="clientId"
                    label="Link client"
                    placeholder="Enter client"
                    default=""
                    workflowType={WorkflowType.PHOTOGRAPHY}
                  />
                </Box>
            )}
            
            <Stack flexDirection={'row'} sx={{marginTop: '1rem'}}>
              <IconButton
                sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
                onClick={() => {
                  setLinkProject(prevState => !prevState)
                }}
              >
                <Box
                  src={linkProject ? CheckRadioButton : UncheckRadioButton}
                  component='img'
                  sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
                />
              </IconButton>
              <Typography style={{fontSize: '1.2rem', marginLeft: '0.5rem'}}>Link project (Optional)</Typography>
            </Stack>

            {linkProject && (
              <Box sx={{marginTop: '1rem', marginLeft: '1rem'}}>
                <MynkFormControl
                  kind={MynkFormControlKind.PROJECT_SEARCH}
                  name="projectId"
                  label="Link project"
                  placeholder="Enter project"
                  default=""
                  workflowType={WorkflowType.PHOTOGRAPHY}
                />
              </Box>
            )}
          </Stack>
        </Stack>
      </Stack>
    </MynkFormDialog>
  )
}