import ElevatorDialog from "../components/ElevatorDialog";
import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Stack, Typography} from "@mui/material";
import CheckoutPage from "../pages/Signup/Checkout";
import {useLogout} from "../util/hooks";


export function TrialExpiredDialog() {
  const { logout, isLoading: isLoggingOut } = useLogout();

  const [waiting, setWaiting] = useState(true);
  const [checkoutReady, setCheckoutReady] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setWaiting(false);

      setTimeout(() => {
        setCheckoutReady(true);
      }, 5000);
    }, 3000);
  }, []);

  return (
    <ElevatorDialog
      open
    >
      {waiting && (
        <CircularProgress sx={{ display: 'table', mx: 'auto', my: 2 }} />
      )}

      {!waiting && (
        <>
          {checkoutReady && (
            <Stack direction="row" alignItems="baseline" justifyContent="flex-end" sx={{ minWidth: '20rem' }}>
              <Button
                onClick={logout}
                disabled={isLoggingOut}
              >
                {isLoggingOut ? <CircularProgress size="1rem" /> : "Sign out"}
              </Button>
            </Stack>
          )}

          <CheckoutPage isInDialog={true} onReady={() => setCheckoutReady(true)} />
        </>
      )}
    </ElevatorDialog>
  );
}
