import { Box, Stack, SxProps, Tab, Tabs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import React from 'react';


export interface TabDescriptor {
  title: string;
}


export interface MynkPageHeaderProps {
  title: string;
  selectedTabIndex: number;
  tabs: TabDescriptor[];
  showTabs?: boolean;
  backLink?: string;
  sx?: SxProps;
  onTabChange?: (tab: number) => void;
}


export default function MynkPageHeader(props: MynkPageHeaderProps) {
  return (
    <Box sx={{...props.sx}}>
      <Box>
        <Typography fontFamily="Helvetica Neue" variant="h1" fontWeight={500}>
          {props.title}
        </Typography>

        {props.backLink && (
          <Typography
          sx={{
            textDecoration: "underline",
            color: "#75b3ff",
            cursor: "pointer",
          }}            component={Link}
            to={props.backLink}
            fontSize="1.44em"
          >
              <ChevronLeft sx={{ width: "1.1rem", height: "1.1rem", ml: -1, mt: 2.2 }} />
            Back
          </Typography>
        )}
      </Box>

      {props.selectedTabIndex >= 0 && (props.showTabs !== false) && (
        <Tabs value={props.selectedTabIndex} sx={{ mb: 2, mt: props.backLink ? 1 : 0 }} onChange={(_, v) => props.onTabChange?.(v)}>
          {props.tabs.map(tab => (
            <Tab
              key={tab.title}
              label={tab.title}
              sx={{
                position: 'relative',
                fontFamily: "Arial",
                fontSize: "1.2rem",

                minWidth: '0px',
                marginRight: '2rem',
                overflow: 'visible',
                px: '0.5rem',

                borderBottom: 'solid 0.1429rem #cde6ff',

                '&:not(:last-child)::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -2,
                  left: 0,
                  height: '2px',
                  background: '#cde6ff',
                  width: 'calc(100% + 2rem)',
                }
              }}
            />          ))}
        </Tabs>
      )}
    </Box>
  )
}