import {Box, Typography} from "@mui/material";


export default function CheckoutFailPage() {
  return (
    <Box>
      <Typography>
        Oh no.
      </Typography>
    </Box>
  );
}
