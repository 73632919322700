import { Divider, Grid, Stack } from "@mui/material";
import {
  useAddPhotographyClientMutation,
  useAddRealEstateClientMutation,
} from "../api/clients";
import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import {
  ClientSource,
  ListingType,
  Priority,
  RealEstateClientLookingTo,
  RealEstateClientType,
} from "../api/types";
import {
  CONTACT_SOURCE_OPTIONS,
  LISTING_TYPE_OPTIONS,
  PRIORITY_OPTIONS,
  REAL_ESTATE_CONTACT_TYPE_OPTIONS,
  REAL_ESTATE_LOOKING_TO_OPTIONS,
} from "../components/ChipSelect/commonOptions";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import { useNavigate } from "react-router-dom";
import { PATHS, WorkflowPath, makePath } from "../paths";
import { currentWorkflowAtom } from "../components/MainAppBar/UserControlPopup";
import { useAtomValue } from "jotai";
import MynkFormConditional from "../components/MynkForm/MynkFormConditional";

export interface AddRealEstateContactDialogProps {
  open: boolean;
  onClose: () => void;
}

export function AddRealEstateContactDialog(
  props: AddRealEstateContactDialogProps
) {
  const addClient = useAddRealEstateClientMutation();

  const navigate = useNavigate();
  const handleSubmit = async (values: any) => {
    try {
      await addClient.mutateAsync({
        type: values["contactType"],
        looking_to: values["lookingTo"],
        full_name: values["contactName"].trim(),
        email: values["contactEmail"].trim(),
        phone: values["contactMobile"].trim(),
        source: values["contactSource"],
        priority: values["contactPriority"],
        preferences: values["preferences"],
        budget: values["budget"],
      });

      props.onClose();
      navigate(
        makePath(
          `/${useAtomValue(currentWorkflowAtom)?.type}` as WorkflowPath,
          PATHS.clients.contacts
        )
      );
    } catch {}
  };

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      title="New contact"
      action="Add contact"
      onSubmit={handleSubmit}
      loading={addClient.isPending}
    >
      <Stack direction="row" sx={{ width: "57.1429rem" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.CHIP_SELECT}
              name="contactType"
              label="Type"
              default={RealEstateClientType.OWNER}
              options={REAL_ESTATE_CONTACT_TYPE_OPTIONS}
            />
          </Grid>

          <MynkFormConditional
            ifWhat={"contactType"}
            equalTo={RealEstateClientType.PROSPECT}
          >
            <Grid item xs={12}>
              <MynkFormControl
                kind={MynkFormControlKind.CHIP_SELECT}
                name="lookingTo"
                label="Looking to"
                default={RealEstateClientLookingTo.BUY}
                options={REAL_ESTATE_LOOKING_TO_OPTIONS}
              />
            </Grid>
          </MynkFormConditional>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="contactName"
              label="Name"
              placeholder="Enter name"
              autoComplete={false}
              default=""
            />
          </Grid>

          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="contactEmail"
              label="Email"
              placeholder="Enter email address"
              autoComplete={false}
              default=""
            />
          </Grid>

          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="contactMobile"
              label="Phone number"
              placeholder="Enter phone number"
              autoComplete={false}
              default=""
            />
          </Grid>
        </Grid>
        <Divider orientation="vertical" sx={{ ml: 5, mr: 5 }} flexItem />
        <Grid container spacing={2} alignItems="center" alignContent="start">
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="preferences"
              label="Preferences"
              placeholder="Enter prospect's preferences"
              autoComplete={false}
              multiLine={true}
              height={150}
              default=""
            />
          </Grid>

          <MynkFormConditional
            ifWhat={"contactType"}
            equalTo={RealEstateClientType.PROSPECT}
          >
            <MynkFormConditional
              ifWhat={"lookingTo"}
              equalTo={RealEstateClientLookingTo.RENT}
            >
              <Grid item xs={12}>
                <MynkFormControl
                  kind={MynkFormControlKind.TEXT}
                  name="budget"
                  label="Budget "
                  placeholder="0.00 / Month"
                  autoComplete={false}
                  isNum={true}
                  default={0}
                />
              </Grid>
            </MynkFormConditional>

            <MynkFormConditional
              ifWhat={"lookingTo"}
              equalTo={RealEstateClientLookingTo.BUY}
            >
              <Grid item xs={12}>
                <MynkFormControl
                  kind={MynkFormControlKind.TEXT}
                  name="budget"
                  label="Budget "
                  placeholder="0.00"
                  autoComplete={false}
                  isNum={true}
                  default={0}
                />
              </Grid>
            </MynkFormConditional>
          </MynkFormConditional>
          <Grid item xs={6}>
            <MynkFormControl
              kind={MynkFormControlKind.CHIP_SELECT}
              name="contactSource"
              label="Source"
              default={ClientSource.OTHER}
              options={CONTACT_SOURCE_OPTIONS}
            />
          </Grid>

          <Grid item xs={6}>
            <MynkFormControl
              kind={MynkFormControlKind.CHIP_SELECT}
              name="contactPriority"
              label="Priority"
              default={Priority.LOW}
              options={PRIORITY_OPTIONS}
            />
          </Grid>
        </Grid>
      </Stack>
    </MynkFormDialog>
  );
}
