import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { StackTableCard } from "../../../../components/StackTable/StackTableCard";
import {
  LoggedInHistoryIconType,
  SimpleTableColumn,
  SimpleTableColumnType,
} from "../../../../components/SimpleTable";
import { MynkSelectBox } from "../../../../components/MynkSelectBox";
import countryList from "react-select-country-list";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import timezones from "timezones-list";
import currencyData from "../../../../assets/currencies.json";
import { GENERAL_SETTING_NAMES } from "./GeneralSettingsTableCard";
import { closeSnackbar, useSnackbar } from "notistack";
import {
  AskDeleteAccountParams,
  AskForgotPasswordParams,
  EditGeneralSettingsParams,
  FileObj,
} from "../../../../api/types";
import {
  useEditGeneralSettingsMutation,
  useGetDevicesHistoryQuery,
  useUploadBusinessLogoMutation,
  useUploadProfilePhotoMutation,
} from "../../../../api/settings";
import CloseIcon from "@mui/icons-material/Close";
import { PhotoType, UploadOnePhoto } from "./UploadOnePhoto";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useAskDeleteAccountMutation } from "../../../../api/auth";
import {useGotoPortalMutation} from "../../../../api/billing";
import ChangePasswordDialog from "../../../../dialogs/ChangePasswordDialog";

const LOGGED_IN_HISTORY_COLUMNS: SimpleTableColumn[] = [
  {
    key: "picture",
    label: "Picture",
    type: SimpleTableColumnType.PICTURE,
    hideHeader: true,
  },
  {
    key: "history_info",
    label: "History Info",
    type: SimpleTableColumnType.HISTORY_INFO,
    hideHeader: true,
  },
];

interface LocationAndCurrencyProps {
  data: Array<Record<string, any>>;
  setData: (newData: Record<string, any>[]) => void;
}

function LocationAndCurrency(props: LocationAndCurrencyProps) {
  const [currencyStrings, setCurrencyStrings] = useState<Array<string>>([]);
  const [save, setSave] = useState(false);
  const editGeneralSettings = useEditGeneralSettingsMutation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const handleSubmit = async () => {
      // Extract parameters (find in the list of dicts):
      const params: EditGeneralSettingsParams = {
        user_name: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.NAME
        )?.setting_val,
        phone_number: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.PHONE
        )?.setting_val,
        business_name: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.B_NAME
        )?.setting_val,
        business_id: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.B_ID
        )?.setting_val,
        business_address: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.B_ADDRESS
        )?.setting_val,
        business_phone: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.B_PHONE
        )?.setting_val,
        country: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.COUNTRY
        )?.setting_val,
        currency: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.CURRENCY
        )?.setting_val,
        time_zone: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.TIME_ZONE
        )?.setting_val,
        invoice_start_number: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.INVOICE_START_NUMBER
        )?.setting_val,
      };

      await editGeneralSettings.mutateAsync(params);
      //useSetAtom(userCurrencyAtom);

      enqueueSnackbar("Settings saved successfully", {
        variant: "success",
        action: (key) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
        style: {
          borderRadius: "0.7rem",
        },
      });
    };
    if (save) {
      handleSubmit();
    }
    setSave(false);
  }, [save]);

  const country =
    props.data.find((item) => item.setting === GENERAL_SETTING_NAMES.COUNTRY)
      ?.setting_val || "";
  const currency =
    props.data.find((item) => item.setting === GENERAL_SETTING_NAMES.CURRENCY)
      ?.setting_val || "";
  const timeZone =
    props.data.find((item) => item.setting === GENERAL_SETTING_NAMES.TIME_ZONE)
      ?.setting_val || "";

  // useEffect(() => {
  //   // Make an API request to fetch currency data
  //   axios
  //     .get("https://openexchangerates.org/api/currencies.json")
  //     .then((response: { data: Record<string, string> }) => {
  //       const currencyData = response.data;

  //       // Convert the currency data to the desired string format
  //       setCurrencyStrings(
  //         Object.entries(currencyData).map(
  //           ([code, name]) => `${name} (${code})`
  //         )
  //       );
  //     })
  //     .catch((error: any) => {
  //       console.error("Error fetching currency data:", error);
  //     });
  // }, []);

  useEffect(() => {
    const fetchCurrencies = () => {
      try {
        const currencyNames = Object.entries(currencyData).map(
          ([code, name]) => `${name} (${code})`
        );

        setCurrencyStrings(currencyNames);
      } catch (error) {}
    };

    fetchCurrencies();
  }, []);

  // Function to update a specific row in the table by the settingName.
  const updateSettingVal = (settingName: string, newValue: any) => {
    props.setData((prevData: Record<string, any>[]) => {
      return prevData.map((item) => {
        if (item.setting === settingName) {
          return { ...item, setting_val: newValue };
        }
        return item;
      });
    });
    setSave(true);
  };

  return (
    <Card sx={{ padding: 2.2 }}>
      <Typography
        fontFamily="Helvetica Neue"
        variant="h5"
        sx={{ mb: 1.45, ml: 1.3 }}
      >
        Location and currency
      </Typography>
      {props.data?.length == 0 ? (
        <CircularProgress sx={{ display: "table", mx: "auto", mt: 2, mb: 2 }} />
      ) : (
        <Stack>
          <Stack direction="row">
            <MynkSelectBox
              title="Country/Region"
              sx={{ width: "15.7rem" }}
              options={countryList().getData()}
              value={country}
              handleChange={(newVal) =>
                updateSettingVal(GENERAL_SETTING_NAMES.COUNTRY, newVal)
              }
            />
            <MynkSelectBox
              title="Currency"
              sx={{ width: "15.7rem" }}
              options={currencyStrings}
              value={currency}
              handleChange={(newVal) =>
                updateSettingVal(GENERAL_SETTING_NAMES.CURRENCY, newVal)
              }
            />
          </Stack>
          <MynkSelectBox
            title="Time zone"
            sx={{ width: "32.5rem" }}
            options={timezones.map((timezone) => timezone.label)}
            value={timeZone}
            handleChange={(newVal) =>
              updateSettingVal(GENERAL_SETTING_NAMES.TIME_ZONE, newVal)
            }
          />
        </Stack>
      )}
    </Card>
  );
}

function LoggedInHistory() {
  const { data, isPending } = useGetDevicesHistoryQuery();
  const [current, setCurrent] = useState<
    { picture: LoggedInHistoryIconType; history_info: string }[]
  >([]);
  useEffect(() => {
    if (!isPending && data && data.history) {
      let fixedData: {
        picture: LoggedInHistoryIconType;
        history_info: string;
      }[] = [];

      data.history.map((value: string, index: number) => {
        const os = value.split("#", 1)[0];

        fixedData.push({
          picture:
            os == "Mac" || os == "Windows" || os == "Linux" || os == "Ubuntu"
              ? LoggedInHistoryIconType.COMPUTER
              : LoggedInHistoryIconType.MOBILE_PHONE,
          history_info: value,
        });
      });
      setCurrent(fixedData);
    }
  }, [data]);

  return (
    <Card>
      <StackTableCard
        data={current}
        paddingLevel={2}
        title={"Your devices history"}
        columns={LOGGED_IN_HISTORY_COLUMNS}
        sx={{ fontSize: "1.254rem" }}
        tableHeight={240}
      />
    </Card>
  );
}

function UploadProfilePhoto() {
  const uploadProfile = useUploadProfilePhotoMutation();
  const uploadBusinessLogo = useUploadBusinessLogoMutation();

  const { enqueueSnackbar } = useSnackbar();

  const onAddPhoto = async (selectedFile: FileObj | null, type: PhotoType) => {
    alert(type);
    if (selectedFile) {
      if (type == PhotoType.LOGO) {
        await uploadBusinessLogo.mutateAsync({ photo: selectedFile });

        enqueueSnackbar("Business logo uploaded successfully", {
          variant: "success",
          action: (key) => (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
          style: {
            borderRadius: "0.7rem",
          },
        });
      } else if (type == PhotoType.PROFILE) {
        await uploadProfile.mutateAsync({ photo: selectedFile });

        enqueueSnackbar("Profile photo uploaded successfully", {
          variant: "success",
          action: (key) => (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
          style: {
            borderRadius: "0.7rem",
          },
        });
      }
    }
  };

  return (
    <Card sx={{ padding: 2, justifyContent: "center", display: "flex" }}>
      <Stack direction="row" spacing="3.3rem">
        <UploadOnePhoto key={1} type={PhotoType.PROFILE} onSubmit={onAddPhoto} />
        <Divider orientation="vertical" />
        <UploadOnePhoto key={2} type={PhotoType.LOGO} onSubmit={onAddPhoto} />
      </Stack>
    </Card>
  );
}


interface ManageSubscriptionCardProps {

}

function ManageSubscriptionCard(props: ManageSubscriptionCardProps) {
  return (
    <Card sx={{ padding: 2.2 }}>
      <Grid container>
        <Grid item xs={12} sx={{ height: "5.7rem" }} textAlign="center">
        </Grid>
      </Grid>
    </Card>
  )
}


interface ButtonsCardProps {
  email: string;
}

function ButtonsCard(
  props: ButtonsCardProps
) {
  const [resetPasswordDialog, setResetPasswordDialog] = useState<boolean>(false);

  const askDeleteAccount = useAskDeleteAccountMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<
          unknown,
          AskDeleteAccountParams
        >;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        alert(detail ?? "");
      }
    },
  });

  const gotoPortal = useGotoPortalMutation({});

  const handleGotoPortal = async () => {
    const result = await gotoPortal.mutateAsync({
      return_url: window.location.href,
    })

    window.location.href = result.url;
  };

  return (
    <Card sx={{ padding: 2.2 }}>
      <ChangePasswordDialog
        open={resetPasswordDialog}
        onClose={() => setResetPasswordDialog(false)}
      />
      
      <Grid container>
        <Grid item xs={3.8} sx={{ height: "5.7rem" }} textAlign="center">
          <Typography
            fontFamily="Helvetica Neue"
            variant="h6"
            sx={{ mb: 1.45, ml: 1.3, fontSize: '1rem' }}
          >
            Manage subscription
          </Typography>
          <Stack alignItems="center">
            {askDeleteAccount.isSuccess ? (
              <Typography>Check your inbox to delete your account</Typography>
            ) : (
              <Button
                sx={{
                  background: "linear-gradient(90deg, #59b5d9, #6a91f2)",
                  color: "white",
                  borderRadius: "14rem",
                  height: "3rem",
                  width: "9rem",
                  "&:hover": {
                    background: "linear-gradient(90deg, #59b5d9b0, #6a91f2b0)",
                  },
                }}
                onClick={handleGotoPortal}
              >
                <Stack direction="row" alignItems="center" spacing="0.4rem">
                  <OpenInNewIcon sx={{ height: "1.5rem" }} />
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      textTransform: "none",
                      color: "white",
                    }}
                  >
                    {gotoPortal.isPending ? (
                      <CircularProgress
                        size={"1.4rem"}
                        sx={{ color: "white", mt: "0.25rem" }}
                      />
                    ) : (
                      "Go to Portal"
                    )}
                  </Typography>
                </Stack>
              </Button>
            )}
          </Stack>
        </Grid>

        <Grid item xs={0.2}>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={3.8} sx={{ height: "5.7rem" }} textAlign="center">
          <Typography
            fontFamily="Helvetica Neue"
            variant="h6"
            sx={{ mb: 1.45, ml: 1.3, fontSize: '1rem' }}
          >
            Change password
          </Typography>
          <Stack alignItems="center">
            <Button
              sx={{
                background: "linear-gradient(90deg, #59b5d9, #6a91f2)",
                color: "white",
                borderRadius: "14rem",
                height: "3rem",
                width: "9rem",
                "&:hover": {
                  background: "linear-gradient(90deg, #59b5d9b0, #6a91f2b0)",
                },
              }}
              onClick={async () =>
                setResetPasswordDialog(true)
              }
            >
              <Stack direction="row" alignItems="center" spacing="0.4rem">
                {/* <ForwardToInboxIcon sx={{ height: "1.5rem" }} /> */}
                <Typography
                  sx={{
                    fontSize: "1rem",
                    textTransform: "none",
                    color: "white",
                  }}
                >
                  Reset password
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={0.2}>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={3.8} sx={{ height: "5.7rem" }} textAlign="center">
          <Typography
            fontFamily="Helvetica Neue"
            variant="h6"
            sx={{ mb: 1.45, ml: 1.3, fontSize: '1rem' }}
          >
            Delete account
          </Typography>
          <Stack alignItems="center">
            {askDeleteAccount.isSuccess ? (
              <Typography>Check your inbox to delete your account</Typography>
            ) : (
              <Button
                sx={{
                  background:
                    "linear-gradient(90deg, #b07070 0%, #b00d0d 100%)",
                  color: "white",
                  borderRadius: "14rem",
                  height: "3rem",
                  width: "9rem",
                  "&:hover": {
                    background: "linear-gradient(90deg, #b07070b0, #b00d0db0)",
                  },
                }}
                onClick={async () =>
                  await askDeleteAccount.mutateAsync({ email: props.email })
                }
              >
                <Stack direction="row" alignItems="center" spacing="0.4rem">
                  <ForwardToInboxIcon sx={{ height: "1.5rem" }} />
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      textTransform: "none",
                      color: "white",
                    }}
                  >
                    {askDeleteAccount.isPending ? (
                      <CircularProgress
                        size={"1.4rem"}
                        sx={{ color: "white", mt: "0.25rem" }}
                      />
                    ) : (
                      "Send email"
                    )}
                  </Typography>
                </Stack>
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
interface GeneralSideCardProps {
  generalData: Array<Record<string, any>>;
  setGeneralData: (newData: Array<Record<string, any>>) => void;
}

export default function GeneralSideCard(props: GeneralSideCardProps) {
  return (
    <Stack sx={{ width: "56rem" }} spacing={3.3}>
      <UploadProfilePhoto />
      <LocationAndCurrency
        data={props.generalData}
        setData={props.setGeneralData}
      />
      
      <ButtonsCard
        email={
          props.generalData.find(
            (item) => item.setting === GENERAL_SETTING_NAMES.EMAIL
          )?.setting_val ?? ""
        }
      />
    </Stack>
  );
}
