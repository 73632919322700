import { useParams } from "react-router-dom";
import {
  useAddProjectFileMutation,
  useDeleteProjectFileMutation,
  useGetProjectQuery,
  useListProjectFilesQuery,
} from "../../../../api/projects";
import { useMemo } from "react";
import { MynkTab } from "../../../../components/MynkPage";
import ViewGeneralLayout, {
  TimelineColumn, openedDeleteFileAtom, openedEditFileAtom, selectedFileUuidAtom,
} from "../../../../components/ViewProjectOrContact/ViewGeneralLayout";
import { LowerCard, UpperCard } from "../common/cards";
import {
  AddProjectFileParams,
  FileObj,
  PhotographyProject,
} from "../../../../api/types";
import { useState } from "react";
import UploadOneFileDialog from "../../../../components/UploadOneFileDialog/UploadOneFile";
import { closeSnackbar, useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PreviewFileDialog from "../../../../components/PreviewFileDialog";
import { fetchFileSrc, zonedDate } from "../../../../util/common";
import { useAtom } from "jotai";
import {AxiosError} from "axios";
import { TwoActionButtonsViewProject } from "./General";
import ConfirmActionDialog from "../../../../components/ConfirmActionDialog";
import { EditFileNameDialog } from "../../../../dialogs/EditFileName";

const TIMELINE_COLUMNS = [
  TimelineColumn.FILE,
  TimelineColumn.FILE_TYPE,
  TimelineColumn.DATE,
  TimelineColumn.VIEW_FILE,
  TimelineColumn.THREE_DOTS_FILES
];

export default function ViewPhotographyProjectFilesSubpage() {
  const { projectId } = useParams();
  const { data } = useGetProjectQuery(
    { project_uuid: projectId ?? "" },
    Boolean(projectId)
  );
  const project = data?.project as PhotographyProject;

  const { data: filesData, isPending: isListFileLoading } =
    useListProjectFilesQuery(
      { project_uuid: projectId ?? "" },
      Boolean(projectId)
    );

  const { timelineItems } = useMemo(() => {
    if (!filesData?.files) return { timelineItems: [] };

    const items = filesData.files.map((f) => ({
      uuid: f.uuid,
      name: f.name,
      mimeType: f.mime_type,
      date: zonedDate(f.date),
      viewFileHandler: (name: string, file_uuid: string, mimeType: string) =>
        onViewFile(name, file_uuid, mimeType),
    }));

    return {
      timelineItems: items,
    };
  }, [filesData]);

  const [openFileUpload, setOpenFileUpload] = useState(false);

  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const addProjectFile = useAddProjectFileMutation({
      onError: (error: unknown) => {
        if (error instanceof Error && "response" in error) {
          const axiosError = error as AxiosError<unknown, AddProjectFileParams>;
          const detail = (axiosError.response?.data as { detail?: string })
            ?.detail;
          setErrorMsg(detail ?? "");
        }
      }
  });
  const onAddFile = async (selectedFile: FileObj | null) => {
    if (selectedFile) {
      await addProjectFile.mutateAsync({
        file: selectedFile,
        project_uuid: projectId ?? "",
      });
      setOpenFileUpload(false);

      enqueueSnackbar("File uploaded successfully", {
        variant: "success",
        action: (key) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
        style: {
          borderRadius: "0.7rem",
        },
      });
    }
  };

  const [selectedFile, setSelectedFile] = useState<FileObj | null>(null);
  const [selectedFileUuid, setSelectedFileUuid] = useAtom(selectedFileUuidAtom);
  const [openViewFile, setOpenViewFile] = useState(false);


  const [openedDeleteFile, setOpenedDeleteFile] = useAtom(openedDeleteFileAtom);
  const [openedEditFile, setOpenedEditFile] = useAtom(openedEditFileAtom);

  const onViewFile = async (
    name: string,
    file_uuid: string,
    mimeType: string
  ) => {
    setSelectedFile(null);
    setOpenViewFile(true);
    const selectedFile: FileObj = {
      name: name,
      data: (await fetchFileSrc(file_uuid, false)) ?? "",
      mime_type: mimeType,
    };
    setSelectedFile(selectedFile);
  };


  const deleteFile = useDeleteProjectFileMutation();
  const onDeleteFile = async (file_uuid: string) => {
    deleteFile.mutateAsync({ file_uuid: file_uuid });
    setOpenedDeleteFile(false);
    enqueueSnackbar("File deleted successfully", {
      variant: "success",
      action: (key) => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
      style: {
        borderRadius: "0.7rem",
      },
    });
  };

  return (
    <MynkTab
      title="Files"
      action={<TwoActionButtonsViewProject project={project} />}
    >
      <ConfirmActionDialog
        open={openedDeleteFile}
        what="file"
        whatAction="delete"
        onClose={() => setOpenedDeleteFile(false)}
        onConfirm={() => onDeleteFile(selectedFileUuid)}
        loading={deleteFile?.isPending ?? true}
      >
        Delete
      </ConfirmActionDialog>
      <EditFileNameDialog
        open={openedEditFile}
        onClose={() => setOpenedEditFile(false)}
        file_uuid={selectedFileUuid}
        />
      <PreviewFileDialog
        open={openViewFile}
        onClose={() => setOpenViewFile(false)}
        selectedFile={selectedFile}
        disableDownload
      />
      <UploadOneFileDialog
        open={openFileUpload}
        onClose={() => setOpenFileUpload(false)}
        onSubmit={onAddFile}
        errorMsg={errorMsg}
        loading={addProjectFile.isPending}
      />
      <ViewGeneralLayout
        timeline={{
          timelineTitle: "Files",
          timelineItems,
          timelineColumns: TIMELINE_COLUMNS,
          timelineAction: "+ Add a new file",
          isDataLoading: isListFileLoading,
          onTimelineActionClick: () => setOpenFileUpload(true),
          columnDivision: ["40%", "15%", "30%", "1%", "1%"]
        }}
        upperCard={
          data?.project && (
            <UpperCard project={data.project as PhotographyProject} />
          )
        }
        lowerCard={
          data?.project && (
            <LowerCard project={data.project as PhotographyProject} />
          )
        }
      />
    </MynkTab>
  );
}
