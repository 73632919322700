import React, { useState, useEffect } from "react";
import { Box, Stack, Typography, Skeleton } from "@mui/material";
import scrollRightArrow from "../../../../assets/icons/scroll-right-arrow.svg";
import scrollLeftArrow from "../../../../assets/icons/scroll-left-arrow.svg";
import { useListFilesQuery } from "../../../../api/files";
import { LibraryCategory } from "../../../../api/types";
import JSZip from 'jszip';
import { MynkTab } from "../../../../components/MynkPage";
import { useNavigate } from "react-router-dom";
import { PATHS, WorkflowPath, makePath } from "../../../../paths";
import { useListFavoritesQuery } from "../../../../api/library";


const PREVIEW_WIDTH = "22.7em";

interface BoxPreviewProps {
    imageUrl: string;
    onClick: () => void;
}

function BoxPreview(props: BoxPreviewProps) {
    const [imageReady, setImageReady] = useState(false);
  
    return (
        <Box
            sx={{
                width: PREVIEW_WIDTH,
                height: `calc(1.4 * ${PREVIEW_WIDTH})`,
                cursor: "pointer",
                transition: "transform 0.15s ease-in-out",
                "&:hover": {
                    transform: "scale(1.04)",
                },
            }}
            onClick={props.onClick}
        >
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "none",
                }}
            >
                {!imageReady && (
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={"100%"}
                        sx={{ bgcolor: "white" }}
                    />
                )}
        
                <img
                    src={props.imageUrl}
                    onLoad={() => setImageReady(true)}
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        display: imageReady ? "block" : "none",
                    }}
                />
            </Stack>
        </Box>
    );
}

interface LibraryGroupProps {
    libraryType: LibraryCategory;
}

function LibraryGroup(props: LibraryGroupProps) {
    const [images, setImages] = useState<string[] | null>(null);
    const [imagesNames, setImagesNames] = useState<string[] | null>(null);

    const { data, isLoading } = useListFilesQuery({ folder: `${props.libraryType}Images` });
    const { data: favoritesData, isLoading: favoritesLoading } = useListFavoritesQuery({});

    const navigate = useNavigate();

    const processZip = async (zipBlob: Blob) => {
        try {
            if (!zipBlob || !favoritesData || !favoritesData.favorites) return [];
    
            const arrayBuffer = await zipBlob.arrayBuffer();
            const zip = await JSZip.loadAsync(arrayBuffer);

            const items: string[] = [];
            const itemsNames = [];
    
            for (const fileName of Object.keys(zip.files)) {
                const file = zip.file(fileName);
                const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
    
                if (file && favoritesData.favorites.includes(fileNameWithoutExtension)) {
                    const base64 = await file.async('base64');

                    if (base64) {
                        items.push(`data:image/jpeg;base64,${base64}`);
                        itemsNames.push(fileNameWithoutExtension);
                    }
                }
            }
    
            return [items, itemsNames];
        } catch (error) {
            console.error('Error processing ZIP file:', error);
            return [];
        }
    };

    useEffect(() => {
        const isValidBlob = (value: any): value is Blob => {
            // Check if `value` is a Blob (the expected format from the backend)
            return value instanceof Blob;
        };

        if (!isLoading && data && !favoritesLoading && favoritesData) {
            // Check that data is in the correct format (Blob)
            if (isValidBlob(data)) {
                const processImages = async () => {
                    const [images, imagesNames] = await processZip(data);
                    setImages(images);
                    setImagesNames(imagesNames);
                };

                processImages();
            } else {
                console.warn('Data is not a valid Blob. Waiting for correct format.');
            }
        }
    }, [data, isLoading, favoritesData, favoritesLoading]);

    const handleClick = (fileName: string) => {
        navigate(
            makePath(
                WorkflowPath.PHOTOGRAPHY,
                props.libraryType === LibraryCategory.GUIDES ?
                    PATHS.guide(fileName).index :
                    PATHS.contract(fileName).index
            )
        );
    };

    if (images !== null && images.length === 0) {
        return (
            <></>
        )
    }

    return (
        <Stack>
            <Typography variant="h4" sx={{ pt: 2 }}>{props.libraryType}</Typography>

            <Stack direction={'row'} mt={3} spacing={5} ml={4}>
                {images !== null && imagesNames !== null ? images.map((imageSrc, index) => (
                    <BoxPreview key={index} imageUrl={imageSrc} onClick={() => handleClick(imagesNames[index])} />
                )) :
                    <>
                        <BoxPreview imageUrl={""} onClick={() => {}} />
                        <BoxPreview imageUrl={""} onClick={() => {}} />
                        <BoxPreview imageUrl={""} onClick={() => {}} />
                    </>
                }
            </Stack>
        </Stack>
    );
}

export default function LibraryFavoritesSubpage() {
    return (
        <MynkTab title="Favorites">
            <Stack spacing={5}>
                <LibraryGroup libraryType={LibraryCategory.CONTRACTS}/>
                <LibraryGroup libraryType={LibraryCategory.GUIDES}/>
            </Stack>
        </MynkTab>
    );
}
