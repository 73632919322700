import { Box, SxProps, TextField } from "@mui/material";
import { SearchOption } from "../../api/types";
import { useEffect, useRef, useState } from "react";
import searchIcon from "../../assets/icons/search-icon.svg";

interface SearchBoxProps {
  searchState: [
    SearchOption,
    React.Dispatch<React.SetStateAction<SearchOption>>
  ];
  height?: number;
  width?: number | string;
  isStretched?: boolean;
  sx?: SxProps;
}

export default function SearchBox(props: SearchBoxProps) {
  const [search, setSearch] = props.searchState;
  const [isFocused, setFocused] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [query, setQuery] = useState("");
  const handleSearch = (searchQuery: string) => {
    setQuery(searchQuery);
  };

  // If changed - send search request if 0.75s without typing:
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      if (setSearch) {
        setSearch({ query: query });
      }
    }, 750); // delay of 0.75s
  }, [query]);

  // If search reseted - reset the input itself also:
  useEffect(() => {
    if (search.query == "") {
      setQuery("");
    }
  }, [search]);

  // Reset timer onload:
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    // The box that wrappes the whole thing:
    <Box
      sx={{
        height: props.height ? `${props.height / 14}rem` : "2.4286rem",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#ecf0f5",
        borderRadius: 100,
        width: props.isStretched
          ? isFocused
            ? props.width
              ? `${Number(props.width) / 14}rem`
              : "12.5rem"
            : props.width
            ? `${Number(props.width) / 1.5 / 14}rem`
            : "7.5rem"
          : props.width, // On focus -> stretch the whole thing.
        transition: "width 0.3s",
        ...props.sx,
      }}
    >
      {/* Search icon */}
      <Box
        component="img"
        height={props.height ? `${props.height / 14 / 1.22}rem` : "2.1429rem"}
        sx={{ ml: 0.67, mr: 0.5, mt: 0.2 }}
        src={searchIcon}
      />
      {/* Text input field itself */}
      <TextField
        variant="standard"
        InputProps={{ disableUnderline: true }}
        placeholder="Search"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={query}
        onChange={(e) => handleSearch(e.target.value)}
        sx={{
          width: "100%",
          "& input::placeholder": {
            fontFamily: "Helvetica Neue",
            color: "#000000",
            fontWeight: "bold",
            fontSize: props.height ? `${props.height / 2.4 / 14}rem` : "1rem",
          },
          "& input": {
            color: "#5d6063",
            fontFamily: "Helvetica Neue",
            fontSize: props.height ? `${props.height / 2.3 / 14}rem` : "1rem",
            ml: 0.3,
          },
        }}
      ></TextField>
    </Box>
  );
}
