// @ts-nocheck

const MYNK_BASE_THEME = {
  spacing: "0.5714rem",
  
  typography: {
    fontFamily: [
      'Heebo, sans-serif',
    ].join(','),
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#404040",
          fontFamily: "Heebo, sans-serif"
        },
        h1: {
          fontFamily: "Heebo, sans-serif Bold !important",
          fontWeight: "bold",
          fontSize: "2.3rem", 
        },
        h2: {
          fontFamily: "Heebo, sans-serif",
          fontWeight: "bold",
          fontSize: "1.92927146rem", 
        },
        h3: {
          fontFamily: "Heebo, sans-serif",
          fontWeight: "bold",
          fontSize: "1.571rem", 
        },
        h4: {
          fontFamily: "Heebo, sans-serif",
          fontWeight: "bold",
          fontSize: "1.7rem", 
          color: "black"
        },
        h5: {
          fontFamily: "Heebo, sans-serif",
          fontSize: "1.286rem", 
          fontWeight: "bold"
        },
        h6: {
          fontFamily: "Heebo, sans-serif",
          fontSize: "1.071rem",
          fontWeight: "bold"
        },
        subtitle1: {
          fontFamily: "Heebo, sans-serif",
          fontSize: "1.22rem",
          fontWeight: "bold "
        },
        subtitle2: {
          fontFamily: "Heebo, sans-serif",
          fontSize: "1.143rem",
          fontWeight: "bold"
        },
        body2: {
          fontFamily: "Heebo, sans-serif",
          fontSize: "1.2rem"
        }
      }
    },

    MuiCard: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(#ffffff60, #ffffff20)',
          border: "0.17rem solid white",
        }
      }
    },

    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          fontFamily: 'Heebo, sans-serif',
          fontWeight: "bold",
          variant: "h4",
          sx: {ml: 1.5},
        },
      },
    },

    MuiCardContent: {
      defaultProps: {
        sx: {
          ml: 1,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {}
      }
    },

    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: "1.1429rem",
          boxShadow: '0px 0.3571rem 1.4286rem rgba(117, 179, 255, 0.35)',
        }
      }
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          paddingTop: '0.4286rem',
          paddingBottom: '0.4286rem',
          borderBottom: '0rem',
        },

        root: {
          paddingLeft: '2.2857rem',
          paddingRight: '2.2857rem',
          borderBottom: '0.0714rem solid #75b3ff30',
        }
      }
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          '&.Mui-selected': {
            background: '#75b3ff',
            color: 'white',
          },
        },
      }
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          textAlign: 'left',
          alignItems: 'left',
          borderBottom: 'solid 0.1429rem #69b3ff4c',
          padding: '0',
          minHeight: '1.7143rem',
        },
      }
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '1.7143rem',

          '& .MuiTabs-indicator': {
            background: '#75b3ff',
          },

          '& .MuiTouchRipple-root': {
            width: '100%',
            height: '100%',
          }
        }
      }
    },

    MuiSelect: {
      defaultProps: {
        disableUnderline: true,
        inputProps: {
          sx: {fontSize: "1rem", mb: '-0.1429rem'}
        }
      },
      styleOverrides: {
        root: {
          background: '#ecf0f5',
          borderRadius: '1.1429rem',
          paddingLeft: '1.1429rem',
        },
      },
    },
  }
};

export default MYNK_BASE_THEME;
