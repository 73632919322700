import React, { useEffect, useRef, useState } from 'react';
import { Stack, Typography, Divider, CircularProgress, Box } from '@mui/material';
import { useListFilesQuery } from '../../../api/files';
import { LibraryCategory } from '../../../api/types';
import JSZip from 'jszip';
import mammoth from 'mammoth';
import { useParams } from 'react-router-dom';
import MynkPageHeader from '../../../components/MynkPage/MynkPageHeader';
import { PATHS, WorkflowPath, makePath } from "../../../paths";
import DownloadIcon from '../../../assets/icons/download-icon.svg';
import { useAddFavoriteMutation } from '../../../api/library';
import { AxiosError } from 'axios';
import { AddLibraryFavoriteParams, DeleteLibraryFavoriteParams } from '../../../api/types';
import { useListFavoritesQuery } from '../../../api/library';
import { useDeleteFavoriteMutation } from '../../../api/library';


interface GuidesSideBarProps {
    currentTitle: string;
    titleRefs: any;
    titles: string[];
    sidebarTitleMap: Record<string, string[]>; // Add sidebarTitleMap prop to map sidebar to content titles
    scrollableContainerRef: any;
}

function ContractSideBar(props: GuidesSideBarProps) {
    const handleTitleClick = (sidebarTitle: string) => {
        const contentTitles = props.sidebarTitleMap[sidebarTitle]; // Get content titles associated with the sidebar title

        if (contentTitles && contentTitles.length > 0) {
            // Scroll to the first content title associated with the sidebar title
            const firstContentTitle = contentTitles[0];
            const titleElement = props.titleRefs.current[firstContentTitle];

            if (titleElement) {
                const container = props.scrollableContainerRef.current;
                const rect = titleElement.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();
                
                // Calculate the center position for scrolling
                const scrollPosition = rect.top - containerRect.top + container.scrollTop - (containerRect.height / 2) + (rect.height / 2);
        
                container.scrollTo({
                    top: scrollPosition,
                    behavior: 'smooth'
                });
            }
        }
    };

    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const extractText = (title: string) => {
        const text = title.replace(/^\d+\.\s*/, '');
        return capitalizeWords(text.toLowerCase());
    };

    return (
        <Stack mr={10} sx={{
            position: "sticky",
            alignSelf: "flex-start",
            flexBasis: "30rem",
            top: 0,
        }}>
            <Typography fontSize={"clamp(16px, 1.6rem, 1.6rem)"} fontWeight={600}>
                What's in the Contract
            </Typography>

            <Divider sx={{ mt: 2, maxWidth: "21.5rem" }} />

            {props.titles.map((sidebarTitle: string) => (
                <Stack key={sidebarTitle} maxWidth={"20rem"}>
                    <Typography
                        onClick={() => handleTitleClick(sidebarTitle)}
                        sx={{
                            mt: 2,
                            opacity: props.sidebarTitleMap[sidebarTitle].includes(props.currentTitle) ? 1 : 0.7,
                            fontSize: "clamp(14px, 1.2rem, 1.2rem)",
                            fontWeight: 500,
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: 1,
                            },
                        }}
                    >
                        {extractText(sidebarTitle)}
                    </Typography>
                </Stack>
            ))}
        </Stack>
    );
}


interface GuideContentProps {
    titlesContents: Record<string, HTMLElement[]>;
    titleRefs: any;
    mainTitle: string;
    mainTitleContent: HTMLElement[];
    scrollableContainerRef: any;
}

function removeTagsAndContent(content: string, tagsToRemove: string[]) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    // Remove all specified tags and their content
    tagsToRemove.forEach(tag => {
        const elements = doc.querySelectorAll(tag);
        elements.forEach(el => el.remove()); // Remove the element and all its children
    });

    return doc.body.innerHTML; // Return the sanitized HTML
}

function ContractContent(props: GuideContentProps) {
    return (
        <Stack
            width="calc(100% - 30rem)"
            sx={{
                overflowY: 'scroll',
                scrollbarWidth: 'none',
            }}
            ref={props.scrollableContainerRef}
        >
            <Typography fontWeight={700} fontSize={"clamp(16px, 1.5rem, 1.5rem)"}>Disclaimer: </Typography>
            <Typography fontWeight={400} fontSize={"clamp(14px, 1.5rem, 1.5rem)"} lineHeight={"2rem"} textAlign={"justify"}>
                This document is provided as a template for informational purposes only and does not constitute legal advice.
                We strongly recommend that you consult with a licensed attorney in your jurisdiction to ensure that this contract meets your specific requirements and complies with local regulations.
                Mynk, Inc. and its contributors make no warranties regarding the enforceability, accuracy, or suitability of this template and assume no liability for any consequences arising from its use.
            </Typography>

            <Typography fontWeight={600} textAlign={"center"} fontSize={"clamp(20px, 2.1rem, 2.1rem)"} mt={5} sx={{textDecoration: 'underline'}}>{props.mainTitle}</Typography>

            {props.mainTitleContent && props.mainTitleContent.map((paragraph, index) => (
                <Typography
                    key={index}
                    mt={2.5}
                    fontSize={"clamp(16px, 1.5rem, 1.5rem)"}
                    lineHeight={"2rem"}
                    fontFamily={'"InterVariable",sans-serif'}
                    component="div"
                    textAlign={"justify"}
                    // Clean up the innerHTML by removing <em> and <s> tags
                    dangerouslySetInnerHTML={{ __html: removeTagsAndContent(paragraph.innerHTML, ['em', 's']) }}
                />
            ))}

            {Object.keys(props.titlesContents).map((title: string, index) => (
                <Stack id={`title-${title}`} key={index} ref={(el) => (props.titleRefs.current[title] = el)} mt={0}>
                    <Typography
                        fontSize={"clamp(16px, 1.5rem, 1.5rem)"}
                        fontFamily={"Helvetica"}
                        lineHeight={"35px"}
                        fontWeight={600}
                        textAlign={'justify'}
                    >
                        {title}
                    </Typography>

                    {props.titlesContents[title].length > 0 && (
                        props.titlesContents[title].map((paragraph, index) => (
                            <Typography
                                key={index}
                                mt={2.5}
                                fontSize={"clamp(16px, 1.5rem, 1.5rem)"}
                                fontFamily={'"InterVariable",sans-serif'}
                                component="div"
                                // Clean up the innerHTML by removing <em> and <s> tags
                                dangerouslySetInnerHTML={{ __html: removeTagsAndContent(paragraph.innerHTML, ['em', 's']) }}
                                ml={5}
                                textAlign={"justify"}
                            />
                        ))
                    )}
                </Stack>
            ))}
        </Stack>
    );
}

interface ContractHeaderProps {
    fileName: string;
}

function ContractHeader(props: ContractHeaderProps) {
    const [isFavorite, setIsFavorite] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");

    const { data: favoritesData, isLoading: favoritesLoading } = useListFavoritesQuery({});
    const { data, isLoading } = useListFilesQuery({ folder: LibraryCategory.CONTRACTS });

    const { mutate: addFavorite, isPending } = useAddFavoriteMutation({
        onError: (error: unknown) => {
            if (error instanceof Error && "response" in error) {
                const axiosError = error as AxiosError<unknown, AddLibraryFavoriteParams>;
                const detail = (axiosError.response?.data as { detail?: string })?.detail;
                setErrorMsg(detail ?? "Something went wrong. Please try again.");
            }
        },
        onSuccess: () => {
            setIsFavorite(true);
            setErrorMsg("");
        },
    });

    const { mutate: deleteFavorite, isPending: deletePending } = useDeleteFavoriteMutation({
        onError: (error: unknown) => {
            if (error instanceof Error && "response" in error) {
                const axiosError = error as AxiosError<unknown, DeleteLibraryFavoriteParams>;
                const detail = (axiosError.response?.data as { detail?: string })?.detail;
                setErrorMsg(detail ?? "Something went wrong. Please try again.");
            }
        },
        onSuccess: () => {
            setIsFavorite(false);
            setErrorMsg("");
        },
    });

    const handleFavoriteClick = () => {
        if (favoritesLoading || isPending || deletePending) return;

        if (!isFavorite)
            addFavorite({ file_name: props.fileName });
        else
            deleteFavorite({ file_name: props.fileName });
    };

    const handleDownloadClick = async () => {
        if (isLoading || !data) return;
    
        try {
            const arrayBuffer = await data.arrayBuffer();
            const zip = await JSZip.loadAsync(arrayBuffer);
    
            const file = zip.file(`${props.fileName}.docx`);
            
            if (file) {
                const blob = await file.async("blob");
    
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${props.fileName}.docx`;
                a.click();
                URL.revokeObjectURL(url);
            } else {
                console.error(`File ${props.fileName} not found in the ZIP`);
            }
        } catch (error) {
            console.error('Error processing the ZIP file for download:', error);
        }
    };

    useEffect(() => {
        if (favoritesData) {
            setIsFavorite(favoritesData.favorites.includes(props.fileName));
        }
    }, [favoritesData, props.fileName]);

    return (
        <Stack
            direction={"row"}
            alignContent={"justify-between"}
            sx={{
                position: 'sticky',
                height: "6.5rem",
                maxHeight: "100px",
                zIndex: 1000,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 32,
                    left: 32,
                }}
            >
                <MynkPageHeader
                    title="Contract"
                    backLink={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.library.all)}
                    selectedTabIndex={0}
                    tabs={[]}
                />
            </Box>
            
            <Box
                sx={{
                    position: 'absolute',
                    top: 32,
                    right: "10rem",
                    padding: "1rem",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    cursor: 'pointer',
                }}
                onClick={handleFavoriteClick}
            >
                <Box
                    component="svg"
                    viewBox="0 0 24 24"
                    width={"1.7rem"}
                    height={"1.7rem"}
                    sx={{
                        transition: "fill 0.3s ease, stroke 0.3s ease",
                        fill: isFavorite ? "#1976d2" : "#FFFFFF",
                        stroke: isFavorite ? "#1976d2" : "#808080",
                        strokeWidth: 2,
                    }}
                >
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                </Box>
            </Box>
            
            <Box
                sx={{
                    position: 'absolute',
                    top: 32,
                    right: "5rem",
                    padding: "1rem",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                onClick={handleDownloadClick}
            >
                {isLoading ? (
                    <CircularProgress size={24} />
                ) : (
                    <Box
                        component="img"
                        src={DownloadIcon}
                        width={"1.7rem"}
                        height={"1.7rem"}
                        sx={{
                            cursor: "pointer"
                        }}
                    />
                )}
            </Box>
        </Stack>
    );
}

export default function ContractPage() {
    const { imageName } = useParams<{ imageName?: string }>();
    const { data, isLoading } = useListFilesQuery({ folder: LibraryCategory.CONTRACTS + "Library" });

    const [htmlContent, setHtmlContent] = useState<string>('');

    const [mainTitle, setMainTitle] = useState<string>('');
    const [mainTitleContent, setMainTitleContent] = useState<HTMLElement[]>([]);

    const [currentTitle, setCurrentTitle] = useState<string>('');
    const [titles, setTitles] = useState<string[]>([]);
    const [titlesContents, setTitlesContents] = useState<Record<string, HTMLElement[]> | null>(null);
    const [sidebarTitles, setSidebarTitles] = useState<string[]>([]);
    const [sidebarTitleMap, setSidebarTitleMap] = useState<Record<string, string[]>>({}); // New map for sidebar titles to actual content titles

    const titleRefs = useRef<{ [key: string]: HTMLElement | null }>({});
    const scrollableContainerRef = useRef<HTMLDivElement>(null);

    const processZip = async (zipBlob: Blob) => {
        try {
            const arrayBuffer = await zipBlob.arrayBuffer();
            const zip = await JSZip.loadAsync(arrayBuffer);
            const items = [];

            for (const fileName of Object.keys(zip.files)) {
                const file = zip.file(fileName);
                
                if (fileName && file) {
                    const content = await file.async('arraybuffer');

                    const { value: html } = await mammoth.convertToHtml({ arrayBuffer: content });
                    items.push({
                        'fileName': fileName.substring(0, fileName.lastIndexOf('.')),
                        'html': html,
                    });
                }
            }

            return items;
        } catch (error) {
            console.error('Error processing ZIP file:', error);
            return [];
        }
    };

    useEffect(() => {
        if (data && !isLoading && imageName) {
            const processGuides = async () => {
                const htmls = await processZip(data);
                
                for (const html of htmls) {
                    if (html.fileName.trim().toLowerCase() === imageName.trim().toLowerCase()) {
                        setHtmlContent(html.html);
                        break;
                    }
                }
            };

            processGuides();
        }
    }, [data, isLoading]);

    useEffect(() => {
        if (!htmlContent) return;

        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        const emElements = Array.from(doc.querySelectorAll('em')); // Sidebar titles (also includes the main title)
        const tempMainTitle = emElements.shift(); // First <em> is the main title, remove it from sidebar titles
        const tempSidebarTitles = emElements.map(em => em.textContent || '');

        // Remove all <em> elements from the document
        emElements.forEach(em => em.remove());

        const titleElements = Array.from(doc.querySelectorAll('s')); // Content titles
        const allParagraphs = Array.from(doc.querySelectorAll('p, ul')); // Gather all paragraphs and lists

        const tempTitlesContents: Record<string, HTMLElement[]> = {};
        const tempSidebarTitleMap: Record<string, string[]> = {}; // Mapping of sidebar titles to content titles
        const tempMainTitleContent: HTMLElement[] = []; // For content between main title and first content title

        let isFirstTitleProcessed = false; // Flag to detect if the first title has been processed

        // Iterate through each sidebar title and find the titles between the current and next sidebar title
        emElements.forEach((sidebarTitleElement, sidebarIndex) => {
            const currentSidebarTitle = tempSidebarTitles[sidebarIndex];
            const nextSidebarTitleElement = emElements[sidebarIndex + 1] || null;

            const currentSidebarTitlePosition = (node: Node) => sidebarTitleElement.compareDocumentPosition(node);
            const nextSidebarTitlePosition = nextSidebarTitleElement ? (node: Node) => nextSidebarTitleElement.compareDocumentPosition(node) : null;

            tempSidebarTitleMap[currentSidebarTitle] = [];

            // Find content titles (<s>) that are between the current sidebar title and the next one
            titleElements.forEach((titleElement, titleIndex) => {
                const currentTitleText = titleElement.textContent || '';

                const isAfterCurrentSidebarTitle = currentSidebarTitlePosition(titleElement) & Node.DOCUMENT_POSITION_FOLLOWING;
                const isBeforeNextSidebarTitle = nextSidebarTitlePosition ? nextSidebarTitlePosition(titleElement) & Node.DOCUMENT_POSITION_PRECEDING : true;

                if (isAfterCurrentSidebarTitle && isBeforeNextSidebarTitle) {
                    tempSidebarTitleMap[currentSidebarTitle].push(currentTitleText);

                    // Extract paragraphs that belong to this title
                    const currentTitlePosition = (node: Node) => titleElement.compareDocumentPosition(node);
                    const nextTitle = titleElements[titleIndex + 1];
                    const nextTitlePosition = nextTitle ? (node: Node) => nextTitle.compareDocumentPosition(node) : null;

                    const titlesContentsBetween = allParagraphs.filter(paragraph => {
                        const isAfterCurrentTitle = currentTitlePosition(paragraph) & Node.DOCUMENT_POSITION_FOLLOWING;
                        const isBeforeNextTitle = nextTitlePosition ? nextTitlePosition(paragraph) & Node.DOCUMENT_POSITION_PRECEDING : true;
                        return isAfterCurrentTitle && isBeforeNextTitle;
                    });

                    // If it's the first title, extract content between the main title and the first title
                    if (!isFirstTitleProcessed) {
                        const mainTitleContentBetween = allParagraphs.filter(paragraph => {
                            const isBeforeFirstTitle = currentTitlePosition(paragraph) & Node.DOCUMENT_POSITION_PRECEDING;
                            return isBeforeFirstTitle;
                        });

                        // Add paragraphs to the main title content
                        tempMainTitleContent.push(...mainTitleContentBetween.map(paragraph => paragraph as HTMLElement));
                        isFirstTitleProcessed = true; // Set the flag to indicate that the first title has been processed
                    }

                    tempTitlesContents[currentTitleText] = titlesContentsBetween.map(paragraph => paragraph as HTMLElement);
                }
            });
        });
    
        setTitlesContents(tempTitlesContents);
        setCurrentTitle(Object.keys(tempTitlesContents)[0]);
        setTitles(Object.keys(tempTitlesContents));
        setMainTitle(tempMainTitle?.textContent || '');
        setSidebarTitles(tempSidebarTitles);
        setSidebarTitleMap(tempSidebarTitleMap);
        setMainTitleContent(tempMainTitleContent);
    }, [htmlContent]);

    useEffect(() => {
        if (!scrollableContainerRef.current) return;

        const handleIntersect = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const elementId = entry.target.id;
    
                    // Detect current title based on the element at the center of the container
                    if (elementId.startsWith("title-")) {
                        const title = elementId.replace("title-", "");
                        setCurrentTitle(title);
                    }
                }
            });
        };
    
        const observerOptions = {
            root: scrollableContainerRef.current, // Set the root to the scrollable container
            rootMargin: "-50% 0px -50% 0px", // Adjust this margin as needed
            threshold: 0, // Adjust the threshold if necessary
        };
    
        const observer = new IntersectionObserver(handleIntersect, observerOptions);
    
        // Observe title elements
        Object.keys(titleRefs.current).forEach((key) => {
            const el = titleRefs.current[key];
            if (el) observer.observe(el);
        });
    
        return () => {
            observer.disconnect(); // Clean up observers
        };
    }, [titleRefs, titles, scrollableContainerRef]);

    return (
        <Stack>
            <ContractHeader fileName={imageName ?? '...'}/>

            <Stack
                direction="row"
                spacing={0}
                maxWidth={'1800px'}
                mx={"auto"}
                pl={10}
                pr={20}
                mt={7}
            >
                {htmlContent && (
                    <Stack
                        direction="row"
                        sx={{
                            flexGrow: 1,
                            height: 'calc(100vh - 20rem)',
                        }}
                    >
                        <ContractSideBar
                            currentTitle={currentTitle}
                            titles={sidebarTitles}
                            sidebarTitleMap={sidebarTitleMap}
                            titleRefs={titleRefs}
                            scrollableContainerRef={scrollableContainerRef}
                        />

                        <ContractContent
                            titlesContents={titlesContents || {}}
                            titleRefs={titleRefs}
                            mainTitle={mainTitle}
                            mainTitleContent={mainTitleContent}
                            scrollableContainerRef={scrollableContainerRef}
                        />
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
}
