import {
  Box,
  Card,
  CircularProgress,
  Grid,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { ProjectStage } from "../../api/types";
import {
  downloadFile,
  fetchFileSrc,
  formatMoney,
  simplifyMimeType,
} from "../../util/common";
import timelineProjectCreatedIcon from "../../assets/icons/timeline/timeline-project-created-icon.svg";
import timelineLeadIcon from "../../assets/icons/timeline/timeline-lead-icon.svg";
import timelineProposalIcon from "../../assets/icons/timeline/timeline-proposal-icon.svg";
import timelineUnderContractIcon from "../../assets/icons/timeline/timeline-under-contract-icon.svg";
import timelineWaitingForEventIcon from "../../assets/icons/timeline/timeline-waiting-for-event-icon.svg";
import timelineEditingIcon from "../../assets/icons/timeline/timeline-editing-icon.svg";
import timelineFinalApprovalIcon from "../../assets/icons/timeline/timeline-final-approval-icon.svg";
import timelineCompletedIcon from "../../assets/icons/timeline/timeline-completed-icon.svg";
import timelineIconFile from "../../assets/icons/timeline-icon-file.svg";
import { Link } from "react-router-dom";
import { useSetProjectStageMutation } from "../../api/projects";
import { atom, useAtom, useAtomValue } from "jotai";
import { userCurrencyAtom } from "../../layouts/Main";
import { DateTime } from "luxon";
import ThreeDotsButton from "../ThreeDotsButton";

interface DetailsCardsProps {
  upperCard?: ReactNode;
  lowerCard?: ReactNode;
}

function DetailsCards(props: DetailsCardsProps) {
  return (
    <Stack spacing={3}>
      <Card sx={{ p: 3, pt: 2 }}>{props.upperCard}</Card>

      <Card sx={{ p: 3, pt: 2 }}>{props.lowerCard}</Card>
    </Stack>
  );
}

export enum TimelineColumn {
  PROJECT = "Project",
  DESCRIPTION = "Description",
  STAGE = "Stage",
  FILE = "File name",
  DATE = "Date",
  PRICE = "Price",
  VIEW_PROJECT = "View",
  FILE_TYPE = "Type",
  VIEW_FILE = "Preview",
  THREE_DOTS_FILES = "Files options",
}

export const selectedFileUuidAtom = atom("");
export const openedDeleteFileAtom = atom(false);
export const openedEditFileAtom = atom(false);

enum threeDotsOptions {
  DOWNLOAD = "download-file",
  EDIT = "edit-file",
  DELETE = "delete-file",
}

const DOTS_MENU = [
  {
    id: threeDotsOptions.DOWNLOAD,
    label: "Download",
  },
  {
    id: threeDotsOptions.EDIT,
    label: "Edit",
  },
  {
    id: threeDotsOptions.DELETE,
    label: "Delete",
  },
];

interface ThreeDotsFileProps {
  fileUuid: string;
  fileName: string;
}

/**
 * Renders a button with three dots. When the button is clicked, it opens a menu with options to edit or delete a task.
 * Also renders two dialog components, `ConfirmDeleteDialog` and `AddPhotographyContactDialog`, which are opened when the corresponding menu options are selected.
 *
 * @returns The rendered `ThreeDotsNote` component with a button and two dialog components.
 */
function ThreeDotsFile(props: ThreeDotsFileProps) {
  const [selectedFileUuid, setSelectedFileUuid] = useAtom(selectedFileUuidAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteFileAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditFileAtom);

  const handleDownloadFile = async () => {
    const data = (await fetchFileSrc(props.fileUuid, false)) ?? "";
    downloadFile(props.fileName, data);
  };

  /**
   * Handles the click event on the menu options.
   * Sets the corresponding state variable to `true` to open the corresponding dialog component.
   *
   * @param id - The ID of the clicked menu option.
   */
  const handleClickItem = (id: string) => {
    setSelectedFileUuid(props.fileUuid);

    switch (id) {
      case threeDotsOptions.DOWNLOAD:
        handleDownloadFile();
        break;
      case threeDotsOptions.EDIT:
        setOpenedEdit(true);
        break;
      case threeDotsOptions.DELETE:
        setOpenedDelete(true);
        break;
    }
  };

  return (
    <ThreeDotsButton
      menu={DOTS_MENU}
      onClickItem={handleClickItem}
      children={undefined}
    ></ThreeDotsButton>
  );
}

export interface TimelineItem {
  uuid?: string;
  title?: string;
  kind?: string;
  date?: DateTime;
  stage?: ProjectStage | string;
  price?: number;
  mimeType?: string;
  name?: string;
  viewPath?: string;
  viewFileHandler?: (file_uuid: string, name: string, mimeType: string) => void;
}

interface TimelineCardProps {
  timelineTitle?: string;
  timelineColumns: TimelineColumn[];
  timelineItems?: TimelineItem[];
  timelineAction: string;
  isDataLoading?: boolean;
  onTimelineActionClick?: () => void;
  columnDivision?: string[];
  isProjectTimeline?: boolean;
}

function getTimelineIcon(item: TimelineItem) {
  if (item.kind || item.name) return timelineIconFile;
  if (item.mimeType) {
    // Switch case by filetypes.
  }

  const stage = item.stage ?? null;
  switch (stage) {
    case "Project":
      return timelineProjectCreatedIcon;

    case ProjectStage.LEAD:
      return timelineLeadIcon;
    case ProjectStage.PROPOSAL:
      return timelineProposalIcon;
    case ProjectStage.UNDER_CONTRACT:
      return timelineUnderContractIcon;
    case ProjectStage.WAITING_FOR_EVENT:
      return timelineWaitingForEventIcon;
    case ProjectStage.EDITING:
      return timelineEditingIcon;
    case ProjectStage.FINAL_APPROVAL:
      return timelineFinalApprovalIcon;
    case ProjectStage.COMPLETED:
      return timelineCompletedIcon;
    default:
      return timelineProjectCreatedIcon;
  }
}

function TimelineCard(props: TimelineCardProps) {
  const currencyCode = useAtomValue(userCurrencyAtom);

  const setProjectStage = useSetProjectStageMutation();
  const handleChange = (uuid: string, value: string) => {
    setProjectStage.mutate({
      uuid: uuid,
      stage: value as ProjectStage,
    });
  };

  const onDownloadFile = async (name: string, uuid: string) => {
    const data = (await fetchFileSrc(uuid, false)) ?? "";
    downloadFile(name, data);
  };

  return (
    <Card sx={{ py: 1.8, height: "45rem", overflowY: "auto" }}>
      <Typography
        variant="h4"
        fontFamily="Helvetica Neue"
        sx={{ px: 3, pb: 1 }}
      >
        {props.timelineTitle ?? "Timeline"}
      </Typography>

      <Table
        sx={{
          mt: 2,
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <colgroup>
          <col span={0} style={{ width: "0rem" }} />
        </colgroup>

        {props.columnDivision && (
          <colgroup>
            {props.columnDivision.map((currentWidth, index) => {
              return (
                <col key={index} span={1} style={{ width: currentWidth }} />
              );
            })}
          </colgroup>
        )}

        <TableHead>
          <TableRow sx={{ bgcolor: "#75b3ff19" }}>
            <TableCell></TableCell>
            {props.timelineColumns.map((col) => (
              <TableCell key={col} sx={{ px: 4, fontSize: "1.18rem" }}>
                {!(
                  col === TimelineColumn.VIEW_FILE ||
                  col === TimelineColumn.VIEW_PROJECT ||
                  col === TimelineColumn.THREE_DOTS_FILES
                ) && col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {props.timelineItems && (
          <TableBody>
            {props.timelineItems.map((item, i) => (
              <TableRow key={i}>
                <TableCell sx={{ borderBottom: 0 }}>
                  <Box
                    sx={{
                      position: "relative",
                      height: "5rem",
                      right: "-2rem",
                      width: "100%",
                      textAlign: "right",
                      "&::after": props.isProjectTimeline ?
                        i === props.timelineItems!.length - 1
                          ? ""
                          : {
                              content: '""',
                              position: "absolute",
                              width: "1px",
                              height: "2.2rem",
                              bottom: "-2.1rem",
                              right: "2rem",
                              backgroundColor: "#00000020",
                            } : "",
                    }}
                  >
                    {props.isProjectTimeline && (
                      <Box
                        component="img"
                        src={getTimelineIcon(item)}
                        alt="Project"
                        sx={{
                          // position: 'absolute',
                          width: "4rem",
                          mt: "0.6rem",
                        }}
                      />
                    )}
                  </Box>
                </TableCell>

                {props.timelineColumns.map((col, j) => (
                  <TableCell
                    key={j}
                    sx={{
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                      whiteSpace: "normal",
                      fontSize: "1.37rem",
                    }}
                  >
                    {(col === TimelineColumn.STAGE ||
                      col === TimelineColumn.PROJECT ||
                      col === TimelineColumn.DESCRIPTION) && (
                      <Typography
                        sx={{
                          fontSize: "1.37rem",
                          fontWeight: "bold",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.title}
                      </Typography>
                    )}

                    {col === TimelineColumn.DATE && (
                      <>
                        {item.date && !isNaN(item.date) && (
                          <Stack direction="row">
                            <Typography
                              fontSize={"1.15rem"}
                              fontWeight="bold"
                              color="#404040B2"
                            >
                              {item.date
                                ? item.date.toFormat("EEEE, ")
                                : "Invalid date"}
                            </Typography>
                            <Typography fontSize={"1.15rem"} color="#404040B2">
                              &nbsp;
                              {item.date
                                ? item.date.toFormat("LLL dd, yyyy")
                                : "Invalid date"}
                            </Typography>
                          </Stack>
                        )}
                      </>
                    )}

                    {col === TimelineColumn.FILE && (
                      <Typography
                        fontFamily="Helvetica Neue"
                        fontWeight="bold"
                        fontSize={"1.45rem"}
                      >
                        {item.name}
                      </Typography>
                    )}

                    {col === TimelineColumn.FILE_TYPE && (
                      <Typography
                        fontFamily="Helvetica Neue"
                        fontSize="1.25rem"
                      >
                        {simplifyMimeType(item?.mimeType ?? "")}
                      </Typography>
                    )}

                    {col === TimelineColumn.PRICE && (
                      <Typography variant="body2" fontWeight="bold">
                        {item.price !== undefined &&
                          formatMoney(item.price, currencyCode)}
                      </Typography>
                    )}

                    {col === TimelineColumn.VIEW_PROJECT && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#75B3FF",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                        component={Link}
                        to={item.viewPath!}
                      >
                        View
                      </Typography>
                    )}

                    {col === TimelineColumn.VIEW_FILE && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#75B3FF",
                          cursor: "pointer",
                          textDecoration: "none",
                          mr: "-4rem",
                        }}
                        onClick={() =>
                          item.viewFileHandler!(
                            item.name ?? "no-name",
                            item.uuid ?? "",
                            item.mimeType ?? ""
                          )
                        }
                      >
                        View
                      </Typography>
                    )}

                    {col === TimelineColumn.THREE_DOTS_FILES && (
                      <ThreeDotsFile
                        fileUuid={item.uuid ?? ""}
                        fileName={item.name ?? "mynk file"}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>

      {props.isDataLoading && (
        <CircularProgress sx={{ display: "table", mx: "auto", my: 3 }} />
      )}

      {props.timelineAction && (
        <Typography
          variant="body2"
          sx={{ color: "#75b3ff", mt: 2, cursor: "pointer" }}
          align="center"
          onClick={props.onTimelineActionClick}
        >
          {props.timelineAction}
        </Typography>
      )}
    </Card>
  );
}

export interface ViewGeneralLayoutProps extends DetailsCardsProps {
  loading?: boolean;
  timeline?: TimelineCardProps;
  mainContent?: ReactNode;
}

export default function ViewGeneralLayout(props: ViewGeneralLayoutProps) {
  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={8.33}
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: "45rem",
        }}
      >
        {props.loading && (
          <CircularProgress sx={{ display: "table", mx: "auto", my: 1 }} />
        )}
        {!props.loading && props.timeline && (
          <TimelineCard {...props.timeline} />
        )}
        {!props.loading && props.mainContent}
      </Grid>

      <Grid
        item
        xs={3.66}
        sx={{ display: "flex", flexDirection: "column", flex: 1 }}
      >
        {!props.loading && <DetailsCards {...(props as DetailsCardsProps)} />}
        {props.loading && (
          <Card sx={{ p: 2 }}>
            <CircularProgress sx={{ display: "table", mx: "auto", my: 1 }} />
          </Card>
        )}
      </Grid>
    </Grid>
  );
}
