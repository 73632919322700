import {atom, useAtom, useSetAtom} from "jotai";
import {useCallback, useEffect, useState} from "react";
import {useGetMoreMessagesQuery, useNewConversationMutation, usePromptMutation} from "../api/ai";
import produce from "immer";
import {
  ConvoMessage,
  GetMoreMessagesParams,
  InvoiceDetails, InvokedTool,
  UserRequiredAction,
  UserRequiredActionKind
} from "../api/types";
import {useSnackbar} from "notistack";
import {useQueryClient} from "@tanstack/react-query";


export interface ConversationPrompt {
  prompt?: null | string;
  prompt_time?: null | Date;
  answer?: null | string;
  answer_time?: null | Date;
  extra?: {
    invoice: InvoiceDetails;
  }
}

export interface AIConversation {
  uuid: string;
  history: ConversationPrompt[];
  startIndex?: number;
}

export const currentConversationAtom = atom<null | AIConversation>(null);


function convertMessagesToConversationPrompts(messages: ConvoMessage[]): ConversationPrompt[] {
  const result: ConversationPrompt[] = [];

  for (let i = 0; i + 1 < messages.length; i += 2) {
    result.push({
      prompt: messages[i].text,
      prompt_time: new Date(messages[i].time * 1000),
      answer: messages[i + 1].text,
      answer_time: new Date(messages[i + 1].time * 1000),
    });
  }

  return result;
}



export const confirmedInvoiceToSendAtom = atom<null | InvoiceDetails>(null);


export function useAIConversation(enabled: boolean) {
  const [currentConv, updateCurrentConv] = useAtom(currentConversationAtom);

  const newConversation = useNewConversationMutation();
  useEffect(() => {
    if (!enabled)
      return;

    if (!currentConv) {
      newConversation.mutate(undefined, {
        onSuccess(data) {
          const history = convertMessagesToConversationPrompts(data.messages ?? []);

          updateCurrentConv({
            uuid: data.uuid,
            startIndex: data.start_index ?? undefined,
            history,
          });
        }
      });
    }
  }, [enabled, currentConv]);

  const updatePendingInvoiceToSend = useSetAtom(confirmedInvoiceToSendAtom);
  const handleUserRequiredAction = useCallback(async (action: UserRequiredAction) => {
    switch (action.action) {
      case UserRequiredActionKind.SEND_CONFIRMED_INVOICE:
        // updatePendingInvoiceToSend(action.details);
        break;
    }
  }, []);

  const handleInvokedTool = useInvokedToolHandler();

  const promptMutation = usePromptMutation();
  const addPrompt = useCallback(async (prompt: string) => {
    console.log('addPrompt', prompt, currentConv?.uuid);
    if (!currentConv?.uuid)
      return;

    // add question
    updateCurrentConv((conv: null | AIConversation) => produce(conv, (draft) => {
      if (!draft)
        return null;

      draft.history.push({
        prompt,
        prompt_time: new Date(),
      });
    }));

    const response = await promptMutation.mutateAsync({
      conversation_id: currentConv.uuid,
      prompt,
      user_time: (new Date()).getTime() / 1000,
    });

    updateCurrentConv((conv: null | AIConversation) => produce(conv, (draft) => {
      if (!draft)
        return null;

      const pendingInvoiceDetails = response.actions.find(
        (action) => action.action === UserRequiredActionKind.CONFIRM_PENDING_INVOICE
      )?.details;

      if (pendingInvoiceDetails) {
        // send confirmation question
        draft.history.push({
          ...draft.history.pop()!,
          answer: response.answer,
          answer_time: new Date(),
          extra: {
            invoice: pendingInvoiceDetails,
          },
        })
      } else {
        draft.history.push({
          ...draft.history.pop()!,
          answer: response.answer,
          answer_time: new Date(),
        });
      }
    }));

    // handle actions
    for (const action of response.actions) {
      await handleUserRequiredAction(action);
    }

    // handle successful tool invocations
    for (const tool of response.invoked_tools) {
      await handleInvokedTool(tool);
    }
  }, [currentConv?.uuid]);

  const [moreMessagesRequest, setMoreMessagesRequest] = useState<null | GetMoreMessagesParams>(null);
  const moreMessages = useGetMoreMessagesQuery(moreMessagesRequest ?? undefined);

  const requestMoreMessages = useCallback((count: number) => {
    if (!currentConv || !currentConv.startIndex)
      return;

    const endIndex = currentConv.startIndex;
    const startIndex = Math.max(endIndex - count, 0);

    setMoreMessagesRequest({
      conversation_id: currentConv.uuid,
      start_index: startIndex,
      end_index: endIndex,
    });
  }, [currentConv]);

  useEffect(() => {
    if (moreMessages.isPending || !moreMessages.data)
      return;

    if (moreMessages.data.end_index !== currentConv?.startIndex)
      return;

    updateCurrentConv((conv: null | AIConversation) => produce(conv, (draft) => {
      if (!draft)
        return;

      draft.history.splice(0, 0, ...convertMessagesToConversationPrompts(moreMessages.data.messages));
      draft.startIndex = moreMessages.data.start_index ?? undefined;
    }));
  }, [moreMessages.data, moreMessages.isPending]);

  return {
    conversation: currentConv,
    isLoading: newConversation.isPending,
    addPrompt,
    requestMoreMessages,
    thinking: promptMutation.isPending,
  };
}


function useInvokedToolHandler() {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useCallback(async (tool: InvokedTool) => {
    if (tool.snackbar) {
      enqueueSnackbar(tool.snackbar, { variant: 'success' })
    }

    switch (tool.tool_name) {
      case 'add_client':
      case 'edit_client':
        await queryClient.invalidateQueries({ queryKey: ["client-list"] });
        await queryClient.invalidateQueries({ queryKey: ["client-stats"] });
        await queryClient.invalidateQueries({ queryKey: ["search-contacts"] });
        break;

      case 'add_project':
      case 'edit_project':
        await queryClient.invalidateQueries({ queryKey: ["project-list"] });
        await queryClient.invalidateQueries({ queryKey: ["stage-stats"] });
        break;

      case 'add_expense':
        await queryClient.invalidateQueries({queryKey: ["expenses-list"]});
        await queryClient.invalidateQueries({queryKey: ["expenses-list-all"]});
        break;
    }
  }, []);
}


