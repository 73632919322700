import {useMemo} from 'react'
import { Navigate } from 'react-router-dom'
import { checkIsMobile } from '../util/mobile'

function FirstNavigate() {
  const mobile = useMemo(() => checkIsMobile(), []);

  return (
    <>
      {mobile ? (
        <Navigate to="/login" />
      ) : (
        <Navigate to="/photography/dashboard/home"/>
      )}
    </>
  )
}

export default FirstNavigate
