import {useCallback} from "react";
import {useTemplateAtoms} from "./TemplateContext";
import {useReadAtom} from "../../../util/common";
import {useRenderTemplateMutation} from "../../../api/templates";


export function useDownloadTemplatePDF(
  pdfName: string,
  templateUuid?: string,
) {
  const atoms = useTemplateAtoms();
  const readDetails = useReadAtom(atoms.detailsAtom);
  const readRecords = useReadAtom(atoms.recordsAtom);

  const renderTemplate = useRenderTemplateMutation();

  return useCallback(async () => {
    if (!templateUuid)
      return;

    const details = await readDetails();
    const records = await readRecords();

    const blob = await renderTemplate.mutateAsync({
      template_uuid: templateUuid,
      details,
      records,
    });

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = pdfName;
    a.click();
    URL.revokeObjectURL(url);
  }, [templateUuid, pdfName]);
}


// export function useDownloadTemplatePDF_clientSideOld(
//   pdfName: string,
//   templateRef?: null | TemplateRef,
// ) {
//   return useCallback(async () => {
//     if (!templateRef) return;
//
//     //
//     // download as PDF
//     //
//     const rendering = await templateRef.renderTemplate();
//     if (!rendering) return;
//
//     const base64PngData = rendering.data;
//
//     // save the png image to a pdf using jsPdf
//     const pdf = new jsPDF("p", "pt", [595, 842]);
//     pdf.addImage(base64PngData, "PNG", 0, 0, 595, 842);
//
//     // download the pdf
//     pdf.save(pdfName);
//   }, [templateRef, pdfName]);
// }


