import {
  AppBar,
  Box,
  Button,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { openSidebarAtom, sidebarWidth } from "../Sidebar";
import { useWhoamiQuery } from "../../api/auth";
import { User } from "../../api/types";
import React, { useState } from "react";
import magicStickIcon from "../../assets/icons/magic-stick-icon.svg";
import ChatbotDialog from "../ChatbotDialog";
import UserControlPopup from "./UserControlPopup";
import ProfilePicture from "../ProfilePicture";
import { PhotoType } from "../../pages/CommonComponents/Settings/common/UploadOnePhoto";
import {useHasActiveProSubscription} from "../../util/hooks";
import { useAtom, useAtomValue } from "jotai";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { LanguagePicker, languageAtom, LanguageCode  } from "../LanguagePicker";

function AIExpertButton() {
  const [openChat, setOpenChat] = useState(false);

  const language = useAtomValue(languageAtom);
  const isHebrew = language === LanguageCode.Hebrew;

  return (
    <>
      <ChatbotDialog open={openChat} onClose={() => setOpenChat(false)} />
      <IconButton
        sx={{
          border: "0.1rem rgba(64, 64, 64, 0.10) solid",
          borderRadius: "10rem",
          height: "4rem",
          width: "13rem",
          boxShadow: "0px 3px 5px rgba(105, 179, 255, 0.15)",
          paddingTop: 0.2,
          paddingBottom: 0.2,
          paddingLeft: 1,
          paddingRight: 3,
        }}
        onClick={() => setOpenChat(true)}
      >
        <Stack direction={isHebrew ? "row-reverse" : "row"} alignItems={'center'}>
          <Box
            component="img"
            src={magicStickIcon}
            sx={{ width: "3.2rem", height: "3.2rem" }}
          />
          <Typography fontSize="1.3rem">
            {isHebrew ? "אסיסטנט חכם" : "AI Assistant"}
          </Typography>
        </Stack>
      </IconButton>
    </>
  );
}

interface UserInfoProps {
  user: User;
}

function UserInfo(props: UserInfoProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <ProfilePicture type={PhotoType.PROFILE} onClick={handleClick} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <UserControlPopup />
      </Popover>
    </Stack>
  );
}

export default function MainAppBar() {
  const { data } = useWhoamiQuery();

  const [aiOpen, setAiOpen] = useState(false);

  const [openedSidebar, setOpenedSidebar] = useAtom(openSidebarAtom);
  const hasPro = useHasActiveProSubscription();

  const language = useAtomValue(languageAtom);
  const isHebrew = language === LanguageCode.Hebrew;
  const textDirection = isHebrew ? "rtl" : "ltr";

  return (
    <>
      {/*<ChatDialog open={aiOpen} onClose={() => setAiOpen(false)} />*/}

      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: `calc(100% - ${openedSidebar ? sidebarWidth : sidebarWidth / 3.7}rem)`,
          transition: "width 0.5s ease, margin-left 0.5s ease, margin-right 0.5s ease",
          ml: `${isHebrew ? 0 : openedSidebar ? sidebarWidth : sidebarWidth / 3.7}rem`,
          mr: `${isHebrew ? openedSidebar ? sidebarWidth : sidebarWidth / 3.7 : 0}rem`,
          height: "6.5rem",
          boxShadow: "0px 1px 5px #ccc !important",
          background: "white",
          direction: textDirection, // Set text direction
        }}
      >
        <Stack
          sx={{ width: "100%", height: "100%" }}
          direction={"row"}
          alignItems="center"
        >
          <Box
            onClick={() => setOpenedSidebar(!openedSidebar)}
            sx={{
              bgcolor: "#cd8bf2",
              borderRadius: isHebrew ? "10rem 0 0 10rem" : "0 10rem 10rem 0", // Adjust border-radius based on language
              height: "3rem",
              width: "1.6rem",
              pr: isHebrew ? 0 : "1rem", // Adjust padding based on language
              pl: isHebrew ? "1rem" : 0,
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              position: "relative",
              "&::before": {
                content: "''",
                position: "absolute",
                top: "-2rem",
                left: isHebrew ? "auto" : 0, // Flip position for Hebrew
                right: isHebrew ? 0 : "auto", // Flip position for Hebrew
                height: "2rem",
                width: "2rem",
                borderBottomLeftRadius: isHebrew ? "0" : "0.4rem", // Adjust for Hebrew
                borderBottomRightRadius: isHebrew ? "0.4rem" : "0", // Adjust for Hebrew
                boxShadow: isHebrew
                  ? "1rem 1rem 0 0 #cd8bf2" // Reverse shadow for Hebrew
                  : "-1rem 1rem 0 0 #cd8bf2",
              },
              "&::after": {
                content: "''",
                position: "absolute",
                bottom: "-2rem",
                left: isHebrew ? "auto" : 0, // Flip position for Hebrew
                right: isHebrew ? 0 : "auto", // Flip position for Hebrew
                height: "2rem",
                width: "2rem",
                borderTopLeftRadius: isHebrew ? "0" : "0.4rem", // Adjust for Hebrew
                borderTopRightRadius: isHebrew ? "0.4rem" : "0", // Adjust for Hebrew
                boxShadow: isHebrew
                  ? "1rem -1rem 0 0 #cd8bf2" // Reverse shadow for Hebrew
                  : "-1rem -1rem 0 0 #cd8bf2",
              },
            }}
          >
            {openedSidebar ? (
              isHebrew ? (
                <ArrowForwardIosIcon
                  sx={{ width: "1.4rem", mr: "0.3rem", color: "white" }} // Adjust margin for Hebrew
                />
              ) : (
                <ArrowBackIosNewIcon
                  sx={{ width: "1.4rem", ml: "0.3rem", color: "white" }} // Adjust margin for English
                />
              )
            ) : isHebrew ? (
              <ArrowBackIosNewIcon
                sx={{ width: "1.4rem", ml: "0.3rem", color: "white" }} // Adjust margin for Hebrew
              />
            ) : (
              <ArrowForwardIosIcon
                sx={{ width: "1.4rem", mr: "0.3rem", color: "white" }} // Adjust margin for English
              />
            )}
          </Box>

          <Box sx={{ width: "2rem" }} />

          {/* AI Expert Button */}
          {hasPro && <AIExpertButton />}

          {/* Spacer */}
          <Box sx={{ flex: 1 }} />

          {/* Language Picker */}
          <Box mr={isHebrew ? 0 : 2} ml={isHebrew ? 2 : 0}>
            <LanguagePicker />
          </Box>

          {/* User Info */}
          <Box ml={isHebrew ? 7 : 0} mr={isHebrew ? 0 : 7}>
            {data?.user && <UserInfo user={data.user} />}
          </Box>
        </Stack>
      </AppBar>
    </>
  );
}
