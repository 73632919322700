import {Box, CircularProgress, SxProps} from '@mui/material';
import React, {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {atom, useAtom, useAtomValue} from 'jotai';
import {DomState, makeEmptyDomState, TemplateRef} from './common';
import {useAtomCallback} from 'jotai/utils';
import * as htmlToImage from 'html-to-image';

import './template-common.css';

// @ts-ignore
import nunjucks from 'nunjucks';
import useResizeObserver from 'use-resize-observer';
import {useTemplateAtoms} from "./TemplateContext";
import {useAddUpdateRecord, useTemplateHooks} from "./commonHooks";

import ContractRightButtonIcon from "../../../assets/icons/contract-right-button.svg";
import ContractLeftButtonIcon from "../../../assets/icons/contract-left-button.svg";

export const wantToRenderAtom = atom(false);

interface TemplateContainerProps {
  sx?: SxProps;
  children?: ReactNode;
}

function TemplateContainer(props: TemplateContainerProps) {
  return (
    <Box
      sx={{
        background: 'white',
        boxShadow: '5px 5px 35px #75b3ff59',
        width: '50%',
        ...props.sx,
        overflow: 'none',
        mb: '1rem',
      }}
    >
      <div
        style={{
          paddingBottom: '141%',
          position: 'relative',
        }}
      >
        <div style={{position: 'absolute', inset: 0,}}>
          {props.children}
        </div>
      </div>
    </Box>
  );
}

export interface TemplateProps {
  template: string;
  sx?: SxProps;
  live?: boolean;
  onMakeRef?: (ref: TemplateRef) => void;
  realSize?: { width: number | string, height: number | string };
}

function checkElementInsideSelectable(el: HTMLElement) {
  let curr: null | HTMLElement = el;
  let i = 0;
  while (curr && i++ < 20) {
    if (curr.classList?.contains?.('t-movable'))
      return true;

    curr = curr.parentElement;
  }

  return false;
}

export interface TemplateRenderResult {
  data: string;
}

function Template_(props: TemplateProps, ref: ForwardedRef<TemplateRef>) {
  const live = props.live ?? false;
  const templateAtoms = useTemplateAtoms()

  const [rerenderCounter, setRerenderCounter] = useState(0);
  const [page, setPage] = useState<number>(0);

  const records = useAtomValue(templateAtoms.recordsAtom);
  const templateData = useAtomValue(templateAtoms.dataAtom);
  const html = useMemo(() => {
    nunjucks.configure({autoescape: true});

    function getClasses(id: string) {
      return live ? 't-live' : '';
    }

    function addStyles(id: string) {
      const relevantRecords: { [key: string]: string } = {};
      for (const record of records) {
        if (record.id === id) {
          relevantRecords[record.key] = record.value;
        }
      }
      console.log('relevantRecords', records);

      return Object.entries(relevantRecords).map(entry => `${entry[0]}: ${entry[1]}; `).join('');
    }

    function getRemovedClasses(id: string) {
      if (templateData.data.hasOwnProperty('services')) {
        const services = templateData.data.services;
        const index = services.indexOf(id);

        if (index !== -1) {
          return 'removed';
        }
      }
    }

    function getNumbersServices(servicesCount: number) {
      if (!templateData.data.hasOwnProperty('services')) {
        return {};
      }

      const removedServices = templateData.data.services.filter((service: string) => service && !service.includes('-'));
      const services = Array.from(document.querySelectorAll('.service')).filter(service => service.querySelector('h3'));
      const classNames = services.map(service => (service.className.split(' ')[0]));
      const filteredClassNames = classNames.filter(className => !removedServices.includes(className));
    
      const result: { [key: string]: number } = {};
      for (let i = 0; i < servicesCount && i < filteredClassNames.length; i++) {
        result[filteredClassNames[i]] = i + 1;
      }
      
      return result;
    }

    function getNumbersUnderServices(serviceClass: string) {
      if (!templateData.data.hasOwnProperty('services')) {
        return {};
      }
      
      const services = Array.from(document.querySelectorAll(`.${serviceClass}.service`));
      const listOfUnderServices = services.map(service => Array.from(service.querySelectorAll('.under-service')));
      const underServices = listOfUnderServices.flat();
    
      const removedUnderServices = templateData.data.services.filter((service: string) => service && service.includes('-'));
      const classNames = underServices.map(underService => underService.className.split(' ')[0]);
      const filteredClassNames = classNames.filter(className => !removedUnderServices.includes(className));
    
      const result: { [key: string]: number } = {};
      for (let i = 0; i < underServices.length && i < filteredClassNames.length; i++) {
        result[filteredClassNames[i].replace('-', '_')] = i + 1;
      }
      
      return result;
    }

    return nunjucks.renderString(props.template, {data: templateData?.data ?? {}, addStyles, getClasses, getRemovedClasses, getNumbersServices, getNumbersUnderServices});
  }, [templateData, templateData?.updates, rerenderCounter, live]);

  const tRef = useRef<HTMLDivElement | null>(null);
  // const domState = useRef<null | DomState>(null);
  const [domState, updateDomState] = useAtom(templateAtoms.domStateAtom);
  const readDomState = useAtomCallback(
    useCallback((get) => get(templateAtoms.domStateAtom), [])
  );

  const addRecord = useAddUpdateRecord();

  useEffect(() => {
    if (!tRef.current || !live)
      return;

    const state: DomState = domState ?? makeEmptyDomState();

    const movables = document.querySelectorAll('.templateContainer .t-movable');

    for (const elem of movables) {
      if (state.elements?.get?.(elem.id) === elem) {
        continue;
      }

      state.elements.set(elem.id, elem as HTMLElement);

      // restore selected element
      if (state.selectedElement?.id === elem.id) {
        state.selectedElement = elem as HTMLElement;
        elem.classList.add('t-selected');
      }

      // add events
      ((elem: HTMLElement) => {
        // add click event
        elem.addEventListener('click', async () => {
          const state = await readDomState();
          if (!state)
            return;

          if (state.selectedElement !== null) {
            state.selectedElement.classList.remove('t-selected');
          }

          elem.classList.add('t-selected');
          updateDomState({
            selectedElement: elem as HTMLElement,
          });
        });

        const dragHandler: ((e: MouseEvent) => void)[] = [];
        const changed = [false];

        // drag events
        elem.addEventListener('mousedown', async (e: Event) => {
          if (dragHandler.length > 0)
            return;
          if (!(await readDomState())?.canDrag)
            return;

          const startX = (e as MouseEvent).pageX;
          const startY = (e as MouseEvent).pageY;

          // get rem value in px
          const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);

          const style = window.getComputedStyle(elem, null);
          console.log('S', rem, style.left, style.right);
          let lastLeft = +style.left.replace('px', '') / rem;
          let lastTop = +style.top.replace('px', '') / rem;
          if (isNaN(lastLeft))
            lastLeft = 0;
          if (isNaN(lastTop))
            lastTop = 0;
          // get the scaling factor (in the transform attribute) of the tRef.current's
          // immediate child (whose class is "template")
          const transform = window.getComputedStyle(tRef.current?.querySelector("div > .mynk-template") as HTMLElement, null).transform;
          // the transform attribute is of the form "matrix(a, b, c, d, tx, ty)"
          // set the scaling factor to be a
          const scale = +transform.split(',')[0].split('(')[1] ?? 1;
          elem.classList.add('t-drag');
          dragHandler.length = 0;
          dragHandler.push((e: MouseEvent) => {
            const leftDelta = (e as any).pageX - startX;
            const topDelta = (e as any).pageY - startY;

            (elem as HTMLElement).style.left = (lastLeft + leftDelta / rem /scale) + 'rem';
            (elem as HTMLElement).style.top = (lastTop + topDelta / rem / scale) + 'rem';
            changed[0] = (leftDelta != 0) || (topDelta != 0);
          });

          document.addEventListener('mousemove', dragHandler[0] as any);
        });

        document.addEventListener('mouseup', () => {
          // remove mousemove handler
          if (dragHandler.length > 0) {
            elem.classList.remove('t-drag');

            document.removeEventListener('mousemove', dragHandler[0] as any);
            dragHandler.length = 0;

            // record change
            if (changed[0]) {
              const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
              const style = window.getComputedStyle(elem, null);
              addRecord('left', (+style.left.replace('px', '') / rem) + 'rem', elem);
              addRecord('top', (+style.top.replace('px', '') / rem) + 'rem', elem);
            }
          }
        });
      })(elem as HTMLElement);
    }
    
    updateDomState(state);

    if (!tRef.current)
      return;

    const pages = tRef.current?.querySelectorAll('.t-page');

    for (let i = 0; i < pages.length; i++) {
      const pageElem = pages[i] as HTMLElement;
      pageElem.style.display = page === i ? 'block' : 'none';
    }
  }, [html, page]);

  const onTemplateClick = (e: React.MouseEvent) => {
    const el = (e.target as HTMLElement);
    const isSelectable = checkElementInsideSelectable(el);
    if (!isSelectable) {
      if (domState?.selectedElement) {
        domState.selectedElement.classList.remove('t-selected');
      }

      updateDomState({
        selectedElement: null,
      });
    }
  };

  const templateHooks = useTemplateHooks();
  const [wantToRender, setWantToRender] = useAtom(wantToRenderAtom);

  const renderTemplate = useCallback(async () => {
    setWantToRender(true);
    await templateHooks.deselectAll();

    const result: string = await new Promise((resolve) => {
      setTimeout(async () => {
        resolve(await htmlToImage.toJpeg(tRef.current!));
      }, 10);
    });
    setWantToRender(false);

    // // tRef.current is a Box element that contains a single svg element
    // // render the SVG element to an image using canvg library
    // const svgElem = (tRef.current! as HTMLElement).querySelector('svg');
    // if (!svgElem)
    //   return null;
    //
    // // render svgElem
    // const canvas = document.createElement('canvas');
    // canvas.width = 2480;
    // canvas.height = 3508;
    // const ctx = canvas.getContext('2d');
    // if (!ctx)
    //   return null;
    //
    // Canvg.fromString(ctx, svgElem.outerHTML, {
    //   ignoreMouse: true,
    //   ignoreAnimation: true,
    //   ignoreClear: true,
    //   offsetX: 0,
    //   offsetY: 0,
    //   scaleWidth: 2480,
    //   scaleHeight: 3508,
    // });
    //
    // // capture data URI of rendered image as PNG
    // const result = canvas.toDataURL('image/png');

    return {
      data: result,
    };
  }, []);

  const rerender = useCallback(() => {
    setRerenderCounter(x => x + 1);
  }, [setRerenderCounter]);

  const madeRef = useRef<boolean>(false);
  useImperativeHandle(ref, () => {
    const ref: TemplateRef = {
      renderTemplate,
      rerender,
    };

    if (!madeRef.current) {
      madeRef.current = true;
      props.onMakeRef?.(ref);
    }

    return ref;
  });

  // scale the contents of the template to fit the container
  const {
    width: containerWidth,
    height: containerHeight,
  } = useResizeObserver<HTMLDivElement>({ref: tRef});

  useLayoutEffect(() => {
    if (!tRef.current || !containerWidth || !containerHeight)
      return;

    const containerElem = tRef.current as HTMLElement;

    // find the immediate child of tRef.current.
    // this child has the class name "template"
    const templateElem = containerElem.querySelector('.mynk-template');  // NOTE: Changed from ".template"

    if (props.realSize) {
      templateElem.style.width = props.realSize.width;
      templateElem.style.height = props.realSize.height;
    }

    if (!templateElem)
      return;

    // get templateElem's width and height in points:
    const templateWidth = templateElem.clientWidth;
    const templateHeight = templateElem.clientHeight;

    // apply a scale transform to templateElem so that it fits neatly
    // inside its parent container (tRef.current)
    const scale = Math.min(containerWidth / templateWidth, containerHeight / templateHeight);
    (templateElem as HTMLElement).style.transform = `scale(${scale})`;

    // Update arrow button sizes
    const arrowButtonSize = 4 * scale; // Adjust this multiplier as needed

    // Left arrow button
    document.querySelector('.left-arrow')?.setAttribute('style', `
      width: ${arrowButtonSize}rem;
      height: ${arrowButtonSize}rem;
      margin-left: -${arrowButtonSize / 2}rem;
    `);

    // Right arrow button
    document.querySelector('.right-arrow')?.setAttribute('style', `
      width: ${arrowButtonSize}rem;
      height: ${arrowButtonSize}rem;
      margin-right: -${arrowButtonSize / 2}rem;
    `);
  }, [html, containerWidth, containerHeight]);

  return (
    <TemplateContainer
      sx={{ ...props.sx, position: "relative", overflow: "auto"}}
    >
      <Box
        className='left-arrow'
        sx={{
          display: page > 0 ? "block" : "none",
          cursor: "pointer",
          position: "absolute",
          left: 0,
          top: "50%",
          transform: "translateY(-50%)",
          marginLeft: "-2rem",
          zIndex: 1,
          width: "4rem",
          height: "4rem",
        }}
        component={"img"}
        src={ContractLeftButtonIcon}
        onClick={() => setPage(prevPage => prevPage - 1)}
      />

      <Box
        sx={{
          height: wantToRender ? "4230px" : "100%",
          width: wantToRender ? "3000px" : undefined,
          useSelect: 'none',
          overflow: 'hidden',
        }}
        className="templateContainer"
        dangerouslySetInnerHTML={{ __html: html }}
        onClick={onTemplateClick}
        ref={tRef}
      />

      <Box
        className='right-arrow'
        sx={{
          display: tRef.current && page < tRef.current?.querySelectorAll('.t-page').length - 1 ? "block" : "none",
          cursor: "pointer",
          position: "absolute",
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",
          marginRight: "-2rem",
          width: "4rem",
          height: "4rem",
        }}
        component={"img"}
        src={ContractRightButtonIcon}
        onClick={() => setPage(prevPage => prevPage + 1)}
      />
    </TemplateContainer>
  );
}

export const Template = forwardRef(Template_);
