import {
  GetDefaultTemplateParams,
  GetDefaultTemplateResponse,
  GetTemplatesParams,
  GetTemplatesResponse,
  ListDefaultTemplatesParams,
  ListDefaultTemplatesResponse,
  ListSentTemplatesParams,
  ListTemplatesParams,
  ListTemplatesResponse, RenderTemplateParams,
  SaveTemplateParams,
  SaveTemplateResponse,
  SendTemplateParams,
  SendTemplateResponse,
  SetDefaultTemplateParams,
  SetDefaultTemplateResponse,
  SendPdfParams,
  SendPdfResponse
} from "./types";
import { useMutation, useQuery, useQueryClient, UseMutationOptions } from "@tanstack/react-query";
import axios from "axios";

export function useGetTemplateQuery(params: GetTemplatesParams) {
  return useQuery<GetTemplatesResponse>({
    queryKey: ["template", params.uuid],
    queryFn: async () =>
      (await axios.get("/api/templates/get", { params })).data,
  });
}

export function useListTemplatesQuery(
  params: ListTemplatesParams,
  enabled?: boolean
) {
  return useQuery<ListTemplatesResponse>({
    queryKey: ["template-list", params],
    queryFn: async () =>
      (await axios.get("/api/templates/list", { params })).data,

    enabled: enabled ?? true,
  });
}

export function useListDefaultTemplatesQuery(
  params: ListDefaultTemplatesParams,
  enabled?: boolean
) {
  return useQuery<ListDefaultTemplatesResponse>({
    queryKey: ["default-template-list", params],
    queryFn: async () =>
      (await axios.get("/api/templates/list-defaults", { params })).data,

    enabled: enabled ?? true,
  });
}

export function useGetDefaultTemplateQuery(params: GetDefaultTemplateParams) {
  return useQuery<GetDefaultTemplateResponse>({
    queryKey: ["default-template", params.template_kind],
    queryFn: async () =>
      (await axios.get("/api/templates/get-default", { params })).data,
  });
}

export function useSetDefaultTemplateMutation() {
  const queryClient = useQueryClient();
  return useMutation<
    SetDefaultTemplateParams,
    unknown,
    SetDefaultTemplateResponse
  >({
    mutationFn: async (params) =>
      (await axios.post("/api/templates/set-default", params)).data,

    async onSuccess(params) {
      await queryClient.invalidateQueries({queryKey: ["default-template"]});
      await queryClient.invalidateQueries({queryKey: ["default-template-list"]});
    }
  });
}


export function useSaveTemplateMutation() {
  const queryClient = useQueryClient();
  return useMutation<SaveTemplateResponse, unknown, SaveTemplateParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/templates/save", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["template-list"] });
    },
  });
}

export function useSendTemplateMutation(
  options: UseMutationOptions<SendTemplateResponse, unknown, SendTemplateParams>
) {
  const queryClient = useQueryClient();
  return useMutation<SendTemplateResponse, unknown, SendTemplateParams>({
    mutationFn: async (params) =>
      (
        await axios.post("/api/templates/send", params.form_data, {
          params: {
            template_uuid: params.template_uuid,
            project_uuid: params.project_uuid,
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      ).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["sent-templates"]});
    },
    ...options
  });
}


export function useListSentTemplatesQuery(
  params: ListSentTemplatesParams,
  enabled?: boolean
) {
  return useQuery<ListSentTemplatesParams>(
    {
      queryKey: ["sent-templates", params],
      queryFn: async () => (await axios.post("/api/templates/sent/list",  params)).data,

      enabled: enabled ?? true,
    }
  );
}


export function useRenderTemplateMutation() {
  return useMutation<Blob, unknown, RenderTemplateParams>({
    mutationFn: async (params) =>
      // new Blob([
      //   (await axios.post("/api/templates/render_image", params, { responseType: 'blob' })).data
      // ], { type: "image/png" }),
      new Blob([
        (await axios.post("/api/templates/render_pdf", params, { responseType: 'blob' })).data
      ], { type: "application/pdf" }),
  });
}