import { Grid, Typography, Stack, Divider } from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import { useAddEventMutation, useEditEventMutation } from "../api/scheduler";
import { EditEventParams, WorkflowType } from "../api/types";
import { AxiosError } from "axios";
import { useSetNewFileNameMutation } from "../api/files";

export interface EditFileNameDialogProps {
  open: boolean;
  onClose: () => void;
  file_uuid?: string;
}

export function EditFileNameDialog(props: EditFileNameDialogProps) {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const setNewFileName = useSetNewFileNameMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, EditEventParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        setErrorMsg(detail ?? "");
      }
    },
  });

  const handleSubmit = async (values: any) => {
    await setNewFileName.mutateAsync({
      file_uuid: props.file_uuid ?? "",
      new_name: values["name"],
    });
    props.onClose();
  };

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      title={"Set new file name"}
      action="Apply changes"
      onSubmit={handleSubmit}
      loading={setNewFileName.isPending}
      errorMsg={errorMsg}
    >
      <Stack
        direction="row"
        sx={{
          width: "26rem",
          maxHeight: "33rem",
        }}
      >
        <Grid container spacing={"1rem"} alignItems="center">
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="name"
              label="New file name"
              placeholder="Enter name"
              autoComplete={false}
              default={""}
            />
          </Grid>
        </Grid>
      </Stack>
    </MynkFormDialog>
  );
}
