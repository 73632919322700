import {
  Box,
  IconButton,
  Popover,
  Stack,
  SxProps,
  TextField,
  Typography,
  Popper
} from "@mui/material";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "../../../../components/SimpleTable";
import { StackTableCard } from "../../../../components/StackTable/StackTableCard";
import editIcon from "../../../../assets/icons/edit.svg";
import { ListGeneralSettingsResponse } from "../../../../api/types";
import { useEffect, useMemo, useState } from "react";
import { useListGeneralSettingsQuery } from "../../../../api/settings";
import { closeSnackbar, useSnackbar } from "notistack";
import { EditGeneralSettingsParams } from "../../../../api/types";
import {
  useEditGeneralSettingsMutation,
  useGetDevicesHistoryQuery,
} from "../../../../api/settings";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { zonedDate } from "../../../../util/common";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import ElevatorDialog from "../../../../components/ElevatorDialog";
import { ChangeEmailDialog } from "../../../../dialogs/ChangeEmail";

const GENERAL_SETTINGS_COLUMNS: SimpleTableColumn[] = [
  {
    key: "setting",
    label: "Setting",
    type: SimpleTableColumnType.SETTING,
    hideHeader: true,
  },
  {
    key: "setting_val",
    label: "Value",
    type: SimpleTableColumnType.SETTING_VAL,
    hideHeader: true,
  },
];

export enum GENERAL_SETTING_NAMES {
  NAME = "Name",
  EMAIL = "Email",
  PHONE = "Phone number",
  DATE_OF_BIRTH = "Date of birth",
  B_NAME = "Business name",
  B_ID = "Business ID",
  B_ADDRESS = "Business address",
  B_PHONE = "Business phone",
  B_WEBSITE = "Business website",
  VAT_NUMBER = "Vat number",
  COUNTRY = "Country/Region",
  CURRENCY = "Currency",
  TIME_ZONE = "Time zone",
  INVOICE_START_NUMBER = "Invoice number",
}

function convertResponseToList(
  data: ListGeneralSettingsResponse
): Record<string, any>[] {
  const dateOfBirth = DateTime.fromISO(data.date_of_birth, { zone: "utc" });

  return [
    { setting: GENERAL_SETTING_NAMES.NAME, setting_val: data.user_name || "" },
    { setting: GENERAL_SETTING_NAMES.EMAIL, setting_val: data.email || "" },
    {
      setting: GENERAL_SETTING_NAMES.PHONE,
      setting_val: data.phone_number || "",
    },
    {
      setting: GENERAL_SETTING_NAMES.DATE_OF_BIRTH,
      setting_val:
        (data.date_of_birth &&
          DateTime.fromObject({
            year: dateOfBirth.year,
            month: dateOfBirth.month,
            day: dateOfBirth.day,
          }).toFormat("dd MMMM yyyy")) ||
        "",
    },
    {
      setting: GENERAL_SETTING_NAMES.B_NAME,
      setting_val: data.business_name || "",
    },
    {
      setting: GENERAL_SETTING_NAMES.B_ID,
      setting_val: data.business_id || "",
    },
    {
      setting: GENERAL_SETTING_NAMES.B_ADDRESS,
      setting_val: data.business_address || "",
    },
    {
      setting: GENERAL_SETTING_NAMES.B_PHONE,
      setting_val: data.business_phone || "",
    },
    {
      setting: GENERAL_SETTING_NAMES.B_WEBSITE,
      setting_val: data.business_website || "",
    },
    {
      setting: GENERAL_SETTING_NAMES.VAT_NUMBER,
      setting_val: data.vat_number || "",
    },
    { setting: GENERAL_SETTING_NAMES.COUNTRY, setting_val: data.country || "" },
    {
      setting: GENERAL_SETTING_NAMES.CURRENCY,
      setting_val: data.currency || "",
    },
    {
      setting: GENERAL_SETTING_NAMES.TIME_ZONE,
      setting_val: data.time_zone || "",
    },
    {
      setting: GENERAL_SETTING_NAMES.INVOICE_START_NUMBER,
      setting_val: data.invoice_start_number || 0,
    },
  ];
}

interface GeneralSettingsTableCardProps {
  data: Array<Record<string, any>>;
  setData: (newData: Record<string, any>[]) => void;
}

export function GeneralSettingsTableCard(props: GeneralSettingsTableCardProps) {
  const { data, isPending } = useListGeneralSettingsQuery({});
  const [save, setSave] = useState(false);
  const editGeneralSettings = useEditGeneralSettingsMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [changeEmailDialogOpen, setChangeEmailDialogOpen] = useState(false);
  const [newEmail, setNewEmail] = useState(data?.email);

  useEffect(() => {
    const handleSubmit = async () => {
      // Extract parameters (find in the list of dicts):
      const params: EditGeneralSettingsParams = {
        user_name: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.NAME
        )?.setting_val,
        phone_number: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.PHONE
        )?.setting_val,
        date_of_birth: dateOfBirth && dateOfBirth.toISO(),
        business_name: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.B_NAME
        )?.setting_val,
        business_id: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.B_ID
        )?.setting_val,
        business_address: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.B_ADDRESS
        )?.setting_val,
        business_phone: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.B_PHONE
        )?.setting_val,
        business_website: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.B_WEBSITE
        )?.setting_val,
        vat_number: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.VAT_NUMBER
        )?.setting_val,
        country: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.COUNTRY
        )?.setting_val,
        currency: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.CURRENCY
        )?.setting_val,
        time_zone: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.TIME_ZONE
        )?.setting_val,
        invoice_start_number: props.data.find(
          (item) => item.setting === GENERAL_SETTING_NAMES.INVOICE_START_NUMBER
        )?.setting_val,
      };
      
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([_, value]) => value !== null && value !== undefined && value !== ''
        )
      );
      
      console.log(filteredParams);
      await editGeneralSettings.mutateAsync(filteredParams);
      //useSetAtom(userCurrencyAtom);

      enqueueSnackbar("Settings saved successfully", {
        variant: "success",
        action: (key) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
        style: {
          borderRadius: "0.7rem",
        },
      });
    }
    if (save) {
      handleSubmit();
    }
    setSave(false);
  }, [save]);

  useEffect(() => {
    if (!isPending && data) {
      props.setData(convertResponseToList(data));
    }
  }, [data, isPending]);

  const [dateOfBirth, setDateOfBirth] = useState<DateTime | null>(null);

  const columns = useMemo(() => {
    const result = [
      ...GENERAL_SETTINGS_COLUMNS,
      {
        key: "",
        label: "",
        render(_: unknown, row: any) {
          const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
          const [dateAnchorEl, setDateAnchorEl] = useState<null | HTMLElement>(null);

          const [openDatePicker, setOpenDatePicker] = useState(false);

          // Open popover:
          const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (row.setting == GENERAL_SETTING_NAMES.DATE_OF_BIRTH) {
              setOpenDatePicker(true);
              setDateAnchorEl(event.currentTarget);
            } else if (row.setting == GENERAL_SETTING_NAMES.EMAIL) {
              setChangeEmailDialogOpen(true);
            } else {
              setAnchorEl(event.currentTarget);
            }
          };

          // Function to update a specific row in the table by the settingName.
          const updateSettingVal = (settingName: string, newValue: any) => {
            // If email changed -> open indicator dialog:
            if (settingName == GENERAL_SETTING_NAMES.EMAIL && newValue != data?.email) {
              setNewEmail(newValue);
            }
            else {
              props.setData((prevData: Record<string, any>[]) => {
                return prevData.map((item) => {
                  if (item.setting === settingName) {
                    return { ...item, setting_val: newValue };
                  }
                  return item;
                });
              });
            }
          };

          // Close popover:
          const handleClose = () => {
            setSave(true);
            setAnchorEl(null);
            setDateAnchorEl(null);
          };

          const open = Boolean(anchorEl);

          // Rendering the edit button and the small pop-over input.
          return (
            <>
              <IconButton sx={{ borderRadius: 3 }} onClick={handleClick}>
                <Box component="img" src={editIcon} width={"1.25rem"} />
              </IconButton>
      <LocalizationProvider dateAdapter={AdapterLuxon}>

              <DatePicker<Date>
                sx={{
                  height:" 20rem"
                }}
                slots={{
                  field: (slotProps: any) => <></>,
                }}
                slotProps={{
                  popper: {
                    anchorEl: dateAnchorEl,
                    placement: "bottom-end"
                  }
                }}
                open={openDatePicker}
                onClose={() => setOpenDatePicker(false)}
                onOpen={() => setOpenDatePicker(true)}
                value={dateOfBirth}
                onChange={(value: DateTime | null) => {
                  updateSettingVal(row.setting, "Setting date...");
                  setDateOfBirth(value.set({hour: 12}));
                  setOpenDatePicker(false);
                  handleClose();
                }}
              />
      </LocalizationProvider>

              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <TextField
                  variant="standard"
                  autoFocus={true}
                  autoComplete="off"
                  sx={{
                    margin: 1,
                    ml: 2,
                    "& .MuiInputBase-input": {
                      fontFamily: "Helvetica Neue",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  defaultValue={row.setting_val}
                  onChange={(e) => {
                    updateSettingVal(row.setting, e.target.value);
                  }}
                />
              </Popover>
            </>
          );
        },
      },
    ];

    return result;
  }, []);
  
  return (
    <Stack sx={{ width: "100%" }} spacing={3.3}>
      <ChangeEmailDialog open={changeEmailDialogOpen} onClose={() => setChangeEmailDialogOpen(false)} />
      <StackTableCard
        data={props.data.filter(
          // Give all general info related Settings.
          (item) =>
            ![
              GENERAL_SETTING_NAMES.B_ADDRESS,
              GENERAL_SETTING_NAMES.B_ID,
              GENERAL_SETTING_NAMES.B_NAME,
              GENERAL_SETTING_NAMES.B_PHONE,
              GENERAL_SETTING_NAMES.INVOICE_START_NUMBER,
              GENERAL_SETTING_NAMES.B_WEBSITE,
              GENERAL_SETTING_NAMES.VAT_NUMBER,
            ].includes(item.setting)
        )}
        paddingLevel={1.7}
        title={"General information"}
        sx={{ width: "100%" }}
        columns={columns}
        columnDivision={["45%", "55%"]}
      />
      <StackTableCard
        data={props.data.filter(
          // Give all business info related Settings.
          (item) =>
            [
              GENERAL_SETTING_NAMES.B_ADDRESS,
              GENERAL_SETTING_NAMES.B_ID,
              GENERAL_SETTING_NAMES.B_NAME,
              GENERAL_SETTING_NAMES.B_PHONE,
              GENERAL_SETTING_NAMES.INVOICE_START_NUMBER,
              GENERAL_SETTING_NAMES.B_WEBSITE,
              GENERAL_SETTING_NAMES.VAT_NUMBER,
            ].includes(item.setting)
        )}
        paddingLevel={1.7}
        title={"Business information"}
        sx={{ width: "100%" }}
        columns={columns}
        columnDivision={["45%", "55%"]}
      />
    </Stack>
  );
}
