import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchContactsQuery } from "../api/clients";
import { AddPhotographyContactDialog } from "../dialogs/AddPhotographyContact";
import { AddRealEstateContactDialog } from "../dialogs/AddRealEstateContact";
import { ClientInfo, ClientSummary, WorkflowType } from "../api/types";
import chipSelectArrowIcon from "../assets/icons/chipselect-arrow-icon.svg";
import {BoxWithGradientFrame} from "./GradientFrame";
import Popper from '@mui/material/Popper';


interface ContactOption {
  label: string;
  id: string;
  clientSum?: ClientSummary
}

export interface ContactSearchProps {
  name: string;
  default?: null | string;
  placeholder?: null | string;
  onSelectContact?: (client?: ClientSummary) => void;
  workflowType?: WorkflowType;
}

export default function ContactSearch(props: ContactSearchProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const { data, isPending } = useSearchContactsQuery({
    query: searchQuery,
    limit: 5
  });

  
  const [openAddContact, setOpenAddContact] = useState(false);
  const [value, setValue] = useState<null | ContactOption>(null);
  const [options, setOptions] = useState<ContactOption[]>([]);
  const [contactSelected, setContactSelected] = useState(false);
  const [key, setKey] = useState(0);
  console.log(searchQuery)
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && contactSelected) {
      setSearchQuery('');
      setContactSelected(false);
      setValue(null);
      setKey(prevKey => prevKey + 1);  // increment key to force re-render
    }
  };

  useEffect(() => {
    if (isPending || !data) return;

    const newOptions = data.clients.map((c) => ({
      label: c.full_name,
      id: c.uuid,
      clientSum: c as ClientSummary
    }));

    const newClient: ContactOption = {
      label: "+ Add new client",
      id: "0",
    };
    setOptions([newClient, ...newOptions]);

    if (props.default) {
      const defaultOption = newOptions.filter((x) => x.id === props.default);
      if (defaultOption.length > 0) {
        setValue(defaultOption[0]);
      }
    }
  }, [data, isPending]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleChangeOption = (_: unknown, option: null | ContactOption) => {
    if (option?.id === "0") {
      setOpenAddContact(true);
    } else {
      props.onSelectContact?.(option?.clientSum);
      setValue(option);
      setContactSelected(true);
    }
  };

  return (
    <>
      {props.workflowType == WorkflowType.PHOTOGRAPHY ? (
        <AddPhotographyContactDialog
          open={openAddContact}
          onClose={() => setOpenAddContact(false)}
        />
      ) : (
        <AddRealEstateContactDialog
          open={openAddContact}
          onClose={() => setOpenAddContact(false)}
        />
      )}

      <BoxWithGradientFrame>
        <Autocomplete
          disablePortal
          id={props.name + "-autocomplete"}
          filterOptions={(x) => x}
          loading={isPending}
          value={value}
          PopperComponent={(props) => (
            <Popper {...props} style={{ backgroundColor: "white", borderRadius: "1rem", width: "100%" }}/>
          )}
          onChange={handleChangeOption}
          renderInput={(params) => (
            <TextField
              {...params}
              key={key}
              variant="standard"
              fullWidth
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={searchQuery}
              placeholder={props.placeholder ?? undefined}
              sx={{
                mt: 1,
                height: "4rem",
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Box component="img" src={chipSelectArrowIcon} sx={{ width: "0.9rem", height: "0.9rem", mr: "-2rem" }} />
                  </InputAdornment>
                ),
                sx: {
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  pl: 1.5,
                  "&.MuiAutocomplete-inputRoot": {
                    pr: "2rem !important",
                    color: contactSelected ? "#68B3FF" : undefined
                  }
                },
              }}
            />
          )}
          renderOption={(props, option) =>
            option.id == "0" ? (
              <li
                {...props}
                style={{ color: "#75B3FF", fontFamily: "Helvetica Neue" }}
              >
                + Add new client
              </li>
            ) : (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )
          }
          options={options}
        />
      </BoxWithGradientFrame>
    </>
  );
}
