import {useAtom, useAtomValue} from "jotai";
import React, {useCallback, useMemo, useState} from "react";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {useAtomCallback} from "jotai/utils";
import {FormatBold, FormatItalic, FormatUnderlined,} from "@mui/icons-material";
import {useTemplateAtoms} from "./TemplateContext";
import {useAddUpdateRecord} from "./commonHooks";

interface SingleColorProps {
  color: string;
}

function SingleColor(props: SingleColorProps) {
  const templateAtoms = useTemplateAtoms();

  const readDomState = useAtomCallback(
    useCallback((get) => get(templateAtoms.domStateAtom), [])
  );

  const addRecord = useAddUpdateRecord();

  const handleClick = async () => {
    const state = await readDomState();
    if (!state?.selectedElement) return;

    state.selectedElement.style.color = props.color;
    addRecord("color", props.color);
  };

  return (
    <Box
      sx={{
        bgcolor: props.color,
        width: "2.2rem",
        height: "2.2rem",
        borderRadius: "50%",
        boxSizing: "border-box",
        "&:hover": {
          border: "solid 0.1429rem #ffffff80",
        },
      }}
      onClick={handleClick}
    />
  );
}

const DEFAULT_INVOICE_COLORS = [
  ["#fbfbfb", "#a3a3a3", "#5d5d5d", "#020202"],
  ["#56c2ff", "#00a2ff", "#0075ba", "#014d7f"],
  ["#73fcea", "#17e7cf", "#00ab8e", "#006b64"],
  ["#88fa4f", "#61d836", "#1db101", "#017101"],
  ["#fff056", "#ffd931", "#fead02", "#f27200"],
  ["#ff968d", "#ff644d", "#ee220d", "#b41700"],
  ["#d48f6c", "#9f694b", "#784124", "#4f1d0c"],
];

const DEFAULT_FONTS = [
  "Helvetica Neue",
  "Helvetica Neue",
  "Arial",
  "Times New Roman",
];

const FONT_SIZES = [
  8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 28, 32, 36, 40, 44, 48,
].map((x) => ({
  value: `${x}pt`,
  label: `${x} pt`,
}));

const DEFAULT_FONT_WEIGHTS = [{label: "Regular", value: "normal"}];

function FontFamilySelect() {
  const templateAtoms = useTemplateAtoms();
  const state = useAtomValue(templateAtoms.domStateAtom);
  const [updateCounter, setUpdateCounter] = useState(0);

  const addRecord = useAddUpdateRecord();

  const value = useMemo(() => {
    if (!state?.selectedElement) return "Helvetica Neue";

    const style = window.getComputedStyle(state.selectedElement, null);
    const family = style.getPropertyValue("font-family")?.split(",")?.[0];

    return family;
  }, [state?.selectedElement, updateCounter]);

  const handleChange = (e: SelectChangeEvent<string>) => {
    if (!state?.selectedElement) return;

    state.selectedElement.style.fontFamily = e.target.value;
    addRecord("font-family", e.target.value);
    setUpdateCounter((x) => x + 1);
  };

  return (
    <Select
      fullWidth
      sx={{
        height: "3.2rem",
        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          // bgcolor: '#e7f4ff',
        },
        borderRadius: 2,
        bgcolor: "#e7f4ff",
      }}
      size="small"
      value={value}
      onChange={handleChange}
    >
      {DEFAULT_FONTS.map((font) => (
        <MenuItem key={font} value={font}>
          {font}
        </MenuItem>
      ))}
    </Select>
  );
}

function FontWeightSelect() {
  return (
    <Select
      fullWidth
      sx={{
        height: "3.2rem",
        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          // bgcolor: '#e7f4ff',
        },
        borderRadius: 2,
        bgcolor: "#e7f4ff",
      }}
      size="small"
      value="normal"
    >
      {DEFAULT_FONT_WEIGHTS.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

const FONT_STYLING_OPTIONS = [
  {
    value: "bold",
    icon: <FormatBold/>,
    jsKey: "fontWeight",
    cssKey: "font-weight",
    cssValue: "bold",
  },
  {
    value: "italic",
    icon: <FormatItalic/>,
    jsKey: "fontStyle",
    cssKey: "font-style",
    cssValue: "italic",
  },
  {
    value: "underline",
    icon: <FormatUnderlined/>,
    jsKey: "textDecoration",
    cssKey: "text-decoration",
    cssValue: "underline",
  },
  // {
  //   value: "line-through",
  //   icon: <StrikethroughS />,
  //   jsKey: "textDecoration",
  //   cssKey: "text-decoration",
  //   cssValue: "line-through",
  // },
];

function FontSizing() {
  const templateAtoms = useTemplateAtoms();
  const state = useAtomValue(templateAtoms.domStateAtom);
  const [updateCounter, setUpdateCounter] = useState(0);

  const addRecord = useAddUpdateRecord();

  const value = useMemo(() => {
    if (!state?.selectedElement) return "14pt";

    const style = window.getComputedStyle(state.selectedElement, null);
    const fontSize = style.getPropertyValue("font-size");
    if (fontSize) return Math.round(+fontSize?.split("px")?.[0] * 0.75) + "pt";
    else return "14pt";
  }, [state?.selectedElement, updateCounter]);

  const handleChange = (e: SelectChangeEvent<string>) => {
    if (!state?.selectedElement) return;

    addRecord("font-size", e.target.value);
    state.selectedElement.style.fontSize = e.target.value as string;
    setUpdateCounter((x) => x + 1);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{height: "100%"}}
    >
      <Typography variant="body2">Font size:</Typography>

      <Select
        sx={{
          height: "3.2rem",
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
            // backgroundColor: '#e7f4ff',
          },
          borderRadius: 2,
          bgcolor: "#e7f4ff",
        }}
        size="small"
        value={value}
        onChange={handleChange}
      >
        {FONT_SIZES.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}

function FontStyling() {
  const templateAtoms = useTemplateAtoms();
  const state = useAtomValue(templateAtoms.domStateAtom);
  const [updateCounter, setUpdateCounter] = useState(0);
  const addRecord = useAddUpdateRecord();

  const value = useMemo<string[]>(() => {
    const result = [];

    const el = state?.selectedElement;
    if (el) {
      for (const option of FONT_STYLING_OPTIONS) {
        // @ts-ignore
        if (el.style[option.jsKey] === option.cssValue)
          result.push(option.value);
      }
    }

    return result;
  }, [state?.selectedElement, updateCounter]);

  const handleClick = async (option: (typeof FONT_STYLING_OPTIONS)[0]) => {
    if (!state?.selectedElement) return;

    const enable = !value.includes(option.value);

    switch (option.value) {
      case "bold":
        state.selectedElement.style.fontWeight = enable ? "bold" : "normal";
        addRecord("font-weight", state.selectedElement.style.fontWeight);
        break;

      case "italic":
        state.selectedElement.style.fontStyle = enable ? "italic" : "normal";
        addRecord("font-style", state.selectedElement.style.fontStyle);
        break;

      case "underline":
      case "line-through": {
        let items = value.filter((x) =>
          ["underline", "line-through"].includes(x)
        );
        if (enable) items.push(option.value);
        else items = items.filter((x) => x !== option.value);
        state.selectedElement.style.textDecoration = items.join(" ");
        addRecord(
          "text-decoration",
          state.selectedElement.style.textDecoration
        );
        break;
      }
    }

    setUpdateCounter((x) => x + 1);
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={value}
      sx={{display: "table", mx: "auto"}}
    >
      {FONT_STYLING_OPTIONS.map((option) => (
        <ToggleButton
          key={option.value}
          value={option.value}
          onClick={() => handleClick(option)}
          sx={{
            height: "3.2rem",
            width: "4rem",
            border: 0,
            bgcolor: "#e7f4ff",
            color: "black",
            "&.MuiToggleButton-root.Mui-selected": {
              color: "#75B3FF",
            },
          }}
        >
          {option.icon}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

function InvoiceStyleFonts() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FontFamilySelect/>
      </Grid>

      <Grid item xs={6}>
        <FontWeightSelect/>
      </Grid>

      <Grid item xs={6}>
        <FontStyling/>
      </Grid>

      <Grid item xs={6}>
        <FontSizing/>
      </Grid>
    </Grid>
  );
}

function InvoiceStyleColors() {
  return (
    <Box sx={{mt: 3}}>
      <Typography variant="h5">Colors</Typography>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        {DEFAULT_INVOICE_COLORS.map((group, i) => (
          <Stack key={i} spacing={1}>
            {group.map((color) => (
              <SingleColor key={color} color={color}/>
            ))}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}

export function TemplateStyle() {
  return (
    <>
      <Typography variant="h2" fontFamily="Helvetica Neue" sx={{mb: 3}}>
        Style
      </Typography>

      <InvoiceStyleFonts/>
      <InvoiceStyleColors/>
    </>
  );
}
