import {
  Box,
  Button,
  IconButton,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FileObj } from "../../../../api/types";
import { readAsDataURL } from "../../../../components/MynkForm/controls/FileUpload";
import {
  Cropper,
  CropperRef,
  ImageRestriction,
  CircleStencil,
  Coordinates,
} from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import addPhotoIcon from "../../../../assets/icons/add-photo-icon.svg";
import ElevatorDialog from "../../../../components/ElevatorDialog";
import "react-advanced-cropper/dist/themes/bubble.css";
import ProfilePicture, {
  businessLogoAtom,
  profilePhotoAtom,
} from "../../../../components/ProfilePicture";
import { useAtomValue } from "jotai";
import DialogActionButton from "../../../../components/DialogActionButton";
import { getCroppedImageDataURL } from "../../../../util/common";

export enum PhotoType {
  LOGO = "logo",
  PROFILE = "profile",
}

interface UploadOnePhotoProps {
  onSubmit?: (selectedFile: FileObj | null, type: PhotoType) => void;
  type: PhotoType;
}

export function UploadOnePhoto(props: UploadOnePhotoProps) {
  const [selectedFile, setSelectedFile] = useState<FileObj | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPreviewImg, setOpenPreviewImg] = useState(false);
  const [coordinates, setCoordinates] = useState<Coordinates | undefined>(undefined);
  const [filePreview, setFilePreview] = useState<string>("");

  const onChange = (cropper: CropperRef) => {
    setCoordinates(cropper.getCoordinates() as Coordinates);
  };
  
  const handleFilesChange = async (files: FileList) => {
    // Select only the first file in the list:
    const file = files[0];

    if (file) {
      try {
        const dataUrl = await readAsDataURL(file);

        // Set the selected file and its preview:
        setSelectedFile({
          name: file.name,
          data: dataUrl,
        });
        setFilePreview(URL.createObjectURL(file));
        setOpenDialog(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const openFileDialog = () => {
    const fileInput = document.getElementById(`fileInput-${props.type}`) as HTMLInputElement;
    fileInput.value = "";
    fileInput.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const droppedFiles = event.dataTransfer.files as FileList;
    if (droppedFiles.length > 0) {
      handleFilesChange(droppedFiles);
    }
  };

  const profilePhoto = useAtomValue(profilePhotoAtom);
  const businessLogo = useAtomValue(businessLogoAtom);

  return (
    <Stack spacing="1rem">
      <Input
        id={`fileInput-${props.type}`}
        type="file"
        inputProps={{ accept: "image/png, image/jpeg", multiple: false }}
        onChange={async (e) => {
          const files = (e.target as HTMLInputElement).files as FileList;
          await handleFilesChange(files);
        }}
        style={{ display: "none" }}
      />
      <ElevatorDialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <Stack sx={{ maxHeight: "44rem", maxWidth: "44rem" }}>
          <Cropper
            src={filePreview}
            onChange={onChange}
            stencilProps={{
              aspectRatio: 1 / 1,
            }}
            stencilComponent={CircleStencil}
          />
          <Stack alignItems="center" sx={{ mt: "1rem", mb: "-1rem" }}>
            <DialogActionButton
              type="submit"
              loading={false}
              onClick={() => {
                if (coordinates) {
                  getCroppedImageDataURL(selectedFile?.data ?? "", coordinates)
                  .then(croppedDataURL => {
                      const croppedFile: FileObj = {
                        name: selectedFile?.name ?? "",
                        mime_type: selectedFile?.mime_type,
                        data: coordinates ? croppedDataURL : (selectedFile?.data ?? "")
                      };
                      setSelectedFile(croppedFile);
                      props.onSubmit?.(croppedFile, props.type);
                  })
                  .catch(error => {
                      console.error('Failed to crop image:', error);
                  });
                }
                else {
                  props.onSubmit?.(selectedFile, props.type);
                }
                setOpenDialog(false);
              }}
            >
              <Typography
                fontFamily="Helvetica Neue"
                fontWeight="bold"
                color="white"
              >
                Apply
              </Typography>
            </DialogActionButton>
          </Stack>
        </Stack>
      </ElevatorDialog>

      <ElevatorDialog
        open={openPreviewImg}
        onClose={() => setOpenPreviewImg(false)}
      >
        {profilePhoto || businessLogo ? (
          <Box
            component="img"
            src={props.type === PhotoType.PROFILE ? profilePhoto : businessLogo}
            sx={{ width: "37rem" }}
          />
        ) : (
          <Typography>Nothing to see here</Typography>
        )}
      </ElevatorDialog>
      <Stack direction="row" alignItems="end" spacing="-2.5rem">
        <ProfilePicture
          onClick={() => setOpenPreviewImg(true)}
          size="9rem"
          fontSize="3rem"
          type={props.type}
        />
        <Box
          component="img"
          src={addPhotoIcon}
          sx={{
            width: "2.7rem",
            height: "2.7rem",
            pb: "0.3rem",
            cursor: "pointer",
          }}
          onClick={openFileDialog}
        />
      </Stack>
      <Stack alignItems="center">
        <Typography sx={{ fontSize: "1.1rem" }}>
          {props.type.charAt(0).toUpperCase() + props.type.slice(1)}
        </Typography>
      </Stack>
    </Stack>
  );
}
