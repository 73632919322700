import { Box, Divider, Stack, Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useLogoutMutation, useWhoamiQuery } from "../../api/auth";
import { Workflow } from "../../api/types";
import { Fragment, useEffect, useState } from "react";
import arrowLeftIcon from "../../assets/icons/small-left-arrow-black.svg";
import settingsIcon from "../../assets/icons/small-settings-icon.svg";
import helpAndSupportIcon from "../../assets/icons/small-help-and-support-icon.svg";
import logoutIcon from "../../assets/icons/small-logout-icon.svg";
import { useNavigate } from "react-router-dom";
import repeatIcon from "../../assets/icons/repeat-icon.svg";
import { useListWorkflowsQuery } from "../../api/workflows";
import { useAtom, useAtomValue } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { PATHS, WorkflowPath, makePath } from "../../paths";
import ProfilePicture from "../ProfilePicture";
import { useListGeneralSettingsQuery } from "../../api/settings";
import ChatbotDialog from "../ChatbotDialog";
import { PhotoType } from "../../pages/CommonComponents/Settings/common/UploadOnePhoto";

export const currentWorkflowAtom = atomWithStorage<Workflow | undefined>(
  "currentWorkflow",
  undefined
);

interface StackOptionProps {
  title: string;
  iconRight?: string;
  iconLeft: string;
  link?: string;
  onClick?: () => Promise<boolean>;
  disabled?: boolean;
}

function StackOption(props: StackOptionProps) {
  const navigate = useNavigate();
  const handleClick = async () => {
    if (props.onClick) {
      const isSucceed = await props.onClick();
      if (isSucceed) {
        if (props.link) {
          navigate(props.link);
        }
      }
    } else {
      if (props.link) {
        navigate(props.link);
      }
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        borderRadius: "1.2rem",
        height: "1.9rem",
        pl: 1,
        pr: 1,
        transition: "background-color 0.1s ease-in-out",
        "&:hover": {
          bgcolor: "#ededed",
        },
        "&:active": {
          bgcolor: "#e3e3e3",
        },
      }}
      onClick={handleClick}
    >
      {/* Left icon  */}
      <Box
        component="img"
        src={props.iconLeft}
        sx={{ width: "1rem", height: "1rem" }}
      />
      <Typography
        fontFamily="Helvetica Neue"
        fontSize={"1.27rem"}
        fontWeight="bold"
        sx={{ ml: 1 }}
      >
        {props.title}
      </Typography>
      <Box sx={{ flex: 1 }} />
      {/* Right icon (arrow) */}
      {props.iconRight ? (
        <Box
          component="img"
          src={props.iconRight}
          sx={{ width: "0.7rem", height: "0.7rem" }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
}

export default function UserControlPopup() {
  const [currentWorkflow, setCurrentWorkflow] = useAtom(currentWorkflowAtom);

  const logout = useLogoutMutation();
  const handleLogout = async () => {
    try {
      const result = await logout.mutateAsync({});
      console.log("result", result);
      if (Object.keys(result).length == 0) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };

  const { data, isPending } = useListWorkflowsQuery({});
  const [current, setCurrent] = useState<Array<Workflow>>([]);
  const fullName = useListGeneralSettingsQuery({}).data?.user_name;

  useEffect(() => {
    if (data && !isPending) {
      if (currentWorkflow) {
        setCurrent(
          data.workflows.filter(
            (workflow) => workflow["uuid"] !== currentWorkflow.uuid
          )
        );
      } else {
        setCurrent(data.workflows);
      }
    }
  }, [data, currentWorkflow]);

  const handleSetWorkflow = (workflow: Workflow) => {
    setCurrentWorkflow(workflow);
    window.location.reload();
  };

  const [openChat, setOpenChat] = useState(false);

  return (
    <>
      <ChatbotDialog open={openChat} onClose={() => setOpenChat(false)} />
      <Box sx={{ width: "28rem", margin: 2 }}>
        <Box
          sx={{
            padding: 1,
            borderRadius: 3,
            boxShadow: "0px 0px 0.1429rem 0.1429rem rgba(117, 179, 255, 0.10)",
          }}
        >
          {/* Constant data that shows the current workflow */}
          <Stack
            direction="row"
            sx={{ alignItems: "center", pb: 2.7, ml: 1.2 }}
          >
            <ProfilePicture type={PhotoType.PROFILE} />
            <Typography
              fontFamily="Helvetica Neue"
              fontSize={"1.5rem"}
              fontWeight="bold"
              sx={{ pl: 1.3 }}
            >
              {fullName}
            </Typography>
          </Stack>
          <Divider sx={{ bgcolor: "#75B3FF", opacity: 0.2 }} />
          <Typography
            fontSize={"1.1rem"}
            sx={{ color: "#75B3FF", mt: 1.23, ml: 1.23 }}
          >
            {currentWorkflow?.name}
          </Typography>
        </Box>

        {/* List available workflows */}
        <Stack
          sx={{
            mt: 1.3,
            mb: 1.3,
            boxShadow: "0px 0px 0.1429rem 0.1429rem rgba(117, 179, 255, 0.1)",
            borderRadius: "0.8571rem",
          }}
        >
          {current.map((item, index) => (
            <Fragment key={item.uuid}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  padding: 1.4,
                  borderRadius: `${index === 0 ? 0.8571 : 0}rem ${
                    index === 0 ? 8571 : 0
                  }rem ${index === current.length - 1 ? 8571 : 0}rem ${
                    index === current.length - 1 ? 8571 : 0
                  }rem`,
                  transition: "background-color 0.1s ease-in-out",
                  "&:hover": {
                    bgcolor: "#f5f9ff",
                  },
                  "&:active": {
                    bgcolor: "#ebf3ff",
                  },
                  cursor: "pointer",
                }}
                onClick={() => handleSetWorkflow(item)}
              >
                <Typography fontSize={"1.1rem"} sx={{ color: "#75B3FF" }}>
                  {item.name}
                </Typography>
                <Box sx={{ flex: 1 }} />
                <Box
                  component="img"
                  src={repeatIcon}
                  sx={{ width: "1.4286rem", height: "1.4286rem" }}
                />
              </Stack>
              {index != current.length - 1 ? (
                <Divider sx={{ bgcolor: "#75B3FF", opacity: 0.2 }} />
              ) : (
                <></>
              )}
            </Fragment>
          ))}
        </Stack>

        {/* Quick options */}
        <Stack spacing={"0.8rem"} sx={{ cursor: "pointer" }}>
          <StackOption
            title="Settings"
            iconRight={arrowLeftIcon}
            iconLeft={settingsIcon}
            link={makePath(
              `/${currentWorkflow?.type}` as WorkflowPath,
              PATHS.settings.index
            )}
          />
          <StackOption
            title="Help & support"
            iconRight={arrowLeftIcon}
            iconLeft={helpAndSupportIcon}
            onClick={async () => {
              setOpenChat(true); 
              return true;
            }}
          />
          <StackOption
            title="Logout"
            iconLeft={logoutIcon}
            link="/login"
            onClick={handleLogout}
          />
        </Stack>
      </Box>
    </>
  );
}
