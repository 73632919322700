import React, {lazy, ReactNode, Suspense} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {createBrowserRouter, Navigate, RouterProvider, useLocation,} from "react-router-dom";
import {PersistQueryClientProvider} from "@tanstack/react-query-persist-client";
import {createSyncStoragePersister} from "@tanstack/query-sync-storage-persister";
import {QueryClient} from "@tanstack/react-query";
import mynkTextLogo from "./assets/images/mynk-textlogo.svg";
import MainLayout from "./layouts/Main";
import {Box, CircularProgress, Stack} from "@mui/material";
import LoginLayout from "./layouts/Login";
import SignInPage from "./pages/SignIn";
import ClientsPage from "./pages/CommonComponents/Clients";
import AuthGuard from "./guards/AuthGuard";
import ClientsAllSubpage from "./pages/CommonComponents/Clients/tabs/All";
import ProjectsPage from "./pages/MynkPhotography/Projects";
import ProjectsAllSubpage from "./pages/MynkPhotography/Projects/tabs/All";
import ProjectsCompletedSubpage from "./pages/MynkPhotography/Projects/tabs/Completed";
import ViewPhotographyProjectGeneralSubpage from "./pages/MynkPhotography/ViewProject/tabs/General";
import ViewPhotographyProjectPage from "./pages/MynkPhotography/ViewProject";
import TemplatesPage from "./pages/MynkPhotography/Templates";
import TemplatesAllSubpage from "./pages/MynkPhotography/Templates/tabs/All";
import TemplatesSentSubpage from "./pages/MynkPhotography/Templates/tabs/Sent";
import ViewPortfolioPage from "./pages/MynkPhotography/Portfolio";
import TasksPage from "./pages/CommonComponents/Tasks";
import TasksAllSubpage from "./pages/CommonComponents/Tasks/tabs/All";
import TasksCompletedSubpage from "./pages/CommonComponents/Tasks/tabs/Completed";
import InstantiateTemplatePage from "./pages/MynkPhotography/InstantiateTemplate";
import ViewPhotographyContactPage from "./pages/MynkPhotography/ViewContact";
import ViewPhotographyContactGeneralSubpage from "./pages/MynkPhotography/ViewContact/tabs/General";
import {SnackbarProvider} from "notistack";
import ViewPhotographyProjectFilesSubpage from "./pages/MynkPhotography/ViewProject/tabs/Files";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ViewPhotographyProjectNotesSubpage from "./pages/MynkPhotography/ViewProject/tabs/Notes";
import ClientsCRMSubpage from "./pages/CommonComponents/Clients/tabs/CRM";
import ListingsPage from "./pages/MynkRealEstate/Listings";
import ListingsAllSubpage from "./pages/MynkRealEstate/Listings/tabs/All";
import ListingsArchivedSubpage from "./pages/MynkRealEstate/Listings/tabs/Archived";
import WorkflowGuard from "./guards/WorkflowGuard";
import {WorkflowType} from "./api/types";
import {BASE_PATHS, makePath, PATHS, WorkflowPath} from "./paths";
import PhotographyHomePage from "./pages/MynkPhotography/Home";
import RealEstateHomePage from "./pages/MynkRealEstate/Home";
import PhotographySettingsPage from "./pages/MynkPhotography/Settings";
import SettingsGeneralSubpage from "./pages/CommonComponents/Settings/tabs/General";
import RealEstateSettingsPage from "./pages/MynkRealEstate/Settings";
import ViewRealEstateListingPage from "./pages/MynkRealEstate/ViewListing";
import ViewRealEstateListingGeneralSubpage from "./pages/MynkRealEstate/ViewListing/tabs/General";
import ViewRealEstateContactPage from "./pages/MynkRealEstate/ViewContact";
import ViewRealEstateContactGeneralSubpage from "./pages/MynkRealEstate/ViewContact/tabs/General";
import RealEstatePortalPage from "./pages/MynkRealEstate/Portal";
import RealEstatePortalEditSubpage from "./pages/MynkRealEstate/Portal/tabs/Edit";
import RealEstatePortalTemplatesSubpage from "./pages/MynkRealEstate/Portal/tabs/Templates";
import RealEstatePortalViewSubpage from "./pages/MynkRealEstate/Portal/tabs/View";
import ViewRealEstateListingPhotosSubpage from "./pages/MynkRealEstate/ViewListing/tabs/Photos";
import ViewPhotographyProjectTasksSubpage from "./pages/MynkPhotography/ViewProject/tabs/Tasks";
import {MynkThemeProvider} from "./components/MynkThemeProvider";
// import DashboardPage from "./pages/MynkPhotography/Dashboard";
import VerifyEmailPage from "./pages/Signup/VerifyEmail";
import CheckoutPage from "./pages/Signup/Checkout";
import SchedulerPage from "./pages/CommonComponents/Scheduler";
import SchedulerCalendarSubpage from "./pages/CommonComponents/Scheduler/tabs/Calendar";
import SchedulerSchedulerSubpage from "./pages/CommonComponents/Scheduler/tabs/Scheduler";
import ResetPasswordPage from "./pages/SignIn/ResetPassword";
import NotFound404 from "./pages/404NotFound";
import CheckoutSuccessPage from "./pages/Signup/CheckoutSuccess";
import CheckoutFailPage from "./pages/Signup/CheckoutFail";
import DeleteAccountPage from "./pages/DeleteAccount";
import MobileGuard from "./guards/MobileGuard";
import DesignPage from "./pages/MynkPhotography/Portfolio/tabs/Design";
import EditPage from "./pages/MynkPhotography/Portfolio/tabs/Edit";
import SchedulerLayout from "./layouts/SchedulerLayout";
import ExpensesPage from "./pages/MynkPhotography/Expenses";
import ExpensesAllSubpage from "./pages/MynkPhotography/Expenses/tabs/All";
import RemindersGuard from "./guards/RemindersGuard";
import SalesPendingSubpage from "./pages/MynkPhotography/Sales/tabs/Pending";
import SalesCompletedSubpage from "./pages/MynkPhotography/Sales/tabs/Completed";
import FirstNavigate from "./components/FirstNavigate";
import SettingsIntegrationsSubpage from "./pages/CommonComponents/Settings/tabs/Integrations";
import LibraryPage from "./pages/CommonComponents/Library";
import LibraryAllSubpage from "./pages/CommonComponents/Library/tabs/All";
import GuidePage from "./pages/CommonComponents/LibraryCategory/Guide";
import ContractPage from "./pages/CommonComponents/LibraryCategory/Contract";
import ReleasePage from "./pages/CommonComponents/LibraryCategory/Release";
import ToolPage from "./pages/CommonComponents/LibraryCategory/Tool";
import LibraryFavoritesSubpage from "./pages/CommonComponents/Library/tabs/Favorites";
import ViewPhotographyContactExpensesSubpage from "./pages/MynkPhotography/ViewContact/tabs/Expenses";
import ViewPhotographyProjectExpensesSubpage from "./pages/MynkPhotography/ViewProject/tabs/Expenses";
import TermsAndConditions from "./pages/CommonComponents/TermsPrivacy/TermsAndConditions";
import PrivacyPolicy from "./pages/CommonComponents/TermsPrivacy/PrivacyPolicy";

const ViewPhotographyContactTasksSubpage = lazy(
  () => import("./pages/MynkPhotography/ViewContact/tabs/Tasks")
);
const ViewPhotographyContactFilesSubpage = lazy(
  () => import("./pages/MynkPhotography/ViewContact/tabs/Files")
);
const ViewPhotographyContactNotesSubpage = lazy(
  () => import("./pages/MynkPhotography/ViewContact/tabs/Notes")
);
const SignupPage = lazy(() => import("./pages/Signup"));

const photographyDashboard = {
  // Dashboard:
  path: makePath(WorkflowPath.PHOTOGRAPHY, BASE_PATHS.dashboard),
  element: (
    <MobileGuard>
      <AuthGuard>
        <WorkflowGuard expectedWorkflow={WorkflowType.PHOTOGRAPHY}>
          <RemindersGuard>
            <MainLayout/>
          </RemindersGuard>
        </WorkflowGuard>
      </AuthGuard>
    </MobileGuard>
  ),
  children: [
    // Home:
    {
      path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.home.index),
      element: <PhotographyHomePage/>,
    },

    // // Dashboard:
    // {
    //   path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.dashboard.index),
    //   element: <DashboardPage />,
    // },

    // Projects:
    {
      path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.projects.index),
      element: <ProjectsPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.projects.all)}
            />
          ),
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.projects.all),
          element: <ProjectsAllSubpage/>,
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.projects.completed),
          element: <ProjectsCompletedSubpage/>,
        },
      ],
    },

    // CRM:
    {
      path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.clients.index),
      element: <ClientsPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.clients.all)}
            />
          ),
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.clients.all),
          element: <ClientsAllSubpage/>,
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.clients.crm),
          element: <ClientsCRMSubpage/>,
        },
      ],
    },

    // Tasks:
    {
      path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.tasks.index),
      element: <TasksPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.tasks.all)}
            />
          ),
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.tasks.all),
          element: <TasksAllSubpage/>,
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.tasks.completed),
          element: <TasksCompletedSubpage/>,
        },
      ],
    },

    // Templates:
    {
      path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.templates.index),
      element: <TemplatesPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.templates.all)}
            />
          ),
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.templates.all),
          element: <TemplatesAllSubpage/>,
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.templates.sent),
          element: <TemplatesSentSubpage/>,
        },
      ],
    },

    // View Project:
    {
      path: makePath(
        WorkflowPath.PHOTOGRAPHY,
        PATHS.viewProject(":projectId").index
      ),
      element: <ViewPhotographyProjectPage/>,
      children: [
        {
          path: makePath(
            WorkflowPath.PHOTOGRAPHY,
            PATHS.viewProject(":projectId").general
          ),
          element: <ViewPhotographyProjectGeneralSubpage/>,
        },
        {
          path: makePath(
            WorkflowPath.PHOTOGRAPHY,
            PATHS.viewProject(":projectId").files
          ),
          element: <ViewPhotographyProjectFilesSubpage/>,
        },
        {
          path: makePath(
            WorkflowPath.PHOTOGRAPHY,
            PATHS.viewProject(":projectId").tasks
          ),
          element: <ViewPhotographyProjectTasksSubpage/>,
        },
        {
          path: makePath(
            WorkflowPath.PHOTOGRAPHY,
            PATHS.viewProject(":projectId").notes
          ),
          element: <ViewPhotographyProjectNotesSubpage/>,
        },
        {
          path: makePath(
            WorkflowPath.PHOTOGRAPHY,
            PATHS.viewProject(":projectId").expenses
          ),
          element: <ViewPhotographyProjectExpensesSubpage/>,
        }
      ],
    },

    // View Contact:
    {
      path: makePath(
        WorkflowPath.PHOTOGRAPHY,
        PATHS.viewContact(":contactId").index
      ),
      element: <ViewPhotographyContactPage/>,
      children: [
        {
          path: makePath(
            WorkflowPath.PHOTOGRAPHY,
            PATHS.viewContact(":contactId").general
          ),
          element: <ViewPhotographyContactGeneralSubpage/>,
        },
        {
          path: makePath(
            WorkflowPath.PHOTOGRAPHY,
            PATHS.viewContact(":contactId").files
          ),
          element: <ViewPhotographyContactFilesSubpage/>,
        },
        {
          path: makePath(
            WorkflowPath.PHOTOGRAPHY,
            PATHS.viewContact(":contactId").tasks
          ),
          element: <ViewPhotographyContactTasksSubpage/>,
        },
        {
          path: makePath(
            WorkflowPath.PHOTOGRAPHY,
            PATHS.viewContact(":contactId").notes
          ),
          element: <ViewPhotographyContactNotesSubpage/>,
        },
        {
          path: makePath(
            WorkflowPath.PHOTOGRAPHY,
            PATHS.viewContact(":contactId").expenses
          ),
          element: <ViewPhotographyContactExpensesSubpage/>,
        }
      ],
    },

    // Instantiate Template:
    {
      path: makePath(
        WorkflowPath.PHOTOGRAPHY,
        PATHS.instantiateTemplate(":templateId").index
      ),
      element: <InstantiateTemplatePage/>,
    },

    // Calendar:
    {
      path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.scheduler.index),
      element: <SchedulerPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.scheduler.calendar)}
            />
          ),
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.scheduler.calendar),
          element: <SchedulerCalendarSubpage/>,
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.scheduler.scheduler),
          element: <SchedulerSchedulerSubpage/>,
        },
      ],
    },

    // // AI:
    // {
    //   path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.ai.index),
    //   element: <AIPage />,
    //   children: [
    //     {
    //       index: true,
    //       element: <AIChatSubpage />,
    //     },
    //   ],
    // },

    // Settings:
    {
      path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.settings.index),
      element: <PhotographySettingsPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.settings.general)}
            />
          ),
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.settings.general),
          element: <SettingsGeneralSubpage/>,
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.settings.integrations),
          element: <SettingsIntegrationsSubpage/>,
        }
      ],
    },

    // Portfolio:
    {
      path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.portfolio.index),
      element: <ViewPortfolioPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.portfolio.design)}
            />
          )
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.portfolio.design),
          element: <DesignPage/>,
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.portfolio.edit),
          element: <EditPage/>,
        },
      ],
    },

    // Expenses:
    {
      path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.expenses.index),
      element: <ExpensesPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.expenses.all)}
            />
          ),
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.expenses.all),
          element: <ExpensesAllSubpage/>,
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.expenses.upcoming),
          element: <SalesPendingSubpage/>,
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.expenses.completed),
          element: <SalesCompletedSubpage/>,
        },
      ],
    },

    // Library:
    {
      path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.library.index),
      element: <LibraryPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.library.all)}
            />
          ),
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.library.all),
          element: <LibraryAllSubpage/>,
        },
        {
          path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.library.favorites),
          element: <LibraryFavoritesSubpage/>,
        }
      ],
    },

    // Guide:
    {
      path: makePath(
        WorkflowPath.PHOTOGRAPHY,
        PATHS.guide(":imageName").index
      ),
      element: <GuidePage/>,
    },

    {
      path: makePath(
        WorkflowPath.PHOTOGRAPHY,
        PATHS.contract(":imageName").index
      ),
      element: <ContractPage/>,
    },

    {
      path: makePath(
        WorkflowPath.PHOTOGRAPHY,
        PATHS.release(":imageName").index
      ),
      element: <ReleasePage/>,
    },

    {
      path: makePath(
        WorkflowPath.PHOTOGRAPHY,
        PATHS.tool(":imageName").index
      ),
      element: <ToolPage/>,
    }
  ],
};

const realEstateDashboard = {
  // Dashboard:
  path: makePath(WorkflowPath.REAL_ESTATE, BASE_PATHS.dashboard),
  element: (
    <MobileGuard>
      <AuthGuard>
        <WorkflowGuard expectedWorkflow={WorkflowType.REAL_ESTATE}>
          <MainLayout/>
        </WorkflowGuard>
      </AuthGuard>
    </MobileGuard>
  ),
  children: [
    // Home:
    {
      path: makePath(WorkflowPath.REAL_ESTATE, PATHS.home.index),
      element: <RealEstateHomePage/>,
    },

    // Listings:
    {
      path: makePath(WorkflowPath.REAL_ESTATE, PATHS.listings.index),
      element: <ListingsPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.REAL_ESTATE, PATHS.listings.all)}
            />
          ),
        },
        {
          path: makePath(WorkflowPath.REAL_ESTATE, PATHS.listings.all),
          element: <ListingsAllSubpage/>,
        },
        {
          path: makePath(WorkflowPath.REAL_ESTATE, PATHS.listings.archived),
          element: <ListingsArchivedSubpage/>,
        },
      ],
    },

    // CRM:
    {
      path: makePath(WorkflowPath.REAL_ESTATE, PATHS.clients.index),
      element: <ClientsPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.REAL_ESTATE, PATHS.clients.contacts)}
            />
          ),
        },
        {
          path: makePath(WorkflowPath.REAL_ESTATE, PATHS.clients.all),
          element: <ClientsAllSubpage/>,
        },
        {
          path: makePath(WorkflowPath.REAL_ESTATE, PATHS.clients.crm),
          element: <ClientsCRMSubpage/>,
        },
      ],
    },

    // Tasks:
    {
      path: makePath(WorkflowPath.REAL_ESTATE, PATHS.tasks.index),
      element: <TasksPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.REAL_ESTATE, PATHS.tasks.all)}
            />
          ),
        },
        {
          path: makePath(WorkflowPath.REAL_ESTATE, PATHS.tasks.all),
          element: <TasksAllSubpage/>,
        },
        {
          path: makePath(WorkflowPath.REAL_ESTATE, PATHS.tasks.completed),
          element: <TasksCompletedSubpage/>,
        },
      ],
    },

    // Portal:
    {
      path: makePath(WorkflowPath.REAL_ESTATE, PATHS.portal.index),
      element: <RealEstatePortalPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.REAL_ESTATE, PATHS.portal.view)}
            />
          ),
        },
        {
          path: makePath(WorkflowPath.REAL_ESTATE, PATHS.portal.view),
          element: <RealEstatePortalViewSubpage/>,
        },
        {
          path: makePath(WorkflowPath.REAL_ESTATE, PATHS.portal.edit),
          element: <RealEstatePortalEditSubpage/>,
        },
        {
          path: makePath(WorkflowPath.REAL_ESTATE, PATHS.portal.templates),
          element: <RealEstatePortalTemplatesSubpage/>,
        },
      ],
    },

    // View Listing:
    {
      path: makePath(
        WorkflowPath.REAL_ESTATE,
        PATHS.viewProject(":projectId").index
      ),
      element: <ViewRealEstateListingPage/>,
      children: [
        {
          path: makePath(
            WorkflowPath.REAL_ESTATE,
            PATHS.viewProject(":projectId").general
          ),
          element: <ViewRealEstateListingGeneralSubpage/>,
        },
        {
          path: makePath(
            WorkflowPath.REAL_ESTATE,
            PATHS.viewProject(":projectId").photos
          ),
          element: <ViewRealEstateListingPhotosSubpage/>,
        },
      ],
    },

    // View Contact:
    {
      path: makePath(
        WorkflowPath.REAL_ESTATE,
        PATHS.viewContact(":contactId").index
      ),
      element: <ViewRealEstateContactPage/>,
      children: [
        {
          path: makePath(
            WorkflowPath.REAL_ESTATE,
            PATHS.viewContact(":contactId").general
          ),
          element: <ViewRealEstateContactGeneralSubpage/>,
        },
      ],
    },

    // Settings:
    {
      path: makePath(WorkflowPath.REAL_ESTATE, PATHS.settings.index),
      element: <RealEstateSettingsPage/>,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={makePath(WorkflowPath.REAL_ESTATE, PATHS.settings.general)}
            />
          ),
        },
        {
          path: makePath(WorkflowPath.REAL_ESTATE, PATHS.settings.general),
          element: <SettingsGeneralSubpage/>,
        },
      ],
    },
  ],
};

const router = createBrowserRouter([
  {
    path: "*",
    element: <Navigate to="/404"/>,
  },
  {
    path: "/404",
    element: <LoginLayout noElevator/>,
    children: [
      {
        index: true,
        element: <NotFound404/>,
      },
    ],
  },
  {
    path: "/",
    element: <FirstNavigate />,
  },
  {
    path: "/dashboard/home",
    element: <Navigate to="/photography/dashboard/home"/>,
  },
  {
    path: "/login",
    element: (
      <LoginLayout/>
    ),
    children: [
      {
        index: true,
        element: <SignInPage/>,
      },
      {
        path: "/login/reset-password",
        element: <ResetPasswordPage/>,
      },
    ],
  },
  {
    path: "/signup",
    element: (
      <LoginLayout/>
    ),
    children: [
      {
        index: true,
        element: <SignupPage/>,
      },
      {
        path: "/signup/verify-email",
        element: <VerifyEmailPage/>,
      },
      {
        path: "/signup/checkout",
        element: <CheckoutPage/>,
      },
      {
        path: "/signup/checkout/success",
        element: <CheckoutSuccessPage/>,
      },
      {
        path: "/signup/checkout/fail",
        element: <CheckoutFailPage/>,
      }
    ],
  },
  {
    path: "/delete-account",
    element: (
      <MobileGuard>
        <LoginLayout/>
      </MobileGuard>
    ),
    children: [
      {
        index: true,
        element: <AuthGuard><DeleteAccountPage/></AuthGuard>,
      },
    ],
  },
  {
    path: "/terms-and-conditions",
    element: (
      <TermsAndConditions/>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <PrivacyPolicy/>
    ),
  },
  {
    //Portal:
    path: PATHS.viewPortal(":companyName/:meetingType?").index,
    element: (
      <SchedulerLayout/>
    ),
    children: [],
  },
  photographyDashboard,
  realEstateDashboard,
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24 * 2, // 2 Days.
      // disabled for DEBUGGING
      // refetchOnWindowFocus: false,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Suspense
    fallback={
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "calc(100vh - 6.5rem)",
        }}
      >
        <Stack
          sx={{
            pb: 7,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <CircularProgress
            size={"4.2857rem"}
            thickness={32}
            sx={{color: "#c05ec6"}}
          />
          <Box height={"1.4286rem"}/>
          <Box component="img" src={mynkTextLogo} sx={{width: "8.5714rem"}}/>
        </Stack>
      </Stack>
    }
  >
    <MynkThemeProvider>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{persister}}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <RouterProvider router={router}/>
          </SnackbarProvider>
        </LocalizationProvider>
      </PersistQueryClientProvider>
    </MynkThemeProvider>
  </Suspense>
);

function wait(time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}
