import { useParams } from "react-router-dom";
import { useGetProjectQuery } from "../../../../api/projects";
import { RealEstateListing } from "../../../../api/types";
import { useEffect, useState } from "react";
import GalleryView from "../../../../components/GalleryView";
import { fetchAllImagesByUuids, fetchFileSrc } from "../../../../util/common";
import { CircularProgress } from "@mui/material";

export default function ViewRealEstateListingPhotosSubpage() {
  const { projectId } = useParams();
  const { data, isPending } = useGetProjectQuery(
    { project_uuid: projectId ?? "" },
    Boolean(projectId)
  );
  const listing = data?.project as RealEstateListing;

  

  return isPending ? (
    <CircularProgress size={"3.5714rem"} />
  ) : (
    <GalleryView photosUuids={listing.files_uuids} />
  );
}
