import React from 'react'
import { Stack, Box, Typography } from '@mui/material';
import WhiteRightArrow from '../../assets/icons/white-right-arrow.svg'

type FormButtonProps = {
    text: string
    nextPage: 1 | 2 | 3 | 4
    setCurrentPage: React.Dispatch<React.SetStateAction<1 | 2 | 3 | 4>>
}

function FormButton({text, nextPage, setCurrentPage}: FormButtonProps) {
    return (
        <Stack flexDirection={'row'}
            sx={{
                alignSelf: 'center',
                borderRadius: '40px',
                background: 'linear-gradient(90deg, #59B5D9 4.39%, #6A91F2 104.82%)',
                boxShadow: '0px 4px 4px 0px rgba(54, 144, 255, 0.20)',
                width: text === 'Book' ? '9rem' : '12rem',
                height: '3.5rem',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '3rem',
                cursor: 'pointer',
            }}
            onClick={() => {setCurrentPage(nextPage)}}
        >
            <Typography sx={{fontSize: '1.2rem', color: 'white', marginRight: '0.5rem'}}>{text}</Typography>
            <Box
                src={WhiteRightArrow}
                component='img'
                sx={{
                    width: '1rem',
                    height: '1rem'
                }}
            />
        </Stack>
    );
}

export default FormButton
