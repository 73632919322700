import {MynkPage} from "../../../components/MynkPage";
import { PATHS, WorkflowPath, makePath } from "../../../paths";

const TABS = [
  {
    title: 'Design',
    path: '/design',
  },
  {
    title: 'Edit',
    path: '/edit',
  }
]

export default function ViewPortfolioPage() {
  return (
    <MynkPage
      title="Portfolio"
      basePath={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.portfolio.index)}
      tabs={TABS}
    />
  );
}
