import { MynkPage, MynkTabInfo } from "../../../components/MynkPage";
import { PATHS, WorkflowPath, makePath } from "../../../paths";

const TABS: MynkTabInfo[] = [
  {
    title: "General",
    path: "/general",
  },
  {
    title: "Profile",
    path: "/profile"
  }
];

export default function RealEstateSettingsPage() {
  return (
    <MynkPage
      title="Settings"
      basePath={makePath(WorkflowPath.REAL_ESTATE, PATHS.settings.index)}
      tabs={TABS}
    />
  );
}
