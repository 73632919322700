import { Divider, Grid, IconButton, Stack, Typography, Box } from "@mui/material";
import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import { useMemo, useState } from "react";
import MynkFormEditable from "../components/MynkForm/MynkFormEditable";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import {
    MeetingType,
    EditMeetingTypeParams
} from "../api/types";
import { DURATION_OPTIONS, BLOCK_OFF_TIME_OPTIONS, MINIMUM_NOTICE_TIME } from '../components/ChipSelect/commonOptions'
import CheckRadioButton from '../assets/icons/check-radio-button.svg'
import UncheckRadioButton from '../assets/icons/uncheck-radio-button.svg'
import InformationIcon from '../assets/icons/information-icon.svg'
import { useEditMeetingTypeMutation } from "../api/scheduler";
import { AxiosError } from "axios";

export interface EditMeetingTypeProps {
  open: boolean;
  onClose: () => void;
  meetingType: MeetingType;
  index: number;
  meetingTypes: Array<MeetingType>;
  setMeetingTypes: React.Dispatch<React.SetStateAction<Array<MeetingType>>>;
}

export default function EditMeetingTypeDialog(props: EditMeetingTypeProps) {
    const [isPublic, setIsPublic] = useState<boolean>(props.meetingType.isPublic);
    const editMeetingType = useEditMeetingTypeMutation();

    const handleSubmit = async (values: any) => {
        if (!props.meetingType?.uuid) return;
        
        const params: EditMeetingTypeParams = {
            uuid: props.meetingType.uuid,
            name: values["name"] ?? "",
            duration: values["duration"] ?? "30",
            location: values["location"] ?? "None",
            isPublic: isPublic,
            price: values["price"] ?? "0",
            description: values["description"] ?? "",
            blockOffTimeBefore: values["blockOffTimeBefore"] ?? '',
            blockOffTimeAfter: values["blockOffTimeAfter"] ?? '',
            minimumNoticeTime: values["minimumNoticeTime"] ?? '',
        };

        const newMeetingTypes = [...props.meetingTypes];
        newMeetingTypes[props.index] = {
            uuid: props.meetingType.uuid,
            name: params.name,
            duration: params.duration,
            location: params.location,
            isPublic: params.isPublic,
            price: params.price,
            description: params.description,
            blockOffTimeBefore: params.blockOffTimeBefore,
            blockOffTimeAfter: params.blockOffTimeAfter,
            minimumNoticeTime: params.minimumNoticeTime,
        };

        try {
            await editMeetingType.mutateAsync(params);
        } catch (err) {
            const error = err as AxiosError;
            console.error(error.response?.data);
            return;
        }

        props.setMeetingTypes(newMeetingTypes);
        props.onClose();
    }

    const initialValues = useMemo(() => {
        return {
            name: props.meetingType.name.trim(),
            duration: props.meetingType.duration.trim(),
            location: props.meetingType.location.trim(),
            isPublic: props.meetingType.isPublic,
            price: props.meetingType.price,
            description: props.meetingType.description,
            blockOffTimeBefore: props.meetingType.blockOffTimeBefore,
            blockOffTimeAfter: props.meetingType.blockOffTimeAfter,
            minimumNoticeTime: props.meetingType.minimumNoticeTime,
        };
    }, [props.meetingType]);

    return (
        <MynkFormDialog
            open={props.open}
            onClose={props.onClose}
            actionDisabled={false}
            onSubmit={handleSubmit}
            loading={editMeetingType.isPending}
            action="Apply changes"
            title="Edit Meeting Type"
            initialValues={initialValues}
            elevatorSx={{px: 6}}
        >
            <Grid container spacing={"1.1rem"} sx={{ width: "35rem" }}>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 0.5 }} />
                </Grid>

                <Grid item xs={12}>
                    <MynkFormEditable>
                        <MynkFormControl
                            kind={MynkFormControlKind.TEXT}
                            name="name"
                            label="Meeting type name"
                            placeholder="Enter meeting type name"
                            autoComplete={false}
                            default={initialValues.name ?? ""}
                        />
                    </MynkFormEditable>
                </Grid>

                <Grid item xs={12}>
                    <MynkFormControl
                        kind={MynkFormControlKind.CHIP_SELECT}
                        name="duration"
                        label="Duration"
                        placeholder="Enter duration"
                        default={initialValues.duration ?? "30 min"}
                        options={DURATION_OPTIONS}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider sx={{ mb: 0.5 }} />
                </Grid>

                <Grid item xs={12}>
                    <Stack>
                        <Stack flexDirection={'row'} sx={{marginTop: '1rem'}}>
                            <IconButton
                                sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
                                onClick={() => isPublic && setIsPublic(prevState => !prevState)}
                            >
                                <Box
                                    src={isPublic ? UncheckRadioButton : CheckRadioButton}
                                    component='img'
                                    sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
                                />
                            </IconButton>
                            <span style={{fontSize: '1.2rem', marginLeft: '0.6rem', marginRight: '1rem'}}>Private</span>
                            <Box
                                src={InformationIcon}
                                component='img'
                                sx={{width: '1.2rem', height: '1.2rem', marginTop: '0.1rem'}}
                            />
                        </Stack>
                        <Stack flexDirection={'row'} sx={{marginTop: '1rem'}}>
                            <IconButton
                                sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
                                onClick={() => !isPublic && setIsPublic(prevState => !prevState)}
                            >
                                <Box
                                    src={isPublic ? CheckRadioButton : UncheckRadioButton}
                                    component='img'
                                    sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
                                />
                            </IconButton>
                            <span style={{fontSize: '1.2rem', marginLeft: '0.6rem',  marginRight: '1.55rem'}}>Public</span>
                            <Box
                                src={InformationIcon}
                                component='img'
                                sx={{width: '1.2rem', height: '1.2rem', marginTop: '0.1rem'}}
                            />
                        </Stack>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Divider sx={{ mb: 0.5 }} />
                </Grid>

                <Grid item xs={12}>
                    <MynkFormEditable>
                        <MynkFormControl
                        kind={MynkFormControlKind.TEXT}
                        name="location"
                        label="Location"
                        placeholder="Enter location"
                        autoComplete={false}
                        default={initialValues.location ?? ""}
                        />
                    </MynkFormEditable>
                </Grid>

                <Grid item xs={12}>
                    <Divider sx={{ mb: 0.5 }} />
                </Grid>

                <Grid item xs={12}>
                    <MynkFormEditable>
                        <MynkFormControl
                            kind={MynkFormControlKind.TEXT}
                            name="price"
                            label="Price"
                            placeholder="Enter price"
                            autoComplete={false}
                            default={initialValues.price ?? ""}
                        />
                    </MynkFormEditable>
                </Grid>

                <Grid item xs={12}>
                    <MynkFormEditable longText>
                        <MynkFormControl
                            kind={MynkFormControlKind.TEXT}
                            name="description"
                            label="Description"
                            placeholder="Enter Meeting Type's description"
                            autoComplete={false}
                            multiLine={true}
                            default={initialValues?.description ?? ""}
                            height={100}
                        />
                    </MynkFormEditable>
                </Grid>

                <Grid item xs={12}>
                    <Divider sx={{ mb: 0.5 }} />
                </Grid>

                <Grid item xs={12}>
                    <MynkFormControl
                        kind={MynkFormControlKind.CHIP_SELECT}
                        name="blockOffTimeBefore"
                        label="Block off time before"
                        default={initialValues.blockOffTimeBefore}
                        options={BLOCK_OFF_TIME_OPTIONS}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MynkFormControl
                        kind={MynkFormControlKind.CHIP_SELECT}
                        name="blockOffTimeAfter"
                        label="Block off time after"
                        
                        default={initialValues.blockOffTimeAfter}
                        options={BLOCK_OFF_TIME_OPTIONS}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MynkFormControl
                        kind={MynkFormControlKind.CHIP_SELECT}
                        name="minimumNoticeTime"
                        label="Minimum notice time"
                        default={initialValues.minimumNoticeTime}
                        options={MINIMUM_NOTICE_TIME}
                    />
                </Grid>
            </Grid>
        </MynkFormDialog>
    );
}
