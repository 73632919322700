import { MynkTab } from "../../../../components/MynkPage";
import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import normal0 from "../../../../assets/images/projectCards/0-normal.svg";
import normal1 from "../../../../assets/images/projectCards/1-normal.svg";
import normal8 from "../../../../assets/images/projectCards/8-normal.svg";
import normal9 from "../../../../assets/images/projectCards/9-normal.svg";
import selected0 from "../../../../assets/images/projectCards/0-selected.svg";
import selected1 from "../../../../assets/images/projectCards/1-selected.svg";
import selected8 from "../../../../assets/images/projectCards/8-selected.svg";
import selected9 from "../../../../assets/images/projectCards/9-selected.svg";
import ActionMenuButton from "../../../../components/ActionMenuButton";
import { Add } from "@mui/icons-material";
import { AddPhotographyProjectDialog } from "../../../../dialogs/AddPhotographyProject";
import { AddLeadDialog } from "../../../../dialogs/AddLead";
import { AddPhotographyContactDialog } from "../../../../dialogs/AddPhotographyContact";
import { ProjectsTableCard } from "../common/ProjectsTableCard";
import { ProjectStage } from "../../../../api/types";
import {
  useGetClientStatsQuery,
  useGetStageStatsQuery,
} from "../../../../api/clients";
import { TableType } from "../../../../components/SimpleTable";

const NORMAL_BACKGROUNDS = [normal0, normal1, normal8, normal9];

const SELECTED_BACKGROUNDS = [selected0, selected1, selected8, selected9];

const ALL_BACKGROUNDS = [...NORMAL_BACKGROUNDS, ...SELECTED_BACKGROUNDS];

interface LeadCardEntry {
  label: string;
  value: ProjectStage;
  kind: "lead";
}

interface ProjectCardEntry {
  label: string;
  value: ProjectStage;
  kind: "project";
}

interface InfoCardEntry {
  label: string;
  value: string;
  kind: "info";
}

type CardEntry = LeadCardEntry | ProjectCardEntry | InfoCardEntry;

const isInfoCardEntry = (entry: CardEntry): entry is InfoCardEntry => {
  return entry.kind === "info";
};

const PROJECT_CARDS: CardEntry[] = [
  {
    label: "Contacts",
    value: "none",
    kind: "info",
  },
  {
    label: "Leads",
    value: ProjectStage.LEAD,
    kind: "lead",
  },
  {
    label: "Proposal",
    value: ProjectStage.PROPOSAL,
    kind: "lead",
  },
  {
    label: "Under contract",
    value: ProjectStage.UNDER_CONTRACT,
    kind: "lead",
  },
  {
    label: "Closed/won",
    value: ProjectStage._CLOSED_WON,
    kind: "project",
  },
  {
    label: "Active projects",
    value: ProjectStage._ACTIVE,
    kind: "project",
  },
  {
    label: "Waiting for event",
    value: ProjectStage.WAITING_FOR_EVENT,
    kind: "project",
  },
  {
    label: "Editing",
    value: ProjectStage.EDITING,
    kind: "project",
  },
  {
    label: "Final approval",
    value: ProjectStage.FINAL_APPROVAL,
    kind: "project",
  },
  {
    label: "Completed",
    value: ProjectStage.COMPLETED,
    kind: "project",
  },
];

interface ProjectCardBubbleProps {
  card: CardEntry;
  selected?: boolean;
  index: number;
  label: string;
  aspectRatio: number;
  onClick?: (card: CardEntry) => void;
  value?: number;
}

function ProjectCardBubble(props: ProjectCardBubbleProps) {
  const middleIndex = Math.floor(PROJECT_CARDS.length / 2);
  const lastIndex = PROJECT_CARDS.length - 1;
  const isEdge = props.index === 0 || props.index == lastIndex;
  const step = 0.07;

  const selected = props.selected ?? false;
  // const background = selected ? SELECTED_BACKGROUNDS[props.index] : NORMAL_BACKGROUNDS[(props.index === 0 || props.index === lastIndex) ? props.index : 1];
  const background = (selected ? SELECTED_BACKGROUNDS : NORMAL_BACKGROUNDS)[
    props.index === 0
      ? 0
      : props.index === lastIndex
      ? 3
      : props.index >= middleIndex
      ? 2
      : 1
  ];

  const numHeightSteps =
    props.index >= middleIndex ? lastIndex - props.index : props.index;
  const heightScale = 1 - numHeightSteps * step;

  return (
    <Box
      sx={{
        position: "relative",
        flex: 1,
        cursor: props.onClick ? "pointer" : "inherit",
      }}
      onClick={() => props.onClick?.(props.card)}
    >
      <Box
        sx={{
          width: "100%",
          paddingBottom: (1 / props.aspectRatio) * 100 + "%",
          backgroundImage: `url("${background}")`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: isEdge ? "" : "0 3px",
          transform: isEdge ? "" : `scale(1.0, ${heightScale})`,
        }}
      />

      <Box sx={{ position: "absolute", top: "21%", width: "100%" }}>
        <Typography
          fontFamily="Helvetica Neue"
          fontSize="4rem"
          fontWeight={"bold"}
          textAlign="center"
          sx={{
            color: "white",
            textShadow: "0px 0.1429rem 0.1429rem #6aadffe0",
          }}
        >
          {props.value === undefined ? <Box height="6rem"/> : props.value}
        </Typography>

        <Typography
          textAlign="center"
          fontFamily="Helvetica Neue"
          lineHeight="1.5rem"
          sx={{ color: "white", width: "70%", ml: "15%" }}
          fontSize="1.2rem"
        >
          {props.label}
        </Typography>
      </Box>
    </Box>
  );
}

interface ProjectsOverviewCardProps {
  activeCard: ProjectStage;
  setActiveCard: (value: ProjectStage) => void;
}

export function ProjectsOverviewCard(props: ProjectsOverviewCardProps) {
  const { data, isPending } = useGetStageStatsQuery();
  const [activeCardsRange, setActiveCardsRange] =
    useState<Array<ProjectStage> | null>(null);

  useEffect(() => {
    switch (props.activeCard) {
      case ProjectStage._ALL:
        setActiveCardsRange([]);
        break;
      case ProjectStage._ACTIVE:
        setActiveCardsRange([
          ProjectStage.WAITING_FOR_EVENT,
          ProjectStage.EDITING,
          ProjectStage.FINAL_APPROVAL,
        ]);
        break;
      case ProjectStage._CLOSED_WON:
        setActiveCardsRange([
          ProjectStage.WAITING_FOR_EVENT,
          ProjectStage.EDITING,
          ProjectStage.FINAL_APPROVAL,
          ProjectStage.COMPLETED
        ]);
        break;
      case ProjectStage._LEADS_TO_CLOSED:
        setActiveCardsRange([
          ProjectStage.LEAD,
          ProjectStage.PROPOSAL,
          ProjectStage.UNDER_CONTRACT,
        ]);
        break;
      case ProjectStage.COMPLETED:
        setActiveCardsRange([ProjectStage.COMPLETED]);
        break;
      default:
        setActiveCardsRange(null);
    }
  }, [props.activeCard]);

  const cardValues = useMemo(() => {
    if (isPending || !data) return null;

    const result: { [stage: string]: number } = {};
    data.stageStats.stages.forEach((stage, i) => {
      result[stage] = data.stageStats.values[i];
    });

    return result;
  }, [data, isPending]);

  const { data: statsData } = useGetClientStatsQuery();

  return (
    <Card sx={{ height: "27.2rem" }}>
      <CardHeader title="Projects overview" />

      {ALL_BACKGROUNDS.map((b) => (
        <link key={b} rel="preload" as="image" href={b} />
      ))}

      <Stack
        direction="row"
        display="flex"
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        sx={{
          pt: 3,
          px: 3.3,
          fontSize: "1.2rem",
        }}
      >
        {PROJECT_CARDS.map((pc, i) =>
          isInfoCardEntry(pc) ? (
            <ProjectCardBubble
              key={i}
              card={pc}
              index={i}
              aspectRatio={0.625}
              label={pc.label}
              value={statsData?.stats.existing_clients}
            />
          ) : (
            <ProjectCardBubble
              key={i}
              card={pc}
              index={i}
              aspectRatio={0.625}
              selected={
                activeCardsRange
                  ? activeCardsRange.includes(pc.value)
                  : pc.value === props.activeCard
              }
              onClick={(card: CardEntry) => {
                const valueToSet =
                  !isInfoCardEntry(card) ? card.value : ProjectStage._ALL;

                props.setActiveCard(valueToSet);
              }}
              label={pc.label}
              value={cardValues ? cardValues![pc.value] ?? 0 : undefined}
            />
          )
        )}
      </Stack>
    </Card>
  );
}

enum NewMenuIds {
  NEW_PROJECT = "new-project",
  NEW_LEAD = "new-lead",
  NEW_CONTACT = "new-client",
}

const NEW_MENU = [
  {
    id: NewMenuIds.NEW_PROJECT,
    label: "New project",
  },
  {
    id: NewMenuIds.NEW_CONTACT,
    label: "New contact",
  },
];

export function NewButtonProjects() {
  const [newProjectOpen, setNewProjectOpen] = useState(false);
  const [addContactOpen, setAddContactOpen] = useState(false);

  const handleClickItem = (id: string) => {
    switch (id) {
      case NewMenuIds.NEW_PROJECT:
        setNewProjectOpen(true);
        break;
      case NewMenuIds.NEW_CONTACT:
        setAddContactOpen(true);
        break;
    }
  };

  return (
    <>
      <AddPhotographyProjectDialog
        open={newProjectOpen}
        onClose={() => setNewProjectOpen(false)}
      />
      <AddPhotographyContactDialog
        open={addContactOpen}
        onClose={() => setAddContactOpen(false)}
      />

      <ActionMenuButton
        startIcon={<Add sx={{ width: "1.1rem" }} />}
        menu={NEW_MENU}
        onClickItem={handleClickItem}
      >
        New
      </ActionMenuButton>
    </>
  );
}

function ProjectsAllSubpage_(
  _props: unknown,
  ref: ForwardedRef<HTMLDivElement>
) {
  const [activeCard, setActiveCard] = useState(ProjectStage.LEAD);

  return (
    <div id="capture-element">
      {" "}
      {/* Add this ID */}
      <MynkTab title="Projects" action={<NewButtonProjects />}>
        <Grid container spacing={3.2} ref={ref}>
          <Grid item xs={12}>
            <ProjectsOverviewCard
              activeCard={activeCard}
              setActiveCard={setActiveCard}
            />
          </Grid>

          <Grid item xs={12}>
            <ProjectsTableCard
              stage={activeCard}
              setStage={setActiveCard}
              tableType={TableType.PROJECTS}
            />
          </Grid>
        </Grid>
      </MynkTab>
    </div>
  );
}

const ProjectsAllSubpage = forwardRef(ProjectsAllSubpage_);

export default ProjectsAllSubpage;
