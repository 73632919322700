// TODO: combine with LeadView?
import { DeleteTaskParams, Priority, Task } from "../api/types";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import PriorityChip from "./PriorityChip";
import { useMemo, useRef, useState } from "react";
import {
  useDeleteTaskMutation,
  useListTasksQuery,
  useSetTaskPriorityMutation,
  useSetTaskStatusMutation,
} from "../api/tasks";
import { Skeleton } from '@mui/material';

import trashIcon from "../assets/icons/trash-lightgray-icon.svg";
import checkboxEmpty from "../assets/icons/checkbox-empty.svg";
import checkboxChecked from "../assets/icons/checkbox-checked.svg";
import ThreeDotsButton from "./ThreeDotsButton";
import { atom, useAtom } from "jotai";
import arrowRightShowMore from "../assets/icons/arrow-right-show-more.svg";
import arrowDownShowMoreOpened from "../assets/icons/arrow-down-show-more-opened.svg";
import { closeSnackbar, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { zonedDate } from "../util/common";
import ElevatorDialog from "./ElevatorDialog";
import { Clear } from "@mui/icons-material";
import blackCalendar from "../assets/icons/black-calendar-icon.svg";
import ClockIcon from "../assets/icons/small-clock-icon.svg";
import documentIcon from "../assets/icons/document-icon.svg";
import personIcon from "../assets/icons/person-icon.svg";
import { useGetProjectQuery } from "../api/projects";
import { useGetClientQuery } from "../api/clients";
import { useNavigate } from "react-router-dom";
import { PATHS, WorkflowPath, makePath } from "../paths";
import { DateTime } from "luxon";

export const selectedTaskAtom = atom<Task | null>(null);
export const openedEditAtom = atom(false);
export const openedDeleteAtom = atom(false);

export interface TaskGroup {
  title: string;
  due_days_min?: number;
  due_days_max?: number;
  completed?: boolean;
  allowAdd?: boolean;
}

interface TaskCheckboxProps {
  checked: boolean;
  onChange?: (value: boolean) => void;
  sx?: SxProps;
}

/**
 * Renders a checkbox icon that changes based on the `checked` prop.
 * When clicked, the `onChange` prop is called with the opposite value of `checked`.
 *
 * @param {TaskCheckboxProps} props - The props for the TaskCheckbox component.
 *   - checked (boolean) - Determines whether the checkbox is checked or not.
 *   - onChange (function) - Callback function that is called when the checkbox is clicked. It receives a boolean value as an argument.
 *   - sx (object) - Optional styling props for customizing the checkbox icon.
 * @returns {JSX.Element} The TaskCheckbox component.
 */
export function TaskCheckbox(props: TaskCheckboxProps) {
  return (
    <Box
    component="img"
    src={props.checked ? checkboxChecked : checkboxEmpty}
      sx={{
        width: "1.9rem",
        height: "1.9rem",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        cursor: "pointer",
        ...props.sx,
      }}
      onClick={() => props.onChange?.(!props.checked)}
    ></Box>
  );
}

enum threeDotsOptions {
  EDIT = "edit-task",
  DELETE = "delete-task",
}

const DOTS_MENU = [
  {
    id: threeDotsOptions.EDIT,
    label: "Edit",
  },
  {
    id: threeDotsOptions.DELETE,
    label: "Delete",
  },
];

interface ThreeDotsTaskProps {
  task: Task;
}

/**
 * Renders a button with three dots. When the button is clicked, it opens a menu with options to edit or delete a task.
 * Also renders two dialog components, `ConfirmDeleteDialog` and `EditTaskDialog`, which are opened when the corresponding menu options are selected.
 *
 * @returns The rendered `ThreeDotsTask` component with a button and two dialog components.
 */
export function ThreeDotsTask(props: ThreeDotsTaskProps) {
  const [selectedTask, setSelectedTask] = useAtom(selectedTaskAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);

  /**
   * Handles the click event on the menu options.
   * Sets the corresponding state variable to `true` to open the corresponding dialog component.
   *
   * @param id - The ID of the clicked menu option.
   */
  const handleClickItem = (id: string) => {
    setSelectedTask(props.task);

    switch (id) {
      case threeDotsOptions.EDIT:
        setOpenedEdit(true);
        break;
      case threeDotsOptions.DELETE:
        setOpenedDelete(true);
        break;
    }
  };

  return (
    <ThreeDotsButton
      menu={DOTS_MENU}
      onClickItem={handleClickItem}
      children={undefined}
    ></ThreeDotsButton>
  );
}

interface DeleteTaskButtonProps {
  task: Task;
  sx?: SxProps;
}

export function DeleteTaskButton(props: DeleteTaskButtonProps) {
  const { enqueueSnackbar } = useSnackbar();

  // Fetch delete task:
  const deleteTask = useDeleteTaskMutation();
  const handleDeleteTask = async (uuid: string) => {
    const params = {
      uuid: uuid,
    } as DeleteTaskParams;

    await deleteTask.mutateAsync(params);
    enqueueSnackbar("Task deleted successfully", {
      variant: "success",
      action: (key) => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
      style: {
        borderRadius: "0.7rem",
      },
    });
  };

  return (
    <IconButton
      onClick={() => handleDeleteTask(props.task.uuid)}
      sx={{ ...props.sx }}
    >
      <Box component="img" src={trashIcon} sx={{ height: "1.5rem" }} />
    </IconButton>
  );
}

interface ShowTaskDialogProps {
  task: Task;
  open: boolean;
  onClose: () => void;
  checked: boolean;
  onChange?: (value: boolean) => void;
}

export function ShowTaskDialog(props: ShowTaskDialogProps) {
  const {data: projectData} = useGetProjectQuery({project_uuid: props.task?.project_uuid ?? ""});
  const {data: clientData} = useGetClientQuery({uuid: props.task?.client_uuid ?? projectData?.project?.client_uuid ?? ""});
  const navigate = useNavigate();

  return (
    <ElevatorDialog open={props.open} onClose={props.onClose} customCloseButton>
      <Stack
        direction="row"
        sx={{ position: "fixed", right: "3rem", top: "2.5rem" }}
      >
                  <ThreeDotsTask task={props.task} />

        <IconButton
          sx={{ width: "2rem", height: "2rem" }}
          onClick={props.onClose}
        >
          <Clear sx={{ width: "1.7rem", height: "1.7rem" }} />
        </IconButton>
      </Stack>
      <Stack sx={{ width: "28rem", my: "1.5rem" }} spacing="1.3rem">
        <Stack direction="row" spacing="0.7rem" alignItems="center">

          {props.checked ? <DeleteTaskButton task={props.task} /> : <></>}
        {/* Mark completed circle */}
        <TaskCheckbox
          checked={props.checked ?? props.task.completed}
          onChange={props.onChange}
        />
          <Typography variant="h1" fontSize="2rem" sx={{textDecoration: props.checked ? "line-through" : ""}}>
            {props.task.title}
          </Typography>
        </Stack>
        <Stack spacing="0.6rem" sx={{ pt: "0.5rem" }}>
          <Stack direction="row" spacing="0.7rem" alignItems="center">
            <Box component="img" src={blackCalendar} sx={{ width: "1.2rem", height: "1.2rem" }} />
            <Typography>
                <b>Due: </b>
                {DateTime.fromISO(props.task.due_date ?? "")
                  .minus({ minutes: DateTime.local().offset }).toFormat(
                  "EEEE, LLL dd, yyyy"
                )}
              </Typography>
          </Stack>
          {props.task.is_reminder && (
            <Stack direction="row" spacing="0.7rem" alignItems="center">
              <Box component="img" src={ClockIcon} sx={{ width: "1.2rem", height: "1.2rem" }} />
              <Typography>
                <b>Time:</b>
              </Typography>
              <Typography
                fontSize={"1.15rem"}
                color="#75B3FF"
                fontWeight="bold"
                sx={{ minWidth: "7.7rem" }}
              >
                {(() => {
                  const time = new Date(props.task.due_date);
                  let hours = time.getUTCHours(); // Extract hours using UTC
                  const minutes = time.getUTCMinutes(); // Extract minutes using UTC
                  const ampm = hours >= 12 ? 'PM' : 'AM';
                  hours = hours % 12;
                  hours = hours ? hours : 12; // the hour '0' should be '12'
                  const strMinutes = minutes < 10 ? '0'+minutes : minutes;
                  return `${hours}:${strMinutes} ${ampm}`;
                })()}
              </Typography>
            </Stack>
          )}
          {props.task.project_uuid && projectData && projectData.project && projectData.project.uuid && projectData.project.name && (
            <Stack direction="row" spacing="0.7rem" alignItems="center" sx={{ pt: "0.4rem" }}>
              <Box component="img" src={documentIcon} sx={{ width: "1.2rem", height: "1.2rem" }} />
              
              
              <Stack direction="row">
              <Typography sx={{ fontWeight: "bold"}}>
                  Project:&nbsp;
                </Typography>
                  <Typography sx={{ color: "#75b3ff", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(
                  makePath(
                    WorkflowPath.PHOTOGRAPHY,
                    PATHS.viewProject(projectData.project.uuid ?? "").general
                  )
                )}>
                  {projectData?.project.name}
                  </Typography>
                </Stack>

            </Stack>
          )}
          {props.task.client_uuid && clientData && clientData?.client && clientData.client.uuid && clientData.client.full_name && (
            <Stack direction="row" spacing="0.7rem" alignItems="center">
              <Box component="img" src={personIcon} sx={{ width: "1.2rem", height: "1.2rem" }} />
              <Stack direction="row">
              <Typography sx={{ fontWeight: "bold"}}>
                  Client:&nbsp;
                </Typography>
                  <Typography sx={{ color: "#75b3ff", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(
                  makePath(
                    WorkflowPath.PHOTOGRAPHY,
                    PATHS.viewContact(clientData.client.uuid ?? "").general
                  )
                )}>
                  {clientData?.client.full_name}
                  </Typography>
                </Stack>
            </Stack>
          )}
              
        </Stack>
      </Stack>
    </ElevatorDialog>
  )
}

interface ListTypeContentsProps {
  task: Task;
  onCompletedChange?: (value: boolean) => void;
  forceCheck?: boolean;
}

/**
 * Renders the contents of a task in a list view.
 * Displays the task's checkbox, title, due date, priority, and three dots button.
 *
 * @param {ListTypeContentsProps} props - The props object.
 *   - task (object) - The task object containing information about the task.
 *   - onCompletedChange (function) - A callback function to handle the change in the completed status of the task.
 *   - forceCheck (boolean) - A flag to force the checkbox to be checked or unchecked.
 * @returns {JSX.Element} - The rendered JSX elements representing the contents of the task in a list view.
 */
function ListTypeContents(props: ListTypeContentsProps) {
  const date = useMemo(
    () => DateTime.fromISO(props.task.due_date ?? "")
    .minus({ minutes: DateTime.local().offset }),
    [props.task.due_date]
  );

  const setTaskPriority = useSetTaskPriorityMutation();
  const handleChangePriority = (value: string) => {
    setTaskPriority.mutate({
      uuid: props.task.uuid,
      priority: value as Priority,
    });
  };

  const [showTask, setShowTask] = useState(false);

  return (
    <Grid container alignItems="center" sx={{ py: 1, pl: 2, height: "4.2rem" }}>
      <ShowTaskDialog checked={props.forceCheck ?? props.task.completed}
          onChange={props.onCompletedChange} task={props.task} open={showTask} onClose={() => setShowTask(false)}/>
      <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
        {props.forceCheck && <DeleteTaskButton task={props.task} />}
        {/* Mark completed circle */}
        <TaskCheckbox
          checked={props.forceCheck ?? props.task.completed}
          onChange={props.onCompletedChange}
          sx={{
            ml: 2,
            mr: 2,
          }}
        />
        {/* Task title */}
        <Typography
          fontSize={"1.37rem"}
          fontWeight={"bold"}
          sx={{ textDecoration: props.forceCheck ? "line-through" : "", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", cursor: "pointer", width: "35rem" }}
          onClick={() => setShowTask(true)}
        >
          {props.task.title}
        </Typography>
      </Grid>

      {/* Due date */}
      <Grid item xs={3}>
        { !date ? (
          <Typography fontSize={"1.15rem"} color="#404040">Invalid date</Typography>
        ) : (
        <Stack direction="row" sx={{ minWidth: "15rem" }}>
          <Typography fontSize={"1.15rem"} color="#404040" fontWeight="bold">
            {date.toFormat("EEEE")},
          </Typography>
          <Typography fontSize={"1.15rem"} color="#404040B2">
            &nbsp;{date.toFormat("LLL dd, yyyy")}
          </Typography>
        </Stack>
        ) }
      </Grid>

      {/* Priority + 3 dots */}
      <Grid item xs={3}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          alignItems="baseline"
        >
          {props.task.priority && (
            <PriorityChip
              value={props.task.priority}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "7.7rem",
                height: "2.5rem",
                textAlign: "center",
                marginRight: "1.4rem",
              }}
              onChange={handleChangePriority}
            />
          )}
          {props.task.is_reminder && (
            <Typography
              fontSize={"1.15rem"}
              color="#75B3FF"
              fontWeight="bold"
              sx={{ minWidth: "7.7rem" }}
            >
              {(() => {
                const time = new Date(props.task.due_date);
                let hours = time.getUTCHours(); // Extract hours using UTC
                const minutes = time.getUTCMinutes(); // Extract minutes using UTC
                const ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                const strMinutes = minutes < 10 ? '0'+minutes : minutes;
                return `${hours}:${strMinutes} ${ampm}`;
              })()}
            </Typography>
          )}
          <ThreeDotsTask task={props.task} />
        </Stack>
      </Grid>
    </Grid>
  );
}

interface CardTypeContentsProps {
  task: Task;
  onCompletedChange?: (value: boolean) => void;
  forceCheck?: boolean;
}

/**
 * Renders the contents of a task in a card view.
 * Displays the task's checkbox, title, due date, and priority.
 *
 * @param {CardTypeContentsProps} props - The props passed to the component.
 *   - task (Task) - The task object containing information about the task.
 *   - onCompletedChange (function) - A callback function to handle the change in the task's completion status.
 *   - forceCheck (boolean) - A flag indicating whether the task checkbox should be forced to a specific state.
 * @returns {JSX.Element} - The rendered JSX elements representing the contents of a task card in a specific view type.
 */
function CardTypeContents(props: CardTypeContentsProps) {
  const date = useMemo(
    () => DateTime.fromISO(props.task.due_date ?? "")
    .minus({ minutes: DateTime.local().offset }),
    [props.task.due_date]
  );

  const setTaskPriority = useSetTaskPriorityMutation();
  const handleChangePriority = (value: string) => {
    setTaskPriority.mutate({
      uuid: props.task.uuid,
      priority: value as Priority,
    });
  };

  const [showTask, setShowTask] = useState(false);

  return (
    // Mark completed circle
    <Stack direction="row" minHeight="10.2rem">
      <ShowTaskDialog checked={props.forceCheck ?? props.task.completed}
          onChange={props.onCompletedChange} task={props.task} open={showTask} onClose={() => setShowTask(false)}/>
      <Stack justifyContent="center" alignContent="center" alignItems="center">
        {props.forceCheck ? <DeleteTaskButton task={props.task} /> : <></>}
        <TaskCheckbox
          checked={props.forceCheck ?? props.task.completed}
          onChange={props.onCompletedChange}
        />
      </Stack>

      <Box sx={{ flex: 1 }}>
        <Stack direction="row" alignItems="center">
          {/* Task title */}
          <Typography
            fontSize={"1.37rem"}
            fontWeight={"bold"}
            sx={{
              ml: 3,
              mt: 1.2,
              textDecoration: props.forceCheck ? "line-through" : "", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "15rem",
              cursor: "pointer"
            }}
            onClick={() => setShowTask(true)}
          >
            {props.task.title}
          </Typography>

          <Box sx={{ flex: 1 }} />

            <Box sx={{ mr: "0.7rem" }}>
          <ThreeDotsTask task={props.task} />
          </Box>
        </Stack>

        {/* Priority + Due date */}
        <Stack
          direction="row"
          alignItems="baseline"
          sx={{ mt: 8, mb: 1, px: 3 }}
          justifyContent="space-between"
          spacing={2}
        >
          <Typography
            fontSize={"1.14rem"}
            fontFamily="Helvetica Neue"
            sx={{ color: "#404040B2" }}
          >
            {date ? date.toFormat("LLL dd, yyyy") : "Invalid date"}
          </Typography>
          {props.task.priority && (
            <PriorityChip
              value={props.task.priority}
              onChange={handleChangePriority}
              sx={{ width: "7.7rem", height: "2.3rem" }}
            />
          )}
          {props.task.is_reminder && (
            <Typography
              fontSize={"1.15rem"}
              color="#75B3FF"
              fontWeight="bold"
              sx={{ minWidth: "7.7rem" }}
            >
              {(() => {
                const time = new Date(props.task.due_date);
                let hours = time.getUTCHours(); // Extract hours using UTC
                const minutes = time.getUTCMinutes(); // Extract minutes using UTC
                const ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                const strMinutes = minutes < 10 ? '0'+minutes : minutes;
                return `${hours}:${strMinutes} ${ampm}`;
              })()}
            </Typography>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}

interface TaskCardProps {
  viewType: TaskViewType;
  task: Task;
  forceCheck?: boolean;
  onCompletedChange?: (value: boolean) => void;
}

/**
 * Renders a task card (list/card view tasks) based on the specified view type.
 * The appearance and layout of the task card differ based on the view type (list or card).
 *
 * @param {TaskCard} props - The props passed to the component.
 *   - task (Task) - The task object containing information about the task.
 *   - viewType (TaskViewType) - card/list view.
 *   - onCompletedChange (function) - A callback function to handle the change in the task's completion status.
 *   - forceCheck (boolean) - A flag indicating whether the task checkbox should be forced to a specific state.
 * @returns The rendered task card component.
 */
function TaskCard(props: TaskCardProps) {
  
  return (
    <Box
      sx={{
        p: 0.5,
        pl: 2,
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%)",
        borderRadius: "1.2rem",
        boxShadow: "0px 5px 25px rgba(117, 179, 255, 0.10)",
        opacity: props.forceCheck !== undefined ? 0.5 : "",
      }}
    >
      {/* Generate the list-view-side tasks */}
      {props.viewType === TaskViewType.LIST && (
        <ListTypeContents
          task={props.task}
          onCompletedChange={props.onCompletedChange}
          forceCheck={props.forceCheck}
        />
      )}
      {/* Generate the card-view-side tasks */}
      {props.viewType === TaskViewType.CARD && (
        <CardTypeContents
          task={props.task}
          onCompletedChange={props.onCompletedChange}
          forceCheck={props.forceCheck}
        />
      )}
    </Box>
  );
}

export enum TaskViewType {
  LIST,
  CARD = 1,
}
export interface TaskViewProps {
  title: string;
  showAll?: boolean;
  dueDaysMin?: number;
  dueDaysMax?: number;
  dueDaysNot?: boolean;
  completed?: boolean;
  project_uuid?: string;
  client_uuid?: string;
  viewType?: TaskViewType;
  allowAdd?: boolean;
  onAdd?: () => void;
  sx?: SxProps;
  query?: string;
  noHeader?: boolean;
}

/**
 * Renders a task view component that displays a list and cards of tasks.
 *
 * @param props - The props object containing the following properties:
 *   - title: The title of the task view.
 *   - dueDaysMin: The minimum number of days from today for the due date of the tasks to be included in the view.
 *   - dueDaysMax: The maximum number of days from today for the due date of the tasks to be included in the view.
 *   - dueDaysNot: If true, tasks with due dates within the specified range will be excluded from the view.
 *   - completed: If true, only completed tasks will be included in the view.
 *   - viewType: The type of view to display the tasks (`LIST` or `CARD`).
 *   - allowAdd: If true, a link to add a new task will be displayed.
 *   - onAdd: A callback function to be called when the user clicks on the "Add Task" link.
 *   - sx: Additional styles to be applied to the task view container.
 * @returns The rendered task view component.
 */
export default function TaskView(props: TaskViewProps) {
  const [openedShowMore, setOpenedShowMore] = useState(props.showAll ?? false);

  // Fetch tasks from the server:
  const { data, isPending } = useListTasksQuery({
    due_days_min: props.dueDaysMin,
    due_days_max: props.dueDaysMax,
    due_days_not: props.dueDaysNot,
    completed: props.completed,
    title_query: props.query,
    project_uuid: props?.project_uuid,
    client_uuid: props?.client_uuid,
  });

  const viewType = props.viewType ?? TaskViewType.LIST; // Default preview is LIST.

  const _recentlyCheckedInit = useMemo(() => new Set(), []);
  const recentlyChecked = useRef(_recentlyCheckedInit);

  // Fetch changing task completion status:
  const setTaskStatus = useSetTaskStatusMutation();
  const [recentlyCheckedUpdate, setRecentlyCheckedUpdate] = useState(0);
  const handleCheckChange = (task: Task, value: boolean) => {
    setTaskStatus.mutate({
      uuid: task.uuid,
      completed: value,
    });

    if (value === !(props.completed ?? false)) {
      recentlyChecked.current.add(task.uuid);
    } else {
      recentlyChecked.current.delete(task.uuid);
    }

    // force rerender:
    setRecentlyCheckedUpdate((x) => x + 1);
  };

  return (
    <>
      <Box sx={{ ...props.sx }}>
        {/* Headers (Today/Tomorrow/Upcoming) */}

        {props.noHeader ? (
          <></>
        ) : (
          <Stack
            direction="row"
            alignItems="baseline"
            sx={{ pl: 2 }}
            spacing={0.8}
          >
            <Typography variant="h4" fontFamily="Helvetica Neue">
              {props.title}
            </Typography>

            {viewType === TaskViewType.CARD && <Box sx={{ flex: 1 }} />}
            <Box width="1.5rem" />

            <Box
              component="img"
              src={
                openedShowMore ? arrowDownShowMoreOpened : arrowRightShowMore
              }
              sx={{ cursor: "pointer", width: "0.8rem", height: "0.8rem" }}
              onClick={() => setOpenedShowMore(!openedShowMore)}
            />
            <Typography fontSize={"1.2rem"}>
              {data?.total ?? "..."} Tasks
            </Typography>
          </Stack>
        )}

        {/* Loading circles whenever tasks is still uploading from the server */}
        {!data || isPending ? (
          <Stack
          spacing={viewType == TaskViewType.LIST ? 1.1 : 2.7}
          sx={{ mt: 3, mx: 0.4, ...props.sx }}
        >
          {viewType == TaskViewType.LIST ? (
            <Skeleton variant="rounded" width={"100%"} height={"4.2rem"} sx={{ borderRadius: "1.2rem", background: "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%)", boxShadow: "0px 5px 25px rgba(117, 179, 255, 0.10)" }}/>
          ) : (
            <Skeleton variant="rounded" width={"100%"} height={"10.2rem"} sx={{ borderRadius: "1.2rem", background: "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%)", boxShadow: "0px 5px 25px rgba(117, 179, 255, 0.10)" }} />
          )}
          </Stack>
        ) : data.total === 0 ? (
          <></>
        ) : (
          // Generate stack for all tasks - sorted by due date
          <Stack
            spacing={viewType == TaskViewType.LIST ? 1.1 : 2.7}
            sx={{ mt: 3, mx: 0.4, ...props.sx }}
          >
            {openedShowMore
              ? data.tasks!.map((task, i) => (
                  <TaskCard
                    onCompletedChange={(value) =>
                      handleCheckChange(task, value)
                    }
                    key={i}
                    task={task}
                    viewType={viewType}
                    forceCheck={
                      recentlyChecked.current.has(task.uuid)
                        ? !(props.completed ?? false)
                        : undefined
                    }
                  />
                ))
              : data.tasks
                  .slice(0, 3)!
                  .map((task, i) => (
                    <TaskCard
                      onCompletedChange={(value) =>
                        handleCheckChange(task, value)
                      }
                      key={i}
                      task={task}
                      viewType={viewType}
                      forceCheck={
                        recentlyChecked.current.has(task.uuid)
                          ? !(props.completed ?? false)
                          : undefined
                      }
                    />
                  ))}
          </Stack>
        )}

        {/* {props.allowAdd && (
          <Box
            sx={
              viewType === TaskViewType.LIST
                ? { ml: 10, mt: 2 }
                : { display: "table", mx: "auto", mt: 2 }
            }
          >
            <Link
              sx={{
                color: "#75b3ff",
                textDecoration: "none",
                fontFamily: "Helvetica Neue",
                fontSize: "1.2rem",
                cursor: "pointer",
              }}
              onClick={props.onAdd}
            >
              + New Task
            </Link>
          </Box>
        )} */}
      </Box>
    </>
  );
}
