import { Box, Stack } from "@mui/material";
import { keyframes } from "@mui/system";

const slide = keyframes({
  "0%": {
    transform: "translateX(0%)",
  },
  "100%": {
    transform: "translateX(-100%)",
  },
});

interface ImageCarouselProps {
  items: string[];
  height: number;
  secondsPerImg: number;
}

export default function ImageCarousel(props: ImageCarouselProps) {
  const manyItems = [
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
    ...props.items,
  ];
  const itemsPerGroup = 3;

  const mappedArray = manyItems.map((_, index) => {
    const startIndex = index * itemsPerGroup;
    const endIndex = startIndex + itemsPerGroup;

    return manyItems.slice(startIndex, endIndex);
  });

  return (
    <Box
      className="container"
      sx={{
        overflow: "hidden",
        height: `${props.height}rem`,
        position: "absolute",
        bgcolor: "white",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Stack
        className="track"
        direction="row"
        spacing={"1.5rem"}
        sx={{
          my: "0.7rem",

          animation: `${slide} ${
            manyItems.length * props.secondsPerImg
          }s linear infinite`,
        }}
      >
        {mappedArray.map((item, index) => (
          <Stack
            key={index}
            sx={{
              transform: "rotate(11deg)",
              width: `${props.height / 1.5}rem`,
            }}
            spacing={"0.7rem"}
          >
            <Box
              component="img"
              src={item[0]}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0.4rem",
              }}
            />
            <Box
              component="img"
              src={item[1]}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0.4rem",
              }}
            />
            <Box
              component="img"
              src={item[2]}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0.4rem",
              }}
            />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
