import { Grid } from '@mui/material';
import React from 'react';
import { SalesInfoTableCard } from '../../../../components/SalesInfoTableCard';
import {MynkTab} from "../../../../components/MynkPage";
import {SalesNewButton} from "../common/NewButton";


export default function SalesPendingSubpage(){
  return (
    <MynkTab title="Pending" action={<SalesNewButton />}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SalesInfoTableCard tableProps={{ status: 'pending' }} />
        </Grid>
      </Grid>
    </MynkTab>
  );
}
