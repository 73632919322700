import {Box, Dialog, Divider, IconButton, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect, useMemo} from "react";
import {Clear} from "@mui/icons-material";
import {useFormik} from "formik";
import produce from "immer";
import DialogActionButton from "../../components/DialogActionButton";

export interface ItemEntry {
  name: string;
  qty: string;
  price: string;
}

interface AddSaleValues {
  items: ItemEntry[];
}

const INITIAL_VALUES: AddSaleValues = {
  items: [
    {
      name: '',
      qty: '1',
      price: '',
    }
  ],
};


export interface AddSaleDialogProps {
  open: boolean;
  onClose: () => void;
}


export function AddSaleDialog(props: AddSaleDialogProps) {
  const handleSubmit = (values: AddSaleValues) => {
    console.log('submit', values);
  };

  const formik = useFormik<AddSaleValues>({
    initialValues: INITIAL_VALUES,
    onSubmit: handleSubmit,
  });

  const handleAddItem = () => {
    formik.setFieldValue('items', produce(formik.values.items, draft => {
      draft.push({
        name: '',
        qty: '1',
        price: '',
      });
    }))
  };

  const handleAdd = () => {
    formik.submitForm().then();
  };

  const totalPrice = useMemo(() => {
    let total = 0;
    for (const item of formik.values.items) {
      const qty = +item.qty;
      const price = +item.price;
      if (isNaN(price) || isNaN(qty))
        continue;

      total += qty * price;
    }

    return total;
  }, [formik.values.items]);

  useEffect(() => {
    if (props.open) {
      formik.setValues(INITIAL_VALUES);
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      // onClose={props.onClose}
      sx={{
        '& div.MuiPaper-root': {
          background: '#cccccce0',
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <Box sx={{
        m: 2.5,
        p: 3,
        px: 6,
        background: 'linear-gradient(#ffffff 0%, #e2f4ff 100%)',
        borderRadius: '16px',
        position: 'relative'
      }}>
        <Typography variant="h6" fontWeight={600}>Add a sale</Typography>

        <IconButton
          sx={{position: 'absolute', top: 16, right: 16, color: 'black'}}
          onClick={() => props.onClose()}
        >
          <Clear/>
        </IconButton>

        <Box
          sx={{
            mt: 3,
            '& td': {
              'text-align': 'center',
            }
          }}
        >
          <table style={{width: '100%'}}>
            <colgroup>
              <col span={1} style={{width: '60%'}}/>
              <col span={1} style={{width: '20%'}}/>
              <col span={1} style={{width: '20%'}}/>
            </colgroup>

            <tbody>
            <tr>
              <td style={{textAlign: 'left'}}>
                <Typography variant="subtitle1" fontWeight="bold">Items</Typography>
              </td>
              <td>
                <Typography variant="subtitle1">Qty</Typography>
              </td>
              <td>
                <Typography variant="subtitle1">Price</Typography>
              </td>
            </tr>

            {formik.values.items.map((item, i) => (
              <tr key={i}>
                <td>
                  <TextField
                    placeholder="e-g goods/services..."
                    fullWidth
                    name="name"
                    autoComplete="off"
                    value={item.name}
                    onChange={(e) => formik.setFieldValue('items', produce(formik.values.items, draft => {
                      draft[i].name = e.target.value;
                    }))}
                  />
                </td>

                <td>
                  <TextField
                    fullWidth
                    name="qty"
                    autoComplete="off"
                    value={item.qty}
                    onChange={(e) => formik.setFieldValue('items', produce(formik.values.items, draft => {
                      draft[i].qty = e.target.value;
                    }))}
                  />
                </td>

                <td>
                  <TextField
                    placeholder="$ 00.00"
                    fullWidth
                    name="price"
                    autoComplete="off"
                    value={item.price}
                    onChange={(e) => formik.setFieldValue('items', produce(formik.values.items, draft => {
                      draft[i].price = e.target.value;
                    }))}
                  />
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </Box>

        <Typography sx={{ml: 2, mt: 1, cursor: 'pointer'}} color="#75b3ff" onClick={handleAddItem}>
          + Add an item
        </Typography>

        <Divider sx={{my: 2}}/>

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" fontWeight={600}>Total:</Typography>
          <Typography fontSize={24} fontWeight="bold">${totalPrice.toFixed(2)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="center" sx={{mt: 5}}>
          <DialogActionButton onClick={handleAdd}>
            Add
          </DialogActionButton>
        </Stack>
      </Box>
    </Dialog>
  )
}