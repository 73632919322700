import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Input, Stack, Typography } from "@mui/material";
import uploadFilesIcon from "../assets/icons/upload-files-icon.svg";
import { FileObj } from "../api/types";
import TextField from "@mui/material/TextField";
import { SxProps, CircularProgress } from "@mui/material";
import { getMimeType } from "../util/common";
import { ArrowForward } from "@mui/icons-material";

export const readAsDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      let result = reader.result as string;
      const mime =
        getMimeType(file.name) || file.type || "application/octet-stream";
      const base64Data = result.split(",")[1];
      result = `data:${mime};base64,${base64Data}`;
      resolve(result);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

interface FileUploadProps {
  onSubmit: (file: FileObj | null) => void;
  allowedFileTypes?: string[];
  noBorder?: boolean;
  noTitle?: boolean;
  label?: string;
  sx?: SxProps;
  loading?: boolean;
}

export default function FileUpload(props: FileUploadProps) {
  const [selectedFile, setSelectedFile] = useState<FileObj | null>(null);
  const [newFileName, setNewFileName] = useState("");

  const handleFilesChange = async (files: FileList) => {
    // Select only the first file in the list:
    const file = files[0];

    if (file) {
      try {
        const dataUrl = await readAsDataURL(file);

        // Set the selected file and its preview:
        setSelectedFile({
          name: file.name,
          data: dataUrl,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (selectedFile) {
      setNewFileName(selectedFile.name);
    }
  }, [selectedFile]);

  const openFileDialog = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.value = "";
    fileInput.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const droppedFiles = event.dataTransfer.files as FileList;
    if (droppedFiles.length > 0) {
      handleFilesChange(droppedFiles);
    }
  };

  return (
    <Box sx={{ ...props.sx }}>
      {props.noTitle ? (
        <></>
      ) : (
        <Typography fontFamily="Helvetica Neue">{props.label}</Typography>
      )}
      <Box
        display="flex"
        flexWrap="wrap"
        sx={{
          minHeight: "18.2143rem",
          border: props.noBorder ? "none" : "2px solid #DBE2E6",
          borderRadius: 2,
          maxHeight: "14.2857rem",
          overflowY: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Responsible to open the add files' dialog: */}
        <Input
          id="fileInput"
          type="file"
          inputProps={{
            ...(props.allowedFileTypes && {
              accept: props.allowedFileTypes.join(", "),
            }),
          }}
          onChange={(e) =>
            handleFilesChange((e.target as HTMLInputElement).files as FileList)
          }
          style={{ display: "none" }}
        />

        {/* If there are files - show them as card view */}
        {selectedFile ? (
          <Stack width="95%" spacing={2}>
            <Box>
              <Typography fontFamily="Helvetica Neue" fontWeight="bold">
                Current file name:{" "}
              </Typography>
              <Typography>{selectedFile.name}</Typography>
            </Box>
            <Box>
              <Typography fontFamily="Helvetica Neue" fontWeight="bold">
                New file name (optional):{" "}
              </Typography>
              <TextField
                variant="standard"
                placeholder={"Enter new name..."}
                fullWidth
                sx={{
                  mt: 1,
                  height: "2.8571rem",
                  border: "1.4px solid",
                  borderImage:
                    "linear-gradient(90deg, #C7A5FF 0%, #68B3FF 100%) 1",
                  borderRadius: 1,
                }}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    pl: 1.5,
                  },
                }}
                onChange={(e) => setNewFileName(e.target.value)}
              />
            </Box>
            <Stack alignItems="center">
              <Button
                variant="contained"
                endIcon={<ArrowForward />}
                sx={{
                  width: "11rem",
                  borderRadius: "5rem",

                  background: "linear-gradient(90deg, #59b5d9, #6a91f2)",
                  textTransform: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "1.2rem",
                  pt: 1,
                  mb: 2,
                }}
                onClick={() => {
                  const fileToSend: FileObj = {
                    name: newFileName,
                    data: selectedFile.data,
                  };
                  props.onSubmit?.(fileToSend);
                }} // disabled={props.loading || props.disabled}
              >
                {/* {props.loading ? <CircularProgress size={20} sx={{ my: 1 }}/> :  */}
                <Typography
                  fontFamily="Helvetica Neue"
                  fontSize={"1.3rem"}
                  color="white"
                >
                  {props.loading ? <CircularProgress /> : "Add file"}
                </Typography>
              </Button>
              {/* //   } */}
              {/* </Button>
          <IconButton
            sx={{
              boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.3)",
              background: "linear-gradient(#fbadfd 0%, #ad4bfb 100%)",
              borderRadius: "4rem",
              height: "2.4rem",
              width: "6rem",
            }}
            onClick={() => {
              const fileToSend: FileObj = {
                name: newFileName,
                data: selectedFile.data
              }
              props.onSubmit?.(fileToSend);
            }}
          >
              <Typography fontFamily="Helvetica Neue" fontSize={"1.3rem"} color="white">
                Add file
              </Typography>
          </IconButton> */}
            </Stack>
          </Stack>
        ) : (
          // If no files - show the "Choose a file or drag it here." message
          <Stack
            alignItems="center"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={openFileDialog}
            sx={{ cursor: "pointer" }}
          >
            <Box
              component="img"
              src={uploadFilesIcon}
              width={"6rem"}
              sx={{ mb: 2 }}
            />
            <Typography
              fontFamily="Helvetica Neue"
              color="black"
              fontSize="1.35rem"
            >
              <b>Choose a file</b> or drag it here.
            </Typography>
          </Stack>
        )}
      </Box>
    </Box>
  );
}
