import { Availability } from "../api/types";
import { Box, SxProps, Typography } from "@mui/material";

export interface AvailabilityReadOnlyChipProps {
  value: Availability;
  sx?: SxProps;
  height?: number;
  width?: number;
}

export default function AvailabilityReadOnlyChip(
  props: AvailabilityReadOnlyChipProps
) {
  return (
    <Box
      sx={{
        borderRadius: "3.5714rem",
        height: props.height ? `${props.height / 14}rem` : "2.1429rem",
        width: props.width ? `${props.width / 14}rem` : "8.9286rem",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        bgcolor:
          props.value === Availability.AVAILABLE
            ? "#37ce4a30"
            : props.value == Availability.ON_HOLD
            ? "#ff7a0019"
            : props.value == Availability.NOT_AVAILABLE
            ? "#ff000019"
            : "#5d6063",
      }}
    >
      <Typography
        sx={{
          fontSize: props.height ? `${props.height / 2.5 / 14}` : "1rem",
          color:
            props.value === Availability.AVAILABLE
              ? "#37ce4a"
              : props.value == Availability.ON_HOLD
              ? "#ff7a00"
              : props.value == Availability.NOT_AVAILABLE
              ? "#ff0000"
              : "#ecf0f5",
        }}
      >
        {props.value === Availability.AVAILABLE
          ? "Available"
          : props.value === Availability.ON_HOLD
          ? "On hold"
          : props.value === Availability.NOT_AVAILABLE
          ? "Not available"
          : ""}
      </Typography>
    </Box>
  );
}
