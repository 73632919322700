import { MynkFormControlCommonProps } from "./types";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { ChipSelectOption } from "../../ChipSelect";
import { useIsInEditable } from "../context";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface MynkFormColoredChipSelectProps
  extends MynkFormControlCommonProps<string> {
  options: ChipSelectOption[];
}

export function MynkFormColoredChipSelect(
  props: MynkFormColoredChipSelectProps
) {
  const formik = useFormikContext();
  const selectedVal = formik?.getFieldProps?.(props.name).value;
  const selectedOption = props.options.find(
    (option) => option.value === selectedVal
  );
  const selectedColor = selectedOption?.color;
  const isInEditable = useIsInEditable();

  return (
    <Stack direction="row" justifyContent="space-between">
      {!isInEditable && (
        <Typography variant="subtitle1">{props.label}</Typography>
      )}
      <Stack sx={{ alignItems: "center" }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            bgcolor: "white",
            borderRadius: "10rem",
            boxShadow: "0px 0.0714rem 0.3571rem rgba(117, 179, 255, 0.35)",
            height: "2.2rem",
            border: `0.1rem solid ${selectedColor}`,
          }}
        >
          <Box
            sx={{
              bgcolor: selectedColor,
              borderRadius: "50%",
              width: "1.4rem",
              height: "1.4rem",
              ml: "0.4rem",
              aspectRatio: "1/1",
            }}
          />
          <Select
            variant="standard"
            {...(formik?.getFieldProps?.(props.name) ?? {
              name: props.name,
              value: props.default,
            })}
            sx={{
              bgcolor: "white",
              width: "100%",
              height: "2.2rem",
              px: "0.5rem",
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "14.2857rem",
                },
              },
            }}
            IconComponent={() => <ExpandMoreIcon sx={{ color: selectedColor, width: "1.5rem", height: "1.5rem" }} />}
            renderValue={() => (
              <div
                style={{
                  fontSize: "1.1rem",
                }}
              >
                {selectedOption
                  ? selectedOption.shortenLabel ?? selectedOption.label
                  : props.placeholder}
              </div>
            )}
          >
            {props.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Box
                  sx={{
                    bgcolor: option.color,
                    borderRadius: "50%",
                    width: "1.3rem",
                    height: "1.3rem",
                    aspectRatio: "1/1",
                    mr: "0.4rem",
                    ml: "-0.4rem",
                  }}
                />
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
    </Stack>
  );
}
