import { useFormikContext } from "formik";
import { ReactNode } from "react";
import { MynkFormControl } from "./MynkFormControl";
import { MynkFormControlKind } from "./controlTypes";

export interface FormValues {
    [key: string]: any;
}

interface MynkFormConditionalProps {
  ifWhat: string;
  equalTo: any;
  children?: ReactNode;
}

export default function MynkFormConditional(props: MynkFormConditionalProps) {
  const formik = useFormikContext<FormValues>();
  if (formik && formik.values[props.ifWhat] === props.equalTo) {
    return <>{props.children}</>;
  }
  return <></>;
}
