import ActionMenuButton from "../../../../components/ActionMenuButton";
import { MynkTab } from "../../../../components/MynkPage";
import CalendarCard from "../common/CalendarCard";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import { AddEventDialog } from "../../../../dialogs/AddEvent";
import AddReminderDialog from "../../../../dialogs/AddReminder";
import AddTaskDialog from "../../../../dialogs/AddTask";
import { Stack } from "@mui/material";

enum NewMenuIds {
  NEW_EVENT = "new-event",
}

const NEW_MENU = [
  {
    id: NewMenuIds.NEW_EVENT,
    label: "New event",
  },
  {
    id: "new-task",
    label: "New task",
  },
  {
    id: "new-reminder",
    label: "New reminder",
  },
];

function SchedulerActionMenuButton() {
  const [addEventOpen, setAddEventOpen] = useState(false);
  const [addTaskOpen, setAddTaskOpen] = useState(false);
  const [addReminderOpen, setAddReminderOpen] = useState(false);

  const handleClickItem = (id: string) => {
    switch (id) {
      case NewMenuIds.NEW_EVENT:
        setAddEventOpen(true);
        break;
      case "new-task":
        setAddTaskOpen(true);
        break;
      case "new-reminder":
        setAddReminderOpen(true);
        break;
    }
  };

  return (
    <>
      <AddEventDialog open={addEventOpen} onClose={() => setAddEventOpen(false)} />
      <AddTaskDialog open={addTaskOpen} onClose={() => setAddTaskOpen(false)} />
      <AddReminderDialog open={addReminderOpen} onClose={() => setAddReminderOpen(false)} />
      <ActionMenuButton
        startIcon={<Add sx={{ width: "1.1rem" }} />}
        menu={NEW_MENU}
        onClickItem={handleClickItem}
      >
        New
      </ActionMenuButton>
    </>
  );
}

export default function SchedulerCalendarSubpage() {
    return (
      <MynkTab
        title="Calendar"
        action={
          <Stack flexDirection={'row'}>
            <SchedulerActionMenuButton />
          </Stack>
        }
      >
        <CalendarCard />
      </MynkTab>
    );
  }
  