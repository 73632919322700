import {
  AddEventParams,
  AddEventResponse,
  DeleteEventParams,
  DeleteEventResponse,
  EditEventParams,
  EditEventResponse,
  ListEventsParams,
  ListEventsResponse,
  AddMeetingTypeParams,
  AddMeetingTypeResponse,
  DeleteMeetingTypeParams,
  DeleteMeetingTypeResponse,
  ListMeetingTypesResponse,
  EditAvailableMeetingTimesParams,
  EditAvailableMeetingTimesResponse,
  ListAvailableMeetingTimesResponse,
  AddDescriptionParams,
  AddDescriptionResponse,
  EditMeetingTypeParams,
  EditMeetingTypeResponse,
  GetPortalParams,
  ListAvailableMeetingTimesPortalParams,
  ListAvailableMeetingTimesPortalResponse,
  SendConfirmationMeetingEmailParams,
  SendConfirmationMeetingEmailResponse,
  ListMeetingsParams,
  ListMeetingsResponse,
  GetMeetingTypeParams,
  GetMeetingTypeResponse,
  ListGoogleCalendarEventsParams,
  ListGoogleCalendarEventsResponse
} from "./types";
import { useMutation, useQueryClient, useQuery, UseMutationOptions } from "@tanstack/react-query";
import axios from "axios";

export function useAddEventMutation(
  options: UseMutationOptions<AddEventResponse, unknown, AddEventParams>
) {
  const queryClient = useQueryClient();
  return useMutation<AddEventResponse, unknown, AddEventParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/scheduler/add-event", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["events-list"]});
    },
    ...options
  });
}

export function useDeleteEventMutation() {
  const queryClient = useQueryClient();
  return useMutation<DeleteEventResponse, unknown, DeleteEventParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/scheduler/delete-event", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["events-list"]});
    },
  });
}

export function useEditEventMutation(  options?: UseMutationOptions<EditEventResponse, unknown, EditEventParams>
  ) {
  const queryClient = useQueryClient();
  return useMutation<EditEventResponse, unknown, EditEventParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/scheduler/edit-event", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["events-list"]});
    },
    ...options
  });
}

export function useListEventsQuery(params: ListEventsParams) {
  return useQuery<ListEventsResponse>({
    queryKey: ["events-list", params],
    queryFn: async () => (await axios.get("/api/scheduler/list-events", { params })).data
});
}

export function useAddMeetingTypeMutation(
  options: UseMutationOptions<AddMeetingTypeResponse, unknown, AddMeetingTypeParams>
) {
  const queryClient = useQueryClient();
  return useMutation<AddMeetingTypeResponse, unknown, AddMeetingTypeParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/scheduler/add-meeting-type", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["meeting-types-list"]});
    },
    ...options
  });
}

export function useDeleteMeetingTypeMutation() {
  const queryClient = useQueryClient();
  return useMutation<DeleteMeetingTypeResponse, unknown, DeleteMeetingTypeParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/scheduler/delete-meeting-type", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["meeting-types-list"]});
    },
  });
}

export function useEditMeetingTypeMutation() {
  const queryClient = useQueryClient();
  return useMutation<EditMeetingTypeResponse, unknown, EditMeetingTypeParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/scheduler/edit-meeting-type", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["meeting-types-list"]});
    },
  });
}

export function useListMeetingTypesQuery() {
  return useQuery<ListMeetingTypesResponse>({
    queryKey: ["meeting-types-list"],
    queryFn: async () => (await axios.get("/api/scheduler/list-meeting-types")).data
  });
}

export function useGetMeetingTypeMutation() {
  const queryClient = useQueryClient();
  return useMutation<GetMeetingTypeResponse, unknown, GetMeetingTypeParams>({
    mutationFn: async (params) =>
      (await axios.get("/api/scheduler/get-meeting-type-portal", {params})).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["meeting-type"]});
    },
  });
}

export function useEditAvailableMeetingTimesMutation(
  options: UseMutationOptions<EditAvailableMeetingTimesResponse, unknown, EditAvailableMeetingTimesParams>
) {
  const queryClient = useQueryClient();
  return useMutation<EditAvailableMeetingTimesResponse, unknown, EditAvailableMeetingTimesParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/scheduler/edit-available-meeting-times", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["available-meeting-times-list"]});
    },
    ...options
  });
}

export function useListAvailableMeetingTimesQuery() {
  return useQuery<ListAvailableMeetingTimesResponse>({
    queryKey: ["available-meeting-times-list"],
    queryFn: async () => (await axios.get("/api/scheduler/list-available-meeting-times")).data
  });
}

export function useAddDescriptionMutation(
  options: UseMutationOptions<AddDescriptionResponse, unknown, AddDescriptionParams>
) {
  const queryClient = useQueryClient();
  return useMutation<AddDescriptionResponse, unknown, AddDescriptionParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/scheduler/add-description", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({queryKey: ["portal"]});
    },
    ...options
  });
}

export function useGetPortalQuery(params: GetPortalParams) {
  return useQuery({
    queryKey: ["portal"],
    queryFn: async () => (await axios.get("/api/scheduler/get-portal", { params })).data
  });
}

export function useListMeetingTypesPortalQuery(params: GetPortalParams) {
  return useQuery<ListMeetingTypesResponse>({
    queryKey: ["meeting-types-list", params],
    queryFn: async () => (await axios.get("/api/scheduler/list-meeting-types-portal", { params })).data
  });
}

export function useListAvailableMeetingTimesPortalQuery(params: ListAvailableMeetingTimesPortalParams) {
  return useQuery<ListAvailableMeetingTimesPortalResponse>({
    queryKey: ["available-meeting-times-list", params],
    queryFn: async () => (await axios.get("/api/scheduler/list-available-meeting-times-portal", { params })).data
  });
}

export function useSendMeetingConfirmationEmailMutation(
  options: UseMutationOptions<
    SendConfirmationMeetingEmailResponse,
    unknown,
    SendConfirmationMeetingEmailParams
  >
) {
  return useMutation<
  SendConfirmationMeetingEmailResponse,
    unknown,
    SendConfirmationMeetingEmailParams
  >({
    mutationFn: async (params) => {
      return (await axios.post("/api/scheduler/send-meeting-confirmation-email", params))
        .data;
    },
    ...options,
  });
}

export function useListMeetingsQuery(params: ListMeetingsParams) {
  return useQuery<ListMeetingsResponse>({
    queryKey: ["meetings-list", params],
    queryFn: async () => (await axios.post("/api/scheduler/list-meetings", params)).data
  });
}

export function useListGoogleCalendarEventsQuery(params: ListGoogleCalendarEventsParams) {
  return useQuery<ListGoogleCalendarEventsResponse>({
    queryKey: ["google-events-list", params],
    queryFn: async () => (await axios.post("/api/scheduler/google-calendar-events", params)).data
  });
}