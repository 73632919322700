import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { Email, Phone } from "@mui/icons-material";
import {
  DeleteProjectParams,
  ListingStage,
  Priority,
  PhotographyProject,
  ProjectStage,
  WorkflowType,
} from "../api/types";
import PriorityChip from "./PriorityChip";
import { ChipSelect } from "./ChipSelect";
import { ALL_PROJECT_STAGE_OPTIONS, LEAD_STAGE_OPTIONS } from "./ChipSelect/commonOptions";
import { getProjectStagePlural, getProjectStageSingular } from "../util/common";
import { useNavigate } from "react-router-dom";
import {
  useDeleteProjectMutation,
  useListProjectsQuery,
  useSetProjectPriorityMutation,
  useSetProjectStageMutation,
} from "../api/projects";
import { atom, useAtom } from "jotai";
import ThreeDotsButton from "./ThreeDotsButton";
import ConfirmActionDialog from "./ConfirmActionDialog";
import EditProjectDialog from "../dialogs/EditProject";
import arrowRightShowMore from "../assets/icons/arrow-right-show-more.svg";
import arrowDownShowMoreOpened from "../assets/icons/arrow-down-show-more-opened.svg";
import { useState } from "react";
import { makeEmptyDomState } from "../pages/MynkPhotography/InstantiateTemplate/common";
import { PATHS, WorkflowPath, makePath } from "../paths";

export const selectedLeadAtom = atom<PhotographyProject | null>(null);
export const openedEditAtom = atom(false);
export const openedDeleteAtom = atom(false);

enum threeDotsOptions {
  EDIT = "edit-laed",
  DELETE = "delete-lead",
}

const DOTS_MENU = [
  {
    id: threeDotsOptions.EDIT,
    label: "Edit",
  },
  {
    id: threeDotsOptions.DELETE,
    label: "Delete",
  },
];

interface ThreeDotsLeadProps {
  project: PhotographyProject;
}

/**
 * Renders a button with three dots. When the button is clicked, it opens a menu with options to edit or delete a Lead.
 * Also renders two dialog components, `ConfirmDeleteDialog` and `EditLeadDialog`, which are opened when the corresponding menu options are selected.
 *
 * @returns The rendered `ThreeDotsLead` component with a button and two dialog components.
 */
function ThreeDotsLead(props: ThreeDotsLeadProps) {
  const [selectedLead, setSelectedLead] = useAtom(selectedLeadAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);

  /**
   * Handles the click event on the menu options.
   * Sets the corresponding state variable to `true` to open the corresponding dialog component.
   *
   * @param id - The ID of the clicked menu option.
   */
  const handleClickItem = (id: string) => {
    setSelectedLead(props.project);

    switch (id) {
      case threeDotsOptions.EDIT:
        setOpenedEdit(true);
        break;
      case threeDotsOptions.DELETE:
        setOpenedDelete(true);
        break;
    }
  };

  return (
    <ThreeDotsButton
      menu={DOTS_MENU}
      onClickItem={handleClickItem}
      children={undefined}
    ></ThreeDotsButton>
  );
}

export enum LeadViewType {
  LIST,
  CARD,
}

interface CardTypeContentsProps {
  project: PhotographyProject;
}

function CardTypeContents(props: CardTypeContentsProps) {
  
  const setProjectStage = useSetProjectStageMutation();
  const handleChangeStage = (value: string) => {
    setProjectStage.mutate({
      uuid: props.project.uuid!,
      stage: value as ProjectStage,
    });
  };

  const setLeadPriority = useSetProjectPriorityMutation();
  const handleChangePriority = (value: string) => {
    setLeadPriority.mutate({
      uuid: props.project.uuid,
      priority: value as Priority,
    });
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center">
        <Typography fontSize="1.3rem" color="black" fontWeight="bold" sx={{ ml: 1, mt: 0.2}}>
          {props.project.name}
        </Typography>

        <Box sx={{ flex: 1 }} />

        <ThreeDotsLead project={props.project} />
      </Stack>

      <Stack
        direction="row"
        alignItems="baseline"
        sx={{ mt: 7.9, mb: 1 }}
        justifyContent="center"
        spacing={2}
      >
        <ChipSelect
          sx={{ bgcolor: "white", width: "8rem", height: "2.5rem" }}
          options={ALL_PROJECT_STAGE_OPTIONS}
          value={props.project.stage}
          onChange={handleChangeStage}
        />
        <PriorityChip
          value={props.project.priority}
          onChange={handleChangePriority}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "7.9rem",
            height: "2.5rem",
            textAlign: "center",
          }}
        />
      </Stack>
    </Box>
  );
}

interface ListTypeContentsProps {
  project: PhotographyProject;
}

function ListTypeContents(props: ListTypeContentsProps) {
  const navigate = useNavigate();

  const handleView = () => {
    console.log(props.project);
    navigate(
      makePath(
        WorkflowPath.PHOTOGRAPHY,
        PATHS.viewProject(props.project.uuid).general
      )
    );
  };

  const setProjectStage = useSetProjectStageMutation();
  const handleChangeStage = (value: string) => {
    setProjectStage.mutate({
      uuid: props.project.uuid!,
      stage: value as ProjectStage,
    });
  };

  const setLeadPriority = useSetProjectPriorityMutation();
  const handleChangePriority = (value: string) => {
    setLeadPriority.mutate({
      uuid: props.project.uuid,
      priority: value as Priority,
    });
  };

  return (
    <Grid container alignItems="center" height="3.7rem" sx={{ py: 0.3, pl: 2 }}>
      <Grid item xs={6.3}>
        <Typography fontSize="1.37rem" fontWeight="bold">
          {props.project.name}
        </Typography>
      </Grid>

      <Grid item xs={1.8}>
        <Link
          variant="body2"
          sx={{ color: "#75b3ff", textDecoration: "none", cursor: "pointer" }}
        >
          {props.project.client_name}
        </Link>
      </Grid>
      <Grid item xs={2.9}>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <ChipSelect
            options={ALL_PROJECT_STAGE_OPTIONS}
            value={props.project.stage}
            onChange={handleChangeStage}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 1px 5px rgba(117, 179, 255, 0.35)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "2.5rem",
              width: "8rem",
              textAlign: "center",
            }}
          />

          <PriorityChip
            value={props.project.priority}
            onChange={handleChangePriority}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "7.7rem",
              height: "2.5rem",
              textAlign: "center",
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={1}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          alignItems="baseline"
        >
          <Link
            variant="body2"
            sx={{ color: "#75b3ff", textDecoration: "none", cursor: "pointer" }}
            onClick={handleView}
          >
            View
          </Link>
          <ThreeDotsLead project={props.project} />
        </Stack>
      </Grid>
    </Grid>
  );
}

interface LeadCardProps {
  viewType: LeadViewType;
  project: PhotographyProject;
}

function LeadCard(props: LeadCardProps) {
  return (
    <Box
      sx={{
        p: 1,
        pl: 2,
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%)",
        borderRadius: "1.1rem",
        border: "1px solid white",
        boxShadow: "0px 5px 25px rgba(117, 179, 255, 0.10)",
      }}
    >
      {props.viewType === LeadViewType.CARD && (
        <CardTypeContents project={props.project} />
      )}
      {props.viewType === LeadViewType.LIST && (
        <ListTypeContents project={props.project} />
      )}
    </Box>
  );
}

export interface LeadViewProps {
  title: string;
  status?: ProjectStage;
  viewType?: LeadViewType;
  allowAdd?: boolean;
  onAdd?: () => void;
  sx?: SxProps;
}

export default function LeadView(props: LeadViewProps) {
  const [openedShowMore, setOpenedShowMore] = useState(false);

  const { data, isPending } = useListProjectsQuery({
    stage: props.status,
  });

  const viewType = props.viewType ?? LeadViewType.CARD;

  return (
    <>
      <Box sx={{ ...props.sx }}>
        <Stack
          direction="row"
          alignItems="baseline"
          sx={{ pl: 1.5 }}
          spacing={0.8}
        >
          <Typography variant="h4" fontFamily="Helvetica Neue">
            {props.title}
          </Typography>
          {viewType == LeadViewType.CARD && <Box sx={{ flex: 1 }} />}
          <Box width="1.5rem" />
          <Box
            component="img"
            src={openedShowMore ? arrowDownShowMoreOpened : arrowRightShowMore}
            sx={{ cursor: "pointer", width: "0.8rem", height: "0.8rem" }}
            onClick={() => setOpenedShowMore(!openedShowMore)}
          />
          <Typography fontSize={"1.2rem"}>
            {data?.projects.length ?? "..."}
          </Typography>
        </Stack>

        {!data || isPending ? (
          <CircularProgress sx={{ display: "table", mx: "auto" }} />
        ) : data.projects.length === 0 ? (
          <></>
        ) : (
          <Stack
            spacing={viewType == LeadViewType.LIST ? 1.1 : 2.7}
            sx={{ mt: 2.7, width: viewType == LeadViewType.CARD ? "25.5rem" : "auto" }}
          >
            {openedShowMore
              ? data.projects.map((project, i) => (
                  <LeadCard
                    key={i}
                    project={project as PhotographyProject}
                    viewType={viewType}
                  />
                ))
              : data.projects
                  .slice(0, 3)
                  .map((project, i) => (
                    <LeadCard
                      key={i}
                      project={project as PhotographyProject}
                      viewType={viewType}
                    />
                  ))}
          </Stack>
        )}

        
      </Box>
    </>
  );
}
