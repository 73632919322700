import { Box, Grid } from "@mui/material";
import { MynkTab } from "../../../../components/MynkPage";
import LeadView, { LeadViewType, openedDeleteAtom, openedEditAtom, selectedLeadAtom } from "../../../../components/LeadView";

import listViewSwitchOn from "../../../../assets/icons/list-view-switch-on.svg";
import listViewSwitchOff from "../../../../assets/icons/list-view-switch-off.svg";
import cardViewSwitchOn from "../../../../assets/icons/card-view-switch-on.svg";
import cardViewSwitchOff from "../../../../assets/icons/card-view-switch-off.svg";
import verticalLineSeperatesIcon from "../../../../assets/icons/vertical-line-seperates-icon.svg";
import { ClientsAllSubpageNewButton } from "./All";
import { useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { currentWorkflowAtom } from "../../../../components/MainAppBar/UserControlPopup";
import { DeleteProjectParams, ListingStage, ProjectStage, WorkflowType } from "../../../../api/types";
import { useDeleteProjectMutation } from "../../../../api/projects";
import { useDeleteListingMutation } from "../../../../api/listings";
import ConfirmActionDialog from "../../../../components/ConfirmActionDialog";
import EditProjectDialog from "../../../../dialogs/EditProject";
import { AddLeadDialog } from "../../../../dialogs/AddLead";



interface LeadGroup {
  status: ProjectStage | ListingStage;
  title: string;
}

const LISTINGS_LEAD_GROUPS: LeadGroup[] = [
  { status: ListingStage.INTEREST, title: "Inquiry / Interest" },
  { status: ListingStage.PROPERTY_VIEWING, title: "Property viewing" },
  { status: ListingStage.UNDER_CONTRACT, title: "Under contract" },
];

const PROJECTS_LEAD_GROUPS: LeadGroup[] = [
  { status: ProjectStage.LEAD, title: "Leads" },
  { status: ProjectStage.PROPOSAL, title: "Proposals" },
  { status: ProjectStage.UNDER_CONTRACT, title: "Under contract" },
  { status: ProjectStage._CLOSED_WON, title: "Closed/Won" },
];

interface leadViewGroupsProps {
  viewType: LeadViewType;
  distance?: number;
}

export function LeadViewGroups(props: leadViewGroupsProps) {
  const [addLeadOpen, setAddLeadOpen] = useState(false);
  const [targetStatus, setTargetStatus] = useState<
    ProjectStage | ListingStage | undefined
  >(undefined);
  const [selectedLead, setSelectedLead] = useAtom(selectedLeadAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);

  let deleteLead:
    | ReturnType<typeof useDeleteProjectMutation>
    | ReturnType<typeof useDeleteListingMutation>
    | undefined;

  // Fetch delete lead:
  switch (useAtomValue(currentWorkflowAtom)?.type) {
    case WorkflowType.PHOTOGRAPHY:
      deleteLead = useDeleteProjectMutation();
      break;
    case WorkflowType.REAL_ESTATE:
      deleteLead = useDeleteListingMutation();
      break;
  }

  const handleDeleteLead = async (uuid: string) => {
    setOpenedDelete(false);

    const params = {
      uuid: uuid,
    } as DeleteProjectParams;

    await deleteLead?.mutateAsync(params);
    setOpenedDelete(false);
  };

  const LEAD_GROUPS =
    useAtomValue(currentWorkflowAtom)?.type === WorkflowType.PHOTOGRAPHY
      ? PROJECTS_LEAD_GROUPS
      : LISTINGS_LEAD_GROUPS;

  return (
    <>
      {/* Confirm delete lead pop-up window */}
      <ConfirmActionDialog
        open={openedDelete}
        what="lead"
        whatAction="delete"
        onClose={() => setOpenedDelete(false)}
        onConfirm={() =>
          selectedLead ? handleDeleteLead(selectedLead.uuid) : undefined
        }
        loading={deleteLead?.isPending ?? true}
      >
        Delete
      </ConfirmActionDialog>

      {/* Confirm edit lead pop-up window */}
      <EditProjectDialog
        open={openedEdit}
        onClose={() => setOpenedEdit(false)}
        project={selectedLead}
      />

      <AddLeadDialog
        open={addLeadOpen}
        onClose={() => setAddLeadOpen(false)}
        status={targetStatus as ProjectStage}
      />

      {props.viewType === LeadViewType.LIST ? (
        LEAD_GROUPS.map((group) => (
          <Box key={group.title} sx={{ pb: props.distance ? `${props.distance / 14}rem` : 3.8 }}>
            <LeadView
              title={group.title}
              status={group.status as ProjectStage}
              viewType={LeadViewType.LIST}
              allowAdd
              onAdd={() => {
                setTargetStatus(group.status);
                setAddLeadOpen(true);
              }}
            />
          </Box>
        ))
      ) : props.viewType === LeadViewType.CARD ? (
        <Grid container spacing={6}>
          {LEAD_GROUPS.map((group, i) => (
            <Grid item xs={3} key={group.title}>
              {i > 0 && (
                <Box
                  sx={{
                    position: "fixed",
                    width: 1,
                    height: "calc(100% - 10.4286rem)",
                    borderLeft: "solid 1px #75b3ffb2",
                    mx: -3,
                    pointerEvents: "none",
                  }}
                />
              )}

              <LeadView
                title={group.title}
                status={group.status as ProjectStage}
                viewType={LeadViewType.CARD}
                allowAdd
                onAdd={() => {
                  setTargetStatus(group.status);
                  setAddLeadOpen(true);
                }}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}


interface ViewTypeSwitchProps {
  selectedView: LeadViewType;
  setSelectedView: (newSelectedView: LeadViewType) => void;
}

function ViewTypeSwitch(props: ViewTypeSwitchProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ position: "relative", mb: 1.3 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 1.3, mr: 1.5 }}
      >
        <Box
          component="img"
          src={
            props.selectedView == LeadViewType.LIST
              ? listViewSwitchOn
              : listViewSwitchOff
          }
          sx={{ cursor: "pointer", height: "2.5rem" }}
          onClick={() => props.setSelectedView(LeadViewType.LIST)}
        />
        <Box
          component="img"
          src={verticalLineSeperatesIcon}
          sx={{ height: "2rem" }}
        />
        <Box
          component="img"
          src={
            props.selectedView == LeadViewType.CARD
              ? cardViewSwitchOn
              : cardViewSwitchOff
          }
          sx={{ cursor: "pointer", height: "2.5rem" }}
          onClick={() => props.setSelectedView(LeadViewType.CARD)}
        />
      </Box>
    </Box>
  );
}

export default function ClientsCRMSubpage() {
  const [selectedView, setSelectedView] = useState(LeadViewType.LIST);
  return (
    <MynkTab
      title="CRM"
      action={<ClientsAllSubpageNewButton workflowType={useAtomValue(currentWorkflowAtom)?.type}/>}
      nextToAction={
        <ViewTypeSwitch
          selectedView={selectedView}
          setSelectedView={setSelectedView}
        />
      }
    >
      <LeadViewGroups viewType={selectedView} distance={51}/>
    </MynkTab>
  );
}
