import {
  Box,
  CircularProgress,
  Pagination,
  PaginationItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { ReactNode } from "react";
import PriorityChip from "../PriorityChip";
import {
  Availability,
  FilterOption,
  ListingType,
  Priority,
  RealEstateClientType,
  SearchOption,
  SortOption,
} from "../../api/types";
import computerIcon from "../../assets/icons/computer-icon.svg";
import mobilePhoneIcon from "../../assets/icons/mobile-phone-icon.svg";
import { ChipSelectOption } from "../ChipSelect";
import RealEstateClientTypeReadOnlyChip from "../RealEstateClientTypeReadOnlyChip";
import AvailabilityChip from "../AvailabilityChip";
import ListingTypeReadOnlyChip from "../ListingTypeReadOnlyChip";
import { formatMoney, zonedDate } from "../../util/common";
import { useAtomValue } from "jotai";
import { userCurrencyAtom } from "../../layouts/Main";
import { Skeleton } from '@mui/material';


export enum LoggedInHistoryIconType {
  MOBILE_PHONE = "phone",
  COMPUTER = "computer",
}

export enum SimpleTableColumnType {
  NAME = "name",
  NAME_TITLE = "name_title",
  DATE = "date",
  DATE_NO_TIME = "date_no_time",
  USD = "usd",
  PRIORITY = "priority",
  REAL_ESTATE_CLIENT_TYPE = "real_estate_client_type",
  REAL_ESTATE_LISTING_TYPE = "real_estate_listing_type",
  LINK = "view",
  SETTING = "setting",
  SETTING_VAL = "setting_value",
  PICTURE = "picture",
  HISTORY_INFO = "history_info",
  AVAILABILITY = "availability",
  END_DATE = "end_date"
}

export interface SimpleTableColumn {
  key: string;
  label: string;
  type?: SimpleTableColumnType;
  render?: (value: any, row: any) => ReactNode;
  hideHeader?: boolean;
}

interface SimpleTableRowProps {
  columns: SimpleTableColumn[];
  row: any;
  onClickLink?: (row: any) => void;
}

function getTimeAgo(timestamp: number): string {
  const now = new Date();
  const then = new Date(timestamp * 1000); // Convert the timestamp to milliseconds
  const secondsAgo = Math.floor((now.getTime() - then.getTime()) / 1000);

  if (secondsAgo < 60) {
    return `${secondsAgo} sec ago`;
  } else if (secondsAgo < 3600) {
    const minutesAgo = Math.floor(secondsAgo / 60);
    return `${minutesAgo} min ago`;
  } else if (secondsAgo < 86400) {
    const hoursAgo = Math.floor(secondsAgo / 3600);
    const minutesAgo = Math.floor((secondsAgo % 3600) / 60);
    return `${hoursAgo} hours and ${minutesAgo} min ago`;
  } else if (secondsAgo < 2592000) {
    // Approximately 30 days
    const daysAgo = Math.floor(secondsAgo / 86400);
    const hoursAgo = Math.floor((secondsAgo % 86400) / 3600);
    return `${daysAgo} days ago`;
  } else {
    let yearsAgo = now.getFullYear() - then.getFullYear();
    let monthsAgo = now.getMonth() - then.getMonth();
    let daysAgo = now.getDate() - then.getDate();

    if (monthsAgo < 0) {
      yearsAgo--;
      monthsAgo += 12;
    }

    if (daysAgo < 0) {
      monthsAgo--;
      const prevMonth = new Date(now.getFullYear(), now.getMonth(), 0);
      daysAgo += prevMonth.getDate();
    }

    if (yearsAgo > 0) {
      return `${yearsAgo} years and ${monthsAgo} months ago`;
    } else if (monthsAgo > 0) {
      return `${monthsAgo} months and ${daysAgo} days ago`;
    } else {
      return `${daysAgo} days ago`;
    }
  }
}

interface SimpleTableCellProps {
  row: any;
  column: SimpleTableColumn;
  value: any;
  onClickLink?: (row: any) => void;
  onChangePriority?: (value: string) => void;
  onChangeRealEstateClientType?: (value: string) => void;
}

function SimpleTableCellContents(props: SimpleTableCellProps) {
  const currencyCode = useAtomValue(userCurrencyAtom);

  switch (props.column.type) {
    case SimpleTableColumnType.NAME:{
      return (
        <div style={{maxWidth: "30rem"}}>
          <Typography
            sx={{
              fontSize: "1.37rem",
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
          >{props.value.toString()}</Typography>
        </div>
      );
    }
    case SimpleTableColumnType.NAME_TITLE:{
      return (
        <div style={{maxWidth: "30rem"}}>
          <Typography  sx={{     fontSize: "1.37rem",
            fontWeight: "bold",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textTransform: "capitalize",
            whiteSpace: "nowrap",}}>{props.value.toString()}</Typography></div>
      );
    }
    case SimpleTableColumnType.SETTING:
      return (
        <Typography fontSize={"1.3rem"}>{props.value.toString()}</Typography>
      )
    case SimpleTableColumnType.DATE: {
      console.log(props.value)
      if (!props.value) return <>N/A</>;

      const date = zonedDate(props.value);
      if (!date) return <>Invalid time</>;

      return (
        <Stack direction="row">
          <Typography fontSize={"1.15rem"} color="#404040" fontWeight="bold">
            {date.toFormat("EEEE")},
          </Typography>
          <Typography fontSize={"1.15rem"} color="#404040B2">
            &nbsp;{date.toFormat("LLL dd, yyyy")}
          </Typography>
        </Stack>
      );
    }

    case SimpleTableColumnType.DATE_NO_TIME: {
      if (!props.value) return <>N/A</>;

      const date = zonedDate(props.value);
      if (!date) return <>Invalid time</>;
      
      return (
        <Stack direction="row">
          <Typography fontSize={"1.15rem"} sx={{ color: "#404040", fontWeight: "bold", fontFamily: "Helvetica Neue" }}>
            {date.toFormat("EEEE")},
          </Typography>
          <Typography fontSize={"1.15rem"} sx={{ color: "#404040B2", fontFamily: "Helvetica Neue" }}>
            &nbsp;{date.toFormat("LLL dd, yyyy")}
          </Typography>
        </Stack>
      );
    }

    case SimpleTableColumnType.USD:
      return (
          <Typography variant="body2" fontWeight="bold">{formatMoney(props.value, currencyCode)}</Typography>
      );

    case SimpleTableColumnType.PRIORITY:
      return (
        <PriorityChip
          value={props.value as Priority}
          onChange={props.onChangePriority}
        />
      );

    case SimpleTableColumnType.AVAILABILITY:
      return <AvailabilityChip value={props.value as Availability} />;

    case SimpleTableColumnType.REAL_ESTATE_CLIENT_TYPE:
      return (
        <RealEstateClientTypeReadOnlyChip
          value={props.value as RealEstateClientType}
          width={100}
        />
      );

    case SimpleTableColumnType.REAL_ESTATE_LISTING_TYPE:
      return (
        <ListingTypeReadOnlyChip
          value={props.value as ListingType}
          width={100}
        />
      );

    case SimpleTableColumnType.LINK:
      return (
        <Typography
          variant="body2"
          sx={{ color: "#75B3FF", cursor: "pointer" }}
          onClick={() => props.onClickLink?.(props.row)}
        >
          {props.value ?? props.column.label}
        </Typography>
      );

    case SimpleTableColumnType.SETTING_VAL:
      return (
        <Typography fontFamily={"Helvetica Neue"}>
          {typeof(props.value) === "number" && !props.value ? 0 : props.value ? props.value.toString() : "N/A"}
        </Typography>
      );

    case SimpleTableColumnType.PICTURE:
      return props.value == LoggedInHistoryIconType.MOBILE_PHONE ? (
        <Box
          component="img"
          src={mobilePhoneIcon}
          sx={{ width: "3.7rem", height: "3.7rem" }}
        />
      ) : props.value == LoggedInHistoryIconType.COMPUTER ? (
        <Box
          component="img"
          src={computerIcon}
          sx={{ width: "3.4rem", height: "3.4rem" }}
        />
      ) : (
        <p>None</p>
      );

    case SimpleTableColumnType.HISTORY_INFO:
      const parts = props.value.split("#");
      const [device, location, browser, timestamp] = parts;
      const timeAgoStr = getTimeAgo(timestamp);

      return parts.length === 4 ? (
        <Typography fontFamily="Helvetica Neue" fontSize={"1.15rem"} align="left" color="#404040">
          {device} • {location} <br />
          {browser} • {timeAgoStr}
        </Typography>
      ) : (
        <Typography fontFamily="Helvetica Neue" fontSize={"1.15rem"} color="#404040">
          Something went wrong
        </Typography>
      );
    default:
      return props.value.toString();
  }
}

export function SimpleTableRow(props: SimpleTableRowProps) {
  return (
    <TableRow
      sx={{
        "&:hover": {
          background: "linear-gradient(#dddddd80, #cccccc40)", // Darker gradient on hover
        },
        cursor: "pointer", // Optional: Show pointer cursor on hover
      }}
      onClick={() => props.onClickLink && props.onClickLink(props.row)}
    >
      {props.columns.map((column, i) => {
        const value = props.row[column.key];

        // Define custom width for specific columns
        const columnWidths: { [key: string]: string } = {
          picture: "1.4rem",
        };

        const customWidth = columnWidths[column.key];

        return (
          <TableCell
            key={column.key ?? i}
            align={i === props.columns.length - 1 ? "right" : "left"}
            sx={{
              width: customWidth,
              maxWidth: customWidth,
              overflow: "hidden",
              p: 2.6,
              px: 4,
            }}
          >
            {column.render ? (
              column.render(value, props.row)
            ) : (
              <SimpleTableCellContents
                row={props.row}
                column={column}
                value={value}
                onClickLink={props.onClickLink}
              />
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export interface SimpleTableProps {
  data?: any[];
  columns: SimpleTableColumn[];
  selectedPage: number;
  setSelectedPage: (newPage: number) => void;
  pageSize: number;
  numOfPages: number;
  total: number;
  isPending?: boolean;
  onClickLink?: (row: any) => void;
  onChangePriority?: (value: string) => void;
  searchState?: [
    SearchOption,
    React.Dispatch<React.SetStateAction<SearchOption>>
  ];
  filterState?: [
    FilterOption,
    React.Dispatch<React.SetStateAction<FilterOption>>
  ];
  sortState?: [
    SortOption,
    React.Dispatch<React.SetStateAction<SortOption>>
  ];
  filterOptions?: ChipSelectOption[];
  sortOptions?: ChipSelectOption[];
  columnDivision?: string[];
  allColumnsWithoutInfo?: SimpleTableColumn[];
  selectedColumns?: string[];
  setSelectedColumns?: React.Dispatch<React.SetStateAction<string[]>>;
  downloadToCsv?: boolean;
}

export function SimpleTable(props: SimpleTableProps) {
  return (
    <>
      <Table>
        {props.columnDivision && 
        <colgroup>
          {props.columnDivision.map((currentWidth, index) => {
            return <col key={index} span={1} style={{ width: currentWidth }} />
          })}
        </colgroup>
        }
        
        <TableHead>
          <TableRow sx={{bgcolor: "#75b3ff30" }}>
            {props.columns.map((column) => (
              <TableCell key={column.key} sx={{ px: 4, fontSize: "1.18rem" }}>
                {!column.hideHeader ? column.label : ""}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {props.data && !props.isPending && (
          <TableBody>
            {props.data.map((row, i) => (
              <SimpleTableRow
                key={row?.id ?? i}
                row={row}
                columns={props.columns}
                onClickLink={props.onClickLink}
              />
            ))}
          </TableBody>
        )}
      </Table>

      {props.isPending && (
        <Skeleton variant="rounded" animation="wave" width={"100%"} height={"4.2rem"} sx={{ background: "transparent" }}/>
      )}

      <Stack direction="row" justifyContent="center" sx={{ my: 2 }}>
        <Pagination
          count={props.numOfPages}
          page={props.selectedPage + 1}
          onChange={(_, v) => v && props.setSelectedPage?.(v - 1)}
          variant="text"
          renderItem={(item) => <PaginationItem {...item} sx={{ width: "2rem", height: "2rem", borderRadius: 100, fontSize: "1.3rem" }}/>}
        />
      </Stack>
    </>
  );
}
