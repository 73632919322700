import React, {useCallback, useEffect, useMemo, useState} from "react";
import {MynkTab} from "../../../../components/MynkPage";
import {SimpleTableCard, SimpleTableColumnType, TableType} from "../../../../components/SimpleTable";
import {FileObj, FilterOption, ListSentTemplatesResponse, SearchOption, SortOption} from "../../../../api/types";
import {useListDefaultTemplatesQuery, useListSentTemplatesQuery} from "../../../../api/templates";
import {useAtomValue} from "jotai/index";
import {currentWorkflowAtom} from "../../../../components/MainAppBar/UserControlPopup";
import {makePath, PATHS, WorkflowPath} from "../../../../paths";
import {Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {fetchFileSrc} from "../../../../util/common";
import PreviewFileDialog from "../../../../components/PreviewFileDialog";
import { NewTemplatesButton } from "./All";
import { SORT_SENT_TEMPLATES_OPTIONS } from "../../../../components/ChipSelect/commonOptions";


const BASE_COLUMNS = [
  {
    key: "template_kind",
    label: "Document type",
    type: SimpleTableColumnType.NAME_TITLE,
  },
  {
    key: "client_name",
    label: "Client",
    type: SimpleTableColumnType.NAME,
  },
  {
    key: "issuing_date",
    label: "Issuing Date",
    type: SimpleTableColumnType.DATE,
  },
  {
    key: "doc_number",
    label: "Doc Number",
  },
  {
    key: "file_uuid",
    label: "A",
    type: SimpleTableColumnType.LINK,
  },
];

const PAGE_SIZE = 10;


export default function TemplatesSentSubpage() {
  const [selectedPage, setSelectedPage] = useState(0);

  const pageCount = useMemo(() => 1, []);
  const total = useMemo(() => 0, []);

  const [search, setSearch] = useState<SearchOption>({query: ""});
  const [filter, setFilter] = useState<FilterOption>({by: "", query: ""});
  const [sort, setSort] = useState<SortOption>({by: "", descending: false});

  const [current, setCurrent] = useState<null | ListSentTemplatesResponse>(null);
  const {data, isPending} = useListSentTemplatesQuery({
    page_size: PAGE_SIZE,
    page_index: selectedPage,
    retrieve_options: {
      search: search,
      filter: filter,
      sort: sort,
    },
  });

  useEffect(() => {
    if (!isPending && data) {
      setCurrent(data);
    }
  }, [data, isPending]);

  // file viewing:
  const [openViewFile, setOpenViewFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileObj | null>(null);


  const handleViewFile = useCallback(async (file_uuid: string) => {
    setSelectedFile(null);
    setOpenViewFile(true);
    const selectedFile: FileObj = {
      name: "file",
      data: (await fetchFileSrc(file_uuid, false)) ?? "",
      mime_type: "application/pdf",
    };

    setSelectedFile(selectedFile);
  }, []);

  const navigate = useNavigate();
  const columns = useMemo(() => {
    const result = [...BASE_COLUMNS];

    const clientIndex = result.findIndex((c) => c.key === "client_name");
    result[clientIndex] = {
      key: "client_name",
      label: "Client",
      render(value, row) {
        const workflowType = useAtomValue(currentWorkflowAtom)?.type;
        const handleViewContact = (uuid: string) => {
          navigate(
            makePath(
              `/${workflowType}` as WorkflowPath,
              PATHS.viewContact(uuid).general
            )
          );
        };

        return (
          <Typography
            variant="body2"
            sx={{color: "#75B3FF", cursor: "pointer"}}
            onClick={() => handleViewContact(row.client_uuid)}
          >
            {value ?? ""}
          </Typography>
        );

      },
    };

    // view
    const viewIndex = result.findIndex((c) => c.key === "file_uuid");
    result[viewIndex] = {
      key: "file_uuid",
      label: "",
      render: (value, row) => {
        return (
          <Typography
            variant="body2"
            sx={{color: "#75B3FF", cursor: "pointer", mr: 3}}
            onClick={() => handleViewFile(value)}
          >
            View
          </Typography>
        );
      }
    }

    return result;
  }, [navigate, handleViewFile]);

  const { data: defaultTemplatesData, isPending: defaultisPending } =
    useListDefaultTemplatesQuery({});
    
  return (
    <MynkTab
      title="Sent"
      action={
        <NewTemplatesButton defaultTemplates={defaultTemplatesData?.templates}/>
      }
    >
      <PreviewFileDialog
        open={openViewFile}
        onClose={() => setOpenViewFile(false)}
        selectedFile={selectedFile}
        disableDownload
      />

      <SimpleTableCard
        title="Documents info"
        data={current?.items}
        columns={columns}
        tableType={TableType.FILES}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        pageSize={PAGE_SIZE}
        numOfPages={current?.num_of_pages ?? 1}
        total={current?.total ?? 0}
        isPending={isPending}
        searchState={[search, setSearch]}
        filterState={[filter, setFilter]}
        sortState={[sort, setSort]}
        // filterOptions={FILTER_PROJECTS_OPTIONS}
        sortOptions={SORT_SENT_TEMPLATES_OPTIONS}
        // columnDivision={["30%", "15%", "20%", "15%", "15%"]}
      />
    </MynkTab>
  )
}
