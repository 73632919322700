import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {templateSentAtom, useCurrentTemplate,} from "./common";
import {
  ClientSummary,
  ProjectStage,
  SendTemplateParams,
  TemplateClientFieldSchema,
  TemplateCollabsableFieldSchema,
  TemplateFieldSchema,
  TemplateNumberFieldSchema,
  TemplateObjectFieldSchema,
  TemplateTableFieldSchema,
  PhotographyClientInfo,
  PhotographyProject,
  TemplateUnderCollapsableFieldSchema,
  GetClientResponse,
  GetProjectResponse,
} from "../../../api/types";
import ContactSearch from "../../../components/ContactSearch";
import {Atom, useAtom, useAtomValue, useSetAtom, WritableAtom} from "jotai";
import produce from "immer";
import {selectAtom, useAtomCallback} from "jotai/utils";
import {articleFromNoun, formatMoney, generateId, useReadAtom,} from "../../../util/common";
import {useDebouncedCallback} from "use-debounce";
import DialogActionButton from "../../../components/DialogActionButton";
import {useNavigate} from "react-router-dom";
import {templateStateAtom} from "../../../state/projects";
import {useSetProjectStageMutation, useGetProjectQuery} from "../../../api/projects";
import jsPDF from "jspdf";
import {useSendTemplateMutation} from "../../../api/templates";
import {closeSnackbar, useSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {makePath, PATHS, WorkflowPath} from "../../../paths";
import {currentWorkflowAtom} from "../../../components/MainAppBar/UserControlPopup";
import {useListGeneralSettingsQuery} from "../../../api/settings";
import {DateTime} from "luxon";
import {userCurrencyAtom} from "../../../layouts/Main";
import {useGetClientQuery} from "../../../api/clients";
import {Unstable_NumberInput as NumberInput} from "@mui/base/Unstable_NumberInput";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ElevatorDialog from "../../../components/ElevatorDialog";
import ClearIcon from '@mui/icons-material/Clear';
import {AxiosError} from "axios";
import checkedRadio from '../../../assets/icons/check-radio-button.svg'
import card from '../../../assets/icons/card.svg'
import bankTransfer from '../../../assets/icons/bank-transfer.svg'
import cash from '../../../assets/icons/cash.svg'
import uncheckedRadio from '../../../assets/icons/uncheck-radio-button.svg'
import { ChipSelect } from "../../../components/ChipSelect";
import {
  MONTHS_OPTIONS,
  TAXES_OPTIONS,
  DISCOUNT_OPTIONS
} from "../../../components/ChipSelect/commonOptions";
import { BoxWithGradientFrame } from "../../../components/GradientFrame";
import {useTemplateAtoms} from "./TemplateContext";
import { MynkForm, MynkFormControl } from "../../../components/MynkForm";
import { MynkFormControlKind } from "../../../components/MynkForm/controlTypes";
import ArrowRight from '../../../assets/icons/arrow-right-show-more.svg'
import ArrowDown from '../../../assets/icons/arrow-down-show-more-opened.svg'
import EmptyEllipseIcon from '../../../assets/icons/empty-elipse-icon.svg'
import FilledEllipseIcon from '../../../assets/icons/filled-elipse-icon.svg'
import LineIcon from '../../../assets/icons/line-icon.svg'
import MynkFormEditable from "../../../components/MynkForm/MynkFormEditable";

type PathDescriptor =
  | RootPathDescriptor
  | ObjectPathDescriptor
  | ArrayPathDescriptor
  | CollapsablePathDescriptor;

interface RootPathDescriptor {
  type: "root";
}

interface ObjectPathDescriptor {
  type: "object";
  arg: PathDescriptor;
  field: string;
}

interface ArrayPathDescriptor {
  type: "array";
  arg: PathDescriptor;
  index: number;
}

interface CollapsablePathDescriptor {
  type: "collapsable";
  arg: PathDescriptor;
  field: string;
}

function getByPath(root: any, path: PathDescriptor): any {
  switch (path.type) {
    case "root":
      return root;

    case "object":
      return getByPath(root, path.arg)[path.field];

    case "array":
      return getByPath(root, path.arg)[path.index];

    case "collapsable":
      return getByPath(root, path.arg)[path.field];

    default:
      throw "Unimplemented";
  }
}

function setByPath(root: any, path: PathDescriptor, value: any) {
  switch (path.type) {
    case "object":
      const arg = getByPath(root, path.arg);
      arg[path.field] = value;
      break;
    case "collapsable":
      const collapsable = getByPath(root, path.arg);
      collapsable[path.field] = value;
      break;
  }
}

interface FieldComponentProps {
  field: TemplateFieldSchema;
  path: PathDescriptor;
  rootAtom: WritableAtom<any, unknown[], any>;
  isLast?: boolean;
  onFieldChange?: (fieldPath: ObjectPathDescriptor, newValue: any) => void;
  collapsableState?: {
    [key: string]: boolean;
  };
  setCollapsableState?: React.Dispatch<React.SetStateAction<{
    [key: string]: boolean;
  }>>;
}

function Field(props: FieldComponentProps) {
  switch (props.field.type) {
    case "object":
      return <ObjectField {...props} />;

    case "table":
      return <TableField {...props} />;

    case "client":
      return <ClientField {...props} />;

    case "string":
      return <StringField {...props} />;

    case "number":
      return <NumberField {...props} />;

    case "price":
      return <NumberField {...props} />;

    case "collapsable":
      return <CollapseField {...props} collapsableState={props.collapsableState} setCollapsableState={props.setCollapsableState} />;

    case "under_collapsable":
      return <UnderCollapsableField {...props} />;

    default:
      return <></>;
  }
}

interface ObjectFieldContentsProps extends FieldComponentProps {
  isTableRow?: boolean;
}

function ObjectFieldContents(props: ObjectFieldContentsProps) {
  const obj = props.field as TemplateObjectFieldSchema;
  const [collapsablesState, setCollapsablesState] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const initialCollapsablesState: { [key: string]: boolean } = {};
    Object.entries(obj.fields).forEach(([key, value]) => {
      if (value.type === "collapsable") {
        initialCollapsablesState[value.title as string] = false;
      }
    });
    setCollapsablesState(initialCollapsablesState);
  }, [obj]);

  return (
    <>
      {Object.entries(obj.fields).map((pair, index) => {
        const [key, value] = pair;
        const fieldPath: ObjectPathDescriptor = {
          type: "object",
          arg: props.path,
          field: key,
        };
        
        if (value.type === "collapsable") {
          const collapsable = value as TemplateCollabsableFieldSchema;
          if (Object.keys(collapsable.fields).length === 0) {
            return null;
          }
        }

        const f = (
          <Field
            key={key}
            field={value}
            path={fieldPath}
            rootAtom={props.rootAtom}
            isLast={index === Object.keys(obj.fields).length - 1}
            collapsableState={collapsablesState}
            setCollapsableState={setCollapsablesState}
          />
        );

        if (props.isTableRow) {
          return <td key={key}>{f}</td>;
        } else {
          return f;
        }
      })}
    </>
  );
}

function ObjectField(props: FieldComponentProps) {
  return (
    <Stack spacing={3}>
      <ObjectFieldContents {...props} />
    </Stack>
  );
}

function CollapseField(props: FieldComponentProps) {
  const readRootValue = useReadAtom(props.rootAtom);
  const updateRootValue = useSetAtom(props.rootAtom);

  const [iconToUse, setIconToUse] = useState(EmptyEllipseIcon);

  const [value, setValue] = useState(getByPath(readRootValue(), props.path) ?? undefined);
  useEffect(() => {
    (async () => {
      const root = await readRootValue();
      setValue(getByPath(root, props.path) ?? undefined);
    })().then();
  }, [readRootValue]);

  const collapsableField = props.field as TemplateCollabsableFieldSchema;

  useEffect(() => {
    setIconToUse((value && Object.values(value).every(val => val !== '')) ? FilledEllipseIcon : EmptyEllipseIcon);
  }, [value]);

  const handleFieldChange = async (fieldPath: ObjectPathDescriptor, newValue: string) => {
    const rootValue = await readRootValue();
    setValue((prevValue: any)=> {
      const newValueObj = { ...prevValue, [fieldPath.field]: newValue };
      updateRootValue(
        produce(rootValue, (draft: any) => {
          setByPath(draft, props.path, newValueObj);
        })
      );
      return newValueObj;
    });
  };

  const handleOpen = () => {
    if (!props.collapsableState || !props.setCollapsableState || !props.field || !props.field.title) return;
  
    const newState = Object.keys(props.collapsableState).reduce((acc, key) => {
      acc[key] = props.collapsableState && key === props.field.title ? !props.collapsableState[key] : false;
      return acc;
    }, {} as { [key: string]: boolean });
  
    props.setCollapsableState(newState);
  };

  const isOpen = () => {
    if (!props.collapsableState || !props.field || !props.field.title) return false;
    return props.collapsableState[props.field.title];
  }

  const [underCollapsablesState, setUnderCollapsablesState] = useState<{ [key: string]: boolean }>({});
  useEffect(() => {
    const initialCollapsablesState: { [key: string]: boolean } = {};
    const collapsableField = props.field as TemplateCollabsableFieldSchema;

    Object.entries(collapsableField.fields).forEach(([key, value]) => {
      if (value.type === "under_collapsable") {
        initialCollapsablesState[value.title as string] = false;
      }
    });
    setUnderCollapsablesState(initialCollapsablesState);
  }, []);

  return (
    <Stack>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Box
          component='img'
          src={iconToUse}
          mr={2}
          width={'1.5rem'}
          height={'1.6rem'}
        />

        <Typography flexGrow={1} variant='h5' sx={{opacity: iconToUse === FilledEllipseIcon ? 0.4 : 1}}>
          {collapsableField.title}
        </Typography>

        <Box
          component='img'
          src={isOpen() ? ArrowDown : ArrowRight}
          width={'0.7rem'}
          height={'0.7rem'}
          mt={1.1}
          mr={22}
          sx={{cursor: 'pointer'}}
          onClick={handleOpen}
        />
      </Stack>
          
      {isOpen() && (
        <MynkForm
          onSubmit={() => {}}
        >
          {Object.entries(collapsableField.fields).map((pair, index) => {
            const [key, fieldValue] = pair;
            const fieldPath: ObjectPathDescriptor = {
              type: "object",
              arg: props.path,
              field: key,
            };

            return (
              <Field
                key={index}
                field={fieldValue}
                path={fieldPath}
                rootAtom={props.rootAtom}
                isLast={index === Object.keys(collapsableField.fields).length - 1}
                onFieldChange={handleFieldChange}
                collapsableState={underCollapsablesState}
                setCollapsableState={setUnderCollapsablesState}
              />
            );
          })}
        </MynkForm>
      )}

      {!isOpen() && !props.isLast && (
        <Box
          component='img'
          src={LineIcon}
          width={'0.15rem'}
          height={'2.5rem'}
          ml={1.25}
          mt={-0.2}
          mb={-3}
        />
      )}
    </Stack>
  );
}

function UnderCollapsableField(props: FieldComponentProps) {
  const underCollapsableField = props.field as TemplateUnderCollapsableFieldSchema;

  const readRootValue = useReadAtom(props.rootAtom);
  const updateRootValue = useSetAtom(props.rootAtom);

  const [value, setValue] = useState(getByPath(readRootValue(), props.path) ?? "");

  const handleChange = async (e: React.ChangeEvent<any>) => {
    const inputValue = e.target.value;
    const path = props.path as ObjectPathDescriptor;
    const pathArg = path.arg as CollapsablePathDescriptor;

    const editables = document.querySelectorAll('.mynk-t-editable');
    
    for (const editable of editables) {
      const htmlElement = editable as HTMLElement;
    
      if (editable.classList.contains(pathArg.field + '-' + path.field)) {
        const underServiceElement = htmlElement.parentElement;
        const className = underServiceElement?.className;
    
        if (!className?.includes('under-service')) {
          continue;
        }
    
        const serviceNumber = className.split(' ')[0].split('-')[0];
        const serviceElement = document.querySelector(`.${serviceNumber}.service`);
    
        if (serviceElement && window.getComputedStyle(serviceElement).display === 'none') {
          return;
        }
      }
    }

    const rootValue = await readRootValue();
    updateRootValue(
      produce(rootValue, (draft: any) => {
        setByPath(draft, props.path, inputValue);
      })
    );

    setValue(inputValue);

    if (props.onFieldChange)
      props.onFieldChange(props.path as ObjectPathDescriptor, inputValue);

    setTimeout(() => {
      const path = props.path as ObjectPathDescriptor;
      const pathArg = path.arg as CollapsablePathDescriptor;
  
      const editables = document.querySelectorAll('.mynk-t-editable');
      editables.forEach((editable) => {
        const htmlElement = editable as HTMLElement;
  
        if (editable.classList.contains(pathArg.field + '-' + path.field)) {
          htmlElement.classList.add('mynk-t-editing')
          htmlElement.style.backgroundColor = 'orange';
        }
      });
    }, 0);
  };

  const iconToUse = value !== "" ? FilledEllipseIcon : EmptyEllipseIcon;

  const handleEditing = (editing: boolean) => {
    if (!props.collapsableState || !props.setCollapsableState) return;
  
    const fieldsFlags = { ...props.collapsableState };

    Object.keys(fieldsFlags).forEach(key => {
      if (key !== underCollapsableField.title) {
        fieldsFlags[key] = false;
      }
      else {
        fieldsFlags[key] = editing;
      }
    });

    props.setCollapsableState(fieldsFlags);
  }

  return (
      <Stack mt={2}>
        <Stack ml={5} direction='row' justifyContent={'space-between'}>
          <Box
            component='img'
            src={iconToUse}
            mr={1}
            ml={-1}
            mt={0.3}
            width={'1.5rem'}
            height={'1.6rem'}
          />

          <Box flexGrow={1}>
            <MynkFormEditable setEditing={handleEditing} editing={props.collapsableState && props.collapsableState[underCollapsableField.title]}>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name={props.field.title ?? ""}
                label={props.field.title ?? ""}
                autoComplete={false}
                placeholder={props.field.title ?? ""}
                default={value ?? ""}
                onChange={handleChange}
              />
            </MynkFormEditable>
          </Box>
        </Stack>

        {props.collapsableState && !props.collapsableState[underCollapsableField.title] && !props.isLast && (
          <Box
            component='img'
            src={LineIcon}
            width={'0.4rem'}
            height={'1.6rem'}
            mb={-2}
            ml={5}
          />
        )}
      </Stack>
  )
}

interface TableRowFieldProps extends FieldComponentProps {
  canRemoveAtom: Atom<boolean>;
}

function TableRowField(props: TableRowFieldProps) {
  const canRemove = useAtomValue(props.canRemoveAtom);

  const readRootValue = useReadAtom(props.rootAtom);
  const updateRootValue = useSetAtom(props.rootAtom);
  const handleRemove = async () => {
    const rootValue = await readRootValue();

    const newRootValue = produce(rootValue, (draft: any) => {
      const removeIndex = (props.path as ArrayPathDescriptor).index;
      const arrayPath = (props.path as ArrayPathDescriptor).arg;
      const arr = getByPath(draft, arrayPath);

      if (arr.length > 1) {
        arr.splice(removeIndex, 1);
      }
    });

    updateRootValue(newRootValue);
  };

  return (
    <tr>
      <ObjectFieldContents {...props} isTableRow/>

      {canRemove && (
        <td>
          <IconButton onClick={handleRemove} sx={{marginTop: '-1.1rem'}}>
            <ClearIcon sx={{width: "1.3rem", height: "1.3rem"}}/>
          </IconButton>
        </td>
      )}
    </tr>
  );
}

function TableField(props: FieldComponentProps) {
  const tableField = props.field as TemplateTableFieldSchema;
  const itemField = tableField.item as TemplateObjectFieldSchema;

  const itemsAtom = useMemo(
    () => selectAtom(props.rootAtom, (x) => getByPath(x, props.path) ?? []),
    [props.rootAtom, props.path]
  );
  const items = useAtomValue(itemsAtom);

  const readRootValue = useReadAtom(props.rootAtom);
  const updateRootValue = useSetAtom(props.rootAtom);

  const canRemoveAtom = useMemo(
    () =>
      selectAtom(props.rootAtom, (x) => getByPath(x, props.path)?.length > 1),
    [props.rootAtom, props.path]
  );

  const handleAdd = async () => {
    const rootValue = await readRootValue();

    updateRootValue(
      produce(rootValue, (draft: any) => {
        const table = getByPath(rootValue, props.path);
        if (!table) {
          setByPath(draft, props.path, []);
        }

        getByPath(draft, props.path).push({
          _id: generateId(16),
          quantity: 1,
        });
      })
    );
  };

  const lastItem = items[items.length - 1];
  return (
    <Box sx={{ maxHeight: "16rem", overflowY: "auto" }}>
      <table style={{ width: "100%", borderSpacing: "0" }}>
        {tableField.spans && (
          <colgroup>
            {tableField.spans.map((x, i) => (
              <col key={i} span={1} style={{width: `${x * 100}%`}}/>
            ))}
          </colgroup>
        )}

        <thead>
        <tr>
          {Object.entries(itemField.fields).map((pair, i: number) => (
            <td key={i} style={{paddingBottom: "1rem"}}>
              <Typography variant="body2">{pair[1].title}</Typography>
            </td>
          ))}
        </tr>
        </thead>

        <tbody>
        {items.map((item: any, i: number) => {
          const rowPath: ArrayPathDescriptor = {
            type: "array",
            arg: props.path,
            index: i,
          };

          return (
            <TableRowField
              key={item._id}
              field={tableField.item}
              path={rowPath}
              rootAtom={props.rootAtom}
              canRemoveAtom={canRemoveAtom}
              isLast={i === items.length - 1}
            />
          );
        })}
        </tbody>
      </table>

      {Boolean(
        lastItem && lastItem.item && lastItem.item.length > 0 && lastItem.price
      ) && (
        <Typography
          sx={{ ml: "1.5rem", cursor: "pointer", mb: "1.2rem" }}
          variant="body2"
          color="#75b3ff"
          onClick={handleAdd}
        >
          + Add an item
        </Typography>
      )}
    </Box>
  );
}

interface CommonTextFieldProps {
  fieldProps: FieldComponentProps;
  translateValue: (s: string) => any;
}

function CommonTextField(props: CommonTextFieldProps) {
  const fieldProps = props.fieldProps;

  const readRootValue = useReadAtom(fieldProps.rootAtom);
  const updateRootValue = useSetAtom(fieldProps.rootAtom);

  const [value, setValue] = useState("");
  useEffect(() => {
    (async () => {
      const root = await readRootValue();
      setValue(getByPath(root, fieldProps.path) ?? "");
    })().then();
  }, [readRootValue]);

  const userCurrency = useAtomValue(userCurrencyAtom);
  const performChange = useCallback(
    async (value: string) => {
      const rootValue = await readRootValue();
      updateRootValue(
        produce(rootValue, (draft: any) => {
          setByPath(draft, fieldProps.path, value);
        })
      );
    },
    [fieldProps.path]
  );

  const performChangeDebounced = useDebouncedCallback(performChange, 250);

  return props.fieldProps.field.type === "number" ? (
    <NumberInput
      value={+value}
      onChange={(_, value) => {
        if (value !== undefined) {
          setValue(value + "");
          performChangeDebounced(value + "");
        }
      }}
      defaultValue={1}
      min={1}
      slotProps={{
        root: {
          style: {
            marginTop: "-1rem",
            display: "flex",
            height: "3.6rem",
            marginLeft: "-0.5rem",
          },
        },
        input: {
          style: {
            textAlign: "center",
            backgroundColor: "transparent",
            fontFamily: "Helvetica Neue",
            fontSize: "1.11rem",
            maxWidth: "1.2rem",
            border: "none",
            outline: "none"
          },
        },
        incrementButton: {
          children: (
            <AddIcon
              sx={{ maxWidth: "1rem", height: "1rem", color: "#75b3ff"}}
              fontSize="small"
            />
          ),
          style: {
            backgroundColor: "transparent",
            border: "none",
            order: 1,
            cursor: "pointer",
          },
        },
        decrementButton: {
          children: (
            <RemoveIcon
              sx={{ maxWidth: "1rem", height: "1rem", color: "#75b3ff"}}
              fontSize="small"
            />
          ),
          style: {
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          },
        },
      }}
    />
  ) : (
    <TextField
      fullWidth
      variant="standard"
      defaultValue={"0.00"}
      value={value}
      placeholder={props.fieldProps.field.type == "price" ? "0.00" : `Item ${fieldProps.path.arg.index + 1}`}
      InputProps={{
        disableUnderline: true,
        startAdornment:
          props.fieldProps.field.type === "price" ? (
            <InputAdornment position="start">
              {formatMoney(undefined, userCurrency)}
            </InputAdornment>
          ) : (
            <></>
          ),
        sx: {
          height: '3.6rem',
          mt: '-1rem',
        },
      }}
      onChange={(e) => {
        const inputValue = e.target.value;

        // Allow only integers or floats if props.fieldProps.field.type is "number"
        if (props.fieldProps.field.type === "price") {
          // Regular expression to match integers or floats
          const numberRegex = /^(\d+)?(\.\d{0,2})?$/;
          if (!numberRegex.test(inputValue)) {
            // Invalid input, do not update the state
            return;
          }
        }

        setValue(inputValue);
        performChangeDebounced(inputValue);
      }}
    />
  );
}

function StringField(props: FieldComponentProps) {
  return <CommonTextField fieldProps={props} translateValue={(s) => s}/>;
}

function NumberField(props: FieldComponentProps) {
  const field = props.field as TemplateNumberFieldSchema;

  const translateValue = (s: string) => {
    const num = +s;
    return isNaN(num) ? field.default ?? 0.0 : num;
  };

  return <CommonTextField fieldProps={props} translateValue={translateValue}/>;
}

function ClientField(props: FieldComponentProps) {
  const readRootValue = useReadAtom(props.rootAtom);
  const updateRootValue = useSetAtom(props.rootAtom);
  const field = props.field as TemplateClientFieldSchema;

  // read initial value
  const [ready, setReady] = useState(false);
  const [initialValue, setInitialValue] = useState<null | string>(null);
  useEffect(() => {
    (async () => {
      const root = await readRootValue();
      setInitialValue(getByPath(root, props.path)?.uuid ?? null);
      setReady(true);
    })().then();
  }, [readRootValue]);

  const handleSelectContact = async (client?: ClientSummary) => {
    const uuid = client?.uuid;
    const name = client?.full_name;
    const email = client?.email;
    const phone = client?.phone;

    updateRootValue(
      produce(await readRootValue(), (draft: any) => {
        setByPath(draft, props.path, {
          uuid,
          name,
          email,
          phone,
        });
      })
    );
  };

  const currentWorkflow = useAtomValue(currentWorkflowAtom)?.type;
  return (
    <Box>
      <Typography variant="body2">{field.title}</Typography>

      {ready && (
        <ContactSearch
          name="pasten"
          onSelectContact={handleSelectContact}
          default={initialValue}
          workflowType={currentWorkflow}
        />
      )}
    </Box>
  );
}

interface SendDialogProps {
  open: boolean;
  status: null | string;
}

function SendDialog(props: SendDialogProps) {
  return (
    <Dialog open={props.open}>
      <DialogContent>
        <Stack alignItems="center" spacing={3}>
          <CircularProgress/>
          <Typography>{props.status}</Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

function useSendAction(
  setSendStatus: (value: null | string) => void,
  project_uuid?: string
) {
  const template = useCurrentTemplate();
  const templateAtoms = useTemplateAtoms();
  const setProjectStage = useSetProjectStageMutation();

  const updateTemplateSent = useSetAtom(templateSentAtom);

  const templateRef = useAtomValue(templateAtoms.refAtom);

  const sendTemplate = useSendTemplateMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, SendTemplateParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        alert(detail ?? "Sending failed...");
      }
    },
  });
  const {enqueueSnackbar} = useSnackbar();

  const readTemplateDetails = useReadAtom(templateAtoms.detailsAtom);

  const navigate = useNavigate();
  return useCallback(async () => {
    if (!templateRef || !template) return;

    const details = await readTemplateDetails();

    setSendStatus("Sending...");

    try {
      // upload pdf as FormData using sendTemplate mutation
      const formData = new FormData();
      formData.append("details", JSON.stringify(details));

      const result = await sendTemplate.mutateAsync({
        template_uuid: template.uuid,
        project_uuid: project_uuid,
        form_data: formData,
      });

      // this will let the user leave the page
      updateTemplateSent(true);

      // await new Promise((resolve) => setTimeout(resolve, 1000));
      enqueueSnackbar(
        `${template.kind[0].toUpperCase() + template.kind.slice(1)} sent`,
        {
          variant: "success",
          action: (key) => (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              <CloseIcon fontSize="small"/>
            </IconButton>
          ),
          style: {
            borderRadius: "0.7rem",
          },
        }
      );

      if (!project_uuid) {
        // navigate to sent templates page
        setTimeout(() => {
          navigate(makePath(WorkflowPath.PHOTOGRAPHY, PATHS.templates.sent));
        }, 50);
        return;
      }

      setProjectStage.mutate({
        uuid: project_uuid,
        stage: ProjectStage.PROPOSAL,
      });

      navigate(
        makePath(
          WorkflowPath.PHOTOGRAPHY,
          PATHS.viewProject(project_uuid).general
        )
      );
    } finally {
      setSendStatus(null);
    }
  }, [templateRef]);
}

interface SendConfirmationDialogProps {
  open: boolean;
  templateType?: string;
  to?: string;
  options?: string[];
  onClose: () => void;
  onSend: (option: string) => void;
  loading?: boolean;
}

function SendConfirmationDialog(props: SendConfirmationDialogProps) {
  const [value, setValue] = React.useState(
    props.options ? props.options[0] : ""
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <ElevatorDialog open={props.open} onClose={props.onClose}>
      <Stack sx={{width: "25rem", mt: "1rem"}} spacing={"0.5rem"}>
        <Typography fontWeight="bold">
          Your {props.templateType ?? "template"} is ready to be sent to:
        </Typography>
        <Stack alignItems="center">
          <Typography sx={{color: "#75b3ff"}} variant="body2">
            {props.to ?? "client"}
          </Typography>
        </Stack>
        <Typography fontWeight="bold">
          Choose how you want to send it:
        </Typography>
        <Box>
          {props.options && (
            <FormControl>
              <RadioGroup
                defaultValue={props.options[0]}
                onChange={handleChange}
              >
                {props.options.map((val, i) => {
                  return (
                    <FormControlLabel
                      sx={{ml: "1.5rem"}}
                      value={val}
                      control={
                        <Radio
                          sx={{
                            color: "#75b3ff",
                            width: "1rem",
                            height: "2rem",
                            mr: "0.7rem",
                          }}
                        />
                      }
                      label={`${val.charAt(0).toUpperCase()}${val.slice(1)}`}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          )}
        </Box>
        <Stack alignItems="center" sx={{pt: "1rem"}}>
          <DialogActionButton
            onClick={() => props.onSend(value)}
            loading={props.loading}
          >
            Send
          </DialogActionButton>
        </Stack>
      </Stack>
    </ElevatorDialog>
  );
}

interface AdditionalDetailsProps {
  dueDate: number;
  setDueDate: React.Dispatch<React.SetStateAction<number>>;
  discount: number | undefined;
  setDiscount: React.Dispatch<React.SetStateAction<number | undefined>>;
  tax: number | undefined;
  setTax: React.Dispatch<React.SetStateAction<number | undefined>>;
  isCardChecked: boolean
  setIsCardChecked: React.Dispatch<React.SetStateAction<boolean>>
  isBankTransferChecked: boolean
  setIsBankTransferChecked: React.Dispatch<React.SetStateAction<boolean>>
  isPaymentLinkChecked: boolean
  setIsPaymentLinkChecked: React.Dispatch<React.SetStateAction<boolean>>
  isOtherChecked: boolean
  setIsOtherChecked: React.Dispatch<React.SetStateAction<boolean>>
}

function CheckedSVG() {
  return (
    <Box
      component="img"
      src={checkedRadio}
      sx={{width: "1.5rem", height: "1.5rem"}}
    >
    </Box>
  );
}

function UncheckedSVG() {
  return (
    <Box
      component="img"
      src={uncheckedRadio}
      sx={{width: "1.5rem", height: "1.5rem"}}
    >
    </Box>
  );
}

function AdditionalDetails(props: AdditionalDetailsProps) {
  const [taxValue, setTaxValue] = useState("");

  const handleCardClick = () => {
    props.setIsCardChecked(!props.isCardChecked);
    props.setIsBankTransferChecked(false);
    props.setIsPaymentLinkChecked(false);
    props.setIsOtherChecked(false);
  }

  const handleBankTransferClicked = () => {
    props.setIsBankTransferChecked(!props.isBankTransferChecked);
    props.setIsCardChecked(false);
    props.setIsPaymentLinkChecked(false);
    props.setIsOtherChecked(false);
  }

  const handlePaymentLinkClicked = () => {
    props.setIsPaymentLinkChecked(!props.isPaymentLinkChecked);
    props.setIsCardChecked(false);
    props.setIsBankTransferChecked(false);
    props.setIsOtherChecked(false);
  }

  const handleOtherClicked = () => {
    props.setIsOtherChecked(!props.isOtherChecked);
    props.setIsCardChecked(false);
    props.setIsBankTransferChecked(false);
    props.setIsPaymentLinkChecked(false);
  }

  const handleChangeDate = (value: string) => {
    props.setDueDate(Number(value));
  }

  const handleChangeTax = (value: string) => {
    if (Number(value) === 0){
      props.setTax(undefined);
      return;
    }
    props.setTax(Number(value));
  }

  const handleChangeDiscount = (value: string) => {
    if (Number(value) === 0){
      props.setDiscount(undefined);
      return;
    }
    props.setDiscount(Number(value));
  }

  return (
    <>
      <Stack gap={2} mt={2}>
        <Typography>Payment method</Typography>
        <Stack gap={1} direction={'row'}>
          <Stack flexGrow={1} gap={1}>
            <Stack flexDirection={"row"} gap={0.7}>
              <Box onClick={handlePaymentLinkClicked} style={{cursor: 'pointer'}}>
                {props.isPaymentLinkChecked ? <CheckedSVG /> : <UncheckedSVG />}
              </Box>
              <Box component={'img'} src={cash} width={"1.5"} height={"1.5rem"} />
              <Typography>Cash</Typography>
            </Stack>

            <Stack flexDirection={"row"} gap={0.7}>
              <Box onClick={handleCardClick} style={{cursor: 'pointer'}}>
                {props.isCardChecked ? <CheckedSVG /> : <UncheckedSVG />}
              </Box>
              <Box component={'img'} src={card} width={"1.5"} height={"1.5rem"} />
              <Typography style={{marginLeft: "0.5rem"}}>Card</Typography>
            </Stack>
          </Stack>
          <Stack flexGrow={1} gap={1}>
            <Stack flexDirection={"row"} gap={0.7}>
              <Box onClick={handleBankTransferClicked} style={{cursor: 'pointer'}}>
                {props.isBankTransferChecked ? <CheckedSVG /> : <UncheckedSVG />}
              </Box>
              <Box component={'img'} src={bankTransfer} width={"1.5"} height={"1.5rem"} />
              <Typography style={{marginLeft: "0.5rem"}}>Bank transfer</Typography>
            </Stack>
            
            <Stack flexDirection={"row"} gap={0.7}>
              <Box onClick={handleOtherClicked} style={{cursor: 'pointer'}}>
                {props.isOtherChecked ? <CheckedSVG /> : <UncheckedSVG />}
              </Box>
              <Typography>Other</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack flexDirection={"row"}>
          <Stack direction={'row'} gap={1.5} flexGrow={1}>
            <Typography width={"5rem"}>Due date: </Typography>
            <ChipSelect
              options={MONTHS_OPTIONS}
              value={(props.dueDate || 1).toString()}
              onChange={handleChangeDate}
              placeholder="1 Month"
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0.0714rem 0.3571rem rgba(117, 179, 255, 0.35)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "2rem",
              }}
            />
          </Stack>
          
          <Stack flexDirection={"row"} gap={1.5} flexGrow={1}>
            <Typography>Discount: </Typography>
            <ChipSelect
              options={DISCOUNT_OPTIONS}
              value={(props.discount || 0).toString()}
              onChange={handleChangeDiscount}
              placeholder="None"
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0.0714rem 0.3571rem rgba(117, 179, 255, 0.35)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "7rem",
                height: "2rem",
              }}
            />
          </Stack>
        </Stack>
        <Stack direction={'row'} gap={1.5}>
          <Typography>VAT: </Typography>
          <TextField
            fullWidth
            variant="standard"
            defaultValue={"0"}
            value={taxValue}
            placeholder={"0"}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  %
                </InputAdornment>
              ),
              sx: {
                height: '3.6rem',
                mt: '-1rem',
              },
            }}
            onChange={(e) => {
              const inputValue = e.target.value;

              // Regex to allow numbers with up to two decimal places
              const numberRegex = /^(\d+)?(\.\d{0,2})?$/;
              if (!numberRegex.test(inputValue)) {
                // Invalid input, do not update the state
                return;
              }

              // Parse the input as a number and block values greater than 100
              const numericValue = parseFloat(inputValue);
              if (numericValue > 100) {
                // Do not update the state if the value exceeds 100
                return;
              }

              // Update state with valid input
              setTaxValue(inputValue);
            }}
          />
        </Stack>
      </Stack>
    </>
  );
}

interface GenericDetailsProps {
  clientData?: GetClientResponse;
  projectData?: GetProjectResponse;
}

export default function GenericDetails({clientData, projectData}: GenericDetailsProps) {
  const template = useCurrentTemplate();
  const [templateSchema, setTemplateSchema] = useState<TemplateObjectFieldSchema | null>()
  const [dueDate, setDueDate] = useState<number>(1)
  const [tax, setTax] = useState<number | undefined>()
  const [discount, setDiscount] = useState<number | undefined>()
  const [isCardChecked, setIsCardChecked] = useState<boolean>(false);
  const [isBankTransferChecked, setIsBankTransferChecked] = useState<boolean>(false);
  const [isPaymentLinkChecked, setIsPaymentLinkChecked] = useState<boolean>(false);
  const [isOtherChecked, setIsOtherChecked] = useState<boolean>(false);

  const templateAtoms = useTemplateAtoms();

  const rootPath = useMemo<RootPathDescriptor>(
    () => ({
      type: "root",
    }),
    []
  );
  const [templateState, setTemplateState] = useAtom(templateStateAtom);
  const [projectUuid, setProjectUuid] = useState(templateState?.project.uuid);
  const [sendStatus, setSendStatus] = useState<null | string>(null);
  const send = useSendAction(setSendStatus, projectUuid);
  const [templateDataReady, setTemplateDataReady] = useState(false);
  
  const value = useAtomValue(templateAtoms.detailsAtom);

  const areAllFieldsFilled = () => {
    const schema = template?.schema?.root as TemplateObjectFieldSchema;
    
    // Check if value object is empty
    if (!value || Object.keys(value).length === 0) return false;
  
    for (const key of Object.keys(value)) {
      // Skip 'services' field
      if (key === 'services') continue;
  
      const schemaFields = schema?.fields?.[key] as TemplateCollabsableFieldSchema;
  
      // Ensure schemaFields exists before accessing its fields
      if (!schemaFields || !schemaFields.fields) {
        return false; // Or you can skip this iteration if necessary
      }
  
      for (const field of Object.keys(schemaFields.fields)) {
        const schemaField = schemaFields.fields[field] as TemplateUnderCollapsableFieldSchema;
  
        if (schemaField.under_service) {
          const fieldService = schemaField.under_service.split('-')[0];
          const fieldUnderService = schemaField.under_service;
  
          if (value.services && (value.services.includes(fieldService) || value.services.includes(fieldUnderService))) {
            continue;
          }
        }
  
        // Check if the field exists in value[key] before accessing it
        if (!value[key] || !value[key][field]) {
          return false;
        }
      }
    }
  
    return true;
  };

  const sendDisabled = useMemo(() => {
    if ((!value?.client?.uuid || (value?.items ?? []).length === 0) && template?.kind === "invoice") return true;
    if (template?.kind === "contract" && !areAllFieldsFilled()) return true;

    return false;
  }, [value]);

  const priceSum = useMemo(() => {
    let priceSum = 0;

    // Calculate sum of all items:
    if (value.items) {
      value.items.forEach((item: { price: string; quantity: string }) => {
        if (item.price) {
          priceSum +=
            Number(item.price) * (item.quantity ? Number(item.quantity) : 1);
        }
      });
    }
    return priceSum;
  }, [value?.items]);

  const [templateData, setTemplateData] = useAtom(templateAtoms.dataAtom);
  const userCurrency = useAtomValue(userCurrencyAtom);
  const {data: projectClient} = useGetClientQuery({
    uuid: templateState?.project.client_uuid ?? "",
  });
  const { data: generalSettingsData } = useListGeneralSettingsQuery({});

  // When loading the template - all kind of default values assigned to the templateDataAtom:
  useEffect(() => {
    const taxNumber = (priceSum * (100 - (discount || 0)) / 100 * (100 + (tax || 0)) / 100) - (priceSum * (100 - (discount || 0)) / 100);
    const total = priceSum * (100 - (discount || 0)) / 100 * (100 + (tax || 0)) / 100;

    if (templateState && !projectClient?.client) {
      return;
    }

    setTemplateData({
      data: {
        settings: {
          currency: formatMoney(undefined, userCurrency),
        },
        me: {
          name: generalSettingsData?.business_name,
          address: generalSettingsData?.business_address,
          email: generalSettingsData?.email,
          phone: generalSettingsData?.business_phone,
        },
        issued_on: DateTime.now().toFormat("dd MMMM yyyy"),
        due_date: DateTime.now().plus({ month: dueDate }).toFormat("dd MMMM yyyy"),
        template_uuid: ((generalSettingsData?.invoice_start_number ?? 0) + 1)
          .toString()
          .padStart(4, "0"),
        sum: {
          subtotal: priceSum,
          discount: discount,
          taxPercent: tax,
          taxNumber: +taxNumber.toFixed(3),
          total: +total.toFixed(3),
          amount_due: +total.toFixed(3),
        },
        items: [
          {
            item: templateState?.project.name,
            quantity: 1,
            price: templateState?.project.price,
            _id: generateId(16),
          },
        ],
        client: {
          uuid: projectClient?.client?.uuid,
          name: projectClient?.client?.full_name,
          phone: projectClient?.client?.phone,
          email: projectClient?.client?.email,
        },
      },
      updates: 0,
    });
    setTemplateDataReady(true);
  }, [projectClient]);
  
  useEffect(() => {
    if (template?.kind !== "contract" || !generalSettingsData || !clientData || !projectData || !clientData.client || !projectData.project) return;

    const client = clientData.client as PhotographyClientInfo;
    const project = projectData.project as PhotographyProject;

    setTemplateData((prevData: any) => ({
      data: {
        ...prevData.data,
        me: {
          date: new Date().toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          }),
          name: generalSettingsData.business_name,
          address: generalSettingsData.business_address,
          phone_number: generalSettingsData.business_phone
        },
        client_details: {
          uuid: clientData?.client?.uuid,
          name: client.full_name,
          address: client.email,
          phone_number: client.phone
        },
        event_details: {
          uuid: projectData?.project?.uuid,
          date: new Date(project.date).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          }),
          location: project.location,
          duration: (() => {
            if (!project.date || !project.end_date) return "";
            
            const startDate = new Date(project.date);
            const endDate = new Date(project.end_date);
            const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
            const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
            return `${differenceInDays} days`;
          })(),
          number_of_photographers: '',
          additional_hour_rate: '',
          pre_event_consultation: '',
        },
        payment: {
          retainer: project.price,
          remaining_balance: '',
          due: '',
          forms_payment: '',
          late_payment_penalty: '',
        },
        deliverables: {
          number_photos: '',
          format: '',
          time_frame: '',
          delivery_method: '',
          rounds_edits: '',
          additional_edits_fee: '',
        },
        cancellation_policy: {
          no_extra_fee: '',
          fifty_percent_of_contract_fee: '',
          hundred_percent_of_contract_fee: '',
        },
        rescheduling: {
          more_than: '',
          within: '',
          less_than: '',
          fee: '',
          second_within: '',
        },
        breaks_and_travel: {
          provide_meals_after: '',
          travel_beyond: '',
        },
        archiving: {
          time: ''
        },
        governing: {
          country: '',
        }
      },
      updates: 0,
    }));

    setTemplateDataReady(true);
  }, [generalSettingsData, clientData, projectData]);

  const readTemplateData = useAtomCallback(
    useCallback((get) => get(templateAtoms.dataAtom), [])
  );

  useEffect(() => {
    if (template?.kind !== "invoice") return;

    (async () => {
      const prevData = await readTemplateData();
      setTemplateData(
        produce(prevData, (draft: any) => {
          const taxNumber = (priceSum * (100 - (discount || 0)) / 100 * (100 + (tax || 0)) / 100) - (priceSum * (100 - (discount || 0)) / 100);
          const total = priceSum * (100 - (discount || 0)) / 100 * (100 + (tax || 0)) / 100;

          draft.data.sum = {
            discount: discount,
            taxPercent: tax,
            taxNumber: +taxNumber.toFixed(3),
            subtotal: priceSum,
            total: +total.toFixed(3),
            amount_due: +total.toFixed(3),
          };
        })
      );
    })();
  }, [priceSum, discount, tax]);

  useEffect(() => {
    (async () => {
      const prevData = await readTemplateData();
      setTemplateData(
        produce(prevData, (draft: any) => {
          draft.data.due_date = DateTime.now().plus({ month: dueDate }).toFormat("dd MMMM yyyy");
        })
      );
    })();
  }, [dueDate]);

  useEffect(() => {
    if (!template || !template.schema) return;
  
    const tempSchema = produce(template.schema.root, draft => {
      if (value.services) {
        for (const key of Object.keys(draft.fields)) {
          const schemaFields = draft.fields[key] as TemplateCollabsableFieldSchema;
  
          for (const field of Object.keys(schemaFields.fields)) {
            const schemaField = schemaFields.fields[field] as TemplateUnderCollapsableFieldSchema;
  
            if (schemaField.under_service) {
              const fieldService = schemaField.under_service.split('-')[0];
              const fieldUnderService = schemaField.under_service;
  
              if (value.services.includes(fieldService) || value.services.includes(fieldUnderService)) {
                delete schemaFields.fields[field];
              }
            }
          }
        }
      }
    });
  
    setTemplateSchema(tempSchema);
  }, [value, template?.schema]);

  // confirmation dialog
  const [showSendDialog, setShowSendDialog] = useState(false);
  const confirmText = useMemo(() => {
    return `You are about to send ${articleFromNoun(template?.kind ?? "")} ${
      template?.kind
    } to ${value?.client?.name}.\nHow would you like to send it?`;
  }, [template, value]);

  const handleConfirmSend = async (option: string) => {
    setShowSendDialog(false);
    await send();
  };

  return (
    <Box>
      <Typography variant="h2" fontFamily="Helvetica Neue" mb={2}>
        {template?.kind === "invoice" ? "Invoice" : "Contract"} Details
      </Typography>

      <br/>

      {templateSchema && templateDataReady && (
        <ObjectField
          field={templateSchema}
          path={rootPath}
          rootAtom={templateAtoms.detailsAtom}
        />
      )}

      {template?.kind === "invoice" && (
        <Stack>
          <AdditionalDetails
            dueDate={dueDate}
            setDueDate={setDueDate}
            tax={tax} setTax={setTax}
            discount={discount}
            setDiscount={setDiscount}
            isCardChecked={isCardChecked}
            setIsCardChecked={setIsCardChecked}
            isBankTransferChecked={isBankTransferChecked}
            setIsBankTransferChecked={setIsBankTransferChecked}
            isPaymentLinkChecked={isPaymentLinkChecked}
            setIsPaymentLinkChecked={setIsPaymentLinkChecked}
            isOtherChecked={isOtherChecked}
            setIsOtherChecked={setIsOtherChecked}
          />

          <Divider sx={{ my: "2.2rem" }} />
          
          <Stack direction="row">
            <Typography
              fontFamily={"Helvetica Neue"}
              fontWeight="bold"
              fontSize="1.3rem"
              color="black"
            >
              Total:
            </Typography>
            <Box sx={{ flex: 1 }} />
            <Typography
              fontSize="2rem"
              fontWeight="bold"
              fontFamily="Helvetica Neue"
            >
              {formatMoney(priceSum, userCurrency)}
            </Typography>
          </Stack>
        </Stack>
      )}

      <Stack alignItems="center" sx={{mb: "-1rem", mt: "3rem"}}>
        <SendDialog open={sendStatus !== null} status={sendStatus}/>

        <DialogActionButton
          onClick={() => setShowSendDialog(true)}
          disabled={sendDisabled}
        >
          Send
        </DialogActionButton>

        <SendConfirmationDialog
          open={showSendDialog}
          onClose={() => setShowSendDialog(false)}
          onSend={handleConfirmSend}
          to={template?.kind === 'invoice' ? value?.client?.name : clientData?.client?.full_name ?? ''}
          templateType={template?.kind}
          options={["email"]}
        />
      </Stack>

      {/* <Typography variant="body2" sx={{color: 'orange', mt: 3}}>
       {JSON.stringify(value)}
      </Typography>

      <Typography variant="body2" sx={{color: 'gray'}}>
       {JSON.stringify(template?.schema)}
      </Typography> */}
    </Box>
  );
}
