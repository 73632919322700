import {Box, CircularProgress, Typography} from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";


export default function CheckoutSuccessPage() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/photography/dashboard/home");
    }, 5000);
  }, []);

  return (
    <Box>
      <Typography>
        Redirecting you to the dashboard... &nbsp; <CircularProgress size="1rem" />
      </Typography>
    </Box>
  );
}
