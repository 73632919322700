import { useEffect, useState } from "react";
import {
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import { EditTaskParams, Task, WorkflowType } from "../api/types";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import { useNavigate } from "react-router-dom";
import { useEditTaskMutation } from "../api/tasks";
import { PATHS, WorkflowPath, makePath } from "../paths";
import { currentWorkflowAtom } from "../components/MainAppBar/UserControlPopup";
import { useAtomValue } from "jotai";
import { DateTime } from "luxon";
import { zonedDate } from "../util/common";
import MynkFormEditable from "../components/MynkForm/MynkFormEditable";
import { PRIORITY_OPTIONS } from "../components/ChipSelect/commonOptions";
import {AxiosError} from "axios";

export interface EditTaskDialogProps {
  open: boolean;
  onClose: () => void;
  oldTask: Task | null;
}

export function EditTaskDialog(props: EditTaskDialogProps) {
  const [title, setTitle] = useState(
    props.oldTask != null ? props.oldTask.title : "he"
  );
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const initialLinkOption = props.oldTask ? props.oldTask.project_uuid ? "project" : (props.oldTask.client_uuid && !props.oldTask.project_uuid) ? "client" : "" : "";
  const [linkOption, setLinkOption] = useState(initialLinkOption);
  const [additionalDetailsOpen, setAdditionalDetailsOpen] = useState(true);

  useEffect(() => {
    if (props.open) {
      setAdditionalDetailsOpen(false);
      setLinkOption(props.oldTask ? props.oldTask.project_uuid ? "project" : (props.oldTask.client_uuid && !props.oldTask.project_uuid) ? "client" : "" : "");
    }
  }, [props.open]);
  
  // Whenever 'oldTask' is changed (the user clicked other Edit button) - change the default title to display on the form:
  useEffect(() => {
    if (props.oldTask) {
      setTitle(props.oldTask.title);
    }
  }, [props.oldTask]);

  // Fetch edit task and then navigate to show all tasks:
  const navigate = useNavigate();
  const editTask = useEditTaskMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, EditTaskParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        setErrorMsg(detail ?? "");
      }
    }
  });
  const handleSubmit = async (values: any) => {
    if (props.oldTask) {
      const dueDate = new Date(values.due_date.valueOf());
      dueDate.setHours(23, 59, 59);
      const offsetInMilliseconds = 3 * 60 * 60 * 1000;
      const adjustedDueDate = new Date(dueDate.getTime() + offsetInMilliseconds);
      let combinedDueDate: any = adjustedDueDate.getTime();

      if (values.due_time) {
        let dueTime = new Date(values.due_time.valueOf());
        const timezoneOffsetMs = dueTime.getTimezoneOffset() * 60 * 1000;
        
        dueTime = new Date(dueTime.getTime() - timezoneOffsetMs);
        const timeWithTimezone = dueTime.toISOString();

        const dueDate = new Date(values.due_date.valueOf());
        const datePart = dueDate.toISOString().split('T')[0];

        const timePart = timeWithTimezone.split('T')[1];
        combinedDueDate = `${datePart}T${timePart}`;
      }
      
      const params = {
        uuid: props.oldTask.uuid,
        title: title,
        due_date: combinedDueDate,
        priority: values.priority,
        project_uuid: (linkOption == "project" && values.projectUuid) ? values.projectUuid : null,
        client_uuid: (linkOption == "client" && values.clientUuid) ? values.clientUuid : null,
      } as EditTaskParams;

      await editTask.mutateAsync(params);
      props.onClose();
      navigate(
        makePath(
          `/${useAtomValue(currentWorkflowAtom)?.type}` as WorkflowPath,
          PATHS.tasks.all
        )
      );
    }
  };

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      title=""
      action="Save"
      onSubmit={handleSubmit}
      loading={editTask.isPending}
      errorMsg={errorMsg}
    >
      <Stack sx={{ width: "22rem", minHeight: "10rem" }}>
        {/* Place to edit the task's title: */}
        <TextField
          fullWidth
          multiline
          variant="standard"
          placeholder="Title"
          InputProps={{ disableUnderline: true, style: { fontSize: "1.7rem" } }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        {props.oldTask && (
          <Grid container spacing="1.4rem" sx={{ mt: "0rem" }}>
            {/* Place to edit the task's due-date: */}
            <Grid item xs={12}>
              <MynkFormEditable>
                <MynkFormControl
                  kind={MynkFormControlKind.DATE_SELECT}
                  name="due_date"
                  label="Due date:"
                  default={DateTime.fromISO(props.oldTask.due_date ?? "")
                    .minus({ minutes: DateTime.local().offset }) ?? DateTime.now()}
                />
              </MynkFormEditable>
            </Grid>
            {!props.oldTask.is_reminder && (
              <Grid item xs={12}>
                <MynkFormControl
                  kind={MynkFormControlKind.CHIP_SELECT}
                  name="priority"
                  label="Priority:"
                  default={props.oldTask.priority ?? "medium"}
                  options={PRIORITY_OPTIONS}
                  width="8rem"
                />
              </Grid>
            )}

            {!props.oldTask.is_reminder && (
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="#69B3FF"
                  sx={{ cursor: "pointer" }}
                  onClick={() => setAdditionalDetailsOpen(!additionalDetailsOpen)}
                >
                  {"Additional details >"}
                </Typography>
              </Grid>
            )}

            {props.oldTask.is_reminder && (
              <Grid item xs={12}>
                <MynkFormControl
                  kind={MynkFormControlKind.DATE_SELECT}
                  name="due_time"
                  label="Time:"
                  onlyTime
                  default={
                    DateTime.fromISO(props.oldTask.due_date)
                      .minus({ minutes: DateTime.local().offset })
                  }
                />
              </Grid>
            )}

            {additionalDetailsOpen && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    value={linkOption}
                    onChange={(event) => setLinkOption(event.target.value)}
                    defaultValue={initialLinkOption}
                  >
                    <FormControlLabel
                      value="client"
                      control={<Radio />}
                      label="Link client"
                    />
                    <FormControlLabel
                      value="project"
                      control={<Radio />}
                      label="Link project"
                    />
                  </RadioGroup>
                </Grid>
                {linkOption == "client" ? (
                  <Grid item xs={12}>
                    <MynkFormControl
                      kind={MynkFormControlKind.CONTACT_SEARCH}
                      name="clientUuid"
                      default={props.oldTask.client_uuid ?? null}
                      label="Client:"
                    />
                  </Grid>
                ) : linkOption == "project" ? (
                  <Grid item xs={12}>
                    <MynkFormControl
                      kind={MynkFormControlKind.PROJECT_SEARCH}
                      name="projectUuid"
                      default={props.oldTask.project_uuid ?? null}
                      label="Project:"
                      workflowType={WorkflowType.PHOTOGRAPHY}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </>
            )}
          </Grid>
        )}
      </Stack>
    </MynkFormDialog>
  );
}
