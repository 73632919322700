import { Divider, Grid, IconButton, Stack, TextField, Typography, Box } from "@mui/material";
import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import { useMemo } from "react";
import MynkFormEditable from "../components/MynkForm/MynkFormEditable";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import { Client, ClientSource, EditClientParams, Priority } from "../api/types";
import {
  CONTACT_SOURCE_OPTIONS,
  PRIORITY_OPTIONS,
} from "../components/ChipSelect/commonOptions";
import { useEditClientMutation } from "../api/clients";
import { closeSnackbar, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {AxiosError} from "axios";
import {useState} from "react";
import {DateTime} from "luxon";
import { zonedDate } from "../util/common";
import { BoxWithGradientFrame } from "../components/GradientFrame";
import instagramIcon from '../assets/icons/sources/instagram.svg';
import facebookIcon from '../assets/icons/sources/facebook.svg';
import websiteIcon from '../assets/icons/sources/website.svg';

export interface ReferenceTextFieldProps {
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

function ReferenceTextField(props: ReferenceTextFieldProps) {
  return (
    <BoxWithGradientFrame>
      <TextField
        variant="standard"
        placeholder={props.placeholder?.toString() ?? ""}
        fullWidth
        sx={{
          mt: 1,
          height: "3rem",
        }}
        InputProps={{
          disableUnderline: true,
          sx: {
            display: "flex",
            fontFamily: "Helvetica Neue",
            alignItems: "center",
            height: "100%",
            pl: 1.5,
            mb: 0.5,
          },
        }}
        onChange={(e) => props.onChange?.(e.target.value)}
        defaultValue={props.defaultValue}
      />
    </BoxWithGradientFrame>
  );
}

export interface EditContactDialogProps {
  open: boolean;
  onClose: () => void;
  contact?: Client | null;
}

export default function EditContactDialog(props: EditContactDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const [instagram, setInstagram] = useState<string | undefined>(props.contact?.instagram);
  const [website, setWebsite] = useState<string | undefined>(props.contact?.website);
  const [facebook, setFacebook] = useState<string | undefined>(props.contact?.facebook);

  // Fetch edit contact to the server:
  const editContact = useEditClientMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, EditClientParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        setErrorMsg(detail ?? "");
      }
    }
  });
  const handleSubmit = async (values: any) => {
    if (!props.contact?.uuid) return;

    const zDate = zonedDate(values["contactBirthday"]);
    const date = DateTime.fromObject({
      year: zDate.year,
      month: zDate.month,
      day: zDate.day,
    });

    const params: EditClientParams = {
      uuid: props.contact.uuid,
      fields: {
        full_name: values["contactName"].trim() ?? "",
        email: values["contactEmail"].trim() ?? "",
        phone: values["contactPhone"].trim() ?? "",
        source: values["contactSource"] ?? ClientSource.OTHER,
        priority: values["contactPriority"] ?? Priority.LOW,
        birth_date: date ?? "",
        address: values["contactAddress"] ?? "",
        instagram: instagram ?? "",
        website: website ?? "",
        facebook: facebook ?? "",
      },
    };

    await editContact.mutateAsync(params);
    enqueueSnackbar("Contact edited successfully", {
      variant: "success",
      action: (key) => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
      style: {
        borderRadius: "0.7rem",
      },
    });
    props.onClose();
  };

  const initialValues = useMemo(() => {
    if (!props.contact) return {};
    
    return {
      contactName: props.contact.full_name,
      contactEmail: props.contact.email,
      contactPhone: props.contact.phone,
      contactSource: props.contact.source,
      contactPriority: props.contact.priority,
      contactBirthday: props.contact.birth_date,
      contactAddress: props.contact.address,
    };
  }, [props.contact]);

  if (!props.contact) {
    // In case no contact was supplied - don't open the dialog.
    return <></>;
  }

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      title="Edit contact"
      action="Apply changes"
      onSubmit={handleSubmit}
      initialValues={initialValues}
      loading={editContact.isPending}
      errorMsg={errorMsg}
    >
      <Stack direction='row' width={"60rem"}>
        <Grid container spacing={"1.3rem"}>
          {/* Place for fill the client's name */}
          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name="contactName"
                label="Name"
                placeholder="Enter name"
                autoComplete={false}
                default=""
              />
            </MynkFormEditable>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Place to fill the client's mail */}
          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name="contactEmail"
                label="Email"
                placeholder="Enter email address"
                autoComplete={false}
                default=""
              />
            </MynkFormEditable>
          </Grid>

          {/* Place to fill the client's phone number */}
          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name="contactPhone"
                label="Phone"
                placeholder="Enter phone number"
                autoComplete={false}
                default=""
              />
            </MynkFormEditable>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Place to change the client's priority */}
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.CHIP_SELECT}
              name="contactPriority"
              label="Priority"
              default={Priority.LOW}
              options={PRIORITY_OPTIONS}
            />
          </Grid>

          {/* Place to change the client's source */}
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.CHIP_SELECT}
              name="contactSource"
              label="Lead source"
              default={ClientSource.OTHER}
              options={CONTACT_SOURCE_OPTIONS}
            />
          </Grid>
        </Grid>

        <Divider orientation="vertical" sx={{ ml: 5, mr: 5 }} flexItem />

        <Grid
          container
          spacing={"1.3rem"}
          alignItems="center"
          alignContent="start"
        >
          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.DATE_SELECT}
                name="contactBirthday"
                label="Birth Date"
                default=""
                onlyDate
              />
            </MynkFormEditable>
          </Grid>

          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name="contactAddress"
                label="Address"
                placeholder="Enter Address"
                autoComplete={false}
                default=""
              />
            </MynkFormEditable>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          
          <Grid item xs={12}>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <Box component="img" src={instagramIcon} style={{ width: "1.33rem", height: "1.33rem" }} />
              <Box flexGrow={1}>
                <ReferenceTextField
                  placeholder="@yourhandle"
                  defaultValue={props.contact?.instagram}
                  onChange={(value) => setInstagram(value)}
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <Box component="img" src={facebookIcon} style={{ width: "1.33rem", height: "1.33rem" }} />
              <Box flexGrow={1}>
                <ReferenceTextField
                  placeholder="@yourhandle"
                  defaultValue={props.contact?.facebook}
                  onChange={(value) => setFacebook(value)}
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <Box component="img" src={websiteIcon} style={{ width: "1.33rem", height: "1.33rem" }} />
              <Box flexGrow={1}>
                <ReferenceTextField
                  placeholder="Enter website"
                  defaultValue={props.contact?.website}
                  onChange={(value) => setWebsite(value)}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </MynkFormDialog>
  );
}
