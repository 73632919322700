import {MynkFormControlCommonProps} from './types';
import {useFormikContext} from 'formik';
import {Box, Typography} from '@mui/material';
import {useIsInEditable} from "../context";
import { MinimalProjectSum, WorkflowType } from '../../../api/types';
import ProjectSearch from '../../ProjectSearch';

export interface MynkFormProjectSearchProps extends MynkFormControlCommonProps<null | string> {
  workflowType?: WorkflowType;
}


export function MynkFormProjectSearch(props: MynkFormProjectSearchProps) {
  const formik = useFormikContext();

  const handleSelectProject = (project?: MinimalProjectSum) => {
    formik?.setFieldValue(props.name, project?.uuid);
  };

  const isInEditable = useIsInEditable();

  return (
    <Box>
      {!isInEditable && (
        <Typography variant="subtitle1">{props.label}</Typography>
      )}

      <ProjectSearch
        name={props.name}
        default={props.default}
        placeholder={props.placeholder}
        onSelectProject={handleSelectProject}
        workflowType={props.workflowType}
      />
    </Box>
  );
}
