import { MynkFormControlCommonProps } from "./types";
import { Box, MenuItem, Select, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { ChipSelectOption } from "../../ChipSelect";

export interface MynkFormIconedChipSelectProps
  extends MynkFormControlCommonProps<string> {
  options: ChipSelectOption[];
  icon?: string;
}

export function MynkFormIconedChipSelect(props: MynkFormIconedChipSelectProps) {
  const formik = useFormikContext();

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        bgcolor: "white",
        borderRadius: "10rem",
        boxShadow: "0px 0.0714rem 0.3571rem rgba(117, 179, 255, 0.35)",
      }}
    >
      <Box
        component="img"
        src={props.icon ?? ""}
        width={"1.0714rem"}
        height={"1.0714rem"}
        marginLeft={1}
      />

      <Select
        variant="standard"
        {...(formik?.getFieldProps?.(props.name) ?? {
          name: props.name,
          value: props.default,
        })}
        sx={{
          bgcolor: "white",
          width: "100%",
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "14.2857rem"
            },
          },
        }}
      >
        {props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}
