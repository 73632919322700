import {
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ElevatorDialog from "../components/ElevatorDialog";
import SearchFilterSort from "../components/SearchFilterSort";
import {
  FILTER_REAL_ESTATE_LISTINGS_OPTIONS,
  SORT_REAL_ESTATE_LISTINGS_OPTIONS,
} from "../components/ChipSelect/commonOptions";
import { useEffect, useState } from "react";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

import {
  ChangeListingViewerAction,
  FilterOption,
  RealEstateListing,
  SearchOption,
  SortOption,
  WorkflowType,
} from "../api/types";
import { useChangeListingViewer, useListProjectsQuery } from "../api/projects";
import { ListingCard, ListingViewType } from "../components/ListingsView";

interface ManageListingsProps {
  open: boolean;
  onClose: () => void;
  prospectUuid: string;
  chosenListings: RealEstateListing[];
}

const PAGE_SIZE = 10;

export default function ManageListings(props: ManageListingsProps) {
  const [search, setSearch] = useState<SearchOption | undefined>(undefined);
  const [filter, setFilter] = useState<FilterOption | undefined>(undefined);
  const [sort, setSort] = useState<SortOption | undefined>(undefined);
  const [current, setCurrent] = useState<RealEstateListing[]>([]);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    setCurrent([]);
  }, [search, filter, sort]);

  const { data, isPending } = useListProjectsQuery({
    page_size: PAGE_SIZE,
    page_index: pageIndex,
    retrieve_options: {
      search: search,
      filter: filter,
      sort: sort,
    },
  });

  const loadMore = () => {
    if (current && pageIndex < (data?.num_of_pages ?? 0) - 1) {
      setPageIndex(pageIndex + 1);
    }
  };

  // *Append* more listings:
  useEffect(() => {
    if (data) {
      setCurrent((prevState) => {
        const newData = (data?.projects as RealEstateListing[]) || [];
        return [...(prevState ?? []), ...newData];
      });
    }
  }, [data, isPending]);

  const setViewer = useChangeListingViewer();

  const handleChecked = async (uuid: string, add: boolean) => {
    if (add) {
      await setViewer.mutateAsync({
        prospect_uuid: props.prospectUuid,
        listing_uuid: uuid,
        action: ChangeListingViewerAction.ADD,
      });
    } else {
      // Delete
      await setViewer.mutateAsync({
        prospect_uuid: props.prospectUuid,
        listing_uuid: uuid,
        action: ChangeListingViewerAction.DELETE,
      });
    }
    setCurrent([]);
  };

  return (
    <ElevatorDialog open={props.open} onClose={props.onClose}>
      <Stack spacing={1} sx={{ width: "62.1429rem" }}>
        <Typography variant="h4" fontFamily="Helvetica Neue">
          Manage listings
        </Typography>
        <Card
          sx={{
            zIndex: 1,
            backgroundColor: "white",
            width: "100%",
            position: "sticky",
            top: 15,
          }}
        >
          <SearchFilterSort
            searchState={[search, setSearch]}
            filterState={[filter, setFilter]}
            sortState={[sort, setSort]}
            filterOptions={FILTER_REAL_ESTATE_LISTINGS_OPTIONS}
            sortOptions={SORT_REAL_ESTATE_LISTINGS_OPTIONS}
            height={34}
            searchWidth={"100%"}
            spacing={1}
            sx={{ margin: 1.2, mr: 3, ml: 3 }}
          />
        </Card>
        <Stack spacing={1}>
          <Typography variant="h6" fontFamily="Helvetica Neue">
            Chosen listings
          </Typography>
          <Stack alignItems="center" sx={{ pt: 1 }}>
            <Grid
              container
              spacing={2}
              sx={{ maxHeight: "35.7143rem", overflowY: "auto" }}
            >
              {props.chosenListings.map((item, i) => (
                <Grid item xs={4} key={i}>
                  <ListingCard
                    viewType={ListingViewType.BOX}
                    listing={item as RealEstateListing}
                    handleChecked={handleChecked}
                    isChecked={true}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>

          <Typography variant="h6" fontFamily="Helvetica Neue">
            Other listings
          </Typography>
          <Stack
            alignItems="center"
            sx={{ pt: 1, maxHeight: "35.7143rem", overflowY: "auto" }}
          >
            <Grid container spacing={2}>
              {current
                ?.filter(
                  (item) =>
                    !props.chosenListings
                      .map((item) => item.uuid)
                      .includes(item.uuid)
                )
                .map((item, i) => (
                  <Grid item xs={4} key={i}>
                    <ListingCard
                      viewType={ListingViewType.BOX}
                      listing={item as RealEstateListing}
                      handleChecked={handleChecked}
                    />
                  </Grid>
                ))}
            </Grid>
            {current && pageIndex < (data?.num_of_pages ?? 0) - 1 && (
              <IconButton
                sx={{
                  color: "#f5fbfe",
                  background:
                    "linear-gradient(180deg, #D288F2 0%, #6DBFF2 100%)",
                  width: "2.8571rem",
                  height: "2.8571rem",
                  mt: 2,
                  "&:hover": {
                    background:
                      "linear-gradient(180deg, #D288F2A6 0%, #6DBFF2A6 100%)",
                  },
                }}
                onClick={loadMore}
              >
                <ExpandCircleDownIcon sx={{ width: "2.8571rem", height: "2.8571rem" }} />
              </IconButton>
            )}

            {isPending && (
              <CircularProgress sx={{ display: "table", mx: "auto", my: 3 }} />
            )}
          </Stack>
        </Stack>
      </Stack>
    </ElevatorDialog>
  );
}
