import {
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  Stack,
  SxProps,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { Fragment, ReactNode, useMemo } from "react";
import { Logout } from "@mui/icons-material";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import homeIcon from "../../assets/icons/sidebar/home.svg";
// import dashboardIcon from "../../assets/icons/sidebar/dashboard.svg";
import projectsIcon from "../../assets/icons/sidebar/projects.svg";
import crmIcon from "../../assets/icons/sidebar/crm.svg";
import tasksIcon from "../../assets/icons/sidebar/tasks.svg";
import portalIcon from "../../assets/icons/sidebar/portal.svg";
import templatesIcon from "../../assets/icons/sidebar/templates.svg";
import listingsIcon from "../../assets/icons/sidebar/listings.svg";
import settingsIcon from "../../assets/icons/sidebar/settings.svg";
import schedulerIcon from "../../assets/icons/sidebar/scheduler.svg";
import portfolioIcon from "../../assets/icons/sidebar/portfolio.svg";
import expensesIcon from "../../assets/icons/sidebar/expenses.svg";


import { useLogoutMutation } from "../../api/auth";
import { WorkflowType } from "../../api/types";
import { useAtom } from "jotai";
import { currentWorkflowAtom } from "../MainAppBar/UserControlPopup";
import { BASE_PATHS, makePath, PATHS, WorkflowPath } from "../../paths";
import { alignProperty } from "@mui/material/styles/cssUtils";

import { useState, useEffect } from "react";
import mynkWhiteTextLogo from "../../assets/images/mynk-white.svg";
import { loginStateAtom } from "../../state/auth";
import {atom, useAtomValue} from "jotai";
import { languageAtom, LanguageCode } from "../LanguagePicker";

export const openSidebarAtom = atom(true);
export const sidebarWidth = 16.2;
export interface SidebarProps {}

interface SidebarItem {
  title: { [key in LanguageCode]: string };
  icon: (selected: boolean) => ReactNode;
  isDisabled: boolean;
  path?: string;
  onClick?: () => void;
}

interface SidebarItemGroup {
  items: SidebarItem[];
  bottom?: boolean;
}

function makeIcon(path: string, selected = false) {
  return (
    <img
      src={path}
      style={{
        width: "1.6rem",
        height: "1.6rem",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        filter: selected
          ? "brightness(0) saturate(100%) invert(79%) sepia(14%) saturate(5773%) hue-rotate(201deg) brightness(99%) contrast(104%)"
          : "",
      }}
    />
  );
}

const MYNK_PHOTOGRAPHY_SIDEBAR_ITEMS: SidebarItemGroup[] = [
  {
    items: [
      {
        title: {
          [LanguageCode.English]: "Home",
          [LanguageCode.Hebrew]: "דף בית",
        },
        isDisabled: false,
        icon: (selected) => makeIcon(homeIcon, selected),
        path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.home.index),
      },
      // {
      //   title: "Dashboard",
      //   isDisabled: false,
      //   icon: (selected) => makeIcon(dashboardIcon, selected),
      //   path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.dashboard.index),
      // },
    ],
  },
  {
    items: [
      // {
      //   title: 'Sales',
      //   // icon: (color: string) => <ShoppingCartOutlined sx={{color,}}/>,
      //   icon: (selected) => makeIcon(salesIcon, selected),
      //   path: '/dashboard/sales',
      // },
      {
        title: {
          [LanguageCode.English]: "Clients",
          [LanguageCode.Hebrew]: "לקוחות",
        },
        isDisabled: false,
        icon: (selected) => makeIcon(crmIcon, selected),
        path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.clients.index),
      },
      {
        title: {
          [LanguageCode.English]: "Projects",
          [LanguageCode.Hebrew]: "פרויקטים",
        },
        isDisabled: false,
        icon: (selected) => makeIcon(projectsIcon, selected),
        path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.projects.index),
      },
      {
        title: {
          [LanguageCode.English]: "Calendar",
          [LanguageCode.Hebrew]: "לוח זמנים",
        },
        isDisabled: false,
        icon: (selected) => makeIcon(schedulerIcon, selected),
        path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.scheduler.index),
      },
      {
        title: {
          [LanguageCode.English]: "Tasks",
          [LanguageCode.Hebrew]: "משימות",
        },
        isDisabled: false,
        icon: (selected) => makeIcon(tasksIcon, selected),
        path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.tasks.index),
      },
      {
        title: {
          [LanguageCode.English]: "Expenses",
          [LanguageCode.Hebrew]: "הוצאות",
        },
        isDisabled: false,
        icon: (selected) => makeIcon(expensesIcon, selected),
        path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.expenses.index),
      },
      {
        title: {
          [LanguageCode.English]: "Templates",
          [LanguageCode.Hebrew]: "תבניות",
        },
        isDisabled: false,
        icon: (selected) => makeIcon(templatesIcon, selected),
        path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.templates.index),
      },
    ],
  },
  {
    items: [
      {
        title: {
          [LanguageCode.English]: "Library",
          [LanguageCode.Hebrew]: "ספריה",
        },
        isDisabled: false,
        icon: (selected) => makeIcon(portfolioIcon, selected),
        path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.library.index),
      }
    ],
  },
  // {
  //   items: [
  //     {
  //       title: "Portfolio",
  //       isDisabled: false,
  //       icon: (selected) => makeIcon(portfolioIcon, selected),
  //       path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.portfolio.index),
  //     },
  //   ]
  // },
  {
    bottom: true,
    items: [
      {
        title: {
          [LanguageCode.English]: "Settings",
          [LanguageCode.Hebrew]: "הגדרות",
        },
        isDisabled: false,
        icon: (selected) => makeIcon(settingsIcon, selected),
        path: makePath(WorkflowPath.PHOTOGRAPHY, PATHS.settings.index),
      },
      {
        title: {
          [LanguageCode.English]: "Logout",
          [LanguageCode.Hebrew]: "התנתקות",
        },
        isDisabled: false,
        icon: (selected) => <Logout sx={{ color: "white" }} />,
      },
    ],
  },
];

interface SideBarListItemProps {
  item: SidebarItem;
}

function SideBarListItem(props: SideBarListItemProps) {
  const { item } = props;
  const currentWorkflow = useAtomValue(currentWorkflowAtom);
  const [language] = useAtom(languageAtom); // Get the current language

  const location = useLocation();
  const isSelected = useMemo(() => {
    return location.pathname.startsWith(item?.path!);
  }, [location.pathname]);

  const logout = useLogoutMutation();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const result = await logout.mutateAsync({});
      if (Object.keys(result).length === 0) {
        navigate(`/login`);
      }
    } catch {}
  };

  const sidebarOpened = useAtomValue(openSidebarAtom);

  // Determine text direction and alignment
  const isHebrew = language === LanguageCode.Hebrew;
  const textDirection = isHebrew ? "rtl" : "ltr";
  const alignment = isHebrew ? "right" : "left";

  return (
    <ListItemButton
      sx={{
        color: isSelected ? "#d288f2" : "white",
        background: isSelected ? "white" : "transparent",
        borderRadius: "10rem",
        mb: "0.19rem",
        pl: sidebarOpened ? 2 : 0,
        height: "3.3rem",
        px: sidebarOpened ? undefined : "0.8rem",
        "&:hover": {
          background: isSelected ? "white" : "transparent",
        },
        flexDirection: isHebrew ? "row-reverse" : "row", // Reverse row direction for Hebrew
      }}
      component={item.onClick ? "button" : Link}
      to={item.path || "#"}
      disabled={item.isDisabled || logout.isPending}
      onClick={item.title[LanguageCode.English] === "Logout" ? handleLogout : item.onClick}
    >
      {item.icon(isSelected)}
      <Typography
        fontSize="1.33rem"
        sx={{
          ml: isHebrew ? 0 : 1.5, // Adjust margin for RTL
          mr: isHebrew ? 1.5 : 0,
          color: isSelected ? "#c4a6ff" : "white",
          opacity: sidebarOpened ? 1 : 0,
          transition: "opacity 0.4s ease",
          textAlign: alignment, // Align text based on language
          direction: textDirection, // Set text direction
        }}
      >
        {logout.isPending ? (
          <Stack alignItems="center" sx={{ width: "100%" }}>
            <CircularProgress sx={{ color: "white" }} size={"1.5rem"} />
          </Stack>
        ) : (
          item.title[language] // Render the title based on the selected language
        )}
      </Typography>
    </ListItemButton>
  );
}

interface SidebarItemListProps {
  items: SidebarItem[];
  sx?: SxProps;
}

function SidebarItemList(props: SidebarItemListProps) {
  const sidebarOpened = useAtomValue(openSidebarAtom);

  return (
    <List sx={{ px: sidebarOpened ? 2.7 : 1, ...props.sx }}>
      {props.items.map((item) => (
        <SideBarListItem item={item} key={item.title} />
      ))}
    </List>
  );
}

interface SidebarItemGroupCollectionProps {
  groups: SidebarItemGroup[];
}

function SidebarItemGroupCollection(props: SidebarItemGroupCollectionProps) {
  const openedSidebar = useAtomValue(openSidebarAtom);

  return (
    <>
      {props.groups.map((group, i) => (
        <Fragment key={i}>
          <SidebarItemList items={group.items} />

          {i !== props.groups.length - 1 && (
            <Divider sx={{ background: "white", mx: openedSidebar ? 4.5 : 1, my: 1.7 }} />
          )}
        </Fragment>
      ))}
    </>
  );
}

export default function Sidebar(props: SidebarProps) {
  const [currentWorkflow, setCurrentWorkflow] = useAtom(currentWorkflowAtom);

  const topGroups = useMemo(() => {
    if (currentWorkflow?.type == WorkflowType.PHOTOGRAPHY) {
      return MYNK_PHOTOGRAPHY_SIDEBAR_ITEMS.filter((g) => !g.bottom);
    } else {
      return [];
    }
  }, [MYNK_PHOTOGRAPHY_SIDEBAR_ITEMS]);

  const bottomGroups = useMemo(() => {
    if (currentWorkflow?.type == WorkflowType.PHOTOGRAPHY) {
      return MYNK_PHOTOGRAPHY_SIDEBAR_ITEMS.filter((g) => g.bottom);
    } else {
      return [];
    }
  }, [MYNK_PHOTOGRAPHY_SIDEBAR_ITEMS]);

  const navigate = useNavigate();
  const navigateHome = () => {
    navigate(
      makePath(`/${currentWorkflow?.type}` as WorkflowPath, PATHS.home.index)
    );
  };

  const openedSidebar = useAtomValue(openSidebarAtom);

  // Determine the language
  const language = useAtomValue(languageAtom);

  // Set anchor dynamically
  const anchor = language === LanguageCode.Hebrew ? "right" : "left";

  return (
    <Drawer
      sx={{
        width: `${openedSidebar ? sidebarWidth : sidebarWidth / 3.7}rem`,
        position: "relative",
        flexShrink: 0,
        transition: "width 0.5s ease",
        "& .MuiDrawer-paper": {
          width: `${openedSidebar ? sidebarWidth : sidebarWidth / 3.7}rem`,
          background: "linear-gradient(#d288f2, #6dbff2)",
          borderRight: 0,
          borderLeft: 0,
          boxSizing: "border-box",
          transition: "width 0.5s ease",
          "::-webkit-scrollbar": {
            display: "none",
          },
          overflowX: "hidden",
        },
      }}
      variant="permanent"
      anchor={anchor} // Dynamically set the anchor
    >
      <Stack alignItems="center" alignContent="center">
        <Box
          component="img"
          src={mynkWhiteTextLogo}
          sx={{
            width: openedSidebar ? "34%" : "90%",
            mt: "3rem",
            mb: "2rem",
            height: "3rem",
            cursor: "pointer",
            transform: openedSidebar ? undefined : "rotate(-90deg)",
            transition: "transform 0.4s ease, width 0.4s ease",
          }}
          onClick={navigateHome}
        />
      </Stack>
      <SidebarItemGroupCollection groups={topGroups} />

      <Box sx={{ position: "absolute", bottom: "1rem", left: 0, right: 0 }}>
        <Divider
          sx={{
            background: "white",
            mx: openedSidebar ? 4.5 : 1,
            my: 1.7,
          }}
        />
        <SidebarItemGroupCollection groups={bottomGroups} />
      </Box>
    </Drawer>
  );
}
