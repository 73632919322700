import { Card, CardHeader, Divider } from '@mui/material';
import SalesInfoTable, { SalesInfoTableProps } from './SalesInfoTable';
import React from 'react';


export interface SalesInfoTableCardProps {
  tableProps?: SalesInfoTableProps;
}

export function SalesInfoTableCard(props: SalesInfoTableCardProps) {
  return (
    <Card>
      <CardHeader title="Sales info" />

      <Divider sx={{ borderColor: '#edf6ff', mt: -1, mb: 1 }} />

      <SalesInfoTable {...props.tableProps} />
    </Card>
  );
}
