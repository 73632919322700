import React, {ReactNode} from "react";
import {Box, Card, SxProps} from "@mui/material";


export interface ElevatorCardProps {
  children: ReactNode;
  sx?: SxProps;
}

export default function ElevatorCard(props: ElevatorCardProps) {
  return (
    <Card
      sx={{
          background: 'rgba(255, 255, 255, 0.70)',
          border: "0.1rem white solid",
          backdropFilter: "blur(0.6rem)",
        ...props.sx,
      }}
    >
      <Box sx={{ m: 2.5, p: 3, px: 6, background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.60) 100%)', boxShadow: '0px 14px 24px 1px rgba(0, 0, 0, 0.15)', borderRadius: '1rem', position: 'relative' }}>
        {props.children}
      </Box>
    </Card>
  )
}