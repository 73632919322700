import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { MynkTab } from "../../../../components/MynkPage";
import {
  Alert,
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import {
  useListDefaultTemplatesQuery,
  useListTemplatesQuery,
} from "../../../../api/templates";
import { Template, TemplateKind } from "../../../../api/types";
import { PATHS, WorkflowPath, makePath } from "../../../../paths";
import ActionMenuButton from "../../../../components/ActionMenuButton";
import { Add } from "@mui/icons-material";
import scrollRightArrow from "../../../../assets/icons/scroll-right-arrow.svg";
import scrollLeftArrow from "../../../../assets/icons/scroll-left-arrow.svg";
import contractBg from "../../../../assets/images/contract-background.svg";
import downloadIcon from "../../../../assets/icons/upload-files-icon.svg";

const PREVIEW_WIDTH = "22.7em";

enum NewMenuIds {
  NEW_INVOICE = "new-invoice",
}

const NEW_MENU = [
  {
    id: NewMenuIds.NEW_INVOICE,
    label: "New invoice",
  },
];

interface NewTemplatesButtonProps {
  defaultTemplates?: Template[];
}

export function NewTemplatesButton(props: NewTemplatesButtonProps) {
  const navigate = useNavigate();

  const handleClickItem = (id: string) => {
    switch (id) {
      case NewMenuIds.NEW_INVOICE:
        navigate(
          makePath(
            WorkflowPath.PHOTOGRAPHY,
            PATHS.instantiateTemplate(
              props.defaultTemplates?.find(
                (template) => template.kind == TemplateKind.INVOICE
              )?.uuid ?? ""
            ).index
          )
        );
        break;
    }
  };

  return (
    <>
      <ActionMenuButton
        startIcon={<Add sx={{ width: "1.1rem" }} />}
        menu={NEW_MENU}
        onClickItem={handleClickItem}
      >
        New
      </ActionMenuButton>
    </>
  );
}

interface ContractPreviewProps {
  name: string;
  state: string;
  onDownload?: (uuid: string) => void;
}

function ContractPreview(props: ContractPreviewProps) {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadFile = async (file: File) => {
    const url = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleGetContract = async () => {
    try {
      const response = await axios.get(
        `/api/files/get-contract?name=${props.name
          .toLowerCase()
          .replace(/\s+/g, "-")}&state=${props.state
          .toLowerCase()
          .replace(/\s+/g, "-")}`,
        {
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const file = new File(
        [blob],
        props.name + " (" + props.state + ")" + ".docx",
        {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }
      );
      return file;
    } catch (error) {
      console.error("Error fetching contract:", error);
    }
  };

  const onClick = async () => {
    setIsDownloading(true);
    const file = await handleGetContract();
    if (file) {
      await downloadFile(file);
    }
    setIsDownloading(false);
  };

  return (
    <Card sx={{ border: "0.12rem solid white", width: "25rem" }}>
      <Stack>
        {isDownloading ? (
          <Stack
            sx={{
              height: "16.7rem",
              alignItems: "center",
              alignContent: "center",
              display: "flex",
              justifyContent: "flex",
            }}
          >
            <Box sx={{ flex: 1 }} />
            <CircularProgress />
            <Box sx={{ flex: 1 }} />
          </Stack>
        ) : (
          <Box component="img" src={contractBg} sx={{ height: "16.7rem" }} />
        )}
        <Stack direction="row">
          <Stack sx={{ margin: "1rem" }} spacing="0.7rem">
            <Typography fontFamily="Helvetica Neue Bold" fontSize="1.4rem">
              {props.name}
            </Typography>
            <Typography>{props.state}</Typography>
          </Stack>
          <Stack>
            <Box sx={{ flex: 1 }} />
            <IconButton
              sx={{ width: "2.2rem", height: "2.2rem", m: "0.6rem" }}
              onClick={onClick}
            >
              <Box
                component="img"
                src={downloadIcon}
                sx={{ width: "1.5rem", height: "1.5rem" }}
              />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
interface TemplatePreviewProps {
  imageUrl: string;
  onClick?: () => void;
}

function TemplatePreview(props: TemplatePreviewProps) {
  const [imageReady, setImageReady] = useState(false);

  return (
    <Box
      sx={{
        width: PREVIEW_WIDTH,
        height: `calc(1.4 * ${PREVIEW_WIDTH})`,
        cursor: "pointer",
        transition: "transform 0.15s ease-in-out",
        "&:hover": {
          transform: "scale(1.04)",
        },
      }}
      onClick={props.onClick}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          height: "100%",
          // background: `url(${props.imageUrl})`,
          // backgroundSize: "cover",
          boxShadow: "8px 0px 18px #b0cbeb80",
          overflow: "none",
        }}
      >
        {!imageReady && (
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={"100%"}
            sx={{ bgcolor: "white" }}
          />
        )}

        <img
          src={props.imageUrl}
          onLoad={() => setImageReady(true)}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            display: imageReady ? "block" : "none",
          }}
        />
      </Stack>
    </Box>
  );
}

interface TemplateLineupProps {
  title: string;
  templates: Template[];
}

function TemplateLineup(props: TemplateLineupProps) {
  const navigate = useNavigate();
  const handleClick = (t: Template) => {
    navigate(
      makePath(
        WorkflowPath.PHOTOGRAPHY,
        PATHS.instantiateTemplate(t.uuid).index
      )
    );
  };

  const groupTitle = useMemo(() => {
    return props.title + "s";
  }, [props.title]);

  const [templatesCards, setTemplatesCards] = useState<ReactElement[]>([]);
  const [currentPage, setCurrenctPage] = useState(0);
  const [slideDirection, setSlideDirection] = useState<
    "right" | "left" | undefined
  >("left");
  const templatesCardsPerPage = 4;
  useEffect(() => {
    setTemplatesCards(
      props.templates.map((t) => (
        <TemplatePreview
          key={t.uuid}
          imageUrl={t.preview_uri}
          onClick={() => handleClick(t)}
        />
      ))
    );
  }, [props.templates]);

  const handleNext = () => {
    setSlideDirection("left");
    setCurrenctPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setSlideDirection("right");
    setCurrenctPage((prevPage) => prevPage - 1);
  };
  return (
    <Box sx={{ mb: 8.7 }}>
      <Typography variant="h4" sx={{ pt: 2 }}>
        {groupTitle}
      </Typography>

      <Stack
        direction="row"
        spacing="-3.4rem"
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
          mt: "2rem",
          height: "30rem",
        }}
      >
        {currentPage === 0 ? (
          <Box sx={{ width: "5.5rem", height: "5.5rem" }}></Box>
        ) : (
          <IconButton onClick={handlePrev} sx={{ margin: 5 }}>
            <Box
              sx={{
                width: "5.5rem",
                height: "5.5rem",
                borderRadius: "50%",
                background: "rgba(176, 176, 176, 0.3)",
                backdropFilter: "blur(0.1143rem)",
                alignItems: "center",
                alignContent: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={scrollLeftArrow}
                sx={{ width: "2rem", height: "2rem", mr: "0.2rem" }}
              />
            </Box>
          </IconButton>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            alignContent: "flex-start",
            justifyContent: "flex-start",
            height: "30rem",
            width: "100%",
          }}
        >
          {templatesCards.map((card, index) => (
            <Box
              key={index}
              sx={{
                width: `calc(100% / ${Math.min(
                  templatesCardsPerPage,
                  props.templates.length - index * templatesCardsPerPage
                )})`,
                display: currentPage === index ? "block" : "none",
              }}
            >
              <Slide direction={slideDirection} in={currentPage === index}>
                <Stack spacing={5} direction="row">
                  {templatesCards
                    .slice(
                      index * templatesCardsPerPage,
                      index * templatesCardsPerPage + templatesCardsPerPage
                    )
                    .map((template, subIndex) => (
                      <Box key={subIndex}>{template}</Box>
                    ))}
                </Stack>
              </Slide>
            </Box>
          ))}
        </div>
        <IconButton
          onClick={handleNext}
          sx={{
            margin: 5,
            display:
              currentPage >=
              Math.ceil((templatesCards.length || 0) / templatesCardsPerPage) -
                1
                ? "none"
                : "block",
          }}
        >
          <Box
            sx={{
              width: "5.5rem",
              height: "5.5rem",
              borderRadius: "50%",
              background: "rgba(176, 176, 176, 0.3)",
              backdropFilter: "blur(0.1143rem)",
              alignItems: "center",
              alignContent: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={scrollRightArrow}
              sx={{ width: "2rem", height: "2rem", ml: "0.2rem" }}
            />
          </Box>
        </IconButton>
      </Stack>
    </Box>
  );
}

interface TemplateGroup {
  title: string;
  templates: Template[];
}

const MY_TEMPLATE = "Saved template";
const DEFAULT_TEMPLATE = "Default template";

export default function TemplatesAllSubpage() {
  const { data, isPending } = useListTemplatesQuery({});
  const { data: defaultTemplatesData, isPending: defaultisPending } =
    useListDefaultTemplatesQuery({});

  const [defaultGroup, setDefaultGroup] = useState<null | TemplateGroup>(null);
  useEffect(() => {
    if (!defaultTemplatesData || defaultisPending) return;

    const defaultTemplateGroup: TemplateGroup = {
      title: DEFAULT_TEMPLATE,
      templates: [],
    };
    for (const template of defaultTemplatesData.templates) {
      defaultTemplateGroup.templates.push(template);
    }

    setDefaultGroup(defaultTemplateGroup ?? null);
  }, [defaultTemplatesData, defaultisPending]);

  const [myGroup, setMyGroup] = useState<null | TemplateGroup>(null);
  const [groups, setGroups] = useState<null | TemplateGroup[]>(null);
  useEffect(() => {
    if (!data || isPending) return;

    const result: { [key: string]: TemplateGroup } = {};
    for (const template of data.templates) {
      const key = template.is_standard ? template.kind : MY_TEMPLATE;

      let group = result[key];
      if (group === undefined) {
        result[key] = {
          title: key[0].toUpperCase() + key.slice(1),
          templates: [template],
        };
      } else {
        group.templates.push(template);
      }
    }

    const groups = Object.values(result);
    // const myGroup = groups.find((g) => g.title === MY_TEMPLATE) ?? null;
    // setMyGroup(myGroup);
    setGroups(groups.filter((g) => g.title !== MY_TEMPLATE));
  }, [data, isPending]);

  return (
    <MynkTab
      title="All"
      action={
        <NewTemplatesButton
          defaultTemplates={defaultTemplatesData?.templates}
        />
      }
    >
      {isPending && (
        <Alert
          severity="info"
          icon={<CircularProgress size={16} sx={{ mt: 0.5 }} />}
          sx={{ mt: 3 }}
        >
          Loading templates...
        </Alert>
      )}

      {defaultGroup && (
        <TemplateLineup
          key={defaultGroup.title}
          title={defaultGroup.title}
          templates={defaultGroup.templates}
        />
      )}

      {myGroup && (
        <TemplateLineup
          key={myGroup.title}
          title={myGroup.title}
          templates={myGroup.templates}
        />
      )}

      {groups &&
        groups.map((group) => (
          <TemplateLineup
            key={group.title}
            title={group.title}
            templates={group.templates}
          />
        ))}

      {/* <Box sx={{ mb: 8.7 }}>
        <Typography variant="h4" sx={{ pt: 2, pb: "2rem" }}>
          Contracts
        </Typography>

        <Stack direction="row" sx={{ ml: "2rem" }} spacing="2rem">
          <ContractPreview name="Wedding photography agreement" state="USA" />
          <ContractPreview
            name="Photography services rescheduling agreement"
            state="USA"
          />
          <ContractPreview
            name="General portrait photography services agreement"
            state="USA"
          />
        </Stack>
      </Box> */}
    </MynkTab>
  );
}
