import {Box} from "@mui/material";
import {useGetTemplateQuery, useSendTemplateMutation} from "../../../api/templates";
import {Template} from "./Template";
import React, {useEffect, useMemo, useRef} from "react";
import {TemplateContext} from "./TemplateContext";
import {useAtom} from "jotai";
import {confirmedInvoiceToSendAtom} from "../../../state/ai";
import {useSnackbar} from "notistack";
import {TemplateRef} from "./common";
import jsPDF from "jspdf";
import {AxiosError} from "axios/index";
import {SendTemplateParams} from "../../../api/types";
import {formatMoney, generateId} from "../../../util/common";
import {useListGeneralSettingsQuery} from "../../../api/settings";
import {useAtomValue} from "jotai";
import {userCurrencyAtom} from "../../../layouts/Main";
import { DateTime } from "luxon";
import {useGetClientQuery} from "../../../api/clients";


export interface TemplatePreviewImageProps {
  template_uuid: string;
  client_uuid: string;
  item_name: string;
  item_price: number;
  item_quantity: number;
}

export function TemplatePreviewImage(props: TemplatePreviewImageProps) {
  const widthRem = 16;
  const heightRem = 1.41 * widthRem;
  const templateRef = useRef<null | TemplateRef>(null);

  const { data, isPending } = useGetTemplateQuery({ uuid: props.template_uuid });

  const {data: generalSettingsData} = useListGeneralSettingsQuery({});
  const userCurrency = useAtomValue(userCurrencyAtom);
  const {data: projectClient} = useGetClientQuery({
    uuid: props.client_uuid ?? "",
  });

  const initialData = useMemo(() => {
    if (!projectClient || !generalSettingsData)
      return null;

    return {
      items: [
        {
          item: props.item_name,
          quantity: props.item_quantity,
          price: props.item_price,
          _id: generateId(16),
        }
      ],
      client: {
        uuid: props.client_uuid,
        name: projectClient?.client?.full_name,
        phone: projectClient?.client?.phone,
        email: projectClient?.client?.email,
      },
      settings: {
        currency: formatMoney(undefined, userCurrency),
      },
      me: {
        name: generalSettingsData?.business_name,
        address: generalSettingsData?.business_address,
        email: generalSettingsData?.email,
        phone: generalSettingsData?.business_phone,
      },
      issued_on: DateTime.now().toFormat("dd MMMM yyyy"),
      due_date: DateTime.now().plus({month: 1}).toFormat("dd MMMM yyyy"),
      template_uuid: ((generalSettingsData?.invoice_start_number ?? 0) + 1)
        .toString()
        .padStart(4, "0"),
      sum: {
        subtotal: props.item_quantity * props.item_price,
        total: props.item_quantity * props.item_price,
        amount_due: props.item_quantity * props.item_price,
      },
    }
  }, [projectClient, generalSettingsData]);

  const sendTemplate = useSendTemplateMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, SendTemplateParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        alert(detail ?? "Sending failed...");
      }
    },
  });

  const { enqueueSnackbar } = useSnackbar();
  const [confirmedInvoiceToSend, updateConfirmedInvoiceToSend] = useAtom(confirmedInvoiceToSendAtom);
  useEffect(() => {
    if (!confirmedInvoiceToSend)
      return;

    (async () => {
      if (!data?.template || !templateRef.current) return;

      enqueueSnackbar("Sending invoice...", {variant: "info"});

      console.log('rendering');
      const rendering = await templateRef.current.renderTemplate();
      console.log('rendering result:', rendering);
      if (!rendering) return;

      const base64PngData = rendering.data;

      // save the png image to a pdf using jsPdf
      const pdf = new jsPDF("p", "pt", [595, 842]);
      pdf.addImage(base64PngData, "PNG", 0, 0, 595, 842);

      // upload pdf as FormData using sendTemplate mutation
      const formData = new FormData();
      formData.append("file", pdf.output("blob"), "file.pdf");
      formData.append("details", JSON.stringify({
        client: {
          uuid: confirmedInvoiceToSend.client_uuid,
        },
        template_uuid: data.template.uuid,
      }));

      console.log('sending');
      await sendTemplate.mutateAsync({
        template_uuid: data.template.uuid,
        form_data: formData,
      });

      updateConfirmedInvoiceToSend(null);
      enqueueSnackbar("Invoice sent!", {variant: "success"});
    })();
  }, [confirmedInvoiceToSend]);

  return (
    <Box
      sx={{
        width: `${widthRem}rem`,
        height: `${heightRem}rem`,
        bgcolor: 'black',
        userSelect: 'none',
      }}
    >
      {data?.template && initialData && (
        <TemplateContext
          initialData={initialData}
        >
          <Template
            live={false}
            template={data.template.html}
            ref={templateRef}
            realSize={{ width: '60rem', height: '85rem' }}
            sx={{ width: '100%', height: '100%' }}
          />
        </TemplateContext>
      )}
    </Box>
  );
}