import {useState} from 'react'
import { Stack, Typography } from '@mui/material';
import Images from '../common/Images';
import Presentation from '../common/Presentation';
import PreviewButtons from '../common/PreviewButtons';

export default function DesignPage() {
  const [phoneSelected, setPhoneSelected] = useState<boolean>(false)
  const [desktopSelected, setDesktopSelected] = useState<boolean>(false)

  return (
    <Stack>
        <PreviewButtons phoneSelected={phoneSelected} desktopSelected={desktopSelected} setPhoneSelected={setPhoneSelected} setDesktopSelected={setDesktopSelected}/>
        <Presentation isEditing={false} />
        <Typography fontFamily="Helvetica Medium" variant="h1" fontWeight='500' sx={{marginTop: '4rem', marginLeft: '2.5rem', marginBottom: '2rem'}}>
            My Portfolio
        </Typography>
        <Images />
    </Stack>
  )
}
