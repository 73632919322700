import { atom, useAtom } from "jotai";
import {
  useGetBusinessLogoQuery,
  useGetProfilePhotoQuery,
  useListGeneralSettingsQuery,
} from "../api/settings";
import { MouseEventHandler, useEffect } from "react";
import { fetchAllImagesByUuids } from "../util/common";
import { Box, Typography, SxProps } from "@mui/material";
import { PhotoType } from "../pages/CommonComponents/Settings/common/UploadOnePhoto";

export const profilePhotoAtom = atom("");
export const businessLogoAtom = atom("");

interface ProfilePictureProps {
  onClick?: MouseEventHandler<HTMLImageElement>;
  sx?: SxProps;
  size?: string;
  fontSize?: string;
  type: PhotoType;
}

export default function ProfilePicture(props: ProfilePictureProps) {
  const [profileSrc, setProfileSrc] = useAtom(profilePhotoAtom);
  const [logoSrc, setLogoSrc] = useAtom(businessLogoAtom);

  const { data: profilePhotoData } = useGetProfilePhotoQuery();
  const { data: businessLogoData } = useGetBusinessLogoQuery();

  const { data: generalSettingsData } = useListGeneralSettingsQuery({});
  const fullNameInitials = generalSettingsData?.user_name
    ?.split(" ")
    .map((n) => n[0])
    .join("")
    .substring(0, 2);

  useEffect(() => {
    const fetchData = async () => {
      if (props.type == PhotoType.PROFILE) {
        if (profilePhotoData?.uuid && profilePhotoData.uuid != "") {
          const imgSrcs = await fetchAllImagesByUuids(
            [profilePhotoData.uuid],
            true
          );
          setProfileSrc(imgSrcs[0]);
        }
      } else if (props.type == PhotoType.LOGO) {
        if (businessLogoData?.uuid && businessLogoData.uuid != "") {
          const imgSrcs = await fetchAllImagesByUuids(
            [businessLogoData.uuid],
            true
          );
          setLogoSrc(imgSrcs[0]);
        }
      }
    };

    fetchData();
  }, [profilePhotoData, businessLogoData]);

  return profileSrc || logoSrc ? (
    // Render profile photo
    <Box
      component="img"
      src={props.type == PhotoType.LOGO ? logoSrc :  profileSrc ?? ""}
      sx={{
        width: props.size ?? "4rem",
        height: props.size ?? "4rem",
        objectFit: "cover",
        borderRadius: "50%",
        cursor: props.onClick ? "pointer" : "auto",
        boxShadow: "0rem 0.0714rem 0.3571rem rgba(0, 0, 0, 0.35)",
        ...props.sx,
      }}
      onClick={props.onClick}
    />
  ) : (
    // Render user's name initials "photo"
    <Box
      sx={{
        width: props.size ?? "4rem",
        height: props.size ?? "4rem",
        objectFit: "cover",
        borderRadius: "50%",
        cursor: props.onClick ? "pointer" : "auto",
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.35)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#8c8c8c",
        ...props.sx,
      }}
      onClick={props.onClick}
    >
      <Typography
        fontFamily="Helvetica Neue"
        fontWeight="bold"
        fontSize={props.fontSize ? props.fontSize : "1.34rem"}
        color="white"
      >
        {props.type == PhotoType.PROFILE
          ? fullNameInitials
          : "Logo"}
      </Typography>
    </Box>
  );
}
