import {
  Dialog,
  Typography,
  Box,
  Stack,
  SxProps,
  IconButton,
  CircularProgress,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadFile } from "../../util/common";
import { FileObj } from "../../api/types";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PreviewFileDialogProps {
  selectedFile: FileObj | null;
  open: boolean;
  onClose: () => void;
  disableDownload?: boolean;
  sx?: SxProps;
}

export default function PreviewFileDialog(props: PreviewFileDialogProps) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const previewStyles = {
    border: "1px solid black",
    borderRadius: props.disableDownload
      ? "0.8571rem"
      : "0.8571rem 0.8571rem 0 0",
    borderStyle: props.disableDownload ? "none" : "solid solid none solid",
  };

  const onDownloadFile = () => {
    if (props.selectedFile) {
      downloadFile(props.selectedFile.name, props.selectedFile.data);
    }
  };

  const renderPreview = () => {
    console.log(props.selectedFile);
    if (!props.selectedFile) {
      return <CircularProgress sx={{ margin: "10rem" }} />;
    }
    switch (true) {
      case props.selectedFile.mime_type!.startsWith("image/"):
        return (
          <Box
            component="img"
            maxHeight="45rem"
            src={props.selectedFile.data}
            style={previewStyles}
          />
        );
      case props.selectedFile.mime_type!.startsWith("video/"):
        return (
          <video
            src={props.selectedFile.data}
            controls
            autoPlay
            style={previewStyles}
          />
        );
      case props.selectedFile.mime_type!.startsWith("audio/"):
        return (
          <audio
            src={props.selectedFile.data}
            controls
            autoPlay
            style={{ ...previewStyles, minWidth: "25rem" }}
          />
        );
      case props.selectedFile.mime_type === "application/pdf":
        return (
          <Document
            file={props.selectedFile.data}
            onLoadSuccess={({ numPages }) => {setNumPages(numPages); console.log(numPages)}}
          >
            <Stack
              spacing="1rem"
              sx={{ "& .react-pdf__Page__canvas": { height: "55rem !important", width: "auto !important" } }}
            >
              {[...Array(numPages)].map((_, i) => {
                return (
                  <Page
                    key={i}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    pageIndex={i}
                  />
                );
              })}
            </Stack>
          </Document>
        );
      // case props.selectedFile.mime_type!.startsWith("text/"):
      // case props.selectedFile.mime_type === "application/json":
      //   return (
      //     <pre>
      //       {new TextDecoder().decode(Uint8Array.from(atob(props.selectedFile.data.split(",")[1]), c => c.charCodeAt(0)))}
      //     </pre>
      //   );

      default:
        return (
          <Typography
            fontFamily="Helvetica Neue"
            padding={2}
            sx={previewStyles}
          >
            Sorry, preview is not available for this type of file.
          </Typography>
        );
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={false}
      sx={props.selectedFile?.mime_type === "application/pdf" ? {
        "& div.MuiPaper-root": {
          bgcolor: "transparent",
          boxShadow: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
      } : {}}
    >
      <Stack padding={1}>
        {renderPreview()}
        {!props.disableDownload ?? (
          <IconButton
            onClick={onDownloadFile}
            sx={{
              borderRadius: "0 0 0.8571rem 0.8571rem",
              height: "2.4286rem",
              border: "1px solid black",
            }}
          >
            <Typography fontFamily="Helvetica Neue">
              Download file&nbsp;
            </Typography>
            <FileDownloadIcon />
          </IconButton>
        )}
      </Stack>
    </Dialog>
  );
}
