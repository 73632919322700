import {useLogoutMutation, useWhoamiQuery} from "../api/auth";
import {useCallback} from "react";
import {useSetAtom} from "jotai";
import {currentWorkflowAtom} from "../components/MainAppBar/UserControlPopup";
import {useNavigate} from "react-router-dom";


export function useHasActiveProSubscription() {
  const {data} = useWhoamiQuery();
  return (data?.plan?.plan === 'pro' && data?.plan?.status === 'active') || (data?.plan?.status === 'trial');
}


export function useLogout() {
  const logout = useLogoutMutation();
  const setWorkflowAtom = useSetAtom(currentWorkflowAtom);

  const navigate = useNavigate();

  const handler = useCallback(async () => {
    const result = await logout.mutateAsync({});
    setWorkflowAtom(undefined);
    navigate(`/login`);
  }, []);

  return {
    logout: handler,
    isLoading: logout.isPending,
  };
}
