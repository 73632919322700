import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import {
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PRIORITY_OPTIONS } from "../components/ChipSelect/commonOptions";
import React, { useEffect, useMemo, useState } from "react";
import { useAddTaskMutation } from "../api/tasks";
import { AddTaskParams } from "../api/types";
import { DateSelectQuickOption } from "../components/MynkForm/controls/DateSelect";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import {AxiosError} from "axios";
import { zonedDate } from "../util/common";
import { DateTime } from "luxon";

export const FUTURE_QUICK_OPTIONS = [
  { label: "Today", deltaMin: 0, deltaMax: 1 },
  { label: "Tomorrow", deltaMin: 1, deltaMax: 2 },
];

export interface AddReminderDialogProps {
  open: boolean;
  onClose: () => void;
  defaultDelta?: number;
  quickOptions?: DateSelectQuickOption[];
}

export default function AddReminderDialog(props: AddReminderDialogProps) {
  const [title, setTitle] = useState(""); 
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const addReminder = useAddTaskMutation({
      onError: (error: unknown) => {
        if (error instanceof Error && "response" in error) {
          const axiosError = error as AxiosError<unknown, AddTaskParams>;
          const detail = (axiosError.response?.data as { detail?: string })
            ?.detail;
          setErrorMsg(detail ?? "");
        }
      }
  });

  const handleSubmit = async (values: any) => {
    const dueDate = new Date(values.due_date.valueOf());
    
    // Set to the last moment of the day in local time
    dueDate.setHours(23, 59, 59);
    
    // Adjust for UTC+3
    const offsetInMilliseconds = 3 * 60 * 60 * 1000; // 3 hours in milliseconds
    const adjustedDueDate = new Date(dueDate.getTime() + offsetInMilliseconds);
  
    // Adjust time component (from values.due_time if needed)
    let dueTime = new Date(values.due_time.valueOf());
    const timezoneOffsetMs = dueTime.getTimezoneOffset() * 60 * 1000;
    dueTime = new Date(dueTime.getTime() - timezoneOffsetMs);
    
    // Combine adjusted date and time
    const combinedDueDate = new Date(
      adjustedDueDate.getFullYear(),
      adjustedDueDate.getMonth(),
      adjustedDueDate.getDate(),
      dueTime.getHours(),
      dueTime.getMinutes(),
      dueTime.getSeconds()
    );
  
    const params = {
      title: title.trim(),
      due_date: combinedDueDate.getTime(), // Use the adjusted due_date as a number
      is_reminder: true,
    } as AddTaskParams;
  
    await addReminder.mutateAsync(params);
    props.onClose();
  };

  const dueDateData = useMemo(() => {
    const quickOptions = props.quickOptions ?? FUTURE_QUICK_OPTIONS;

    let defaultIndex = null;
    if (props.defaultDelta !== undefined) {
      quickOptions.forEach((option, i) => {
        if (
          props.defaultDelta! >= option.deltaMin &&
          props.defaultDelta! < option.deltaMax
        ) {
          defaultIndex = i;
        }
      });
    }

    const twoDaysAhead = new Date();
    twoDaysAhead.setDate(twoDaysAhead.getDate() + 2);

    return {
      default: twoDaysAhead,
      defaultIndex,
      quickOptions,
    };
  }, [props.defaultDelta]);

  useEffect(() => {
    setTitle("");
  }, [props.open]);

  let now = DateTime.now();
  if (now.minute !== 0 || now.second !== 0) {
    now = now.plus({ hours: 2 }).set({ minute: 0, second: 0 });
  }

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      action="Set Reminder"
      actionDisabled={title.length === 0}
      onSubmit={handleSubmit}
      loading={addReminder.isPending}
      elevatorSx={{
        px: "3rem",
        py: "2rem",
      }}
      errorMsg={errorMsg}
    >
      <Stack sx={{ width: "28rem", height: '14rem' }}>
        <TextField
          fullWidth
          multiline
          variant="standard"
          placeholder="Type reminder here.."
          InputProps={{
            disableUnderline: true,
            style: { fontSize: "1.8rem", fontFamily: "Helvetica Neue Bold" },
          }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <Grid container spacing="1.4rem" sx={{ mt: "0.4rem" }}>
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.DATE_SELECT}
              name="due_date"
              label="Due:"
              default={dueDateData.default}
              quickOptions={dueDateData.quickOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.DATE_SELECT}
              name="due_time"
              label="Time:"
              onlyTime
              default={now}
            />
          </Grid>
        </Grid>
      </Stack>
    </MynkFormDialog>
  );
}
