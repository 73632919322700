import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    Stack,
    SxProps,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {ResponsiveLine} from '@nivo/line';
import {format, isToday} from 'date-fns';
import {ResponsivePie} from '@nivo/pie';
import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import ExpensesInfoTable from '../../../../components/ExpensesInfoTableCard/ExpensesInfoTable';
import {useListAllExpensesQuery, useListExpensesQuery} from '../../../../api/expenses';
import {ExpensesWindowSize, PaymentMethod, SalesWindowSize, Expense, ExpensesCategories} from '../../../../api/types';
import { formatMoney, zonedDate} from '../../../../util/common';
import {atom, useAtom, useAtomValue} from 'jotai';
import {MynkTab} from "../../../../components/MynkPage";
import { ExpensesNewButton } from '../common/NewButton';
import { userCurrencyAtom } from '../../../../layouts/Main';
import { EXPENSES_CATEGORIES } from '../../../../components/ChipSelect/commonOptions';
import { Gradient } from '@mui/icons-material';
  
const PIE_CHART_COLORS = [
  {from: '#91d0fe', to: '#91d0fe'},
  {from: '#6ab4ff', to: '#6ab4ff'},
  {from: '#fe8875', to: '#fe8875'},
  {from: '#f9b41b', to: '#f9b41b'},
  {from: '#b6f09a', to: '#b6f09a'},
];
  
type ExpensesCardStatColor = 'black' | 'blue' | 'green' | 'red';

interface ExpensesCardStatProps {
    title: string;
    value?: ReactNode;
    color?: ExpensesCardStatColor;
}
  
function getExpensesCardStatColor(label: ExpensesCardStatColor) {
    switch (label) {
        case 'black':
        return '#000';
        case 'blue':
        return '#4f70a9';
        case 'green':
        return '#4ea437';
        case 'red':
        return '#d32f2f';
    }
}
  
function ExpensesCardStat(props: ExpensesCardStatProps) {
    const color = getExpensesCardStatColor(props.color || 'black');

    return (
        <Box>
            <Typography sx={{color: '#606060'}} fontFamily="Helvetica Neue">{props.title}</Typography>
            <Typography fontSize={'2.3rem'} sx={{color,}}>{props.value}</Typography>
        </Box>
    );
}
  
interface MainSalesCardProps {
    sx?: SxProps;
    hideStats?: boolean;
}

interface MainExpensesLineChartProps {
    windowSize: ExpensesWindowSize;
    data: [string, number][];
}
  
function MainExpensesChart(props: MainExpensesLineChartProps) {
  const data = useMemo(() => [{
    id: 'expenses-' + props.windowSize,
    data: props.data.map(([timeStr, amount]) => ({
      x: timeStr,
      y: amount,
    })),
  }], [props.data, props.windowSize]);

  return (
    <>
      <svg style={{height: 0}}>
        <defs>
          <linearGradient id="redGradient" gradientUnits="userSpaceOnUse" x1="0" y1="0" x2="0" y2="210">
            <stop offset="25%" stopColor="#E14C64"/>
            <stop offset="100%" stopColor="#F4702385"/>
          </linearGradient>
        </defs>
      </svg>

      <ResponsiveLine
        data={data}
        margin={{
          top: 10,
          bottom: 62,
          left: 20,
          right: 35,
        }}
        colors={['url(#redGradient)']}
        enablePoints
        pointSize={10}
        enableGridY={false}
        isInteractive
        pointColor="white"
        pointBorderColor="#E14C64"
        pointBorderWidth={2}
        enableArea
        axisLeft={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 20,
        }}
        theme={{
          grid: {
            line: {
              stroke: '#eee',
            }
          }
        }}
      />
    </>
  );
}
  
interface MainExpensesPieChartProps {
  categories: [ExpensesCategories, number][];
}
  
function MainExpensesPieChart(props: MainExpensesPieChartProps) { 
  const currencyCode = useAtomValue(userCurrencyAtom);
  
  const data = useMemo(() => {
    return props.categories.map(([method, amount]) => ({
      id: method,
      value: amount,
    }));
  }, [props.categories])

  return (
    <>
      <svg style={{height: 0}}>
        <defs>
          {PIE_CHART_COLORS.map((color, i) => (
            <linearGradient key={i} id={`pie${i}`} gradientUnits="userSpaceOnUse">
              <stop offset="0%" stopColor={color.from}/>
              <stop offset="100%" stopColor={color.to}/>
            </linearGradient>
          ))}
        </defs>
      </svg>

      <ResponsivePie
        data={data}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        innerRadius={0.8}
        colors={PIE_CHART_COLORS.map((_, i) => `url(#pie${i})`)}
        margin={{
          bottom: 40,
        }}
      />
    </>
  );
}
  
interface PieChartLegendItemProps {
    title: string;
    value: number;
    colorIndex: number;
}
  
function PieChartLegendItem(props: PieChartLegendItemProps) {
    const color = PIE_CHART_COLORS[props.colorIndex];
    const currencyCode = useAtomValue(userCurrencyAtom);
  
    return (
      <Card sx={{p: 0.5, px: 1, borderRadius: '8px', height: '2rem'}}>
        <Stack direction="row" alignItems="center">
          <Box
            sx={{
              width: 16,
              height: 16,
              background: `linear-gradient(90deg, ${color.from} 0%, ${color.to} 100%)`,
              borderRadius: '50%',
              mr: 1,
            }}
          />
  
          <Typography sx={{flex: 1, mr: 2}}>
            {props.title}
          </Typography>
  
          <Typography>
            {formatMoney(props.value, currencyCode)}
          </Typography>
        </Stack>
      </Card>
    );
}
  
  
interface PieChartLegendProps {
  payments: [ExpensesCategories, number][];
}
  
function PieChartLegend(props: PieChartLegendProps) {
  return (
    <Stack spacing={1}>
      {props.payments.map(([method, amount], i) => (
        <PieChartLegendItem
          key={method}
          title={method}
          value={amount}
          colorIndex={i % PIE_CHART_COLORS.length}
        />
      ))}
    </Stack>
  );
}

type SizePossibilities = 'week' | 'month';

function MainExpensesCard(props: MainSalesCardProps) {
  const currencyCode = useAtomValue(userCurrencyAtom);
  const [windowSize, setWindowSize] = useState<SizePossibilities>('month');
  const [totalExpensesThisMonth, setTotalExpensesThisMonth] = useState(0);
  const [changeFromLastMonth, setChangeFromLastMonth] = useState(0);
  const [averageMonthlySpent, setAverageMonthlySpent] = useState(0);
  const [shownExpensesGraph, setShownExpensesGraph] = useState<[string, number][]>(() => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentMonthIndex = new Date().getMonth();
    let startMonthIndex = currentMonthIndex - 6;
    
    const selectedMonths = [];
    for (let i = 0; i <= 6; i++) {
      // If startMonthIndex is negative, it wraps around to the end of the months array
      let index = (startMonthIndex + i + 12) % 12;
      selectedMonths.push([months[index], 0] as [string, number]);
    }

    return selectedMonths;
  });

  const data = useListAllExpensesQuery().data;

  useEffect(() => {
    if (data) {
      const now = new Date();
      const total = data.expenses
        .filter(expense => {
          const expenseDate = new Date(expense.date);
          return expenseDate.getMonth() === now.getMonth() && expenseDate.getFullYear() === now.getFullYear();
        })
        .reduce((acc, expense) => {
          return acc + expense.amount;
      }, 0);
      setTotalExpensesThisMonth(total);

      const months = new Set(data.expenses.map(expense => new Date(expense.date).getMonth()));
      const totalExpensesThisYear = data.expenses
        .filter(expense => {
          const expenseDate = new Date(expense.date);
          return expenseDate.getFullYear() === now.getFullYear();
        })
        .reduce((acc, expense) => {
          return acc + expense.amount;
      }, 0);
      if (totalExpensesThisYear && months.size)
        setAverageMonthlySpent(totalExpensesThisYear / months.size);
      else
        setAverageMonthlySpent(0);

      const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const lastMonthTotal = data.expenses
        .filter(expense => {
          const expenseDate = new Date(expense.date);
          return expenseDate.getMonth() === lastMonth.getMonth() && expenseDate.getFullYear() === lastMonth.getFullYear();
        })
        .reduce((acc, expense) => {
          return acc + expense.amount;
      }, 0);

      if (lastMonthTotal === 0) {
        setChangeFromLastMonth(0);
      } else {
        setChangeFromLastMonth(((total - lastMonthTotal) / lastMonthTotal) * 100);
      }

      switch (windowSize) {
        case 'week':
          const daysByIndex = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          const currentDate = new Date();
          currentDate.setHours(23, 59, 59, 999); // Ensure today's expenses are included
        
          // Calculate the first day to be 6 days before today
          const firstDayOfWeek = new Date(currentDate);
          firstDayOfWeek.setDate(currentDate.getDate() - 6);
          firstDayOfWeek.setHours(0, 0, 0, 0); // Start of the day
        
          // Create an array for the past 6 days + today in the correct order
          let lastSevenDays = [];
          for (let i = 6; i >= 0; i--) {
            const tempDate = new Date(currentDate);
            tempDate.setDate(currentDate.getDate() - i);
            lastSevenDays.push(daysByIndex[tempDate.getDay()]);
          }
        
          const expensesByDay = data.expenses.reduce((acc, expense) => {
            const expenseDate = new Date(expense.date);
            if (expenseDate >= firstDayOfWeek && expenseDate <= currentDate) {
              const day = daysByIndex[expenseDate.getDay()];
              acc[day] = (acc[day] || 0) + expense.amount;
            }
            return acc;
          }, {} as Record<string, number>);
        
          // Map the expenses to the last seven days
          setShownExpensesGraph(lastSevenDays.map(day => [day, expensesByDay[day] || 0]));
          break;
        case 'month':
          const currentMonthIndex = new Date().getMonth();
          const monthsByIndex = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          
          // Calculate the start index for 6 months before the current month
          let startIndex = currentMonthIndex - 6;
          if (startIndex < 0) {
            startIndex += 12; // Wrap around to the previous year if needed
          }

          // Create an array for the selected months
          let selectedMonths = [];
          for (let i = 0; i < 7; i++) { // Only include 7 months: the current month and the 6 months before
            const index = (startIndex + i) % 12; // Correctly wrap around the year
            selectedMonths.push(monthsByIndex[index]);
          }
          
          const expensesByMonth = data.expenses.reduce((acc, expense) => {
            const expenseDate = new Date(expense.date);
            const month = monthsByIndex[expenseDate.getMonth()];
            if (selectedMonths.includes(month)) {
              acc[month] = (acc[month] || 0) + expense.amount;
            }
            return acc;
          }, {} as Record<string, number>);

          setShownExpensesGraph(selectedMonths.map(month => [month, expensesByMonth[month] || 0]));
          break;
        default:
      }
    }
  }, [data, windowSize]);

  return (
    <Card>
      <Grid container spacing={3}>
        <Grid item xs={6} sx={{p: 2}}>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontFamily="Helvetica Neue" variant="h3" sx={{ml: 3, mt: 2}}>
              Expenses
            </Typography>

            <Select
              variant="standard"
              value={windowSize}
              onChange={(e) => setWindowSize(e.target.value as ExpensesWindowSize)}
              startAdornment={
                <InputAdornment position="start" disablePointerEvents sx={{pointerEvents: 'none'}}>
                  <Typography variant="body2" sx={{pointerEvents: 'none'}}>Show:</Typography>
                </InputAdornment>
              }
              sx={{mt: 2}}
            >
              <MenuItem value="week">Daily</MenuItem>
              <MenuItem value="month">Monthly</MenuItem>
            </Select>
          </Stack>
        </Grid>
        <Typography fontFamily="Helvetica Neue" variant="h3" sx={{ml: 3, mt: 5}}>
          Stats
        </Typography>
      </Grid>

      <Divider sx={{borderColor: '#edf6ff', mt: -1, mb: 1}}/>

      <CardContent>
        {data && (
          <Grid container spacing={3}>
            <Grid item xs={props.hideStats ? 12 : 6}>
              <Stack direction="row" sx={{flex: 1}} spacing={7}>
                <Stack spacing={2}>
                  <ExpensesCardStat title="Total expenses this month" value={formatMoney(totalExpensesThisMonth, currencyCode)} color="blue"/>
                  <Divider sx={{borderColor: '#f0f0f0'}}/>
                  <ExpensesCardStat 
                    title="Change from last month"
                    value={changeFromLastMonth >= 0 ? '+' + changeFromLastMonth.toFixed(2) + '%' : changeFromLastMonth.toFixed(2) + '%'}
                    color={changeFromLastMonth >= 0 ? "green" : "red"}
                  />
                  <Divider sx={{borderColor: '#f0f0f0'}}/>
                  <ExpensesCardStat title="Average monthly spent" value={formatMoney(averageMonthlySpent, currencyCode)} color="black"/>
                </Stack>

                <Box sx={{flex: 1, position: 'relative'}}>
                  <Box sx={{position: 'absolute', inset: 0}}>
                    <MainExpensesChart windowSize={windowSize} data={shownExpensesGraph}/>
                  </Box>
                </Box>
              </Stack>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{borderColor: '#dfeeff', mr: '-1px', mb: -3}}/>

            <Grid item xs={6}>
              <StatsCardBody expenses={data.expenses}/>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}
  
  
type StatsCardProps = {
  expenses: Expense[]
}

function StatsCardBody({expenses}: StatsCardProps) {
  const payment_histogram = Object.entries(
    expenses.reduce((acc: Record<ExpensesCategories, number>, expense) => {
      const { category, amount } = expense;
      if (!category) return acc;
  
      const categoryLabel = EXPENSES_CATEGORIES.find((cat) => cat.value === category)?.label as ExpensesCategories;
      acc[categoryLabel] = (acc[categoryLabel] || 0) + parseFloat(amount);
      return acc;
    }, {} as Record<ExpensesCategories, number>)
  )
  .sort((a, b) => b[1] - a[1]) // Sort by amount in descending order
  .reduce((acc, [category, amount], index) => {
    if (index < 4) {
      // Keep the top 4 categories as they are
      acc.top.push([category, amount] as [ExpensesCategories, number]);
    } else {
      // Accumulate amounts for "Other"
      acc.other += amount;
    }
    return acc;
  }, { top: [] as [ExpensesCategories, number][], other: 0 });

  const adjustedHistogram = payment_histogram
  .top.concat([["Other", payment_histogram.top.length === 4 ? payment_histogram.other : 0] as unknown as [ExpensesCategories, number]]);

  return (
    <Grid container sx={{flex: 1}} alignItems="center">
      <Grid item xs>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Box sx={{height: '20rem', width: '20rem', position: 'relative'}}>
            <Box sx={{position: 'absolute', inset: 0}}>
              {expenses && <MainExpensesPieChart categories={adjustedHistogram}/>}
            </Box>
          </Box>
        </Stack>
      </Grid>

      {expenses && (
        <Grid item xs="auto">
          <Stack sx={{flex: 1, ml: '2rem', width: '24rem'}} justifyContent="center">
            <PieChartLegend payments={adjustedHistogram}/>
          </Stack>
        </Grid>
      )}
    </Grid>
  )
}
  
  
export default function ExpensesAllSubpage() {  
    return (
      <MynkTab title="All" action={<ExpensesNewButton />}>
        <Grid container spacing={3} sx={{marginTop: 3}}>
          <Grid item xs={12}>
            <MainExpensesCard/>
          </Grid>
  
          <Grid item xs={12}>

          </Grid>
  
          <Grid item xs={12}>
            <ExpensesInfoTable/>
          </Grid>
        </Grid>
      </MynkTab>
    );
}
  