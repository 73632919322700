import {createContext, useContext} from "react";
import {FormikProps} from "formik";
import {MynkFormControlProps} from "./controlTypes";


export interface MynkFormTrackedControl<T> {
  name: string;
  default: T;
}

export interface MynkFormContextValue {
  trackControl: (control: MynkFormTrackedControl<any>) => void;
}

export const MynkFormContext = createContext<null | MynkFormContextValue>(null);



export interface MynkFormEditableContextValue {
  control: null | MynkFormControlProps;
  setControl: (control: MynkFormControlProps) => void;
  popped: boolean;
}

export const MynkFormEditableContext = createContext<null | MynkFormEditableContextValue>(null);


export function useEditableContext() {
  return useContext(MynkFormEditableContext);
}


export function useIsInEditable() {
  const editableContext = useContext(MynkFormEditableContext);
  return editableContext !== null;
}
