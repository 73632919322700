import {useState} from "react";
import ActionMenuButton from "../../../../components/ActionMenuButton";
import {MynkTab} from "../../../../components/MynkPage";
import {Add} from "@mui/icons-material";
import {Box, Card, Stack,} from "@mui/material";
import ListingsView from "../../../../components/ListingsView";
import {AddListingDialog} from "../../../../dialogs/AddListing";
import {FilterOption, SearchOption, SortOption,} from "../../../../api/types";
import {
  FILTER_REAL_ESTATE_LISTINGS_OPTIONS,
  SORT_REAL_ESTATE_LISTINGS_OPTIONS,
} from "../../../../components/ChipSelect/commonOptions";
import SearchFilterSort from "../../../../components/SearchFilterSort";

enum NewMenuIds {
  NEW_LISTING = "new-listing",
}

const NEW_MENU = [
  {
    id: NewMenuIds.NEW_LISTING,
    label: "New listing",
  },
];

export function ListingsActionNewButton() {
  const [newListingOpen, setNewListingOpen] = useState(false);

  const handleClickItem = (id: string) => {
    switch (id) {
      case NewMenuIds.NEW_LISTING:
        setNewListingOpen(true);
        break;
    }
  };

  return (
    <>
      <AddListingDialog
        open={newListingOpen}
        onClose={() => setNewListingOpen(false)}
      />

      <ActionMenuButton
        startIcon={<Add sx={{width: "1.1rem"}}/>}
        menu={NEW_MENU}
        onClickItem={handleClickItem}
      >
        New
      </ActionMenuButton>
    </>
  );
}

export default function ListingsAllSubpage() {
  const [search, setSearch] = useState<SearchOption | undefined>(undefined);
  const [filter, setFilter] = useState<FilterOption | undefined>(undefined);
  const [sort, setSort] = useState<SortOption | undefined>(undefined);

  return (
    <MynkTab title="All" action={<ListingsActionNewButton/>}>
      <Stack position="relative">
        <Card
          sx={{
            position: "sticky",
            top: 75,
            zIndex: 1,
            background: "rgba(255, 255, 255, 0.7)",
            border: "0.1071rem white solid",
            backdropFilter: "blur(1.6px)",
            width: "100%",
          }}
        >
          <SearchFilterSort
            searchState={[search, setSearch]}
            filterState={[filter, setFilter]}
            sortState={[sort, setSort]}
            filterOptions={FILTER_REAL_ESTATE_LISTINGS_OPTIONS}
            sortOptions={SORT_REAL_ESTATE_LISTINGS_OPTIONS}
            height={44}
            searchWidth={"100%"}
            spacing={1.5}
            sx={{mr: 1.5, margin: 2.5, marginTop: 3.5, marginBottom: 3.5}}
          />
        </Card>

        <Box sx={{mt: 2}}>
          <ListingsView search={search} filter={filter} sort={sort}/>
        </Box>
      </Stack>
    </MynkTab>
  );
}
