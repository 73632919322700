import { MynkTab } from "../../../../components/MynkPage";
import ViewGeneralLayout from "../../../../components/ViewProjectOrContact/ViewGeneralLayout";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import PriorityChip from "../../../../components/PriorityChip";
import {
  Priority,
  RealEstateClientType,
  RealEstateListing,
  WorkflowType,
} from "../../../../api/types";
import { useListProjectsQuery } from "../../../../api/projects";
import ActionMenuButton, {
  ActionMenuButtonVariant,
} from "../../../../components/ActionMenuButton";
import { Tune } from "@mui/icons-material";
import { useSetClientPriority } from "../../../../api/clients";
import { ListingsTableCard, useCurrentContact } from "../common";
import { AddListingDialog } from "../../../../dialogs/AddListing";
import RealEstateClientTypeReadOnlyChip from "../../../../components/RealEstateClientTypeReadOnlyChip";
import {
  FullDescriptionPopUp,
  TruncatedDescription,
} from "../../../MynkPhotography/ViewProject/common/cards";
import {
  ListingCard,
  ListingViewType,
} from "../../../../components/ListingsView";
import EditRealEstateContactDialog from "../../../../dialogs/EditRealEstateContact";
import { formatMoney, zonedDate } from "../../../../util/common";
import { useAtomValue } from "jotai";
import { userCurrencyAtom } from "../../../../layouts/Main";

function UpperDetailsCard() {
  const client = useCurrentContact();

  const setClientPriority = useSetClientPriority();
  const handleChangePriority = (value: string) => {
    setClientPriority.mutate({
      uuid: client?.uuid ?? "",
      priority: value as Priority,
    });
  };

  return (
    <Box>
      <Typography sx={{ fontFamily: "Helvetica Neue" }} variant="h5">
        Details
      </Typography>

      {client === undefined && (
        <CircularProgress sx={{ display: "table", mx: "auto", mt: 2 }} />
      )}

      {client && (
        <Stack sx={{ px: 1, pt: 1, mt: 1 }} spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Name:</Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {client.full_name}
            </Typography>
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Type:</Typography>
            <RealEstateClientTypeReadOnlyChip value={client.type} width={110} />
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Priority:</Typography>
            <PriorityChip
              value={client.priority}
              onChange={handleChangePriority}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "7.8571rem",
                textAlign: "center",
              }}
            />
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Phone:</Typography>
            <Typography variant="body2">{client.phone}</Typography>
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Email:</Typography>
            <Typography variant="body2">{client.email}</Typography>
          </Stack>
        </Stack>
      )}
    </Box>
  );
}

function LowerDetailsCard() {
  const client = useCurrentContact();

  const [openedViewFullPreferences, setOpenedViewFullPreferences] =
    useState(false);

  const { createdAt } = useMemo(() => {
    if (!client) return {};

    return {
      createdAt: zonedDate(client.created_at),
    };
  }, [client]);

  const currencyCode = useAtomValue(userCurrencyAtom);

  if (!client) {
    return <CircularProgress sx={{ display: "table", mx: "auto", mt: 2 }} />;
  }
  return (
    <>
      {" "}
      <FullDescriptionPopUp
        open={openedViewFullPreferences}
        fullDescription={client.preferences ?? ""}
        onClose={() => setOpenedViewFullPreferences(false)}
      />
      <Stack sx={{ px: 1, pt: 1 }} spacing={2}>
        {client.type == RealEstateClientType.PROSPECT && (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="body2">Budget:</Typography>
              <Typography variant="body2">
                {formatMoney(client.budget, currencyCode)}
              </Typography>
            </Stack>
            <Divider />
          </>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Lead source:</Typography>
          <Typography variant="body2">{client.source}</Typography>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Preferences:</Typography>
          <div onClick={() => setOpenedViewFullPreferences(true)}>
            <Typography
              variant="body2"
              sx={{
                color: "#75B3FF",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              View
            </Typography>
          </div>
        </Stack>

        <TruncatedDescription
          description={client.preferences}
          maxLength={140}
        />

        <Divider />

        {client.type == RealEstateClientType.OWNER && (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="body2">No. properties:</Typography>
              <Typography variant="body2">
                {client.properties_amount ?? 0}
              </Typography>
            </Stack>
            <Divider />
          </>
        )}
        {createdAt && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Contact created:</Typography>
            <Typography variant="body2">
              <b style={{ color: "#404040" }}>{createdAt ? createdAt.toFormat("EEEE") : "Invalid date"}</b>,{" "}
              <span style={{ color: "#404040b2" }}>
                {createdAt && createdAt.toFormat("LLL dd, yyyy")}
              </span>
            </Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
}

interface ListingListViewProps {
  data: RealEstateListing[];
  onClickedNew?: () => void;
}

function ListingListView(props: ListingListViewProps) {
  return (
    <Card>
      <Stack spacing={1}>
        <Typography
          variant="h4"
          fontFamily="Helvetica Neue"
          sx={{ ml: 2.5, mt: 2 }}
        >
          Listings
        </Typography>
        <Stack
          spacing={2}
          sx={{ pt: 1, alignItems: "center", height: "40.8571rem", overflowY: "auto" }}
        >
          {props.data?.map((item, i) => (
            <ListingCard
              listing={item as RealEstateListing}
              viewType={ListingViewType.LONG_CARD}
              sx={{ width: "93%" }}
            />
          ))}
          <Typography
            variant="body2"
            sx={{ color: "#75b3ff", pb: 2, cursor: "pointer" }}
            align="center"
            onClick={props.onClickedNew}
          >
            + New property
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

export default function ViewRealEstateContactGeneralSubpage() {
  const [newListingOpen, setNewListingOpen] = useState(false);

  const contact = useCurrentContact();

  const { data, isPending } = useListProjectsQuery(
    {
      client_uuid: contact?.uuid ?? "",
      page_size: 10,
    },
    Boolean(contact)
  );

  const [editContactOpen, setEditContactOpen] = useState(false);

  return (
    <MynkTab
      title="General"
      action={
        <ActionMenuButton
          startIcon={<Tune sx={{ mr: 1 }} />}
          variant={ActionMenuButtonVariant.SECONDARY}
          sx={{
            mb: -9,
          }}
          onClickItem={() => setEditContactOpen(true)}
        >
          Edit
        </ActionMenuButton>
      }
    >
      <EditRealEstateContactDialog
        open={editContactOpen}
        onClose={() => setEditContactOpen(false)}
        contact={contact ?? undefined}
      />

      <AddListingDialog
        open={newListingOpen}
        onClose={() => setNewListingOpen(false)}
        contactId={contact?.uuid}
      />

      <ViewGeneralLayout
        upperCard={<UpperDetailsCard />}
        lowerCard={<LowerDetailsCard />}
        mainContent={
          contact?.type == RealEstateClientType.OWNER ? (
            <ListingListView
              data={data?.projects as RealEstateListing[]}
              onClickedNew={() => setNewListingOpen(true)}
            />
          ) : contact?.type == RealEstateClientType.PROSPECT ? (
            <ListingsTableCard client_uuid={contact.uuid} />
          ) : (
            <></>
          )
        }
      />
    </MynkTab>
  );
}
