import {Box, Divider, IconButton, Stack, Typography, TextField, InputAdornment} from "@mui/material";
import {useEffect, useState} from "react";
import { Expense } from "../api/types";
import { useEditExpenseMutation } from "../api/expenses";
import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import { format, set } from "date-fns";
import { EXPENSES_CATEGORIES, PAYMENT_METHODS } from "../components/ChipSelect/commonOptions";
import {
  WorkflowType,
} from "../api/types";
import CheckRadioButton from '../assets/icons/check-radio-button.svg'
import UncheckRadioButton from '../assets/icons/uncheck-radio-button.svg'
import { formatMoney } from "../util/common";
import { useAtomValue } from "jotai";
import { userCurrencyAtom } from "../layouts/Main";

export interface AddExpenseDialogProps {
  open: boolean;
  onClose: () => void;
  expense?: Expense;
}

export function EditExpenseDialog({open, onClose, expense}: AddExpenseDialogProps) {
  const [linkClient, setLinkClient] = useState(false)
  const [linkProject, setLinkProject] = useState(false)
  const [description, setDescription] = useState<string>('')
  const [amount, setAmount] = useState<string>('')
  const userCurrency = useAtomValue(userCurrencyAtom);

  const editExpense = useEditExpenseMutation()

  useEffect(() => {
    setLinkClient(!!expense?.client)
    setLinkProject(!!expense?.project)
    setDescription(expense?.description ?? '')
    setAmount(expense?.amount.toString() ?? '')
  }, [expense])

  const handleSubmit = async (values: any) => {
    if (!expense || !expense.uuid) return;

    let formattedDate;
    if (values.date instanceof Date) {
      const date = new Date(values.date.ts);
      formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    } else {
      const date = new Date(values.date);
      formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    }

    const newExpense = {
      uuid: expense.uuid,
      amount: parseFloat(amount),
      description: description,
      date: formattedDate,
      category: values.category,
      payment_method: values.payment_method,
      client: linkClient ? (values.clientId ? values.clientId : expense?.client) : undefined,
      project: linkProject ? (values.projectId ? values.projectId : expense?.project) : undefined,
    }

    editExpense.mutateAsync(newExpense, {
      onSuccess: () => {
        onClose();
      }
    });
  }; 

  return (
    <MynkFormDialog
      open={open}
      onClose={() => onClose()}
      actionDisabled={false}
      onSubmit={handleSubmit}
      loading={editExpense.isPending}
      action='Edit expense'
    >
      <Stack flexDirection={'row'} px={2} height={'fit-content'}>
        <Stack sx={{width: '35rem'}}>
          <h1 style={{fontSize: '2rem', marginBottom: '2.5rem'}}>Edit Expense</h1>

          <Stack>
            <Stack direction='row' justifyContent={'space-between'} mb={2}>
              <Typography>Description</Typography>
              <Typography>Amount</Typography>
            </Stack>
            <Stack direction='row'>
              <TextField
                fullWidth
                variant="standard"
                placeholder='Type description here..'
                defaultValue={description}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: '3.6rem',
                    mt: '-1rem',
                  },
                }}
                onChange={(e) => {
                  setDescription(e.target.value)
                }}
              />

              <TextField
                fullWidth
                variant="standard"
                placeholder='0.00'
                defaultValue={amount}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: '3.6rem',
                    mt: '-1rem',
                    mr: '-3rem',
                    width: '7rem',
                    alignSelf: 'flex-end',
                    justifySelf: 'flex-end',
                  },
                  startAdornment:
                    <InputAdornment position="start">
                      {formatMoney(undefined, userCurrency)}
                    </InputAdornment>
                }}
                onChange={(e) => {
                  setAmount(e.target.value)
                }}
              />
            </Stack>
          </Stack>

          {/* <MynkFormEditable>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="amount"
              label="Amount"
              placeholder={"$ 00.00"}
              default={expense ? expense.amount : ""}
              isNum
            />
          </MynkFormEditable>

          <Box mt={1}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name="description"
                label="Description"
                placeholder={"Enter expense's description"}
                default={expense ? expense.description : ""}
              />
            </MynkFormEditable>
          </Box> */}

          <Divider sx={{my: 2}}/>

          <MynkFormControl
            kind={MynkFormControlKind.DATE_SELECT}
            name="date"
            label="Date"
            default={expense ? format(new Date(expense.date), 'MM-dd-yyyy') : format(new Date(), 'MM-dd-yyyy')}
          />

          <Box mt={1}>
            <MynkFormControl
              kind={MynkFormControlKind.CHIP_SELECT}
              name="category"
              label="Category"
              default={expense?.category ?? "none"}
              options={EXPENSES_CATEGORIES}
            />
          </Box>

          <Box mt={1}>
            <MynkFormControl
              kind={MynkFormControlKind.CHIP_SELECT}
              name="payment_method"
              label="Payment method"
              default={expense?.payment_method ?? "none"}
              options={PAYMENT_METHODS}
            />
          </Box>

          <Stack flexDirection={'row'} sx={{marginTop: '1rem'}}>
            <IconButton
              sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
              onClick={() => {
                if (linkProject)
                  setLinkProject(false)

                setLinkClient(prevState => !prevState)
              }}
            >
              <Box
                src={linkClient ? CheckRadioButton : UncheckRadioButton}
                component='img'
                sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
              />
            </IconButton>
            <Typography style={{fontSize: '1.2rem', marginLeft: '0.5rem'}}>Link client (Optional)</Typography>
          </Stack>
          
          {linkClient && (
              <Box sx={{marginTop: '1rem', marginLeft: '1rem'}}>
                <MynkFormControl
                  kind={MynkFormControlKind.CONTACT_SEARCH}
                  name="clientId"
                  label="Link client"
                  placeholder="Enter client"
                  default={expense?.client ?? ""}
                  workflowType={WorkflowType.PHOTOGRAPHY}
                />
              </Box>
          )}
          
          <Stack flexDirection={'row'} sx={{marginTop: '1rem'}}>
            <IconButton
              sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
              onClick={() => {
                if (linkClient)
                  setLinkClient(false)
                
                setLinkProject(prevState => !prevState)
              }}
            >
              <Box
                src={linkProject ? CheckRadioButton : UncheckRadioButton}
                component='img'
                sx={{width: '1rem', height: '1rem', marginTop: '0.1rem', cursor: 'pointer'}}
              />
            </IconButton>
            <Typography style={{fontSize: '1.2rem', marginLeft: '0.5rem'}}>Link project (Optional)</Typography>
          </Stack>

          {linkProject && (
            <Box sx={{marginTop: '1rem', marginLeft: '1rem'}}>
              <MynkFormControl
                kind={MynkFormControlKind.PROJECT_SEARCH}
                name="projectId"
                label="Link project"
                placeholder="Enter project"
                default={expense?.project ?? ""}
                workflowType={WorkflowType.PHOTOGRAPHY}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    </MynkFormDialog>
  )
}