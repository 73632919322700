import { Box, MenuItem, Select, Stack, SxProps } from "@mui/material";
import chipSelectArrowIcon from "../../assets/icons/chipselect-arrow-icon.svg";
import { useState } from "react";
import {MENU_TAIL_SX} from "../../util/styles";

export interface ChipSelectOption {
  value: string;
  label: string;
  icon?: string;
  shortenLabel?: string;
  color?: string;
}

const CustomArrowIcon = (icon: string) => (
  <img
    src={icon}
    alt="Arrow"
    style={{
      marginRight: "1rem",
      cursor: "pointer",
      width: "0.7rem",
      height: "0.7rem",
    }}
  />
);

export interface ChipSelectProps {
  options: ChipSelectOption[];
  value: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  arrowIcon?: string;
  sideIcon?: string;
  bgcolor?: string;
  height?: number;
  sx?: SxProps;
  ignoreCentering?: boolean;
}

export function ChipSelect(props: ChipSelectProps) {
  const [openMenu, setOpenMenu] = useState(false);
  const selectedOption = props.options.find(
    (option) => option.value === props.value
  );

  return (
    <Stack
      onClick={() => setOpenMenu(!openMenu)}
      direction="row"
      sx={{
        borderRadius: "15rem",
        cursor: "pointer",
        alignItems: "center",
        bgcolor: props.bgcolor,
        height: props.height ? `${props.height / 14}rem` : "2rem",
      }}
    >
      {props.sideIcon ? (
        <Box
          component="img"
          src={props.sideIcon}
          sx={{
            width: props.height ? `${props.height / 14 / 1.22}rem` : "2rem",
            height: props.height ? `${props.height / 14 / 1.22}rem` : "2rem",
            ml: 0.67,
            cursor: "pointer",
            mt: 0.2,
          }}
        />
      ) : null}
      <Select
        variant="standard"
        value={props.value === "" ? "default" : props.value}
        onChange={(e) => props.onChange?.(e.target.value)}
        IconComponent={() => CustomArrowIcon(props.arrowIcon || chipSelectArrowIcon)}
        renderValue={() => (
          <div
            style={{
              fontSize: props.height ? `${props.height / 2.5 / 14}rem` : "1.17rem",
              display: "flex",
              marginLeft: "0.1rem",
            }}
          >
            {selectedOption
              ? selectedOption.shortenLabel ?? selectedOption.label
              : props.placeholder}
          </div>
        )}
        open={openMenu}
        sx={{
          ...props.sx,
          cursor: "pointer",
          pl: props.sideIcon ? 0 : 1.7,
          pr: props.sideIcon ? 0 : 0.2,
          borderRadius: 50,
          "& .MuiSelect-select": {
            backgroundColor: "transparent !important", // Prevent grey background
          },
          "&:focus": {
            backgroundColor: "transparent", // Clear background on focus
          },
        }}
        MenuProps={{
          sx: {
            ml: -7,
            ...MENU_TAIL_SX,
          },
        }}
      >
        {props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.icon ? (
              <Box
                component="img"
                src={option.icon}
                sx={{
                  width: "2.1429rem",
                  height: "2.1429rem",
                  padding: 0,
                  mt: -1,
                  mb: -1,
                  ml: -1,
                  mr: 0.3,
                }}
              />
            ) : null}
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}
