import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  useDeleteAccountMutation,
} from "../../api/auth";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AxiosError } from "axios";
import { DeleteAccountParams, ResetPasswordParams } from "../../api/types";
import ConfirmActionDialog from "../../components/ConfirmActionDialog";
import { useSetAtom } from "jotai";
import { loginStateAtom } from "../../state/auth";

export default function DeleteAccountPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user_id = queryParams.get("user_id");
  const email = queryParams.get("email");
  const code = queryParams.get("code");

  const updateLoginState = useSetAtom(loginStateAtom);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);

  const deleteAccount = useDeleteAccountMutation({
    onError: (error: unknown) => {
      setDeleteAccountOpen(false);
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, DeleteAccountParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        setErrorMsg(detail ?? "");
      }
    },
    onSuccess: () => {
      setDeleteAccountOpen(false);
      updateLoginState(null)
      navigate("/login");
    },
  });
  const navigate = useNavigate();

  const deleteAccountAction = async () => {
    await deleteAccount.mutateAsync({
    user_id: user_id ?? "",
      email: email ?? "",
      code: code ?? "",
      password: password,
    });
  };

  return (
    <Stack
      sx={{
        width: "37rem",
        height: "41rem",
        pt: 3,
        pb: 1,
        justifyContent: "center",
      }}
    >
      <ConfirmActionDialog
        whatAction="delete"
        prefix="your"
        what="account"
        open={deleteAccountOpen}
        onClose={() => setDeleteAccountOpen(false)}
        onConfirm={deleteAccountAction}
        loading={deleteAccount.isPending}
      >
        Delete
      </ConfirmActionDialog>{" "}
      <Typography fontFamily="Helvetica Neue" variant="h2">
        Delete your account
      </Typography>
      {errorMsg && <Typography sx={{ color: "red" }}>{errorMsg}</Typography>}
      <Card
        sx={{
          mt: "2rem",
          px: "1rem",
          pt: "0.7rem",
          pb: "1.5rem",
          bgcolor: "white",
          boxShadow: "0px 0.3571rem 1.4286rem #cbced1",
        }}
      >
        <Typography>
          <b>⚠️Warning!</b>
          <br />
          Deleting your account is an irreversible action, and if you want to
          come back and register again we will not be able to restore your data
          under any circumstances.
          <br />
          Only delete your account if you are sure you want to leave.
        </Typography>
      </Card>
      <Typography sx={{ mt: 5, mb: 2 }}>
        Type your password to confirm
      </Typography>
      <TextField
        fullWidth
        type={showPassword ? "text" : "password"}
        disabled={deleteAccount.isPending}
        onChange={(event) => {
          setPassword(event.target.value);
        }}
        sx={{
          "& *": {
            borderColor: "#c7a5ff",
            borderRadius: "1.7143rem",
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                <Box
                  width={"1.4286rem"}
                  height={"1.4286rem"}
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {showPassword ? (
                    <VisibilityOffIcon sx={{ color: "#00000055" }} />
                  ) : (
                    <VisibilityIcon sx={{ color: "#00000055" }} />
                  )}
                </Box>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <br />
      <Button
        fullWidth
        variant="contained"
        sx={{
          my: 2,
          textTransform: "capitalize",
          background: "linear-gradient(90deg, #6ab4ff 0%, #c2a6ff 100%)",
          fontFamily: "Helvetica Neue",
          fontSize: "1.5rem",
          borderRadius: "1.7143rem",
          py: 1.5,
          boxShadow: "0px 7px 8px #999",
        }}
        disabled={deleteAccount.isPending}
        onClick={() => setDeleteAccountOpen(true)}
      >
        {deleteAccount.isPending ? (
          <CircularProgress />
        ) : (
          "Delete account permanently"
        )}
      </Button>
    </Stack>
  );
}
