import {Box, Stack} from "@mui/material";
import React, {ReactNode, useContext, useMemo} from "react";
import {MynkPageContext} from "./context";
import {createPortal} from "react-dom";


export interface MynkTabInfo {
  title: string;
  path: string;
}

export interface MynkTabProps {
  title: string;
  action?: ReactNode;
  nextToAction?: ReactNode;
  children: ReactNode;
}

export function MynkTab(props: MynkTabProps) {
  const value = useContext(MynkPageContext);
  
  return (
    <>
      <Stack direction="row">
        {props.action && value?.actionContainer 
          ? createPortal(
              <Stack direction="row" alignItems="center" sx={{ mb: -10.7 }}>
                {props.nextToAction || <></>}
                {props.action || <></>}
              </Stack>, 
              value.actionContainer
            )
          : null}
      </Stack>
      <Box>
        {props.children}
      </Box>
    </>
  );
}
