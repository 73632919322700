import { MynkTab } from "../../../../components/MynkPage";
import ActionMenuButton, {
  ActionMenuEntry,
} from "../../../../components/ActionMenuButton";
import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from "react";
import { Add } from "@mui/icons-material";
import { AddPhotographyContactDialog } from "../../../../dialogs/AddPhotographyContact";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import {
  StatColumn,
  StatColumnItemInfo,
} from "../../../../components/StatColumn";
import ClientSourceRows, {
  ClientSourceRowInfo,
} from "../../../../components/ClientSourceRows";
import {
  ClientSource,
  DeleteClientParams,
  ProjectStage,
  RealEstateClientInfo,
  WorkflowType,
} from "../../../../api/types";
import {
  useDeleteClientMutation,
  useGetClientStatsQuery,
  useGetStageStatsQuery,
} from "../../../../api/clients";
import { AddLeadDialog } from "../../../../dialogs/AddLead";
import { openedDeleteAtom, openedEditAtom } from "../common/ContactsTableCard";
import ClientPipeline, {
  ChartMode,
  LISTINGS_STAGES_LABELS,
  PROJECTS_STAGES_LABELS,
} from "../../../../components/ClientPipeline";

import stackChartSwitchOn from "../../../../assets/icons/stack-chart-switch-on.svg";
import stackChartSwitchOff from "../../../../assets/icons/stack-chart-switch-off.svg";
import ringChartSwitchOn from "../../../../assets/icons/ring-chart-switch-on.svg";
import ringChartSwitchOff from "../../../../assets/icons/ring-chart-switch-off.svg";
import verticalLineSeperatesGray from "../../../../assets/icons/vertical-line-seperates-icon-gray.svg";
import { useAtom } from "jotai";
import ConfirmActionDialog from "../../../../components/ConfirmActionDialog";
import { useAtomValue } from "jotai";
import { currentWorkflowAtom } from "../../../../components/MainAppBar/UserControlPopup";
import { AddRealEstateContactDialog } from "../../../../dialogs/AddRealEstateContact";
import {
  ContactsTableCard,
  EditContactType,
  selectedClientAtom,
} from "../common/ContactsTableCard";
import EditContactDialog from "../../../../dialogs/EditContact";
import EditRealEstateContactDialog from "../../../../dialogs/EditRealEstateContact";
import { AddPhotographyProjectDialog } from "../../../../dialogs/AddPhotographyProject";
import { Skeleton } from "@mui/material";

enum NewMenuIds {
  NEW_PHOTOGRAPHY_PROJECT = "new-photography-project",
  NEW_PHOTOGRAPHY_CONTACT = "new-photography-client",
  NEW_REAL_ESTATE_CONTACT = "new-real-estate-client",
}

interface ClientsAllSubpageNewButtonProps {
  workflowType?: WorkflowType;
}

export function ClientsAllSubpageNewButton(
  props: ClientsAllSubpageNewButtonProps
) {
  const [addPhotographyProjectOpen, setAddPhotographyProjectOpen] =
    useState(false);
  const [addPhotographyContactOpen, setAddPhotographyContactOpen] =
    useState(false);
  const [addRealEstateContactOpen, setAddRealEstateContactOpen] =
    useState(false);

  const handleClickItem = (id: string) => {
    switch (id) {
      case NewMenuIds.NEW_PHOTOGRAPHY_PROJECT:
        setAddPhotographyProjectOpen(true);
        break;

      case NewMenuIds.NEW_PHOTOGRAPHY_CONTACT:
        setAddPhotographyContactOpen(true);
        break;

      case NewMenuIds.NEW_REAL_ESTATE_CONTACT:
        setAddRealEstateContactOpen(true);
        break;
    }
  };

  let NEW_MENU: ActionMenuEntry[];

  if (props.workflowType === WorkflowType.PHOTOGRAPHY) {
    NEW_MENU = [
      {
        id: NewMenuIds.NEW_PHOTOGRAPHY_CONTACT,
        label: "New contact",
      },
      {
        id: NewMenuIds.NEW_PHOTOGRAPHY_PROJECT,
        label: "New project",
      },
    ];
  } else if (props.workflowType === WorkflowType.REAL_ESTATE) {
    NEW_MENU = [
      {
        id: NewMenuIds.NEW_REAL_ESTATE_CONTACT,
        label: "New contact",
      },
    ];
  } else {
    NEW_MENU = [];
  }

  return (
    <>
      <AddPhotographyContactDialog
        open={addPhotographyContactOpen}
        onClose={() => setAddPhotographyContactOpen(false)}
      />

      <AddRealEstateContactDialog
        open={addRealEstateContactOpen}
        onClose={() => setAddRealEstateContactOpen(false)}
      />

      <AddPhotographyProjectDialog
        open={addPhotographyProjectOpen}
        onClose={() => setAddPhotographyProjectOpen(false)}
      />

      <ActionMenuButton
        startIcon={<Add sx={{ width: "1.1rem" }} />}
        menu={NEW_MENU}
        onClickItem={handleClickItem}
      >
        New
      </ActionMenuButton>
    </>
  );
}

interface chartModeSwitchProps {
  chartMode: ChartMode;
  setChartMode: (newChartMode: ChartMode) => void;
  sx?: SxProps;
}

export function ChartModeSwitch(props: chartModeSwitchProps) {
  return (
    <Stack direction="row" sx={{ ...props.sx }}>
      <Box
        component="img"
        src={
          props.chartMode == ChartMode.STACK
            ? stackChartSwitchOn
            : stackChartSwitchOff
        }
        sx={{ cursor: "pointer", height: "1.6429rem" }}
        onClick={() => props.setChartMode(ChartMode.STACK)}
      />
      <Box
        component="img"
        src={verticalLineSeperatesGray}
        sx={{ height: "1.5714rem", mr: 0.4, ml: 0.4 }}
      />
      <Box
        component="img"
        src={
          props.chartMode == ChartMode.RING
            ? ringChartSwitchOn
            : ringChartSwitchOff
        }
        sx={{ cursor: "pointer", height: "1.6429rem" }}
        onClick={() => props.setChartMode(ChartMode.RING)}
      />
    </Stack>
  );
}

interface OverviewCardProps {
  workflowType: WorkflowType;
}

export function OverviewCard(props: OverviewCardProps) {
  const [chartMode, setChartMode] = useState(ChartMode.STACK);

  const { data } = useGetClientStatsQuery();
  const { data: stageData } = useGetStageStatsQuery();

  const [closedwonAmount, setClosedwonAmount] = useState(0);
  const [numOfContact, setNumOfContacts] = useState(0);

  useEffect(() => {
    if (stageData) {
      const closedwonIndex = stageData?.stageStats.stages.findIndex(
        (stage) => stage === ProjectStage._CLOSED_WON
      );

      if (closedwonIndex !== undefined && closedwonIndex !== -1) {
        setClosedwonAmount(stageData.stageStats.values[closedwonIndex]);
      } else {
        setClosedwonAmount(0);
      }
    }
  }, [stageData]);

  useEffect(() => {
    if (data) {
      setNumOfContacts(data?.stats?.existing_clients ?? 0);
    }
  }, [data]);

  useEffect(() => {
    console.log("numOfContact:", numOfContact);
    console.log("closedwonAmount:", closedwonAmount);

    // Your calculation logic here
  }, [numOfContact, closedwonAmount]);

  const stats = useMemo(() => {
    switch (props.workflowType) {
      case WorkflowType.PHOTOGRAPHY:
        return [
          {
            title: "New contacts (past 30 days)",
            value: data?.stats?.new_clients,
          },
          {
            title: "Contact to contract rate",
            value:
              numOfContact === 0
                ? "0%" // If numOfContact is 0, set rate to 0%
                : `${((closedwonAmount / numOfContact) * 100).toFixed(1)}%`, // Calculate and format the rate
          },
          {
            title: "Returning clients",
            value: data?.stats?.returning_clients,
          },
        ] as StatColumnItemInfo[];

      case WorkflowType.REAL_ESTATE:
        return [
          {
            title: "Number of prospects",
            value: data?.stats?.prospects,
          },
          {
            title: "Number of owners",
            value: data?.stats?.owners,
          },
          {
            title: "Number of listings",
            value: data?.stats?.listings,
          },
        ] as StatColumnItemInfo[];

      default:
        return [] as StatColumnItemInfo[];
    }
  }, [data, numOfContact, closedwonAmount]);

  const sourceRows = useMemo(() => {
    if (!data?.stats?.sources) return [];
  
    // Sort sources by count in descending order
    let sortedSources = [...data.stats.sources].sort((a, b) => b.count - a.count);
  
    // Get the "Other" source and its count
    const otherSourceData = data.stats.sources.find((s) => s.source === ClientSource.OTHER);
    let otherCount = otherSourceData?.count || 0;
  
    // Filter out the "Other" source from sortedSources (we will add it later)
    sortedSources = sortedSources.filter((s) => s.source !== ClientSource.OTHER);
  
    // Add default sources if there are fewer than 5
    const defaultSources = [ClientSource.FACEBOOK, ClientSource.INSTAGRAM, ClientSource.TIKTOK];
    defaultSources.forEach((defaultSource) => {
      if (!sortedSources.find((s) => s.source === defaultSource)) {
        sortedSources.push({ source: defaultSource, count: 0 });
      }
    });
  
    // Slice the top 5 sources
    const topSources = sortedSources.slice(0, 5);
  
    // Calculate "Other" count by summing up the remaining sources not in the top 5
    const remainingSources = sortedSources.slice(5);
    remainingSources.forEach((s) => {
      otherCount += s.count;
    });
  
    // Add "Other" at the bottom with the calculated sum
    topSources.push({ source: ClientSource.OTHER, count: otherCount });
  
    // Map the result to the expected structure
    return topSources.map((source) => ({
      source: source.source as ClientSource,
      count: source.count,
    }));
  }, [data]);

  const { data: stageStatsData, isPending: stageStatsLoading } =
    useGetStageStatsQuery();

  return (
    <Card>
      <Grid container spacing={3}>
        <Grid item xs={4.63}>
          <Stack direction="row">
            <Typography variant="h4" sx={{ ml: 3, mt: 1.5 }}>
              Pipeline
            </Typography>
            <Box sx={{ flex: 1 }} />
            <ChartModeSwitch
              chartMode={chartMode}
              setChartMode={setChartMode}
              sx={{ mr: -0.8, mt: 2.5 }}
            />
          </Stack>
        </Grid>

        <Grid item xs={3.32}>
          <Typography variant="h4" sx={{ ml: 2.5, mt: 2 }}>
            Stats
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h4" sx={{ ml: 3, mt: 2 }}>
            Top sources
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ borderColor: "#edf6ff", mt: 1, mb: 1 }} />

      <Grid container spacing={3} height="24.4rem">
        <Grid item xs={4.75}>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%", minHeight: "19.2857rem" }}
          >
            {stageStatsLoading || !stageStatsData ? (
              <Skeleton
                variant="rounded"
                width={"100%"}
                height="100%"
                sx={{
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%)",
                }}
              />
            ) : (
              <>
                <ClientPipeline
                  stats={stageStatsData.stageStats}
                  chartMode={chartMode}
                  stagesLabels={
                    props.workflowType === WorkflowType.PHOTOGRAPHY
                      ? PROJECTS_STAGES_LABELS
                      : props.workflowType === WorkflowType.REAL_ESTATE
                      ? LISTINGS_STAGES_LABELS
                      : []
                  }
                />
              </>
            )}
          </Stack>
        </Grid>

        <Divider
          orientation="vertical"
          flexItem
          sx={{ borderColor: "#dfeeff", mr: "-0.0714rem", mt: 2 }}
        />

        <Grid item xs={3.35}>
          <Box sx={{ py: 1, pb: 2 }}>
            <StatColumn items={stats} />
          </Box>
        </Grid>

        <Divider
          orientation="vertical"
          flexItem
          sx={{ borderColor: "#dfeeff", mr: "-0.0714rem", mt: 2 }}
        />

        <Grid item xs={3.5}>
          <Stack justifyContent="center" sx={{ height: "100%", pr: 3 }}>
            <ClientSourceRows data={sourceRows} />
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}

interface ClientAllSubpageProps {
  sx?: SxProps;
}

function ClientsAllSubpage_(
  props: ClientAllSubpageProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const currentWorkflow = useAtomValue(currentWorkflowAtom);

  const [addContactOpen, setAddContactOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useAtom(selectedClientAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);
  const [editType, setEditType] = useState<EditContactType | null>(null);

  const workflowType = useAtomValue(currentWorkflowAtom)?.type;

  const deleteClient = useDeleteClientMutation();

  const handleDeleteClient = async (uuid: string) => {
    const params = {
      uuid: uuid,
    } as DeleteClientParams;

    await deleteClient.mutateAsync(params);
    setOpenedDelete(false);
  };

  useEffect(() => {
    if (workflowType == WorkflowType.PHOTOGRAPHY) {
      setEditType(EditContactType.REGULAR);
    } else if (workflowType == WorkflowType.REAL_ESTATE) {
      setEditType(EditContactType.REAL_ESTATE);
    }
  }, [openedEdit]);

  return (
    <MynkTab
      title="All"
      action={
        <ClientsAllSubpageNewButton workflowType={currentWorkflow?.type} />
      }
    >
      <ConfirmActionDialog
        open={openedDelete}
        what="client"
        whatAction="delete"
        onClose={() => setOpenedDelete(false)}
        onConfirm={() =>
          selectedClient ? handleDeleteClient(selectedClient.uuid) : undefined
        }
        loading={deleteClient.isPending}
      >
        Delete
      </ConfirmActionDialog>

      <EditContactDialog
        open={editType === EditContactType.REGULAR && openedEdit}
        onClose={() => setOpenedEdit(false)}
        contact={selectedClient}
      />

      <EditRealEstateContactDialog
        open={editType === EditContactType.REAL_ESTATE && openedEdit}
        onClose={() => setOpenedEdit(false)}
        contact={selectedClient as RealEstateClientInfo}
      />
      <AddPhotographyContactDialog
        open={addContactOpen}
        onClose={() => setAddContactOpen(false)}
      />

      <Box sx={{ position: "relative", ...props.sx }} ref={ref}>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            mx: 3,
            pointerEvents: "none",
          }}
        />

        <Stack spacing={3.2}>
          <OverviewCard
            workflowType={currentWorkflow?.type ?? WorkflowType.NONE}
          />
          <ContactsTableCard />
        </Stack>
      </Box>
    </MynkTab>
  );
}

const ClientsAllSubpage = forwardRef(ClientsAllSubpage_);

export default ClientsAllSubpage;
