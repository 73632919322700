import { MynkFormControlCommonProps } from "./types";
import { useFormikContext } from "formik";
import {Box, Chip, Stack, Typography, Autocomplete, InputAdornment, Popper, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
  BaseSingleInputFieldProps,
  DateTimePicker,
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import calendarIcon from "../../../assets/icons/calendar.svg";
import { ExpandMore, Height } from "@mui/icons-material";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {useEditableContext, useIsInEditable } from "../context";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import format from "date-fns/format";
import chipSelectArrowIcon from "../../../assets/icons/chipselect-arrow-icon.svg";
import { BoxWithGradientFrame } from "../../GradientFrame";

export interface DateSelectQuickOption {
  label: string;
  deltaMin: number;
  deltaMax: number;
}

interface DateSelectQuickOptionControlProps {
  option: DateSelectQuickOption;
  selected?: boolean;
  onClick?: () => void;
}

function daysFromToday(delta: number): DateTime {
  const now = DateTime.now();
  return now.plus({ days: delta });
}

function DateSelectQuickOptionControl(
  props: DateSelectQuickOptionControlProps
) {
  return (
    <Chip
      label={props.option.label}
      sx={{
        color: "#75b3ff",
        bgcolor: "#75b3ff19",
        fontWeight: props.selected ? "bold" : "normal",
        width: "6.7rem",
        height: "2.2rem",
        borderRadius: "3rem",
        fontSize: "1rem",
        border: props.selected ? "0.1rem solid #75b3ff" : undefined,
        "&:hover": {
          bgcolor: "#75b3ff55"
        }
      }}
      onClick={props.onClick}
    />
  );
}

interface ChipFieldProps
  extends BaseSingleInputFieldProps<DateTime | null, DateTime, any, any> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  withTime?: boolean;
  onlyTime?: boolean;
  default?: string;
}

export function ChipField(props: ChipFieldProps) {
  const { InputProps: { ref } = {}, value } = props;
  // const { value } = props;

  const date = useMemo(() => (value ? value : null), [value]);
  const isInEditable = useIsInEditable();
  return (
    <Chip
      icon={
        (<img
          src={calendarIcon}
          alt="calendar"
          style={{
            width: "1.1429rem",
            height: "1.1429rem",
            paddingLeft: "0.5714rem",
          }}
        />)
      }
      label={
        <Stack direction="row" alignItems="center">
          <Typography
            variant="body2"
            fontSize="1.05rem"
            color="#75b3ff"
            fontWeight={date ? "bold" : "normal"}
          >
            {isInEditable
              ? props.default ? format(new Date(props.default), "MM/dd/yyyy") : "Select"
              : date
              ? props.withTime
                ? date.toFormat("MM/dd/yyyy HH:mm")
                : date.toFormat("MM/dd/yyyy")
              : props.default ? format(new Date(props.default), "MM/dd/yyyy") : "Select"}
          </Typography>
          <ExpandMore
            sx={{ width: "1.4rem", height: "1.4rem", mr: "-0.4rem" }}
          />
        </Stack>
      }
      sx={{
        border: "solid 0.0714rem #75b3ff",
        bgcolor: "white",
        color: "#75b3ff",
        height: "2.2rem",
        borderRadius: "20rem",
      }}
      onClick={() => props.setOpen?.((x) => !x)}
      ref={ref}
    />
  );
}

export interface MynkFormDateSelectProps
  extends MynkFormControlCommonProps<DateTime> {
  quickOptions?: DateSelectQuickOption[];
  defaultQuickOption?: number;
  withTime?: boolean;
  onlyTime?: boolean;
  onlyDate?: boolean;
}

export function MynkFormDateSelect(props: MynkFormDateSelectProps) {
  const formik = useFormikContext();
  const [open, setOpen] = useState(false);
  const [otherValue, setOtherValue] = useState<DateTime | null>(() => {
    // This code snippset runs only on the first render on otherValue:

    if (props.default && props.default instanceof DateTime) {
      // If "withTime" is true and the user provided a time, use it
      if (props.withTime || props.onlyTime) {
        return props.default;
      } else {
        // Set the time to 12:00 PM if "withTime" is false/null
        return props.default.set({ hour: 12, minute: 0, second: 0 });
      }
    } else {
      return null;
    }
  });

  const [selectedOptionIndex, setSelectedOptionIndex] = useState<null | number>(
    props.defaultQuickOption ?? null
  );
  const handleSelectQuickOption = (
    option: DateSelectQuickOption,
    index: number
  ) => {
    setSelectedOptionIndex(index);
    setOtherValue(null);
  };

  // Triggered when chosen chip changes / when date value changes:
  useEffect(() => {
    if (!formik) return;
    if (otherValue) {
      formik.setFieldValue(props.name, otherValue.toUTC());
    } else if (selectedOptionIndex !== null && props.quickOptions) {
      // Calculate date based on selected quick option
      const selectedOption = props.quickOptions[selectedOptionIndex];
      const date = daysFromToday(selectedOption.deltaMin);
      // Save epoch time in GMT+00:00 timezone
      formik.setFieldValue(props.name, date);
    } else if (props.default) {
      // Save epoch time in GMT+00:00 timezone
      formik.setFieldValue(props.name, props.default);
    }
  }, [selectedOptionIndex, otherValue]);

  // Triggered when the user change his picks in the MUI's DatePicker:
  const handlePickOtherDate = (value: DateTime | null) => {
    // Here the date is in time zoned (e.g: "2024-01-13T12:10:31.000+04:00").

    if (props.withTime || props.onlyTime) {
      setOtherValue(value);
    } else {
      if (value !== null) {
        // Reset to 12:00AM in the zoned time.
        setOtherValue(value.set({ hour: 12, minute: 0, second: 0 }));
      }
    }

    // If date is valid - set those little chips to 'no one choosed':
    if (value !== null) {
      setSelectedOptionIndex(null);
    } else {
      // Else - defaultly select the first chip (mostly 'today'):
      setSelectedOptionIndex(0);
    }
  };

  const handlePickOtherHour = (value: string) => {
  // Assuming value is in "hh:mm a" format, e.g., "12:30 am"
  const regex = /(\d{1,2}):(\d{2})\s*(am|pm)/i;
  const match = value.match(regex);

  if (match) {
    let [_, hourStr, minuteStr, ampm] = match;
    let hour = parseInt(hourStr, 10);
    let minute = parseInt(minuteStr, 10);

    // Convert 12-hour clock to 24-hour clock
    if (ampm.toLowerCase() === 'pm' && hour < 12) {
      hour += 12;
    } else if (ampm.toLowerCase() === 'am' && hour === 12) {
      hour = 0;
    }

    // Use today's date with the specified hour and minute
    const time = DateTime.now().set({ hour, minute, second: 0, millisecond: 0 });
    setOtherValue(time);
  } else {
    console.error('Invalid time format');
  }
};

  const isInEditable = useIsInEditable();

  const editableContext = useEditableContext();
  const isPopped = editableContext?.popped ?? false

  const options = Array.from({ length: 24 * 4 }, (_, i) => {
    const hour = Math.floor(i / 4) % 12 === 0 ? 12 : Math.floor(i / 4) % 12;
    const minutes = (i % 4) * 15;
    const amPm = Math.floor(i / 4) < 12 ? 'am' : 'pm';
    return `${hour}:${minutes.toString().padStart(2, '0')} ${amPm}`;
  });

  useEffect(() => {
    props.setDefault?.(otherValue);
  }, [otherValue]);

  useEffect(() => {
    if (props.default && props.label === 'End') {
      setOtherValue(props.default);
    }
  }, [props.default]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
    >
      <Typography variant="subtitle1">
        {!isInEditable && props.label}
      </Typography>

      {props.quickOptions?.map?.((option, i) => (
        <DateSelectQuickOptionControl
          key={option.label}
          option={option}
          selected={i === selectedOptionIndex}
          onClick={() => handleSelectQuickOption(option, i)}
        />
      ))}
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        {props.withTime ? (
          <DateTimePicker<Date>
            slots={{
              field: ChipField,
            }}
            slotProps={{
              field: {
                setOpen,
                withTime: props.withTime,
                onlyTime: props.onlyTime,
                default: props.default,
              } as any,
              popper: {
                sx: {
                  top: isPopped ? '-2.1rem !important' : undefined,
                }
              } as any,
            }}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            value={otherValue}
            onChange={handlePickOtherDate}
          />
        ) : props.onlyTime ? (
          <Autocomplete
            disablePortal
            id="hour-autocomplete"
            filterOptions={(x) => x}
            PopperComponent={(props) => (
              <Popper {...props} style={{ backgroundColor: "white", borderRadius: "1rem", width: "9.2rem", height: "10rem" }}/>
            )}
            value={otherValue ? new Date(otherValue).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: '2-digit',
              hour12: true
            }).toLowerCase() : ""}
            onChange={(e, value) => {
              if (typeof value === 'string') {
                handlePickOtherHour(value);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                key={props.name}
                variant="standard"
                fullWidth
                sx={{
                  width: '7rem',
                  marginTop: '0.1rem',
                }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box component="img" src={chipSelectArrowIcon} sx={{ width: "0.9rem", height: "0.9rem", mr: "-2rem", marginTop: '0.2rem' }} />
                    </InputAdornment>
                  ),
                  sx: {
                    height: '3.5rem',
                    pr: "1rem !important",
                    color: '#75B3FF',
                  },
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            options={options}
            ListboxProps={{ style: { maxHeight: '11rem', overflow: 'auto', width: '9rem' } }}
          />
        ) : (
          <DatePicker<Date>
            slots={{
              field: ChipField,
            }}
            slotProps={{
              field: {
                setOpen,
                withTime: props.withTime,
                onlyTime: props.onlyTime,
                default: props.default,
              } as any,
            }}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            value={otherValue}
            onChange={handlePickOtherDate}
          />
        )}
      </LocalizationProvider>
    </Stack>
  );
}

// export const ChipField = forwardRef(_ChipField);
