import { Autocomplete, Box, InputAdornment, TextField, Popper } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  MinimalProjectSum,
  WorkflowType,
} from "../api/types";
import chipSelectArrowIcon from "../assets/icons/chipselect-arrow-icon.svg";
import { AddPhotographyProjectDialog } from "../dialogs/AddPhotographyProject";
import { AddListingDialog } from "../dialogs/AddListing";
import { useSearchProjectsQuery } from "../api/projects";
import { BoxWithGradientFrame } from "./GradientFrame";

interface ProjectOption {
  label: string;
  id: string;
  projectSum?: MinimalProjectSum;
}

export interface ProjectSearchProps {
  name: string;
  default?: null | string;
  placeholder?: null | string;
  onSelectProject?: (project?: MinimalProjectSum) => void;
  workflowType?: WorkflowType;
}

export default function ProjectSearch(componentProps: ProjectSearchProps) {
  const [searchQuery, setSearchQuery] = useState("");

  const { data, isPending } = useSearchProjectsQuery({
    query: searchQuery,
    limit: 5,
  });

  const [openAddProject, setOpenAddProject] = useState(false);
  const [value, setValue] = useState<null | ProjectOption>(null);
  const [options, setOptions] = useState<ProjectOption[]>([]);
  const [projectSelected, setProjectSelected] = useState(false);
  const [key, setKey] = useState(0);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && projectSelected) {
      setSearchQuery("");
      setProjectSelected(false);
      setValue(null);
      setKey((prevKey) => prevKey + 1); // increment key to force re-render
    }
  };

  useEffect(() => {
    if (isPending || !data) return;

    const newOptions = data.projects.map((c) => ({
      label: c.name,
      id: c.uuid,
      projectSum:  {
        uuid: c.uuid,
        name: c.name
      }
    }));

    const newProject: ProjectOption = {
      label: `+ Add new ${
        componentProps.workflowType === WorkflowType.PHOTOGRAPHY
          ? "project"
          : componentProps.workflowType === WorkflowType.REAL_ESTATE
          ? "listing"
          : "ERROR WORKFLOW TYPE"
      }`,
      id: "0",
    };
    setOptions([newProject, ...newOptions]);

    if (componentProps.default) {
      const defaultOption = newOptions.filter((x) => x.id === componentProps.default);
      if (defaultOption.length > 0) {
        setValue(defaultOption[0]);
      }
    }
  }, [data, isPending]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleChangeOption = (_: unknown, option: null | ProjectOption) => {
    if (option?.id === "0") {
      setOpenAddProject(true);
    } else {
    componentProps.onSelectProject?.(option?.projectSum);
      setValue(option);
      setProjectSelected(true);
    }
  };

  return (
    <>
      {componentProps.workflowType == WorkflowType.PHOTOGRAPHY ? (
        <AddPhotographyProjectDialog
          open={openAddProject}
          onClose={() => setOpenAddProject(false)}
        />
      ) : (
        <AddListingDialog
          open={openAddProject}
          onClose={() => setOpenAddProject(false)}
        />
      )}
      <BoxWithGradientFrame>
        <Autocomplete
          disablePortal
          id={componentProps.name + "-autocomplete"}
          filterOptions={(x) => x}
          loading={isPending}
          value={value}
          PopperComponent={(props) => (
            <Popper {...props} style={{ backgroundColor: "white", borderRadius: "1rem", width: "100%" }}/>
          )}
          onChange={handleChangeOption}
          renderInput={(params) => (
            <TextField
              {...params}
              key={key}
              variant="standard"
              fullWidth
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={searchQuery}
              placeholder={componentProps.placeholder ?? undefined}
              sx={{
                mt: 1,
                height: "4rem",
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      component="img"
                      src={chipSelectArrowIcon}
                      sx={{ width: "0.9rem", height: "0.9rem", mr: "-2rem" }}
                    />
                  </InputAdornment>
                ),
                sx: {
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  pl: 1.5,
                  "&.MuiAutocomplete-inputRoot": {
                    pr: "2rem !important",
                    color: projectSelected ? "#68B3FF" : undefined,
                  },
                },
              }}
            />
          )}
          renderOption={(props, option) =>
            option.id == "0" ? (
              <li
                {...props}
                style={{ color: "#75B3FF", fontFamily: "Helvetica Neue" }}
              >
              { `+ Add new ${
          componentProps.workflowType === WorkflowType.PHOTOGRAPHY
            ? "project"
            : componentProps.workflowType === WorkflowType.REAL_ESTATE
            ? "listing"
            : "ERROR WORKFLOW TYPE"
        }`}
              </li>
            ) : (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )
          }
          options={options}
        />
      </BoxWithGradientFrame>
    </>
  );
}
