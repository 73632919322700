import React, {ReactNode, useEffect, useMemo} from "react";
import {useAtom, useAtomValue} from "jotai";
import {axiosAuthenticatedAtom, loginStateAtom} from "../state/auth";
import {useNavigate} from "react-router-dom";
import {Box, CircularProgress, Stack} from "@mui/material";
import axios from "axios";
import {useWhoamiQuery} from "../api/auth";
import mynkTextLogo from "../assets/images/mynk-textlogo.svg";
import { currentWorkflowAtom } from "../components/MainAppBar/UserControlPopup";
import { WorkflowType } from "../api/types";
import { PATHS, WorkflowPath, makePath } from "../paths";
import {TrialExpiredDialog} from "../dialogs/TrialExpiredDialog";

export interface WorkflowGuardProps {
    expectedWorkflow: WorkflowType;
  children: ReactNode;
}

export default function WorkflowGuard(props: WorkflowGuardProps) {
  const [ currentWorkflow, setCurrentWorkflow ] = useAtom(currentWorkflowAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentWorkflow) {
      navigate("/login");
    }
    else if (props.expectedWorkflow != currentWorkflow?.type) {
      navigate(makePath(`/${currentWorkflow?.type}` as WorkflowPath, PATHS.home.index));
    }
  }, []);

  const {data: whoamiData, isPending: whoamiPending, isSuccess: whoamiSuccess, isError: whoamiError} = useWhoamiQuery();

  const isTrialExpired = whoamiData?.plan?.status === 'trial_expired';
  const isBlur = whoamiPending || isTrialExpired;

  return (
    <Box
      sx={{
        filter: isBlur ? 'blur(5px)' : '',
        // add blur transition
        transition: 'filter 0.5s',
      }}
    >
      {props.expectedWorkflow == currentWorkflow?.type && props.children}

      {isTrialExpired && (
        <TrialExpiredDialog />
      )}
    </Box>
  );
}
