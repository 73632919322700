import { ListingType } from "../api/types";
import { Box, SxProps, Typography } from "@mui/material";

export interface ListingTypeReadOnlyChipProps {
  value: ListingType;
  sx?: SxProps;
  height?: number;
  width?: number;
}

export default function ListingTypeReadOnlyChip(props: ListingTypeReadOnlyChipProps) {
  
  return (
    <Box
      sx={{
        borderRadius: 50,
        height: props.height ? `${props.height / 14}rem` : "2.1429rem",
        width: props.width ? `${props.width / 14}rem` : "8.9286rem",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        bgcolor:
          props.value === ListingType.FOR_RENT
            ? "#D288F233"
            : props.value == ListingType.FOR_SALE
            ? "#75B3FF33"
            : "#5d6063",
      }}
    >
      <Typography
        sx={{
          fontSize: props.height ? `${props.height / 2 / 14}rem` : "1rem",
          color:
            props.value === ListingType.FOR_RENT
              ? "#D288F2"
              : props.value == ListingType.FOR_SALE
              ? "#75B3FF"
              : "#ecf0f5",
        }}
      >
        {props.value === ListingType.FOR_RENT
          ? "For rent"
          : props.value === ListingType.FOR_SALE
          ? "For sale"
          : ""}
      </Typography>
    </Box>
  );
}
