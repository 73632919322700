import { Stack, Typography, Box } from "@mui/material";
import notFoundLogo from "../../assets/images/404-people-img.svg";
import DialogActionButton from "../../components/DialogActionButton";
import { useNavigate } from "react-router-dom";

export default function NotFound404() {
  const navigate = useNavigate();

  return (
    <Stack alignItems="center" spacing="1rem">
      <Box />
      <Box />
      <Typography
        variant="h1"
        color="white"
        fontSize="6rem"
        sx={{ textShadow: "0rem 0.1rem 0.7rem rgba(0, 0, 0, 0.35)" }}
      >
        Oops! Page not found!
      </Typography>
      <Typography
        fontSize="1.47rem"
        color="white"
        sx={{
          width: "45rem",
          textAlign: "center",
          textShadow: "0rem 0.05rem 0.1rem rgba(0, 0, 0, 0.35)",
        }}
      >
        We are very sorry for the inconvenience. It looks like you're trying to
        access a page that has been deleted or never existed.<br/>
        <p style={{fontSize: "1.25rem", marginBottom: "0.5rem", marginTop: "0rem"}}>If you have any questions, please <a style={{color: "#0076ad", textDecoration: "none"}} href="mailto:support@mynk.ai">contact us</a>.</p>
      </Typography>
      <DialogActionButton onClick={() => navigate("/")}>
        Back to home
      </DialogActionButton>
      <Box />
      <Box />
      <Box />
      <Box
        component="img"
        src={notFoundLogo}
        sx={{ pt: "1rem", width: "45rem" }}
      />
    </Stack>
  );
}
