import { useState, useEffect, useRef } from 'react'
import { Stack, Divider, Box } from '@mui/material'
import { useParams } from 'react-router-dom';
import { useGetPortalQuery, useListMeetingTypesPortalQuery, useAddEventMutation, useSendMeetingConfirmationEmailMutation, useGetMeetingTypeMutation } from '../api/scheduler';
import { useAddPhotographyClientPortalMutation } from '../api/clients';
import { useListGeneralSettingsQuery } from '../api/settings';
import { MeetingType, AddPhotographyClientPortalIn, AddEventParams, SendConfirmationMeetingEmailParams } from '../api/types';
import Description from '../components/SchedulerLink/Description';
import ChooseMeeting from '../components/SchedulerLink/ChooseMeeting';
import DateAndTime from '../components/SchedulerLink/DateAndTime';
import AddDetails from '../components/SchedulerLink/AddDetails';
import Confirm from '../components/SchedulerLink/Confirm';
import { ClientSource, Priority } from '../api/types';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';

function SchedulerLayout() {
    const { meetingType } = useParams<{ meetingType?: string }>();
    const companyName = useParams<{companyName: string}>().companyName
    const [currentPage, setCurrentPage] = useState<1 | 2 | 3 | 4>(1);
    
    const meetingTypeMutation = useGetMeetingTypeMutation();

    useEffect(() => {
        if (meetingTypeMutation.data && meetingTypeMutation.data.meeting_type.isPublic && currentPage === 1 && meetingType) {
            setCurrentPage(2)
        }
    }, [meetingTypeMutation.data, meetingType]);

    useEffect(() => {
        if (meetingType && companyName) {
            meetingTypeMutation.mutateAsync({
                uuid: meetingType,
                company_name: companyName
            })
        }
    }, [companyName, meetingType])

    const [description, setDescription] = useState<string>('');
    const portalData = useGetPortalQuery({company_name: companyName}).data;

    const [meetingTypes, setMeetingTypes] = useState<Array<MeetingType>>([]);
    const { data: meetingTypesData, isPending: meetingTypesIsPending } = useListMeetingTypesPortalQuery({
        company_name: companyName
    });

    const count = useRef(0);

    const [selectedMeetingType, setSelectedMeetingType] = useState<string>(meetingType || '');
    const [selectedDate, setSelectedDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
    const [selectedTime, setSelectedTime] = useState<string>('');
    const [selectedName, setSelectedName] = useState<string>('');
    const [selectedEmail, setSelectedEmail] = useState<string>('');
    const [selectedPhone, setSelectedPhone] = useState<string>('');

    const isMobile = useMediaQuery('(max-width:1000px)');

    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
    const addClient = useAddPhotographyClientPortalMutation({
        onError: (error: unknown) => {
        if (error instanceof Error && "response" in error) {
            const axiosError = error as AxiosError<unknown, AddPhotographyClientPortalIn>;
            const detail = (axiosError.response?.data as { detail?: string })
            ?.detail;
            setErrorMsg(detail ?? "");
        }
        }
    });
    const addEventMutation = useAddEventMutation({
        onError: (error: unknown) => {
        if (error instanceof Error && "response" in error) {
            const axiosError = error as AxiosError<unknown, AddEventParams>;
            const detail = (axiosError.response?.data as { detail?: string })
            ?.detail;
            setErrorMsg(detail ?? "");
        }
        }
    });
    const sendMeetingConfirmationEmailMutation = useSendMeetingConfirmationEmailMutation({
        onError: (error: unknown) => {
        if (error instanceof Error && "response" in error) {
            const axiosError = error as AxiosError<unknown, SendConfirmationMeetingEmailParams>;
            const detail = (axiosError.response?.data as { detail?: string })
            ?.detail;
            setErrorMsg(detail ?? "");
        }
        }
    });

    useEffect(() => {
        if (portalData && count.current === 0) {
            meetingTypesData?.meeting_types.forEach(meetingType => {
                setMeetingTypes(prevState => [...prevState,{
                    uuid: meetingType.uuid,
                    name: meetingType.name,
                    duration: meetingType.duration,
                    location: meetingType.location,
                    isPublic: meetingType.isPublic,
                    price: meetingType.price,
                    description: meetingType.description,
                    blockOffTimeBefore: meetingType.blockOffTimeBefore,
                    blockOffTimeAfter: meetingType.blockOffTimeAfter,
                    minimumNoticeTime: meetingType.minimumNoticeTime,
                }]);
            });

            setDescription(portalData.description);
            count.current++;
        }
    }, [portalData]);
    
    const calculateStartDate = (selectedDate: string, selectedTime: string) => {
        // Split the selectedDateTime into components
        const [timeStr, meridiem] = selectedTime.split(' '); // ['02:30', 'PM']

        // Parse the time component
        let [hours, minutes] = timeStr.split(':').map(Number); // [2, 30]

        // Adjust hours based on AM/PM
        if (meridiem === 'PM' && hours < 12) {
            hours += 12;
        }
        if (meridiem === 'AM' && hours === 12) {
            hours = 0;
        }

        // Create a Date object for the selected date and time
        const dateTimeObj = new Date(`${selectedDate}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`);
        return dateTimeObj;
    }

    const calculateEndDate = (dateTimeObj: Date, duration: string) => {
        // Parse duration to get the number of minutes
        const durationMinutes = parseInt(duration.split(' ')[0], 10); // 30
        // Calculate the end date by adding the duration
        const endDateObj = new Date(dateTimeObj.getTime() + durationMinutes * 60000); // 60000 ms in a minute
        return endDateObj;
    }

    const handleMeetingBooking = async () => {
        const date = calculateStartDate(selectedDate, selectedTime);
        const endDate = calculateEndDate(
            date,
            meetingTypes.find(
                (meetingType) => meetingType.uuid === selectedMeetingType
            )?.duration || '0 min'
        );
    
        // Convert date and endDate to UTC
        const dateUtc = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        const endDateUtc = new Date(
            endDate.getTime() - endDate.getTimezoneOffset() * 60000
        );
    
        // Format dates as ISO strings with 'Z' to indicate UTC timezone
        const dateIsoString = dateUtc.toISOString();
        const endDateIsoString = endDateUtc.toISOString();
    
        const clientResult = await addClient.mutateAsync({
            full_name: selectedName.trim(),
            email: selectedEmail.trim(),
            phone: selectedPhone.trim(),
            source: ClientSource.OTHER,
            priority: Priority.LOW,
            company_name: companyName,
        });
    
        const eventResult = await addEventMutation.mutateAsync({
            name:
                meetingTypes.find(
                    (meetingType) => meetingType.uuid === selectedMeetingType
                )?.name ?? '',
            is_all_day: false,
            date: dateIsoString,         // Date in UTC with timezone offset zero
            end_date: endDateIsoString,  // End date in UTC with timezone offset zero
            client_uuid: clientResult.uuid,
            location: meetingTypes.find(
                (meetingType) => meetingType.uuid === selectedMeetingType
            )?.location,
            company_name: companyName,
            is_meeting: true,
        });
    
        await sendMeetingConfirmationEmailMutation.mutateAsync({
            event_uuid: eventResult.uuid,
            client_uuid: clientResult.uuid,
            company_name: companyName,
            email: selectedEmail,
        });
    };

    const renderPage = () => {
        switch(currentPage) {
            case 1:
                return <ChooseMeeting
                    meetingTypes={meetingTypes}
                    setCurrentPage={setCurrentPage}
                    selectedMeetingType={selectedMeetingType}
                    setSelectedMeetingType={setSelectedMeetingType}
                />;
            case 2:
                return <DateAndTime
                    setCurrentPage={setCurrentPage}
                    meetingType={meetingTypes.find(meetingType => meetingType.uuid === selectedMeetingType)}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    companyName={companyName}
                    isMobile={isMobile}
                />;
            case 3:
                return <AddDetails
                    setCurrentPage={setCurrentPage}
                    name={selectedName}
                    setName={setSelectedName}
                    email={selectedEmail}
                    setEmail={setSelectedEmail}
                    phone={selectedPhone}
                    setPhone={setSelectedPhone}
                 />;
            case 4:
                return <Confirm email={selectedEmail}/>;
            default:
                return null;
        }
    }

    useEffect(() => {
        if (currentPage === 4)
            handleMeetingBooking();
    }, [currentPage]);

    return (
        <Stack flexDirection={isMobile ? 'column' : 'row'} sx={{height: '100vh', width: '100vw'}} alignItems={'center'} justifyContent={'center'}>
            <Box marginTop={isMobile ? '2rem' : 0} width={isMobile ? '20rem' : '50%'} marginLeft={isMobile ? '0' : '7rem'}>
                <Description name={companyName} description={description} />
            </Box>
            <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem sx={{marginY: isMobile ? '0' : '5rem', marginX: isMobile ? '3rem' : '5rem'}} />
            <Box width={isMobile ? '20rem' : '50%'} marginRight={isMobile ? '0' : '7rem'} marginTop={isMobile ? '2rem' : 0}>
                {renderPage()}
            </Box>
        </Stack>
    )
}

export default SchedulerLayout
