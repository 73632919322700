import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  ListSalesParams,
  ListSalesResponse,
  SalesStatsParams,
  SalesStatsResponse,
} from "./types";

export function useSalesStatsQuery(params: SalesStatsParams) {
  return useQuery<SalesStatsResponse>({
    queryKey: ["sale-stats", params.window_size],
    queryFn: async () => (await axios.get("/api/sales/stats", { params })).data,
  });
}

export function useListSalesQuery(params: ListSalesParams) {
  return useQuery<ListSalesResponse>({
    queryKey: ["sale-list", params],
    queryFn: async () => (await axios.get("/api/sales/list", { params })).data,
  });
}
