import { Client, PhotographyClientInfo, RealEstateClientInfo } from '../../../api/types';
import { useParams } from 'react-router-dom';
import { useGetClientQuery } from '../../../api/clients';

export function useCurrentContact(): null | undefined | PhotographyClientInfo {
  const { contactId } = useParams();
  if (!contactId)
    return undefined;

  const { data, isPending } = useGetClientQuery({
    uuid: contactId
  });

  if (isPending)
    return undefined;

  return data?.client as PhotographyClientInfo ?? null;
}