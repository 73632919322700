import {MynkForm, MynkFormProps} from "./MynkForm";
import ElevatorDialog from "../ElevatorDialog";
import React, {ReactNode, useCallback, useRef} from "react";
import {Stack, SxProps, Typography} from "@mui/material";
import DialogActionButton from "../DialogActionButton";
import {FormikProps} from "formik";


export interface MynkFormDialogProps extends MynkFormProps {
  open: boolean;
  onClose?: () => void;
  children?: ReactNode;
  action?: string;
  actionDisabled?: boolean;
  loading?: boolean;
  elevatorSx?: SxProps;
  errorMsg?: string;
  noMargin?: boolean;
}


export function MynkFormDialog(props: MynkFormDialogProps) {
  const {children, ...otherProps} = props;
  
  return (
    <ElevatorDialog open={props.open} onClose={props.onClose} sx={props.elevatorSx} noMargin={props.noMargin}>  { /* Responsible to make it a pop-up window and not inside the page itself */}
      <MynkForm {...otherProps}>
        <>
          {children}

          
          {typeof props.action === 'string' && (
            <Stack direction="row" justifyContent="center" sx={{mt: 5}}>
              <DialogActionButton type="submit" loading={props.loading}>
                {props.action}
              </DialogActionButton>
            </Stack>
          )}
          {props.errorMsg && (
            <Typography sx={{ color: "red" }}>{props.errorMsg}</Typography>
          )}
        </>
      </MynkForm>
    </ElevatorDialog>
  )
}