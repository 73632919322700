import { MynkTab } from "../../../../components/MynkPage";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewGeneralLayout from "../../../../components/ViewProjectOrContact/ViewGeneralLayout";
import { DeleteProjectParams, RealEstateListing } from "../../../../api/types";
import { Stack } from "@mui/material";
import {
  useDeleteProjectMutation,
  useGetProjectQuery,
} from "../../../../api/projects";
import ActionMenuButton, {
  ActionMenuButtonVariant,
} from "../../../../components/ActionMenuButton";
import { Tune } from "@mui/icons-material";
import { LowerCard, UpperCard } from "../common/cards";
import { EditListingDialog } from "../../../../dialogs/EditRealEstateListing";
import ListingViewersTableCard from "../common/ListingViewersTableCard";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmActionDialog from "../../../../components/ConfirmActionDialog";
import { PATHS, WorkflowPath, makePath } from "../../../../paths";

export default function ViewRealEstateListingGeneralSubpage() {
  const { projectId } = useParams();
  const { data, isPending } = useGetProjectQuery(
    { project_uuid: projectId ?? "" },
    Boolean(projectId)
  );
  const listing = data?.project as RealEstateListing;
  
  const [editListingOpen, setEditListingOpen] = useState(false);
  const [deleteListingOpen, setDeleteListingOpen] = useState(false);
  const navigate = useNavigate();

  const deleteListing = useDeleteProjectMutation();
  const handleDeleteListing = async (uuid: string) => {
    const params = {
      uuid: uuid,
    } as DeleteProjectParams;

    await deleteListing.mutateAsync(params);
    setDeleteListingOpen(false);
    navigate(makePath(WorkflowPath.REAL_ESTATE, PATHS.listings.all));
  };

  return (
    <MynkTab
      title="General"
      action={
        <>
          <EditListingDialog
            open={editListingOpen}
            onClose={() => setEditListingOpen(false)}
            listing={listing}
          />
          <ConfirmActionDialog
            open={deleteListingOpen}
            what="listing"
            whatAction="delete"
            onClose={() => setDeleteListingOpen(false)}
            onConfirm={() => handleDeleteListing(listing.uuid)}
            loading={deleteListing.isPending}
          >
            Delete
          </ConfirmActionDialog>
          <Stack direction="row" alignItems="center">
            <ActionMenuButton
              startIcon={<Tune sx={{ mr: 1 }} />}
              variant={ActionMenuButtonVariant.SECONDARY}
              sx={{
                mb: -9,
                mr: 2,
              }}
              onClickItem={() => setEditListingOpen(true)}
            >
              Edit
            </ActionMenuButton>
            <ActionMenuButton
              startIcon={<DeleteIcon />}
              variant={ActionMenuButtonVariant.SECONDARY}
              sx={{
                mb: -9,
                mr: 2,
                bgcolor: "#ffc2c2",
                color: "#5c0000",
                "&:hover": {
                  bgcolor: "#ffdede",
                  color: "#5c0000",
                },
              }}
              onClickItem={() => setDeleteListingOpen(true)}
            >
              &nbsp;Delete
            </ActionMenuButton>
          </Stack>
        </>
      }
    >
      <ViewGeneralLayout
        upperCard={data?.project && <UpperCard listing={listing} />}
        lowerCard={data?.project && <LowerCard listing={listing} />}
        loading={!data?.project || isPending}
        mainContent={<ListingViewersTableCard listing={listing} />}
      />
    </MynkTab>
  );
}
