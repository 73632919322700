import React, { useState, useEffect } from "react";
import { Box, Stack, Typography, Skeleton, IconButton, Slide } from "@mui/material";
import scrollRightArrow from "../../../../assets/icons/scroll-right-arrow.svg";
import scrollLeftArrow from "../../../../assets/icons/scroll-left-arrow.svg";
import { useListFilesQuery } from "../../../../api/files";
import { LibraryCategory } from "../../../../api/types";
import JSZip from 'jszip';
import { MynkTab } from "../../../../components/MynkPage";
import { useNavigate } from "react-router-dom";
import { PATHS, WorkflowPath, makePath } from "../../../../paths";


const PREVIEW_WIDTH = "22.7em";

interface BoxPreviewProps {
    imageUrl: string;
    onClick: () => void;
}

function BoxPreview(props: BoxPreviewProps) {
    const [imageReady, setImageReady] = useState(false);
  
    return (
        <Box
            sx={{
                width: PREVIEW_WIDTH,
                height: `calc(1.4 * ${PREVIEW_WIDTH})`,
                cursor: "pointer",
                transition: "transform 0.15s ease-in-out",
                "&:hover": {
                    transform: "scale(1.04)",
                },
            }}
            onClick={props.onClick}
        >
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "none",
                }}
            >
                {!imageReady && (
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={"100%"}
                        sx={{ bgcolor: "white" }}
                    />
                )}
        
                <img
                    src={props.imageUrl}
                    onLoad={() => setImageReady(true)}
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        display: imageReady ? "block" : "none",
                    }}
                />
            </Stack>
        </Box>
    );
}

interface LibraryGroupProps {
    libraryType: LibraryCategory;
}

function LibraryGroup(props: LibraryGroupProps) {
    const [images, setImages] = useState<string[] | null>(null);
    const [imagesNames, setImagesNames] = useState<string[] | null>(null);
    const { data, isLoading } = useListFilesQuery({ folder: `${props.libraryType}Images` });
    const [currentPage, setCurrentPage] = useState<number>(0); // Specific to each LibraryGroup
    const [slideDirection, setSlideDirection] = useState<"right" | "left" | undefined>("left");
    const templatesCardsPerPage = 4;

    const navigate = useNavigate();

    const processZip = async (zipBlob: Blob) => {
        try {
            if (!zipBlob) return [];

            const arrayBuffer = await zipBlob.arrayBuffer();
            const zip = await JSZip.loadAsync(arrayBuffer);

            const items = [];
            const itemsNames = [];

            for (const fileName of Object.keys(zip.files)) {
                const file = zip.file(fileName);
                const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));

                if (file) {
                    const base64 = await file.async('base64');

                    if (base64) {
                        items.push(`data:image/jpeg;base64,${base64}`);
                        itemsNames.push(fileNameWithoutExtension);
                    }
                }
            }

            return [items, itemsNames];
        } catch (error) {
            console.error('Error processing ZIP file:', error);
            return [];
        }
    };

    useEffect(() => {
        const isValidBlob = (value: any): value is Blob => {
            // Check if `value` is a Blob (the expected format from the backend)
            return value instanceof Blob;
        };

        if (!isLoading && data) {
            // Check that data is in the correct format (Blob)
            if (isValidBlob(data)) {
                const processImages = async () => {
                    const [images, imagesNames] = await processZip(data);
                    setImages(images);
                    setImagesNames(imagesNames);
                };

                processImages();
            } else {
                console.warn('Data is not a valid Blob. Waiting for correct format.');
            }
        }
    }, [data, isLoading]);

    const handleClick = (fileName: string) => {
        if (props.libraryType === LibraryCategory.GUIDES) {
            navigate(
                makePath(
                    WorkflowPath.PHOTOGRAPHY,
                    PATHS.guide(fileName).index
                )
            );
        }
        else if (props.libraryType === LibraryCategory.CONTRACTS) {
            navigate(
                makePath(
                    WorkflowPath.PHOTOGRAPHY,
                    PATHS.contract(fileName).index
                )
            );
        }
        else if (props.libraryType === LibraryCategory.RELEASES) {
            navigate(
                makePath(
                    WorkflowPath.PHOTOGRAPHY,
                    PATHS.release(fileName).index
                )
            );
        }
        else if (props.libraryType === LibraryCategory.TOOLS) {
            navigate(
                makePath(
                    WorkflowPath.PHOTOGRAPHY,
                    PATHS.tool(fileName).index
                )
            );
        }
    };

    const handleNext = () => {
        if (images && currentPage < Math.ceil(images.length / templatesCardsPerPage) - 1) {
            setSlideDirection("left");
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrev = () => {
        if (currentPage > 0) {
            setSlideDirection("right");
            setCurrentPage(currentPage - 1);
        }
    };
    
    if (images !== null && images.length === 0) {
        return (
            <></>
        )
    }

    return (
        <Stack>
            <Typography variant="h4" sx={{ pt: 2 }}>{props.libraryType}</Typography>

            <Stack
                direction="row"
                spacing="-3rem"
                sx={{
                    mt: "2rem",
                    height: "30rem",
                }}
            >
                {/* Left Arrow */}
                {currentPage === 0 ? (
                    <Box sx={{ width: "5.5rem", height: "5.5rem" }}></Box>
                ) : (
                    <IconButton onClick={handlePrev} sx={{
                        width: "5.5rem",
                        height: "5.5rem",
                        borderRadius: "50%",
                        background: "rgba(176, 176, 176, 0.3)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "background 0.3s ease",
                        ":hover": {
                            background: "rgba(176, 176, 176, 0.6)",
                        },
                        mr: "1rem",
                        alignSelf: "center",
                    }}>
                        <Box
                            component="img"
                            src={scrollLeftArrow}
                            sx={{ width: "2rem", height: "2rem", mr: "0.2rem" }}
                        />
                    </IconButton>
                )}

                <Stack direction={'row'} mt={3} spacing={5} ml={4}>
                    {images && imagesNames ? (
                        images
                            .slice(currentPage * templatesCardsPerPage, currentPage * templatesCardsPerPage + templatesCardsPerPage)
                            .map((imageSrc, index) => (
                                imageSrc && (
                                    <BoxPreview imageUrl={imageSrc} onClick={() => handleClick(imagesNames[index])} />
                                )
                            ))
                    ) : (
                        <>
                            <BoxPreview imageUrl={""} onClick={() => {}} />
                            <BoxPreview imageUrl={""} onClick={() => {}} />
                            <BoxPreview imageUrl={""} onClick={() => {}} />
                        </>
                    )}
                </Stack>

                {/* Right Arrow */}
                <IconButton
                    onClick={handleNext}
                    sx={{
                        display: images && currentPage < Math.ceil(images.length / templatesCardsPerPage) - 1 ? "block" : "none",
                        width: "5.5rem",
                        height: "5.5rem",
                        borderRadius: "50%",
                        background: "rgba(176, 176, 176, 0.3)",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "background 0.3s ease",
                        ":hover": {
                            background: "rgba(176, 176, 176, 0.6)",
                        },
                        alignSelf: "center",
                        ml: "-2.4rem !important",
                    }}
                >
                    <Box
                        component="img"
                        src={scrollRightArrow}
                        sx={{ width: "2rem", height: "2rem", ml: "0.2rem" }}
                    />
                </IconButton>
            </Stack>
        </Stack>
    );
}

export default function LibraryAllSubpage() {
    return (
        <MynkTab title="All">
            <Stack spacing={5}>
                <LibraryGroup libraryType={LibraryCategory.CONTRACTS}/>
                <LibraryGroup libraryType={LibraryCategory.GUIDES}/>
                <LibraryGroup libraryType={LibraryCategory.RELEASES}/>
                <LibraryGroup libraryType={LibraryCategory.TOOLS}/>
            </Stack>
        </MynkTab>
    );
}
