import { Card, Box, Stack, Typography, Grid, SxProps } from "@mui/material";
import { useState } from "react";

import { AddPhotographyContactDialog } from "../../../dialogs/AddPhotographyContact";
import smallLeftArrowBlue from "../../../assets/icons/small-left-arrow-blue.svg";
import welcomeReelPhotography from "../../../assets/images/welcome-reel-photography.jpg";
import sampleProjectsScreenshot from "../../../assets/images/project-image.png";
import sampleClientsScreenshot from "../../../assets/images/client-image.png";
import {
  ExploreButton,
  MoreToExploreButton,
  WelcomePanel,
} from "../../CommonComponents/Home";
import { PATHS, WorkflowPath, makePath } from "../../../paths";
import { AddPhotographyProjectDialog } from "../../../dialogs/AddPhotographyProject";
import { ProjectStage, WorkflowType } from "../../../api/types";
import { languageAtom, LanguageCode } from "../../../components/LanguagePicker";
import { useAtomValue } from "jotai";

enum NewMenuIds {
  NEW_PROJECT = "New project",
  NEW_CONTACT = "New contact",
}

function LinkWithArrow(props: { type: string; sx?: SxProps }) {
  const [newProjectOpen, setNewProjectOpen] = useState(false);
  const [addContactOpen, setAddContactOpen] = useState(false);
  {
    /* Open relevant dialog box by the link type */
  }
  const handleClick = () => {
    switch (props.type) {
      case NewMenuIds.NEW_PROJECT:
        setNewProjectOpen(true);
        break;
      case NewMenuIds.NEW_CONTACT:
        setAddContactOpen(true);
        break;
    }
  };

  const language = useAtomValue(languageAtom);
  const isHebrew = language === LanguageCode.Hebrew;

  return (
    <>
      <AddPhotographyProjectDialog
        open={newProjectOpen}
        onClose={() => setNewProjectOpen(false)}
      />
      <AddPhotographyContactDialog
        open={addContactOpen}
        onClose={() => setAddContactOpen(false)}
      />

      <Box sx={{ ml: isHebrew ? 0 : 4, mr: isHebrew ? 4 : 0, mb: 4, ...props.sx }}>
        <span
          style={{
            fontFamily: "Helvetica Neue",
            background:
              "linear-gradient(transparent calc(100% - 0.1rem), #75b3ff 0.1rem)",
            paddingBottom: 4,
            fontSize: "1.3rem",
            color: "#75b3ff",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          {props.type}{" "}
          <Box
            component="img"
            src={smallLeftArrowBlue}
            width={"0.6429rem"}
            style={{ paddingLeft: 2, marginBottom: 1 }}
            sx={{transform: isHebrew ? "rotate(180deg)" : "none"}}
          />
        </span>
      </Box>
    </>
  );
}

function BusinessInGlance() {
  const language = useAtomValue(languageAtom);
  const isHebrew = language === LanguageCode.Hebrew;

  // Localized strings
  const localizedStrings = {
    en: {
      businessGlance: "Your business in a glance",
      projects: "Projects",
      newProject: "New project",
      clients: "Clients",
      newContact: "New client",
      exploreTemplates: "Explore all templates",
      keepTasks: "Keep up with your tasks",
      expandTeam: "Expand your team",
    },
    he: {
      businessGlance: "העסק שלך במבט",
      projects: "פרויקטים",
      newProject: "פרויקט חדש",
      clients: "לקוחות",
      newContact: "לקוח חדש",
      exploreTemplates: "גילוי תבניות",
      keepTasks: "מעקב משימות",
      expandTeam: "גיוס עובדים",
    },
  };

  const strings = localizedStrings[language] || localizedStrings.en;

  return (
    <Box
      sx={{
        mx: 4,
        direction: isHebrew ? "rtl" : "ltr", // Set text direction dynamically
      }}
    >
      <Typography variant="h4" sx={{ mt: 5, mb: 5, textAlign: isHebrew ? "right" : "left" }}>
        {strings.businessGlance}
      </Typography>

      <Card>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          {/* Projects column */}
          <Stack width={'35rem'} ml={2}>
            <Typography
              variant="h4"
              fontWeight="regular"
              sx={{ ml: isHebrew ? 0 : 2, mr: isHebrew ? 2 : 0, mt: 3.7, textAlign: isHebrew ? "right" : "left" }}
            >
              {strings.projects}
            </Typography>
            <a href={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.projects.all)}>
              <img
                src={sampleProjectsScreenshot}
                style={{ width: "100%", marginTop: "1.5rem" }}
              />
            </a>
            <LinkWithArrow type={strings.newProject} sx={{ mt: "2rem" }} />
          </Stack>

          {/* Clients column */}
          <Stack width={'35rem'}>
            <Typography
              variant="h4"
              fontWeight="regular"
              sx={{ ml: isHebrew ? 0 : 2, mr: isHebrew ? 2 : 0, mt: 3.7, textAlign: isHebrew ? "right" : "left" }}
            >
              {strings.clients}
            </Typography>
            <a href={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.clients.all)}>
              <img
               src={sampleClientsScreenshot}
               style={{ width: "100%", marginTop: "1.5rem" }}
              />
            </a>
            <LinkWithArrow type={strings.newContact} sx={{ mt: "2rem" }} />
          </Stack>

          {/* Need help & Explore column */}
          <Stack width={'32rem'} ml={isHebrew ? 0 : "4rem !important"} mr={isHebrew ? "4rem !important" : 0}>
            <Stack spacing={4}>
              <Stack spacing={5.2} width={"88%"}>
                <ExploreButton
                  text={strings.exploreTemplates}
                  link={makePath(
                    WorkflowPath.PHOTOGRAPHY,
                    PATHS.templates.index
                  )}
                />
                <ExploreButton
                  text={strings.keepTasks}
                  link={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.tasks.index)}
                />
                <ExploreButton
                  text={strings.expandTeam}
                  link={makePath(
                    WorkflowPath.PHOTOGRAPHY,
                    PATHS.settings.index
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
}

function MoreToExplore() {
  const language = useAtomValue(languageAtom);
  const isHebrew = language === LanguageCode.Hebrew;

  // Localized strings
  const localizedStrings = {
    en: {
      title: "More to explore",
      customInvoices: "Custom invoices",
      contracts: "Contracts",
      templates: "Templates",
      newEvent: "Create a new event",
      changeProfilePicture: "Change your profile picture",
      settings: "Settings",
    },
    he: {
      title: "גלו עוד",
      customInvoices: "חשבוניות בהתאמה אישית",
      contracts: "חוזים",
      templates: "תבניות",
      newEvent: "יצירת אירוע חדש",
      changeProfilePicture: "שינוי תמונת פרופיל",
      settings: "הגדרות",
    },
  };

  const strings = localizedStrings[language] || localizedStrings.en;

  return (
    <Box
      sx={{
        pl: isHebrew ? 0 : 4,
        pr: isHebrew ? 4 : 0,
        pb: 10,
        pt: 3.2,
        direction: isHebrew ? "rtl" : "ltr", // Adjust text direction for Hebrew
      }}
    >
      <Typography
        variant="h4"
        sx={{
          mb: 5.2,
          textAlign: isHebrew ? "right" : "left", // Align title dynamically
        }}
      >
        {strings.title}
      </Typography>

      <Grid
        container
        spacing={3}
        sx={{
          width: "99%",
          direction: isHebrew ? "rtl" : "ltr", // Adjust grid layout for Hebrew
        }}
      >
        <Grid item xs={4}>
          <MoreToExploreButton link={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.templates.index)}>
            {strings.customInvoices}
          </MoreToExploreButton>
        </Grid>
        <Grid item xs={4}>
          <MoreToExploreButton link={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.templates.index)}>
            {strings.contracts}
          </MoreToExploreButton>
        </Grid>
        <Grid item xs={4}>
          <MoreToExploreButton link={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.templates.index)}>
            {strings.templates}
          </MoreToExploreButton>
        </Grid>
        <Grid item xs={4}>
          <MoreToExploreButton link={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.scheduler.calendar)}>
            {strings.newEvent}
          </MoreToExploreButton>
        </Grid>
        <Grid item xs={4}>
          <MoreToExploreButton link={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.settings.index)}>
            {strings.changeProfilePicture}
          </MoreToExploreButton>
        </Grid>
        <Grid item xs={4}>
          <MoreToExploreButton link={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.settings.index)}>
            {strings.settings}
          </MoreToExploreButton>
        </Grid>
      </Grid>
    </Box>
  );
}

function HomeWidgets() {
  return (
    <Stack spacing={7} sx={{ mt: 5.3 }}>
      <BusinessInGlance />
      {/* TODO: Blogs */}
      <MoreToExplore />
    </Stack>
  );
}

export default function PhotographyHomePage() {
  return (
    <Box>
      <WelcomePanel scrollingImg={welcomeReelPhotography} />
      <HomeWidgets />
    </Box>
  );
}
