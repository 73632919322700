import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Stack,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import React from "react";

export interface ElevatorDialogProps {
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  sx?: SxProps;
  noMargin?: boolean;
  customCloseButton?: boolean;
}

export default function ElevatorDialog(props: ElevatorDialogProps) {
  return (
    <Dialog
      open={props.open}
      hideBackdrop={true}
      onClose={() => props.onClose?.()}
      // onClose={props.onClose}
      sx={{
        "& div.MuiPaper-root": {
          background: "rgba(255, 255, 255, 0.7)",
          border: "0.1071rem white solid",
          backdropFilter: "blur(0.01rem)",
        },
        background: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(0.07rem)",
        ...props.sx,
      }}
      maxWidth={false}
      // fullWidth
    >
      <Box
        sx={{
          ...(props.noMargin ? { p: 3 } : { m: 3, p: 3, px: 6.5}),
          background: "linear-gradient(180deg, white 15%, #E0F3FF 66%)",
          boxShadow: "0px 0px 1.4286rem rgba(117, 179, 255, 0.20)",
          borderRadius: "1.3rem",
          position: "relative",
          ...props.sx,
        }}
      >
        {/* X icon */}
        {(props.onClose && props.customCloseButton != true) ? (
          <IconButton
            sx={{ position: "absolute", top: 16, right: 16, color: "black" }}
            onClick={() => props.onClose?.()}
          >
            <Clear />
          </IconButton>
        ) : (
          <></>
        )}
        <Box>{props.children}</Box>
        {/*<Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>*/}
        {/*  <Button*/}
        {/*    variant="contained"*/}
        {/*    endIcon={<ArrowForward />}*/}
        {/*    sx={{*/}
        {/*      minWidth: 135,*/}
        {/*      borderRadius: '24px',*/}
        {/*      background: 'linear-gradient(90deg, #59b5d9, #6a91f2)',*/}
        {/*      mb: 2,*/}
        {/*    }}*/}
        {/*    onClick={handleAdd}*/}
        {/*  >*/}
        {/*    Add*/}
        {/*  </Button>*/}
        {/*</Stack>*/}
      </Box>
    </Dialog>
  );
}
