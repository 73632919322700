import {
  Availability,
  ChangeListingViewerAction,
  FilterOption,
  ListProjectsResponse,
  ListingStage,
  ListingViewer,
  RealEstateClientInfo,
  RealEstateListing,
  SearchOption,
  SortOption,
  WorkflowType,
} from "../../../api/types";
import { useNavigate, useParams } from "react-router-dom";
import { useGetClientQuery } from "../../../api/clients";
import { atom, useAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useChangeListingViewer,
  useListProjectsQuery,
  useSetListingAvailabilityMutation,
} from "../../../api/projects";
import {
  SimpleTableCard,
  SimpleTableColumn,
  SimpleTableColumnType,
  TableType,
} from "../../../components/SimpleTable";
import { PATHS, WorkflowPath, makePath } from "../../../paths";
import ThreeDotsButton from "../../../components/ThreeDotsButton";
import { EditListingDialog } from "../../../dialogs/EditRealEstateListing";
import {
  FILTER_REAL_ESTATE_LISTINGS_OPTIONS,
  REAL_ESTATE_LISTING_STAGE_OPTIONS,
  SORT_REAL_ESTATE_LISTINGS_OPTIONS,
} from "../../../components/ChipSelect/commonOptions";
import ManageListings from "../../../dialogs/ManageListings";
import { Typography } from "@mui/material";
import AvailabilityChip from "../../../components/AvailabilityChip";
import { ChipSelect } from "../../../components/ChipSelect";

const selectedProjectAtom = atom<RealEstateListing | null>(null);
const openedEditAtom = atom(false);
const openedDeleteAtom = atom(false);

export function useCurrentContact(): null | undefined | RealEstateClientInfo {
  const { contactId } = useParams();
  if (!contactId) return undefined;

  const { data, isPending } = useGetClientQuery({
    uuid: contactId,
  });

  if (isPending) return undefined;

  return (data?.client as RealEstateClientInfo) ?? null;
}

const BASE_COLUMNS: SimpleTableColumn[] = [
  {
    key: "name",
    label: "Listing",
    type: SimpleTableColumnType.NAME,
  },
  {
    key: "price",
    label: "Price",
    type: SimpleTableColumnType.USD,
  },
  {
    key: "availability",
    label: "Property's status",
    type: SimpleTableColumnType.AVAILABILITY,
  },
  {
    key: "stage",
    label: "Stage",
  },
  {
    key: "view_listing",
    label: "View",
    type: SimpleTableColumnType.LINK,
    hideHeader: true,
  },
  {
    key: "",
    label: "",
    render(_: unknown, row: any) {
      return <ThreeDotsListing listing={row} />;
    },
  },
];

enum threeDotsOptions {
  EDIT = "edit-listing",
}

const DOTS_MENU = [
  {
    id: threeDotsOptions.EDIT,
    label: "Edit",
  },
];

interface ThreeDotsListingProps {
  listing: RealEstateListing;
}

/**
 * Renders a button with three dots. When the button is clicked, it opens a menu with options to edit or delete a task.
 * Also renders two dialog components, `ConfirmDeleteDialog` and `EditProjectDialog`, which are opened when the corresponding menu options are selected.
 *
 * @returns The rendered `ThreeDotsTask` component with a button and two dialog components.
 */
function ThreeDotsListing(props: ThreeDotsListingProps) {
  const [selectedProject, setSelectedProject] = useAtom(selectedProjectAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);

  /**
   * Handles the click event on the menu options.
   * Sets the corresponding state variable to `true` to open the corresponding dialog component.
   *
   * @param id - The ID of the clicked menu option.
   */
  const handleClickItem = (id: string) => {
    setSelectedProject(props.listing);

    switch (id) {
      case threeDotsOptions.EDIT:
        setOpenedEdit(true);
        break;
    }
  };

  return (
    <ThreeDotsButton
      menu={DOTS_MENU}
      onClickItem={handleClickItem}
      children={undefined}
    ></ThreeDotsButton>
  );
}

interface ListingsTableCardProps {
  client_uuid?: string;
}

const PAGE_SIZE = 10;

export function ListingsTableCard(props: ListingsTableCardProps) {
  const [selectedProject, setSelectedProject] = useAtom(selectedProjectAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);

  const [search, setSearch] = useState<SearchOption | undefined>(undefined);
  const [filter, setFilter] = useState<FilterOption | undefined>(undefined);
  const [sort, setSort] = useState<SortOption | undefined>(undefined);

  const [selectedPage, setSelectedPage] = useState(0);
  const [current, setCurrent] = useState<null | ListProjectsResponse>(null);

  const { data, isPending } = useListProjectsQuery({
    page_size: PAGE_SIZE,
    page_index: selectedPage,
    retrieve_options: {
      search: search,
      filter: filter,
      sort: sort,
    },
    prospect_uuid: props.client_uuid,
  });

  // If something changes - re-paginate everything:
  useEffect(() => {
    setSelectedPage(0);
  }, [props.client_uuid, search, filter, sort]);

  useEffect(() => {
    if (!isPending && data) {
      setCurrent(data);
    }
  }, [data, isPending]);

  const navigate = useNavigate();
  const handleClickLink = useCallback((row: RealEstateListing, key: string) => {
    navigate(
      makePath(WorkflowPath.REAL_ESTATE, PATHS.viewProject(row.uuid).general)
    );
  }, []);

  const [manageListingsOpen, setManageListingsOpen] = useState(false);

  const columns = useMemo(() => {
    const result = [...BASE_COLUMNS];
    const stageIndex = result.findIndex((c) => c.key === "stage");
    result[stageIndex] = {
      key: "stage",
      label: "Stage",
      render(value, row) {
        const currentStage = row.viewers.find(
          (viewer: ListingViewer) => viewer.client_uuid === props.client_uuid
        ).stage;

        const changeViewer = useChangeListingViewer();
        const handleChangeStage = (value: string) => {
          changeViewer.mutate({
            prospect_uuid: props.client_uuid ?? "",
            listing_uuid: row.uuid,
            action: ChangeListingViewerAction.SET_STAGE,
            new_stage: value as ListingStage,
          });
        };

        return (
          <>
            <ChipSelect
              options={REAL_ESTATE_LISTING_STAGE_OPTIONS}
              value={currentStage}
              onChange={handleChangeStage}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 1px 5px rgba(117, 179, 255, 0.35)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "8rem",
                textAlign: "center",
              }}
            />
            <></>
          </>
        );
      },
    };

    const availabilityIndex = result.findIndex((c) => c.key === "availability");
    result[availabilityIndex] = {
      key: "availability",
      label: "Availability",
      render(value, row) {
        const setListingAvailability = useSetListingAvailabilityMutation();
        const handleChangeAvailability = (value: string) => {
          setListingAvailability.mutate({
            uuid: row.uuid,
            availability: value as Availability,
          });
        };

        return (
          <AvailabilityChip
            value={row.availability}
            onChange={handleChangeAvailability}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "7rem",
              textAlign: "center",
            }}
          />
        );
      },
    };

    return result;
  }, []);

  return (
    <>
      <EditListingDialog
        open={openedEdit}
        onClose={() => setOpenedEdit(false)}
        listing={selectedProject ?? undefined}
      />

      <ManageListings
        chosenListings={(current?.projects as RealEstateListing[]) ?? []}
        prospectUuid={props.client_uuid ?? ""}
        open={manageListingsOpen}
        onClose={() => setManageListingsOpen(false)}
      />

      <SimpleTableCard
        title="Relevant listings"
        data={current?.projects}
        columns={columns}
        tableType={TableType.CLIENTS}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        pageSize={PAGE_SIZE}
        numOfPages={current?.num_of_pages ?? 1}
        total={current?.total ?? 0}
        isPending={isPending}
        onClickLink={handleClickLink}
        searchState={[search, setSearch]}
        filterState={[filter, setFilter]}
        sortState={[sort, setSort]}
        filterOptions={FILTER_REAL_ESTATE_LISTINGS_OPTIONS}
        sortOptions={SORT_REAL_ESTATE_LISTINGS_OPTIONS}
      />

      <Typography
        variant="body2"
        sx={{ color: "#75b3ff", mt: 2, cursor: "pointer" }}
        align="center"
        onClick={() => setManageListingsOpen(true)}
      >
        + Add/delete relevant listings
      </Typography>
    </>
  );
}
