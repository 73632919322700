import React, {
  ForwardedRef,
  forwardRef,
  ReactNode, Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo, useRef,
  useState
} from "react";
import {Box, Typography} from "@mui/material";
import {MynkFormContext, MynkFormContextValue, MynkFormTrackedControl} from "./context";
import {Form, Formik, FormikProps, useFormik} from "formik";



interface MynkFormFormikProps {
  controls: MynkFormTrackedControl<any>[];
  handleSubmit: (values: any) => void;
  children?: ReactNode;
  initialValues?: any;
}

function MynkFormFormik(props: MynkFormFormikProps) {
  const initialValues = useMemo(() => {
    if (props.initialValues)
      return props.initialValues;

    return Object.fromEntries(props.controls.map(control => [control.name, control.default]));

    // do not include initialValues in deps
  }, [props.controls]);

  return (
    <Formik
      onSubmit={props.handleSubmit}
      initialValues={initialValues}
    >
      <Form>
        {props.children}
      </Form>
    </Formik>
  );
}


export interface MynkFormProps {
  title?: string;
  children?: ReactNode;
  onSubmit: (values: any) => void;
  initialValues?: any;
}



export function MynkForm(props: MynkFormProps) {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(true);
  }, []);

  const [trackedControls, setTrackedControls] = useState<MynkFormTrackedControl<any>[]>([]);
  const trackControl = useCallback((control: MynkFormTrackedControl<any>) => {
    if (!ready) {
      setTrackedControls(prev => [...prev, control]);
    }
  }, [ready]);

  const contextValue = useMemo<MynkFormContextValue>(() => ({
    trackControl,
  }), [trackControl]);

  const handleSubmit = (values: any) => {
    props.onSubmit?.(values);
  }

  return (
    <Box>
      {props.title && (
        <Typography variant="h2" fontFamily="Helvetica Neue Bold" sx={{mb: 3, mt: 2}}>{props.title}</Typography>
      )}

      {ready ? (
        <MynkFormFormik
          controls={trackedControls}
          handleSubmit={handleSubmit}
          initialValues={props.initialValues}
        >
          {props.children}
        </MynkFormFormik>
      ) : (
        <MynkFormContext.Provider value={contextValue}>
          {props.children}
        </MynkFormContext.Provider>
      )}
    </Box>
  );
}
