import React, { useEffect, useState } from "react";
import { AddProjectNoteParams, Note } from "../api/types";
import { MynkFormDialog } from "../components/MynkForm";
import { TextField, Stack } from "@mui/material";
import { useAddProjectNoteMutation } from "../api/projects";
import {AxiosError} from "axios";
export interface AddProjectNoteDialogProps {
  open: boolean;
  onClose: () => void;
  projectId?: string;
  editNote?: null | Note;
}

export default function AddProjectNoteDialog(props: AddProjectNoteDialogProps) {
  const [title, setTitle] = useState(""); // New state for title
  const [text, setText] = useState("");
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const addNote = useAddProjectNoteMutation({
      onError: (error: unknown) => {
        if (error instanceof Error && "response" in error) {
          const axiosError = error as AxiosError<unknown, AddProjectNoteParams>;
          const detail = (axiosError.response?.data as { detail?: string })
            ?.detail;
          setErrorMsg(detail ?? "");
        }
      }
  });
  const handleSubmit = async (values: any) => {
    if (!props.projectId) return;

    const params = {
      project_uuid: props.projectId,
      title: title,
      note: text,
      update_note_uuid: props.editNote?.uuid,
    } as AddProjectNoteParams;

    await addNote.mutateAsync(params);
    props.onClose();
  };

  useEffect(() => {
    if (props.open) {
      setTitle(props.editNote == null ? "" : props.editNote.title); // Initialize the title
      setText(props.editNote == null ? "" : props.editNote.text);
    }
  }, [props.open]);

  useEffect(() => {
    if (props.editNote) {
      setTitle(props.editNote.title);
      setText(props.editNote.text);
    }
  }, [props.editNote]);

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      action={props.editNote ? "Update note" : "Add note"}
      actionDisabled={title.length === 0 || text.length === 0}
      onSubmit={handleSubmit}
      loading={addNote.isPending}
      elevatorSx={{
        pl: 4,
        pr: 8,
        pt: 2,
        pb: 1,
      }}
      errorMsg={errorMsg}
    >
      <Stack width={"25rem"}>
        <TextField
          fullWidth
          variant="standard"
          placeholder="Type title here..."
          InputProps={{
            disableUnderline: true,
            style: {
              fontSize: "1.8rem",
              fontFamily: "Helvetica Neue Bold",
              marginTop: "1rem",
              marginLeft: "1rem",
            },
          }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <TextField
          fullWidth
          multiline
          variant="standard"
          placeholder="Type note here..."
          InputProps={{
            disableUnderline: true,
            style: {
              fontSize: "1.3rem",
              fontFamily: "Helvetica Neue",
              minHeight: "18rem",
              display: "flex",
              alignItems: "flex-start",
              marginTop: "1rem",
              marginLeft: "1rem",
            },
          }}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </Stack>
    </MynkFormDialog>
  );
}
