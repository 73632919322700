import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  ImageList,
  ImageListItem,
  Stack,
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { fetchAllImagesByUuids } from "../util/common";

interface GalleryViewProps {
  photosUuids?: string[];
}

export default function GalleryView(props: GalleryViewProps) {
  const [open, setOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  const [zoom, setZoom] = useState(1);
  const [imageSrcList, setImageSrcList] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setImageSrcList(await fetchAllImagesByUuids(props.photosUuids ?? []));
    };

    setImageSrcList([]);
    fetchData();
  }, [props.photosUuids]);

  const handleOpen = (src: string) => {
    setOpen(true);
    setSelectedImg(src);
    setZoom(1); // Reset zoom when opening a new image
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleZoomIn = () => {
    if (zoom < 2.5) {
      // Maximum zoom: x2.5
      setZoom((prevZoom) => prevZoom * 1.2); // Increase zoom by 20%
    }
  };

  const handleZoomOut = () => {
    if (zoom > 1) {
      // Minimum zoom: x1
      setZoom((prevZoom) => prevZoom / 1.2); // Decrease zoom by 20%
    }
  };

  if (props.photosUuids) {
    return (
      <>
        <ImageList
          sx={{ width: "100%", height: "auto", overflow: "hidden" }}
          cols={4}
          variant="quilted"
          gap={11}
        >
          {imageSrcList.map((src, index) => (
            <ImageListItem
              key={index}
              cols={index === 0 ? 2 : 1}
              rows={index === 0 ? 2 : 1}
            >
              <Box
                component="img"
                src={src}
                alt={`image ${index}`}
                onClick={() => handleOpen(src)}
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: 3,
                  cursor: "pointer",
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>

        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiDialog-container": {
              alignItems: "center",
              justifyContent: "center",
            },
            "& .MuiPaper-root": {
              height: "90%", // Adjust the height as needed
              maxWidth: "none", // This will help to ignore the maxWidth
            },
            zIndex: 20000,
          }}
        >
          <Stack
            direction="row"
            sx={{
              position: "fixed",
              top: 16,
              left: 16,
              zIndex: 20002,
              borderRadius: 3,
              bgcolor: "white",
            }}
          >
            <IconButton onClick={handleZoomOut}>
              <ZoomOutIcon />
            </IconButton>
            <IconButton onClick={handleZoomIn}>
              <ZoomInIcon />
            </IconButton>
          </Stack>
          <img
            src={selectedImg}
            alt="Full view"
            style={{
              maxWidth: "100%",
              height: "100%",
              transform: `scale(${zoom})`,
              transition: "transform 0.2s ease-in-out",
              zIndex: 20001,
            }}
          />
        </Dialog>
      </>
    );
  } else {
    return <></>;
  }
}
