import {useAtomValue, useSetAtom} from "jotai";
import {useAtomCallback} from "jotai/utils";
import {useCallback, useMemo} from "react";
import {useTemplateAtoms} from "./TemplateContext";

export function useTemplateHooks() {
  const templateAtoms = useTemplateAtoms();
  const updateDomState = useSetAtom(templateAtoms.domStateAtom);
  const readDomState = useAtomCallback(
    useCallback((get) => get(templateAtoms.domStateAtom), [])
  );

  const deselectAll = useCallback(async () => {
    const state = await readDomState();
    if (!state)
      return;

    if (state.selectedElement !== null) {
      state.selectedElement.classList.remove('t-selected');
    }

    updateDomState({
      selectedElement: null,
    });
  }, []);

  const hooks = {
    deselectAll,
  };

  return useMemo(() => hooks, Object.values(hooks));
}

export function useAddUpdateRecord() {
  const templateAtoms = useTemplateAtoms();
  const state = useAtomValue(templateAtoms.domStateAtom);
  const doAppendRecord = useSetAtom(templateAtoms.appendRecordAtom);

  return useCallback((key: string, value: string, elem?: HTMLElement) => {
    const el = elem ?? state?.selectedElement;
    if (!el)
      return;

    console.log('appending record', el.id, key, value);

    doAppendRecord({
      id: el.id,
      key,
      value,
    });
  }, [state?.selectedElement]);
}

export function useInitTemplateData() {

}
