import {
  Card,
  CircularProgress,
  Divider,
  SxProps,
  Typography,
} from "@mui/material";
import { StackTable, StackTableProps } from "./StackTable";

interface TableHeaderProps {
  title: string;
  sx?: SxProps;
  paddingLevel: number;
}

function StackTableHeader(props: TableHeaderProps) {
  return (
    <>
      <Typography
        variant="h4"
        fontFamily="Helvetica Neue"
        sx={{ pr: 2, pl: 2.7, pt: props.paddingLevel, pb: props.paddingLevel, ...props.sx}}
      >
        {props.title}
      </Typography>
      <Divider sx={{ borderColor: "#75B3FF", opacity: 0.2 }} />
    </>
  );
}

interface StackTableCardProps extends StackTableProps {
  sx?: SxProps;
  title: string;
  paddingLevel: number;
}

export function StackTableCard(props: StackTableCardProps) {
  return (
    <Card sx={{ ...props.sx }} >
      <StackTableHeader title={props.title} paddingLevel={props.paddingLevel} sx={props.sx}/>

      {props.data?.length == 0 ? (
        <CircularProgress sx={{ display: "table", mx: "auto", mt: 2, mb: 2 }} />
      ) : (
        <StackTable {...props} />
      )}
    </Card>
  );
}
