import {
  AppBar,
  Box,
  CircularProgress,
  Drawer,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import Sidebar from "../components/Sidebar";
import { Outlet } from "react-router-dom";
import MainAppBar from "../components/MainAppBar";
import {
  useGetUserCountryQuery,
  useGetUserCurrencyQuery,
  useGetUserTimezoneQuery,
  useRecordUserMutation,
} from "../api/settings";
import { Suspense, startTransition, useEffect } from "react";
import { atom, useAtom, useAtomValue } from "jotai";
import mynkTextLogo from "../assets/images/mynk-textlogo.svg";
import { DateTime, Settings } from "luxon";
import { languageAtom } from "../components/LanguagePicker";
import { LanguageCode } from "../components/LanguagePicker";

export const userCurrencyAtom = atom("");
export const userCountryAtom = atom("");

export default function MainLayout() {
  const currencyCode = useGetUserCurrencyQuery().data?.currency_code;
  const [currencyCodeAtom, setCurrencyCodeAtom] = useAtom(userCurrencyAtom);
  useEffect(() => {
    setCurrencyCodeAtom(currencyCode || "USD");
  }, [currencyCode]);

  const timezone = useGetUserTimezoneQuery().data?.timezone;
  useEffect(() => {
    Settings.defaultZone = timezone;
  }, [timezone]);

  const country = useGetUserCountryQuery().data?.country;
  const [countryCode, setCountryCode] = useAtom(userCountryAtom);
  useEffect(() => {
    setCountryCode(country ?? "");
  }, [country]);

  const recordUser = useRecordUserMutation();

  useEffect(() => {
    async function recordUserDevice() {
      await recordUser.mutateAsync({});
    }

    recordUserDevice();
  }, []);

  // Get the current language from the atom
  const language = useAtomValue(languageAtom);

  // Determine if the layout should be RTL
  const isHebrew = language === LanguageCode.Hebrew;

  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
        flexDirection: isHebrew ? "row-reverse" : "row", // Change direction for Hebrew
      }}
    >
      <Sidebar />

      <MainAppBar />

      <Box sx={{ flexGrow: 1 }}>
        <Toolbar sx={{ height: "6.5rem" }} />

        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "fixed",
              height: "calc(100vh - 6.5rem)",
              left: 0,
              right: 0,
              background: "linear-gradient(#fafdfe, #def2ff)",
            }}
          ></Box>

          <Box sx={{ position: "relative" }}>
            <Suspense
              fallback={
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    height: "calc(100vh - 6.5rem)",
                  }}
                >
                  <Stack
                    sx={{
                      pb: 7,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <CircularProgress
                      size={"4.2857rem"}
                      thickness={32}
                      sx={{ color: "#c05ec6" }}
                    />
                    <Box height={"1.4286rem"} />
                    <Box
                      component="img"
                      src={mynkTextLogo}
                      sx={{ width: "8.5714rem" }}
                    />
                  </Stack>
                </Stack>
              }
            >
              <Outlet />
            </Suspense>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}