import { MynkFormControlCommonProps } from "./types";
import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useIsInEditable } from "../context";
import {BoxWithGradientFrame} from "../../GradientFrame";

export interface MynkFormTextFieldProps
  extends MynkFormControlCommonProps<string | number> {
  autoComplete?: boolean;
  multiLine?: boolean;
  isNum?: boolean;
  height?: number;
  onChange?: (e: React.ChangeEvent<any>, fieldName: string) => void;
}

export function MynkFormTextField(props: MynkFormTextFieldProps) {
  const formik = useFormikContext();

  const isInEditable = useIsInEditable();

  return (
    <Box>
      {!isInEditable && (
        <Typography
          variant="subtitle1"
        >
          {props.label}
        </Typography>
      )}

      <BoxWithGradientFrame>
        <TextField
          variant="standard"
          placeholder={props.placeholder?.toString() ?? ""}
          fullWidth
          autoComplete={
            props.autoComplete !== undefined
              ? props.autoComplete
                ? "on"
                : "off"
              : undefined
          }
          multiline={props.multiLine}
          sx={{
            mt: 1,
            height: props.multiLine
              ? props.height
                ? `${props.height / 14}rem`
                : "1rem"
              : "4rem",
            overflowY: props.multiLine ? "auto" : null, // Enable vertical scrollbar
          }}
          inputProps={{
            inputMode: props.isNum ? "numeric" : "text",
            pattern: props.isNum ? "[0-9]*[.]?[0-9]*" : undefined,
            style: {
              paddingTop: props.multiLine ? "0.5rem" : "0", // Add padding to top for multiline
              boxSizing: "border-box", // Ensure padding does not overflow the container
            },
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              display: "flex",
              fontFamily: "Helvetica Neue",
              alignItems: props.multiLine ? "flex-start" : "center", // Align text at the top for multiline
              height: "100%",
              pl: 1.5,
              mt: props.multiLine ? 0.5 : null,
              mb: 0.5,
            },
          }}
          {...(formik?.getFieldProps?.(props.name) ?? {
            name: props.name,
            value: "",
          })}
          onChange={(e) => {
            if (props.onChange) props.onChange(e, props.name);

            formik.setFieldValue(props.name, e.target.value);
          }}
        />
      </BoxWithGradientFrame>
    </Box>
  );
}
