import { useParams } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { MynkTab } from "../../../../components/MynkPage";
import ViewGeneralLayout from "../../../../components/ViewProjectOrContact/ViewGeneralLayout";
import { LowerCard, UpperCard } from "../common/cards";
import {
  useDeleteProjectNoteMutation,
  useGetProjectQuery,
} from "../../../../api/projects";
import { Box, Card, Stack, Typography } from "@mui/material";
import AddProjectNoteDialog from "../../../../dialogs/AddProjectNote";
import {
  DeleteProjectNoteParams,
  Note,
  PhotographyProject,
} from "../../../../api/types";
import ThreeDotsButton from "../../../../components/ThreeDotsButton";
import ConfirmActionDialog from "../../../../components/ConfirmActionDialog";
import { atom, useAtom } from "jotai";
import ProfilePicture from "../../../../components/ProfilePicture";
import { TwoActionButtonsViewProject } from "./General";
import { zonedDate } from "../../../../util/common";
import { PhotoType } from "../../../CommonComponents/Settings/common/UploadOnePhoto";

const selectedNoteAtom = atom<Note | null>(null);
const openedEditAtom = atom(false);
const openedDeleteAtom = atom(false);

enum threeDotsOptions {
  EDIT = "edit-note",
  DELETE = "delete-note",
}

const DOTS_MENU = [
  {
    id: threeDotsOptions.EDIT,
    label: "Edit",
  },
  {
    id: threeDotsOptions.DELETE,
    label: "Delete",
  },
];

interface ThreeDotsNoteProps {
  note: Note;
  project: PhotographyProject;
}

/**
 * Renders a button with three dots. When the button is clicked, it opens a menu with options to edit or delete a task.
 * Also renders two dialog components, `ConfirmDeleteDialog` and `AddPhotographyContactDialog`, which are opened when the corresponding menu options are selected.
 *
 * @returns The rendered `ThreeDotsNote` component with a button and two dialog components.
 */
function ThreeDotsNote(props: ThreeDotsNoteProps) {
  const [selectedNote, setSelectedNote] = useAtom(selectedNoteAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);

  /**
   * Handles the click event on the menu options.
   * Sets the corresponding state variable to `true` to open the corresponding dialog component.
   *
   * @param id - The ID of the clicked menu option.
   */
  const handleClickItem = (id: string) => {
    setSelectedNote(props.note);

    switch (id) {
      case threeDotsOptions.EDIT:
        setOpenedEdit(true);
        break;
      case threeDotsOptions.DELETE:
        setOpenedDelete(true);
        break;
    }
  };

  return (
    <ThreeDotsButton
      menu={DOTS_MENU}
      onClickItem={handleClickItem}
      children={undefined}
    ></ThreeDotsButton>
  );
}

interface NoteBoxProps {
  note: Note;
  project: PhotographyProject;
  onStartEdit?: (note: Note) => void;
  onDelete?: (note: Note) => void;
}

function NoteBox(props: NoteBoxProps) {
  const [showFullText, setShowFullText] = useState(false); // Toggle for showing full text
  const createdAt = useMemo(
    () => zonedDate(props.note.created_at),
    [props.note.created_at]
  );

  const handleToggleText = () => setShowFullText((prev) => !prev);

  return (
    <>
      <Card
        sx={{
          p: 2,
          borderRadius: "1.3rem !important",
          position: "relative",
        }}
      >
        <Stack direction="row" spacing={0.3} sx={{ position: "relative" }}>
          <Stack>
            <ProfilePicture type={PhotoType.PROFILE} size="3.3rem" />
          </Stack>
          <Box sx={{ pl: 2, flex: 1 }}>
            {/* Note Title */}
            <Typography
              fontSize="1.4rem"
              fontWeight="bold"
              color="black"
              mb={1}
            >
              {props.note.title}
            </Typography>

            {/* Note Text */}
            <Typography
              fontSize="1.27rem"
              whiteSpace="pre-wrap"
              color="black"
              textAlign="justify"
            >
              {showFullText
                ? props.note.text
                : props.note.text.split("\n")[0]} {/* Show only the first line */}
            </Typography>

            {!showFullText && props.note.text.includes("\n") && (
              <Typography
                variant="body2"
                color="#75b3ff"
                sx={{ cursor: "pointer", mt: 1 }}
                onClick={handleToggleText}
              >
                ... Show more
              </Typography>
            )}

            {showFullText && (
              <Typography
                variant="body2"
                color="#75b3ff"
                sx={{ cursor: "pointer", mt: 1 }}
                onClick={handleToggleText}
              >
                Show less
              </Typography>
            )}

            {/* Creation Date */}
            <Typography variant="body2" sx={{ mt: 1.2 }}>
              <b style={{ color: "#404040" }}>
                {createdAt ? createdAt.toFormat("EEEE") : "Invalid date"}
              </b>
              ,{" "}
              <span style={{ color: "#404040b2" }}>
                {createdAt && createdAt.toFormat("LLL dd, yyyy")}
              </span>
            </Typography>
          </Box>

          <Box>
            <ThreeDotsNote
              note={props.note}
              project={props.project}
            ></ThreeDotsNote>
          </Box>
        </Stack>
      </Card>
    </>
  );
}

interface NoteStackProps {
  project: PhotographyProject;
  onStartEdit?: (note: Note) => void;
  onDelete?: (note: Note) => void;
}

function NoteStack(props: NoteStackProps) {
  const [selectedNote, setSelectedNote] = useAtom(selectedNoteAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);

  const deleteNote = useDeleteProjectNoteMutation();

  const handleDeleteNote = async (project_uuid: string, note_uuid: string) => {
    const params = {
      project_uuid: project_uuid,
      note_uuid: note_uuid,
    } as DeleteProjectNoteParams;

    await deleteNote.mutateAsync(params);
    setOpenedDelete(false);
  };

  return (
    <>
      <ConfirmActionDialog
        open={openedDelete}
        what="note"
        whatAction="delete"
        onClose={() => setOpenedDelete(false)}
        onConfirm={() =>
          handleDeleteNote(
            props.project.uuid,
            selectedNote ? selectedNote.uuid : ""
          )
        }
        loading={deleteNote.isPending}
      >
        Delete
      </ConfirmActionDialog>

      <AddProjectNoteDialog
        open={openedEdit}
        onClose={() => setOpenedEdit(false)}
        projectId={props.project?.uuid}
        editNote={selectedNote}
      />
      {props.project.notes.length > 0 && (
        <Stack spacing={2} sx={{ p: 2, px: 3 }}>
          {props.project.notes.map((note) => (
            <NoteBox
              key={note.uuid}
              note={note}
              project={props.project}
              onStartEdit={props.onStartEdit}
              onDelete={props.onDelete}
            />
          ))}
        </Stack>
      )}
    </>
  );
}

interface MainContentCardProps {
  project?: PhotographyProject;
}

function MainContentCard(props: MainContentCardProps) {
  const [newNoteOpen, setNewNoteOpen] = useState(false);
  const handleNewNote = () => {
    setEditNote(null);
    setNewNoteOpen(true);
  };

  const [editNote, setEditNote] = useState<null | Note>(null);
  const handleStartEditNote = (note: Note) => {
    setEditNote(note);
    setNewNoteOpen(true);
  };

  const deleteNote = useDeleteProjectNoteMutation();
  const handleDeleteNote = async (note: Note) => {
    if (!props.project?.uuid) return;

    await deleteNote.mutateAsync({
      project_uuid: props.project.uuid,
      note_uuid: note.uuid,
    });
  };

  return (
    <Card sx={{ py: 2, height: "51.5rem", overflowY: "auto" }}>
      <AddProjectNoteDialog
        open={newNoteOpen}
        onClose={() => setNewNoteOpen(false)}
        projectId={props.project?.uuid}
        editNote={editNote}
      />

      <Typography
        variant="h4"
        fontFamily="Helvetica Neue"
        sx={{ px: 3, pb: 1 }}
      >
        Notes
      </Typography>

      {props.project && (
        <NoteStack
          project={props.project}
          onStartEdit={handleStartEditNote}
          onDelete={handleDeleteNote}
        />
      )}

      <Typography
        variant="body2"
        sx={{ color: "#75b3ff", cursor: "pointer", mt: 2 }}
        align="center"
        onClick={handleNewNote}
      >
        + New note
      </Typography>
    </Card>
  );
}

export default function ViewPhotographyProjectNotesSubpage() {
  const { projectId } = useParams();
  const { data, isPending } = useGetProjectQuery(
    { project_uuid: projectId ?? "" },
    Boolean(projectId)
  );
  const project = data?.project as PhotographyProject;

  const { timelineItems } = useMemo(() => {
    return {
      timelineItems: [],
    };
  }, []);

  return (
    <MynkTab
      title="Notes"
      action={<TwoActionButtonsViewProject project={project} />}
    >
      <ViewGeneralLayout
        mainContent={
          <MainContentCard project={data?.project as PhotographyProject} />
        }
        upperCard={
          data?.project && (
            <UpperCard project={data.project as PhotographyProject} />
          )
        }
        lowerCard={
          data?.project && (
            <LowerCard project={data.project as PhotographyProject} />
          )
        }
      />
    </MynkTab>
  );
}
