import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  SxProps,
  TextField,
} from "@mui/material";

interface MynkSelectBoxProps {
  title?: string;
  sx?: SxProps;
  options?: string[] | any[];
  value?: any;
  handleChange?: (newVal: any) => void;
  svgIcon?: string;
}

export function MynkSelectBox(componentProps: MynkSelectBoxProps) {
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => {
    if (componentProps.handleChange) {
      if (componentProps.title === "Country/Region") {
        componentProps.handleChange(value.label);
      } else {
        componentProps.handleChange(value);
      }
    }
  };

  return (
    <Autocomplete
      value={componentProps.value}
      options={componentProps.options ?? []}
      onChange={handleChange}
      sx={{
        display: "flex",
        alignItems: "center", // Vertically center the input
        height: "3.3rem",
        ".MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        ".css-fbfx27-MuiFormLabel-root-MuiInputLabel-root": {
          top: "-15%",
        },
        fontFamily: "Helvetica Neue",
        m: "0.5rem",
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            display: "flex",
            alignItems: "center", // Ensure input content is centered vertically
            justifyContent: "center", // Horizontally center the input text
            bgcolor: "#E7F4FF",
            borderRadius: "0.6rem",
            ...componentProps.sx,
            width: "13rem",
            height: "3.3rem", // Ensure full height for proper vertical alignment
            ml: "0.5rem",
          }}
        />
      )}
      renderOption={(props, option) =>
        componentProps.title === "Country/Region" ? (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
              alt=""
            />
            {option.label}
          </Box>
        ) : (
          <Box component="li" {...props}>
            {option}
          </Box>
        )
      }
    />
  );
}
