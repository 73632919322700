import React, {ReactNode, useEffect, useMemo, useRef, useState} from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import {useBlocker, useNavigate} from "react-router-dom";
import MynkPageHeader from "../../../components/MynkPage/MynkPageHeader";
import {atom, useAtom, useAtomValue, useSetAtom} from "jotai";
import expandIcon from "../../../assets/icons/expand-icon.svg";
import threeDotsIcon from "../../../assets/icons/three-dots-icon.svg";
import {
  makeEmptyDomState,
  TemplateData,
  templateDataChangedAtom,
  TemplateRef,
  templateSentAtom,
  useCurrentTemplate,
} from "./common";
import {TemplateStyle} from "./TemplateStyle";
import {Template, wantToRenderAtom} from "./Template";
import {useSaveTemplateMutation, useSetDefaultTemplateMutation,} from "../../../api/templates";
import GenericDetails from "./GenericDetails";
import {closeSnackbar, useSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {makePath, PATHS, WorkflowPath} from "../../../paths";
import zoomIn from "../../../assets/icons/zoom-in.svg";
import zoomOut from "../../../assets/icons/zoom-out.svg";
import ConfirmActionDialog from "../../../components/ConfirmActionDialog";
import jsPDF from "jspdf";
import {useListGeneralSettingsQuery} from "../../../api/settings";
import {TemplateContext, useTemplateAtoms} from "./TemplateContext";
import {useDownloadTemplatePDF} from "./downloadTemplate";
import EditClauses from "./EditClauses";
import { MynkForm } from "../../../components/MynkForm";
import DialogActionButton from "../../../components/DialogActionButton";
import { useGetClientQuery } from "../../../api/clients";
import { useGetProjectQuery } from "../../../api/projects";
import { MynkFormControl } from "../../../components/MynkForm";
import { MynkFormControlKind } from "../../../components/MynkForm/controlTypes";
import { WorkflowType } from "../../../api/types";

const wantSaveTemplateAtom = atom<boolean>(false);
const wantSetDefaultAtom = atom<boolean>(false);

const TABS = [{title: "Details"}, {title: "Style"}, {title: "Layout"}];

interface DoubleElevatorProps {
  sx?: SxProps;
  children?: ReactNode;
}

function DoubleElevator(props: DoubleElevatorProps) {
  return (
    <Box
      sx={{
        bgcolor: "#ffffff66",
        border: "solid 3px white",
        borderRadius: "1.1429rem",
        boxShadow: "0 0 20px #75b3ff33",
        p: 3,
        ...props.sx,
      }}
    >
      <Box
        sx={{
          background: "linear-gradient(#ffffff 0%, #e0f3ff 100%)",
          borderRadius: "16px",
          boxShadow: "0 0 14px #75b3ff4c",
          p: 4,
          px: 8,
          maxHeight: '40rem',
          overflowY: 'auto',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}

function emptyTemplateData(initialData: null | any): TemplateData {
  return {
    updates: 0,
    data: initialData ?? {},
  };
}

function DbgTestRecords() {
  const templateAtoms = useTemplateAtoms();
  const records = useAtomValue(templateAtoms.recordsAtom);

  return (
    <Typography variant="body2" sx={{mt: 3}}>
      {JSON.stringify(records)}
    </Typography>
  );
}

interface PreviewDialogProps {
  open: boolean;
  onClose: () => void;
}

function PreviewDialog(props: PreviewDialogProps) {
  const template = useCurrentTemplate();
  const templateRef = useRef<null | TemplateRef>(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!ready || !templateRef?.current) return;

    (async () => {
      // const result = await templateRef.current!.renderTemplate();
      //
      // // result.data is base64 encoded png
      // // download it
      // const a = document.createElement('a');
      // a.href = result!.data;
      // a.download = 'template.png';
      // a.click();
    })();
  }, [ready]);

  useEffect(() => {
    setReady(props.open);
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 0,
        },
      }}
    >
      <DialogContent
        sx={{
          width: "52rem",
          padding: 0,
        }}
      >
        {template?.html && (
          <TemplateContext global>
            <Template
              template={template.html}
              sx={{width: "100%", height: "100%"}}
              ref={templateRef}
            />
          </TemplateContext>
        )}
      </DialogContent>
    </Dialog>
  );
}


interface TopToolbarProps {
  previewWidthState?: [number, React.Dispatch<React.SetStateAction<number>>];
}

const MIN_WIDTH = 30;
const MAX_WIDTH = 60;
const INITIAL_WIDTH = 50;
const WIDTH_STEP = 5;

function TopToolbar(props: TopToolbarProps) {
  const [open, setOpen] = useState(false);
  const [wantToRender, setWantToRender] = useAtom(wantToRenderAtom);
  const [previewWidth, setPreviewWidth] = props.previewWidthState ?? [];
  const [lastWidthSelected, setLastWidthSelected] = useState(INITIAL_WIDTH);

  useEffect(() => {
    if (setPreviewWidth) {

      if (wantToRender) {
        setLastWidthSelected(previewWidth ?? INITIAL_WIDTH);

      } else {
        setPreviewWidth(lastWidthSelected);
      }
    }
  }, [wantToRender]);

  const handleOpenPreview = () => {
    setOpen(true);
  };

  const moreRef = useRef<null | HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMore = () => {
    setAnchorEl(moreRef.current!);
  };

  const [wantSave, updateWantSave] = useAtom(wantSaveTemplateAtom);
  const handleSaveTemplate = () => {
    updateWantSave(true);
    setAnchorEl(null);
  };

  const {enqueueSnackbar} = useSnackbar();
  const template = useCurrentTemplate();
  const [wantSetDefault, updateWantSetDefault] = useAtom(wantSetDefaultAtom);
  const handleSetDefault = async () => {
    updateWantSetDefault(true);
    setAnchorEl(null);
  };


  // const haveChanges = useAtomValue(templateStylingOrDataChangedAtom);

  return (
    <Stack direction="row" sx={{alignItems: "center"}}>
      <PreviewDialog open={open} onClose={() => setOpen(false)}/>

      {/*{haveChanges && (*/}
      {/*  <Typography sx={{mr: 3, color: 'text.secondary'}}>*/}
      {/*    Modified*/}
      {/*  </Typography>*/}
      {/*)}*/}

      <Stack sx={{
        borderRadius: "0.6rem",
        bgcolor: "#f0f8ff",
        mr: "0.7rem",
        width: "6.7rem",
        height: "2.3rem",
        alignItems: "center",
        alignContent: "center",
        display: "flex",
        justifyContent: "center"
      }} direction="row" spacing="0.6rem">
        <IconButton onClick={() => {
          if (setPreviewWidth && previewWidth) {
            if (previewWidth < MAX_WIDTH) {
              setPreviewWidth((previewWidth ?? INITIAL_WIDTH) + WIDTH_STEP)
            }
          }
        }}>
          <Box component="img" src={zoomIn} sx={{width: "1.27rem"}}/>
        </IconButton>
        <Divider orientation="vertical"/>
        <IconButton onClick={() => {
          if (setPreviewWidth && previewWidth) {
            if (previewWidth > MIN_WIDTH) {
              setPreviewWidth((previewWidth ?? INITIAL_WIDTH) - WIDTH_STEP)
            }
          }
        }}>
          <Box component="img" src={zoomOut} sx={{width: "1.27rem"}}/>
        </IconButton>
      </Stack>

      <IconButton sx={{width: "3rem", height: "3rem"}} onClick={handleOpenPreview}>
        <Box sx={{width: "3rem", height: "3rem"}} component="img" src={expandIcon}/>
      </IconButton>

      {(wantSave || wantSetDefault) ? (
        <CircularProgress size="2rem" sx={{ml: 1, mr: 2}}/>
      ) : (
        <IconButton sx={{width: "3rem", height: "3rem"}} ref={moreRef} onClick={handleMore}>
          <Box sx={{width: "3rem", height: "3rem"}} component="img" src={threeDotsIcon}/>
        </IconButton>
      )}

      <Menu
        id="template-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleSetDefault} disabled={wantSetDefault}>
          {wantSetDefault ? 'Setting as default template...' : 'Set as default template'}
        </MenuItem>
        <MenuItem onClick={handleSaveTemplate} disabled={wantSave}>
          {wantSave ? 'Downloading...' : 'Download'}
        </MenuItem>
      </Menu>
    </Stack>
  );
}

function ContractFirstPage() {
  return (
    <Stack>
      <Box mt={3.5}>
        <MynkFormControl
          kind={MynkFormControlKind.CONTACT_SEARCH}
          name="client"
          label="Client"
          placeholder="Enter client"
          default=""
          workflowType={WorkflowType.PHOTOGRAPHY}
        />
      </Box>

      <Box mt={1.5}>
        <MynkFormControl
          kind={MynkFormControlKind.PROJECT_SEARCH}
          name="project"
          label="Project"
          placeholder="Enter project"
          default=""
          workflowType={WorkflowType.PHOTOGRAPHY}
        />
      </Box>

      <Typography mt={3.5}>
        <b>Disclaimer:</b><br/>
        This document is provided as a template and for informational purposes only.We strongly recommend that you consult with a
        licensed attorney in your jurisdiction to ensure that this contract meets your specific requirements and complies with local regulations.
      </Typography>
    </Stack>
  )
}

enum TemplateTabValue {
  DETAILS = 0,
  STYLE = 1,
  LAYOUT = 2,
}


function TemplateModificationGuard() {
  const haveChanges = useAtomValue(templateDataChangedAtom);
  const [templateSent, updateTemplateSent] = useAtom(templateSentAtom);

  const proceed = useRef(false);
  const [showDialog, setShowDialog] = useState(false);
  const [destination, setDestination] = useState<null | string>(null);

  useBlocker(({nextLocation}) => {
    if (proceed.current || !haveChanges) return false;

    if (templateSent) {
      // user just sent template, do not block
      updateTemplateSent(false);
      return false;
    }

    setDestination(nextLocation.pathname);
    setShowDialog(true);
    return true;
  });

  const navigate = useNavigate();
  const handleConfirm = () => {
    if (destination) {
      proceed.current = true;
      setShowDialog(false);
      navigate(destination);
    }
  };

  return (
    <>
      <ConfirmActionDialog
        text="You have unsaved changes&Are you sure you want to discard them?"
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onConfirm={handleConfirm}
        loading={false}
      >
        Discard
      </ConfirmActionDialog>
    </>
  );
}


function InstantiateTemplatePageBody() {
  const template = useCurrentTemplate();
  const templateAtoms = useTemplateAtoms();

  const navigate = useNavigate();
  useEffect(() => {
    if (template === null)
      navigate(makePath(WorkflowPath.PHOTOGRAPHY, PATHS.templates.all));
  }, [template]);

  const updateTemplateData = useSetAtom(templateAtoms.dataAtom);

  // clear styling/dom changes
  const updateDomStateRaw = useSetAtom(templateAtoms.rawDomStateAtom);
  const [records, updateRecords] = useAtom(templateAtoms.recordsAtom);
  useEffect(() => {
    if (template) {
      updateDomStateRaw(makeEmptyDomState());
      updateRecords(template.records ?? []);
      templateRef.current?.rerender();
    }
  }, [template]);

  // clear items / set initial template data
  const [ready, setReady] = useState(false);
  const [initialTemplateDetails, updateInitialTemplateDetails] = useAtom(
    templateAtoms.initialDetailsAtom
  );
  useEffect(() => {
    if (!template) return;

    updateTemplateData(emptyTemplateData(initialTemplateDetails));
    updateInitialTemplateDetails(null);
    setReady(true);
  }, [template]);

  const [currentTab, setCurrentTab] = useState(TemplateTabValue.DETAILS);
  const updateDomState = useSetAtom(templateAtoms.domStateAtom);
  const handleTabChange = (t: number) => {
    setCurrentTab(t);
    updateDomState({
      canDrag: t === TemplateTabValue.LAYOUT,
    });
  };

  // get settings for invoice number
  const {data: generalSettingsData} = useListGeneralSettingsQuery({});
  const invoiceNumber = useMemo(() => {
    return ((generalSettingsData?.invoice_start_number ?? 0) + 1)
      .toString()
      .padStart(4, "0");
  }, [generalSettingsData]);

  const templateRef = useRef<null | TemplateRef>(null);
  const [wantSave, updateWantSave] = useAtom(wantSaveTemplateAtom);
  const saveTemplate = useSaveTemplateMutation();
  const {enqueueSnackbar} = useSnackbar();
  const downloadPdfName = useMemo(() =>
    template ? `${template.kind.charAt(0).toUpperCase()}${template.kind.slice(1)}-${invoiceNumber}.pdf` : 'file.pdf',
    [template, invoiceNumber]
  );
  const downloadPdf = useDownloadTemplatePDF(downloadPdfName, template?.uuid);
  useEffect(() => {
    if (!wantSave || !template || !templateRef.current) return;

    //
    // download as PDF
    //
    (async () => {
      await downloadPdf();
      updateWantSave(false);
    })();
  }, [wantSave, downloadPdfName]);

  const [wantSetDefault, updateWantSetDefault] = useAtom(wantSetDefaultAtom);
  const setDefaultTemplate = useSetDefaultTemplateMutation();
  useEffect(() => {
    if (!wantSetDefault || !template || !templateRef.current) return;

    //
    // first save the template
    //
    (async () => {
      const result = await saveTemplate.mutateAsync({
        base_uuid: template.uuid,
        update: false,
        records,
      });

      const resultUuid = result?.uuid;
      if (!resultUuid)
        // TODO: error
        return;

      await setDefaultTemplate.mutateAsync({
        template_uuid: resultUuid,
      });

      enqueueSnackbar("Template set as default", {
        variant: "success",
        action: (key) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <CloseIcon fontSize="small"/>
          </IconButton>
        ),
        style: {
          borderRadius: "0.7rem",
        },
      });

      updateWantSetDefault(false);
    })();
  }, [wantSetDefault])

  const updateTemplateRef = useSetAtom(templateAtoms.refAtom);
  const [previewWidth, setPreviewWidth] = useState(INITIAL_WIDTH);

  const [isSecondPage, setIsSecondPage] = useState<boolean>(false);
  const [contractClientUuid, setContractClientUuid] = useState(null);
  const [contractProjectUuid, setContractProjectUuid] = useState(null);

  const { data: clientData } = useGetClientQuery({ uuid: contractClientUuid ?? "" });
  const { data: projectData } = useGetProjectQuery({ project_uuid: contractProjectUuid ?? "" });
  
  const handleContractContinue = (values: any) => {
    if (!values.client || !values.project) return;

    setContractClientUuid(values.client);
    setContractProjectUuid(values.project);
    setIsSecondPage(true);
  };

  return (
    <Stack sx={{height: "calc(100vh - 4rem)"}} direction="row">
      <Box
        sx={{
          flex: 2,
          p: "4rem",
          pt: "14rem",
          display: currentTab === TemplateTabValue.LAYOUT ? "none" : "block",
        }}
      >
        <DoubleElevator>
          <Box
            sx={{
              display:
                currentTab !== TemplateTabValue.STYLE ? "block" : "none",
            }}
          >
            {/*<InvoiceDetails/>*/}
            {ready && currentTab !== TemplateTabValue.LAYOUT && !isSecondPage && template?.kind === 'contract' && (
              <MynkForm
                onSubmit={handleContractContinue}
              >
                <Typography variant="h2" fontFamily="Helvetica Neue">
                  Contract Details
                </Typography>

                <ContractFirstPage />
    
                <Box mt={8} mb={-2} ml={'32%'}>
                  <DialogActionButton type='submit'>
                    Continue
                  </DialogActionButton>
                </Box>
              </MynkForm>
            )}

            {ready && 
              (
                <>
                  <Box
                    sx={{
                      display: currentTab !== TemplateTabValue.LAYOUT && (isSecondPage || template?.kind !== 'contract') ? "block" : "none",
                    }}
                  >
                    <GenericDetails clientData={clientData} projectData={projectData}/>
                  </Box>
                  <Box
                    sx={{display: currentTab === TemplateTabValue.LAYOUT ? "block" : "none"}}
                  >
                    <EditClauses />
                  </Box>
                </>
              )
            }
          </Box>

          <Box
            sx={{
              display: currentTab === TemplateTabValue.STYLE ? "block" : "none",
            }}
          >
            <TemplateStyle/>
          </Box>
        </DoubleElevator>

        {/*<DbgTestRecords />*/}
      </Box>

      <Stack
        sx={{
          flex: 3,
          borderLeft: "solid 1px #69b3ff40",
          pt: 12,
          position: "relative",
        }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box sx={{position: "absolute", top: 32, right: 16}}>
          <TopToolbar previewWidthState={[previewWidth, setPreviewWidth]}/>
        </Box>

        {template === undefined && <CircularProgress/>}

        {(template === null || (template && !template?.html)) && (
          <Alert severity="error">Template content not available.</Alert>
        )}

        {ready && template?.html && (
            <Template
              live={!wantSave}
              template={template.html}
              ref={templateRef}
              onMakeRef={(ref) => {
                console.log("got ref", ref);
                updateTemplateRef(ref);
              }}
              realSize={{width: "50rem", height: "70.5rem"}}
              sx={{
                width: `${previewWidth}rem`,
                transformOrigin: "top",
                transition: "width 0.1s linear",
                "::-webkit-scrollbar": {
                  display: "none"
                },
              }}
            />
        )}
      </Stack>

      <Box sx={{position: "absolute", left: 32, top: 32}}>
        <MynkPageHeader
          title={`New ${template ? template.kind : "..."}`}
          backLink={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.templates.all)}
          selectedTabIndex={currentTab}
          onTabChange={handleTabChange}
          tabs={TABS}
        />
      </Box>

      <TemplateModificationGuard/>
    </Stack>
  );
}


export default function InstantiateTemplatePage() {
  return (
    <TemplateContext global>
      <InstantiateTemplatePageBody />
    </TemplateContext>
  )
}

