import { MynkTab } from "../../../../components/MynkPage";
import ViewGeneralLayout, {
  TimelineColumn,
  TimelineItem,
} from "../../../../components/ViewProjectOrContact/ViewGeneralLayout";
import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import PriorityChip from "../../../../components/PriorityChip";
import {
  ClientSource,
  PhotographyClientInfo,
  PhotographyProject,
  Priority,
} from "../../../../api/types";
import { formatMoney, zonedDate } from "../../../../util/common";
import { AddPhotographyProjectDialog } from "../../../../dialogs/AddPhotographyProject";
import { useListProjectsQuery } from "../../../../api/projects";
import ActionMenuButton, {
  ActionMenuButtonVariant,
} from "../../../../components/ActionMenuButton";
import { Add, Tune } from "@mui/icons-material";
import EditContactDialog from "../../../../dialogs/EditContact";
import {
  useSetClientPriority,
  useSetClientSource,
} from "../../../../api/clients";
import { useCurrentContact } from "../common";
import { PATHS, WorkflowPath, makePath } from "../../../../paths";
import { useAtom, useAtomValue } from "jotai";
import { currentWorkflowAtom } from "../../../../components/MainAppBar/UserControlPopup";
import { userCurrencyAtom } from "../../../../layouts/Main";
import AddClientNoteDialog from "../../../../dialogs/AddClientNote";
import AddTaskDialog from "../../../../dialogs/AddTask";

export function UpperDetailsCard() {
  const client = useCurrentContact();
  const currencyCode = useAtomValue(userCurrencyAtom);

  return (
    <Box>
      <Typography sx={{ fontFamily: "Helvetica Neue" }} variant="h4">
        Details
      </Typography>

      {client === undefined && (
        <CircularProgress sx={{ display: "table", mx: "auto", mt: 2 }} />
      )}

      {client && (
        <Stack sx={{ px: 2.3, pt: 2.9, pb: 1.2 }} spacing={2.55}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Name:</Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {client.full_name}
            </Typography>
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Email:</Typography>
            <Typography variant="body2">{client.email || "N/A"}</Typography>
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Phone:</Typography>
            <Typography variant="body2">{client.phone || "N/A"}</Typography>
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Birth Date: </Typography>
            <Typography
              variant="body2"
            >
              &nbsp;{client.birth_date ? zonedDate(client.birth_date).toFormat("LLL dd, yyyy") : "N/A"}
            </Typography>
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Address:</Typography>
            <Typography variant="body2">{client.address || "N/A"}</Typography>
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Instagram:</Typography>
            <Typography
              variant="body2"
            >
              {client.instagram || "N/A"}
            </Typography>
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Website:</Typography>
            <Typography variant="body2">{client.website || "N/A"}</Typography>
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Facebook:</Typography>
            <Typography variant="body2">{client.facebook || "N/A"}</Typography>
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Total spent:</Typography>
            <Typography fontWeight="bold" fontSize="1.45rem" color="black">
              {formatMoney(client.total_spent, currencyCode)}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Box>
  );
}

export function LowerDetailsCard() {
  const client = useCurrentContact();
  const setClientPriority = useSetClientPriority();

  const { createdAt, lastPurchase } = useMemo(() => {
    if (!client) return {};

    return {
      createdAt: zonedDate(client.created_at),
      lastPurchase: zonedDate(client.last_purchase),
    };
  }, [client]);

  if (!client) {
    return <CircularProgress sx={{ display: "table", mx: "auto", mt: 2 }} />;
  }

  const handleChangePriority = (value: string) => {
    setClientPriority.mutate({
      uuid: client?.uuid ?? "",
      priority: value as Priority,
    });
  };
  
  return (
    <Stack sx={{ px: 2.3, pt: 2.9, pb: 1.2 }} spacing={2.55}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Typography variant="body2">Priority:</Typography>
        <PriorityChip
          value={client.priority}
          onChange={handleChangePriority}
          sx={{
            width: "7.7rem",
            height: "2.5rem"
          }}
        />
      </Stack>

      <Divider />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Typography variant="body2">Lead source:</Typography>
        <Typography variant="body2">
          {(client.source.charAt(0).toUpperCase() + client.source.substring(1)).replace("_", " ") || "N/A"}
        </Typography>
      </Stack>

      <Divider />

      {createdAt && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body2">Contact created:</Typography>
            <Typography variant="body2">
              <b style={{ color: "#404040" }}>{createdAt ? createdAt.toFormat("EEEE") : "Invalid date"}</b>,{" "}
              <span style={{ color: "#404040b2" }}>
                {createdAt ? createdAt.toFormat("LLL dd, yyyy") : "Invalid date"}
              </span>
            </Typography>
          </Stack>
          <Divider /> 
        </>
      )}

      {lastPurchase && !isNaN(lastPurchase) && (
        <>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
          paddingTop={1}
        >
          <Typography variant="body2">Last purchase:</Typography>
          <Typography variant="body2">
            <b style={{ color: "#404040" }}>{lastPurchase ? lastPurchase.toFormat("EEEE") : "Invalid date"}</b>,{" "}
            <span style={{ color: "#404040b2" }}>
              {lastPurchase ? lastPurchase.toFormat("LLL dd, yyyy") : "Invalid date"}
            </span>
          </Typography>
        </Stack>
        <Divider /> 
        </>
      )}


      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Typography variant="body2">No. projects:</Typography>
        <Typography variant="body2">{client.projects_amount}</Typography>
      </Stack>
    </Stack>
  );
}

const TIMELINE_COLUMNS = [
  TimelineColumn.PROJECT,
  TimelineColumn.DATE,
  TimelineColumn.PRICE,
  TimelineColumn.VIEW_PROJECT,
];

enum NewMenuIds {
  NEW_TASK = "new_task",
  NEW_NOTE = "new_note",
  NEW_PROJECT = "new_project",
}

const NEW_MENU = [
  {
    id: NewMenuIds.NEW_TASK,
    label: "New task",
  },
  {
    id: NewMenuIds.NEW_NOTE,
    label: "New note",
  },
  {
    id: NewMenuIds.NEW_PROJECT,
    label: "New project"
  }
];

interface TwoActionButtonsViewContactProps {
  client?: PhotographyClientInfo | null;
}

export function TwoActionButtonsViewContact(
  props: TwoActionButtonsViewContactProps
) {
  const [newNoteOpen, setNewNoteOpen] = useState(false);
  const [newTaskOpen, setNewTaskOpen] = useState(false);
  const [newProjectOpen, setNewProjectOpen] = useState(false);
  const [editClientOpen, setEditClientOpen] = useState(false);

  const handleClickItem = (id: string) => {
    switch (id) {
      case NewMenuIds.NEW_NOTE:
        setNewNoteOpen(true);
        break;
      case NewMenuIds.NEW_TASK:
        setNewTaskOpen(true);
        break;
      case NewMenuIds.NEW_PROJECT:
        setNewProjectOpen(true);
        break;
    }
  };
  return (
    <Stack direction="row" alignItems="center">
      <AddClientNoteDialog
        open={newNoteOpen}
        onClose={() => setNewNoteOpen(false)}
        clientId={props.client?.uuid}
      />
      <AddTaskDialog
        open={newTaskOpen}
        onClose={() => setNewTaskOpen(false)}
        client_uuid={props.client?.uuid}
      />
      <AddPhotographyProjectDialog
        open={newProjectOpen}
        onClose={() => setNewProjectOpen(false)}
        contactId={props.client?.uuid}
      />
      <EditContactDialog open={editClientOpen} onClose={() => setEditClientOpen(false)} contact={props.client} />
      <ActionMenuButton
        startIcon={<Tune sx={{ mr: 1 }} />}
        variant={ActionMenuButtonVariant.SECONDARY}
        sx={{
          mb: -9,
          mr: 2,
        }}
        onClickItem={() => setEditClientOpen(true)}
      >
        Edit
      </ActionMenuButton>

      <ActionMenuButton
        startIcon={<Add sx={{ width: "1.1rem" }} />}
        menu={NEW_MENU}
        onClickItem={handleClickItem}
        sx={{
          mb: -9,
        }}
      >
        New
      </ActionMenuButton>
    </Stack>
  );
}

export default function ViewPhotographyContactGeneralSubpage() {
  const [newProjectOpen, setNewProjectOpen] = useState(false);
  const onTimelineAction = () => {
    setNewProjectOpen(true);
  };

  const currentWorkflow = useAtomValue(currentWorkflowAtom);
  const contact = useCurrentContact();

  const { data, isPending } = useListProjectsQuery(
    {
      client_uuid: contact?.uuid ?? "",
      page_size: 10,
    },
    Boolean(contact)
  );
  
  const timelineItems: undefined | TimelineItem[] = useMemo(() => {
    if (isPending || !data || !currentWorkflow) return undefined;
    const projects = data.projects as PhotographyProject[];

    return projects.map((project) => {
      const date = zonedDate(project.date);
        
      return {
        uuid: project.uuid,
        title: project.name,
        date,
        stage: project.stage,
        price: project.price,
        viewPath: makePath(
          `/${currentWorkflow.type}` as WorkflowPath,
          PATHS.viewProject(project.uuid).general
        ),
      };
    });
  }, [data, isPending, currentWorkflow]);

  const [editContactOpen, setEditContactOpen] = useState(false);

  return (
    <MynkTab
      title="General"
      action={
        <TwoActionButtonsViewContact client={contact} />
      }
    >

      <AddPhotographyProjectDialog
        open={newProjectOpen}
        onClose={() => setNewProjectOpen(false)}
        contactId={contact?.uuid}
      />

      <ViewGeneralLayout
        timeline={{
          timelineTitle: "Projects",
          timelineItems,
          timelineColumns: TIMELINE_COLUMNS,
          timelineAction: "+ New project",
          onTimelineActionClick: onTimelineAction,
          columnDivision: ["35%", "24%"],
          isProjectTimeline: true
        }}
        upperCard={<UpperDetailsCard />}
        lowerCard={<LowerDetailsCard />}
      />
    </MynkTab>
  );
}
