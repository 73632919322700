import { Box, Stack } from "@mui/material";

import loginBackdrop from "../assets/images/login-backdrop.svg";
import loginBackdropOntop from "../assets/images/login-backdrop-ontop.png";
import mynkWhite from "../assets/images/mynk-white.svg";
import ElevatorCard from "../components/ElevatorCard";
import { Outlet } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { checkIsMobile } from "../util/mobile";

interface LoginLayoutProps {
  noElevator?: boolean;
}

export default function LoginLayout(props: LoginLayoutProps) {
  useEffect(() => {
    // Set up the background for loginBackdrop
    const backdropDiv = document.createElement("div");
    backdropDiv.style.position = "fixed";
    backdropDiv.style.top = "0";
    backdropDiv.style.left = "0";
    backdropDiv.style.width = "100vw";
    backdropDiv.style.height = "100vh";
    backdropDiv.style.backgroundImage = `url(${loginBackdrop})`;
    backdropDiv.style.backgroundPosition = "center";
    backdropDiv.style.backgroundSize = "cover";
    backdropDiv.style.backgroundRepeat = "no-repeat";
    backdropDiv.style.zIndex = "-10";

    document.body.appendChild(backdropDiv);

    // Set up the background for loginBackdropOntop
    const backdropOntopDiv = document.createElement("div");
    backdropOntopDiv.style.position = "fixed";
    backdropOntopDiv.style.top = "0";
    backdropOntopDiv.style.left = "0";
    backdropOntopDiv.style.width = "100vw";
    backdropOntopDiv.style.height = "100vh";
    backdropOntopDiv.style.backgroundImage = `url(${loginBackdropOntop})`;
    backdropOntopDiv.style.backgroundPosition = "center";
    backdropOntopDiv.style.backgroundSize = "cover";
    backdropOntopDiv.style.backgroundRepeat = "no-repeat";
    backdropOntopDiv.style.zIndex = "10";
    backdropOntopDiv.style.pointerEvents = "none";

    document.body.appendChild(backdropOntopDiv);

    return () => {
      document.body.removeChild(backdropDiv);
      document.body.removeChild(backdropOntopDiv);
    };
  }, []);

  return (
    <Stack
      sx={{
        height: "100vh",
        boxSizing: "border-box",
        overflow: "hidden",
        position: "relative",
        zIndex: "1", // Ensure content is on top
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        component="img"
        src={mynkWhite}
        sx={{
          position: "fixed",
          width: "6.5rem",
          top: "4%",
          left: "9%",
          zIndex: "2",
        }}
      />
      {props.noElevator ? (
        <Box sx={{ zIndex: 100 }}>
          <Outlet />
        </Box>
      ) : (
        <ElevatorCard
          sx={{
            position: "absolute",
          }}
        >
          <Box sx={{ zIndex: 100 }}>
            <Outlet />
          </Box>
        </ElevatorCard>
      )}
    </Stack>
  );
}
