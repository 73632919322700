import {useEffect, useState} from 'react'
import { Stack, Divider, Box, Typography, IconButton } from '@mui/material'
import { MynkFormDialog } from '../components/MynkForm'
import CopyIcon from '../assets/icons/copy-icon.svg'
import ClockIcon from '../assets/icons/small-clock-icon.svg'
import EyeIcon from '../assets/icons/small-eye-icon.svg'
import LocationIcon from '../assets/icons/small-location-icon.svg'
import InformationIcon from '../assets/icons/information-icon.svg'
import ThreeLines from '../assets/icons/three-lines.svg'
import ThreeDotsButton from '../components/ThreeDotsButton'
import EditMeetingTypeDialog from './EditMeetingType'
import ConfirmActionDialog from '../components/ConfirmActionDialog'
import { MeetingType, Event } from '../api/types'
import { useDeleteMeetingTypeMutation, useListMeetingsQuery } from '../api/scheduler'
import { useAtomValue } from 'jotai'
import { userCurrencyAtom } from '../layouts/Main'
import { formatMoney } from '../util/common'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useEditMeetingTypeMutation } from '../api/scheduler'
import ElevatorDialog from '../components/ElevatorDialog'
import { Clear } from '@mui/icons-material'

type MeetingTypeProps = {
  meetingType: MeetingType
  open: boolean
  onClose: () => void
  meetingTypes: Array<MeetingType>;
  setMeetingTypes: React.Dispatch<React.SetStateAction<Array<MeetingType>>>;
  index: number
  portalLink: string
}

enum threeDotsOptions {
  EDIT = "edit-lead",
  DELETE = "delete-lead",
}

const DOTS_MENU = [
  {
    id: threeDotsOptions.EDIT,
    label: "Edit",
  },
  {
    id: threeDotsOptions.DELETE,
    label: "Delete",
  },
];

interface MeetingsAccumulator {
  pastMeetings: Event[];
  upcomingMeetings: Event[];
}

export default function MeetingTypeDialog({ meetingType, open, onClose, meetingTypes, setMeetingTypes, index, portalLink }: MeetingTypeProps) {
  const [editMeetingTypeOpen, setEditMeetingTypeOpen] = useState<boolean>(false);
  const [openDeleteMeetingType, setOpenDeleteMeetingType] = useState<boolean>(false);
  const [linkCopied, setLinkCopied] = useState<boolean>(false)
  const [isRight, setIsRight] = useState(meetingType.isPublic ? true : false);
  const [isDisabled, setIsDisabled] = useState(false);
  const deleteMeetingType = useDeleteMeetingTypeMutation();
  const editMeetingType = useEditMeetingTypeMutation();

  const listMeetingsQuery = useListMeetingsQuery({meeting_name: meetingType.name});
  const now = new Date();
  
  let pastMeetings: Event[] = [];
  let upcomingMeetings: Event[] = [];

  if (listMeetingsQuery.data) {
    const meetingsAccumulator = listMeetingsQuery.data.events.reduce<MeetingsAccumulator>((acc, meeting) => {
      const meetingDate = new Date(meeting.date);
      if (meetingDate < now) {
        acc.pastMeetings.push(meeting);
      } else {
        acc.upcomingMeetings.push(meeting);
      }
      return acc;
    }, { pastMeetings: [], upcomingMeetings: [] });
  
    // Assign the results to the variables declared outside the if condition
    pastMeetings = meetingsAccumulator.pastMeetings;
    upcomingMeetings = meetingsAccumulator.upcomingMeetings;
  }

  const handleThreeDotsClick = (id: string) => {
    if (!meetingType.uuid) return;

    switch (id) {
      case threeDotsOptions.EDIT:
        setEditMeetingTypeOpen(true);
        break;
      case threeDotsOptions.DELETE:
        setOpenDeleteMeetingType(true)
        break;
    }
  }

  const handleDelete = () => {
    if (!meetingType.uuid) return;

    deleteMeetingType.mutateAsync({ uuid: meetingType.uuid });
    const updatedMeetingTypes = meetingTypes.filter(item => item?.uuid !== meetingType.uuid);
    setMeetingTypes(updatedMeetingTypes);
  }

  const currencyCode = useAtomValue(userCurrencyAtom);

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: '1rem',
      borderRadius: '10px',
      fontStyle: 'normal',
      fontFamily: 'Helvetica Neue',
      fontWeight: 600,
      padding: '0.5rem',
    },
  }));

  useEffect(() => {
    if (!meetingType.uuid) return;
  
    const updatedMeetingType = {
      ...meetingType,
      isPublic: isRight,
      uuid: meetingType.uuid!
    }

    setMeetingTypes(meetingTypes.map((item, i) => i === index ? updatedMeetingType : item));
    editMeetingType.mutateAsync(updatedMeetingType);
  }, [isRight]);

  const togglePosition = () => {
    if (isDisabled) return; // Prevent function execution if button is disabled

    setIsRight(!isRight);
    setIsDisabled(true); // Disable button

    setTimeout(() => {
      setIsDisabled(false); // Enable button after 500ms
    }, 700);
  };

  return (
    <ElevatorDialog
      open={open}
      onClose={onClose}
      sx={{minWidth: '28rem'}}
      customCloseButton
    >
      <EditMeetingTypeDialog
        open={editMeetingTypeOpen}
        onClose={() => setEditMeetingTypeOpen(false)}
        meetingType={meetingType}
        index={index}
        meetingTypes={meetingTypes}
        setMeetingTypes={setMeetingTypes}
      />
            
      <ConfirmActionDialog
        open={openDeleteMeetingType}
        what="meeting type"
        whatAction="delete"
        onClose={() => setOpenDeleteMeetingType(false)}
        onConfirm={handleDelete}
        loading={deleteMeetingType.isPending}
        >
        Delete
      </ConfirmActionDialog>

      <Stack
        direction="row"
        sx={{ position: "fixed", right: "3rem", top: "2.5rem" }}
      >
        <ThreeDotsButton
          menu={DOTS_MENU}
          onClickItem={handleThreeDotsClick}
          children={undefined}
          sx={{cursor: 'pointer'}}
        />

        <IconButton
          sx={{ width: "2rem", height: "2rem" }}
          onClick={onClose}
        >
          <Clear sx={{ width: "1.7rem", height: "1.7rem" }} />
        </IconButton>
      </Stack>

      <Stack gap={2.5}>
        <Stack justifyContent={'space-between'} flexDirection={'row'}>
          <Typography variant='h1' sx={{
            fontSize: '2rem',
            mb: 1,
          }}>
            {meetingType.name}
          </Typography>
        </Stack>

        <Stack flexDirection={'row'} justifyContent={'center'}>
          <Stack alignItems={'center'} flexGrow={1}>
            <Typography style={{
              fontSize: '1.1rem',
              opacity: 0.7,
            }}>
              Upcoming
            </Typography>
            <Typography style={{
              fontSize: '2.2rem',
            }}>
              {upcomingMeetings.length}
            </Typography>
          </Stack>

          <Divider
            orientation='vertical'
            variant='middle' flexItem
            sx={{
              marginX: '4rem',
            }}
          />

          <Stack alignItems={'center'} flexGrow={1}>
            <Typography
              sx={{
                fontSize: '1.1rem',
                opacity: 0.7,
              }}
            >
              Past
            </Typography>
            <Typography
              sx={{
                fontSize: '2.2rem',
              }}
            >
              {pastMeetings.length}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          flexDirection={'row'}
          sx={{cursor: 'pointer'}}
          onClick={(event) => {
            event.stopPropagation();
            navigator.clipboard.writeText(portalLink);
            setLinkCopied(true); // Set linkCopied to true when the link is copied
            setTimeout(() => setLinkCopied(false), 1000); // Set linkCopied back to false after 1 second
          }}
        >
          <Box
            src={CopyIcon}
            component='img'
            sx={{
              marginRight: '0.5rem',
              marginTop: '0.3rem',
              width: '1rem',
              height: '1rem'
            }}
          />
          <Typography sx={{
            fontSize: '1.1rem',
            opacity: 0.7,
            marginRight: '0.5rem'
          }}>
            {linkCopied ? 'Copied!' : 'Link'}
          </Typography>
          <Typography sx={{
            fontSize: '1.1rem'
          }}>
            {portalLink}
          </Typography>
        </Stack>
        <Stack flexDirection={'row'}>
          <Stack flexDirection={'row'} alignSelf={'center'}>
            <Box
              src={ClockIcon}
              component='img'
              sx={{
                marginRight: '0.5rem',
                width: '1rem',
                height: '1rem',
                mt: '0.3rem'
              }}
            />
            <Typography sx={{
              fontSize: '1.1rem'
            }}>
              {meetingType.duration}
            </Typography>
          </Stack>

          <Divider orientation='vertical' variant='middle' flexItem sx={{
            marginX: '1rem',
            height: '2rem'
          }}/>

          <Stack flexDirection={'row'} alignSelf={'center'}>
            <Box
              src={LocationIcon}
              component='img'
              sx={{
                marginRight: '0.5rem',
                width: '1rem',
                height: '1rem',
                mt: '0.3rem'
              }}
            />
            <Typography sx={{
              fontSize: '1.1rem'
            }}>
              {meetingType.location}
            </Typography>
          </Stack>
          
          {meetingType.price !== '0' && (
            <>
              <Divider orientation='vertical' variant='middle' flexItem sx={{
                marginX: '1rem',
                height: '2rem'
              }}/>
              <Typography variant='h1' sx={{fontSize: '1.3rem'}} alignSelf={'center'}>{meetingType.price && formatMoney(parseFloat(meetingType.price), currencyCode)}</Typography>
            </>
          )}
        </Stack>

        <Stack flexDirection={'row'}>
          <Box
            src={EyeIcon}
            component='img'
            sx={{
              marginRight: '0.5rem',
              width: '1rem',
              height: '1rem',
              mt: '0.3rem'
            }}
          />
          <Typography sx={{
            fontSize: '1.1rem',
            marginRight: '0.5rem'
          }}>
            {meetingType.isPublic ? 'Public' : 'Private'}
          </Typography>
          <LightTooltip
            title={meetingType.isPublic ? "Everyone with the link can see and book this meeting." : "No one can see or book this meeting."}
            placement='top'
          >
            <Box 
              src={InformationIcon}
              component='img'
              sx={{
                marginTop: '0.3rem',
                width: '1rem',
                height: '1rem',
                cursor: 'pointer'
              }}
            />
          </LightTooltip>
          <Box sx={{
            width: '2.2rem',
            height: '1.1rem',
            background: isRight ? '#75B3FF' : '#e2e2e2',
            borderRadius: '20px',
            marginLeft: '1rem',
            position: 'relative',
            marginTop: '0.3rem',
            cursor: 'pointer',
            opacity: isDisabled ? 0.5 : 1,
          }} onClick={togglePosition}>
            <Box sx={{
              width: '1.1rem',
              height: '1.1rem',
              background: '#FFFFFF',
              borderRadius: '20px',
              position: 'absolute',
              left: isRight ? 'calc(100% - 1.1rem)' : '0',
              transition: 'left 0.3s'
            }}/>
          </Box>
        </Stack>
        
        <Typography fontWeight={600}>Description:</Typography>
        <Stack flexDirection={'row'} mt={-1}>
          <Box
            src={ThreeLines}
            component='img'
            sx={{
              marginRight: '0.5rem',
              alignSelf: 'flex-start',
              marginTop: '0.5rem'
            }}
          />
          <Typography sx={{
            fontSize: '1.1rem',
            maxWidth: '40ch',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
          }}>
            {meetingType?.description}
          </Typography>
        </Stack>
      </Stack>
    </ElevatorDialog>
  )
}