



export const MENU_TAIL_SX = {
  '& .MuiPaper-root': {
    marginTop: '0rem',
    overflow: 'visible',
    borderTopRightRadius: 0,
  },

  '& .MuiPaper-root::before': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: '-1rem',

    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 0 1rem 0.8rem',
    borderColor: 'transparent transparent white transparent',
    transform: 'rotate(0deg)',
  },
};
