import React, {useState} from "react";
import {AddExpenseDialog} from "../../../../dialogs/AddExpense";
import {Button} from "@mui/material";
import {Add, ExpandMore} from "@mui/icons-material";

export function ExpensesNewButton() {
  const [addExpenseOpen, setAddExpenseOpen] = useState(false);

  return (
    <>
      <AddExpenseDialog open={addExpenseOpen} onClose={() => setAddExpenseOpen(false)} />

      <Button
        variant="contained"
        startIcon={<Add />}
        sx={{
          background: 'linear-gradient(#fbadfd 0%, #ad4bfb 100%)',
          borderRadius: '1.7143rem',
          textTransform: 'capitalize',
          fontSize: "1.1429rem",
          boxShadow: '0',
          width: '5.5rem',
          '& span': {
            margin: 0,
          }
        }}
        onClick={(e) => setAddExpenseOpen(true)}
      >
        New
      </Button>
    </>
  )
}



