import { useAddProjectFileMutation } from "../../api/projects";
import { FileObj } from "../../api/types";
import ElevatorDialog from "../ElevatorDialog";
import FileUpload from "../FileUpload";
import { Typography } from "@mui/material";

interface UploadOneFileDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (selectedFile: FileObj | null) => void;
  errorMsg?: string;
  loading?: boolean;
}

export default function UploadOneFileDialog(props: UploadOneFileDialogProps) {
  return (
    <ElevatorDialog open={props.open} onClose={props.onClose}>
      <FileUpload
        sx={{ width: "25rem" }}
        noBorder
        noTitle
        onSubmit={props.onSubmit}
        loading={props.loading}
      />
      <Typography sx={{ color: "red" }}>{props.errorMsg}</Typography>
    </ElevatorDialog>
  );
}
