import { useMutation, useQuery } from "@tanstack/react-query";
import {
  GetMoreMessagesParams,
  GetMoreMessagesResponse,
  NewConversationResponse,
  PromptParams,
  PromptResponse,
} from "./types";
import axios from "axios";

export function useNewConversationMutation() {
  return useMutation<NewConversationResponse>({
    mutationFn: async () => (await axios.post("/api/ai/new-conversation")).data,
  });
}

export function usePromptMutation() {
  return useMutation<PromptResponse, unknown, PromptParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/ai/prompt", params)).data,
  });
}

export function useGetMoreMessagesQuery(params?: GetMoreMessagesParams) {
  return useQuery({
    queryKey: ["more-messages", params],
    queryFn: async () =>
      (await axios.get("/api/ai/more-messages", { params }))
        .data as GetMoreMessagesResponse,
    enabled: Boolean(params),
  });
}
