import React, { useState } from "react";
import { MynkTab } from "../../../../components/MynkPage";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import TaskView, {
  TaskViewType,
  TaskGroup,
  TaskViewProps,
  selectedTaskAtom,
  openedEditAtom,
  openedDeleteAtom,
} from "../../../../components/TaskView";
import AddTaskDialog, { PAST_QUICK_OPTIONS } from "../../../../dialogs/AddTask";
import { NewTaskButton, SearchAndViewTypeSwitch, TaskViewGroups } from "./All";
import ConfirmActionDialog from "../../../../components/ConfirmActionDialog";
import { useAtom } from "jotai";
import { closeSnackbar, useSnackbar } from "notistack";
import { useDeleteTaskMutation } from "../../../../api/tasks";
import { DeleteTaskParams, SearchOption } from "../../../../api/types";
import CloseIcon from "@mui/icons-material/Close";
import { EditTaskDialog } from "../../../../dialogs/EditTask";

const GROUPS: TaskViewProps[] = [
  { title: "Today", dueDaysMin: 0, dueDaysMax: 1, completed: true },
  { title: "Yesterday", dueDaysMin: -1, dueDaysMax: 0, completed: true },
  {
    title: "Last week",
    dueDaysMin: -7,
    dueDaysMax: -1,
    completed: true,
    allowAdd: false,
  },
  {
    title: "All",
    dueDaysMin: -7,
    dueDaysMax: 1,
    dueDaysNot: true,
    completed: true,
    allowAdd: false,
  },
];

export default function TasksCompletedSubpage() {
  const [addTaskOpen, setAddTaskOpen] = useState(false);
  const [addTaskDelta, setAddTaskDelta] = useState(0);

  const [search, setSearch] = useState<SearchOption>({ query: "" }); 
  const [selectedView, setSelectedView] = useState(TaskViewType.LIST);

  const [selectedTask, setSelectedTask] = useAtom(selectedTaskAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);
  const { enqueueSnackbar } = useSnackbar();

  // Fetch delete task:
  const deleteTask = useDeleteTaskMutation();
  const handleDeleteTask = async (uuid: string) => {
    const params = {
      uuid: uuid,
    } as DeleteTaskParams;

    await deleteTask.mutateAsync(params);
    enqueueSnackbar("Task deleted successfully", {
      variant: "success",
      action: (key) => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
      style: {
        borderRadius: "0.7rem",
      },
    });

    setOpenedDelete(false);
  };
  
  return (
    <MynkTab
      title="Completed"
      action={<NewTaskButton />}
      nextToAction={
        <SearchAndViewTypeSwitch
          searchState={[search, setSearch]}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
        />
      }
    >
      {/* Confirm delete task pop-up window */}
      <ConfirmActionDialog
        open={openedDelete}
        what="task"
        whatAction="delete"
        onClose={() => setOpenedDelete(false)}
        onConfirm={() =>
          selectedTask ? handleDeleteTask(selectedTask.uuid) : undefined
        }
        loading={deleteTask.isPending}
      >
        Delete
      </ConfirmActionDialog>

      {/* Confirm edit task pop-up window */}
      <EditTaskDialog
        open={openedEdit}
        onClose={() => setOpenedEdit(false)}
        oldTask={selectedTask}
      />
      
      <AddTaskDialog
        open={addTaskOpen}
        onClose={() => setAddTaskOpen(false)}
        defaultDelta={addTaskDelta}
        quickOptions={PAST_QUICK_OPTIONS}
        completed={true}
      />

      {selectedView == TaskViewType.LIST ? (
               <Grid container>
               {/* Tasks horizontal view (left side - Today/Tomorrow/Upcoming) */}
               <Grid item xs={9}>
                 <Box sx={{ position: "relative" }}>
                   <Box
                     sx={{
                       position: "absolute",
                       width: "100%",
                       height: "100%",
                       borderRight: "solid 1px #75b3ffb2",
                       mx: 3,
                       pointerEvents: "none",
                     }}
                   />
     
                   <Stack spacing={3} sx={{ mt: 2 }}>
                     <TaskViewGroups
                       groups={GROUPS.slice(0, 3)}
                       viewType={selectedView}
                       title_query={search?.query ?? ""}
                     />
                   </Stack>
                 </Box>
               </Grid>
               {/* Task table view (right side - Overdue) */}
               <Grid item xs={3}>
                 <Box sx={{ ml: 3, mr: -1.7, pl: 3, position: "relative" }}>
                   <Box
                     sx={{
                       position: "absolute",
                       width: 1,
                       height: "100%",
                       borderLeft: "solid 1px #75b3ffb2",
                       mx: -3,
                       pointerEvents: "none",
                     }}
                   />
     
                   <Box sx={{ minHeight: "75vh" }}>
                     <TaskViewGroups
                       groups={GROUPS.slice(3, 4)}
                       viewType={TaskViewType.CARD}
                       title_query={search?.query ?? ""}
                     />
                   </Box>
                 </Box>
               </Grid>
             </Grid>
      ) : selectedView == TaskViewType.CARD ? (
        <Grid container spacing={6}>
        {GROUPS.map((group, i) => (
          <Grid item xs={3} key={group.title}>
            {i > 0 && (
              <Box
                sx={{
                  position: "fixed",
                  width: 1,
                  height: "calc(100% - 146px)",
                  borderLeft: "solid 1px #75b3ffb2",
                  mx: -3,
                  pointerEvents: "none",
                }}
              />
            )}

            <TaskView
              allowAdd
              {...group}
              viewType={TaskViewType.CARD}
              onAdd={() => {
                setAddTaskDelta(group.dueDaysMin!);
                setAddTaskOpen(true);
              }}
              query={search?.query ?? ""}
            />
          </Grid>
        ))}
      </Grid>
      ) : (
        <></>
      )}
    </MynkTab>
  );
}
