import { Box, Button, Divider, Menu, MenuItem, SxProps } from "@mui/material";
import { Add, ExpandMore } from "@mui/icons-material";
import React, { ReactNode, useState } from "react";
import {MENU_TAIL_SX} from "../../util/styles";

export interface ActionMenuEntry {
  id: string;
  label: string;
}

export enum ActionMenuButtonVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export interface ActionMenuButtonProps {
  children: ReactNode;
  startIcon?: ReactNode;
  menu?: ActionMenuEntry[];
  onClickItem?: (id: string) => void;
  variant?: ActionMenuButtonVariant;
  sx?: SxProps;
}

export default function ActionMenuButton(props: ActionMenuButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      {props.menu && (
     <Menu
     id="sales-new-menu"
     anchorEl={anchorEl}
     open={Boolean(anchorEl)}
     onClose={() => setAnchorEl(null)}
     sx={{
       ml: -4,
       ...MENU_TAIL_SX,

       "& ul > li": {
         paddingRight: 6,
         paddingLeft: 2,
         pt: 1,
         pb: 1,
       },
     }}
   >
  {props.menu.map((item) => (
  <div key={item.id}>
    <MenuItem
      key={item.id}
      onClick={() => {
        setAnchorEl(null);
        props.onClickItem?.(item.id);
      }}
    >
      {item.label}
    </MenuItem>
    <Box
    sx={{
      '&:not(:last-child)::before': {
        content: '""',
        display: 'block',
        borderBottom: '0.01rem solid rgba(0, 0, 0, 0.12)',
        width: '81%',
        margin: 'auto',
      },
    }}
  />
  </div>
))}
   </Menu>
      )}

      <Button
        variant="contained"
        startIcon={props.startIcon}
        endIcon={props.menu && <ExpandMore sx={{ width: "1.07rem" }}/>}
        sx={{
          zIndex: 100,
          background:
            props.variant === ActionMenuButtonVariant.SECONDARY
              ? "#ecf0f5"
              : "linear-gradient(#fbadfd 0%, #ad4bfb 100%)",
          color:
            props.variant === ActionMenuButtonVariant.SECONDARY
              ? "#444"
              : "white",
          borderRadius: "1.714rem",
          textTransform: "capitalize",
          fontSize: "1.2rem",
          height: "2.9rem",
          width: "6.3rem",
          boxShadow: "0",
          "& span": {
            margin: 0,
          },
          ...(props.variant === ActionMenuButtonVariant.SECONDARY
            ? {
                "&:hover": {
                  background: "#ecf0f5",
                },
              }
            : {}),
          "& .MuiSvgIcon-root": {
            fontSize: "1.1rem",
            ml: 0.2,
          },
          ...props.sx,
        }}
        onClick={(e) =>
          props.menu ? setAnchorEl(e.currentTarget) : props.onClickItem?.("")
        }
      >
        {props.children}
      </Button>
    </>
  );
}
