
export type SaleStatus = "completed" | "pending" | "refunded";
export type PaymentMethod = "card" | "cash" | "bank" | "none";
export type ExpensesCategories = "Photography Equipment" | "Administrative Expenses" | "Marketing & Advertising" | "Personnel & Staffing" | "Office Expenses" | "Travel & Entertainment" | "Operational & Maintenance" | "none";

export interface FileObj {
  name: string;
  data: string;
  mime_type?: string;
}

export interface MinimalFileInfoObj {
  uuid: string;
  name: string;
  mime_type: string;
  date: string;
}

export enum TemplateKind {
  INVOICE = "invoice",
  PROPOSAL = "proposal",
}

export enum GetFileType {
  GENERAL = "general",
  SPECIFIC_WORKFLOW = "specific_workflow",
}

export enum Priority {
  NONE = "none",
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export enum Availability {
  NONE = "none",
  AVAILABLE = "available",
  ON_HOLD = "on_hold",
  NOT_AVAILABLE = "not_available",
}

export enum EntranceDateTypeOptions {
  READY_TO_MOVE = "ready_to_move",
  SPECIFIC_DATE = "specific_date",
}

export enum ChangeListingViewerAction {
  ADD = "add",
  DELETE = "delete",
  SET_STAGE = "set_stage",
}

export enum ClientSource {
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  TIKTOK = "tiktok",
  LINKEDIN = "linkedin",
  WEBSITE = "website",
  GOOGLE_SEARCH = "google_search",
  REFERRAL = "referral",
  PINTEREST = "pinterest",
  PAID_ADS = "paid_ads",
  BEHANCE = "behance",
  DRIBBLE = "dribbble",
  OTHER = "other",
}

export enum TimePeriod {
  DAYS = "day",
  WEEKS = "week",
  MONTHS = "month",
  YEARS = "year",
}

export enum ProjectStage {
  _ALL = "all_projects",
  _LEADS_TO_CLOSED = "leads_to_closedwon",
  _CLOSED_WON = "closed_won",
  _ACTIVE = "active",
  LEAD = "lead",
  PROPOSAL = "proposal",
  UNDER_CONTRACT = "under_contract",
  WAITING_FOR_EVENT = "waiting_for_event",
  EDITING = "editing",
  FINAL_APPROVAL = "final_approval",
  COMPLETED = "completed",
}

export enum ClientPipelineStage {
  NEW_LEAD = "new_lead",
  FOLLOW_UP = "follow_up",
  OPPORTUNITIES = "opportunities",
  PROPOSALS = "proposals",
  CLOSED_WON = "closed_won",
}

export enum ListingType {
  NONE = "none",
  FOR_RENT = "for_rent",
  FOR_SALE = "for_sale",
}

export enum RealEstateClientLookingTo {
  RENT = "rent",
  BUY = "buy",
}

export enum RealEstateClientType {
  OWNER = "owner",
  PROSPECT = "prospect",
}

export enum WorkflowType {
  NONE = "none",
  PHOTOGRAPHY = "photography",
  REAL_ESTATE = "real_estate",
  GRAPHIC_DESIGN = "graphic_design",
  WEB_DEVELOPMENT = "web_development",
  COACH = "coach",
  COPYWRITER = "copywriter",
  EVENT_PLANNER = "event_planner",
  MARKETER = "marketer",
  HAIR_MAKEUP_ARTIST = "hair_makeup_artist",
  REAL_ESTATE_AGENT = "real_estate",
  OTHER = "other",
}

export enum SquareSizeUnits {
  SQM = "sqm",
  SQFT = "sqft",
}

export enum ListingStage {
  INTEREST = "inquiry_interest",
  PROPERTY_VIEWING = "property_viewing",
  SERIOUS_CONSIDERATION = "serious_consideration",
  NEGOTIATION = "negotiation",
  UNDER_CONTRACT = "under_contract",
  SOLD = "closed_sold",
}

export enum ExpenseCategory {
  None = "none",
  PhotographyEquipment = "Photography Equipment",
  AdministrativeExpenses = "Administrative Expenses",
  MarketingAndAdvertising = "Marketing & Advertising",
  PersonnelAndStaffing = "Personnel & Staffing",
  OfficeExpenses = "Office Expenses",
  TravelAndEntertainment = "Travel & Entertainment",
  OperationalAndMaintenance = "Operational & Maintenance",
}

export enum LibraryCategory {
  GUIDES = "Guides",
  CONTRACTS = "Contracts",
  RELEASES = "Releases",
  TOOLS = "Tools",
}

export interface SearchOption {
  query: string;
}

export interface FilterOption {
  by: string;
  query: string;
}

export interface SortOption {
  by: string;
  descending: boolean;
}

export interface RetrieveOptions {
  search?: SearchOption;
  filter?: FilterOption;
  sort?: SortOption;
}

export type ClientPipelineStats = {
  stage: ClientPipelineStage;
  count: number;
}[];

export interface LastEvaluatedKey {
  PK: string;
  SK: string;
  entity_type: string;
}

export interface User {
  uuid: string;
  email: string;
}

export interface ItemToAdd {
  name: string;
  quantity: number;
  price: number;
}

export interface Sale {
  id: string;
  status: SaleStatus;
  created_at: string;
  payment_method: PaymentMethod;
  payment_hint: string;
  amount: number;
  tax: number;
  client_id: string;
  client: string;
  item: string;
}

export interface Expense {
  uuid?: string;
  description: string;
  amount: number;
  date: string;
  payment_method?: PaymentMethod;
  payment_hint?: string;
  project?: string;
  client?: string;
  category?: ExpensesCategories;
}

export type SalesWindowSize = "day" | "week" | "month" | "year";
export type ExpensesWindowSize = "week" | "month";

export interface SalesStatsParams {
  window_size: SalesWindowSize;
}

export interface SalesStatsResponse {
  window_volume: number;
  window_change: null | number;
  window_average: number;
  window_sales: [string, number][];
  total_sale_count: number;
  total_item_count: number;
  best_sale: number;
  payment_histogram: [PaymentMethod, number][];
}

export interface LoginParams {
  email: string;
  password: string;
  remember_me: boolean;
}

export interface LoginResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

export interface LoginWithGoogleParams {
}

export interface LoginWithGoogleResponse {
  url: string;
}

export interface SignupParams {
  name: string;
  email: string;
  password: string;
  plan: SubscriptionPlan;
  code?: string;
}

export interface SignupResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

export interface VerifyEmailParams {
  email: string;
  code: string;
}

export interface VerifyEmailResponse {}

export interface ResendVerificationEmailParams {
  email: string;
}

export interface ResendVerificationEmailResponse {}

export interface AskForgotPasswordParams {
  email: string;
}

export interface AskForgotPasswordResponse {}

export interface ResetPasswordParams {
  email: string;
  password: string;
  code: string;
}

export interface ResetPasswordResponse {}

export interface ResetPasswordConnectedParams {
  old_password: string;
  password: string;
}

export interface ResetPasswordConnectedResponse {}


export interface ResendResetPasswordEmailParams {
  email: string;
}

export interface ResendResetPasswordEmailResponse {}

export interface LogoutParams {}

export interface LogoutResponse {}

export interface AskDeleteAccountParams {
  email: string;
}

export interface AskDeleteAccountResponse {}


export interface DeleteAccountParams {
  user_id: string;
  email: string;
  password: string;
  code: string;
}

export interface DeleteAccountResponse {}

export interface ListWorkflowsParams {}

export interface Workflow {
  uuid: string;
  name: string;
  type: WorkflowType;
}

export interface ListWorkflowsResponse {
  workflows: Workflow[];
}

export interface EditWorkflowParams {
  workflow: Workflow;
  workflow_type: WorkflowType | string;
}

export interface RecordUserParams {}

export interface RecordUserResponse {}

export interface UploadProfilePhotoParams {
  photo: FileObj;
}

export interface UploadProfilePhotoResponse {
  uuid: string;
}

export interface GetProfilePhotoParams {
}

export interface GetProfilePhotoResponse {
  uuid: string;
}

export interface UploadBusinessLogoParams {
  photo: FileObj;
}

export interface UploadBusinessLogoResponse {
  uuid: string;
}

export interface GetBusinessLogoParams {
}

export interface GetBusinessLogoResponse {
  uuid: string;
}

export interface GetDevicesHistoryResponse {
  history: Array<string>;
}

export interface WhoamiParams {}

export type SubscriptionPlan = 'essentials' | 'pro';

export type PlanStatus = 'none' | 'pending' | 'active' | 'cancelled' | 'trial' | 'trial_expired' | 'expired';

export interface PlanQueryResult {
   plan: null | SubscriptionPlan;
   status: PlanStatus;
}

export interface WhoamiResponse {
  user: null | User;
  plan: PlanQueryResult;
}

export interface ClientSummary {
  uuid: string;
  full_name: string;
  email: string;
  phone: string;
}

export interface MinimalProjectSum {
  uuid: string;
  name: string;
}

export interface ClientInfo extends ClientSummary {
  created_at: string;
  last_change: string;
  source: ClientSource;
  priority: Priority;
  notes?: Note[];
  birth_date?: string;
  address?: string;
  instagram?: string;
  website?: string;
  facebook?: string;
}

export interface PhotographyClientInfo extends ClientInfo {
  last_contact: string;
  last_purchase: string;
  purchase_count: number;
  total_spent: number;
  projects_amount: number;
}

export interface RealEstateClientInfo extends ClientInfo {
  type: RealEstateClientType;
  preferences: string;
  properties_amount: number;
  looking_to: RealEstateClientLookingTo;
  budget: number;
}

export interface Client extends ClientInfo {}

export interface AddClientParams {
  full_name: string;
  email: string;
  phone: string;
  source: ClientSource;
  priority: Priority;
}

export interface AddPhotographyClientIn extends AddClientParams {}

export interface AddPhotographyClientPortalIn extends AddClientParams {
  company_name?: string;
}

export interface AddRealEstateClientIn extends AddClientParams {
  type: RealEstateClientType;
  looking_to?: ListingType;
  preferences?: string;
  budget?: number;
}

export interface AddClientResponse {
  uuid: string;
}

export interface EditClientParams {
  uuid: string;
  fields: Record<string, any>;
}

export interface EditClientResponse {}

export interface DeleteClientParams {
  uuid: string;
}

export interface DeleteClientResponse {}

export interface ListClientsParams {
  page_size?: number;
  page_index?: number;
  retrieve_options?: RetrieveOptions;
}

export interface ListClientsResponse {
  clients: Client[];
  total: number;
  num_of_pages: number;
}

export interface CRMStats {
  existing_clients?: number;
  new_clients?: number;
  returning_clients?: number;
  prospects?: number;
  owners?: number;
  listings?: number;
  sources: { source: ClientSource; count: number }[];
  pipeline: { stage: ClientPipelineStage; count: number }[];
}

export interface GetClientStatsParams {}

export interface GetClientStatsResponse {
  stats: CRMStats;
}



export interface ConvoMessage {
  who: string;
  text: string;
  time: number;
}
export interface Conversation {
  uuid: string;
  messages: ConvoMessage[];
  start_index: null | number;
  end_index: null | number;
}

export interface NewConversationResponse extends Conversation {}

export interface NewConversationParams {}

export interface InvoiceDetails {
  template_uuid: string;
  client_uuid: string;
  item_name: string;
  item_price: number;
  item_quantity: number;
}

export enum UserRequiredActionKind {
  CONFIRM_PENDING_INVOICE = "confirm_pending_invoice",
  SEND_CONFIRMED_INVOICE = "send_confirmed_invoice",
}

export interface ConfirmPendingInvoiceAction {
  action: UserRequiredActionKind.CONFIRM_PENDING_INVOICE;
  details: InvoiceDetails;
}

export interface SendConfirmedInvoice {
  action: UserRequiredActionKind.SEND_CONFIRMED_INVOICE;
  details: InvoiceDetails;
}

export type UserRequiredAction = ConfirmPendingInvoiceAction | SendConfirmedInvoice;

export interface InvokedTool {
  tool_name: string;
  snackbar: string;
}

export interface PromptResponse {
  prompt: string;
  answer: string;
  actions: UserRequiredAction[];
  invoked_tools: InvokedTool[];
}

export interface PromptParams {
  conversation_id: string;
  prompt: string;
  user_time: number;
}

export interface ProjectStageChange {
  old_stage: null | ProjectStage;
  new_stage: ProjectStage;
  change_time: string;
}

export interface Note {
  uuid: string;
  title: string;
  text: string;
  created_at: string;
  owner_uuid: string;
}

export interface ListingViewer {
  client_uuid: string;
  stage: ListingStage;
}

export interface GenericProject {
  uuid: string;
  name: string;
  client_name: string;
  client_uuid: string;
  description: null | string;
  price: number;
  files_uuids?: string[];
}

export interface PhotographyProject extends GenericProject {
  date: string;
  end_date?: string;
  is_all_day: boolean;
  stage: ProjectStage;
  priority: Priority;
  lead_source: null | string;
  location: null | string;
  stage_changes: ProjectStageChange[];
  notes: Note[];
}

export interface RealEstateListing extends GenericProject {
  availability: Availability;
  viewers: ListingViewer[];
  type: ListingType;
  location: string;
  size: number;
  size_units: SquareSizeUnits;
  bedrooms: number;
  bathrooms: number;
  entrance_date: string;
}

export interface ListProjectsParams {
  page_size?: number;
  page_index?: number;
  client_uuid?: string;
  prospect_uuid?: string;
  stage?: ProjectStage;
  retrieve_options?: RetrieveOptions;
  availability?: Availability;
}

export interface ListProjectsResponse {
  projects: PhotographyProject[] | RealEstateListing[]; // TOFIX: GenericProjectEntry?
  num_of_pages: number;
  total: number;
}


export interface SearchProjectsParams {
  query: string;
  limit: number;
}

export interface SearchProjectsResponse {
  projects: MinimalProjectSum[];
  total: number;
  page_size: number;
}


export interface AddProjectFileParams {
  file: FileObj;
  project_uuid: string;
}

export interface AddProjectFileResponse {
  uuid: string;
}

export interface DeleteProjectFileParams {
  file_uuid: string;
}

export interface DeleteProjectFileResponse {}

export interface ListProjectFilesParams {
  project_uuid: string;
}

export interface ListClientFilesParams {
  client_uuid: string;
}

export interface ProjectFile {
  uuid: string;
  kind: string;
  time: string;
}


export interface ListProjectFilesResponse {
  files: MinimalFileInfoObj[];
}

export interface AddClientFileParams {
  file: FileObj;
  client_uuid: string;
}

export interface AddClientFileResponse {
  uuid: string;
}

export interface ListClientFilesResponse {
  files: MinimalFileInfoObj[];
}

export interface GetProjectParams {
  project_uuid: string;
}

export interface GetProjectResponse {
  project: PhotographyProject | RealEstateListing;
}

export interface Listing {
  uuid: string;
  availability: Availability;
  stage: ListingStage;
  type: ListingType;
  owner_name: string;
  owner_uuid: string;
  location: string;
  price: number;
  size: number;
  size_units: SquareSizeUnits;
  bedrooms: number;
  bathrooms: number;
  description: null | string;
  entrance_date: string;
  name: string;
}

export interface SearchContactsParams {
  query: string;
  limit: number;
}

export interface SearchContactsResponse {
  clients: ClientSummary[];
  total: number;
  page_size: number;
}

export interface SetClientPriorityParams {
  uuid: string;
  priority: Priority;
}

export interface SetClientPriorityResponse {}

export interface SetClientSourceParams {
  uuid: string;
  source: ClientSource;
}

export interface SetClientSourceResponse {}

export interface AddLeadParams {
  name: string;
  contact_id: string;
  priority: Priority;
  status: ProjectStage;
}

export interface AddLeadResponse {}

export interface AddProjectParams {
  name: string;
  contact_id: string;
  priority: Priority;
  stage: ProjectStage;
  date?: string;
  is_all_day?: boolean;
  end_date?: string;
}

export interface AddProjectResponse {
  uuid: string;
}

export interface IsProjectDateAvailableParams {
  date?: string;
  end_date?: string;
}

export interface IsProjectDateAvailableResponse {
  is_available: boolean;
  projects?: PhotographyProject[];
}

export interface DeleteProjectParams {
  uuid: string;
}

export interface DeleteProjectResponse {}

export interface EditProjectParams {
  uuid: string;
  fields: Record<string, any>;
}

export interface EditProjectResponse {}

export interface Lead {
  uuid: string;
  name: string;
  contact_name: string;
  status: ProjectStage;
  priority: Priority;
}

export interface ListLeadsParams {
  page_index: number;
  page_size: number;
  status?: null | ProjectStage;
}

export interface ListLeadsResponse {
  leads: Lead[];
  total: number;
  page_size: number;
}

export interface StageStats {
  stages: ProjectStage[] | ListingStage[];
  values: number[];
}

export interface GetStageStatsResponse {
  stageStats: StageStats;
}

export interface GetLeadParams {
  uuid: string;
}

export interface GetLeadResponse {
  lead: Lead;
}

export interface GetClientParams {
  uuid: string;
}

export interface GetClientResponse {
  client: PhotographyClientInfo | RealEstateClientInfo;
}

export interface AddListingParams {
  type: ListingType;
  location: string;
  price: number;
  size: number;
  size_units: SquareSizeUnits;
  bedrooms: number;
  bathrooms: number;
  description: string | null;
  entrance_date: string;
  owner_uuid: string;
  name: string;
  photos: FileObj[];
}

export interface AddListingResponse {
  uuid: string;
}

export interface DeleteListingParams {
  uuid: string;
}

export interface DeleteListingResponse {}

export interface ListListingsParams {
  page_size?: number;
  last_evaluated_key?: LastEvaluatedKey;
  availability?: Availability;
}

export interface ListListingsResponse {
  listings: Listing[];
  new_last_evaluated_key?: LastEvaluatedKey;
}

export interface SetListingAvailabilityParams {
  uuid: string;
  availability: Availability;
}

export interface SetListingAvailabilityResponse {}

export interface SetListingTypeParams {
  uuid: string;
  type: ListingType;
}

export interface SetListingTypeResponse {}

export interface Task {
  uuid: string;
  title: string;
  completed: boolean;
  is_reminder: boolean;
  due_date: string;
  priority?: Priority;
  client_uuid?: string;
  project_uuid?: string;
}

export interface ListTasksParams {
  page_index?: number;
  page_size?: number;
  due_days_min?: number;
  due_days_max?: number;
  due_days_not?: boolean;
  completed?: boolean;
  title_query?: string;
  project_uuid?: string;
  client_uuid?: string;
}

export interface ListTasksResponse {
  tasks: Task[];
  total: number;
  page_size: number;
}

export interface AddTaskParams {
  title: string;
  priority?: Priority;
  due_date: number;
  completed?: boolean;
  project_uuid?: string;
  client_uuid?: string;
  is_reminder?: boolean;
}

export interface AddTaskResponse {}

export interface EditTaskParams {
  uuid: string;
  title: string;
  due_date: number;
  priority: Priority;
  client_uuid?: string;
  project_uuid?: string;
}

export interface EditTaskResponse {}

export interface DeleteTaskParams {
  uuid: string;
}

export interface DeleteTaskResponse {}

export interface SetTaskStatusParams {
  uuid: string;
  completed: boolean;
}

export interface SetTaskStatusResponse {}

export interface SetTaskPriorityParams {
  uuid: string;
  priority: Priority;
}

export interface SetTaskPriorityResponse {}

export interface DomChangeRecord {
  id: string;
  key: string;
  value: string;
}

export interface TemplateFieldSchemaCommon {
  title: null | string;
  description: null | string;
}

export interface TemplateClientFieldSchema extends TemplateFieldSchemaCommon {
  type: "client";
}

export interface TemplateStringFieldSchema extends TemplateFieldSchemaCommon {
  type: "string";
}

export interface TemplateNumberFieldSchema extends TemplateFieldSchemaCommon {
  type: "number";
  default: null | number;
}

export interface TemplatePriceFieldSchema extends TemplateFieldSchemaCommon {
  type: "price";
}

export interface TemplateObjectFieldSchema extends TemplateFieldSchemaCommon {
  type: "object";
  fields: {
    [key: string]: TemplateFieldSchema;
  };
}

export interface TemplateTableFieldSchema extends TemplateFieldSchemaCommon {
  type: "table";
  spans: null | number[];
  item: TemplateFieldSchema;
}

export interface TemplateCollabsableFieldSchema extends TemplateFieldSchemaCommon {
  type: "collapsable";
  fields: {
    [key: string]: TemplateUnderCollapsableFieldSchema;
  };
}

export interface TemplateUnderCollapsableFieldSchema extends TemplateFieldSchemaCommon {
  type: "under_collapsable";
  title: string;
  under_service: string | null;
}

export type TemplateFieldSchema =
  | TemplateClientFieldSchema
  | TemplateStringFieldSchema
  | TemplateNumberFieldSchema
  | TemplatePriceFieldSchema
  | TemplateObjectFieldSchema
  | TemplateTableFieldSchema
  | TemplateCollabsableFieldSchema
  | TemplateUnderCollapsableFieldSchema;

export interface TemplateSchema {
  root: TemplateObjectFieldSchema;
}

export interface Template {
  uuid: string;
  kind: string;
  html: string;
  preview_uri: string;
  is_standard: boolean;
  records: null | DomChangeRecord[];
  schema: null | TemplateSchema;
}

export interface ListTemplatesParams {}

export interface ListTemplatesResponse {
  templates: Template[];
}

export interface ListDefaultTemplatesParams {}

export interface ListDefaultTemplatesResponse {
  templates: Template[];
}

export interface GetTemplatesParams {
  uuid: string;
}

export interface GetTemplatesResponse {
  template: Template;
}

export interface GetDefaultTemplateParams {
  template_kind: string;
}

export interface GetDefaultTemplateResponse {
  uuid: string;
}

export interface SaveTemplateParams {
  base_uuid: string;
  update: boolean;
  records: DomChangeRecord[];
}

export interface SaveTemplateResponse {
  uuid: string;
}

export interface SetDefaultTemplateParams {
  template_uuid: string;
}

export interface SetDefaultTemplateResponse {}

export interface SendTemplateParams {
  project_uuid?: string;
  template_uuid: string;
  form_data: FormData;
}

export interface SendTemplateResponse {}

export interface SetProjectStageParams {
  uuid: string;
  stage: ProjectStage;
}

export interface SetProjectStageResponse {}

export interface SetProjectPriorityParams {
  uuid: string;
  priority: Priority;
}

export interface SetProjectPriorityResponse {}

export interface AddProjectNoteParams {
  project_uuid: string;
  title: string;
  note: string;
  update_note_uuid?: null | string;
}

export interface AddClientNoteParams {
  client_uuid: string;
  title: string;
  note: string;
  update_note_uuid?: null | string;
}

export interface ListListingViewersParams {
  listing_uuid: string;
  page_size?: number;
  page_index?: number;
}

export interface ListListingViewersResponse {
  viewers: RealEstateClientInfo[];
  num_of_pages: number;
}

export interface ChangeListingViewerParams {
  prospect_uuid: string;
  listing_uuid: string;
  action: ChangeListingViewerAction;
  new_stage?: ListingStage;
}

export interface ChangeListingViewerResponse {}

export interface AddProjectNoteResponse {}

export interface DeleteProjectNoteParams {
  project_uuid: string;
  note_uuid: string;
}

export interface DeleteProjectNoteResponse {}

export interface AddClientNoteResponse {}

export interface DeleteClientNoteParams {
  client_uuid: string;
  note_uuid: string;
}

export interface DeleteClientNoteResponse {}

export interface ListSalesParams {
  page_index: number;
  page_size: number;
  status?: SaleStatus;
}

export interface ListSalesResponse {
  sales: Sale[];
  total: number;
  page_size: number;
}

export interface EditGeneralSettingsParams {
  user_name?: string;
  phone_number?: string;
  date_of_birth?: string;
  business_name?: string;
  business_id?: string;
  business_address?: string;
  business_phone?: string;
  business_website?: string;
  vat_number?: number;
  country?: string;
  currency?: string;
  time_zone?: string;
  invoice_start_number?: number;
  signup_details_completed?: boolean;
}

export interface EditGeneralSettingsResponse {}

export interface AskChangeEmailParams {
  new_email: string;
}

export interface AskChangeEmailResponse {
}

export interface VerifyChangeEmailParams {
  new_email_code: string;
  old_email_code: string;
}

export interface VerifyChnageEmailResponse {
}

export interface ListGeneralSettingsParams {}

export interface ListGeneralSettingsResponse {
  user_name?: string;
  email?: string;
  phone_number?: string;
  date_of_birth?: string;
  business_name?: string;
  business_id?: string;
  business_address?: string;
  business_phone?: string;
  business_website?: string;
  vat_number?: number;
  country?: string;
  currency?: string;
  time_zone?: string;
  invoice_start_number?: number;
  signup_details_completed?: boolean;
}

export interface GetUserCurrencyParams {}

export interface GetUserCurrencyResponse {
  currency_code?: string;
}

export interface GetUserTimezoneResponse {
  timezone?: string;
}

export interface GetUserCountryResponse {
  country?: string;
}

export interface GetUserTimezoneNameResponse {
  timezone_name?: string;
}


export interface GetMoreMessagesParams {
  conversation_id: string;
  start_index: number;
  end_index: number;
}

export interface GetMoreMessagesResponse extends Conversation {

}

export interface EventRepetitiveness {
  every_what_period: TimePeriod;
  interval: number;
  until: number | string;
}

export interface AddEventParams {
  name: string;
  date: string;
  is_all_day?: boolean;
  end_date?: string;
  client_uuid?: string;
  location?: string;
  description?: string;
  tag?: string;
  repetitiveness?: EventRepetitiveness;
  project_uuid?: string;
  company_name?: string;
  is_meeting?: boolean
}

export interface AddEventResponse {
  uuid: string;
}

export interface EditEventParams {
  uuid: string;
  name: string;
  date?: string;
  is_all_day?: boolean;
  end_date?: string;
  location?: string;
  description?: string;
  tag?: string;
  client_uuid?: string;
  is_from_google?: boolean;
  my_status?: string;
}

export interface EditEventResponse {
}

export interface DeleteEventParams {
  uuid: string;
}

export interface DeleteEventResponse {}

export interface ListEventsParams {
  start_date: string;
  end_date: string;
  company_name?: string;
}

export enum EventType {
  ONE_TIME = "one_time",
  RECURRING = "recurring",
}

export interface Event {
  uuid: string;
  type: EventType;
  name: string;
  is_all_day: boolean;
  date: string;
  end_date?: string;
  location?: string;
  description?: string;
  tag?: string;
  last_occurrence?: string;
  repetitiveness?: EventRepetitiveness;
  project_uuid?: string;
  client_uuid?: string;
}

export interface ListEventsResponse {
  events: Event[];
  total: number;
  page_size: number;
}

export interface SentTemplate {
  template_uuid: string;
  template_kind: string
  file_uuid: string;
  issuing_date: string;
  doc_number: string;
  client_uuid: string;
  client_name: string;
}


export interface ListSentTemplatesParams {
  page_size?: number;
  page_index?: number;
  retrieve_options?: RetrieveOptions;
}


export interface ListSentTemplatesResponse {
  items: SentTemplate[];
  num_of_pages: number;
  total: number;
}


export interface SetNewFileNameParams {
  file_uuid: string;
  new_name: string;
}


export interface SetNewFileNameResponse {
}

export interface GetFileParams {
  token: string;
  get_type: GetFileType;
}

export interface GetFileResponse {
  binfile: ArrayBuffer;
  mime_type: string;
}

export interface CheckoutSubscriptionParams {
  plan?: null | SubscriptionPlan;
}

export interface CheckoutSubscriptionResponse {
  url: string;
}

export interface GotoPortalParams {
  return_url: string;
}

export interface GotoPortalResponse {}

export type MeetingType = {
  uuid?: string;
  name: string;
  duration: string;
  location: string
  isPublic: boolean;
  price?: string;
  description?: string;
  blockOffTimeBefore?: string;
  blockOffTimeAfter?: string;
  minimumNoticeTime?: string;
}

export interface AddMeetingTypeParams {
  name: string;
  duration: string;
  location: string;
  isPublic: boolean;
  price?: string;
  description?: string;
  blockOffTimeBefore?: string;
  blockOffTimeAfter?: string;
  minimumNoticeTime?: string;
}

export interface AddMeetingTypeResponse {
  uuid: string;
}

export interface DeleteMeetingTypeParams {
  uuid: string;
}

export type EditMeetingTypeParams = AddMeetingTypeParams & DeleteMeetingTypeParams;

export interface EditMeetingTypeResponse {
}

export interface DeleteMeetingTypeResponse {
}

export interface ListMeetingTypesResponse {
  meeting_types: MeetingType[];
}

export enum DayOfWeek {
  SUNDAY = 'Sunday',
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
}

export interface AvailableMeetingTime {
  day: DayOfWeek;
  start_time: string[];
  end_time: string[];
}

export interface EditAvailableMeetingTimesParams {
  available_meeting_times: AvailableMeetingTime[];
}

export interface EditAvailableMeetingTimesResponse {}

export interface ListAvailableMeetingTimesPortalParams {
  date: string;
  company_name: string;
  meeting_type_uuid: string;
}

export interface ListAvailableMeetingTimesResponse {
  available_meeting_times: AvailableMeetingTime[];
}

export interface ListAvailableMeetingTimesPortalResponse {
  available_meeting_times: string[];
}

export interface AddDescriptionParams {
  description: string;
}

export interface AddDescriptionResponse {
}

export interface GetPortalParams {
  company_name?: string;
}

export interface GetMeetingTypeParams {
  uuid: string;
  company_name: string;
}

export interface GetMeetingTypeResponse {
  meeting_type: MeetingType
}

export interface SendConfirmationMeetingEmailParams {
  event_uuid: string;
  company_name?: string;
  client_uuid: string;
  email: string;
}

export interface SendConfirmationMeetingEmailResponse {
}

export interface ListExpensesResponse {
  expenses: Expense[];
  total: number;
  num_of_pages: number;
}

export interface ListAllExpensesResponse {
  expenses: Expense[];
}

export interface ListExpensesParams {
  retrieve_options?: RetrieveOptions;
  page_size?: number;
  page_index?: number;
  client_uuid?: string;
  project_uuid?: string;
}

export interface AddExpenseParams {
  description: string;
  amount: number;
  date: string;
  payment_method?: PaymentMethod;
  payment_hint?: string;
  project_id?: string;
  client_id?: string;
  category?: ExpensesCategories;
}

export interface AddExpenseResponse {
  uuid: string;
}

export interface DeleteExpenseParams {
  uuid: string;
}

export interface DeleteExpenseResponse {
}

export interface EditExpenseParams {
  uuid: string;
  description: string;
  amount: number;
  date: string;
  payment_method?: PaymentMethod;
  payment_hint?: string;
  project?: string;
  client?: string;
  category?: ExpensesCategories;
}

export interface EditExpenseResponse {
}

export interface ListMeetingsParams {
  meeting_name: string;
}

export interface ListMeetingsResponse {
  events: Event[];
}

export interface RenderTemplateParams {
  template_uuid: string;
  details: any;
  records?: DomChangeRecord[];
}

export interface SendPdfParams {
  pdf_data: Blob;
  token: string;
  is_client: string;
}

export interface SendPdfResponse {}

export interface ListFilesParams {
  folder: string;
}

export interface AddLibraryFavoriteParams {
  file_name: string;
}

export interface AddLibraryFavoriteResponse {
}

export interface ListLibraryFavoritesParams {
}

export interface ListLibraryFavoritesResponse {
  favorites: string[];
}

export interface DeleteLibraryFavoriteParams {
  file_name: string;
}

export interface DeleteLibraryFavoriteResponse {
}
export interface VerifyPhoneParams {
  phone: string;
}

export interface VerifyPhoneResponse {
  code: string;
}

export interface SendPdfParams {
  pdf_data: Blob;
  token: string;
  is_client: string;
}

export interface SendPdfResponse {}
export interface ListFilesParams {
  folder: string;
}

export interface AddLibraryFavoriteParams {
  file_name: string;
}

export interface AddLibraryFavoriteResponse {
}

export interface ListLibraryFavoritesParams {
}

export interface ListLibraryFavoritesResponse {
  favorites: string[];
}

export interface DeleteLibraryFavoriteParams {
  file_name: string;
}

export interface DeleteLibraryFavoriteResponse {
}
export interface VerifyPhoneParams {
  phone: string;
}

export interface VerifyPhoneResponse {
  code: string;
}

export interface RefreshGoogleTokenParams {
}

export interface RefreshGoogleTokenResponse {
  token: string;
}

export interface ListGoogleCalendarEventsParams {
  token: string;
  start_date: string;
  end_date: string;
}

export interface ListGoogleCalendarEventsResponse {
  events: any;
}