import ElevatorDialog from '../../components/ElevatorDialog';
import { Sale } from '../../api/types';
import { Divider, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { format } from 'date-fns';
import SaleStatusChip from '../../components/SaleStatusChip';
import { PaymentMethodLabel } from '../../components/PaymentMethodLabel';
import { formatMoney, zonedDate } from '../../util/common';
import { useAtomValue } from 'jotai';
import { userCurrencyAtom } from '../../layouts/Main';

export interface ViewSaleDialogProps {
  sale: null | Sale;
  open: boolean;
  onClose: () => void;
}

export default function ViewSaleDialog(props: ViewSaleDialogProps) {

  const currencyCode = useAtomValue(userCurrencyAtom);
  const created_at_date = useMemo(() => zonedDate(props.sale?.created_at ?? ""), [props.sale?.created_at]);

  return (
    <ElevatorDialog open={props.open} onClose={props.onClose}>
      {props.sale && (
        <>
          <Typography variant="h5" fontFamily="Helvetica Neue" fontWeight={500} sx={{ mt: 1 }}>Sale details</Typography>

          <Divider sx={{ my: 3 }} />

          <Stack direction="row" justifyContent="space-between">
            <Typography>Client</Typography>
            <Typography>{props.sale.client}</Typography>
          </Stack>

          <Typography color="text.secondary" sx={{ mt: 1 }}>
            {created_at_date ? (
              created_at_date.toFormat("EEEE, MMMM dd, yyyy, hh:mm b")
            ) : (
              "Invalid date"
            )}
          </Typography>

          <Divider sx={{ my: 3 }} />

          <Stack direction="row" justifyContent="space-between">
            <Typography>Status</Typography>
            <SaleStatusChip value={props.sale.status} />
          </Stack>

          <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
            <Typography>Payment method</Typography>
            <PaymentMethodLabel method={props.sale.payment_method} hint={props.sale.payment_hint} />
          </Stack>

          <Divider sx={{ my: 3 }} />

          <Typography color="text.secondary">Item</Typography>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
            <Typography>{props.sale.item}</Typography>
            <Typography fontWeight="bold">{formatMoney(props.sale.amount, currencyCode)}</Typography>
          </Stack>

          <Divider sx={{ my: 3 }} />

          <Stack direction="row" justifyContent="space-between">
            <Typography color="text.secondary">Subtotal</Typography>
            <Typography fontWeight="bold" color="text.secondary">{formatMoney(props.sale.amount, currencyCode)}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
            <Typography color="text.secondary">Tax</Typography>
            <Typography fontWeight="bold" color="text.secondary">{formatMoney(props.sale.tax, currencyCode)}</Typography>
          </Stack>

          <Divider sx={{ my: 3 }} />

          <Stack direction="row" justifyContent="space-between">
            <Typography>Total</Typography>
            <Typography fontSize={24} fontFamily="Helvetica Neue">{formatMoney(props.sale.amount + props.sale.tax, currencyCode)}</Typography>
          </Stack>
        </>
      )}
    </ElevatorDialog>
  );
}