import React, { useRef, useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import { MynkFormDialog } from '../components/MynkForm';
import { CSVLink } from 'react-csv';
import { MynkFormControl } from '../components/MynkForm';
import { MynkFormControlKind } from '../components/MynkForm/controlTypes';
import { DateTime } from 'luxon';

interface DownloadDialogProps {
  open: boolean;
  onClose: () => void;
  csvReport: any;
}

export default function DownloadDialog(props: DownloadDialogProps) {
  const csvLinkRef = useRef<any>(null);
  const [csvData, setCsvData] = useState<any[] | null>(null);

  const filterDataByDate = (data: any[], startDate: DateTime | null, endDate: DateTime | null) => {
    return data.filter(item => {
      const itemDate = DateTime.fromISO(item.date).startOf('day');

      if (startDate && endDate) {
        return itemDate >= startDate.startOf('day') && itemDate <= endDate.startOf('day');
      } else if (startDate) {
        return itemDate >= startDate.startOf('day');
      } else if (endDate) {
        return itemDate <= endDate.startOf('day');
      }
      return true; // No date filtering if both dates are null
    });
  };

  const handleSubmit = (values: any) => {
    const startDate = values['startDate'] ? DateTime.fromMillis(values['startDate'].ts).startOf('day') : null;
    const endDate = values['endDate'] ? DateTime.fromMillis(values['endDate'].ts).startOf('day') : null;

    const filteredData = filterDataByDate(props.csvReport.data, startDate, endDate);
    setCsvData(filteredData);
  };

  useEffect(() => {
    if (csvLinkRef.current && csvData) {
      csvLinkRef.current.link.click();
      props.onClose();
    }
  }, [csvData]);

  return (
    <MynkFormDialog
      title="Download"
      open={props.open}
      onClose={props.onClose}
      action="Download"
      onSubmit={handleSubmit}
    >
      <Stack width={"20rem"} spacing={"1rem"}>
        <MynkFormControl
          kind={MynkFormControlKind.DATE_SELECT}
          name="startDate"
          label="From"
          default=""
        />
        <MynkFormControl
          kind={MynkFormControlKind.DATE_SELECT}
          name="endDate"
          label="To"
          default=""
        />
      </Stack>

      <CSVLink
        {...props.csvReport}
        data={csvData ?? []}
        ref={csvLinkRef}
        style={{
          display: 'none',
        }}
      >
        Download CSV
      </CSVLink>
    </MynkFormDialog>
  );
}
