import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "./custom-big-calendar.css";
import {
  Calendar,
  luxonLocalizer,
  Navigate,
  ToolbarProps,
  DateLocalizer,
  SlotInfo,
} from "react-big-calendar";
import { DateTime, Settings } from "luxon";
import {
  Card,
  Stack,
  Typography,
  Box,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Checkbox,
} from "@mui/material";
import {
  useDeleteEventMutation,
  useEditEventMutation,
  useListEventsQuery,
  useListGoogleCalendarEventsQuery,
} from "../../../../api/scheduler";
import { useRefreshGoogleTokenMutation } from "../../../../api/integrations";
import { useCallback, useEffect, useRef, useState } from "react";
import { zonedDate } from "../../../../util/common";
import ElevatorDialog from "../../../../components/ElevatorDialog";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NotesIcon from "@mui/icons-material/Notes";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RepeatIcon from "@mui/icons-material/Repeat";
import { DeleteTaskParams, EventRepetitiveness, Task, RefreshGoogleTokenParams, GetGoogleCalendarEventsParams } from "../../../../api/types";
import nextIcon from "../../../../assets/icons/go-next-arrow.svg";
import previousIcon from "../../../../assets/icons/go-before-arrow.svg";
import TodayIcon from "@mui/icons-material/Today";
import { ChipSelect } from "../../../../components/ChipSelect";
import { CALENDAR_VIEW_TYPE_OPTIONS } from "../../../../components/ChipSelect/commonOptions";
import { atom, useAtom, useAtomValue } from "jotai";
import ActionMenuButton, {
  ActionMenuButtonVariant,
} from "../../../../components/ActionMenuButton";
import { Height, Pause, Tune } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmActionDialog from "../../../../components/ConfirmActionDialog";
import { closeSnackbar, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { EditEventDialog } from "../../../../dialogs/EditEvent";
import Holidays from "date-holidays";
import { userCountryAtom } from "../../../../layouts/Main";
import { start } from "repl";
import { AddEventDialog } from "../../../../dialogs/AddEvent";
import PauseIcon from "@mui/icons-material/Pause";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Clear } from "@mui/icons-material";
import ThreeDotsButton from "../../../../components/ThreeDotsButton";
import LinkIcon from "@mui/icons-material/Link";
import { useNavigate } from "react-router-dom";
import { PATHS, WorkflowPath, makePath } from "../../../../paths";
import {
  useDeleteTaskMutation,
  useListTasksQuery,
  useSetTaskStatusMutation,
} from "../../../../api/tasks";
import TagIcon from "@mui/icons-material/Tag";
import { useGetClientQuery } from "../../../../api/clients";
import { ShowTaskDialog, openedDeleteAtom, openedEditAtom } from "../../../../components/TaskView";
import { EventEmitter } from "stream";
import { EditTaskDialog } from "../../../../dialogs/EditTask";
import axios from "axios";
import { AxiosError } from "axios";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

const DragAndDropCalendar = withDragAndDrop(Calendar);

const viewTypeAtom = atom("month");
const deleteEventOpenAtom = atom(false);
const EditEventOpenAtom = atom(false);
const selectedEventAtom = atom<BigCalendarEvent | undefined>(undefined);
const selectedShowEventTypesAtom = atom<string[]>(["holidays", "tasks"]);

function CustomToolbar(props: ToolbarProps) {
  const [viewType, setViewType] = useAtom(viewTypeAtom);

  useEffect(() => {
    setViewType("month");
  }, []);

  const goToDayView = () => {
    props.onView("day");
    setViewType("day");
  };

  const goToWeekView = () => {
    props.onView("week");
    setViewType("week");
  };

  const goToMonthView = () => {
    props.onView("month");
    setViewType("month");
  };

  const goToBack = () => {
    props.onNavigate(Navigate.PREVIOUS);
  };

  const goToNext = () => {
    props.onNavigate(Navigate.NEXT);
  };

  const goToToday = () => {
    props.onNavigate(Navigate.TODAY);
  };

  const goToSpecificDate = (newDate: DateTime) => {
    props.onNavigate(Navigate.DATE, newDate);
  };

  const onChagneView = (newView: string) => {
    setViewType(newView);
    if (newView === "week") {
      goToWeekView();
    } else if (newView === "month") {
      goToMonthView();
    } else if (newView === "day") {
      goToDayView();
    }
  };

  const [showEventsType, setShowEventsType] = useAtom(
    selectedShowEventTypesAtom
  );

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    newShowTypes: string[]
  ) => {
    setShowEventsType(newShowTypes);
  };

  return (
    <Stack direction="row" sx={{ margin: "1rem" }}>
      <Stack
        alignItems="flex-start"
        spacing="0.3rem"
        sx={{ width: viewType === "day" ? "30rem" : "15rem" }}
      >
        <Typography variant="h2">
          {viewType === "month" ? (
            <Stack direction="row">
              <Typography variant="h2" fontFamily={"Helvetica Neue Bold"}>
                {DateTime.fromJSDate(props.date).toFormat("MMMM")}&nbsp;
              </Typography>
              <Typography variant="h2">
                {DateTime.fromJSDate(props.date).toFormat("yyyy")}
              </Typography>
            </Stack>
          ) : viewType === "week" ? (
            `${DateTime.fromJSDate(props.date)
              .startOf("week")
              .toFormat("dd MMM")} - 
              ${DateTime.fromJSDate(props.date)
                .startOf("week")
                .plus({ days: 6 })
                .toFormat("dd MMM")}`
          ) : viewType === "day" ? (
            DateTime.fromJSDate(props.date).toFormat("EEEE, dd MMMM yyyy")
          ) : (
            ""
          )}
        </Typography>
        <Stack
          alignItems="center"
          alignContent="center"
          sx={{ width: viewType === "day" ? "30rem" : "15rem" }}
        >
          <Stack direction="row">
            <IconButton
              onClick={goToBack}
              sx={{ width: "2.5rem", height: "2.5rem" }}
            >
              {" "}
              <Box
                component="img"
                src={previousIcon}
                sx={{ width: "2.5rem", height: "2.5rem" }}
              />{" "}
            </IconButton>
            <IconButton
              onClick={goToToday}
              sx={{ width: "2.5rem", height: "2.5rem" }}
            >
              {" "}
              <TodayIcon
                sx={{ width: "2rem", height: "2rem", color: "#404040" }}
              />{" "}
            </IconButton>

            <IconButton
              onClick={goToNext}
              sx={{ width: "2.5rem", height: "2.5rem" }}
            >
              {" "}
              <Box
                component="img"
                src={nextIcon}
                sx={{ width: "2.5rem", height: "2.5rem" }}
              />{" "}
            </IconButton>
          </Stack>
        </Stack>
      </Stack>

      <Box sx={{ flex: 1 }} />

      <Stack direction="row" spacing="1rem">
        <ToggleButtonGroup
          value={showEventsType}
          onChange={handleToggle}
          sx={{
            height: "2.47rem",
            mt: "-0.2rem",
          }}
        >
          <ToggleButton
            value="holidays"
            sx={{
              "&.MuiToggleButton-root": {
                border: "none",
                borderRadius: "1.6rem",
                "&.Mui-selected": {
                  bgcolor: "transparent",
                },
              },
            }}
          >
            <Stack direction="row" alignItems="center">
              <Checkbox
                readOnly
                checked={showEventsType.includes("holidays")}
                sx={{ pointerEvents: "none" }}
              ></Checkbox>
              <Typography sx={{ textTransform: "none" }}>Holidays</Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton
            value="tasks"
            sx={{
              "&.MuiToggleButton-root": {
                border: "none",
                borderRadius: "1.6rem",
                "&.Mui-selected": {
                  bgcolor: "transparent",
                },
              },
            }}
          >
            <Stack direction="row" alignItems="center">
              <Checkbox
                readOnly
                checked={showEventsType.includes("tasks")}
                sx={{ pointerEvents: "none" }}
              ></Checkbox>
              <Typography sx={{ textTransform: "none" }}>Tasks</Typography>
            </Stack>
          </ToggleButton>
        </ToggleButtonGroup>
        <ChipSelect
          options={CALENDAR_VIEW_TYPE_OPTIONS}
          value={viewType}
          sx={{
            width: "8rem",
            height: "2.4rem",
            boxShadow: "0px 1px 5px rgba(117, 179, 255, 0.35)",
            bgcolor: "white",
          }}
          onChange={(newView: string) => {
            setViewType(newView);
            onChagneView(newView);
          }}
        />
      </Stack>
    </Stack>
  );
}

enum threeDotsOptions {
  EDIT = "edit-event",
  DELETE = "delete-event",
}

const DOTS_MENU = [
  {
    id: threeDotsOptions.EDIT,
    label: "Edit",
  },
  {
    id: threeDotsOptions.DELETE,
    label: "Delete",
  },
];

interface ShowEventDialogProps {
  open: boolean;
  onClose: () => void;
  event?: BigCalendarEvent;
}
function ShowEventDialog(props: ShowEventDialogProps) {
  const [openDelete, setOpenDelete] = useAtom(deleteEventOpenAtom);
  const [openEdit, setOpenEdit] = useAtom(EditEventOpenAtom);

  const handleClickItem = (id: string) => {
    switch (id) {
      case threeDotsOptions.EDIT:
        setOpenEdit(true);
        break;
      case threeDotsOptions.DELETE:
        setOpenDelete(true);
        break;
    }
  };

  const navigate = useNavigate();
  const eventTag = EVENT_TAGS.find(
    (tag) => tag.tag.valueOf() === props.event?.tag
  );

  const { data, isPending } = useGetClientQuery({
    uuid: props.event?.clientUuid ?? "",
  });

  return (
    <ElevatorDialog open={props.open} onClose={props.onClose} customCloseButton>
      <Stack
        direction="row"
        sx={{ position: "fixed", right: "3rem", top: "2.5rem" }}
      >
        {!props.event?.isHoliday && (
          <ThreeDotsButton
            menu={DOTS_MENU}
            sx={{ height: "1.2rem" }}
            onClickItem={handleClickItem}
            children={null}
          />
        )}
        <IconButton
          sx={{ width: "2rem", height: "2rem" }}
          onClick={props.onClose}
        >
          <Clear sx={{ width: "1.7rem", height: "1.7rem" }} />
        </IconButton>
      </Stack>
      <Stack sx={{ width: "28rem", my: "1.5rem" }} spacing="1.3rem">
        <Stack direction="row" spacing="0.7rem">
          <Box>
            <Box
              sx={{
                width: "1.3rem",
                height: "1.3rem",
                bgcolor: eventTag?.color ?? "black",
                borderRadius: "0.3rem",
                mt: "0.5rem",
              }}
            />
          </Box>
          <Typography variant="h1" fontSize="2rem" sx={{ direction: "rtl", unicodeBidi: "embed" }}>
            {props.event?.title}
          </Typography>
        </Stack>
        <Stack spacing="0.6rem" sx={{ pt: "0.5rem" }}>
          <Stack direction="row" spacing="0.7rem">
            <CalendarMonthIcon
              sx={{ width: "1.3rem", height: "1.3rem", mt: "0.2rem" }}
            />
            {props.event?.allDay ? (
              props.event?.start.toDateString() ===
              props.event?.end.toDateString() ? (
                <Typography fontWeight="bold">
                  {DateTime.fromJSDate(props.event?.start).toFormat(
                    "EEEE, LLL dd, yyyy"
                  )}{" "}
                  (all day)
                </Typography>
              ) : (
                <Typography fontWeight="bold">
                  {DateTime.fromJSDate(props.event?.start).toFormat(
                    "EEEE, LLL dd, yyyy"
                  )}{" "}
                  -{" "}
                  {DateTime.fromJSDate(props.event?.end).toFormat(
                    "EEEE, LLL dd, yyyy"
                  )}{" "}
                  (all day)
                </Typography>
              )
            ) : props.event?.start.toDateString() ===
              props.event?.end.toDateString() ? (
              <Typography fontWeight="bold">
                {DateTime.fromJSDate(props.event?.start).toFormat(
                  "EEEE, LLL dd, yyyy, HH:mm"
                )}{" "}
                - {DateTime.fromJSDate(props.event?.end).toFormat("HH:mm")}
              </Typography>
            ) : (
              <Typography fontWeight="bold">
                {DateTime.fromJSDate(props.event?.start).toFormat(
                  "EEEE, LLL dd, yyyy, HH:mm"
                )}{" "}
                -{" "}
                {DateTime.fromJSDate(props.event?.end).toFormat(
                  "EEEE, LLL dd, yyyy, HH:mm"
                )}
              </Typography>
            )}
          </Stack>
          {/* Location */}
          {!props.event?.isHoliday && (
            <>
              <Stack direction="row" spacing="0.7rem">
                <LocationOnIcon
                  sx={{ width: "1.3rem", height: "1.3rem", mt: "0.2rem" }}
                />
                <Typography>{props.event?.location || "N/A"}</Typography>
              </Stack>
              <Stack direction="row" spacing="0.7rem">
                <NotesIcon
                  sx={{ width: "1.3rem", height: "1.3rem", mt: "0.2rem" }}
                />
                <Typography sx={{ whiteSpace: "pre-line" }}>
                  {props.event?.description || "N/A"}
                </Typography>
              </Stack>
            </>
          )}

          {props.event?.repetitiveness && (
            <Stack direction="row" spacing="0.7rem">
              <RepeatIcon
                sx={{ width: "1.3rem", height: "1.3rem", mt: "0.2rem" }}
              />
              <Typography>
                Every {props.event?.repetitiveness?.interval}{" "}
                {props.event?.repetitiveness?.every_what_period}s until{" "}
                {typeof props.event?.repetitiveness?.until == "number"
                  ? `${props.event?.repetitiveness?.until} occurrences`
                  : zonedDate(
                      props.event?.repetitiveness?.until ?? ""
                    ).toFormat("LLL dd, yyyy")}
              </Typography>
            </Stack>
          )}
          {props.event?.repetitiveness && (
            <Stack direction="row" spacing="0.7rem">
              <PauseIcon
                sx={{ width: "1.3rem", height: "1.3rem", mt: "0.2rem" }}
              />
              <Typography>
                Last occurence is{" "}
                {zonedDate(props.event.lastOccurence ?? "").toFormat(
                  "LLL dd, yyyy"
                )}
              </Typography>
            </Stack>
          )}
          {props.event?.tag && (
            <Stack direction="row" spacing="0.7rem">
              <TagIcon
                sx={{ width: "1.3rem", height: "1.3rem", mt: "0.2rem" }}
              />{" "}
              <Typography>{`${props.event?.tag
                .charAt(0)
                .toUpperCase()}${props.event.tag.slice(1)}`}</Typography>
            </Stack>
          )}
          {props.event?.link && (
            <Stack direction="row" spacing="0.7rem">
              <LinkIcon sx={{ width: "1.3rem", height: "1.3rem", mt: "0.2rem" }} />{" "}
              <Typography>
                <a
                  href={props.event.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#1a73e8", textDecoration: "underline" }} // Styling the link
                >
                  {props.event.link}
                </a>
              </Typography>
            </Stack>
          )}
          {props.event?.attendees && (
            <Stack direction="row" spacing="0.7rem">
              <PersonIcon sx={{ width: "1.3rem", height: "1.3rem", mt: "0.2rem" }} />
              <Stack>
                {props.event.attendees.map((attendee, index) => {
                  let statusIcon;

                  switch (attendee.responseStatus) {
                    case 'accepted':
                      statusIcon = <CheckCircleOutlineIcon sx={{ color: "green", mr: 0.5, width: "1.4rem", height: "1.4rem" }} />;
                      break;
                    case 'declined':
                      statusIcon = <HighlightOffIcon sx={{ color: "red", mr: 0.5, width: "1.4rem", height: "1.4rem" }} />;
                      break;
                    case 'tentative':
                    default:
                      statusIcon = <HelpOutlineIcon sx={{ color: "grey", mr: 0.5, width: "1.4rem", height: "1.4rem" }} />;
                      break;
                  }

                  return (
                    <Stack key={index} direction="row" alignItems="center" spacing="0.5rem">
                      {statusIcon}
                      <Typography>{attendee.email}</Typography>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          )}
          {props.event?.clientUuid && data?.client && data?.client.uuid && (
            <Stack
              direction="row"
              spacing="0.7rem"
              onClick={() =>
                navigate(
                  makePath(
                    WorkflowPath.PHOTOGRAPHY,
                    PATHS.viewContact(props.event?.clientUuid ?? "").general
                  )
                )
              }
              sx={{ cursor: "pointer" }}
            >
              <PersonIcon
                sx={{ width: "1.3rem", height: "1.3rem", mt: "0.2rem" }}
              />
              <Typography
                sx={{ color: "#75b3ff", textDecoration: "underline" }}
              >
                {data?.client.full_name}
              </Typography>
            </Stack>
          )}
          {props.event?.projectUuid && (
            <Stack
              direction="row"
              spacing="0.7rem"
              onClick={() =>
                navigate(
                  makePath(
                    WorkflowPath.PHOTOGRAPHY,
                    PATHS.viewProject(props.event?.projectUuid ?? "").general
                  )
                )
              }
              sx={{ cursor: "pointer" }}
            >
              <Typography
                sx={{ color: "#75b3ff", textDecoration: "underline" }}
              >
                View project
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </ElevatorDialog>
  );
}

export enum EventTagName {
  WORK = "work",
  PERSONAL = "personal",
  PROJECT = "project",
  HOLIDAY = "holiday",
  TASK = "task",
}

export interface EventTag {
  tag: string;
  color: string;
}

export const EVENT_TAGS: EventTag[] = [
  { tag: EventTagName.WORK, color: "#75B3FF" },
  { tag: EventTagName.PERSONAL, color: "#37CE4A" },
  { tag: EventTagName.PROJECT, color: "#b261ff" },
  { tag: EventTagName.HOLIDAY, color: "#FF7A00" },
  { tag: EventTagName.TASK, color: "#edc900" },
];

export interface BigCalendarEvent {
  isHoliday?: boolean;
  uuid?: string;
  title: string;
  allDay: boolean;
  start: DateTime;
  end: DateTime;
  location?: string;
  description?: string;
  tag?: EventTagName;
  repetitiveness?: EventRepetitiveness;
  lastOccurence?: string;
  isDraggable?: boolean;
  projectUuid?: string;
  clientUuid?: string;
  attendees?: { email: string; responseStatus: string }[];
  link?: string;
  myStatus?: string;
  isFromGoogle?: boolean;
}

interface CalendarCardProps {}

type Holiday = {
  date: string;
  start: Date;
  end: Date;
  name: string;
  rule?: string;
};

export default function CalendarCard(props: CalendarCardProps) {
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [tasksEvents, setTasksEvents] = useState<Task[]>([]);
  const countryCode = useAtomValue(userCountryAtom);
  const selectedShowEventTypes = useAtomValue(selectedShowEventTypesAtom);
  const [openedEditTask, setOpenedEditTask] = useAtom(openedEditAtom);

  const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 1 });
  const [loadFrom, setLoadFrom] = useState(
    DateTime.now().startOf("month").minus({ days: 7 }).toISO()
  );
  const [loadTo, setLoadTo] = useState(
    DateTime.now().startOf("month").plus({ months: 1, days: 7 }).toISO()
  );
  const { data: tasksData, isPending: tasksisPending } = useListTasksQuery({
    due_days_min: Math.round(
      DateTime.fromISO(loadFrom).diff(DateTime.now(), "days").toObject().days
    ),
    due_days_max: Math.round(
      DateTime.fromISO(loadTo).diff(DateTime.now(), "days").toObject().days
    ),
    completed: false,
  });
  const { data, isPending } = useListEventsQuery({
    start_date: loadFrom,
    end_date: loadTo,
  });

  const [googleToken, setGoogleToken] = useState<string | null>(null);
  const {data: googleEvents, refetch: refetchGoogleEvents} = useListGoogleCalendarEventsQuery({
    token: googleToken ?? "",
    start_date: loadFrom,
    end_date: loadTo,
  });

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const refreshGoogleToken = useRefreshGoogleTokenMutation({
    async onSuccess(data) {
      setGoogleToken(data.token);
    },
    onError: (error) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, RefreshGoogleTokenParams>;
        const detail = (axiosError.response?.data as { detail?: string })?.detail;
        setErrorMsg(detail ?? "");
      }
    },
  });

  useEffect(() => {
    refreshGoogleToken.mutate({});
  }, []);

  const transformGoogleEvent = (googleEvent: any): BigCalendarEvent => {
    const startDate = zonedDate(googleEvent.start.dateTime || googleEvent.start.date).toJSDate();
    const endDate = zonedDate(googleEvent.end.dateTime || googleEvent.end.date).toJSDate();
  
    // Extract attendees if they exist
    const attendees = googleEvent.attendees
      ? googleEvent.attendees.map((attendee: any) => ({
          email: attendee.email,
          responseStatus: attendee.responseStatus, // accepted, declined, tentative
        }))
      : [];
  
    // Extract Google Meet link if it exists
    const meetLink = googleEvent.conferenceData?.entryPoints?.find(
      (entryPoint: any) => entryPoint.entryPointType === 'video'
    )?.uri;

    const myStatus = googleEvent.attendees?.find((attendee: any) => attendee.self)?.responseStatus 
      || googleEvent.organizer?.self && googleEvent.organizer.responseStatus 
      || 'pending';

    return {
      uuid: googleEvent.id,
      title: googleEvent.summary || 'Untitled Event',
      allDay: !googleEvent.start.dateTime,
      start: startDate,
      end: endDate,
      location: googleEvent.location || '',
      description: googleEvent.description || '',
      tag: EventTagName.WORK,
      isDraggable: false,
      attendees: attendees,
      link: meetLink || '',
      myStatus: myStatus,
      isFromGoogle: true,
    };
  };

  useEffect(() => {
    if (countryCode && selectedShowEventTypes.includes("holidays")) {
      const hd = new Holidays();
      hd.init(countryCode);
      const holidays = hd.getHolidays(DateTime.now().year);
      setHolidays(holidays);
    } else {
      setHolidays([]);
    }

    if (selectedShowEventTypes.includes("tasks")) {
      setTasksEvents(tasksData?.tasks ?? []);
    } else {
      setTasksEvents([]);
    }
  }, [countryCode, selectedShowEventTypes, tasksData]);

  const [current, setCurrent] = useState<BigCalendarEvent[]>([]);

  useEffect(() => {
    let eventsList: BigCalendarEvent[] = [];

    holidays.forEach((holiday) => {
      const curr: BigCalendarEvent = {
        isHoliday: true,
        title: holiday.name,
        allDay: true,
        start: holiday.start,
        end: holiday.end,
        tag: EventTagName.HOLIDAY,
        description: holiday.rule ?? "",
        isDraggable: false,
      };
      eventsList.push(curr);
    });

    tasksEvents.forEach((taskEvent) => {
      const curr: BigCalendarEvent = {
        isHoliday: true,
        title: taskEvent.title,
        uuid: taskEvent.uuid,
        allDay: true,
        start: zonedDate(taskEvent.due_date).toJSDate(),
        end: zonedDate(taskEvent.due_date).plus({ minute: 1 }).toJSDate(),
        isDraggable: false,
        tag: EventTagName.TASK,
      };
      eventsList.push(curr);
    });
    data?.events.forEach((event) => {
      if (event.repetitiveness) {
        // Calculate the start and end dates for each occurrence of the recurring event
        let startDate = zonedDate(event.date);
        let endDate = event.end_date
          ? zonedDate(event.end_date)
          : zonedDate(event.date).plus({ seconds: 1 });

        if (typeof event.repetitiveness.until === "number") {
          // If until is a number, add each occurrence of the recurring event to the eventsList
          for (let i = 0; i < event.repetitiveness.until; i++) {
            const curr: BigCalendarEvent = {
              uuid: event.uuid,
              title: event.name,
              allDay: event.is_all_day,
              start: startDate
                .plus({
                  [event.repetitiveness.every_what_period]:
                    i * event.repetitiveness.interval,
                })
                .toJSDate(),
              end: endDate
                .plus({
                  [event.repetitiveness.every_what_period]:
                    i * event.repetitiveness.interval,
                })
                .toJSDate(),
              location: event.location,
              description: event.description,
              tag: event.tag ? event.tag : EventTagName.WORK,
              repetitiveness: event.repetitiveness,
              lastOccurence: event.last_occurrence,
              isDraggable: event.repetitiveness == null && event.tag !== EventTagName.PROJECT,
              projectUuid: event.project_uuid,
              clientUuid: event.client_uuid,
            };
            eventsList.push(curr);
          }
        } else {
          // If until is a date, add each occurrence of the recurring event to the eventsList until the until date
          let untilDate = zonedDate(event.repetitiveness.until);
          let i = 0;
          while (startDate <= untilDate) {
            const curr: BigCalendarEvent = {
              uuid: event.uuid,
              title: event.name,
              allDay: event.is_all_day,
              start: startDate.toJSDate(),
              end: endDate.toJSDate(),
              location: event.location,
              description: event.description,
              tag: event.tag ? event.tag : EventTagName.WORK,
              repetitiveness: event.repetitiveness,
              lastOccurence: event.last_occurrence,
              isDraggable: event.repetitiveness == null && event.tag !== EventTagName.PROJECT,
              projectUuid: event.project_uuid,
              clientUuid: event.client_uuid,
            };
            eventsList.push(curr);

            // Increment the startDate and endDate by the interval
            i++;
            startDate = startDate.plus({
              [event.repetitiveness.every_what_period]:
                event.repetitiveness.interval,
            });
            endDate = endDate.plus({
              [event.repetitiveness.every_what_period]:
                event.repetitiveness.interval,
            });
          }
        }
      } else {
        const curr: BigCalendarEvent = {
          uuid: event.uuid,
          title: event.name,
          allDay: event.is_all_day,
          start: zonedDate(event.date).toJSDate(),
          end: event.end_date
            ? zonedDate(event.end_date).toJSDate()
            : zonedDate(event.date).plus({ seconds: 1 }).toJSDate(),
          location: event.location,
          description: event.description,
          tag: event.tag ? event.tag : EventTagName.WORK,
          repetitiveness: event.repetitiveness,
          lastOccurence: event.last_occurrence,
          isDraggable: event.repetitiveness == null && event.tag !== EventTagName.PROJECT,
          projectUuid: event.project_uuid,
          clientUuid: event.client_uuid,
        };
        eventsList.push(curr);
      }
    });

    setCurrent(eventsList);
  }, [data, holidays, tasksEvents]);

  useEffect(() => {
    if (googleEvents) {
      const googleEventsList = googleEvents.events.map(transformGoogleEvent);
      setCurrent((current) => {
        const newEvents = current.filter((event) => !event.isFromGoogle);
        return [...newEvents, ...googleEventsList];
      });
    }
  }, [googleEvents]);

  const [openShowEvent, setOpenShowEvent] = useState(false);
  const [openShowTask, setOpenShowTask] = useState(false);
  const [selectedEvent, setSelectedEvent] = useAtom(selectedEventAtom);
  const [openDeleteEvent, setOpenDeleteEvent] = useAtom(deleteEventOpenAtom);
  const [openEditEvent, setOpenEditEvent] = useAtom(EditEventOpenAtom);

  const onSelectEvent = (event: BigCalendarEvent) => {
    if (event.tag === EventTagName.TASK) {
      setOpenShowTask(true);
    } else {
      setOpenShowEvent(true);
    }
    setSelectedEvent(event);
  };

  const [viewType, setViewType] = useAtom(viewTypeAtom);
  const deleteEvent = useDeleteEventMutation();
  const { enqueueSnackbar } = useSnackbar();

  const onDeleteEvent = async (uuid: string) => {
    await deleteEvent.mutateAsync({ uuid: uuid });
    enqueueSnackbar("Event deleted successfully", {
      variant: "success",
      action: (key) => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
      style: {
        borderRadius: "0.7rem",
      },
    });

    setOpenDeleteEvent(false);
    setOpenShowEvent(false);
  };

  const onNavigate = (date: Date, view: string) => {
    setLoadFrom(
      DateTime.fromJSDate(date).startOf("month").startOf("week").toISO()
    );
    setLoadTo(DateTime.fromJSDate(date).endOf("month").endOf("week").toISO());
  };

  const formats = {
    timeGutterFormat: (date: Date, culture: string, localizer: DateLocalizer) =>
      localizer.format(date, "hh a", culture),
  };

  useEffect(() => {
    let spans = document.querySelectorAll(
      ".rbc-timeslot-group > .rbc-time-slot > .rbc-label"
    );
    spans.forEach((span) => {
      let words = span?.innerHTML.split(" ");
      if (words?.length == 2) {
        words[1] = '<span class="ampm">' + words[1] + "</span>";
      }
      if (span) {
        span.innerHTML = words?.join(" ");
      }

      let style = document.createElement("style");
      style.innerHTML = ".ampm { font-size: 0.8rem; }";
      document.head.appendChild(style);
    });
  }, [
    document.querySelectorAll(
      ".rbc-timeslot-group > .rbc-time-slot > .rbc-label"
    ),
  ]);

  const clickRef = useRef<number | null>(null);

  const [addEventDefaultStartDate, setAddEventDefaultStartDate] = useState<
    DateTime | undefined
  >(undefined);
  const [addEventDefaultEndDate, setAddEventDefaultEndDate] = useState<
    DateTime | undefined
  >(undefined);
  const [addEventDefaultAllDay, setAddEventDefaultAllDay] = useState(false);
  const [addEventOpen, setAddEventOpen] = useState(false);

  const onSelectSlot = useCallback((slotInfo: SlotInfo) => {
    if (slotInfo.action === "doubleClick" || slotInfo.action === "select") {
      setAddEventDefaultAllDay(false);
    }
    if (slotInfo.action != "click") {
      if (clickRef.current !== null) {
        window.clearTimeout(clickRef.current);
      }
      clickRef.current = window.setTimeout(() => {
        setAddEventDefaultStartDate(DateTime.fromJSDate(slotInfo.start));
        setAddEventDefaultEndDate(DateTime.fromJSDate(slotInfo.end));
        setAddEventOpen(true);
      }, 250);
    }
  }, []);

  const editEvent = useEditEventMutation();

  const updateEventTimeByUuid = (
    events: BigCalendarEvent[],
    uuidToUpdate: string,
    newStartTime: DateTime,
    newEndTime: DateTime
  ): BigCalendarEvent[] => {
    return events.map((event) =>
      event.uuid === uuidToUpdate
        ? { ...event, start: newStartTime, end: newEndTime }
        : event
    );
  };

  const moveEvent = async (event: any) => {
    if (event.event.repetitiveness) return;
    const endDate = event.event.allDay
      ? DateTime.fromJSDate(event.start).plus({ minutes: 1 })
      : DateTime.fromJSDate(event.end);
    setCurrent(
      updateEventTimeByUuid(
        current,
        event.event.uuid,
        event.start,
        endDate.toJSDate()
      )
    );
    await editEvent.mutateAsync({
      uuid: event.event.uuid ?? "",
      name: event.event.title,
      date: event.start.toISOString(),
      end_date: endDate.toISO(),
    });
  };

  const onEventResize = async (event: any) => {
    if (event.event.repetitiveness) return;
    const endDate = event.event.allDay
      ? DateTime.fromJSDate(event.end).minus({ minutes: 1 })
      : DateTime.fromJSDate(event.end);
    setCurrent(
      updateEventTimeByUuid(
        current,
        event.event.uuid,
        event.start,
        endDate.toJSDate()
      )
    );
    await editEvent.mutateAsync({
      uuid: event.event.uuid ?? "",
      name: event.event.title,
      date: event.start.toISOString(),
      end_date: endDate.toISO(),
    });
  };

  const isDraggable = (event: BigCalendarEvent) => {
    return event.isDraggable;
  };

  useEffect(() => {
    const winHeight = window.innerHeight;
    const weekViewGrid = document.querySelector(
      ".rbc-time-content"
    ) as HTMLElement | null;
    if (weekViewGrid && weekViewGrid.style) {
      weekViewGrid.style.maxHeight = `${winHeight / 1.5}px`;
    }
  }, [window.innerHeight]);

  const selectedTask = tasksData?.tasks.find(
    (item) => item.uuid == selectedEvent?.uuid
  );
  const [openedDeleteTask, setOpenedDeleteTask] = useAtom(openedDeleteAtom);

  const setTaskStatus = useSetTaskStatusMutation();
  const handleCheckChange = (task: Task, value: boolean) => {
    if (value) {
      setForceCheck(true);
    } else {
      setForceCheck(false);
    }

    setTaskStatus.mutate({
      uuid: task.uuid,
      completed: value,
    });
  };

  useEffect(() => {
    setForceCheck(selectedTask?.completed ?? false);
  }, [selectedTask]);
  const [forceCheck, setForceCheck] = useState(false);
  
   // Fetch delete task:
   const deleteTask = useDeleteTaskMutation();
   const handleDeleteTask = async (uuid: string) => {
     const params = {
       uuid: uuid,
     } as DeleteTaskParams;
 
     await deleteTask.mutateAsync(params);
     setOpenedDeleteTask(false);
     enqueueSnackbar("Task deleted successfully", {
       variant: "success",
       action: (key) => (
         <IconButton
           size="small"
           aria-label="close"
           color="inherit"
           onClick={() => {
             closeSnackbar(key);
           }}
         >
           <CloseIcon fontSize="small" />
         </IconButton>
       ),
       style: {
         borderRadius: "0.7rem",
       },
     });
   };

  return (
    <>
      <AddEventDialog
        open={addEventOpen}
        onClose={() => setAddEventOpen(false)}
        defaultStartDate={addEventDefaultStartDate}
        defaultEndDate={addEventDefaultStartDate && addEventDefaultEndDate && addEventDefaultEndDate.toISO().split("T")[1] != addEventDefaultStartDate.toISO().split("T")[1] ? addEventDefaultEndDate : null}
        defaultAllDay={addEventDefaultAllDay}
      />
      <ShowEventDialog
        open={openShowEvent}
        onClose={() => setOpenShowEvent(false)}
        event={selectedEvent}
      />
      {selectedTask && (
        <>
        <ShowTaskDialog
          checked={forceCheck} //props.forceCheck ?? props.task.completed}
          onChange={(value) => handleCheckChange(selectedTask, value)} //props.onCompletedChange}
          task={selectedTask}
          open={openShowTask}
          onClose={() => setOpenShowTask(false)}
        />
      <ConfirmActionDialog
      open={openedDeleteTask}
      what="task"
      whatAction="delete"
      onClose={() => setOpenedDeleteTask(false)}
      onConfirm={() =>
        selectedTask ? handleDeleteTask(selectedTask.uuid) : undefined
      }
      loading={deleteTask.isPending}
    >
      Delete
    </ConfirmActionDialog>

    <EditTaskDialog
      open={openedEditTask}
      onClose={() => setOpenedEditTask(false)}
      oldTask={selectedTask}
    />
</>
      )}
      <ConfirmActionDialog
        open={openDeleteEvent}
        prefix="an"
        what="event"
        whatAction="delete"
        onClose={() => setOpenDeleteEvent(false)}
        onConfirm={() => onDeleteEvent(selectedEvent?.uuid ?? "")}
        loading={deleteEvent.isPending}
      >
        Delete
      </ConfirmActionDialog>
      <EditEventDialog
        open={openEditEvent}
        onClose={() => {
          setOpenEditEvent(false)
          if (selectedEvent?.isFromGoogle) {
            refetchGoogleEvents();
            setOpenShowEvent(false);
          }
        }}
        event={selectedEvent}
      />
      <Card>
        <DragAndDropCalendar
          style={{ height: "80vh" }}
          localizer={localizer}
          events={current}
          eventPropGetter={(event: { tag: string, isFromGoogle?: boolean, myStatus?: string, title?: string }) => {
            const eventTag = EVENT_TAGS.find(
              (tag) => tag.tag.valueOf() === event.tag
            );
          
            // Function to check if the string contains Hebrew characters
            const hasHebrew = (text: string) => /[\u0590-\u05FF]/.test(text);
          
            // Determine the text direction based on the content
            const direction = hasHebrew(event.title ?? '') ? 'rtl' : 'ltr';
          
            // Conditionally add line-through if the event is from Google and myStatus is declined
            const textDecoration = event.isFromGoogle && event.myStatus === "declined"
              ? "line-through"
              : "none";
          
            return {
              style: {
                backgroundColor: `${eventTag?.color ?? "000000"}2B`,
                color: eventTag?.color,
                borderWidth: "0.3rem",
                borderStyle: "solid",
                borderColor: `${eventTag?.color}`,
                borderBottomStyle: "none",
                borderTopStyle: "none",
                borderRightStyle: "none",
                textDecoration: textDecoration, // Add line-through if myStatus is declined
                direction: direction, // Set text direction dynamically
                unicodeBidi: "embed", // Ensures correct handling of mixed LTR/RTL content
              },
            };
          }}
          views={{ month: true, week: true, day: true }}
          onSelectEvent={onSelectEvent}
          components={{
            toolbar: CustomToolbar,
          }}
          onShowMore={() => setViewType("day")}
          onNavigate={onNavigate}
          defaultView={viewType}
          formats={formats}
          selectable
          onSelectSlot={onSelectSlot}
          onEventDrop={moveEvent}
          resizable
          resizableAccessor={isDraggable}
          draggableAccessor={isDraggable}
          scrollToTime={DateTime.now().set({ hour: 7, minute: 30 }).toJSDate()}
          onEventResize={onEventResize}
          view={viewType}
        />
      </Card>
    </>
  );
}
