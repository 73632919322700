import {useState} from 'react'
import { Stack, Box, Typography } from '@mui/material';
import phoneIcon from '../../../../assets/icons/phone-icon.svg';
import desktopIcon from '../../../../assets/icons/desktop-icon.svg';
import PortfolioPreview from '../../../../dialogs/PortfolioPreview';

type PreviewButtonsProps = {
    phoneSelected: boolean,
    setPhoneSelected: React.Dispatch<React.SetStateAction<boolean>>,
    desktopSelected: boolean,
    setDesktopSelected: React.Dispatch<React.SetStateAction<boolean>>,
}

export default function PreviewButtons({phoneSelected, setPhoneSelected, desktopSelected, setDesktopSelected}: PreviewButtonsProps) {
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);

    return (
        <Stack flexDirection={'row'} alignSelf={'end'} marginRight={3} marginBottom={2}>
            <Box 
                sx={{
                    backgroundColor: phoneSelected ? '#69B3FF' : '',
                    width: "1.7857rem",
                    height: "1.7857rem",
                    borderRadius: "0.5rem",
                    cursor: 'pointer',
                    padding: "0.2rem",
                    marginRight: "0.3rem",
                }}>
                    <Box
                        component="img"
                        src={phoneIcon}
                        sx={{
                            width: "1.7857rem",
                            height: "1.7857rem",
                            filter: phoneSelected ? 'brightness(0) invert(1)' : 'none',
                        }}
                        onClick={() => {
                            setPhoneSelected(prevPhoneSelected => !prevPhoneSelected)
                            setDesktopSelected(false)
                        }}
                    />
            </Box>
            <Box 
                sx={{
                    backgroundColor: desktopSelected ? '#69B3FF' : '',
                    width: "1.7857rem",
                    height: "1.7857rem",
                    borderRadius: "0.5rem",
                    cursor: 'pointer',
                    padding: "0.2rem",
                    marginRight: "0.5rem",
                }}>
                    <Box
                        component="img"
                        src={desktopIcon}
                        sx={{
                            width: "1.7857rem",
                            height: "1.7857rem",
                            filter: desktopSelected ? 'brightness(0) invert(1)' : 'none',
                        }}
                        onClick={() => {
                            setDesktopSelected(prevDesktopSelected => !prevDesktopSelected)
                            setPhoneSelected(false)
                        }}
                    />
            </Box>
            <Typography
                color={'#75B3FF'}
                fontSize={'1.1rem'}
                marginTop={'0.2rem'}
                onClick={() => setPreviewOpen(prevPreviewOpen => !prevPreviewOpen)}
                sx={{cursor: 'pointer'}}
            >Preview</Typography>
            <PortfolioPreview open={previewOpen} onClose={() => setPreviewOpen(prevPreviewOpen => !prevPreviewOpen)} />
        </Stack>
    );
}
