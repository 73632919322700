import {MynkPage} from "../../../components/MynkPage";
import React from "react";
import { PATHS, WorkflowPath, makePath } from "../../../paths";

const TABS = [
  {
    title: 'All',
    path: '/all',
  },
  {
    title: 'Completed',
    path: '/completed',
  },
]

export default function ProjectsPage() {
  return (
    <MynkPage
      title="Projects"
      basePath={makePath(WorkflowPath.PHOTOGRAPHY, PATHS.projects.index)}
      tabs={TABS}
    />
  );
}