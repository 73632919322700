import { useState } from "react";
import { SortOption } from "../../api/types";
import { ChipSelect, ChipSelectOption } from "../ChipSelect";
import sortIcon from "../../assets/icons/sort-icon.svg";

interface SortBoxProps {
  sortState: [
    SortOption,
    React.Dispatch<React.SetStateAction<SortOption>>
  ];
  sortOptions?: ChipSelectOption[];
  height?: number;
}

export default function SortBox(props: SortBoxProps) {
  const [sort, setSort] = props.sortState;
  const [option, setOption] = useState("");
  const handleChangeOption = (value: string) => {
    setOption(value);
    const [by, descending] = value.split(":", 2);
    if (setSort) {
      setSort((prev) => ({
        by: by,
        descending: descending == "true" ? true : false,
      }));
    }
  };

  return (
    <ChipSelect
      options={props.sortOptions ?? []}
      value={(option == "" || sort?.by) == "" ? "default" : option}
      bgcolor="#ecf0f5"
      height={props.height ?? 34}
      onChange={handleChangeOption}
      sx={{
        fontFamily: "Helvetica Neue",
        color: "#888b8f",
        fontWeight: "bold",
      }}
      sideIcon={sortIcon}
      placeholder="Sort"
      ignoreCentering
    />
  );
}
