import React, {Fragment, ReactNode} from "react";
import {Box, CircularProgress, Divider, Stack, Typography} from "@mui/material";
import {formatMoney} from "../util/common";

type StatItemColor = 'black' | 'blue' | 'green';

interface StatItemProps {
  title: string;
  value?: ReactNode;
  color?: StatItemColor;
}

function getStatItemColor(label: StatItemColor) {
  switch (label) {
    case 'black':
      return '#000';
    case 'blue':
      return '#4f70a9';
    case 'green':
      return '#4ea437';
  }
}

function StatItem(props: StatItemProps) {
  const color = getStatItemColor(props.color || 'black');

  return (
    <Box>
      <Typography sx={{color: '#77797a'}} fontFamily="Helvetica Neue">{props.title}</Typography>
      <Typography fontSize={"2rem"} fontFamily="Helvetica Neue">{props.value}</Typography>
    </Box>
  );
}


export interface StatColumnItemInfo {
  title: string;
  value: ReactNode;
}

export interface StatColumnProps {
  items: StatColumnItemInfo[];
}

export function StatColumn(props: StatColumnProps) {
  return (
    <Stack spacing={2.7} sx={{ mt: 1 }}>
      {props.items.map((item, i) => (
        <Fragment key={i}>
          <StatItem
            title={item.title}
            value={
              item.value === undefined
                ? <CircularProgress size={"1.1429rem"} sx={{ ml: 0 }} />
                : item.value
            }
          />
          {(i !== props.items.length - 1) && (<Divider sx={{borderColor: '#f0f0f0'}}/>)}
        </Fragment>
      ))}
    </Stack>
  );
}
