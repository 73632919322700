import {MynkTab} from "../../../../components/MynkPage";
import {Grid} from "@mui/material";
import {ProjectsTableCard} from "../common/ProjectsTableCard";
import React from "react";
import {ProjectStage} from "../../../../api/types";
import { NewButtonProjects } from "./All";
import { TableType } from "../../../../components/SimpleTable";

export default function ProjectsCompletedSubpage() {
  return (
    <MynkTab
      title="Completed"
      action={<NewButtonProjects/>}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProjectsTableCard stage={ProjectStage.COMPLETED} tableType={TableType.COMPLETED_PROJECTS}/>
        </Grid>
      </Grid>
    </MynkTab>
  );
}
