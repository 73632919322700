import { MynkPage } from '../../../components/MynkPage';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentContact } from './common';
import { PATHS, WorkflowPath, makePath } from '../../../paths';
import { currentWorkflowAtom } from '../../../components/MainAppBar/UserControlPopup';
import { useAtom, useAtomValue } from "jotai";

const TABS = [
  {
    title: 'General',
    path: '/general',
  },
  {
    title: "Files",
    path: "/files"
  },
  {
    title: "Tasks",
    path: "/tasks"
  },
  {
    title: "Notes",
    path: "/notes"
  },
  {
    title: "Expenses",
    path: "/expenses"
  }
];

export default function ViewPhotographyContactPage () {
  const [currentWorkflow, setCurrentWorkflow] = useAtom(currentWorkflowAtom);

  const { contactId } = useParams();
  const basePath = useMemo(() => {
    return makePath(`/${currentWorkflow?.type}` as WorkflowPath, PATHS.viewContact(contactId ?? "s").index);
  }, [contactId]);

  const client = useCurrentContact();
  const navigate = useNavigate();
  useEffect(() => {
    if (client === null) {
      navigate(makePath(`/${currentWorkflow?.type}` as WorkflowPath, PATHS.clients.contacts));
    }
  }, [client]);

  return (
    <MynkPage
      title={client ? client.full_name : '...'}
      basePath={basePath}
      tabs={TABS}
      backLink={-1}
    />
  );
}