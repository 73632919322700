import React from 'react'
import { Stack, Box } from '@mui/material';
import Ellipse from '../../assets/images/EmailVerifiedImage/Ellipse.svg';
import Check from '../../assets/images/EmailVerifiedImage/Check.svg';

type ConfirmProps = {
    email: string
}

function Confirm({ email }: ConfirmProps) {
    return (
        <Stack
            sx={{
                borderRadius: '16px',
                background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%)',
                boxShadow: '0px 5px 15px 0px rgba(117, 179, 255, 0.20)',
                paddingX: '2rem',
                paddingY: '2rem',
                height: '25rem',
                alignSelf: 'center',
                alignItems: 'center',
            }}
            justifyContent={'center'}
            flexGrow={1}
        >
            <Box sx={{ position: 'relative', width: '7rem', height: '7rem', mb: 3 }}>
                <Box
                    component="img"
                    src={Ellipse}
                    sx={{ position: 'absolute', top: 0, left: 0, width: "100%", height: "100%", zIndex: 1 }}
                />
                <Box
                    component="img"
                    src={Check}
                    sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2, width: "70%", height: "70%" }}
                />
            </Box>
            <h1 style={{fontSize: '1.6rem', marginBottom: '0.5rem'}}>Your booking is confirmed</h1>
            <span style={{marginTop: '3rem', marginBottom: '0.3rem', fontSize: '1.1rem'}}>A confirmation email has been sent to:</span>
            <span style={{fontWeight: 600, fontSize: '1.1rem'}}>{email}</span>
        </Stack>
    );
}

export default Confirm
