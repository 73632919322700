import { ListWorkflowsParams, ListWorkflowsResponse, EditWorkflowParams } from "./types";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";

export function useListWorkflowsQuery(
  params: ListWorkflowsParams,
  enabled?: boolean
) {
  return useQuery<ListWorkflowsResponse>({
    queryKey: ["workflows-list", params],
    queryFn: async () => (await axios.get("/api/workflows/list", params)).data,
    enabled: enabled ?? true,
  });
}

export function useEditWorkflowMutation() {
  return useMutation({
    mutationFn: async (params: EditWorkflowParams) =>
      (await axios.post("/api/workflows/edit", params)).data,
  });
}
