import {SimpleTable, SimpleTableProps} from "./SimpleTable";
import {Box, Card, IconButton, Menu, MenuItem, Stack, Typography,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ChipSelectOption} from "../ChipSelect";
import {PROJECTS_TABLE_CHOOSE_PROJECTS_CATEGORY_OPTIONS,} from "../ChipSelect/commonOptions";
import dropdownArrowCircle from "../../assets/icons/dropdown-circle-icon.svg";
import {FilterOption, ProjectStage, SearchOption, SortOption,} from "../../api/types";
import SearchFilterSort from "../SearchFilterSort";
import {MENU_TAIL_SX} from "../../util/styles";
import { SimpleTableColumn } from "./SimpleTable";

export enum TableType {
  PROJECTS = "projects",
  COMPLETED_PROJECTS = "completed_projects",
  CLIENTS = "clients",
  FILES = "files",
  EXPENSES = "expenses",
}

export interface SimpleTableCardProps extends SimpleTableProps {
  title: string;
  tableType: TableType;
  stage?: ProjectStage;
  setStage?: (newStage: ProjectStage) => void;
}

function TableHeader(props: SimpleTableCardProps) {
  // Variables to handle the dropdown menu of the "multi-stage choosing":
  const [tableTitle, setTableTitle] = useState(props.title);
  const [selectedItem, setSelectedItem] = useState<ChipSelectOption | null>(
    PROJECTS_TABLE_CHOOSE_PROJECTS_CATEGORY_OPTIONS[0]
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (selectedOption: ChipSelectOption) => {
    setAnchorEl(null);
    setSelectedItem(selectedOption);
  };


  useEffect(() => {
    if (props.stage) {
      if (
        ![
          ProjectStage._ALL,
          ProjectStage._ACTIVE,
          ProjectStage._LEADS_TO_CLOSED,
          ProjectStage.COMPLETED,
        ].includes(props.stage)
      ) {
        setTableTitle("Projects info");
        setSelectedItem(null);
      }
    }
  }, [props.stage]);

  useEffect(() => {
    if (props.tableType == TableType.PROJECTS) {
      if (selectedItem) {

        setTableTitle(selectedItem.label);

        if (props.setStage) {
          switch (selectedItem.value) {
            case PROJECTS_TABLE_CHOOSE_PROJECTS_CATEGORY_OPTIONS[0].value:
              props.setStage(ProjectStage._ALL);
              break;
            case PROJECTS_TABLE_CHOOSE_PROJECTS_CATEGORY_OPTIONS[1].value:
              props.setStage(ProjectStage._LEADS_TO_CLOSED);
              break;
            case PROJECTS_TABLE_CHOOSE_PROJECTS_CATEGORY_OPTIONS[2].value:
              props.setStage(ProjectStage._ACTIVE);
              break;
            case PROJECTS_TABLE_CHOOSE_PROJECTS_CATEGORY_OPTIONS[3].value:
              props.setStage(ProjectStage.COMPLETED);
              break;
          }
        }
      }
    }
  }, [selectedItem]);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
    >
      <Typography
        variant="h4"
        sx={{ml: 2.8, mt: 2.2, mb: 3, color: "black"}}
      >
        {tableTitle}&nbsp;
      </Typography>
      <Typography variant="h6" sx={{  pb: 0.5, letterSpacing: "0.087rem" }}>
      ({props.total})&nbsp;
      </Typography>
      {props.tableType == TableType.PROJECTS ? (
        <>
          <IconButton
            sx={{height: "2.2rem", width: "2.2rem", mb: 0.3}}
            onClick={handleClick}
          >
            <Box
              component="img"
              src={dropdownArrowCircle}
              sx={{height: "2.3rem", ml: 0.2}}
            />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() =>
              handleClose(
                selectedItem ||
                PROJECTS_TABLE_CHOOSE_PROJECTS_CATEGORY_OPTIONS[0]
              )
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}

            sx={{
              ml: -2,
              ...MENU_TAIL_SX,
            }}
          >
            {PROJECTS_TABLE_CHOOSE_PROJECTS_CATEGORY_OPTIONS.map((option) => (
              <MenuItem key={option.value} onClick={() => handleClose(option)}>
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <></>
      )}
      <Box sx={{flex: 1}}/>

      {props.searchState && props.filterState && props.sortState && (
        <SearchFilterSort
          searchState={props.searchState}
          filterState={props.filterState}
          sortState={props.sortState}
          height={40}
          isSearchStretched={true}
          searchWidth={187}
          spacing={2}
          sx={{mr: 3.2}}
          {...props}
        />
      )}
    </Stack>
  );
}

export function SimpleTableCard(props: SimpleTableCardProps) {
  return (
    <Card>
      <TableHeader {...props} />
      <SimpleTable {...props} />
    </Card>
  );
}
