import {createTheme, ThemeProvider} from "@mui/material";
import MYNK_BASE_THEME from "../MYNK_BASE_THEME";
import {useEffect, useMemo, useState} from "react";
import {checkIsMobile} from "../util/mobile";

export interface MynkThemeProviderProps {
  children: React.ReactNode;
}

export function MynkThemeProvider(props: MynkThemeProviderProps) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const mobile = useMemo(() => checkIsMobile(), []);

  const theme = useMemo(() => {
    if (mobile) {
      document.documentElement.style.fontSize = `10px`;
      return createTheme({
        ...MYNK_BASE_THEME,
        spacing: 8,
        typography: {
          ...MYNK_BASE_THEME.typography,
          htmlFontSize: 16,
          fontSize: 16,
        }
      });
    }

    // compute base font size
    const fontSize = Math.ceil(windowSize.width * 0.00729);

    // set html font size
    document.documentElement.style.fontSize = `${fontSize}px`;

    // compute spacing
    const spacing = Math.floor(windowSize.width * 0.0042 * 2) / 2;

    return createTheme({
      ...MYNK_BASE_THEME,
      spacing,
      typography: {
        ...MYNK_BASE_THEME.typography,
        htmlFontSize: fontSize,
        fontSize,
      },
    });
  }, [windowSize, mobile]);

  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  );
}
