import {
  AskDeleteAccountParams,
  AskDeleteAccountResponse,
  AskForgotPasswordParams,
  DeleteAccountParams,
  DeleteAccountResponse,
  AskForgotPasswordResponse, CheckoutSubscriptionParams, CheckoutSubscriptionResponse,
  LoginParams,
  LoginResponse,
  LogoutParams,
  LogoutResponse,
  RecordUserParams,
  RecordUserResponse,
  ResendResetPasswordEmailParams,
  ResendResetPasswordEmailResponse,
  ResendVerificationEmailParams,
  ResendVerificationEmailResponse,
  ResetPasswordParams,
  ResetPasswordResponse,
  SignupParams,
  SignupResponse,
  VerifyEmailParams,
  VerifyEmailResponse,
  WhoamiResponse,
  LoginWithGoogleParams,
  LoginWithGoogleResponse,
  ResetPasswordConnectedParams,
  ResetPasswordConnectedResponse,
  VerifyPhoneParams,
  VerifyPhoneResponse
} from "./types";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios from "axios";
import { useAtomValue } from "jotai";
import { axiosAuthenticatedAtom, loginStateAtom } from "../state/auth";

export function useLoginMutation(
  options: UseMutationOptions<LoginResponse, unknown, LoginParams>
) {
  return useMutation<LoginResponse, unknown, LoginParams>({
    mutationFn: async (params) => {
      return (await axios.post("/api/auth/login", params)).data;
    },
    ...options,
  });
}

export function useSignupMutation(
  options: UseMutationOptions<SignupResponse, unknown, SignupParams>
) {
  return useMutation<SignupResponse, unknown, SignupParams>({
    mutationFn: async (params) => {
      return (await axios.post("/api/auth/signup", params)).data;
    },
    ...options,
  });
}

export function useVerifyEmailMutation(
  options: UseMutationOptions<VerifyEmailResponse, unknown, VerifyEmailParams>
) {
  return useMutation<VerifyEmailResponse, unknown, VerifyEmailParams>({
    mutationFn: async (params) => {
      return (await axios.post("/api/auth/verify-email", params)).data;
    },
    ...options,
  });
}

export function useResendVerificationEmailMutation(
  options: UseMutationOptions<
    ResendVerificationEmailResponse,
    unknown,
    ResendVerificationEmailParams
  >
) {
  return useMutation<
    ResendVerificationEmailResponse,
    unknown,
    ResendVerificationEmailParams
  >({
    mutationFn: async (params) => {
      return (await axios.post("/api/auth/resend-verification-email", params))
        .data;
    },
    ...options,
  });
}

export function useAskForgotPasswordMutation(
  options: UseMutationOptions<
    AskForgotPasswordResponse,
    unknown,
    AskForgotPasswordParams
  >
) {
  return useMutation<
    AskForgotPasswordResponse,
    unknown,
    AskForgotPasswordParams
  >({
    mutationFn: async (params) => {
      return (await axios.post("/api/auth/ask-forgot-password", params)).data;
    },
    ...options,
  });
}

export function useResetPasswordMutation(
  options: UseMutationOptions<
    ResetPasswordResponse,
    unknown,
    ResetPasswordParams
  >
) {
  return useMutation<ResetPasswordResponse, unknown, ResetPasswordParams>({
    mutationFn: async (params) => {
      return (await axios.post("/api/auth/reset-password", params)).data;
    },
    ...options,
  });
}

export function useResendResetPasswordEmailMutation(
  options: UseMutationOptions<
    ResendResetPasswordEmailResponse,
    unknown,
    ResendResetPasswordEmailParams
  >
) {
  return useMutation<
    ResendResetPasswordEmailResponse,
    unknown,
    ResendResetPasswordEmailParams
  >({
    mutationFn: async (params) => {
      return (await axios.post("/api/auth/resend-reset-password-email", params))
        .data;
    },
    ...options,
  });
}

export function useLogoutMutation() {
  return useMutation<LogoutResponse, unknown, LogoutParams>({
    mutationFn: async (params) => {
      return (await axios.post("/api/auth/logout", params)).data;
    },
  });
}

export function useAskDeleteAccountMutation(
  options: UseMutationOptions<
    AskDeleteAccountResponse,
    unknown,
    AskDeleteAccountParams
  >
) {
  return useMutation<
    AskDeleteAccountResponse,
    unknown,
    AskDeleteAccountParams
  >({
    mutationFn: async (params) => {
      return (await axios.post("/api/auth/ask-delete-account", params)).data;
    },
    ...options,
  });
}



export function useDeleteAccountMutation(
  options: UseMutationOptions<
    DeleteAccountResponse,
    unknown,
    DeleteAccountParams
  >
) {
  return useMutation<DeleteAccountResponse, unknown, DeleteAccountParams>({
    mutationFn: async (params) => {
      return (await axios.post("/api/auth/delete-account", params)).data;
    },
    ...options,
  });
}


export function useWhoamiQuery() {
  const loginState = useAtomValue(loginStateAtom);
  const axiosAuthenticated = useAtomValue(axiosAuthenticatedAtom);
  return useQuery<WhoamiResponse>({
    queryKey: ["whoami"],
    queryFn: async () => (await axios.get("/api/auth/whoami")).data,
    enabled: Boolean(loginState?.accessToken) && axiosAuthenticated,
    gcTime: 1000,
    staleTime: 1000,
    retry: false,
  });
}


export function useCheckoutSubscriptionMutation(
  options: UseMutationOptions<CheckoutSubscriptionResponse, unknown, CheckoutSubscriptionParams>
) {
  return useMutation<CheckoutSubscriptionResponse, unknown, CheckoutSubscriptionParams>({
    mutationFn: async (params) => {
      return (await axios.post("/api/billing/checkout", params)).data;
    },
    ...options,
  });
}

export function useResetPasswordConnectedMutation(
  options: UseMutationOptions<
    ResetPasswordConnectedResponse,
    unknown,
    ResetPasswordConnectedParams
  >
) {
  return useMutation<ResetPasswordConnectedResponse, unknown, ResetPasswordConnectedParams>({
    mutationFn: async (params) => {
      return (await axios.post("/api/auth/reset-password-connected", params)).data;
    },
    ...options,
  });
}

export function useVerifyPhoneMutation(
  options: UseMutationOptions<VerifyPhoneResponse, unknown, VerifyPhoneParams>
) {
  return useMutation<VerifyPhoneResponse, unknown, VerifyPhoneParams>({
    mutationFn: async (params) => {
      return (await axios.post("/api/auth/verify-phone", params)).data;
    },
    ...options,
  });
}