import React from 'react'
import { Stack } from '@mui/material'

type DescriptionProps = {
  name?: string;
  description: string;
}

function Description({ name, description }: DescriptionProps): JSX.Element {
  return (
    <Stack alignItems={'center'} justifyContent={'center'} flexGrow={1} sx={{
      width: '100%',
      boxSizing: 'border-box',
    }}>
      <h1 style={{
        fontSize: '5rem',
        marginBottom: '0.7rem',
      }}>{name}</h1>
      <p style={{
        fontSize: '1.5rem',    
      }}>{description}</p>
    </Stack>
  )
}

export default Description
