import {Box, Chip, CircularProgress, IconButton, Stack, SxProps, TextField, Typography,} from "@mui/material";
import ElevatorDialog from "../ElevatorDialog";
import searchIcon from "../../assets/icons/search-icon.svg";
import {Clear} from "@mui/icons-material";
import sendMessageIcon from "../../assets/icons/send-icon.svg";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ChatBubble, ChatBubbleBox, MessageFrom} from "./common/bubbles";
import {useAIConversation} from "../../state/ai";
import helpChatIcon from "../../assets/icons/help-and-support-chat-icon.svg";
import generalChatIcon from "../../assets/icons/general-chat-icon.svg";
import {zonedDate} from "../../util/common";
import mynkTextLogo from "../../assets/images/mynk-textlogo.svg";
import ChatIcon from '@mui/icons-material/Chat';
import {InvoiceDetails} from "../../api/types";

const CHATS_SIDEBAR_WIDTH = "25rem";

interface SearchChatbotProps {
  searchQuery?: string;
  setSearchQuery?: (newSearchQuery: string) => void;
  sx?: SxProps;
}

function SearchChatbot(props: SearchChatbotProps) {
  const handleSearch = (searchQuery: string) => {
    console.log(searchQuery);
  };

  return (
    // The box that wrappes the whole thing:
    <Box
      sx={{
        height: "2.5rem",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f4f4f4",
        borderRadius: 10,
        boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.15) inset",
        width: "26rem",
        my: 1,
        ...props.sx,
      }}
    >
      {/* Text input field itself */}
      <TextField
        variant="standard"
        InputProps={{disableUnderline: true}}
        placeholder="Search"
        value={props.searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
        sx={{
          width: "100%",
          "& input::placeholder": {
            fontFamily: "Helvetica Neue",
            fontSize: "1.2rem",
          },
          "& input": {
            color: "#5d6063",
            fontFamily: "Helvetica Neue",
            fontSize: "1.2rem",
            ml: 2,
          },
        }}
      ></TextField>
      {/* Search icon */}
      <Box
        component="img"
        height={"2.5rem"}
        sx={{ml: 0.2, mt: 0.2}}
        src={searchIcon}
      />
    </Box>
  );
}

function ChatSidebar() {
  return (
    <Box
      sx={{
        width: CHATS_SIDEBAR_WIDTH,
        boxShadow: "0px 4px 5px rgba(117, 179, 255, 0.20)",
        bgcolor: "#FFFFFF80",
        borderRadius: "1.1429rem 0 0 1.1429rem",
      }}
    >
      <Stack margin={2} sx={{ml: 2, pt: 0.4}} spacing={0.7}>
        <Typography fontFamily="Helvetica Neue" variant="h3" sx={{mb: 2.2}}>
          Chats
        </Typography>
        <IconButton
          sx={{
            borderRadius: "0.7rem",
            bgcolor: "white",
            border: "0.005rem solid #edf1f2",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.07)",
            height: "4rem",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Stack direction="row" justifyContent="center" display="flex" alignContent="center" alignItems="center"
                 spacing={"0.5rem"}>
            <Box sx={{
              width: "2.7rem",
              height: "2.7rem",
              borderRadius: "0.5rem",
              backgroundImage: "linear-gradient(180deg, #BEE6FF 15%, #90D4FF 66%)",
              justifyContent: "center",
              display: "flex",
              alignItems: "center"
            }}>
              <Box component="img" src={generalChatIcon} width="1.5rem" height="1.5rem"/>
            </Box> <Typography fontSize="1.27rem" fontWeight="bold">
            General
          </Typography>
          </Stack>
        </IconButton>

        <IconButton
          disabled
          sx={{
            borderRadius: "0.7rem",
            border: "0.005rem solid #edf1f2",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.07)",
            height: "4rem",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Stack
            direction="row" justifyContent="center" display="flex" alignContent="center" alignItems="center"
            spacing={"0.5rem"}
          >
            <Box sx={{
              width: "2.7rem",
              height: "2.7rem",
              borderRadius: "0.5rem",
              backgroundImage: "linear-gradient(180deg, #C7A5FF 0%, #68B3FF 100%)",
              justifyContent: "center",
              display: "flex",
              alignItems: "center"
            }}>
              <Box component="img" src={helpChatIcon} width="1.9rem" height="1.9rem"/>
            </Box>
            <Typography fontSize="1.27rem" fontWeight="bold" sx={{color: '#aaa'}}>
              Help & support
            </Typography>
          </Stack>
        </IconButton>
        <Box>{/* Chat history here */}</Box>
      </Stack>
    </Box>
  );
}

interface ChatTopbarProps {
  onClose: () => void;
}

function ChatTopbar(props: ChatTopbarProps) {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "4.7rem",
        bgcolor: "white",
        boxShadow: "0px 1px 4px rgba(117, 179, 255, 0.20)",
        borderRadius: "0 1.1429rem 0 0 ",
      }}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Box sx={{width: "1.4286rem"}}/>
        <SearchChatbot/>
      <Box sx={{flex: 1}}/>
      {/* <Button sx={{mr: 2.5, borderRadius: 5, height: "2.3rem"}}>
        <Box
          component="img"
          src={reloadIcon}
          sx={{height: "1.3rem", width: "1.3rem", pr: 1}}
        />
        <Typography
          fontSize={"1.24rem"}
          sx={{textTransform: "none", color: "#75B3FF"}}
        >
          Regenerate response
        </Typography>
      </Button> */}
      <IconButton
        sx={{width: "2rem", height: "2rem", mr: "1.22rem"}}
        onClick={props.onClose}
      >
        <Clear sx={{width: "1.7rem", height: "1.7rem", color: "#B3B3B3"}}/>
      </IconButton>
    </Stack>
  );
}

interface ChatMessage {
  from: string;
  time: string;
  content: string;
  invoice?: InvoiceDetails;
}

interface ConversationAreaProps {
  hasMore?: boolean;
  onRequestMore?: () => void;
  chatData: ChatMessage[];
}

function ConversationArea(props: ConversationAreaProps) {
  const scrollerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      // scroll to end of chat
      console.log('scrolling to end', scrollerRef.current?.scrollHeight);
      scrollerRef.current?.scrollTo(0, scrollerRef.current?.scrollHeight);
    }, 50);
  }, [props.chatData]);

  return (
    <Box sx={{overflowY: "auto", mr: 1, flexGrow: 1}} ref={scrollerRef}>
      {!props.chatData || props.chatData.length == 0 ? (
        <Box alignContent="center" alignItems="center" display="flex" justifyContent="center" height="32.5rem">
          <Stack alignItems="center">
            <Stack direction="row">
              <Typography variant="h1">Talk to&nbsp;</Typography>
              <Box component="img" height="2.8rem" src={mynkTextLogo} sx={{mt: "0.2rem"}}/>
            </Stack>
          </Stack>
        </Box>
      ) : (

        <Stack sx={{mr: 2.5, ml: 3.5}} spacing={"0.77rem"}>
          {props.hasMore && props.onRequestMore && (
            <Chip
              label="See older messages..."
              sx={{alignSelf: 'center', mt: 3, cursor: 'pointer'}}
              onClick={props.onRequestMore}
            />
          )}

          {props.chatData.map((value, index) => {
            const bubble: ChatBubble = {
              from: value.from as MessageFrom,
              time: value.time ? zonedDate(value.time) : null,
              content: value.content,
            };

            if (value.content === null)
              return <></>;

            return (
              <React.Fragment key={index}>
                {value.invoice && (() => {
                  const invoiceBubble = {...bubble, content: null, invoice: value.invoice};
                  return <ChatBubbleBox bubble={invoiceBubble}/>;
                })()}

                <ChatBubbleBox bubble={bubble}/>
              </React.Fragment>
            );
          })}
        </Stack>
      )}
    </Box>
  );
}

interface TextMessageProps {
  onMessage?: (message: string) => void;
  sx?: SxProps;
  disabled?: boolean;
}

function TextMessage(props: TextMessageProps) {
  const [message, setMessage] = useState("");

  const handleSearch = () => {
    if (props.disabled)
      return;

    if (message && props.onMessage) {
      props.onMessage(message);
    }
    setMessage("");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if Enter key is pressed;
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Box
      sx={{
        height: "3.7rem",
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "4rem",
        width: "52.5rem",
        ...props.sx,
      }}
    >
      <TextField
        variant="standard"
        InputProps={{disableUnderline: true}}
        placeholder="Ask me anything..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        sx={{
          width: "100%",
          "& input::placeholder": {
            fontFamily: "Helvetica Neue",
            fontSize: "1.4rem",
          },
          "& input": {
            color: "#5d6063",
            fontFamily: "Helvetica Neue",
            fontSize: "1.4rem",
            ml: "1.1rem",
            pl: "1rem"
          },
        }}
      />

      <IconButton
        sx={{height: "3rem", width: "3rem", mr: 0.9}}
        onClick={handleSearch}
        disabled={props.disabled}
      >
        <Box
          component="img"
          height={"3rem"}
          src={sendMessageIcon}
        />
      </IconButton>
    </Box>
  );
}

interface SendMessageAreaProps {
  sx?: SxProps;
  onMessage?: (message: string) => void;
  disabled?: boolean;
}

function SendMessageArea(props: SendMessageAreaProps) {
  return (
    <Stack
      direction="row"
      spacing={0.7}
      margin={1}
    >
      <TextMessage onMessage={props.onMessage} disabled={props.disabled}/>
      {/*
      <IconButton sx={{width: "3.7rem", height: "3.7rem"}} disabled={props.disabled}>
        <Box
          component="img"
          src={voiceMessageIcon}
          sx={{width: "3.7rem", height: "3.7rem"}}
        />
      </IconButton>

      <IconButton sx={{width: "3.7rem", height: "3.7rem"}} disabled={props.disabled}>
        <Box
          component="img"
          src={attachFilesIcon}
          sx={{width: "3.7rem", height: "3.7rem"}}
        />
      </IconButton> */}
    </Stack>
  );
}

interface ChatContentProps extends ConversationAreaProps {
  onMessage?: (message: string) => void;
  waitingForResponse: boolean;
}

function ChatContent(props: ChatContentProps) {
  return (
    <Stack sx={{borderRadius: "0 0 1.1429rem 0", bgcolor: "#E0F3FF", minHeight: "37.5rem"}}>
      <ConversationArea chatData={props.chatData} hasMore={props.hasMore} onRequestMore={props.onRequestMore}/>
      <SendMessageArea onMessage={props.onMessage}
                       disabled={props.waitingForResponse}/>
    </Stack>
  );
}

interface ChatbotDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function ChatbotDialog(props: ChatbotDialogProps) {
  const conv = useAIConversation(props.open);

  // const [messages, setMessages] = useState<ChatMessage[]>([]);
  // {
  //   from: "me",
  //   time: "2023-11-23T15:30:00Z",
  //   content: "content here",
  // },
  // {
  //   from: "you",
  //   time: "2023-11-23T15:30:00Z",
  //   content: "content here",
  // },
  const messages = useMemo<ChatMessage[]>(() => {
    const result = [];

    for (const prompt of conv?.conversation?.history ?? []) {
      if (prompt.prompt) {
        result.push({
          from: "me",
          time: prompt.prompt_time!.toISOString(),
          content: prompt.prompt,
        });
      }

      result.push({
        from: "you",
        time: prompt.answer_time ? prompt.answer_time.toISOString() : null,
        content: prompt.answer,
        invoice: prompt.extra?.invoice,
      });
    }

    return result;
  }, [conv?.conversation]);

  const handleMessage = useCallback(
    async (message: string) => {
      console.log("message", message);
      await conv?.addPrompt(message);
    },
    [conv?.addPrompt]
  );

  const handleRequestMore = useCallback(() => {
    conv?.requestMoreMessages?.(20);
  }, [conv]);

  return (
    <ElevatorDialog
      open={props.open}
      onClose={props.onClose}
      customCloseButton
      sx={{overflowX: "none"}}
    >
      <Box sx={{display: "flex", width: "70rem", height: "40rem"}}>
        <ChatSidebar/>

        <Stack sx={{width: "100%"}}>
          {conv?.isLoading && (
            <CircularProgress
              size="6rem"
              sx={{display: 'table', mx: 'auto', my: 'auto', ml: '50%'}}
            />
          )}

          {!conv?.isLoading && (
            <>
              <ChatTopbar onClose={props.onClose}/>
              <ChatContent
                chatData={messages}
                onMessage={handleMessage}
                hasMore={Boolean(conv?.conversation?.startIndex)}
                onRequestMore={handleRequestMore}
                waitingForResponse={Boolean(conv.thinking)}
              />
            </>
          )}
        </Stack>
      </Box>
    </ElevatorDialog>
  );
}
