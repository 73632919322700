import { MynkFormTextFieldProps } from "./controls/TextField";
import { MynkFormChipSelectProps } from "./controls/ChipSelect";
import { MynkFormContactSearchProps } from "./controls/ContactSearch";
import { MynkFormDateSelectProps } from "./controls/DateSelect";
import { MynkFormIconedChipSelectProps } from "./controls/IconedChipSelect";
import { MynkFormFileUploadProps } from "./controls/FileUpload";
import { MynkFormColorPickerProps } from "./controls/ColorPicker";
import { MynkFormColoredChipSelectProps } from "./controls/ColoredChipSelect";
import { MynkFormProjectSearchProps } from "./controls/ProjectsSearch";
import { MynkFormPhoneNumberProps } from "./controls/PhoneNumber";

export enum MynkFormControlKind {
  TEXT,
  LONG_TEXT,
  CHIP_SELECT,
  ICONED_CHIP_SELECT,
  CONTACT_SEARCH,
  PROJECT_SEARCH,
  DATE_SELECT,
  FILE_UPLOAD,
  COLOR_PICKER,
  COLORED_CHIP_SELECT,
  PHONE_NUMBER,
  INSTAGRAM_HANDLE,
}

interface CtrlTextField extends MynkFormTextFieldProps {
  kind: MynkFormControlKind.TEXT;
}

interface CtrlChipSelect extends MynkFormChipSelectProps {
  kind: MynkFormControlKind.CHIP_SELECT;
}

interface CtrlIconedChipSelect extends MynkFormIconedChipSelectProps {
  kind: MynkFormControlKind.ICONED_CHIP_SELECT;
}

interface CtrlContactSearch extends MynkFormContactSearchProps {
  kind: MynkFormControlKind.CONTACT_SEARCH;
}

interface CtrlProjectSearch extends MynkFormProjectSearchProps {
  kind: MynkFormControlKind.PROJECT_SEARCH;
}

interface CtrlDateSelect extends MynkFormDateSelectProps {
  kind: MynkFormControlKind.DATE_SELECT;
}

interface CtrlFileUpload extends MynkFormFileUploadProps {
  kind: MynkFormControlKind.FILE_UPLOAD;
}

interface CtrlColorPicker extends MynkFormColorPickerProps {
  kind: MynkFormControlKind.COLOR_PICKER;
}

interface CtrlColoredChipSelect extends MynkFormColoredChipSelectProps {
  kind: MynkFormControlKind.COLORED_CHIP_SELECT;
}

interface CtrlPhoneNumber extends MynkFormPhoneNumberProps {
  kind: MynkFormControlKind.PHONE_NUMBER;
}

interface CtrlInstagramHandle extends MynkFormTextFieldProps {
  kind: MynkFormControlKind.INSTAGRAM_HANDLE;
}

export type MynkFormControlProps =
  | CtrlTextField
  | CtrlChipSelect
  | CtrlIconedChipSelect
  | CtrlContactSearch
  | CtrlProjectSearch
  | CtrlDateSelect
  | CtrlFileUpload
  | CtrlColorPicker
  | CtrlColoredChipSelect
  | CtrlPhoneNumber
  | CtrlInstagramHandle;
