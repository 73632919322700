import {ReactNode, useEffect, useMemo} from "react";
import {Stack, Typography} from "@mui/material";
import loginBackdrop from "../assets/images/login-backdrop.svg";
import ElevatorCard from "../components/ElevatorCard";
import {checkIsMobile} from "../util/mobile";


export interface MobileGuardProps {
  children: ReactNode;
}

export default function MobileGuard(props: MobileGuardProps) {
  const mobile = useMemo(() => checkIsMobile(), []);

  useEffect(() => {
    if (!mobile)
      return;

    // Set up the background for loginBackdrop
    const backdropDiv = document.createElement("div");
    backdropDiv.style.position = "fixed";
    backdropDiv.style.top = "0";
    backdropDiv.style.left = "0";
    backdropDiv.style.width = "100vw";
    backdropDiv.style.height = "100vh";
    backdropDiv.style.backgroundImage = `url(${loginBackdrop})`;
    backdropDiv.style.backgroundPosition = "center";
    backdropDiv.style.backgroundSize = "cover";
    backdropDiv.style.backgroundRepeat = "no-repeat";
    backdropDiv.style.zIndex = "-10";

    document.body.appendChild(backdropDiv);
    return () => {
      document.body.removeChild(backdropDiv);
    };
  }, [mobile]);

  if (!mobile) {
    return <>{props.children}</>;
  }

  return (
    <>
      {/*{props.children}*/}
      <Stack
        sx={{
          height: "100vh",
          boxSizing: "border-box",
          overflow: "hidden",
          position: "relative",
          zIndex: "1", // Ensure content is on top
        }}
        alignItems="center"
        justifyContent="center"
      >
        <ElevatorCard
          sx={{
            position: "absolute",
          }}
        >
          <Typography align="center" sx={{fontSize: 14}}>
            Mynk does not support mobile devices at this time.
            <br/>
            Please use a desktop browser.
          </Typography>
        </ElevatorCard>
      </Stack>
    </>
  );
}