import React from "react";
import { Box } from "@mui/material";
import { MynkPage, MynkTab, MynkTabInfo } from "../../../components/MynkPage";
import { PATHS, WorkflowPath, makePath } from "../../../paths";
import { useAtomValue } from "jotai";
import { currentWorkflowAtom } from "../../../components/MainAppBar/UserControlPopup";

const TABS: MynkTabInfo[] = [
  {
    title: "All",
    path: "/all",
  },
];

export default function ClientsPage() {
  return <MynkPage title="Clients" basePath={makePath(`/${useAtomValue(currentWorkflowAtom)?.type}` as WorkflowPath, PATHS.clients.index)} tabs={TABS} />;
}
