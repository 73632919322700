import React from 'react'
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Clear } from "@mui/icons-material";
import ElevatorDialog from '../components/ElevatorDialog';
import { ThreeDotsTask, TaskCheckbox } from '../components/TaskView';
import { Task } from '../api/types';
import { DateTime } from "luxon";
import BlackCalendar from "../assets/icons/black-calendar-icon.svg"
import ClockIcon from "../assets/icons/small-clock-icon.svg";
import { useSetTaskStatusMutation } from '../api/tasks';

export interface RemindersDialogProps {
    open: boolean;
    onClose: () => void;
    tasks: Task[];
}

interface TaskProps {
    task: Task;
}

function DateAndTime(props: TaskProps) {
    return (
        <Stack direction={'column'}>
            <Stack direction="row" spacing="0.7rem" alignItems="center" mt={2.5}>
                <Box component="img" src={BlackCalendar} sx={{ width: "1.2rem", height: "1.2rem" }} />
                <Typography>
                    <b>Due: </b>
                        {DateTime.fromISO(props.task.due_date ?? "")
                            .minus({ minutes: DateTime.local().offset }).toFormat(
                        "EEEE, LLL dd, yyyy"
                    )}
                </Typography>
            </Stack>
            <Stack direction="row" spacing="0.7rem" alignItems="center">
                <Box component="img" src={ClockIcon} sx={{ width: "1.2rem", height: "1.2rem" }} />
                <Typography>
                    <b>Time:</b>
                </Typography>
                <Typography
                    fontSize={"1.15rem"}
                    color="#75B3FF"
                    fontWeight="bold"
                    sx={{ minWidth: "7.7rem" }}
                >
                    {(() => {
                        const time = new Date(props.task.due_date);
                        let hours = time.getUTCHours(); // Extract hours using UTC
                        const minutes = time.getUTCMinutes(); // Extract minutes using UTC
                        const ampm = hours >= 12 ? 'PM' : 'AM';
                        hours = hours % 12;
                        hours = hours ? hours : 12; // the hour '0' should be '12'
                        const strMinutes = minutes < 10 ? '0'+minutes : minutes;
                        return `${hours}:${strMinutes} ${ampm}`;
                    })()}
                </Typography>
            </Stack>
        </Stack>
    );
}

export default function RemindersDialog(props: RemindersDialogProps) {
    const setTaskStatus = useSetTaskStatusMutation();
    const handleCheckChange = (task: Task, value: boolean) => {
        setTaskStatus.mutate({
            uuid: task.uuid,
            completed: value,
        });
        task.completed = value;
    };

    return (
        <ElevatorDialog
            open={props.open}
            onClose={props.onClose}
            sx={{pb: "2rem", overflowY: "auto"}}
            customCloseButton
        >
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant="h1" fontSize="2rem">{props.tasks.length === 1 ? "Reminder" : "Reminders"}</Typography>
                <Box mr={-4}>
                    <ThreeDotsTask task={props.tasks[0]} />
                    <IconButton
                        onClick={props.onClose}
                        sx={{ mt: -1.4 }}
                    >
                        <Clear />
                    </IconButton>
                </Box>
            </Stack>
            {props.tasks.length === 1 ? (
                <Stack flexDirection={'column'} width={"30rem"} minHeight={"12rem"}>
                    <Stack direction="row" spacing="0.7rem" mt={6}>
                        <TaskCheckbox
                            checked={props.tasks[0].completed}
                            onChange={() => {handleCheckChange(props.tasks[0], !props.tasks[0].completed)}}
                            sx={{marginTop: "0.4rem"}}
                        />
                        <Typography variant="h2" fontSize="2rem" sx={{textDecoration: props.tasks[0].completed ? "line-through" : ""}}>
                            {props.tasks[0].title}
                        </Typography>
                    </Stack>
                    <DateAndTime task={props.tasks[0]} />
                </Stack>
            ) : (
                <Stack spacing={2} maxHeight={"35rem"}>
                    <Typography variant="h6">You have {props.tasks.length} reminders:</Typography>
                    
                    {props.tasks.map((task) => (
                        <Stack flexDirection={'column'} width={"30rem"} minHeight={"12rem"} sx={{
                            p: 0.5,
                            pl: 2,
                            background:
                              "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%)",
                            borderRadius: "1.2rem",
                            boxShadow: "0px 5px 25px rgba(117, 179, 255, 0.10)",
                            opacity: task.completed ? 0.5 : "",
                          }}>
                            <Stack direction="row" spacing="0.7rem" mt={4} justifyContent={'space-between'}>
                                <TaskCheckbox
                                    checked={task.completed}
                                    onChange={() => {handleCheckChange(task, !task.completed)}}
                                    sx={{marginTop: "0.4rem"}}
                                />
                                <Typography variant="h2" fontSize="2rem" sx={{textDecoration: task.completed ? "line-through" : "", flexGrow: 1}}>
                                    {task.title}
                                </Typography>
                                <Box alignSelf={'center'}>
                                    <ThreeDotsTask task={task} />
                                </Box>
                            </Stack>
                            <DateAndTime task={task} />
                        </Stack>
                    ))}
                </Stack>
            )}
        </ElevatorDialog>
    )
}
