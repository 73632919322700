import {
  Link,
  Pagination,
  PaginationItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useListSalesQuery } from '../../api/sales';
import { ListSalesResponse, Sale, SaleStatus } from '../../api/types';
import SaleStatusChip from '../SaleStatusChip';
import ViewSaleDialog from '../../dialogs/ViewSale';
import { PaymentMethodLabel } from '../PaymentMethodLabel';
import { zonedDate } from '../../util/common';

interface SalesInfoTableRowProps {
  sale: Sale;
  viewSale: (sale: Sale) => void;
}

function SalesInfoTableRow (props: SalesInfoTableRowProps) {
  const date = useMemo(() => zonedDate(props.sale.created_at), [props.sale.created_at]);

  return (
    <TableRow>
      <TableCell>
        {date ? (
          <><b>{date.toFormat('EEEE')}</b>, {date.toFormat('LLL dd, yyyy')}</>
        ) : (
          <b>Invalid date</b>
        )}
      </TableCell>
      <TableCell>
        <b>${props.sale.amount.toFixed(2)}</b>
      </TableCell>
      <TableCell>
        <PaymentMethodLabel method={props.sale.payment_method} hint={props.sale.payment_hint}/>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ color: '#75b3ff' }}>
          {props.sale.client}
        </Typography>
      </TableCell>
      <TableCell>
        <SaleStatusChip value={props.sale.status}/>
      </TableCell>
      <TableCell>
        <Link variant="body2" sx={{ color: '#75b3ff', textDecoration: 'none', cursor: 'pointer' }}
              onClick={() => props.viewSale(props.sale)}>
          View sale
        </Link>
      </TableCell>
    </TableRow>
  );
}

export interface SalesInfoTableProps {
  status?: SaleStatus;
}

const PAGE_SIZE = 10;

export default function SalesInfoTable (props: SalesInfoTableProps) {
  const [page, setPage] = useState(0);
  const { data, isPending } = useListSalesQuery({
    page_index: page,
    page_size: PAGE_SIZE,
    status: props.status
  });

  const [current, setCurrent] = useState<null | ListSalesResponse>(null);
  useEffect(() => {
    if (!isPending && data) {
      setCurrent(data);
    }
  }, [data, isPending]);

  // if (isPending) {
  //   return <CircularProgress sx={{ display: 'table', mx: 'auto', mb: 5  }} />
  // }

  const [saleUnderView, setSaleUnderView] = useState<null | Sale>(null);
  const handleViewSale = (sale: Sale) => {
    setSaleUnderView(sale);
  };

  return (
    <>
      <ViewSaleDialog
        sale={saleUnderView}
        open={Boolean(saleUnderView)}
        onClose={() => setSaleUnderView(null)}
      />

      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: '#75b3ff30' }}>
            <TableCell>Date & Time</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Payment Method</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {current?.sales && current.sales.map(sale => (
            <SalesInfoTableRow key={sale.id} sale={sale} viewSale={handleViewSale}/>
          ))}
        </TableBody>
      </Table>

      {current && (
        <Stack direction="row" justifyContent="center" sx={{ my: 2 }}>
          <Pagination
            count={Math.ceil(current.total / current.page_size)}
            page={page + 1}
            onChange={(_, v) => v && setPage(v - 1)}
            variant="text"
            renderItem={(item) => (
              <PaginationItem {...item} />
            )}
          />
        </Stack>
      )}
    </>
  );
}