import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {GotoPortalParams, GotoPortalResponse} from "./types";
import axios from "axios";


export function useGotoPortalMutation(
  options: UseMutationOptions<
    GotoPortalResponse,
    unknown,
    GotoPortalParams
  >
) {
  return useMutation<
    GotoPortalResponse,
    unknown,
    GotoPortalParams
  >({
    mutationFn: async (params) => {
      return (await axios.post("/api/billing/portal", params)).data;
    },
    ...options,
  });
}

