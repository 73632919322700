import { Box, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { useVerifyEmailMutation } from "../../api/auth";
import { useLocation, useNavigate } from "react-router-dom";
import Ellipse from "../../assets/images/EmailVerifiedImage/Ellipse.svg";
import Check from "../../assets/images/EmailVerifiedImage/Check.svg";
import GppBadIcon from '@mui/icons-material/GppBad';
import LoginIcon from '@mui/icons-material/Login';
import {AxiosError} from "axios";
import { VerifyEmailParams } from "../../api/types";

export default function VerifyEmailPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const code = queryParams.get("code");
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const verifyEmail = useVerifyEmailMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, VerifyEmailParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        setErrorMsg(detail ?? "");
      }
    }
  });

  const [verified, setVerified] = useState(false);
  useEffect(() => {
    const verifyEmailAction = async () => {
      try {
        await verifyEmail.mutateAsync({ email: email ?? "", code: code ?? "" });
      } catch {
        // TODO
      }

      setVerified(true);
    }

    verifyEmailAction().then();
  }, []);

  //
  // redirect to checkout
  //
  const navigate = useNavigate();
  useEffect(() => {
    if (!verified)
      return;

  }, [verified]);

  return (
    verifyEmail.isPending ? (
      <CircularProgress />
    ) : (
      <Stack alignItems="center" m={6.5}>
        {verifyEmail.isSuccess && (
          <>
            <Box sx={{ position: 'relative', width: '7rem', height: '7rem', mb: 3 }}>
              <Box
                component="img"
                src={Ellipse}
                sx={{ position: 'absolute', top: 0, left: 0, width: "100%", height: "100%", zIndex: 1 }}
              />
              <Box
                component="img"
                src={Check}
                sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2, width: "70%", height: "70%" }}
              />
            </Box>
            <Typography variant="h4" sx={{mt: 3}}>Your e-mail has been verified!</Typography>
          </>
        )}
        {verifyEmail.isError && (
          <>
            <GppBadIcon sx={{ width: "17rem", height: "17rem", color: "#8f0b0b"}} />
            <Typography variant="h2">Something went wrong!</Typography>
            <Typography>{errorMsg || ""}</Typography>
          </>
        )}
        <br /><br />
        <IconButton onClick={() => navigate("/login")} sx={{ borderRadius: "1rem", alignSelf: "center" }}>
          <Stack direction="row" alignItems="center" spacing="0.3rem" mt={2}>
            <LoginIcon sx={{ width: "1.7rem", height: "1.7rem", color: "rgb(117, 179, 255)" }} />
            <Typography fontSize="1.5rem" color="rgb(117, 179, 255)">Go to login</Typography>
          </Stack>
        </IconButton>
      </Stack>
    )
  )
}
