import {
  AddListingParams,
  AddListingResponse,
  DeleteListingParams,
  DeleteListingResponse,
  ListListingsParams,
  ListListingsResponse,
  SetListingAvailabilityParams,
  SetListingAvailabilityResponse,
  SetListingTypeParams,
  SetListingTypeResponse,
} from "./types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

export function useDeleteListingMutation() {
  const queryClient = useQueryClient();
  return useMutation<DeleteListingResponse, unknown, DeleteListingParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/listing/delete", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["listings-list"] });
      // await queryClient.invalidateQueries("stage-stats");
    },
  });
}

export function useListListingsQuery(
  params: ListListingsParams,
  enabled?: boolean
) {
  return useQuery<ListListingsResponse>({
    queryKey: ["listings-list", params],
    queryFn: async () => (await axios.post("/api/listings/list", params)).data,
    enabled: enabled ?? true,
  });
}

export function useSetListingTypeMutation() {
  const queryClient = useQueryClient();
  return useMutation<SetListingTypeResponse, unknown, SetListingTypeParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/listings/set-type", params)).data,
    async onSuccess(_, params) {
      await queryClient.invalidateQueries({ queryKey: ["listings-list"] });
      // FIXME: await queryClient.invalidateQueries("stage-stats");
      await queryClient.invalidateQueries({
        queryKey: ["listing", params.uuid],
      });
    },
  });
}
