import { Availability } from "../api/types";
import { SxProps } from "@mui/material";
import { ChipSelect } from "./ChipSelect";
import { AVAILABILITY_OPTIONS } from "./ChipSelect/commonOptions";

import priorityChipArrowGreen from "../assets/icons/prioritychip-arrow-icon-green.svg";
import priorityChipArrowOrange from "../assets/icons/prioritychip-arrow-icon-orange.svg";

export interface AvailabilityChipProps {
  value: Availability;
  sx?: SxProps;
  onChange?: (value: string) => void | null;
}

export default function AvailabilityChip(props: AvailabilityChipProps) {
  
  switch (props.value) {
    case Availability.AVAILABLE:
      return (
        <ChipSelect
          options={AVAILABILITY_OPTIONS}
          value="available"
          placeholder="Availability"
          sx={{
            color: "#37ce4a",
            bgcolor: "#37ce4a30",
            ...props.sx,
          }}
          onChange={props.onChange}
          arrowIcon={priorityChipArrowGreen}
        />
      );

    case Availability.ON_HOLD:
      return (
        <ChipSelect
          options={AVAILABILITY_OPTIONS}
          value="on_hold"
          placeholder="Availability"
          sx={{
            color: "#ff7a00",
            bgcolor: "#ff7a0019",
            ...props.sx,
          }}
          onChange={props.onChange}
          arrowIcon={priorityChipArrowOrange}
        />
      );
    
    case Availability.NOT_AVAILABLE:
    return (
      <ChipSelect
        options={AVAILABILITY_OPTIONS}
        value="not_available"
        placeholder="Availability"
        sx={{
          color: "#ff0000",
          bgcolor: "#ff000019",
          ...props.sx,
        }}
        onChange={props.onChange}
        arrowIcon={priorityChipArrowOrange}
      />
    );
    
    default:
      return (
        <ChipSelect
          options={AVAILABILITY_OPTIONS}
          value=""
          sx={{
            color: "#5d6063",
            bgcolor: "#ecf0f5",
            ...props.sx
          }}
          onChange={props.onChange}
          />
      )
  }
}
