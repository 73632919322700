import { Grid, Typography, Stack, Divider } from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import { DateTime } from "luxon";
import MynkFormEditable from "../components/MynkForm/MynkFormEditable";
import Checkbox from "@mui/material/Checkbox";
import MynkFormConditional, {
  FormValues,
} from "../components/MynkForm/MynkFormConditional";
import { useAddEventMutation, useEditEventMutation } from "../api/scheduler";
import { BigCalendarEvent } from "../pages/CommonComponents/Scheduler/common/CalendarCard";
import { zonedDate } from "../util/common";
import { CALENDAR_TAG_OPTIONS, MEETING_STATUS_OPTIONS } from "../components/ChipSelect/commonOptions";
import { useFormikContext } from "formik";
import { EditEventParams, WorkflowType } from "../api/types";
import { AxiosError } from "axios";

export interface EditEventDialogProps {
  open: boolean;
  onClose: () => void;
  event?: BigCalendarEvent;
}

export function EditEventDialog(props: EditEventDialogProps) {
  const [isAllDay, setIsAllDay] = useState(props.event?.allDay);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllDay(event.target.checked);
  };

  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const editEvent = useEditEventMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, EditEventParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        setErrorMsg(detail ?? "");
      }
    },
  });
  const handleSubmit = async (values: any) => {
    let startDate, endDate;
    try {
      if (props.event?.repetitiveness) {
        setErrorMsg(undefined);
        await editEvent.mutateAsync({
          uuid: props.event?.uuid ?? "",
          name: values["name"],
          location: values["location"],
          description: values["description"],
          tag: values["tag"],
          client_uuid: values["clientUuid"],
          is_from_google: props.event?.isFromGoogle,
          my_status: props.event?.isFromGoogle ? values["status"] : undefined,
        });
        props.onClose();
      }
      const zStartDate = zonedDate(values["startDate"]);
      const zStartTime = values?.["startTime"]
        ? zonedDate(values["startTime"])
        : null;
      const zEndTime = values?.["endTime"]
        ? zonedDate(values["endTime"])
        : null;

      if (zEndTime) {
        endDate = DateTime.fromObject({
          year: zStartDate.year,
          month: zStartDate.month,
          day: zStartDate.day,
          hour: zEndTime ? zEndTime.hour : 0,
          minute: zEndTime ? zEndTime.minute : 1,
          second: zEndTime ? zEndTime.second : 0,
        });
      } else {
        endDate = undefined;
      }
      startDate = DateTime.fromObject({
        year: zStartDate.year,
        month: zStartDate.month,
        day: zStartDate.day,
        hour: zStartTime ? zStartTime.hour : 0,
        minute: zStartTime ? zStartTime.minute : 0,
        second: zStartTime ? zStartTime.second : 0,
      });
    } catch (e) {
      setErrorMsg("Some fields are empty");
    }
    if (!startDate) {
      setErrorMsg("Some fields are empty");
    } else {
      setErrorMsg(undefined);
      await editEvent.mutateAsync({
        uuid: props.event?.uuid ?? "",
        name: values["name"],
        date: props.event?.repetitiveness ? undefined : startDate,
        is_all_day: isAllDay,
        end_date: props.event?.repetitiveness
          ? undefined
          : isAllDay
          ? undefined
          : endDate,
        location: values["location"],
        description: values["description"],
        tag: values["tag"],
        client_uuid: values["clientUuid"],
        is_from_google: props.event?.isFromGoogle,
        my_status: props.event?.isFromGoogle ? values["status"] : undefined,
      });
      props.onClose();
    }
  };

  const initialValues = useMemo(() => {
    if (!props.event) return {};

    setIsAllDay(props.event.allDay);

    return {
      name: props.event.title,
      date: DateTime.fromJSDate(props.event.start),
      endDate: props.event.end && DateTime.fromJSDate(props.event.end),
      location: props.event.location,
      description: props.event.description,
      tag: props.event.tag,
      clientUuid: props.event.clientUuid,
      status: props.event.myStatus,
    };
  }, [props.event]);

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      title={
        props.event?.repetitiveness ? "Edit recurring event" : "Edit event"
      }
      action="Apply changes"
      onSubmit={handleSubmit}
      loading={editEvent.isPending}
      errorMsg={errorMsg}
    >
      <Stack
        direction="row"
        sx={{
          width: "26rem",
          maxHeight: "33rem",
        }}
      >
        <Grid container spacing={"1rem"} alignItems="center">
          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name="name"
                label="Event's name"
                placeholder="Enter name"
                autoComplete={false}
                default={initialValues.name ?? ""}
              />
            </MynkFormEditable>
          </Grid>
          {!props.event?.repetitiveness && !props.event?.projectUuid && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <MynkFormEditable>
                  <MynkFormControl
                    kind={MynkFormControlKind.DATE_SELECT}
                    name="startDate"
                    label="Date"
                    default={zonedDate(initialValues.date) ?? DateTime.now()}
                  />
                </MynkFormEditable>
              </Grid>
              {!isAllDay && (
                <>
                  <Grid item xs={6}>
                    <MynkFormEditable>
                      <MynkFormControl
                        kind={MynkFormControlKind.DATE_SELECT}
                        name="startTime"
                        label="Start"
                        default={
                          zonedDate(initialValues.date) ?? DateTime.now()
                        }
                        onlyTime
                      />
                    </MynkFormEditable>
                  </Grid>
                  <Grid item xs={6}>
                    <MynkFormEditable>
                      <MynkFormControl
                        kind={MynkFormControlKind.DATE_SELECT}
                        name="endTime"
                        label="End"
                        default={
                          zonedDate(initialValues.endDate) ?? DateTime.now()
                        }
                        onlyTime
                      />
                    </MynkFormEditable>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  sx={{ alignItems: "center", ml: "-0.7rem", mt: "-1rem" }}
                >
                  <Checkbox
                    defaultChecked={props.event?.allDay}
                    onChange={handleCheckboxChange}
                  />
                  <Typography
                    fontSize="1.2rem"
                    fontWeight="bold"
                    sx={{ mt: "0.12rem" }}
                  >
                    All day
                  </Typography>
                </Stack>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {!props.event?.projectUuid && (


          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.CONTACT_SEARCH}
                name="clientUuid"
                label="Client"
                placeholder="Enter client"
                default={initialValues.clientUuid ?? ""}
                workflowType={WorkflowType.PHOTOGRAPHY}
              />
            </MynkFormEditable>
          </Grid>)}
          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name="location"
                label="Location"
                placeholder="Enter location"
                autoComplete={false}
                default={initialValues.location ?? ""}
              />
            </MynkFormEditable>
          </Grid>

          <Grid item xs={12}>
            <MynkFormEditable longText>
              <MynkFormControl
                kind={MynkFormControlKind.TEXT}
                name="description"
                label="Description"
                placeholder="Enter description"
                autoComplete={false}
                default={initialValues.description ?? ""}
                multiLine
                height={70}
              />
            </MynkFormEditable>
          </Grid>

          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.COLORED_CHIP_SELECT}
              name="tag"
              label="Tag"
              default={initialValues.tag ?? CALENDAR_TAG_OPTIONS[0].value}
              options={CALENDAR_TAG_OPTIONS}
            />
          </Grid>
          
          {props.event?.isFromGoogle && (
            <Grid item xs={12}>
              <MynkFormControl
                kind={MynkFormControlKind.CHIP_SELECT}
                name="status"
                label="Status"
                default={initialValues.status ?? MEETING_STATUS_OPTIONS[0].value}
                options={MEETING_STATUS_OPTIONS}
              />
            </Grid>
          )}
        </Grid>
      </Stack>
    </MynkFormDialog>
  );
}
