import { MynkTab } from "../../../../components/MynkPage";
import ActionMenuButton from "../../../../components/ActionMenuButton";
import TuneIcon from "@mui/icons-material/Tune";
import { useState } from "react";

import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";

import { GENERAL_SETTING_NAMES, GeneralSettingsTableCard } from "../common/GeneralSettingsTableCard";
import GeneralSideCard from "../common/GeneralSideCard";
import { userCurrencyAtom } from "../../../../layouts/Main";
import { useSetAtom } from "jotai";

// interface UpdateSettingsButtonProps {
//   data: Array<Record<string, any>>;
//   setShow: (newShow: boolean) => void;
// }

// function UpdateSettingsButton(props: UpdateSettingsButtonProps) {
  
//   return (
//     <ActionMenuButton
//       onClickItem={handleSubmit}
//     >
//       {editGeneralSettings.isPending ? (
//         <CircularProgress size={27} sx={{ color: "white" }} />
//       ) : (
//         "Update"
//       )}
//     </ActionMenuButton>
//   );
// }

interface UpdateRemiderProps {
  sx?: SxProps;
  show: boolean;
}

const UpdateRemider = (props: UpdateRemiderProps) => {
  const blinkStyle = {
    fontFamily: "Helvetica Neue",
    animation: "blink 1.5s infinite",
  };

  return props.show ? (
    <Box sx={{ ...props.sx }}>
      <style>
        {`
          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }
        `}
      </style>
      <Typography style={blinkStyle}>
        Click on "Update" to save the changes!
      </Typography>
    </Box>
  ) : (
    <></>
  );
};

export default function SettingsGeneralSubpage() {
  const [data, setData] = useState<Array<Record<string, any>>>([]);

  return (
    <MynkTab
      title="General"
      >
      <Stack direction="row" spacing={3.7}>
        <GeneralSettingsTableCard
          data={data.filter(  // Give all Settings except country, currency and time zone.
            (item) =>
              ![
                GENERAL_SETTING_NAMES.COUNTRY,
                GENERAL_SETTING_NAMES.CURRENCY,
                GENERAL_SETTING_NAMES.TIME_ZONE,
              ].includes(item.setting)
          )}
          setData={setData}
        />
        <GeneralSideCard
          generalData={data.filter(  // Give only country, currency and time zone.
            (item) =>
              [
                GENERAL_SETTING_NAMES.COUNTRY,
                GENERAL_SETTING_NAMES.CURRENCY,
                GENERAL_SETTING_NAMES.TIME_ZONE,
                GENERAL_SETTING_NAMES.EMAIL,
                GENERAL_SETTING_NAMES.NAME,
              ].includes(item.setting)
          )}
          setGeneralData={setData}
        />
      </Stack>
    </MynkTab>
  );
}
