import { RealEstateClientType } from "../api/types";
import { Box, SxProps, Typography } from "@mui/material";
import { ChipSelect } from "./ChipSelect";
import { REAL_ESTATE_CONTACT_TYPE_OPTIONS } from "./ChipSelect/commonOptions";

export interface RealEstateClientTypeChipProps {
  value: RealEstateClientType;
  height?: number;
  width?: number;
  sx?: SxProps;
}

export default function RealEstateClientTypeReadOnlyChip(
  props: RealEstateClientTypeChipProps
) {
  return (
    <Box
      sx={{
        borderRadius: 50,
        height: props.height ? `${props.height / 14}rem` : "2rem",
        width: props.width ? `${props.width / 14}rem` : "8.9286rem",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        bgcolor:
          props.value === RealEstateClientType.OWNER
            ? "#D288F233"
            : props.value == RealEstateClientType.PROSPECT
            ? "#75B3FF33"
            : "#5d6063",
      }}
    >
      <Typography
        sx={{
          fontSize: props.height ? `${props.height / 2.5 / 14}rem` : "1rem",
          color:
            props.value === RealEstateClientType.OWNER
              ? "#D288F2"
              : props.value == RealEstateClientType.PROSPECT
              ? "#75B3FF"
              : "#ecf0f5",
        }}
      >
        {props.value === RealEstateClientType.OWNER
          ? "Owner"
          : props.value === RealEstateClientType.PROSPECT
          ? "Prospect"
          : ""}
      </Typography>
    </Box>
  );
}
