import React, {ReactNode, useEffect, useMemo} from "react";
import {useAtom, useAtomValue} from "jotai";
import {axiosAuthenticatedAtom, loginStateAtom} from "../state/auth";
import {useNavigate} from "react-router-dom";
import {Box, CircularProgress, Stack} from "@mui/material";
import axios from "axios";
import {useWhoamiQuery} from "../api/auth";
import mynkTextLogo from "../assets/images/mynk-textlogo.svg";
import { currentWorkflowAtom } from "../components/MainAppBar/UserControlPopup";

export interface AuthGuardProps {
  children: ReactNode;
}

export default function AuthGuard(props: AuthGuardProps) {
  const loginStateStorage = useMemo(() => localStorage.getItem('login-state'), []);
  const loginState = useAtomValue(loginStateAtom);
  const [currentWorkflow, setCurrentWorkflow] = useAtom(currentWorkflowAtom);
  const [axiosAuthenticated, updateAxiosAuthenticated] = useAtom(axiosAuthenticatedAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loginState && !loginStateStorage) {
      navigate('/login');
    } else if (loginState) {
      console.log('LS', loginState, currentWorkflow);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + loginState.accessToken;
      updateAxiosAuthenticated(true);
      axios.defaults.headers.common["X-Workflow"] = currentWorkflow?.uuid;  // Attack header to determine the user's current workflow.
    }
  }, [loginState, loginStateStorage]);

  const {data: whoamiData, isSuccess: whoamiSuccess, isError: whoamiError} = useWhoamiQuery();

  // expired trials are handled elsewhere
  const isPlanActive = ['active', 'trial', 'trial_expired'].includes(whoamiData?.plan?.status || '');

  useEffect(() => {
    if (whoamiError || (whoamiSuccess && whoamiData && whoamiData.user === null)) {
      // did not get a user back, so our credentials are probably outdated.
      navigate('/login');
    }

    if (whoamiSuccess && whoamiData?.user && !isPlanActive) {
      navigate('/signup/checkout');
    }
  }, [whoamiData, whoamiSuccess, whoamiError]);

  return (
    <>
      {((!loginState && loginStateStorage) || !whoamiData?.user) && (
        <Stack justifyContent="center" alignItems="center" sx={{height: '100vh'}}>
          <CircularProgress size={"4.2857rem"} thickness={32} sx={{ color: "#c05ec6" }}/>
          <Box height={"1.4286rem"} />
          <Box component="img" src={mynkTextLogo} sx={{ width: "8.5714rem" }}/>
        </Stack>
      )}

      {loginState && axiosAuthenticated && whoamiData?.user && isPlanActive && props.children}
    </>
  );
}
