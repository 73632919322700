import { useMutation, useQuery, useQueryClient, UseMutationOptions } from "@tanstack/react-query";
import axios from "axios";
import {
  AskChangeEmailParams,
  AskChangeEmailResponse,
  EditGeneralSettingsParams,
  EditGeneralSettingsResponse,
  GetBusinessLogoResponse,
  GetDevicesHistoryResponse,
  GetProfilePhotoParams,
  GetProfilePhotoResponse,
  GetUserCountryResponse,
  GetUserCurrencyResponse,
  GetUserTimezoneNameResponse,
  GetUserTimezoneResponse,
  ListGeneralSettingsParams,
  ListGeneralSettingsResponse,
  RecordUserParams,
  RecordUserResponse,
  UploadBusinessLogoParams,
  UploadBusinessLogoResponse,
  UploadProfilePhotoParams,
  UploadProfilePhotoResponse,
  VerifyChangeEmailParams,
  VerifyChnageEmailResponse,
} from "./types";

export function useListGeneralSettingsQuery(
  params: ListGeneralSettingsParams,
  enabled?: boolean
) {
  return useQuery<ListGeneralSettingsResponse>({
    queryKey: ["general-settings-list", params],
    queryFn: async () =>
      (await axios.get("/api/settings/list-general", params)).data,

    enabled: enabled ?? true,
  });
}

export function useEditGeneralSettingsMutation() {
  const queryClient = useQueryClient();
  return useMutation<
    EditGeneralSettingsResponse,
    unknown,
    EditGeneralSettingsParams
  >({
    mutationFn: async (params) =>
      (await axios.post("/api/settings/edit-general", params)).data,

    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["user-currency"] });
      await queryClient.invalidateQueries({ queryKey: ["user-timezone"] });
      await queryClient.invalidateQueries({ queryKey: ["user-timezone-name"] });
      await queryClient.invalidateQueries({
        queryKey: ["general-settings-list"],
      });
      await queryClient.invalidateQueries({ queryKey: ["workflows-list"] });
    },
  });
}


export function useAskChangeEmailMutation(
  options: UseMutationOptions<
  AskChangeEmailResponse,
  unknown,
  AskChangeEmailParams
>
) {
  return useMutation<
    AskChangeEmailResponse,
    unknown,
    AskChangeEmailParams
  >({
    mutationFn: async (params) =>
      (await axios.post("/api/settings/ask-change-email", params)).data,
  ...options
    });
}


export function useVerifyChangeEmailMutation(
  options: UseMutationOptions<
  VerifyChnageEmailResponse,
  unknown,
  VerifyChangeEmailParams
>
) {
  return useMutation<
    VerifyChnageEmailResponse,
    unknown,
    VerifyChangeEmailParams
  >({
    mutationFn: async (params) =>
      (await axios.post("/api/settings/verify-change-email", params)).data,
      ...options,
  });
}


export function useGetUserCurrencyQuery() {
  return useQuery<GetUserCurrencyResponse>({
    queryKey: ["user-currency"],
    queryFn: async () =>
      (await axios.get("/api/settings/get-user-currency")).data,
  });
}

export function useGetUserTimezoneQuery() {
  return useQuery<GetUserTimezoneResponse>({
    queryKey: ["user-timezone"],
    queryFn: async () =>
      (await axios.get("/api/settings/get-user-timezone")).data,
  });
}

export function useGetUserCountryQuery() {
  return useQuery<GetUserCountryResponse>({
    queryKey: ["user-country"],
    queryFn: async () =>
      (await axios.get("/api/settings/get-user-country")).data,
  });
}

export function useRecordUserMutation() {
  return useMutation<RecordUserResponse, unknown, RecordUserParams>({
    mutationFn: async (params) => {
      return (await axios.post("/api/settings/record-user", params)).data;
    },
  });
}

export function useGetDevicesHistoryQuery() {
  return useQuery<GetDevicesHistoryResponse>({
    queryKey: ["devices-history"],
    queryFn: async () =>
      (await axios.get("/api/settings/get-devices-history")).data,
  });
}

export function useUploadProfilePhotoMutation() {
  const queryClient = useQueryClient();

  return useMutation<
    UploadProfilePhotoResponse,
    unknown,
    UploadProfilePhotoParams
  >({
    mutationFn: async (params) =>
      (await axios.post("/api/settings/upload-profile", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["profile-photo"] });
    },
  });
}

export function useGetProfilePhotoQuery() {
  return useQuery<GetProfilePhotoResponse>({
    queryKey: ["profile-photo"],
    queryFn: async () =>
      (await axios.get("/api/settings/get-profile-photo")).data,
  });
}

export function useUploadBusinessLogoMutation() {
  const queryClient = useQueryClient();

  return useMutation<
    UploadBusinessLogoResponse,
    unknown,
    UploadBusinessLogoParams
  >({
    mutationFn: async (params) =>
      (await axios.post("/api/settings/upload-business-logo", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["business-logo"] });
    },
  });
}

export function useGetBusinessLogoQuery() {
  return useQuery<GetBusinessLogoResponse>({
    queryKey: ["business-logo"],
    queryFn: async () =>
      (await axios.get("/api/settings/get-business-logo")).data,
  });
}
