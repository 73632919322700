import {MynkPage} from "../../../components/MynkPage";
import {useMemo} from "react";
import { useParams} from "react-router-dom";
import {useGetProjectQuery} from "../../../api/projects";
import { PATHS, WorkflowPath, makePath } from "../../../paths";

const TABS = [
  {
    title: 'General',
    path: '/general',
  },
  {
    title: "Photos",
    path: "/photos"
  }
]

export default function ViewRealEstateListingPage() {
  const {projectId} = useParams();
  const basePath = useMemo(() => {
    return makePath(WorkflowPath.REAL_ESTATE, PATHS.viewProject(projectId ?? "").index);
  }, [projectId]);

  const {data} = useGetProjectQuery({project_uuid: projectId ?? ''});

  return (
    <MynkPage
      title={!data?.project ? '...' : data.project.name}
      basePath={basePath}
      tabs={TABS}
      backLink={-1}
    />
  );
}
