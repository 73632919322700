import React from 'react'
import { Stack, Box, TextField, Typography } from '@mui/material';
import { BoxWithGradientFrame } from '../../components/GradientFrame'
import FormButton from './FormButton';

type AddDetailsPageProps = {
    setCurrentPage: React.Dispatch<React.SetStateAction<1 | 2 | 3 | 4>>
    name?: string
    setName?: React.Dispatch<React.SetStateAction<string>>
    email?: string
    setEmail?: React.Dispatch<React.SetStateAction<string>>
    phone?: string
    setPhone?: React.Dispatch<React.SetStateAction<string>>
}

type InputProps = {
    placeholder: string
    input?: string
    setInput?: React.Dispatch<React.SetStateAction<string>>
}

function Input({placeholder, input, setInput}: InputProps) {
    return (
        <BoxWithGradientFrame>
            <Box sx={{width: '100%', marginTop: '0.2rem', marginLeft: '1rem'}}>
                <TextField
                    variant="standard"
                    placeholder={placeholder}
                    value={input}
                    fullWidth
                    InputProps={{
                        disableUnderline: true,
                        sx: {
                            height: '4.5rem',
                            width: '100%',
                        },
                    }}
                    onChange={(e) => {
                        if (setInput) {
                            setInput(e.target.value)
                        }
                    }}
                />
            </Box>
        </BoxWithGradientFrame>
    )
}

function AddDetails({ setCurrentPage, name, setName, email, setEmail, phone, setPhone }: AddDetailsPageProps) {
    const isValidEmail = (email: string) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(email);
    };

    return (
        <Stack
                sx={{
                    borderRadius: '16px',
                    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%)',
                    boxShadow: '0px 5px 15px 0px rgba(117, 179, 255, 0.20)',
                    paddingX: '2rem',
                    paddingY: '2rem',
                    height: 'fit-content',
                    alignSelf: 'center',
                }}
                flexGrow={1}
            >
                <Typography sx={{fontWeight: 600, fontSize: '1.6rem', marginLeft: '1.2rem', marginBottom: '0.5rem'}}>Add your details</Typography>
                <Typography sx={{marginLeft: '1.2rem', marginTop: '1rem', fontSize: '1.1rem'}}>Name:</Typography>
                <Box sx={{marginLeft: '1.2rem', marginRight: '1.2rem'}}>
                    <Input
                        placeholder='Enter your name'
                        input={name}
                        setInput={setName}
                    />
                </Box>
                <Typography sx={{marginLeft: '1.2rem', marginTop: '1rem', fontSize: '1.1rem'}}>E-mail:</Typography>
                <Box sx={{marginLeft: '1.2rem', marginRight: '1.2rem'}}>
                    <Input
                        placeholder='Enter your e-mail'
                        input={email}
                        setInput={setEmail}
                    />
                </Box>
                <Typography sx={{marginLeft: '1.2rem', marginTop: '1rem', fontSize: '1.1rem'}}>Phone:</Typography>
                <Box sx={{marginLeft: '1.2rem', marginRight: '1.2rem'}}>
                    <Input
                        placeholder='Enter your phone number'
                        input={phone}
                        setInput={setPhone}
                    />
                </Box>
                {name && email && phone && isValidEmail(email) && (
                    <Box sx={{alignSelf: 'center'}}>
                        <FormButton text='Book' nextPage={4} setCurrentPage={setCurrentPage} />
                    </Box>
                )} 
                {email && !isValidEmail(email) && (
                    <Typography sx={{marginLeft: '1.2rem', marginTop: '1rem', fontSize: '1.1rem', color: 'red'}}>Invalid email</Typography>
                )}
        </Stack>
    )
}

export default AddDetails
