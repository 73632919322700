import {ClientSource} from "../api/types";
import {Box, Stack, SxProps, Typography} from "@mui/material";
import React from "react";

import otherIcon from '../assets/icons/sources/other.svg';
import facebookIcon from '../assets/icons/sources/facebook.svg';
import tiktokIcon from '../assets/icons/sources/tiktok.svg';
import websiteIcon from '../assets/icons/sources/website.svg';
import instagramIcon from '../assets/icons/sources/instagram.svg';
import googleSearchIcon from '../assets/icons/sources/google-search.svg';
import linkedinIcon from '../assets/icons/sources/linkedin.svg';
import dribbleIcon from '../assets/icons/sources/dribble.svg';
import behanceIcon from '../assets/icons/sources/behance.svg';
import pinterestIcon from '../assets/icons/sources/pinterest.svg';
import referralIcon from '../assets/icons/sources/referral.svg';
import adsIcon from '../assets/icons/sources/ads.svg';
import {loginStateAtom} from "../state/auth";


export interface ClientSourceRowInfo {
  source: ClientSource;
  count: number;
}

export interface ClientSourceRowsProps {
  data: ClientSourceRowInfo[];
}

function ClientSourceIcon({ source, sx }: { source: ClientSource, sx?: SxProps; }) {
  let imageSource;
  switch (source) {
    case ClientSource.FACEBOOK:
      imageSource = facebookIcon;
      break;

    case ClientSource.TIKTOK:
      imageSource = tiktokIcon;
      break;

    case ClientSource.WEBSITE:
      imageSource = websiteIcon;
      break;

    case ClientSource.INSTAGRAM:
      imageSource = instagramIcon;
      break;

    case ClientSource.GOOGLE_SEARCH:
      imageSource = googleSearchIcon;
      break;

    case ClientSource.LINKEDIN:
      imageSource = linkedinIcon;
      break;

    case ClientSource.DRIBBLE:
      imageSource = dribbleIcon;
      break;

    case ClientSource.BEHANCE:
      imageSource = behanceIcon;
      break;

    case ClientSource.PINTEREST:
      imageSource = pinterestIcon;
      break;

    case ClientSource.REFERRAL:
      imageSource = referralIcon;
      break;
      
    case ClientSource.PAID_ADS:
      imageSource = adsIcon;
      break;

    case ClientSource.OTHER:
    default:
      imageSource = otherIcon;
      break;
  }

  return <Box component="img" alt={source} src={imageSource} style={{ width: "1.33rem", height: "1.33rem" }} sx={{ ...sx }} />;
}

export default function ClientSourceRows(props: ClientSourceRowsProps) {
  return (
    <Stack spacing={0.8}>
      {props.data.map((row, i) => (
        <Stack
          key={i}
          direction="row"
          sx={{ bgcolor: 'white', border: 'solid 1px #ebebeb', borderRadius: "0.6rem", py: 0.5,  pb: 0.4 }}
          spacing={1}
          height="2.4rem"
          width="112%"
          alignItems="center"
        >
          <ClientSourceIcon source={row.source} sx={{ ml: 2 }} />

          <Typography textTransform="capitalize" variant="body2" fontWeight="bold">
            {row.source.replace("_", " ")}
          </Typography>

          <Box sx={{ flex: 1 }} />

          <Typography textTransform="capitalize" variant="body2" fontWeight="bold" sx={{ pr: 3}}>
            {row.count}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}
