import {MynkFormControlCommonProps} from './types';
import {useFormikContext} from 'formik';
import {Box, Typography} from '@mui/material';
import React from 'react';
import ContactSearch from "../../ContactSearch";
import {useIsInEditable} from "../context";
import { ClientSummary, WorkflowType } from '../../../api/types';

export interface MynkFormContactSearchProps extends MynkFormControlCommonProps<null | string> {
  workflowType?: WorkflowType;
}


export function MynkFormContactSearch(props: MynkFormContactSearchProps) {
  const formik = useFormikContext();

  const handleSelectContact = (client?: ClientSummary) => {
    formik?.setFieldValue(props.name, client?.uuid);
  };

  const isInEditable = useIsInEditable();

  return (
    <Box>
      {!isInEditable && (
        <Typography variant="subtitle1">{props.label}</Typography>
      )}

      <ContactSearch
        name={props.name}
        default={props.default}
        placeholder={props.placeholder}
        onSelectContact={handleSelectContact}
        workflowType={props.workflowType}
      />
    </Box>
  );
}
