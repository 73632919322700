import { Grid, Card, Typography, Stack, TextField, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useMemo, useEffect } from "react";
import ElevatorDialog from "../components/ElevatorDialog";
import {
  useAskChangeEmailMutation,
  useVerifyChangeEmailMutation,
} from "../api/settings";
import DialogActionButton from "../components/DialogActionButton";
import axios, { AxiosError } from "axios";
import { AskChangeEmailParams, VerifyChangeEmailParams } from "../api/types";
import { closeSnackbar, useSnackbar } from "notistack";
import {
    useMutation,
    useQuery,
    useQueryClient,
    UseMutationOptions,
  } from "@tanstack/react-query";

export interface ChangeEmailDialogProps {
  open: boolean;
  onClose: () => void;
  file_uuid?: string;
}

export function ChangeEmailDialog(props: ChangeEmailDialogProps) {
    const queryClient = useQueryClient();

    const { enqueueSnackbar } = useSnackbar();
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const askChangeEmail = useAskChangeEmailMutation({
    onError: (error: unknown) => {
        if (error instanceof Error && "response" in error) {
          const axiosError = error as AxiosError<
            unknown,
            AskChangeEmailParams
          >;
          const detail = (axiosError.response?.data as { detail?: string })
            ?.detail;
          setErrorMsg(detail ?? "");
        }
      }
    }
  );
  const verifyChangeEmail = useVerifyChangeEmailMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<
          unknown,
          VerifyChangeEmailParams
        >;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        setErrorMsg(detail ?? "");
      }
    },
    onSuccess: async () => {
        props.onClose();
        await queryClient.invalidateQueries({
            queryKey: ["general-settings-list"],
          });
        enqueueSnackbar("Email changed successfully", {
            variant: "success",
            action: (key) => (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  closeSnackbar(key);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ),
            style: {
              borderRadius: "0.7rem",
            },
          });
    }
  });
  const [newEmail, setNewEmail] = useState("");

  const [oldCode, setOldCode] = useState("");
  const [newCode, setNewCode] = useState("");

  const handleAsk = async () => {
    setErrorMsg("");
    await askChangeEmail.mutateAsync({
      new_email: newEmail,
    });
  };

  const handleVerify = async () => {
    setErrorMsg("");
    await verifyChangeEmail.mutateAsync({
        new_email_code: newCode,
        old_email_code: oldCode
    })
  };

  return (
    <ElevatorDialog open={props.open} onClose={props.onClose}>
      <Stack spacing="1rem" sx={{ mx: "3rem", my: "2rem", width: "27rem" }}>
        <Stack spacing={"1rem"}>
          <Typography variant="h1" sx={{ ml: "-0.26rem" }}>
            Change email
          </Typography>
          <Typography sx={{ color: "red" }}>{errorMsg}</Typography>
          <Stack
            direction="row"
            spacing="0.7rem"
            alignItems={"center"}
            sx={{
              opacity: askChangeEmail.isSuccess ? 0.3 : 1,
              pointerEvents: askChangeEmail.isSuccess ? "none" : undefined,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>To: </Typography>
            <TextField
              variant="standard"
              InputProps={{ disableUnderline: true }}
              placeholder="New email address"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              sx={{
                width: "100%",
                "& input::placeholder": {
                  fontFamily: "Helvetica Neue",
                  fontSize: "1.4rem",
                },
                "& input": {
                  fontFamily: "Helvetica Neue",
                  fontSize: "1.4rem",
                },
              }}
            ></TextField>
          </Stack>
          <Stack
            sx={{
              alignItems: "center",
              opacity: askChangeEmail.isSuccess ? 0.3 : 1,
              pointerEvents: askChangeEmail.isSuccess ? "none" : undefined,
            }}
          >
            <DialogActionButton onClick={handleAsk} loading={askChangeEmail.isPending}>Submit</DialogActionButton>{" "}
          </Stack>{" "}
        </Stack>
        <Stack
          spacing="0rem"
          sx={{
            opacity: askChangeEmail.isSuccess ? 1 : 0.3,
            pointerEvents: askChangeEmail.isSuccess ? undefined : "none",
          }}
        >
            {askChangeEmail.isSuccess &&
            <Card sx={{ padding: "0.8rem", mb: "1rem"}}>
            <Typography>We've sent two emails to your old and new email addresses.<br/>Enter the codes to complete the process:<br/><b>Note:</b> The codes will be expired in 10 minutes.</Typography>
            </Card>
            }
          <Stack direction="row" spacing="0.9rem" alignItems={"center"}>
            <Typography sx={{ fontWeight: "bold" }}>
              Code from old email:&nbsp;
            </Typography>
            <TextField
              inputProps={{ maxLength: 6 }}
              variant="standard"
              InputProps={{ disableUnderline: true }}
              placeholder="XXXXXX"
              value={oldCode}
              onChange={(e) => {
                if (Number(e.target.value)) {
                  setOldCode(e.target.value);
                }
              }}
              sx={{
                width: "50%",
                "& input::placeholder": {
                  fontFamily: "Helvetica Neue",
                  fontSize: "1.4rem",
                },
                "& input": {
                  fontFamily: "Helvetica Neue",
                  fontSize: "1.4rem",
                },
              }}
            ></TextField>
          </Stack>
          <Stack direction="row" spacing="0.7rem" alignItems={"center"}>
            <Typography sx={{ fontWeight: "bold" }}>
              Code from new email:{" "}
            </Typography>
            <TextField
              inputProps={{ maxLength: 6 }}
              variant="standard"
              InputProps={{ disableUnderline: true }}
              placeholder="XXXXXX"
              value={newCode}
              onChange={(e) => {
                if (Number(e.target.value)) {
                  setNewCode(e.target.value);
                }
              }}
              sx={{
                width: "50%",
                "& input::placeholder": {
                  fontFamily: "Helvetica Neue",
                  fontSize: "1.4rem",
                },
                "& input": {
                  fontFamily: "Helvetica Neue",
                  fontSize: "1.4rem",
                },
              }}
            ></TextField>
          </Stack>
          <Stack sx={{ alignItems: "center", pt: "1rem" }}>
            <DialogActionButton onClick={handleVerify} loading={verifyChangeEmail.isPending}>
              Verify & change
            </DialogActionButton>{" "}
          </Stack>{" "}
        </Stack>
      </Stack>
    </ElevatorDialog>
  );
}
