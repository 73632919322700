import {
  SimpleTableCard,
  SimpleTableColumn,
  SimpleTableColumnType,
  TableType,
} from "../../../../components/SimpleTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DeleteProjectParams,
  FilterOption,
  LastEvaluatedKey,
  ListProjectsResponse,
  Priority,
  PhotographyProject,
  ProjectStage,
  RetrieveOptions,
  SearchOption,
  SortOption,
  WorkflowType,
} from "../../../../api/types";
import {
  useDeleteProjectMutation,
  useListProjectsQuery,
  useSetProjectPriorityMutation,
  useSetProjectStageMutation,
} from "../../../../api/projects";
import { ChipSelect } from "../../../../components/ChipSelect";
import {
  ALL_PROJECT_STAGE_OPTIONS,
  FILTER_PROJECTS_OPTIONS,
  SORT_PROJECTS_OPTIONS,
} from "../../../../components/ChipSelect/commonOptions";
import { useNavigate } from "react-router-dom";
import ConfirmActionDialog from "../../../../components/ConfirmActionDialog";
import ThreeDotsButton from "../../../../components/ThreeDotsButton";
import EditProjectDialog from "../../../../dialogs/EditProject";
import { atom, useAtom, useAtomValue } from "jotai";
import PriorityChip from "../../../../components/PriorityChip";
import { Typography, Stack, Box } from "@mui/material";
import { PATHS, WorkflowPath, makePath } from "../../../../paths";
import { currentWorkflowAtom } from "../../../../components/MainAppBar/UserControlPopup";
import { DateTime } from "luxon";

const selectedProjectAtom = atom<PhotographyProject | null>(null);
const openedEditAtom = atom(false);
const openedDeleteAtom = atom(false);

const BASE_COLUMNS: SimpleTableColumn[] = [
  {
    key: "name",
    label: "Project's name",
    type: SimpleTableColumnType.NAME,
  },
  {
    key: "client_name",
    label: "Client",
  },
  {
    key: "date",
    label: "Project's date",
    type: SimpleTableColumnType.DATE_NO_TIME,
  },
  {
    key: "end_date",
    label: "End date",
    type: SimpleTableColumnType.DATE_NO_TIME,
  },
  {
    key: "stage",
    label: "Status",
    /* replaced later */
  },
  {
    key: "priority",
    label: "Priority",
    type: SimpleTableColumnType.PRIORITY,
  },
  // {
  //   key: "view_project",
  //   label: "View",
  //   type: SimpleTableColumnType.LINK,
  //   hideHeader: true,
  // },
  // {
  //   key: "",
  //   label: "",
  //   render(_: unknown, row: any) {
  //     return <ThreeDotsProject project={row} />;
  //   },
  // },
];

enum threeDotsOptions {
  EDIT = "edit-project",
  DELETE = "delete-project",
}

const DOTS_MENU = [
  {
    id: threeDotsOptions.EDIT,
    label: "Edit",
  },
  {
    id: threeDotsOptions.DELETE,
    label: "Delete",
  },
];

interface ThreeDotsProjectProps {
  project: PhotographyProject;
}

/**
 * Renders a button with three dots. When the button is clicked, it opens a menu with options to edit or delete a task.
 * Also renders two dialog components, `ConfirmDeleteDialog` and `EditProjectDialog`, which are opened when the corresponding menu options are selected.
 *
 * @returns The rendered `ThreeDotsTask` component with a button and two dialog components.
 */
function ThreeDotsProject(props: ThreeDotsProjectProps) {
  const [selectedProject, setSelectedProject] = useAtom(selectedProjectAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);

  /**
   * Handles the click event on the menu options.
   * Sets the corresponding state variable to `true` to open the corresponding dialog component.
   *
   * @param id - The ID of the clicked menu option.
   */
  const handleClickItem = (id: string) => {
    setSelectedProject(props.project);

    switch (id) {
      case threeDotsOptions.EDIT:
        setOpenedEdit(true);
        break;
      case threeDotsOptions.DELETE:
        setOpenedDelete(true);
        break;
    }
  };

  return (
    <ThreeDotsButton
      menu={DOTS_MENU}
      onClickItem={handleClickItem}
      children={undefined}
    ></ThreeDotsButton>
  );
}

export interface ProjectsTableCardProps {
  stage?: ProjectStage;
  setStage?: (newStage: ProjectStage) => void;
  tableType: TableType;
}

const PAGE_SIZE = 10;

export function ProjectsTableCard(props: ProjectsTableCardProps) {
  const [selectedProject, setSelectedProject] = useAtom(selectedProjectAtom);
  const [openedEdit, setOpenedEdit] = useAtom(openedEditAtom);
  const [openedDelete, setOpenedDelete] = useAtom(openedDeleteAtom);

  const [search, setSearch] = useState<SearchOption>({ query: "" });
  const [filter, setFilter] = useState<FilterOption>({ by: "", query: "" });
  const [sort, setSort] = useState<SortOption>({ by: "", descending: false });

  const [selectedPage, setSelectedPage] = useState(0);
  const [current, setCurrent] = useState<null | ListProjectsResponse>(null);

  const defaultColumns = BASE_COLUMNS.map((c) => c.key);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(defaultColumns);

  const { data, isPending } = useListProjectsQuery({
    page_size: PAGE_SIZE,
    page_index: selectedPage,
    retrieve_options: {
      search: search,
      filter: filter,
      sort: sort, 
    },
    stage: props.stage,
  });

  useEffect(() => {
    setSelectedPage(0);
  }, [props.stage, search, filter, sort]);

  useEffect(() => {
    setSearch({ query: "" });
    setFilter({ by: "", query: "" });
    setSort({ by: "", descending: false });
  }, [props.stage]);

  useEffect(() => {
    if (!isPending && data) {
      console.log(data)
      setCurrent(data);
    }
  }, [data, isPending]);

  const navigate = useNavigate();
  const handleViewProject = (row: { uuid: string }) => {
    navigate(
      makePath(
        `/${workflowType}` as WorkflowPath,
        PATHS.viewProject(row.uuid).general
      )
    );
  };

  const additionalColumnsFromData = useMemo(() => {
    if (!data || !data.projects || data.projects.length === 0) return [];
    const dynamicKeys = new Set<string>();
  
    // Iterate over the clients data and extract all unique keys
    data.projects.forEach((project: any) => {
      Object.keys(project).forEach((key) => {
        // Check if the value is a string and not email or phone
        const value = project[key];
        if (
          (typeof value === "string" || typeof value === "number") &&
          !key.includes("uuid")
        ) {
          dynamicKeys.add(key);
        }
      });
    });
  
    // Remove keys that are already in baseColumns to avoid duplicates
    return [...dynamicKeys].filter((key) => !defaultColumns.includes(key));
  }, [data, defaultColumns]);

  const setProjectStage = useSetProjectStageMutation();
  const setProjectPriority = useSetProjectPriorityMutation();
  const workflowType = useAtomValue(currentWorkflowAtom)?.type;

  const allColumns = useMemo(() => {
    const result = [...BASE_COLUMNS];

    if (result.some((col) => col.key === "stage")) {
      result.forEach((col) => {
        if (col.key === "stage") {
          col.render = (value, row) => {
            const handleChange = (value: string) => {
              setProjectStage.mutate({
                uuid: row.uuid!,
                stage: value as ProjectStage,
              });
            };

            return (
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ChipSelect
                  options={ALL_PROJECT_STAGE_OPTIONS}
                  value={value}
                  onChange={handleChange}
                  sx={{
                    bgcolor: "white",
                    boxShadow: "0px 0.0714rem 0.3571rem rgba(117, 179, 255, 0.35)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "8rem",
                    height: "2.5rem",
                    textAlign: "center",
                  }}
                />
              </Box>
            );
          };
        }
      });
    }

    if (result.some((col) => col.key === "priority")) {
      result.forEach((col) => {
        if (col.key === "priority") {
          col.render = (value, row) => {
            const handleChangePriority = (value: string) => {
              setProjectPriority.mutate({
                uuid: row.uuid!,
                priority: value as Priority,
              });
            };

            return (
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <PriorityChip
                  value={row.priority}
                  onChange={handleChangePriority}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "7.7rem",
                    height: "2.5rem",
                    textAlign: "center",
                  }}
                />
              </Box>
            );
          };
        }
      });
    }

    if (result.some((col) => col.key === "client_name")) {
      result.forEach((col) => {
        if (col.key === "client_name") {
          col.render = (value, row) => {
            const handleViewContact = (uuid: string) => {
              navigate(
                makePath(
                  `/${workflowType}` as WorkflowPath,
                  PATHS.viewContact(uuid).general
                )
              );
            };

            return (
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: "#75B3FF", cursor: "pointer" }}
                  onClick={() => handleViewContact(row.client_uuid)}
                >
                  {value ?? ""}
                </Typography>
              </Box>
            );
          };
        }
      });
    }

    const extraColumns = [
      {
        key: "info",
        label: "",
        render(_: unknown, row: any) {
          return (
            <Box
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <ThreeDotsProject project={row} />
            </Box>
          );
        },
      },
    ];

    const dynamicColumns = additionalColumnsFromData.map((key) => ({
      key,
      label: (key.charAt(0).toUpperCase() + key.slice(1)).replace("_", " "), // Format label
      render: (value: any) => {
        // Check if the value is a date in ISO format
        const isDate = typeof value === "string" && !isNaN(Date.parse(value));
        
        // If it's a date, format it
        if (isDate) {
          const date = DateTime.fromISO(value); // Use Luxon to parse the ISO string
          return (
            <Stack direction="row">
              <Typography fontSize={"1.15rem"} color="#404040" fontWeight="bold">
                {date.toFormat("EEEE")}, {/* e.g. Wednesday */}
              </Typography>
              <Typography fontSize={"1.15rem"} color="#404040B2">
                &nbsp;{date.toFormat("LLL dd, yyyy")} {/* e.g. Oct 16, 2024 */}
              </Typography>
            </Stack>
          );
        }
    
        // Render the value or "N/A" if it's not a date or is empty
        return <Typography>{value === 0 ? 0 : value || "N/A"}</Typography>;
      },
    }));

    return [...result, ...dynamicColumns, ...extraColumns];
  }, []);

  const columns = useMemo(() => {
    const essentialColumns = ["info"];
    const visibleColumns = selectedColumns.length === 0 ? allColumns : allColumns.filter((col) => selectedColumns.includes(col.key));
    return [...new Set([...visibleColumns, ...allColumns.filter(col => essentialColumns.includes(col.key))])];
  }, [selectedColumns, allColumns]);

  const mainColumns = useMemo(() => {
    return columns.filter(col => !["info"].includes(col.key));
  }, [columns]);

  const columnWidth = useMemo(() => {
    const totalMainColumns = mainColumns.length;
    return `${100 / totalMainColumns}%`;
  }, [mainColumns]);

  const allColumnsWithoutInfo = allColumns.filter((col) => col.key !== "info");

  const handleClickLink = useCallback((row: PhotographyProject) => {
    navigate(makePath(WorkflowPath.PHOTOGRAPHY, PATHS.viewProject(row.uuid).general));
  }, []);

  const deleteProject = useDeleteProjectMutation();

  const handleDeleteProject = async (uuid: string) => {
    const params = {
      uuid: uuid,
    } as DeleteProjectParams;

    await deleteProject.mutateAsync(params);
    setOpenedDelete(false);
  };

  return (
    <>
      <ConfirmActionDialog
        open={openedDelete}
        what="project"
        whatAction="delete"
        onClose={() => setOpenedDelete(false)}
        onConfirm={() =>
          selectedProject
            ? handleDeleteProject(selectedProject.uuid)
            : undefined
        }
        loading={deleteProject.isPending}
      >
        Delete
      </ConfirmActionDialog>

      <EditProjectDialog
        open={openedEdit}
        onClose={() => setOpenedEdit(false)}
        project={selectedProject}
      />

      <SimpleTableCard
        title={props.tableType === TableType.COMPLETED_PROJECTS ? "Completed projects" : "Projects info"}
        data={current?.projects}
        columns={columns}
        tableType={props.tableType}
        stage={props.stage}
        setStage={props.setStage}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        pageSize={PAGE_SIZE}
        numOfPages={current?.num_of_pages ?? 1}
        total={current?.total ?? 0}
        isPending={isPending}
        onClickLink={handleClickLink}
        searchState={[search, setSearch]}
        filterState={[filter, setFilter]}
        sortState={[sort, setSort]}
        filterOptions={FILTER_PROJECTS_OPTIONS}
        sortOptions={SORT_PROJECTS_OPTIONS}
        columnDivision={Array(columns.length).fill(columnWidth)}
        allColumnsWithoutInfo={allColumnsWithoutInfo}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
      />
    </>
  );
}
