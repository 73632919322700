import { useMemo, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { ListingStage, ProjectStage, StageStats } from "../../api/types";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import { useGetClientStatsQuery } from "../../api/clients";

export const PROJECTS_STAGES_LABELS = [
  {
    stage: "contacts_amount",
    label: "Contacts",
    gradients: ["#6ab4ff", "#9dd8fd"],
  },
  {
    stage: ProjectStage.LEAD,
    label: "Leads",
    gradients: ["#c2a6ff", "#6ab4ff"],
  },
  {
    stage: ProjectStage.PROPOSAL,
    label: "Proposals",
    gradients: ["#fc5d77", "#fe8875"],
  },
  {
    stage: ProjectStage.UNDER_CONTRACT,

    label: "Under contract",
    gradients: ["#f59b81", "#f9b41b"],
  },
  {
    stage: ProjectStage._CLOSED_WON,
    label: "Closed / Won",
    gradients: ["#95d478", "#b6f09a"],
  },
];

export const LISTINGS_STAGES_LABELS = [
  {
    stage: ListingStage.INTEREST,
    label: "Inquiry / Interest",
    gradients: ["#6ab4ff", "#9dd8fd"],
  },
  {
    stage: ListingStage.PROPERTY_VIEWING,
    label: "Propery viewing",
    gradients: ["#c2a6ff", "#6ab4ff"],
  },
  {
    stage: ListingStage.SERIOUS_CONSIDERATION,
    label: "Serious consideration",
    gradients: ["#fc5d77", "#fe8875"],
  },
  {
    stage: ListingStage.NEGOTIATION,
    label: "Negotiation",
    gradients: ["#E14C64", "#F47023"],
  },
  {
    stage: ListingStage.UNDER_CONTRACT,

    label: "Under contract",
    gradients: ["#f59b81", "#f9b41b"],
  },
  {
    stage: ListingStage.SOLD,
    label: "Closed / Sold",
    gradients: ["#95d478", "#b6f09a"],
  },
];

interface StageLabel {
  stage: ListingStage | ProjectStage | string;
  label: string;
  gradients: string[];
}

export interface ClientPiplineProps {
  stats: StageStats;
  chartMode: ChartMode;
  stagesLabels: StageLabel[];
}

interface PipelineChartProps {
  stats: StageStats;
  stagesLabels: StageLabel[];
  contacts_amount?: number;
}

const SLOPE = 19;
const START_WIDTH = 290;

function PipelineStackChart(props: PipelineChartProps) {
  // Process the statisticks to prepare assigning them to the stack chart:
  const values = useMemo(() => {
    const result: { [stage: string]: number } = {};
    props.stats.stages.forEach((stage, i) => {
      result[stage] = props.stats.values[i];
    });

    return result;
  }, [props.stats]);

  return (
    <Stack spacing={0.7}>
      {props.stagesLabels.map((stage, i) => (
        <Box
          key={i}
          sx={{
            height: "2.4rem",
            position: "relative",
            bgcolor: "white",
            border: "solid 0.0714rem #ebebeb",
            borderRadius: "0.4286rem",
            py: 0.5,
            px: 2,
            clipPath: `polygon(${(SLOPE * i) / 14}rem 0, ${
              (SLOPE * (i + 1)) / 14
            }rem 100%,  100% 100%, 100% 0)`,
          }}
        >
          {/* The colored boxes (the pyramid itself) */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              alignItems: "center",
              display: "flex",
              left: 0,
              width: `${START_WIDTH / 14}rem`,
              background: `linear-gradient(to right, ${stage.gradients[0]}, ${stage.gradients[1]})`,
              clipPath: `polygon(0 0, 0 100%, calc(100% - ${
                (SLOPE * (i + 1)) / 14
              }rem) 100%, calc(100% - ${(SLOPE * i) / 14}rem) 0)`,
            }}
          >
            {/* The numbers inside every pyrmaid's "floor" */}
            <Typography
              sx={{ width: "100%", color: "white" }}
              textAlign="center"
              fontFamily="Helvetica Neue"
              fontSize="1.4rem"
            >
              {stage.stage === "contacts_amount"
                ? props.contacts_amount ?? 0
                : values[stage.stage] ?? 0}
            </Typography>
          </Box>

          {/* The legend */}
          <Stack
            direction="row"
            sx={{ ml: `${START_WIDTH / 14 + 4.5}rem`, mt: 0.4, mr: 2 }}
            alignItems="center"
            spacing={1}
          >
            {/* Gradient colored circle */}
            <Box
              sx={{
                background: `linear-gradient(to right, ${stage.gradients[0]}, ${stage.gradients[1]})`,
                width: "1.5rem",
                height: "1.5rem",
                borderRadius: "50%",
              }}
            />

            {/* Name of the stage */}
            <Typography
              variant="body2"
              fontFamily="Helvetica Neue"
              fontWeight="bold"
            >
              {stage.label}
            </Typography>
          </Stack>
        </Box>
      ))}
    </Stack>
  );
}

function PipelineRingChart(props: PipelineChartProps) {
  // Process the statisticks to prepare assigning them to the ring chart:
  const data = useMemo(() => {
    let result: Array<{
      stage: string;
      value: number;
      label: string;
      fill1: string;
      fill2: string;
    }> = [];

    const stages = props.stats.stages;
    const values = props.stats.values;

    if (stages.length == values.length) {
      for (let i = 0; i < stages.length; i++) {
        // Merge the two maps into one:
        const { label, gradients } =
          props.stagesLabels.find(
            (stageInfo) => stageInfo.stage === stages[i]
          ) || {};

        if (label && gradients) {
          result.push({
            stage: stages[i],
            value: values[i],
            label: label,
            fill1: gradients[0],
            fill2: gradients[1],
          });
        }
      }
    }

    return result;
  }, [props.stats]);

  return (
    <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
      {/* Graph area */}
      <Grid item xs={6} sx={{ height: "17rem" }}>
        <ResponsiveContainer width="100%" height={"100%"}>
          <PieChart>
            <defs>
              {" "}
              {/* defs is imported to make a gradient fill */}
              {data.map((row, i) => (
                <linearGradient key={`gradient-${i}`} id={`gradient-${i + 1}`}>
                  <stop offset="0%" stopColor={row.fill1} />
                  <stop offset="100%" stopColor={row.fill2} />
                </linearGradient>
              ))}
            </defs>
            <Pie
              data={data}
              dataKey="value"
              nameKey="label"
              cx="50%"
              cy="50%"
              outerRadius="70%"
              innerRadius="60%"
              stroke="none"
            >
              {/* "CRM" label inside the ring */}
              <Label
                value="CRM"
                position="center"
                fill="#000"
                fontSize={"1.4286rem"}
                fontFamily="Helvetica Neue"
                fontWeight="bold"
              />

              {/* Render the deviders (each category in the ring itself) */}
              {data.map((row, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={`url(#gradient-${index + 1})`}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Grid>

      {/* Legend area */}
      <Grid item xs={6}>
        <Stack spacing={0.7}>
          {data.map((row, i) => (
            <Stack
              key={i}
              direction="row"
              sx={{
                bgcolor: "white",
                border: "solid 1px #ebebeb",
                borderRadius: "0.6rem",
                py: 0.5,
                px: 2.2,
                pr: 3.2,
                height: "2.4rem",
                width: "14.7rem",
              }}
              spacing={1}
              alignItems="center"
            >
              {/* Gradient colored circle */}
              <Box
                sx={{
                  background: `linear-gradient(to right, ${row.fill1}, ${row.fill2})`,
                  width: "1.5rem",
                  height: "1.5rem",
                  borderRadius: "50%",
                }}
              />

              {/* Label */}
              <Typography variant="body2" fontWeight="bold" sx={{ pl: 0.7 }}>
                {row.label}
              </Typography>

              <Box sx={{ flex: 1 }} />

              {/* Value */}
              <Typography
                variant="body2"
                fontSize="1.4rem"
                fontFamily="Helvetica Neue"
              >
                {row.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}

export enum ChartMode {
  STACK = "stack",
  RING = "ring",
}

export default function ClientPipeline(props: ClientPiplineProps) {
  const {data} = useGetClientStatsQuery();

  return (
    <>
    
      {props.chartMode === ChartMode.RING && (
        <PipelineRingChart
          stats={props.stats}
          stagesLabels={props.stagesLabels}
          contacts_amount={data?.stats.existing_clients}
        />
      )}

      {props.chartMode === ChartMode.STACK && (
        <PipelineStackChart
          stats={props.stats}
          stagesLabels={props.stagesLabels}
          contacts_amount={data?.stats.existing_clients}
        />
      )}
    </>
  );
}
