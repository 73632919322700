import {MynkPage, MynkTabInfo} from "../../../components/MynkPage";
import { PATHS, WorkflowPath, makePath } from "../../../paths";


const TABS: MynkTabInfo[] = [
  {
    title: 'All',
    path: '/all',
  },
  {
    title: 'Archived',
    path: '/archived',
  },
];

export default function ListingsPage() {
  return (
    <MynkPage
      title="Listings"
      basePath={makePath(WorkflowPath.REAL_ESTATE, PATHS.listings.index)}
      tabs={TABS}
    />
  );
}
