import React, { useEffect, useState } from 'react'
import { useRefreshGoogleTokenMutation, useLoginWithGoogleMutation } from '../../../../api/integrations'
import { RefreshGoogleTokenParams, LoginWithGoogleParams } from '../../../../api/types'
import { AxiosError } from 'axios'
import { MynkTab } from '../../../../components/MynkPage'
import { CircularProgress, Stack, Typography, Button, Card, Box } from '@mui/material'
import GoogleCalendarImage from '../../../../assets/images/google-calendar-image.png'

export default function SettingsIntegrationsSubpage() {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [isGoogleConnected, setIsGoogleConnected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const refreshGoogleToken = useRefreshGoogleTokenMutation({
    async onSuccess(data) {
      setIsGoogleConnected(true);
      setIsLoading(false);
    },
    onError: (error) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, RefreshGoogleTokenParams>;
        const detail = (axiosError.response?.data as { detail?: string })?.detail;
        setErrorMsg(detail ?? "");
      }
      setIsGoogleConnected(false);
      setIsLoading(false);
    },
  });

  const loginWithGoogle = useLoginWithGoogleMutation({
    async onSuccess(data) {
      console.log("login with google success", data.url);
      window.location.href = data.url;
    },
    onError: (error) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, LoginWithGoogleParams>;
        const detail = (axiosError.response?.data as { detail?: string })?.detail;
        setErrorMsg(detail ?? "");
      }
    },
  });

  const handleConnectGoogle = () => {
    setIsLoading(true);
    loginWithGoogle.mutate({});
  };

  useEffect(() => {
    setIsLoading(true);
    refreshGoogleToken.mutate({});
  }, []);
  
  return (
    <MynkTab title="Integrations">
      <Card sx={{ width: "20rem", height: "10rem" }}>
        <Stack spacing={2} sx={{ width: "100%", height: "100%" }}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <img
              src={GoogleCalendarImage}
              alt="Google Calendar"
              style={{
                width: "100%",
                objectFit: "contain", // Adjust to fit the container properly
              }}
            />
          </Box>
          <Box sx={{ textAlign: "center", mt: "-1rem !important", mb: "1rem !important" }}>
            {!isLoading && (
              isGoogleConnected ? (
                <Typography fontWeight={600}>Connected</Typography>
              ) : (
                <Typography fontWeight={600} onClick={handleConnectGoogle} color={"#75B3FF"} sx={{cursor: "pointer"}}>Connect</Typography>
              )
            )}
          </Box>
        </Stack>
      </Card>
    </MynkTab>
  );  
}
