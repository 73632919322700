import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import EmptyEllipseIcon from '../../../assets/icons/empty-elipse-icon.svg';
import FilledEllipseIcon from '../../../assets/icons/filled-elipse-icon.svg';
import ArrowRight from '../../../assets/icons/arrow-right-show-more.svg';
import ArrowDown from '../../../assets/icons/arrow-down-show-more-opened.svg';
import LineIcon from '../../../assets/icons/line-icon.svg';
import produce from "immer";
import { useReadAtom } from '../../../util/common';
import { useSetAtom } from 'jotai';
import { useTemplateAtoms } from './TemplateContext';

const wordToNumberMap: { [key: string]: string } = {
    "one": "1",
    "two": "2",
    "three": "3",
    "four": "4",
    "five": "5",
    "six": "6",
    "seven": "7",
    "eight": "8",
    "nine": "9",
    "ten": "10",
    "eleven": "11",
    "twelve": "12",
    "thirteen": "13",
    "fourteen": "14",
    "fifteen": "15",
    "sixteen": "16",
    "seventeen": "17",
    "eighteen": "18",
    "nineteen": "19",
    "twenty": "20"
};

function EditClauses() {
    const [titles, setTitles] = useState<string[]>([]);
    const [underTitles, setUnderTitles] = useState<string[]>([]);
    const [iconToUse, setIconToUse] = useState<{ [key: string]: boolean }>({});

    const templateAtoms = useTemplateAtoms();
    const readRootValue = useReadAtom(templateAtoms.detailsAtom);
    const updateRootValue = useSetAtom(templateAtoms.detailsAtom);

    const getServicesCount = () => {
        let count = 0;
        const serviceElements = document.querySelectorAll('.service');

        serviceElements.forEach(element => {
            const h3 = element.querySelector('h3');
            if (h3) {
                count++;
            }
        });

        return count;
    };
    
    function getKeyByValue(value: string) {
        return Object.keys(wordToNumberMap).find(key => wordToNumberMap[key] === value);
    }

    const toTitleCase = (str: string) => {
        return str.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

    const [openServices, setOpenServices] = useState<boolean[]>(Array.from({ length: getServicesCount() }, () => false));
    
    useEffect(() => {
        const serviceElements = document.querySelectorAll('.service');
        const underServiceElements = document.querySelectorAll('.under-service');
        const tempIconToUse: { [key: string]: boolean } = {};

        const extractedTitles: string[] = [];
        serviceElements.forEach((element) => {
            const h3 = element.querySelector('h3');
            if (h3) {
                tempIconToUse[element.classList[0]] = true;
                extractedTitles.push(h3.textContent || '');
            }
        });

        const extractedUnderTitles: string[] = [];
        underServiceElements.forEach((element) => {
            const className = element.className.split(' ')[0];
            if (className) {
                tempIconToUse[className] = true;
                const parts = className.split('-');
                const formattedTitle = parts.map(part => wordToNumberMap[part]).join('.');
                extractedUnderTitles.push(formattedTitle);
            }
        });

        const getCheckedIcon = async () => {
            const rootValue = await readRootValue();
            if (rootValue.hasOwnProperty('services')) {
                rootValue.services.forEach((service: string) => {
                    tempIconToUse[service] = false;
                });
            }
        };
        getCheckedIcon();

        setIconToUse(tempIconToUse);
        setTitles(extractedTitles);
        setUnderTitles(extractedUnderTitles);
    }, []);

    const handleCheckboxChange = async (className: string, isService: boolean) => {
        const elements = document.querySelectorAll(isService ? `.service.${className}` : `.under-service.${className}`);
        const rootValue = await readRootValue();

        if (elements.length > 0) {
            updateRootValue(
                produce(rootValue, (draft: any) => {
                    if (!draft.hasOwnProperty('services')) {
                        draft.services = [];
                    }
                    
                    const index = draft.services.indexOf(className);
                    if (index !== -1) {
                        if (draft.services.length === 1) {
                            draft.services = [];
                        } else {
                            draft.services.splice(index, 1);
                        }
                    } else {
                        draft.services.push(className);
                    }
                })
            );
        }
    };

    return (
        <Stack>
            <Typography variant="h2" fontFamily="Helvetica Neue" mb={3}>
                Edit clauses
            </Typography>

            {titles.map((title, index) => {
                const className = getKeyByValue((index + 1).toString());
                return (
                    <Stack>
                        <Stack key={index} direction='row' mb={1} justifyContent={'space-between'}>
                            <Box
                                component='img'
                                src={iconToUse[className ?? ''] ? FilledEllipseIcon : EmptyEllipseIcon}
                                sx={{cursor: 'pointer'}}
                                onClick={() => {
                                    handleCheckboxChange(className ?? '', true);
                                    iconToUse[className ?? ''] = !iconToUse[className ?? ''];
                                    setIconToUse({...iconToUse});
                                }}
                                mr={1}
                                width={'1.3rem'}
                            />

                            <Typography flexGrow={1}>{index + 1}. {toTitleCase(title.split('.')[1])}</Typography>

                            <Box
                                component='img'
                                src={openServices[index] ? ArrowDown : ArrowRight}
                                sx={{cursor: 'pointer'}}
                                onClick={() => {
                                    const newOpenServices = openServices.map((item, idx) => idx === index ? !item : false);
                                    setOpenServices(newOpenServices);
                                }}
                                mr={-2}
                            />
                        </Stack>

                        {index < titles.length - 1 && !openServices[index] && (
                            <Box
                                component='img'
                                src={LineIcon}
                                width={'0.15rem'}
                                height={'1.5rem'}
                                ml={1}
                                mt={-1}
                            />
                        )}

                        {openServices[index] && (
                            <Stack>
                                {underTitles
                                    .filter(underTitle => underTitle.split('.')[0] === (index + 1).toString())
                                    .map((underTitle, underIndex) => {
                                        const className = getKeyByValue((index + 1).toString()) + '-' + getKeyByValue((underIndex + 1).toString());

                                        return (
                                            <Stack mb={underIndex === underTitles.filter(underTitle => underTitle.split('.')[0] === (index + 1).toString()).length - 1 ? 2 : 0}>
                                                <Stack key={underIndex} direction='row' justifyContent={'space-between'} ml={6}>
                                                    <Box
                                                        component='img'
                                                        src={iconToUse[className] ? FilledEllipseIcon : EmptyEllipseIcon}
                                                        sx={{cursor: 'pointer'}}
                                                        onClick={() => {
                                                            handleCheckboxChange(className ?? '', false);
                                                            iconToUse[className ?? ''] = !iconToUse[className ?? ''];
                                                            setIconToUse({...iconToUse});
                                                        }}
                                                        mr={1}
                                                        ml={-3}
                                                        width={'1.3rem'}
                                                    />

                                                    <Typography mr={2} flexGrow={1}>{toTitleCase(underTitle)}</Typography>
                                                </Stack>

                                                {underIndex < underTitles.filter(underTitle => underTitle.split('.')[0] === (index + 1).toString()).length - 1 && (
                                                    <Box
                                                        component='img'
                                                        src={LineIcon}
                                                        width={'0.15rem'}
                                                        height={'1rem'}
                                                        ml={4}
                                                    />
                                                )}

                                            </Stack>
                                        );
                                    })
                                }
                            </Stack>
                        )}
                    </Stack>
                );
            })}
        </Stack>
    );
}

export default EditClauses;