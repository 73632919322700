import {
  SetNewFileNameParams,
  SetNewFileNameResponse,
  GetFileParams,
  GetFileResponse,
  SendPdfParams,
  SendPdfResponse,
  ListFilesParams
} from "./types";
import {
  useMutation,
  useQueryClient,
  UseMutationOptions,
  useQuery
} from "@tanstack/react-query";
import axios from "axios";
  
export function useSetNewFileNameMutation(
  options: UseMutationOptions<SetNewFileNameResponse, unknown, SetNewFileNameParams>
) {
  const queryClient = useQueryClient();
  return useMutation<SetNewFileNameResponse, unknown, SetNewFileNameParams>({
    mutationFn: async (params) =>
      (await axios.post("/api/files/set-new-name", params)).data,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ["project-files-list"] });
      await queryClient.invalidateQueries({ queryKey: ["client-files-list"] });
    },
    ...options,
  });
}
 
export function useGetFileQuery(params: GetFileParams) {
  return useQuery<GetFileResponse>({
    queryKey: ["file", params.token],
    queryFn: async () => {
      const response = await axios.get(`/api/files/get-file`, { params, responseType: 'arraybuffer' });
      return {
        binfile: response.data,
        mime_type: response.headers['content-type'],
      };
    },
    enabled: true,
  });
}

export function useSendPdfMutation() {
  return useMutation<SendPdfResponse, unknown, SendPdfParams>({
    mutationFn: async (params) => {
      const formData = new FormData();
      formData.append('file', params.pdf_data);
      formData.append('token', params.token);
      formData.append('is_client', params.is_client);

      return (await axios.post("/api/files/send_pdf", formData)).data;
    },
  });
}

export function useListFilesQuery(params: ListFilesParams) {
  return useQuery({
    queryKey: ["list-files", params.folder],
    queryFn: async () => {
      const response = await axios.get("/api/files/list-files", {
        params: params,
        responseType: "blob",
      });
      return response.data;
    },
  });
}
  
