import { Grid, Typography, Stack, Divider, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useAddPhotographyClientMutation } from "../api/clients";
import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import {
  AddEventParams,
  ClientSource,
  EventRepetitiveness,
  Priority,
  WorkflowType,
} from "../api/types";
import {
  CALENDAR_TAG_OPTIONS,
  CONTACT_SOURCE_OPTIONS,
  PRIORITY_OPTIONS,
  REPEAT_EVERY_WHAT_PERIOD_OPTIONS,
  REPEAT_INTERVAL_OPTIONS,
  REPEAT_UNTIL_OPTIONS,
  REMIND_ME_OPTIONS
} from "../components/ChipSelect/commonOptions";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import { useNavigate } from "react-router-dom";
import { PATHS, WorkflowPath, makePath } from "../paths";
import { currentWorkflowAtom } from "../components/MainAppBar/UserControlPopup";
import { useAtomValue } from "jotai";
import { DateTime } from "luxon";
import MynkFormEditable from "../components/MynkForm/MynkFormEditable";
import Checkbox from "@mui/material/Checkbox";
import MynkFormConditional, {
  FormValues,
} from "../components/MynkForm/MynkFormConditional";
import { useAddEventMutation } from "../api/scheduler";
import { useFormikContext } from "formik";
import { zonedDate } from "../util/common";
import {AxiosError} from "axios";
import { format } from 'date-fns';
import { useAddTaskMutation } from "../api/tasks";

interface DateControllerProps {
  setStartDateTime?: (newDatetime: DateTime) => void;
  setEndDateTimeOnlyTime?: (newVal: boolean) => void;
}

function DateController(props: DateControllerProps) {
  const formik = useFormikContext<FormValues>();
  const values = formik?.values;

  useEffect(() => {
    if (formik && values && values?.["startDate"] && values?.["startTime"]) {
      const zStartDate = zonedDate(values?.["startDate"]);
      const zStartTime = zonedDate(values?.["startTime"]);
      let zEndDateTime = values?.["endTime"]
        ? zonedDate(values?.["endTime"])
        : null;

      // If endDateTime is undefined: set it to the same date as startDate but in startTime time.
      if (!zEndDateTime) {
        zEndDateTime = DateTime.fromObject({
          year: zStartDate.year,
          month: zStartDate.month,
          day: zStartDate.day,
          hour: zStartTime.hour + 1,
          minute: zStartTime.minute,
          second: zStartTime.second,
        });
        formik.setFieldValue("endTime", zEndDateTime);
      }
    }
  }, [values?.["startTime"]]);

  return <></>;
}

export interface AddEventDialogProps {
  open: boolean;
  onClose: () => void;
  defaultStartDate?: DateTime;
  defaultEndDate?: DateTime;
  defaultAllDay?: boolean;
}

export function AddEventDialog(props: AddEventDialogProps) {
  const [isAllDay, setIsAllDay] = useState(props.defaultAllDay);
  const [enableRepetitiveness, setEnableRepetitiveness] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (props.open) {
      setEnableRepetitiveness(false);
    }
  }, [props.open]);

  useEffect(() => {
    setIsAllDay(props.defaultAllDay);
  }, [props.defaultAllDay]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllDay(event.target.checked);
  };

  const handleRepeatEveryCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnableRepetitiveness(event.target.checked);
  };

  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const addEvent = useAddEventMutation({
      onError: (error: unknown) => {
        if (error instanceof Error && "response" in error) {
          const axiosError = error as AxiosError<unknown, AddEventParams>;
          const detail = (axiosError.response?.data as { detail?: string })
            ?.detail;
          setErrorMsg(detail ?? "");
        }
  }
  });

  const addReminder = useAddTaskMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, AddEventParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        setErrorMsg(detail ?? "");
      }
    }
  });

  const handleSubmit = async (values: any) => {
    let repetitiveness, startDate, endDate;
    try {
      repetitiveness = {
        every_what_period: values["everyWhatPeriod"],
        interval: Number(values["repeatInterval"]),
        until:
          values["until"] === "custom_date"
            ? values["untilCustomDate"]
            : Number(values["until"]),
      };
  
      const zStartDate = zonedDate(values["startDate"]);
      const zStartTime = values?.["startTime"] ? zonedDate(values["startTime"]) : null;
      const zEndTime = values?.["endTime"] ? zonedDate(values["endTime"]) : null;
  
      // Combine date and time for startDate and endDate
      if (zEndTime) {
        endDate = DateTime.fromObject({
          year: zStartDate.year,
          month: zStartDate.month,
          day: zStartDate.day,
          hour: zEndTime.hour,
          minute: zEndTime.minute,
          second: zEndTime.second,
        });
      } else {
        endDate = undefined;
      }
  
      startDate = DateTime.fromObject({
        year: zStartDate.year,
        month: zStartDate.month,
        day: zStartDate.day,
        hour: zStartTime ? zStartTime.hour : 0,
        minute: zStartTime ? zStartTime.minute : 0,
        second: zStartTime ? zStartTime.second : 0,
      });
    } catch (e) {
      setErrorMsg("Some fields are empty");
    }
  
    if (!startDate) {
      setErrorMsg("Some fields are empty");
    } else {
      setErrorMsg(undefined);
  
      await addEvent.mutateAsync({
        name: title,
        date: startDate,
        is_all_day: isAllDay,
        end_date: isAllDay ? undefined : endDate,
        client_uuid: values["clientId"] || undefined,
        location: values["location"],
        description: values["description"],
        tag: values["tag"],
        repetitiveness: enableRepetitiveness && repetitiveness ? repetitiveness : undefined,
      });
  
      if (values["reminder"] !== "none") {
        const reminderMinutes = parseInt(values["reminder"].split(' ')[0], 10);
        const dueDate = startDate.minus({ minutes: reminderMinutes });
  
        const dueDateTimestamp = dueDate.toMillis();

        await addReminder.mutateAsync({
          title: title,
          due_date: dueDateTimestamp,
          is_reminder: true,
        });
      }
  
      props.onClose();
    }
  };  

  let now = DateTime.now();
  if (now.minute !== 0 || now.second !== 0) {
    now = now.plus({ hours: 2 }).set({ minute: 0, second: 0 });
  }

  const [startDefaultDate, setStartDefaultDate] = useState<DateTime | null>(now)
  const [endDefaultDate, setEndDefaultDate] = useState<DateTime | null>(null)

  useEffect(() => {
    if (startDefaultDate === null) return;
  
    const newEnd = startDefaultDate.plus({ hours: 1 });
    if (endDefaultDate === null || newEnd.valueOf() !== endDefaultDate.valueOf()) {
      setEndDefaultDate(newEnd);
    }
  }, [startDefaultDate]);

  return (
    <MynkFormDialog
      open={props.open}
      onClose={() => {
        setIsAllDay(false);
        setTitle("");
        props.onClose();
      }}
      action="Add event"
      onSubmit={handleSubmit}
      loading={addEvent.isPending}
      errorMsg={errorMsg}
    >
      <DateController />
      <Stack
        direction="row"
        sx={{
          width: "60rem",
        }}
      >
        <Grid container spacing={"1rem"} alignItems="center">
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              variant="standard"
              placeholder="Type event's name here.."
              InputProps={{
                disableUnderline: true,
                style: { fontSize: "1.8rem", fontFamily: "Helvetica Neue Bold" },
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{ mb: "1.5rem" }}
            />
          </Grid>
          
          <Grid item xs={12}>
            <MynkFormEditable>
              <MynkFormControl
                kind={MynkFormControlKind.DATE_SELECT}
                name="startDate"
                label="Date"
                default={props.defaultStartDate ?? null}
              />
            </MynkFormEditable>
          </Grid>

          {!isAllDay && (
            <>
              <Grid item xs={6}>
                <MynkFormControl
                  kind={MynkFormControlKind.DATE_SELECT}
                  name="startTime"
                  label="Start"
                  default={startDefaultDate}
                  setDefault={setStartDefaultDate}
                  onlyTime
                />
              </Grid>
              <Grid item xs={6}>
                <MynkFormControl
                  kind={MynkFormControlKind.DATE_SELECT}
                  name="endTime"
                  label="End"
                  default={endDefaultDate}
                  setDefault={setEndDefaultDate}
                  onlyTime
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Divider />
          </Grid>{" "}

          <Grid item xs={12}>
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Checkbox
                onChange={handleCheckboxChange}
                checked={isAllDay}
              />
              <Typography
                fontSize="1.2rem"
                fontWeight="bold"
                sx={{ mt: "0.12rem" }}
              >
                All day
              </Typography>
            </Stack>
          </Grid>
          
          <Grid item xs={12}>
            <Stack sx={{ mt: "-1.5rem" }}>
              <Stack direction="row" alignItems="center">
                <Checkbox
                  onChange={handleRepeatEveryCheckboxChange}
                  checked={enableRepetitiveness}
                  sx={{ borderRadius: "0.7rem" }}
                />
                <Typography fontSize="1.2rem"
                fontWeight="bold">Repetitive event</Typography>
              </Stack>

            {!enableRepetitiveness && <Stack sx={{ height: "4rem"}}></Stack>}
              <Stack
                sx={{
                  display: enableRepetitiveness ? "block" : "none",
                  mx: "0.67rem"
                }}
                spacing="0.6rem"
              >
                <Stack direction="row">
                  <MynkFormControl
                    kind={MynkFormControlKind.CHIP_SELECT}
                    name="repeatInterval"
                    label="Repeat every"
                    default={"1"}
                    options={REPEAT_INTERVAL_OPTIONS}
                    width="4.4rem"
                  />
                  <MynkFormControl
                    kind={MynkFormControlKind.CHIP_SELECT}
                    name="everyWhatPeriod"
                    label=""
                    default={"day"}
                    options={REPEAT_EVERY_WHAT_PERIOD_OPTIONS}
                    width="7.1rem"
                  />
                </Stack>
                <MynkFormControl
                  kind={MynkFormControlKind.CHIP_SELECT}
                  name="until"
                  label="Until"
                  default={"1"}
                  options={REPEAT_UNTIL_OPTIONS}
                  width="10rem"
                />
                <MynkFormConditional
                  ifWhat={"until"}
                  equalTo={"custom_date"}
                >
                  <MynkFormEditable>
                    <MynkFormControl
                      kind={MynkFormControlKind.DATE_SELECT}
                      name="untilCustomDate"
                      label=""
                      default={DateTime.now()}
                    />
                  </MynkFormEditable>
                </MynkFormConditional>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Divider orientation="vertical" sx={{ ml: 5, mr: 5 }} flexItem />

        <Grid
          container
          spacing={2}
          alignItems="center"
          alignContent="start"
        >
          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.CONTACT_SEARCH}
              name="clientId"
              label="Link client"
              placeholder="Enter client"
              default=""
              workflowType={WorkflowType.PHOTOGRAPHY}
            />
          </Grid>

          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="location"
              label="Location"
              placeholder="Enter location"
              autoComplete={false}
              default=""
            />
          </Grid>

          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="description"
              label="Description"
              placeholder="Add description"
              autoComplete={false}
              default=""
              multiLine
              height={100}
            />
          </Grid>

          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.COLORED_CHIP_SELECT}
              name="tag"
              label="Tag"
              default={CALENDAR_TAG_OPTIONS[0].value}
              options={CALENDAR_TAG_OPTIONS}
            />
          </Grid>

          <Grid item xs={12}>
            <MynkFormControl
              kind={MynkFormControlKind.CHIP_SELECT}
              name="reminder"
              label="Remind me"
              default={REMIND_ME_OPTIONS[0].value}
              options={REMIND_ME_OPTIONS}
            />
          </Grid>
        </Grid>
      </Stack>
    </MynkFormDialog>
  );
}
