import axios, { all } from "axios";
import { useAtomValue } from "jotai";
import { scheduler } from "timers/promises";

export enum WorkflowPath {
  EMPTY = "/",
  PHOTOGRAPHY = "/photography",
  REAL_ESTATE = "/real_estate",
}

export const BASE_PATHS = {
  dashboard: "/dashboard",
  projects: "/dashboard/projects",
  clients: "/dashboard/clients",
  tasks: "/dashboard/tasks",
  portal: "/dashboard/portal",
  templates: "/dashboard/templates",
  instantiateTemplate: "/dashboard/instantiate-template",
  listings: "/dashboard/listings",
  settings: "/dashboard/settings",
  viewProject: "/dashboard/view-project",
  viewListing: "/dashboard/view-listing",
  viewContact: "/dashboard/view-contact",
  viewLead: "/dashboard/view-lead",
  ai: "/dashboard/ai",
  scheduler: "/dashboard/scheduler",
  portfolio: "/dashboard/portfolio",
  sentPortal: "/portal",
  expenses: "/dashboard/expenses",
  library: "/dashboard/library",
  guide: "/dashboard/guide",
  contract: "/dashboard/contract",
  release: "/dashboard/release",
  tool: "/dashboard/tool",
};

export const PATHS = {
  home: {
    index: `${BASE_PATHS.dashboard}/home`,
  },
  dashboard: {
    index: `${BASE_PATHS.dashboard}/dashboard`,
  },
  projects: {
    index: BASE_PATHS.projects,
    all: `${BASE_PATHS.projects}/all`,
    completed: `${BASE_PATHS.projects}/completed`,
  },
  clients: {
    index: BASE_PATHS.clients,
    all: `${BASE_PATHS.clients}/all`,
    crm: `${BASE_PATHS.clients}/crm`,
    contacts: `${BASE_PATHS.clients}/contacts`,
  },
  tasks: {
    index: BASE_PATHS.tasks,
    all: `${BASE_PATHS.tasks}/all`,
    completed: `${BASE_PATHS.tasks}/completed`,
  },
  portal: {
    index: BASE_PATHS.portal,
    view: `${BASE_PATHS.portal}/view`,
    edit: `${BASE_PATHS.portal}/edit`,
    templates: `${BASE_PATHS.portal}/templates`
  },
  templates: {
    index: BASE_PATHS.templates,
    all: `${BASE_PATHS.templates}/all`,
    sent: `${BASE_PATHS.templates}/sent`,
  },
  instantiateTemplate: (templateId: string) => ({
    index: `${BASE_PATHS.instantiateTemplate}/${templateId}`,
  }),
  listings: {
    index: BASE_PATHS.listings,
    all: `${BASE_PATHS.listings}/all`,
    archived: `${BASE_PATHS.listings}/archived`,
  },
  settings: {
    index: `${BASE_PATHS.dashboard}/settings`,
    general: `${BASE_PATHS.settings}/general`,
    integrations: `${BASE_PATHS.settings}/integrations`,
    profile: `${BASE_PATHS.settings}/profile`
  },
  viewProject: (projectId: string) => ({
    index: `${BASE_PATHS.viewProject}/${projectId}`,
    general: `${BASE_PATHS.viewProject}/${projectId}/general`,
    photos: `${BASE_PATHS.viewProject}/${projectId}/photos`,
    files: `${BASE_PATHS.viewProject}/${projectId}/files`,
    tasks: `${BASE_PATHS.viewProject}/${projectId}/tasks`,
    notes: `${BASE_PATHS.viewProject}/${projectId}/notes`,
    expenses: `${BASE_PATHS.viewProject}/${projectId}/expenses`,
  }),
  viewListing: (listingId: string) => ({
    index: `${BASE_PATHS.viewProject}/${listingId}`,
    general: `${BASE_PATHS.viewProject}/${listingId}/general`,
  }),
  viewContact: (contactId: string) => ({
    index: `${BASE_PATHS.viewContact}/${contactId}`,
    general: `${BASE_PATHS.viewContact}/${contactId}/general`,
    files: `${BASE_PATHS.viewContact}/${contactId}/files`,
    tasks: `${BASE_PATHS.viewContact}/${contactId}/tasks`,
    notes: `${BASE_PATHS.viewContact}/${contactId}/notes`,
    expenses: `${BASE_PATHS.viewContact}/${contactId}/expenses`,
  }),
  viewLead: (leadId: string) => ({
    index: `${BASE_PATHS.viewLead}/${leadId}`,
    general: `${BASE_PATHS.viewLead}/${leadId}/general`,
  }),
  ai: {
    index: BASE_PATHS.ai,
    chat: `${BASE_PATHS.ai}/chat`,
  },
  scheduler: {
    index: BASE_PATHS.scheduler,
    calendar: `${BASE_PATHS.scheduler}/calendar`,
    scheduler: `${BASE_PATHS.scheduler}/scheduler`,
    portal: `${BASE_PATHS.scheduler}/portal`,
  },
  viewPortal: (companyName: string) => ({
    index: `${BASE_PATHS.sentPortal}/${companyName}`,
  }),
  portfolio: {
    index: `${BASE_PATHS.portfolio}`,
    design: `${BASE_PATHS.portfolio}/design`,
    edit: `${BASE_PATHS.portfolio}/edit`
  },
  expenses: {
    index: `${BASE_PATHS.expenses}`,
    all: `${BASE_PATHS.expenses}/all`,
    upcoming: `${BASE_PATHS.expenses}/upcoming`,
    completed: `${BASE_PATHS.expenses}/completed`
  },
  library: {
    index: `${BASE_PATHS.library}`,
    all: `${BASE_PATHS.library}/all`,
    favorites: `${BASE_PATHS.library}/favorites`,
  },
  guide: (imageName: string) => ({
    index: `${BASE_PATHS.guide}/${imageName}`,
  }),
  contract: (imageName: string) => ({
    index: `${BASE_PATHS.contract}/${imageName}`,
  }),
  release: (imageName: string) => ({
    index: `${BASE_PATHS.release}/${imageName}`,
  }),
  tool: (imageName: string) => ({
    index: `${BASE_PATHS.tool}/${imageName}`,
  }),
};

export function makePath(workflow: WorkflowPath, path: string) {
  return `${workflow}${path}`;
}
