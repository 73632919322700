import React from 'react'
import { MynkFormDialog } from '../components/MynkForm'
import Presentation from '../pages/MynkPhotography/Portfolio/common/Presentation';
import Images from '../pages/MynkPhotography/Portfolio/common/Images';
import { Box, Stack } from '@mui/material';

type PortfolioPreviewProps = {
  open: boolean;
  onClose: () => void;
};

export default function PortfolioPreview({ open, onClose }: PortfolioPreviewProps) {
  return (
    <MynkFormDialog
        open={open}
        onClose={onClose}
        actionDisabled={true}
        onSubmit={() => {}}
        loading={false}
        noMargin
    >
        <Stack sx={{width: '115rem', height: '147rem', marginTop: '1.5rem', marginLeft: '-2rem'}}>
            <Box sx={{marginTop: '6rem'}}>
                <Presentation isEditing={false} />
            </Box>
            <Box sx={{marginLeft: '2rem', marginTop: '6rem', marginBottom: '2rem'}}>
                <Images />
            </Box>
        </Stack>
    </MynkFormDialog>
  )
}
