import {atom, Atom, WritableAtom} from "jotai";
import {
  _domStateAtom,
  DomState,
  recordsAtom,
  TemplateChangesCheckpoint,
  templateChangesCheckpointAtom,
  TemplateData,
  templateDataAtom,
  templateInitialDetailsAtom,
  TemplateRef,
  templateRefAtom,
  templateSentAtom
} from "./common";
import {DomChangeRecord} from "../../../api/types";
import {createContext, useContext, useMemo} from "react";


export interface TemplateContextValue {
  dataAtom: WritableAtom<TemplateData, any[], any>;
  detailsAtom: WritableAtom<any, any[], any>;
  initialDetailsAtom: WritableAtom<null | any, any[], any>;
  rawDomStateAtom: WritableAtom<null | DomState, any[], any>;
  domStateAtom: WritableAtom<null | DomState, any[], any>;
  recordsAtom: Atom<DomChangeRecord[]>;
  appendRecordAtom: WritableAtom<DomChangeRecord, any[], any>;
  changesCheckpointAtom: Atom<TemplateChangesCheckpoint>;
  sentAtom: Atom<boolean>;
  refAtom: Atom<null | TemplateRef>;
}


const _DEFAULT_CONTEXT_VALUE: TemplateContextValue = {
  dataAtom: templateDataAtom,
  detailsAtom: undefined as any,
  initialDetailsAtom: templateInitialDetailsAtom,
  rawDomStateAtom: _domStateAtom,
  domStateAtom: undefined as any,
  recordsAtom: recordsAtom,
  appendRecordAtom: undefined as any,
  changesCheckpointAtom: templateChangesCheckpointAtom,
  sentAtom: templateSentAtom,
  refAtom: templateRefAtom,
};

const UnderlyingTemplateContext = createContext<TemplateContextValue>(undefined as any);


// interface InitTemplateDataWrapperProps {
//   children?: React.ReactNode;
//
// }
//
// function InitTemplateDataWrapper(props: InitTemplateDataWrapperProps) {
//   const templateAtoms = useTemplateAtoms();
//
//   const setTemplateData = useSetAtom(templateAtoms.dataAtom);
//
//   const userCurrency = useAtomValue(userCurrencyAtom);
//   const {data: projectClient} = useGetClientQuery({
//     uuid: templateState?.project.client_uuid ?? "",
//   });
//   const {data: generalSettingsData} = useListGeneralSettingsQuery({});
//
//   useEffect(() => {
//     setTemplateData({
//       data: {
//         settings: {
//           currency: formatMoney(undefined, userCurrency),
//         },
//         me: {
//           name: generalSettingsData?.business_name,
//           address: generalSettingsData?.business_address,
//           email: generalSettingsData?.email,
//           phone: generalSettingsData?.business_phone,
//         },
//         issued_on: DateTime.now().toFormat("dd MMMM yyyy"),
//         due_date: DateTime.now().plus({month: 1}).toFormat("dd MMMM yyyy"),
//         template_uuid: ((generalSettingsData?.invoice_start_number ?? 0) + 1)
//           .toString()
//           .padStart(4, "0"),
//         // sum: {
//         //   subtotal: priceSum,
//         //   total: priceSum,
//         //   amount_due: priceSum,
//         // },
//         // items: [
//         //   {
//         //     item: templateState?.project.name,
//         //     quantity: 1,
//         //     price: templateState?.project.price,
//         //     _id: generateId(16),
//         //   },
//         // ],
//         client: {
//           uuid: projectClient?.client?.uuid,
//           name: projectClient?.client?.full_name,
//           phone: projectClient?.client?.phone,
//           email: projectClient?.client?.email,
//         },
//       },
//       updates: 0,
//     });
//   }, []);
//
//   return (
//     props.children
//   );
// }


export interface TemplateContextProps {
  global?: boolean;
  initialData?: any;
  children?: React.ReactNode;
}

export function TemplateContext(props: TemplateContextProps) {
  const value: TemplateContextValue = useMemo(() => {
    const result = _DEFAULT_CONTEXT_VALUE;

    if (!props.global) {
      result.dataAtom = atom<TemplateData>({
        data: props.initialData ?? {},
        updates: 0,
        settings: undefined,
      });

      result.initialDetailsAtom = atom<null | any>(null);
    }

    //
    // create derived atoms
    //

    result.detailsAtom = atom<any>(
      (get) => get(templateDataAtom).data,
      // @ts-ignore
      (get, set, value: any) => {
        set(templateDataAtom, {
          data: value,
          updates: get(templateDataAtom).updates + 1,
        });
      }
    );

    result.domStateAtom = atom(
      (get) => get(_domStateAtom),
      (get, set, value: Partial<DomState>) => set(_domStateAtom, {
        ...(get(_domStateAtom) ?? {}),
        ...value,
        counter: (get(_domStateAtom)?.counter ?? 0) + 1,
      } as DomState)
    );

    result.appendRecordAtom = atom<DomChangeRecord>(
      (get) => undefined,
      // @ts-ignore
      (get, set, value: DomChangeRecord) => set(recordsAtom, [
        ...get(recordsAtom),
        value,
      ])
    );

    return result;
  }, []);

  return (
    <UnderlyingTemplateContext.Provider value={value}>
      {props.children}
    </UnderlyingTemplateContext.Provider>
  );
}

export function useTemplateAtoms() {
  return useContext(UnderlyingTemplateContext);
}