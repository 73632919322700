import {SaleStatus} from "../api/types";
import {Chip} from "@mui/material";
import React from "react";


export interface SaleStatusChipProps {
  value: SaleStatus;
}

export default function SaleStatusChip(props: SaleStatusChipProps) {
  switch (props.value) {
    case 'completed':
      return (
        <Chip
          label="Completed"
          sx={{ color: '#37ce4a', bgcolor: '#37ce4a30' }}
        />
      );

    case 'pending':
      return (
        <Chip
          label="Pending"
          sx={{ color: '#ff7a00', bgcolor: '#ff7a0019' }}
        />
      );

    case 'refunded':
      return (
        <Chip
          label="Refunded"
          sx={{ color: '#75b3ff', bgcolor: '#75b3ff19' }}
        />
      );

    default:
      return <></>;
  }

}