import {
  RealEstateListing,
  ListingType,
  Availability,
  EntranceDateTypeOptions,
} from "../../../../api/types";
import { useEffect, useMemo, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { formatMoney, zonedDate } from "../../../../util/common";
import { PATHS, WorkflowPath, makePath } from "../../../../paths";
import {
  FullDescriptionPopUp,
  TruncatedDescription,
} from "../../../MynkPhotography/ViewProject/common/cards";
import AvailabilityChip from "../../../../components/AvailabilityChip";
import { useGetClientQuery } from "../../../../api/clients";
import bedroomIcon from "../../../../assets/icons/bedroom-icon.svg";
import bathroomIcon from "../../../../assets/icons/bathroom-icon.svg";
import propertyAreaIcon from "../../../../assets/icons/property-area-icon.svg";
import { useSetListingAvailabilityMutation } from "../../../../api/projects";
import { useAtomValue } from "jotai";
import { userCurrencyAtom } from "../../../../layouts/Main";

export interface UpperCardProps {
  listing: RealEstateListing;
}

export function UpperCard(props: UpperCardProps) {
  const currencyCode = useAtomValue(userCurrencyAtom);

  const entrance_date = useMemo(() => {
    if (props.listing.entrance_date === EntranceDateTypeOptions.READY_TO_MOVE) {
      return "Ready to move";
    } else {
      const date = zonedDate(props.listing.entrance_date)
      if (!date) {
        return "Invalid date";
      }
      return date.toFormat("LLL dd, yyyy");    
    }
  }, [props.listing.entrance_date]);

  
  const [openedViewFullDescription, setOpenedViewFullDescription] =
    useState(false);

  const handleViewFullDescription = () => {
    setOpenedViewFullDescription(true);
  };

  const setListingAvailability = useSetListingAvailabilityMutation();
  const handleChangeAvailability = (value: string) => {
    setListingAvailability.mutate({
      uuid: props.listing.uuid,
      availability: value as Availability,
    });
  };

  return (
    <>
      <FullDescriptionPopUp
        open={openedViewFullDescription}
        fullDescription={props.listing?.description ?? ""}
        onClose={() => setOpenedViewFullDescription(false)}
      />

      {/* Upper card title */}
      <Typography variant="h4" fontFamily="Helvetica Neue">
        Details
      </Typography>

      <Stack sx={{ px: 1, pt: 1 }} spacing={2}>
        {/* Price row */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Price:</Typography>

          {/* Price area (add "/ Month" if the property is for rent): */}
          <Typography
            fontFamily="Helvetica Neue"
            fontSize={"1.7857rem"}
            fontWeight="bold"
            sx={{ display: "inline-block" }}
          >
            {formatMoney(props.listing?.price, currencyCode)}
            {props.listing?.type == ListingType.FOR_RENT ? " / " : ""}
            <Typography
              fontFamily="Helvetica Neue"
              fontSize={"1.4286rem"}
              fontWeight="bold"
              sx={{ display: "inline-block" }}
            >
              {props.listing?.type == ListingType.FOR_RENT ? "Month" : ""}
            </Typography>
          </Typography>
        </Stack>

        <Divider />

        {/* Status/Availability row */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Status:</Typography>
          <AvailabilityChip
            value={props.listing.availability}
            onChange={handleChangeAvailability}
          />
        </Stack>

        <Divider />

        {/* Address row */}
        <Stack>
          <Typography variant="body2">Address:</Typography>
          <Typography sx={{ color: "#9B9B9B", fontSize: "1.2857rem" }}>
            {props.listing.location}
          </Typography>
        </Stack>
        <Divider />

        {/* Property's info area */}
        <Stack direction="row">
          {/* Bedrooms info */}
          <Stack direction="row" alignItems="center">
            <Box
              component="img"
              src={bedroomIcon}
              sx={{ maxHeight: "0.9286rem", mr: 1 }}
            />
            <Typography fontFamily="Helvetica Neue" fontSize={"1.0714rem"}>
              {props.listing.bedrooms} Beds
            </Typography>
          </Stack>
          <Box sx={{ flex: 1 }} />
          {/* Bathrooms info */}
          <Stack direction="row" alignItems="center">
            <Box
              component="img"
              src={bathroomIcon}
              sx={{ maxHeight: "1rem", mr: 1 }}
            />
            <Typography fontFamily="Helvetica Neue" fontSize={"1.0714rem"}>
              {props.listing.bathrooms} Baths
            </Typography>
          </Stack>
          <Box sx={{ flex: 1 }} />
          {/* property's area info */}
          <Stack direction="row" alignItems="center">
            <Box
              component="img"
              src={propertyAreaIcon}
              sx={{ maxHeight: "1.2857rem", mr: 1 }}
            />
            <Typography fontFamily="Helvetica Neue" fontSize={"1.0714rem"}>
              {props.listing.size} {props.listing.size_units}
            </Typography>
          </Stack>
        </Stack>

        <Divider />

        {/* Entrance date row */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Entrance date:</Typography>
          <Typography>{entrance_date}</Typography>
        </Stack>

        <Divider />
        {/* Description (shorten, full will be displayed when clicking on "View" link/button) */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Description:</Typography>
          <div onClick={handleViewFullDescription}>
            <Typography
              variant="body2"
              sx={{
                color: "#75B3FF",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              View
            </Typography>
          </div>
        </Stack>
        <TruncatedDescription // Responsible for shorten the description.
          description={props.listing?.description ?? ""}
          maxLength={140}
        />
      </Stack>
    </>
  );
}

export interface LowerCardProps {
  listing: RealEstateListing;
}

export function LowerCard(props: LowerCardProps) {
  const { data, isPending } = useGetClientQuery({
    uuid: props.listing.client_uuid,
  });
  const owner = data?.client;

  return (
    <>
      {/* Upper card title */}
      <Typography variant="h4" fontFamily="Helvetica Neue">
        Owner's details
      </Typography>

      <Stack sx={{ px: 1, pt: 1 }} spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Name:</Typography>
          <Typography
            variant="body2"
            sx={{ color: "#75B3FF", textDecoration: "none" }}
            component={Link}
            to={makePath(
              WorkflowPath.REAL_ESTATE,
              PATHS.viewContact(owner?.uuid ?? "").general
            )}
          >
            {owner?.full_name}
          </Typography>
        </Stack>
        <Divider />
        {/* Phone row */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Phone:</Typography>
          <Typography>{owner?.phone}</Typography>
        </Stack>

        <Divider />
        {/* Email row */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Email:</Typography>
          <Typography>{owner?.email}</Typography>
        </Stack>
      </Stack>
    </>
  );
}
