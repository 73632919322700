import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import {
  ExploreButton,
  MoreToExploreButton,
  NeedHelp,
  WelcomePanel,
} from "../../CommonComponents/Home";
import welcomeReelRealEstate from "../../../assets/images/welcome-reel-real-estate.jpg";
import { PATHS, WorkflowPath, makePath } from "../../../paths";
import sampleClientsScreenshot from "../../../assets/images/client-image.png";
import { AddPhotographyContactDialog } from "../../../dialogs/AddPhotographyContact";
import { AddListingDialog } from "../../../dialogs/AddListing";
import { useState } from "react";
import smallLeftArrowBlue from "../../../assets/icons/small-left-arrow-blue.svg";

enum NewMenuIds {
  NEW_LISTING = "New listing",
  NEW_CONTACT = "New client",
}

function LinkWithArrow(props: { type: string }) {
  const [newListingOpen, setNewListingOpen] = useState(false);
  const [addContactOpen, setAddContactOpen] = useState(false);

  {
    /* Open relevant dialog box by the link type */
  }
  const handleClick = () => {
    switch (props.type) {
      case NewMenuIds.NEW_LISTING:
        setNewListingOpen(true);
        break;
      case NewMenuIds.NEW_CONTACT:
        setAddContactOpen(true);
        break;
    }
  };

  return (
    <>
      <AddListingDialog
        open={newListingOpen}
        onClose={() => setNewListingOpen(false)}
      />
      <AddPhotographyContactDialog
        open={addContactOpen}
        onClose={() => setAddContactOpen(false)}
      />

      <Box sx={{ ml: 4, mb: 4 }}>
        <span
          style={{
            background:
              "linear-gradient(transparent calc(100% - 1px), #75b3ff 2px)",
            paddingBottom: 4,
            color: "#75b3ff",
            fontSize: "1.4rem",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          {props.type}{" "}
          <Box
            component="img"
            src={smallLeftArrowBlue}
            width={"0.6429rem"}
            style={{ paddingLeft: 2, marginBottom: 1 }}
          />
        </span>
      </Box>
    </>
  );
}
function BusinessInGlance() {
  return (
    <Box sx={{ ml: 4, mr: 4 }}>
      <Typography variant="h4" sx={{ mt: 5, mb: 5 }}>
        Your business in a glance
      </Typography>

      <Card>
        <Grid container>
          {/* Projects column */}
          <Grid item xs={3.9}>
            <Typography variant="h4" fontWeight="regular" sx={{ ml: 4, mt: 3.7 }}>
              Listings
            </Typography>
            <a href={makePath(WorkflowPath.REAL_ESTATE, PATHS.listings.all)}>
              <Box
                component="img"
                sx={{ ml: 3, mt: 1.8, mb: 2 }}
                width="31.5rem"
                src={sampleClientsScreenshot}
              />
            </a>
            <LinkWithArrow type="New listing" />
          </Grid>

          {/* Clients column */}
          <Grid item xs={4.1}>
            <Typography variant="h4" fontWeight="regular" sx={{ ml: 4, mt: 3.7 }}>
              Clients
            </Typography>
            <a
              href={makePath(WorkflowPath.REAL_ESTATE, PATHS.clients.contacts)}
            >
              <Box
                component="img"
                sx={{ ml: 3, mt: 1.8, mb: 2 }}
                width="31.5rem"
                src={sampleClientsScreenshot}
              />
            </a>
            <LinkWithArrow type="New contact" />
          </Grid>

          {/* Need help & Explore column */}
          <Grid item xs={4}>
            <Stack spacing={4} sx={{ mt: 3 }}>
              <NeedHelp />

              <Stack spacing={5.2} width={"88%"}>
                <ExploreButton text="Explore all A" link="/" />
                <ExploreButton text="Explore all X" link="/" />
                <ExploreButton text="Expand you Y" link="/" />
                <ExploreButton text="Explore all Z" link="/" />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}

function MoreToExplore() {
  return (
    <Box sx={{ pl: 4, pb: 10, pt: 3.2 }}>
      <Typography variant="h4" sx={{ mb: 5.2 }}>
        More to explore
      </Typography>

      <Grid container spacing={3} sx={{ width: "99%" }}>
        <Grid item xs={4}>
          <MoreToExploreButton link="/">Explore 1</MoreToExploreButton>
        </Grid>
        <Grid item xs={4}>
          <MoreToExploreButton link="/">Explore 2</MoreToExploreButton>
        </Grid>
        <Grid item xs={4}>
          <MoreToExploreButton link="/">Explore 3</MoreToExploreButton>
        </Grid>
        <Grid item xs={4}>
          <MoreToExploreButton link="/">Explore 4</MoreToExploreButton>
        </Grid>
        <Grid item xs={4}>
          <MoreToExploreButton link="/">Explore 5</MoreToExploreButton>
        </Grid>
        <Grid item xs={4}>
          <MoreToExploreButton
            link={makePath(WorkflowPath.REAL_ESTATE, PATHS.settings.index)}
          >
            Settings
          </MoreToExploreButton>
        </Grid>
      </Grid>
    </Box>
  );
}

function HomeWidgets() {
  return (
    <Stack spacing={7} sx={{ mt: 5.3 }}>
      <BusinessInGlance />
      {/* TODO: Blogs */}
      <MoreToExplore />
    </Stack>
  );
}

export default function RealEstateHomePage() {
  return (
    <Box>
      <WelcomePanel scrollingImg={welcomeReelRealEstate} />
      <HomeWidgets />
    </Box>
  );
}
