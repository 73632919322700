import { ReactNode } from "react";

import ElevatorDialog from "../ElevatorDialog";
import { Typography, Stack, CircularProgress,Box } from "@mui/material";
import SimpleButton from "../SimpleButton";
import trashIcon2 from "../../assets/icons/trash-icon-2.svg";

export interface ConfirmActionDialogProps {
  open: boolean;
  prefix?: string;
  what?: string;
  whatAction?: string;
  text?: string;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
  children?: ReactNode;
}

/**
 * Renders a dialog box with a confirmation message and two buttons (confirm action & cancel).
 *
 * @param {ConfirmActionDialogProps} props - The props for the ConfirmActionDialog component.
 *   - open (boolean) - Determines whether the dialog box is open or closed.
 *   - what (string) - The name or description of the item being changed in the action (e.g., "task", "client").
 *   - whatAction (string) - The action being confirmed (e.g., "delete", "submit").
 *   - onClose (Function) - Callback function to be called when the dialog box is closed.
 *   - onConfirm (Function) - Callback function to be called when the confirm button is clicked.
 *   - loading (boolean) - Determines whether a loading spinner should be displayed instead of the confirm button - when clicked confirm and start perform the action.
 * @returns {JSX.Element} The rendered ConfirmActionDialog component.
 */
export default function ConfirmActionDialog(props: ConfirmActionDialogProps) {
  return (
    <ElevatorDialog open={props.open} onClose={props.onClose} sx={{px: 5, py: 5}}>
      <Stack alignItems={"center"} sx={{ my: "2rem"}}>
      <Box component="img" src={trashIcon2} sx={{ width: "4rem", mb: "1.45rem" }} />
       <Typography align="center" variant="h1" sx={{ pr: 2, whiteSpace: 'pre', fontSize: "1.6rem" }}>
        {props.text ? props.text.split("&")[0] : <>
          You are about to {props.whatAction} {props.prefix ?? "a"} {props.what}
        </>}

      </Typography>

      <Typography align="center" sx={{ pr: 2, whiteSpace: 'pre', mt: "0.4rem", mb: "1rem", color: "#40404080" }}>
        {props.text ? props.text.split("&")[1] : <>
          Are you sure you want to {props.whatAction} it?
        </>}

      </Typography>
      
      <Stack direction="row" justifyContent="center" spacing={1} sx={{ mt: 1 }}>
        {/* Action to confirm button */}
        <SimpleButton
          backgroundColor="#77B3FF"
          borderColor="#77B3FF"
          fontColor="white"
          onClick={props.onConfirm}
        >
          {props.loading ? (
            <CircularProgress size={"1.4286rem"} sx={{ my: 1 }} />
          ) : (
            props.children
          )}
        </SimpleButton>

        {/* Cancel Button */}
        <SimpleButton
          backgroundColor="transparent"
          borderColor="transparent"
          fontColor="#77B3FF"
          onClick={props.onClose}
        >
          Cancel
        </SimpleButton>
      </Stack>
      </Stack>

    </ElevatorDialog>
  );
}
