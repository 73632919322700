import { useEffect, useState } from 'react';
import ElevatorDialog from '../components/ElevatorDialog';
import { Expense } from '../api/types';
import { Divider, Stack, Typography, IconButton } from '@mui/material';
import { useMemo } from 'react';
import { PaymentMethodLabel } from '../components/PaymentMethodLabel';
import { formatMoney, zonedDate } from '../util/common';
import { useAtomValue } from 'jotai';
import { userCurrencyAtom } from '../layouts/Main';
import { useGetClientQuery } from '../api/clients';
import { useGetProjectQuery } from '../api/projects';
import { ExpensesCategories } from '../api/types';
import { EXPENSES_CATEGORIES } from '../components/ChipSelect/commonOptions';
import { EditExpenseDialog } from './EditExpense';
import ConfirmActionDialog from '../components/ConfirmActionDialog';
import { useDeleteExpenseMutation } from '../api/expenses';
import ThreeDotsButton from '../components/ThreeDotsButton';
import { Clear } from '@mui/icons-material';

export interface ViewExpenseDialogProps {
  expense?: Expense;
  open: boolean;
  onClose: () => void;
}

enum threeDotsOptions {
  EDIT = "edit-lead",
  DELETE = "delete-lead",
}

const DOTS_MENU = [
  {
    id: threeDotsOptions.EDIT,
    label: "Edit",
  },
  {
    id: threeDotsOptions.DELETE,
    label: "Delete",
  },
];

export default function ViewExpenseDialog(props: ViewExpenseDialogProps) {
  const currencyCode = useAtomValue(userCurrencyAtom);
  const created_at_date = useMemo(() => zonedDate(props.expense?.date ?? ""), [props.expense?.date]);
  const [clientName, setClientName] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");
  const [openedEdit, setOpenedEdit] = useState<boolean>(false);
  const [openedDelete, setOpenedDelete] = useState<boolean>(false);
  const deleteExpenseMutation = useDeleteExpenseMutation();

  const { data: clientData } = useGetClientQuery({
    uuid: props.expense?.client ?? ""
  });
  const { data: projectData } = useGetProjectQuery({
    project_uuid: props.expense?.project ?? ""
  });

  useEffect(() => {
    if (!clientData && !projectData) return;

    if (clientData && clientData.client) {
      setClientName(clientData.client.full_name);
    }
    if (projectData && projectData.project) {
      setProjectName(projectData.project.name);
    }
  }, [clientData, projectData]);

  const handleDeleteExpense = () => {
    if (!props.expense || !props.expense.uuid) return;

    deleteExpenseMutation.mutate({uuid: props.expense.uuid}, {
      onSuccess: () => {
        setTimeout(() => {
          setOpenedDelete(false);
          props.onClose();
        }, 70);
      }
    });
  };

  const handleThreeDotsClick = (id: string) => {
    if (!props.expense || !props.expense.uuid) return;

    switch (id) {
      case threeDotsOptions.EDIT:
        setOpenedEdit(true);
        break;
      case threeDotsOptions.DELETE:
        setOpenedDelete(true)
        break;
    }
  }

  return (
    <ElevatorDialog open={props.open} onClose={props.onClose} customCloseButton>
      <EditExpenseDialog
        open={openedEdit}
        onClose={() => {
          setOpenedEdit(false)
          props.onClose()
        }}
        expense={props.expense}
      />

      <ConfirmActionDialog
        open={openedDelete}
        what="expense"
        whatAction="delete"
        onClose={() => setOpenedDelete(false)}
        onConfirm={handleDeleteExpense}
        loading={deleteExpenseMutation.isPending}
        prefix="an"
      >
        Delete
      </ConfirmActionDialog>
      {props.expense && (
        <Stack sx={{
          minHeight: "25rem",
          minWidth: "28rem",
          width: "fit-content",
          height: "fit-content",
        }}>
          <Stack flexDirection={'row'} justifyContent={'space-between'} mt={1}>
            <Typography variant="h2" fontFamily="Helvetica Neue">Expense details</Typography>
            <Stack
              direction="row"
              sx={{ position: "fixed", right: "3rem", top: "2.5rem" }}
            >
              <ThreeDotsButton
                menu={DOTS_MENU}
                sx={{ height: "1.2rem" }}
                onClickItem={handleThreeDotsClick}
                children={null}
              />
              <IconButton
                sx={{ width: "2rem", height: "2rem" }}
                onClick={props.onClose}
              >
                <Clear sx={{ width: "1.7rem", height: "1.7rem" }} />
              </IconButton>
            </Stack>
          </Stack>

          <Divider sx={{ mt: 3, mb: 2 }} />

          <Stack direction="row" justifyContent="space-between">
            <Typography>Description</Typography>
            <Typography>{props.expense.description}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between" mt={1.5}>
            <Typography fontWeight={600} mr={'18rem'}>Total:</Typography>
            <Typography fontSize={24} fontFamily="Helvetica Neue" mt={-1}>{formatMoney(props.expense.amount, currencyCode)}</Typography>
          </Stack>

          <Divider sx={{ my: 2.5 }} />

          <Typography>
            {created_at_date ? (
              created_at_date.toFormat("EEEE, MMMM dd, yyyy")
            ) : (
              "Invalid date"
            )}
          </Typography>

          <Stack direction="row" justifyContent="space-between" mt={1}>
            <Typography>Category</Typography>
            <Typography sx={{
              borderRadius: "30px",
              background: "rgba(117, 179, 255, 0.20)",
              padding: "0.2rem 1rem",
              marginRight: "-1rem",
              color: "#75B3FF"
            }}>{props.expense.category === 'none' || !props.expense.category ? 'N/A' : EXPENSES_CATEGORIES.find((category) => props.expense && category.value === props.expense.category)?.label as ExpensesCategories}</Typography>
          </Stack>

          <Divider sx={{ my: 2.5 }} />
          
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
            <Typography>Payment method</Typography>
            {props.expense.payment_method && (
                <PaymentMethodLabel method={props.expense.payment_method} hint={props.expense.payment_hint} />
              )
            }
          </Stack>

          {props.expense.project && (
            <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
              <Typography>Project</Typography>
              <Typography>{projectName}</Typography>
            </Stack>
          )}
          {props.expense.client && (
            <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
              <Typography>Client</Typography>
              <Typography>{clientName}</Typography>
            </Stack>
          )}
        </Stack>
      )}
    </ElevatorDialog>
  );
}