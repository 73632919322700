import {useState} from 'react'
import { Stack, Typography, Box, Divider } from '@mui/material';
import ClockIcon from '../../assets/icons/small-clock-icon.svg'
import LocationIcon from '../../assets/icons/small-location-icon.svg'
import { MeetingType } from '../../api/types';
import FormButton from './FormButton';

type ChooseMeetingPageProps = {
    meetingTypes: Array<MeetingType>
    setCurrentPage: React.Dispatch<React.SetStateAction<1 | 2 | 3 | 4>>
    selectedMeetingType: string
    setSelectedMeetingType: React.Dispatch<React.SetStateAction<string>>
}

function ChooseMeeting({ meetingTypes, setCurrentPage, selectedMeetingType, setSelectedMeetingType }: ChooseMeetingPageProps) {
    return (
        <Stack 
            sx={{
                borderRadius: '16px',
                background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%)',
                boxShadow: '0px 5px 15px 0px rgba(117, 179, 255, 0.20)',
                height: 'fit-content',
                alignSelf: 'center',
                paddingBottom: '2rem',
            }}
            flexGrow={1}
        >
            <span style={{
                fontSize: '1.5rem',
                fontWeight: 600,
                marginTop: '3rem',
                marginLeft: '2rem',
            }}>Choose a meeting type</span>

            <Stack gap={'0.7rem'} sx={{marginTop: '2.3rem'}} flexGrow={1}>
                {meetingTypes.map((meetingType, index) => ((meetingType.isPublic && (
                    <Stack sx={{
                        borderRadius: '16px',
                        background: selectedMeetingType === meetingType.uuid
                        ? 'linear-gradient(180deg, rgba(235, 235, 235, 0.60) 0%, rgba(240, 240, 240, 0.20) 100%)'
                        : 'linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.20) 100%)',
                        boxShadow: '0px 5px 20px 0px rgba(117, 179, 255, 0.35)',
                        marginX: '2rem',
                        paddingX: '2rem',
                        paddingY: '1rem',
                        cursor: 'pointer',
                    }}
                    onClick={() => meetingType.uuid && setSelectedMeetingType && setSelectedMeetingType(meetingType.uuid)}
                    >
                        <span style={{
                            fontSize: '1.2rem',
                            fontWeight: 600,
                        }}>{meetingType.name}</span>
                        <Stack flexDirection={'row'} sx={{marginTop: '1rem'}}>
                            <Box
                                src={ClockIcon}
                                component='img'
                                sx={{
                                    marginRight: '0.5rem',
                                    marginTop: '0.3rem',
                                    width: '1rem',
                                    height: '1rem'
                                }}
                            />
                            <Typography>{meetingType.duration}</Typography>
                            <Divider orientation='vertical' flexItem sx={{
                                marginX: '1rem',
                                height: '1.5rem',
                            }}/>
                            <Box
                                src={LocationIcon}
                                component='img'
                                sx={{
                                    marginRight: '0.5rem',
                                    marginTop: '0.3rem',
                                    width: '1rem',
                                    height: '1rem'
                                }}
                            />
                            <Typography>{meetingType.location}</Typography>
                        </Stack>
                    </Stack>
                ))
                ))}
            </Stack>
            {selectedMeetingType && (
                <FormButton text='Continue' nextPage={2} setCurrentPage={setCurrentPage} />
            )}
        </Stack>
    );
}

export default ChooseMeeting
