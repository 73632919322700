import { Grid } from "@mui/material";
import { useAddPhotographyClientMutation } from "../api/clients";
import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import { AddPhotographyClientIn, ClientSource, Priority } from "../api/types";
import {
  CONTACT_SOURCE_OPTIONS,
  PRIORITY_OPTIONS,
} from "../components/ChipSelect/commonOptions";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import { useNavigate } from "react-router-dom";
import { PATHS, WorkflowPath, makePath } from "../paths";
import { currentWorkflowAtom } from "../components/MainAppBar/UserControlPopup";
import {useAtomValue} from "jotai";
import {useState} from "react";
import {AxiosError} from "axios";

export interface AddPhotographyContactDialogProps {
  open: boolean;
  onClose: () => void;
}

export function AddPhotographyContactDialog(props: AddPhotographyContactDialogProps) {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const addClient = useAddPhotographyClientMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, AddPhotographyClientIn>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        setErrorMsg(detail ?? "");
      }
    }
  });

  const navigate = useNavigate();
  const handleSubmit = async (values: any) => {
    try {
      await addClient.mutateAsync({
        full_name: values["contactName"].trim(),
        email: values["contactEmail"].trim(),
        phone: values["contactMobile"].trim(),
        source: values["contactSource"],
        priority: values["contactPriority"],
      });

      props.onClose();
      navigate(makePath(`/${useAtomValue(currentWorkflowAtom)?.type}` as WorkflowPath, PATHS.clients.contacts));
    } catch {}
  };

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      title="New contact"
      action="Add contact"
      onSubmit={handleSubmit}
      loading={addClient.isPending}
      errorMsg={errorMsg}
    >
      <Grid container spacing={2} sx={{ width: "32.1429rem" }}>
        <Grid item xs={12}>
          <MynkFormControl
            kind={MynkFormControlKind.TEXT}
            name="contactName"
            label="Name"
            placeholder="Enter name"
            autoComplete={false}
            default=""
          />
        </Grid>

        <Grid item xs={12}>
          <MynkFormControl
            kind={MynkFormControlKind.TEXT}
            name="contactEmail"
            label="E-mail (Optional)"
            placeholder="Enter e-mail address"
            autoComplete={false}
            default=""
          />
        </Grid>

        <Grid item xs={12}>
          <MynkFormControl
            kind={MynkFormControlKind.TEXT}
            name="contactMobile"
            label="Phone number (Optional)"
            placeholder="Enter phone number"
            autoComplete={false}
            default=""
          />
        </Grid>

        <Grid item xs={6}>
          <MynkFormControl
            kind={MynkFormControlKind.CHIP_SELECT}
            name="contactSource"
            label="Source"
            default={ClientSource.OTHER}
            options={CONTACT_SOURCE_OPTIONS}
          />
        </Grid>

        <Grid item xs={6}>
          <MynkFormControl
            kind={MynkFormControlKind.CHIP_SELECT}
            name="contactPriority"
            label="Priority"
            default={Priority.LOW}
            options={PRIORITY_OPTIONS}
          />
        </Grid>
      </Grid>
    </MynkFormDialog>
  );
}
